import API from "../../services/apiHelpers";

export const farmlandsAPI = {
  detailsWaterFootprintValidation: data =>
    API().post("/api/v1/cft-crop-validation/", data),
  soilWaterFootprintValidation: data =>
    API().post("/api/v1/cft-water-soil-validation/", data),
  irrigationWaterFootprintValidation: data =>
    API().post("/api/v1/cft-water-irigation-validation/", data),
  reportWaterFootprint: data =>
    API().post("/api/v1/cft-water-calculation/", data),
  detailsValidation: data => API().post("/api/v1/cft-crop-validation/", data),
  soilValidation: data => API().post("/api/v1/cft-soil-validation/", data),
  fertilizersValidation: data =>
    API().post("/api/v1/cft-fertiliser-validation/", data),
  pesticideValidation: data =>
    API().post("/api/v1/cft-pesticide-validation/", data),
  carbonChangesValidation: data =>
    API().post("/api/v1/cft-land-management-validation/", data),
  irigationValidation: data =>
    API().post("/api/v1/cft-irigation-validation/", data),
  transportValidation: data =>
    API().post("/api/v1/cft-transport-validation/", data),
  directEnergyValidation: data =>
    API().post("/api/v1/cft-direct-energy-validation/", data),
  machineryValidation: data =>
    API().post("/api/v1/cft-machinery-validation/", data),
  processingValidation: data =>
    API().post("/api/v1/cft-processing-validation/", data),
  storageValidation: data =>
    API().post("/api/v1/cft-storage-validation/", data),
  report: data => API().post("/api/v1/cft-crops-calculation/", data),
};

export const dairyProductionAPI = {
  detailsValidation: data => API().post("/api/v1/cft-milk-validation/", data),
  characteristicsValidation: data =>
    API().post("/api/v1/cft-herd-validation/", data),
  grazingValidation: data =>
    API().post("/api/v1/cft-grazing-validation/", data),
  dairyFertiliserValidation: data =>
    API().post("/api/v1/cft-dairy-fertiliser-validation/", data),
  feedValidation: data => API().post("/api/v1/cft-feed-validation/", data),
  manureValidation: data => API().post("/api/v1/cft-manure-validation/", data),
  beddingValidation: data =>
    API().post("/api/v1/cft-bedding-validation/", data),
  report: data => API().post("/api/v1/cft-dairy-calculation/", data),
};

export const exportApi = {
  pdf: data =>
    API().post("/api/v1/cft-report-pdf/", data, {
      responseType: "blob",
    }),
  zip: data =>
    API().post("/api/v1/cft-report-zip/", data, {
      responseType: "blob",
    }),
};

export const checkIP = API().get("/api/v1/cft-ip-verify/");

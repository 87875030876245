/* eslint-disable import/no-duplicates */

// Categories Icons
import { ReactComponent as VegeIcon } from "../../../../../assets/icons/cft/warzywa.svg";
import { ReactComponent as PotatoesIcon } from "../../../../../assets/icons/cft/ziemniaki.svg";

// Fruits Icons
import { ReactComponent as BlueberryIcon } from "../../../../../assets/icons/cft/borowki.svg";
import { ReactComponent as AppleIcon } from "../../../../../assets/icons/cft/jablka.svg";
import { ReactComponent as RaspberryIcon } from "../../../../../assets/icons/cft/maliny.svg";
import { ReactComponent as StrawberryIcon } from "../../../../../assets/icons/cft/truskawki.svg";

// Veggies Icons
import { ReactComponent as TomatoIcon } from "../../../../../assets/icons/cft/warzywa.svg";

// Crops Icons
import { ReactComponent as BarleyIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as CornIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as MilletIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as OatIcon } from "../../../../../assets/icons/cft/crops.svg";
import {
  ReactComponent as GrassWheatIcon,
  ReactComponent as GrassCloverIcon,
} from "../../../../../assets/icons/cft/trawa.svg";
import { ReactComponent as RyeIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as SorgoIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as SoyaIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as SpringWheatIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as WinterWheatIcon } from "../../../../../assets/icons/cft/crops.svg";

import { ReactComponent as LucerneIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as CloverIcon } from "../../../../../assets/icons/cft/crops.svg";
import { ReactComponent as DryBeanIcon } from "../../../../../assets/icons/cft/crops.svg";

// Others
import { ReactComponent as OthersIcon } from "../../../../../assets/icons/cft/inne.svg";

export const residueManagementTypes = [
  {
    value: 1,
    label: "Zebrane; pozostawione bez przetworzenia w pryzmach lub dołach",
  },
  {
    value: 2,
    label: "Zebrane; kompostowane bez wymuszonego napowietrzania",
  },
  {
    value: 3,
    label: "Zebrane; kompostowane z wymuszonym napowietrzaniem",
  },
  {
    value: 4,
    label: "Rozprowadzone po polu lub niezwiązane lub ściółkowane",
  },
  {
    value: 5,
    label: "Spalone na polu",
  },
  {
    value: 6,
    label: "Zebrane z pola do użytku lub na sprzedaż",
  },
  {
    value: 7,
    label: "Użycie słomy ryżowej powyżej 30 dni przed uprawą",
  },
  {
    value: 8,
    label: "Użycie słomy ryżowej poniżej 30 dni przed uprawą",
  },
];

export const CROP_TYPES = [
  {
    id: "33",
    parent: "owoce",
    label: {
      i18n: "cft:crop.type.33",
      default: "Borówki",
    },
    Icon: BlueberryIcon,
  },
  {
    id: "2",
    parent: "owoce",
    label: {
      i18n: "cft:crop.type.2",
      default: "Jabłka",
    },
    Icon: AppleIcon,
  },
  {
    id: "32",
    parent: "owoce",
    label: {
      i18n: "cft:crop.type.32",
      default: "Maliny",
    },
    Icon: RaspberryIcon,
  },
  {
    id: "34",
    parent: "owoce",
    label: {
      i18n: "cft:crop.type.34",
      default: "Truskawki",
    },
    Icon: StrawberryIcon,
  },
  {
    id: "31",
    parent: "owoce",
    label: {
      i18n: "cft:crop.type.31",
      default: "Inne",
    },
    Icon: OthersIcon,
  },
  {
    id: "21",
    parent: "warzywa",
    label: {
      i18n: "cft:crop.type.21",
      default: "Pomidory",
    },
    Icon: TomatoIcon,
  },
  {
    id: "23",
    parent: "warzywa",
    label: {
      i18n: "cft:crop.type.23",
      default: "Warzywa - warzywa",
    },
    Icon: VegeIcon,
  },
  {
    id: "31",
    parent: "warzywa",
    label: {
      i18n: "cft:crop.type.31",
      default: "Inne",
    },
    Icon: OthersIcon,
  },
  {
    id: "3",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.3",
      default: "Jęczmień",
    },
    Icon: BarleyIcon,
  },
  {
    id: "9",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.9",
      default: "Kukurydza",
    },
    Icon: CornIcon,
  },
  {
    id: "10",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.10",
      default: "Proso",
    },
    Icon: MilletIcon,
  },
  {
    id: "11",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.11",
      default: "Owies",
    },
    Icon: OatIcon,
  },
  {
    id: "13",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.13",
      default: "Trawa wieloletnia - zboża",
    },
    Icon: GrassWheatIcon,
  },
  {
    id: "16",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.16",
      default: "Żyto",
    },
    Icon: RyeIcon,
  },
  {
    id: "17",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.17",
      default: "Sorgo",
    },
    Icon: SorgoIcon,
  },
  {
    id: "18",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.18",
      default: "Soja",
    },
    Icon: SoyaIcon,
  },
  {
    id: "19",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.19",
      default: "Pszenica jara",
    },
    Icon: SpringWheatIcon,
  },
  {
    id: "24",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.24",
      default: "Zimowa pszenica",
    },
    Icon: WinterWheatIcon,
  },
  {
    id: "25",
    parent: "zboża",
    label: {
      i18n: "cft:crop.type.25",
      default: "Inne",
    },
    Icon: OthersIcon,
  },
  {
    id: "1",
    parent: "bobowate",
    label: {
      i18n: "cft:crop.type.1",
      default: "Lucerna",
    },
    Icon: LucerneIcon,
  },
  {
    id: "4",
    parent: "bobowate",
    label: {
      i18n: "cft:crop.type.4",
      default: "Koniczyna",
    },
    Icon: CloverIcon,
  },
  {
    id: "7",
    parent: "bobowate",
    label: {
      i18n: "cft:crop.type.7",
      default: "Sucha fasola",
    },
    Icon: DryBeanIcon,
  },
  {
    id: "8",
    parent: "bobowate",
    label: {
      i18n: "cft:crop.type.8",
      default: "Trawa i koniczyna",
    },
    Icon: GrassCloverIcon,
  },
  {
    id: "31",
    parent: "bobowate",
    label: {
      i18n: "cft:crop.type.31",
      default: "Inne",
    },
    Icon: OthersIcon,
  },
  {
    id: "14",
    parent: "ziemniaki",
    label: {
      i18n: "cft:crop.type.14",
      default: "Ziemniaki",
    },
    Icon: PotatoesIcon,
  },
];

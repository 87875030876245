import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { sliceString } from "../../../services/helpers/utilities";
import { CategoryRender } from "../index";
import "./Article.scss";
import Image from "../Image/Image";

export const Article = props => {
  const { t, i18n } = useTranslation(["articlesMeta"]);
  const articlesPath = i18n.language === "pl" ? "/artykuly/" : "/articles/";
  const agroArticlesPath =
    i18n.language === "pl" ? "/agroraport/artykuly/" : "/agroraport/article/";
  const tokenFromStore = useSelector(state => state.user.token);
  const userIsLogin = tokenFromStore !== "";

  const getSlug = category => {
    if (category === "") {
      return articlesPath;
    }

    return category < 18 ? agroArticlesPath : articlesPath;
  };

  const slug = getSlug(props.category);
  const loginPath =
    i18n.language === "pl" ? "/uzytkownik/login" : "/user/login";

  return (
    <div className={`articleCard ${props.className}`} data-index={props.index}>
      <div to={`${slug}${props.slug}`}>
        <Link
          to={
            props.access === 2 && !userIsLogin
              ? loginPath
              : `${slug}${props.slug}${
                  props.video
                    ? `?video`
                    : ``
                }`
          }
        >
          {props.src !== "" ? (
            <div
              className={`${props.video ? " c-video c-video__overlay" : ""}`}
            >
              <Image className="article-image" src={props.src} alt="" />
              {props.access === 2 && (
                <div className="access__badge">
                  {t("articlesMeta:access.user")}
                </div>
              )}
              {props.access === 3 && (
                <div className="access__badge access__badge--premium">
                  Premium
                </div>
              )}
            </div>
          ) : (
            <div className="img-temp">
              <div className="no-image" />
            </div>
          )}
        </Link>
        <div className="article-content">
          <div className="article-basicInfo">
            <p>{props.date}</p>
            <CategoryRender
              isHomepage={props.isHomepage}
              id={props.category}
              type="small"
              defaultCategory={props.categoryFromPage}
            />
          </div>
          <Link to={`${slug}${props.slug}`}>
            <article>
              {props.isHomepage ? (
                <h3 className="article-title">
                  {sliceString(props.articleTitle, 70)}
                </h3>
              ) : (
                <h2 className="article-title">
                  {" "}
                  {sliceString(props.articleTitle, 70)}
                </h2>
              )}
              {!!props.summary && (
                <div className="article-text">
                  {sliceString(props.summary, 100)}
                </div>
              )}
            </article>
          </Link>
        </div>
      </div>
    </div>
  );
};

Article.propTypes = {
  summary: PropTypes.string,
  src: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string,
  articleTitle: PropTypes.string,
  className: PropTypes.string,
  video: PropTypes.bool,
  isHomepage: PropTypes.bool,
};

Article.defaultProps = {
  video: false,
  src: "",
  date: "",
  summary: "",
  category: "",
  articleTitle: "",
  className: "",
  isHomepage: false,
};

/* eslint-disable no-undef */
import * as actions from "./actions";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  suggested: [],
  isError: false,
  isFetching: false,
  popular: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
  newest: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
  promoted: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
};

export default function articlesAll(state = InitialState, action) {
  switch (action.type) {
    case "GET_ARTICLES":
      return {
        ...state,
        isFetching: true,
      };
    case actions.GET_FILTERED_ARTICLES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }
    case actions.GET_ARTICLES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
        isFetching: false,
      };
    }
    case actions.GET_SUGGESTED_ARTICLES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        suggested: data,
      };
    }

    case actions.GET_FILTERED_ARTICLES_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    case actions.GET_ARTICLES_FAIL: {
      return {
        ...state,
        isError: true,
        isFetching: false,
      };
    }
    case actions.GET_ARTICLES_POPULAR_SUCCESS:
      return {
        ...state,
        popular: action.payload.data,
      };
    case actions.GET_ARTICLES_NEWEST_SUCCESS:
      return {
        ...state,
        newest: action.payload.data,
      };
    case "GET_PROMOTED_ARTICLES_SUCCESS":
      return {
        ...state,
        promoted: action.payload.data,
      };
    default:
      return state;
  }
}

export const getArticles = (category, tag, phrase, token, page) => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: "GET_ARTICLES",
    payload: {
      request: {
        url: `/api/v2/articles/?membership=1&lang_code=SET_LANG_CODE&limit=20&offset=${(page -
          1) *
          20}&article_type_id=${category}&category_id=${tag}&search=${phrase}`,
        method: "GET",
        headers,
      },
    },
  };
};

export const getSuggestedArticles = (token, report, slug) => {
  let headers;
  let params = "";
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  } else {
    params = "?lang_code=SET_LANG_CODE";
  }
  const endpoint = report
    ? "agroraport-articles/?lang_code=SET_LANG_CODE&limit=4"
    : `articles/${slug}/suggested/${params}`;
  return {
    type: "GET_SUGGESTED_ARTICLES",
    payload: {
      request: {
        url: `/api/v2/${endpoint}`,
        method: "GET",
        headers,
      },
    },
  };
};

export const getFilteredArticles = ({ category, tag, phrase, token, page }) => {
  let url = `/api/v2/articles/?membership=1&lang_code=SET_LANG_CODE&limit=20&offset=${(page -
    1) *
    20}&article_type_id=${category}&search=${phrase}`;
  if (tag !== "") {
    url = `/api/v2/articles/?membership=1&lang_code=SET_LANG_CODE&limit=20&offset=${(page -
      1) *
      20}&article_type_id=${category}&tags_list=${tag}&search=${phrase}`;
  }
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : null;
  return {
    type: "GET_FILTERED_ARTICLES",
    payload: {
      request: {
        url,
        method: "GET",
        headers,
      },
    },
  };
};

import { format, parse } from "date-fns";
import queryString from "query-string";
import { groupBy, keys } from "ramda";
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { getListOfEvents } from "../../../services/redux/events/eventsAll/reducer";
import Event from "./Event";
import "./Events.scss";

const Events = ({ t, getEvents, events, location, history }) => {
  const searchQuery = queryString.parse(location.search);

  useEffect(() => {
    getEvents({
      past: searchQuery.past,
    });
  }, [location]);

  return (
    <div className="c-events">
      <div className="c-events__filter">
        {t("widgets:events.showMe")}:
        <label htmlFor="incoming-radio" className="c-radio-group">
          {t("widgets:events.incoming")}
          <input
            id="incoming-radio"
            type="radio"
            defaultChecked={searchQuery.past !== true}
            name="radio"
            onChange={() => {
              searchQuery.past = false;
              history.push(
                `${location.pathname}?${queryString.stringify(searchQuery)}`
              );
            }}
          />
          <span className="checkmark" />
        </label>
        <label htmlFor="past-radio" className="c-radio-group">
          {t("widgets:events.past")}
          <input
            id="past-radio"
            type="radio"
            name="radio"
            defaultChecked={searchQuery.past === true}
            onChange={() => {
              searchQuery.past = true;
              history.push(
                `${location.pathname}?${queryString.stringify(searchQuery)}`
              );
            }}
          />
          <span className="checkmark" />
        </label>
      </div>
      {String(searchQuery.past) === "true"
        ? keys(events.results).map(group => (
            // eslint-disable-next-line react/jsx-indent
            <>
              <p className="c-events__group">{group}</p>
              {events.results[group].map(event => (
                <Event key={event.id} {...event} />
              ))}
            </>
          ))
        : keys(events.results)
            .reverse()
            .map(group => (
              <>
                <p className="c-events__group">{group}</p>
                {events.results[group].reverse().map(event => (
                  <Event key={event.id} {...event} />
                ))}
              </>
            ))}
      {/* <Pagination /> */}
    </div>
  );
};

Events.propTypes = {};
Events.defaultProps = {};

const groupEvents = (events, t) => ({
  ...events,
  results: groupBy(event => {
    const month = t(
      `calculatorPage:months.${Number(format(parse(event.started_at), "M")) -
        1}`
    );
    return `${month} ${format(parse(event.started_at), "YYYY")}`;
  })(events.results),
});

export default compose(
  withTranslation(["calculatorPage", "widgets"]),
  connect(
    (state, ownProps) => ({
      token: state.user.token,
      events: groupEvents(state.events.eventsAll, ownProps.t),
    }),
    dispatch => ({
      getEvents: config => dispatch(getListOfEvents(config)),
    })
  ),
  withRouter
)(Events);

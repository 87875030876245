export const managementPractice = {
  arable: [
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 1,
      id: 21,
    },
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 2,
      id: 22,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 1,
      id: 17,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 2,
      id: 18,
    },
  ],
  grass: [
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 1,
      id: 13,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 2,
      id: 14,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 1,
      id: 9,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 2,
      id: 10,
    },
  ],
  forest: [
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 1,
      id: 1,
    },
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 2,
      id: 2,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 1,
      id: 5,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 2,
      id: 6,
    },
  ],
};

export const forestTypes = [
  {
    pl: "umiarkowany las kontynentalny",
    gb: "temperate continental forest",
    id: 13,
  },
  {
    pl: "umiarkowany las górski",
    gb: "temperate mountain systems",
    id: 14,
  },
  {
    pl: "umiarkowany las tropikalny",
    gb: "temperate oceanic forest",
    id: 15,
  },
];

export const initManagementPractice = {
  landUseBase: "",
  landUse: "",
  forestType: undefined,
  forestYear: undefined,
  yearsAgo: "",
  landUseAllocation: "",
};

export const managementPracticeTillages = [
  {
    pl: "Uprawa bezpłużna – uprawa zredukowana",
    gb: "no till » reduced till",
    soil_moisture_id: 1,
    id: 25,
  },
  {
    pl: "Uprawa bezpłużna – uprawa zredukowana",
    gb: "no till » reduced till",
    soil_moisture_id: 2,
    id: 26,
  },
  {
    pl: "uprawa bezpłużna – uprawa konfencjonalna",
    gb: "no till » conventional till",
    soil_moisture_id: 1,
    id: 29,
  },
  {
    pl: "uprawa bezpłużna – uprawa konfencjonalna",
    gb: "no till » conventional till",
    soil_moisture_id: 2,
    id: 30,
  },
  {
    pl: "uprawa zredukowana – uprawa bezpłużna",
    gb: "reduced till » no till",
    soil_moisture_id: 1,
    id: 33,
  },
  {
    pl: "uprawa zredukowana – uprawa bezpłużna",
    gb: "reduced till » no till",
    soil_moisture_id: 2,
    id: 34,
  },
  {
    pl: "uprawa zredukowana – uprawa konwencjonalna",
    gb: "reduced till » conventional till",
    soil_moisture_id: 1,
    id: 37,
  },
  {
    pl: "uprawa zredukowana – uprawa konwencjonalna",
    gb: "reduced till » conventional till",
    soil_moisture_id: 2,
    id: 38,
  },
  {
    pl: "uprawa konwencjonalna – uprawa bezpłużna",
    gb: "conventional till » no till",
    soil_moisture_id: 1,
    id: 41,
  },
  {
    pl: "uprawa konwencjonalna – uprawa bezpłużna",
    gb: "conventional till » no till",
    soil_moisture_id: 2,
    id: 42,
  },
  {
    pl: "uprawa konwencjonalna – uprawa zredukowana",
    gb: "conventional till » reduced till",
    soil_moisture_id: 1,
    id: 45,
  },
  {
    pl: "uprawa konwencjonalna – uprawa zredukowana",
    gb: "conventional till » reduced till",
    soil_moisture_id: 2,
    id: 46,
  },
];

export const initTillage = {
  tillageChange: "",
  tillageYearsAgo: "",
  tillageAllocation: "",
};

export const managementPracticeCovers = [
  {
    pl: "poplony – brak poplonów",
    gb: "cover crop » no cover crop",
    soil_moisture_id: 1,
    id: 53,
  },
  {
    pl: "poplony – brak poplonów",
    gb: "cover crop » no cover crop",
    soil_moisture_id: 2,
    id: 54,
  },
  {
    pl: "brak poplonów – poplony",
    gb: "no cover crop » cover crop",
    soil_moisture_id: 1,
    id: 49,
  },
  {
    pl: "brak poplonów – poplony",
    gb: "no cover crop » cover crop",
    soil_moisture_id: 2,
    id: 50,
  },
];

export const initCover = {
  coverChange: "",
  coverYearsAgo: "",
  coverAllocation: "",
};

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    directEnergy: values.sources.map(
      ({ agregator, category, consumption, label }) => ({
        energy_source_id: Number(agregator),
        category_id: Number(category),
        usage: {
          value: dotFormatter(consumption.value),
          unit_id: consumption.unit,
        },
        label,
      })
    ),
  };
}

import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Checkbox, RadioButton } from "../index";
import "./RequestAccess.scss";
import {
  getToolAccessStatus,
  postToolRequest,
} from "../../../services/redux/tools/reducer";
import { toolNameToId } from "../../../services/helpers/tools";

class RequestAccess extends React.Component {
  componentDidMount() {
    const { token, getToolAccessStatus } = this.props;
    const { benefit } = this.props.match.params;
    if (token) {
      if (benefit === "manageAgro") {
        getToolAccessStatus(token, toolNameToId("agrivi"));
        getToolAccessStatus(token, toolNameToId("rolnikon"));
      } else if (benefit === "monitorField") {
        getToolAccessStatus(token, toolNameToId("satagro"));
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      const { benefit } = this.props.match.params;
      const { getToolAccessStatus, token } = this.props;

      if (benefit === "manageAgro") {
        getToolAccessStatus(token, toolNameToId("agrivi"));
        getToolAccessStatus(token, toolNameToId("rolnikon"));
      } else {
        getToolAccessStatus(token, toolNameToId("satagro"));
      }
    }
  }

  render() {
    const { formVariant, formSentIds, t, i18n } = this.props;

    return (
      // eslint-disable-next-line no-return-assign
      <section ref={ref => (this.formRef = ref)} className="request-access">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="request-access__centered--horizontal">
                <h2 className="request-access__typography--h1">
                  {t("tools:request.title")}
                </h2>
              </div>
            </Col>
          </Row>
          {formVariant === "manageAgro" &&
          (formSentIds.includes(4) || formSentIds.includes(8)) ? (
            <p className="request-access__text-info">
              {t("tools:request.thx")}
            </p>
          ) : formVariant === "monitorField" && formSentIds.includes(2) ? (
            <p className="request-access__text-info">
              {t("tools:request.thx")}
            </p>
          ) : (
            <Formik
              validationSchema={Yup.object().shape({
                businessType: Yup.string().required(t("validation:required")),
                tool:
                  formVariant === "manageAgro" &&
                  Yup.string().required(t("validation:required")),
                checkbox0: Yup.bool()
                  .test(
                    "checked",
                    t("validation:required"),
                    value => value === true
                  )
                  .required(t("validation:required")),
                checkbox1: Yup.bool()
                  .test(
                    "checked",
                    t("validation:required"),
                    value => value === true
                  )
                  .required(t("validation:required")),
              })}
              initialValues={{
                tool: "",
                businessType: "agricultural",
                checkbox0: false,
                checkbox1: false,
              }}
              onSubmit={(values, { setSubmitting }) => {
                const {
                  postToolRequest,
                  token,
                  getToolAccessStatus,
                } = this.props;
                const toolId =
                  formVariant === "monitorField"
                    ? toolNameToId(formVariant)
                    : toolNameToId(values.tool);

                setSubmitting(true);

                postToolRequest(token, toolId, values.businessType)
                  .then(() => {
                    getToolAccessStatus(token, toolNameToId("agrivi"));
                    getToolAccessStatus(token, toolNameToId("rolnikon"));
                    getToolAccessStatus(token, toolNameToId("satagro"));
                    setSubmitting(false);
                    this.formRef.scrollIntoView();
                  })
                  .catch(() => {
                    getToolAccessStatus(token, toolNameToId("agrivi"));
                    getToolAccessStatus(token, toolNameToId("rolnikon"));
                    getToolAccessStatus(token, toolNameToId("satagro"));
                    setSubmitting(false);
                    this.formRef.scrollIntoView();
                  });
              }}
            >
              {({
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={12} xl={{ size: 6, offset: 3 }}>
                      {formVariant === "manageAgro" && (
                        <div className="request-access__input-group">
                          <p className="request-access__input-title">
                            {t("tools:request.choose")}:
                          </p>
                          <div className="request-access__input-wrapper">
                            <RadioButton
                              checked={values.tool === "rolnikon"}
                              onChange={handleChange}
                              value="rolnikon"
                              id="tool"
                              name="tool"
                              hasError={touched.tool && errors.tool}
                              label="RolnikON"
                            />
                          </div>
                          <div className="request-access__input-wrapper">
                            <RadioButton
                              checked={values.tool === "agrivi"}
                              onChange={handleChange}
                              value="agrivi"
                              id="tool"
                              name="tool"
                              hasError={touched.tool && errors.tool}
                              label="Agrivi"
                            />
                          </div>
                        </div>
                      )}
                      <div className="request-access__input-group">
                        <p className="request-access__input-title">
                          {t("tools:request.inputs.0.label")}
                        </p>
                        <div className="request-access__input-wrapper">
                          <RadioButton
                            checked={values.businessType === "agricultural"}
                            onChange={handleChange}
                            value="agricultural"
                            id="businessType"
                            name="businessType"
                            hasError={
                              touched.businessType && errors.businessType
                            }
                            label={t("tools:request.inputs.0.values.0")}
                          />
                        </div>
                        <div className="request-access__input-wrapper">
                          <RadioButton
                            checked={values.businessType === "nonAgricultural"}
                            onChange={handleChange}
                            value="nonAgricultural"
                            id="businessType"
                            name="businessType"
                            hasError={
                              touched.businessType && errors.businessType
                            }
                            label={t("tools:request.inputs.0.values.1")}
                          />
                        </div>
                      </div>
                      {(values.tool || formVariant === "monitorField") && (
                        <>
                          <p className="request-access__marketing-consents">
                            {t("tools:request.inputs.1")}:
                          </p>
                          <div className="request-access__checkbox-wrapper request-access__input-wrapper">
                            <Checkbox
                              id="checkbox0"
                              onChange={handleChange}
                              value={values.checkbox0}
                              checked={values.checkbox0}
                              haserror={
                                !!(touched.checkbox0 && errors.checkbox0)
                              }
                              label={
                                <p className="request-access__checkbox-text">
                                  {formVariant === "monitorField"
                                    ? t("tools:request.terms.0")
                                    : formVariant === "agrivi"
                                    ? t("tools:request.terms.1")
                                    : formVariant === "rolnikon" &&
                                      t("tools:request.terms.2")}
                                </p>
                              }
                            />
                          </div>
                          <div className="request-access__checkbox-wrapper">
                            <Checkbox
                              checked={values.checkbox1}
                              onChange={handleChange}
                              id="checkbox1"
                              value={values.checkbox1}
                              label={
                                <p>
                                  {t("tools:request.terms.3")}
                                  <a
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    href={
                                      i18n.language === "pl"
                                        ? "/static/pdf/Regulamin_Sprzedazy_Premiowej_PL.pdf"
                                        : "/static/pdf/Regulamin_Sprzedazy_Premiowej_EN.pdf"
                                    }
                                    className="request-access__checkbox-wrapper__link"
                                  >
                                    {t("tools:request.button")}
                                  </a>
                                </p>
                              }
                              haserror={
                                !!(touched.checkbox1 && errors.checkbox1)
                              }
                            />
                          </div>
                        </>
                      )}
                    </Col>
                    <div className="request-access__centered--horizontal request-access__centered--full-width">
                      <button
                        disabled={isSubmitting}
                        type="submit"
                        className="request-access__submit-button"
                      >
                        {t("globals:buttons.submit")}
                      </button>
                    </div>
                  </Row>
                </form>
              )}
            </Formik>
          )}
        </Container>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  token: state.user.token,
  formSentIds: state.tools.formSentIds,
});

export default compose(
  withTranslation(["tools", "validation", "globals"]),
  connect(
    mapStateToProps,
    { getToolAccessStatus, postToolRequest }
  )
)(withRouter(RequestAccess));

import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Tooltip.scss";
import Image from "../Image/Image";
import QuestionMark from "../../../../src/assets/icons/question.svg"

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;
    const { content, icon, withoutBackdrop } = this.props;

    return (
      <>
        {!withoutBackdrop && isOpen && <span className="backdrop" />}
        <span className="custom-tooltip">
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <Image
            src={icon ? icon : QuestionMark}
            alt="question"
            onMouseOver={() => this.setState({ isOpen: true })}
            onMouseOut={() => this.setState({ isOpen: false })}
          />
          {isOpen && (
            <>
              <span
                className="tooltip-content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </>
          )}
        </span>
      </>
    );
  }
}

Tooltip.propTypes = {
  content: PropTypes.string,
  withoutBackdrop: PropTypes.bool,
};

Tooltip.defaultProps = {
  content: "",
  withoutBackdrop: false,
};

export default Tooltip;

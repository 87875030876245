/* eslint-disable react/jsx-indent */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftSelectField,
  CftFormControl,
  CftTextField,
  CftWarningBox,
  CftErrorBox,
  // CftTooltip,
} from "../../components";
import useCftCattleValues from "../../utils/useCftCattleValues";

export default function FeedComponentsFields() {
  const { t } = useTranslation(["cftChickenBroiler", "cftCattle", "cft"]);
  const { setFieldValue, values } = useFormikContext();
  const { feedTypes, getFeedTypeName } = useCftCattleValues();

  const options = useMemo(() => {
    if (!values.tmp || values.tmp.length === 0) {
      return feedTypes.map(({ name }, key) => ({
        label: name,
        value: key,
      }));
    }

    const category = values.tmp[0].value;

    return feedTypes[category].elements.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [values]);

  return (
    <>
      {!!values.feed_components.length && (
        <>
          <CftLabel
            isFullWidth
            label={t(
              "cftCattle:feedComponentsFields.fields.feed_componentsLabel",
              "Podaj informacje o paszach stosowanych dla tej grupy produkcyjnej. "
            )}
            className="mb-3"
          />
        </>
      )}
      {values.feed_components.map(({ feed_type }, key) => (
        <CftTank key={feed_type} display="block" mt={key && 2}>
          <CftTank
            mb={2}
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <span className="cft-badge">
              {key + 1}. {getFeedTypeName(feed_type)}
            </span>
            <button
              className="cft-close-badge"
              type="button"
              onClick={() =>
                setFieldValue(
                  "feed_components",
                  values.feed_components.filter(
                    feed => feed.feed_type !== feed_type
                  )
                )
              }
            />
          </CftTank>
          <CftFormControl alignLeft>
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:feedComponentsFields.fields.quantityLabel",
                "Ilość podanej paszy (kg)"
              )}
            />
            <CftTextField
              isRounded
              name={`feed_components[${key}].quantity`}
              placeholder={t(
                "cftChickenBroiler:feedComponentsFields.fields.calorific_valuePlaceholder",
                "Wpisz liczbę"
              )}
              isAlternative
              mask="0000000"
            />
          </CftFormControl>
        </CftTank>
      ))}
      {values.feed_components.length < 4 ? (
        <CftFormControl alignLeft withoutMarginBottom>
          {!values.feed_components.length && (
            <>
              <CftLabel
                isFullWidth
                label={t(
                  "cftCattle:feedComponentsFields.fields.feed_componentsLabel",
                  "Podaj informacje o paszach stosowanych dla tej grupy produkcyjnej. "
                )}
                className="mb-3"
              />
            </>
          )}
          <CftLabel
            isFullWidth
            label={
              values.feed_components.length > 0
                ? t("cft:forage.form.7", "Dodaj kolejną paszę")
                : t(
                    "cftChickenBroiler:feedComponentsFields.fields.feed_componentsAdd",
                    "Dodaj paszę"
                  )
            }
          />
          <CftSelectField
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            name="tmp"
            options={options}
            onChange={value => {
              setFieldValue("tmp", value);

              if (value.length === 2) {
                setFieldValue("feed_components", [
                  ...values.feed_components,
                  {
                    feed_type: value[1].value,
                    quantity: 120,
                  },
                ]);
                setFieldValue("tmp", []);
              }
            }}
            label={t(
              "cftChickenBroiler:feedComponentsFields.fields.feed_componentsPlaceholder",
              "Wybierz z listy"
            )}
            isRounded
            isAlternative
            isMulti
          />
        </CftFormControl>
      ) : (
        <CftWarningBox text="Zdeklarowałeś maksymalną ilość typów paszy" />
      )}

      {!values.feed_components.length > 0 ? (
        <CftErrorBox
          text={t(
            "cftChickenBroiler:productionCyclesFields.fields.feed_componentsError",
            "Minimum 1 pasz."
          )}
        />
      ) : null}
    </>
  );
}

import React from "react";
import PropTypes from "prop-types";

import "./CftErrorBox.scss";

const CftWarningBox = ({ text, ...props }) => {
  return (
    <div className="cft-error-box" {...props}>
      {text}
    </div>
  );
};

export default CftWarningBox;

CftWarningBox.propTypes = {
  text: PropTypes.string.isRequired,
};

CftWarningBox.defaultProps = {};

import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";

export const gtmconfig = item => {
  let config = {
    gtmId: "GTM-5ZS7M76",
  };
  if (item) {
    switch (item) {
      case "/":
        config.dataLayer = {
          event: "info",
          pageCategory: "HP",
          productName: "agronomist",
        };
        break;

      case "/contact":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "contactForm",
          productName: "agronomist",
        };
        break;

      case "/kontakt":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "contactForm",
          productName: "agronomist",
        };
        break;

      case "contactform":
        config = {
          dataLayer: {
            event: "info",
            pageTitle: "Thx",
            pageCategory: "contactForm",
            transactionId: +new Date(),
            productName: "agronomist",
          },
        };
        break;

      case "/user/login":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "loginForm",
          productName: "agronomist",
        };
        break;

      case "/uzytkownik/login":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "loginForm",
          productName: "agronomist",
        };
        break;

      case "loginform":
        config = {
          dataLayer: {
            event: "info",
            pageTitle: "Thx",
            pageCategory: "loginForm",
            transactionId: +new Date(),
            productName: "agronomist",
          },
        };
        break;

      case "/user/register":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "registrForm",
          productName: "agronomist",
        };
        break;

      case "/uzytkownik/rejestracja":
        config.dataLayer = {
          event: "info",
          pageTitle: "form",
          pageCategory: "registrForm",
          productName: "agronomist",
        };
        break;

      case "registerform":
        config = {
          dataLayer: {
            event: "info",
            pageTitle: "Thx",
            pageCategory: "registrForm",
            transactionId: +new Date(),
            productName: "agronomist",
          },
        };
        break;

      default:
        config.dataLayer = {
          event: "info",
          productName: "agronomist",
        };
        break;
    }
  }
  return config;
};

export const gtmRun = (step, push) => {
  if (["production", "stage"].includes(process.env.REACT_APP_API_ENV)) {
    // if (true) {
    if (push) {
      TagManager.dataLayer(gtmconfig(step || window.location.pathname));
    } else {
      TagManager.initialize(gtmconfig(step || window.location.pathname));
    }
  }
};

export const pixelInit = () => {
  if (["production", "stage"].includes(process.env.REACT_APP_API_ENV)) {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init("495260557545741", {}, options);
  }
};

export const pixelRun = (event, data) => {
  if (["production", "stage"].includes(process.env.REACT_APP_API_ENV)) {
    if (event && data) {
      ReactPixel.track(event, data);
    } else {
      ReactPixel.pageView();
    }
  }
};

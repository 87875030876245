/* eslint-disable no-undef */
import Cookies from "js-cookie";
import * as actions from "./actions";

const InitialState = {
  data: [],
  isError: false,
};

export default function expertsAll(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_EXPERT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isError: false,
      };
    }

    case "RESET_EXPERT": {
      return InitialState;
    }

    case actions.GET_EXPERT_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getExpert = id => {
  const token = Cookies.get("user_token");
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : null;
  return {
    type: actions.GET_EXPERT,
    payload: {
      request: {
        url: `/api/v1/experts/${id}/`,
        method: "GET",
        headers,
      },
    },
  };
};

import useSWR from "swr";
import API from "../../../../services/apiHelpers";

export function useListOfOleaginousCereals() {
  const url = "/api/v1/oleaginous/products/";
  const { data } = useSWR(url, () => API().get(url));

  return {
    data: data ? data.data : undefined,
  };
}

export function useDataForRaportCenowy() {
  const url = "/api/v1/oleaginous/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      params: {
        limit: 20,
        ordering: "-week_start_date",
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Select from "react-select";

import "./CftInputs.scss";

const customStyles = {
  container: provided => ({
    ...provided,
    border: "2px solid #00965E",
    width: "23.2rem",
  }),
  control: provided => ({
    ...provided,
    border: 0,
    borderRadius: 0,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  input: provided => ({
    ...provided,
    paddingTop: 6,
    paddingBottom: 6,
    lineHeight: "20px",
  }),
};

const styleShort = {
  container: provided => ({
    ...provided,
    border: "2px solid green",
    width: "11rem",
    display: "flex",
    flexDirection: "row",
  }),
  control: provided => ({
    ...provided,
    border: 0,
    borderRadius: 0,
    width: "21rem",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  input: provided => ({
    ...provided,
    paddingTop: 6,
    paddingBottom: 6,
    lineHeight: "20px",
  }),
};

function CftSelect({ options, isLong, label, id }) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label htmlFor={id}>
      <div className={classnames("cft-inputs--label")}>{label}</div>
      <div className={classnames("cft-inputs--container")}>
        <Select
          id={id}
          styles={{ ...customStyles, ...(!isLong && styleShort) }}
          options={options}
        />
      </div>
    </label>
  );
}

CftSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isLong: PropTypes.bool,
};

CftSelect.defaultProps = {
  options: [{ value: "default", label: "default" }],
  isLong: true,
};

export default CftSelect;

import React from "react";
import { formatDate } from "../../../services/helpers/articles";
import { Article, Poll } from "../index";
import "./ArticlePoll.scss";

export const ArticlePoll = ({ article }) => {
  return (
    <div className="c-article-with-poll">
      {article && (
        <Article
          key={article.id}
          index={0}
          id={article.id}
          src={article.thumb !== null ? article.thumb.file : ""}
          date={formatDate(article.published_at)}
          tag=""
          category={article.article_type}
          summary={article.summary}
          articleTitle={article.name}
          articleText="Cześć treści naprawdę ważnego raportu."
          articlesTags={article.articlesTags}
          slug={article.slug}
          report
          access={article.access}
          video={!!article.video_url}
        />
      )}
      <Poll poll={article.polls[0]} className="c-poll--side" />
    </div>
  );
};

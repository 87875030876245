import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

import withLayout from "../../../services/hoc/withLayout";
import {
  Breadcrumbs,
  Footer,
  Metadata,
  NavigationBar,
} from "../../common/index";
import { VoluntarySaleCalculation } from "../../common/Sidebar/Widgets";

import RealEstateSearch from "./components/RealEstateSearch/RealEstateSearch";
import RealEstateCategories from "./components/RealEstateCategories/RealEstateCategories";
import PromotedProperties from "./components/PromotedProperties/PromotedProperties";
import RealEstateVideo from "./components/RealEstateVideo/RealEstateVideo";
import RealEstateHowItWork from "./components/RealEstateHowItWork/RealEstateHowItWork";
import RealEstateArticlesLP from "./components/RealEstateArticlesLP/RealEstateArticlesLP";
import LPImage from "../../../assets/img/re_lp.jpg";

import "./RealEstate.scss";

function RealEstateLP({ currentWidth }) {
  const { t } = useTranslation("realEstate");
  return (
    <Container fluid>
      <Metadata
        name={t("realEstate:page.title")}
        description={t("realEstate:page.description")}
      />
      <NavigationBar search selected="Nieruchomości" />
      <div className="real-estate real-estate--lp">
        <div
          className="real-estate__landing-page"
          style={{ backgroundImage: `url("${LPImage}")` }}
        >
          <Container>
            <Row>
              <Col xs={12}>
                <Breadcrumbs currentName={t("realEstate:page.title")} />
              </Col>
              <Col
                className="real-estate__landing-page__title"
                xs={12}
                md={8}
                lg={7}
              >
                <h1>Agronomist - nieruchomości</h1>
                <h5>
                  Poszukujesz gruntu rolnego, siedliska, mieszkania lub lokalu w
                  Twojej lokalizacji? Zależy Ci na dobrej cenie i pewności
                  zakupu? Koniecznie zapoznaj się z naszym Modułem
                  Nieruchomości!
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="real-estate__hero">
          <Row>
            <Col xs={12} as="section">
              <div className="real-estate__note">
                Moduł nieruchomości to wyszukiwarka ułatwiająca znalezienie
                nieruchomości rolnych na terenie Polski, wystawionych na
                sprzedaż przez Klientów Banku BNP Paribas. W łatwy i szybki
                sposób dopasuj swoje kryteria i przeglądaj ogłoszenia oraz bądź
                w kontakcie z opiekunem interesującej Cię oferty. Zapoznaj się
                ze szczegółami sprzedaży na stronie Banku BNP Paribas oraz z
                Regulaminem usługi Modułu Nieruchomości.
              </div>
              <RealEstateSearch isLPMode />
            </Col>
          </Row>
          <Row>
            <Col xs={12} as="section">
              <RealEstateCategories />
            </Col>
            <Col xs={12} as="section">
              <PromotedProperties />
            </Col>
            <Col xs={12} as="section">
              <RealEstateVideo />
            </Col>
            <Col xs={12} as="section">
              <RealEstateHowItWork />
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <VoluntarySaleCalculation />
          </Row>
        </Container>
        <Container>
          <Row>
            <RealEstateArticlesLP />
          </Row>
        </Container>
        <Footer windowWidth={currentWidth} />
      </div>
    </Container>
  );
}

export default withLayout(RealEstateLP);

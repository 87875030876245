export const energySourcesTypes = {
  agregator: [
    {
      pl: "diesel",
      gb: "diesel",
      value: 102,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "benzyna",
      gb: "petrol",
      value: 103,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "bioetanol",
      gb: "bioethanol",
      value: 104,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "biodiesel",
      gb: "biodiesel",
      value: 105,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "biomasa o wysokiej gęstości",
      gb: "high-density biomass",
      value: 110,
      units: [
        {
          value: 7,
          pl: "kg",
          full_name: "kilogram",
        },
        {
          value: 9,
          pl: "t",
          full_name: "t",
        },
      ],
    },
    {
      pl: "drewno opałowe",
      gb: "fuel wood",
      value: 111,
      units: [
        {
          value: 7,
          pl: "kg",
          full_name: "kilogram",
        },
        {
          value: 9,
          pl: "t",
          full_name: "t",
        },
      ],
    },
    {
      pl: "węgiel (według wagi)",
      gb: "coal (by weight)",
      value: 112,
      units: [
        {
          value: 7,
          pl: "kg",
          full_name: "kilogram",
        },
        {
          value: 9,
          pl: "t",
          full_name: "t",
        },
      ],
    },
    {
      pl: "gaz (według wagi )",
      gb: "gas (by weight)",
      value: 113,
      units: [
        {
          value: 7,
          pl: "kg",
          full_name: "kilogram",
        },
        {
          value: 9,
          pl: "t",
          full_name: "t",
        },
      ],
    },
    {
      pl: "ropa naftowa (wg objętości)",
      gb: "oil (by volume)",
      value: 114,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "gaz (wg objętości)",
      gb: "gas (by volume)",
      value: 115,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "węgiel (wg energii)",
      gb: "coal (by energy)",
      value: 116,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "gaz (wg energii)",
      gb: "gas (by energy)",
      value: 117,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "ropa naftowa (wg energii)",
      gb: "oil (by energy)",
      value: 118,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "biodiesel (niski)",
      gb: "biodiesel (low)",
      value: 127,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "bioetanol (niski)",
      gb: "bioethanol (low)",
      value: 128,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
    {
      pl: "propan",
      gb: "propane",
      value: 342,
      units: [
        {
          value: 15,
          pl: "l",
          full_name: "l",
        },
        {
          value: 32,
          pl: "m3",
          full_name: "cubic metre",
        },
      ],
    },
  ],
  electricity: [
    {
      pl: "sieć elektryczna (z sieci)",
      gb: "electricity (grid)",
      value: 106,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "energia elektryczna (hydroelektryczna)",
      gb: "electricity (hydroelectric)",
      value: 107,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "elektryczność (wiatr)",
      gb: "electricity (wind)",
      value: 108,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "elektryczność (fotowoltaiczna)",
      gb: "electricity (photo-voltaic)",
      value: 109,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
  ],
  chp: [
    {
      pl: "Elektrociepłownia na potrzeby własne (gaz ziemny)",
      gb: "CHP onsite (natural gas)",
      value: 343,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "CHP na miejscu (biogaz)",
      gb: "CHP onsite (biogas)",
      value: 344,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl:
        "Elektrociepłownia ze sprzedażą na zewnątrz — energia elektryczna (gaz ziemny)",
      gb: "CHP export - electricity (natural gas)",
      value: 345,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "Elektrociepłownia ze sprzedażą na zewnątrz — ciepło (gaz ziemny)",
      gb: "CHP export - heat (natural gas)",
      value: 346,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "Elektrociepłownia ze sprzedażą na zewnątrz — (biogaz)",
      gb: "CHP export - (biogas)",
      value: 347,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "Z zewnętrznej elektrociepłowni — energia elektryczna (gaz ziemny)",
      gb: "CHP import - electricity (natural gas)",
      value: 348,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "Z zewnętrznej elektrociepłowni — ciepło (gaz ziemny)",
      gb: "CHP import - heat (natural gas)",
      value: 349,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
    {
      pl: "Z zewnętrznej elektrociepłowni — (biogaz)",
      gb: "CHP import (biogas)",
      value: 350,
      units: [
        {
          value: 35,
          pl: "MJ",
          full_name: "MJ",
        },
        {
          value: 36,
          pl: "kWh",
          full_name: "kWh",
        },
      ],
    },
  ],
};

export const machineryTypes = {
  harvest: [
    {
      pl: "oprysk herbicydami",
      gb: "herbicide spraying",
      value: 24,
    },
    {
      pl: "oprysk nawozów",
      gb: "fertiliser spraying",
      value: 25,
    },
    {
      pl: "Kombajn do buraków",
      gb: "Beet harvester",
      value: 27,
    },
    {
      pl: "kombajn",
      gb: "combine",
      value: 28,
    },
    {
      pl: "kombajn kukurydziany",
      gb: "corn combine",
      value: 29,
    },
    {
      pl: "maszyna do zbioru bawełny",
      gb: "cotton picker",
      value: 30,
    },
    {
      pl: "część żniwna kombajnu do bawełny/ogławiacz do ziemniaków",
      gb: "cotton stripper/potato topper",
      value: 31,
    },
    {
      pl: "dmuchawa / myjka do paszy",
      gb: "forage blower/washer",
      value: 32,
    },
    {
      pl: "silosokombajn",
      gb: "forage harvester",
      value: 33,
    },
    {
      pl: "kosiarka / równiarka",
      gb: "mower/grader",
      value: 35,
    },
    {
      pl: "kombajn do ziemniaków",
      gb: "potato harvester",
      value: 37,
    },
    {
      pl: "zgrabiarka do ziemniaków",
      gb: "potato windrower",
      value: 38,
    },
    {
      pl: "zgrabiarka",
      gb: "rake",
      value: 39,
    },
    {
      pl: "kombajn do pomidorów",
      gb: "tomato harvester",
      value: 40,
    },
    {
      pl: "zgrabiarka / żniwiarka",
      gb: "windrower/swather",
      value: 41,
    },
  ],
  plowing: [
    {
      pl: "pogłębiacz",
      gb: "chisel plough",
      value: 2,
    },
    {
      pl: "kultywator",
      gb: "coulter",
      value: 3,
    },
    {
      pl: "brona tarczowa",
      gb: "disc gang",
      value: 4,
    },
    {
      pl: "brona talerzowa",
      gb: "disc harrow",
      value: 5,
    },
    {
      pl: "ścielarka talerzowa",
      gb: "disk bedder",
      value: 6,
    },
    {
      pl: "kultywator polowy / redliniarka",
      gb: "field cultivator/ridger",
      value: 7,
    },
    {
      pl: "włóka/zbieraczka do kamieni",
      gb: "land plane/destoner",
      value: 11,
    },
    {
      pl: "pług odkładnicowy",
      gb: "moldboard plough",
      value: 12,
    },
    {
      pl: "brona chwastownik",
      gb: "rod weeder",
      value: 14,
    },
    {
      pl: "brona rolkowa",
      gb: "roller harrow",
      value: 15,
    },
    {
      pl: "glebogryzarka",
      gb: "rotary hoe/bed tiller",
      value: 17,
    },
    {
      pl: "kultywator do upraw rzędowych",
      gb: "row crop cultivator",
      value: 18,
    },
    {
      gb: "row crop planter",
      pl: "sadzarka do upraw rzędowych",
      value: 19,
    },
    {
      pl: "pogłębiacz",
      gb: "subsoiler",
      value: 20,
    },
    {
      pl: "pług zgarniający",
      gb: "sweep plough",
      value: 21,
    },
    {
      pl: "brona zębowa / transport nasion",
      gb: "tine harrow/seed handling transport",
      value: 22,
    },
    {
      pl: "brona zębata",
      gb: "tooth harrow",
      value: 23,
    },
    {
      pl: "brona z napędem",
      gb: "power harrow",
      value: 43,
    },
    {
      pl: "bronowanie broną talerzową",
      gb: "disc harrowing",
      value: 47,
    },
    {
      pl: "bronowanie broną rolkową",
      gb: "roller harrowing",
      value: 48,
    },
    {
      pl: "maszyna do usuwania kamieni przy uprawie ziemniaków",
      gb: "potato destoner",
      value: 50,
    },
  ],
  sowing: [
    {
      pl: "siewnik zbożowy",
      gb: "grain drill",
      value: 8,
    },
    {
      pl: "siewnik zbożowy do uprawy zerowej",
      gb: "grain drill-notill",
      value: 9,
    },
    {
      pl: "siewnik punktowy",
      gb: "hoe drill",
      value: 10,
    },
    {
      pl: "siewnik pneumatyczny",
      gb: "pneumatic drill",
      value: 13,
    },
    {
      pl: "wał rolkowy",
      gb: "roller packer",
      value: 16,
    },
    {
      pl: "rozrzucanie nawozów",
      gb: "fertiliser spreading",
      value: 26,
    },
    {
      pl: "Rozrzutnik obornika",
      gb: "manure spreader",
      value: 34,
    },
    {
      pl: "kosiarka do pielęgnacji",
      gb: "mower-conditioner",
      value: 36,
    },
    {
      pl: "obsypywanie",
      gb: "ridging",
      value: 42,
    },
    {
      pl: "oprysk środków biobójczych",
      gb: "biocide spraying",
      value: 44,
    },
    {
      pl: "wstrzykiwanie obornika",
      gb: "manure injections",
      value: 45,
    },
    {
      pl: "sadzenie ziemniaków",
      gb: "potato planting",
      value: 49,
    },
  ],
};

export const magazinEnergySourcesTypes = [
  {
    pl: "sieć elektryczna (z sieci)",
    gb: "electricity (grid)",
    value: 106,
  },
  {
    pl: "energia elektryczna (hydroelektryczna)",
    gb: "electricity (hydroelectric)",
    value: 107,
  },
  {
    pl: "elektryczność (wiatr)",
    gb: "electricity (wind)",
    value: 108,
  },
  {
    pl: "elektryczność (fotowoltaiczna)",
    gb: "electricity (photo-voltaic)",
    value: 109,
  },
  {
    pl:
      "Elektrociepłownia ze sprzedażą na zewnątrz — energia elektryczna (gaz ziemny)",
    gb: "CHP export - electricity (natural gas)",
    value: 345,
  },
  {
    pl: "Z zewnętrznej elektrociepłowni — energia elektryczna (gaz ziemny)",
    gb: "CHP import - electricity (natural gas)",
    value: 348,
  },
];

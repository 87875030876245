import React, { useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftFormField,
  CftError,
  CftFormControl,
  CftFormErrorFocus,
  CftTank,
  CftButton,
  CftQuestionGroup,
  CftConditionalRendering,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import { scrollToLastElement } from "../../../../utils/cftUtils";
import TransportTypeFields from "./TransportTypeFields";
import { mapValues } from "./transportBothCalc.utils";
import { farmlandsAPI } from "../../../../utils/cftApi";

export const initTransport = () => ({
  id: Date.now(),
  method: "",
  type: "",
  weight: {
    unit: "",
    value: "",
  },
  distance: "",
  label: "",
});

function TransportBothCalcForm() {
  const { values, setFieldValue, errors } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t(
            "cft:transport.formFarmlands.1",
            "Czy korzystasz z transportu dla tej uprawy?"
          )}
        </h3>
        <CftFormField name="used">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value === true}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("transports", [initTransport()]);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("transports", []);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.used === true}>
        <>
          {values.transports.map((transport, key) => (
            <CftQuestionGroup
              // eslint-disable-next-line no-return-assign
              ref={el => (groupRefs.current[key] = el)}
              key={key}
              label={`${t("cft:transport.form.1", "Metoda transportu")} ${key +
                1}`}
              withoutMarginBottom={key + 1 === values.transports.length}
              onRemove={
                key === 0
                  ? undefined
                  : () => {
                      setFieldValue(
                        "transports",
                        values.transports.filter(f => f.id !== transport.id)
                      );
                      scrollToLastElement(groupRefs, 130);
                    }
              }
              hasError={errors.transports ? !!errors.transports[key] : false}
            >
              <TransportTypeFields id={key} name="transports" />
            </CftQuestionGroup>
          ))}
          <CftFormControl variant="small">
            <CftButton
              isFullwidth
              onClick={() => {
                setFieldValue("transports", [
                  ...values.transports,
                  initTransport(),
                ]);
                scrollToLastElement(groupRefs, 130);
              }}
            >
              {values.transports.length
                ? t("cft:transport.form.11", "Dodaj dodatkowe")
                : t("cft:transport.form.10", "Dodaj metode transportu")}
            </CftButton>
          </CftFormControl>
        </>
      </CftConditionalRendering>
      <CftFormErrorFocus />
    </Form>
  );
}

export default TransportBothCalcForm;

export function TransportBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft"]);

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.transport}
      validationSchema={Yup.object().shape({
        transports: Yup.mixed().when("used", {
          is: v => typeof v === "boolean",
          then: Yup.array().of(
            Yup.object().shape({
              method: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              type: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              weight: Yup.object().shape({
                value: Yup.number()
                  .transform((_, v) => Number(v.replace(/,/, ".")))
                  .required(t("cft:validation.required", "Wpisz wartość"))
                  .typeError(
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                  .min(
                    0,
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  ),
                unit: Yup.string().required(
                  t("cft:validation.unitTypeError", "Wybierz jednostkę")
                ),
              }),
              distance: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              label: Yup.string()
                .trim()
                .required(t("cft:validation.required", "Wpisz wartość"))
                .max(20, "Maksymalna ilość znaków to 20"),
            })
          ),
          otherwise: Yup.array(),
        }),
      })}
      onSubmit={values => {
        saveBothCalc({ transport: values });
        farmlandsAPI.transportValidation(mapValues(values)).then(({ data }) => {
          saveBothCalcReport(data);
          history.push(routes.FARMLANDS.BOTH_CALC.REPORT);
        });
      }}
    >
      {children}
    </Formik>
  );
}

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAIL = "GET_EVENT_FAIL";

export const GET_EVENTS_PRELEGENTS = "GET_EVENTS_PRELEGENTS";
export const GET_EVENTS_PRELEGENTS_SUCCESS = "GET_EVENTS_PRELEGENTS_SUCCESS";

export const GET_EVENT_TRANSLATION = "GET_EVENT_TRANSLATION";
export const GET_EVENT_TRANSLATION_SUCCESS = "GET_EVENT_TRANSLATION_SUCCESS";
export const GET_EVENT_TRANSLATION_FAIL = "GET_EVENT_TRANSLATION_FAIL";

export const REGISTER_USER_TO_EVENT = "REGISTER_USER_TO_EVENT";
export const REGISTER_USER_TO_EVENT_SUCCESS = "REGISTER_USER_TO_EVENT_SUCCESS";
export const REGISTER_USER_TO_EVENT_FAIL = "REGISTER_USER_TO_EVENT_FAIL";

export const UNREGISTER_USER_FROM_EVENT = "UNREGISTER_USER_FROM_EVENT";
export const UNREGISTER_USER_FROM_EVENT_SUCCESS =
  "UNREGISTER_USER_FROM_EVENT_SUCCESS";
export const UNREGISTER_USER_FROM_EVENT_FAIL =
  "UNREGISTER_USER_FROM_EVENT_FAIL";

export const RESET_SINGLE_PAGE = "RESET_SINGLE_PAGE";

import React, { useRef, useState, useEffect } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { differenceInWeeks, addWeeks, format } from "date-fns";

import { Col, Row } from "reactstrap";
import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftTooltip,
  CftTextField,
  CftDatePicker,
  CftTank,
  CftButton,
  CftConditionalRendering,
  CftQuestionGroup,
  CftUnitField,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import {
  mapValues,
  waterFootprintMapValues,
  mapWaterSource,
} from "./irrigationBothCalc.utils";
import useCftOptions from "../../../../utils/useCftOptions";

// Icons
import { ReactComponent as PivotIcon } from "../../../../../assets/icons/cft/pivot.svg";
import { ReactComponent as RaingunIcon } from "../../../../../assets/icons/cft/raingun.svg";
import { ReactComponent as FloodingIcon } from "../../../../../assets/icons/cft/flooding.svg";
import { ReactComponent as DripIcon } from "../../../../../assets/icons/cft/drip.svg";

// Water Source icons
import { ReactComponent as StawIcon } from "../../../../../assets/icons/cft/staw.svg";
import { ReactComponent as ZbiornikIcon } from "../../../../../assets/icons/cft/zbiornik.svg";
import { ReactComponent as RzekaIcon } from "../../../../../assets/icons/cft/rzeka.svg";
import { ReactComponent as ZbiornikSztucznyIcon } from "../../../../../assets/icons/cft/zbiornik-sztuczny.svg";
import { ReactComponent as OdwiertIcon } from "../../../../../assets/icons/cft/odwiert.svg";
import { ReactComponent as KanalizacjaIcon } from "../../../../../assets/icons/cft/kanalizacja.svg";

// Energy Source Icons
import { ReactComponent as DieselIcon } from "../../../../../assets/icons/cft/diesel.svg";
import { ReactComponent as GrawitacjaIcon } from "../../../../../assets/icons/cft/grawitacja.svg";
import { ReactComponent as EnergyIcon } from "../../../../../assets/icons/cft/energia.svg";
import { farmlandsAPI } from "../../../../utils/cftApi";

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function IrrigationBothCalcForm() {
  const { setFieldValue, values, errors } = useFormikContext();
  const prevValues = usePrevious(values);
  const { t } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();
  const [isWeekWaterSourceOpen, setIsWeekWaterSourceOpen] = useState({});

  const dateFormat = "DD.MM.YYYY";
  const weeksGroupRefs = useRef([]);
  const weeksDiff = differenceInWeeks(values.harvestingDate, values.sowingDate);
  const weekWaterAmount =
    Math.floor((values.waterAmount / weeksDiff) * 100) / 100;
  const initialWeeks = [];

  if (weeksDiff) {
    for (let i = 0; i < weeksDiff; i += 1) {
      initialWeeks.push({
        id: addWeeks(values.sowingDate, i).toISOString(),
        waterAmount: weekWaterAmount,
        wateredArea: values.wateredArea,
        waterSource: values.waterSource,
        irrigationDate: addWeeks(values.sowingDate, i).toISOString(),
      });
    }
  }

  const changeWeekWaterSource = key => () => {
    setIsWeekWaterSourceOpen(p => ({
      ...p,
      [key]: true,
    }));
  };

  const saveWeekWaterSource = key => () => {
    const newIsWeekWaterSourceOpen = {
      ...isWeekWaterSourceOpen,
    };
    delete newIsWeekWaterSourceOpen[key];
    setIsWeekWaterSourceOpen(newIsWeekWaterSourceOpen);
  };

  const handleRemoveWeek = key => () => {
    setFieldValue(`weeks[${key}]`, {
      ...initialWeeks[key],
      waterAmount: 0,
      wateredArea: 0,
      waterSource: 0,
      irrigationDate: null,
    });
  };

  useEffect(() => {
    if (
      (prevValues && prevValues.sowingDate !== values.sowingDate) ||
      (prevValues && prevValues.harvestingDate !== values.harvestingDate)
    ) {
      setFieldValue("sameParameters", true);
    }
  }, [values.sowingDate, values.harvestingDate]);

  useEffect(() => {
    if (values.sameParameters && weeksDiff) {
      const weeks = Array.from(Array(weeksDiff).keys()).map((el, i) => {
        return {
          waterAmount: values.waterAmount,
          wateredArea: values.wateredArea,
          waterSource: values.waterSource,
          irrigationDate: addWeeks(values.sowingDate, i).toISOString(),
        };
      });
      setFieldValue("weeks", weeks);
    }
  }, [
    values.waterAmount,
    values.wateredArea,
    values.waterSource,
    values.sameParameters,
    weeksDiff,
  ]);

  const WaterSourceField = name => {
    return (
      <CftFormField name={name}>
        {({ value, helpers, meta }) => (
          <>
            <CftBoxWrapper>
              <CftBox
                isActive={value === "1"}
                onClick={() => {
                  helpers.setValue("1");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <StawIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t(
                    "cft:irrigationWaterFootprint.form.11",
                    "Naturalne jezioro/staw"
                  )}
                </p>
              </CftBox>
              <CftBox
                isActive={value === "2"}
                onClick={() => {
                  helpers.setValue("2");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <ZbiornikIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t("cft:irrigationWaterFootprint.form.12", "Zbiornik")}
                </p>
              </CftBox>
              <CftBox
                isActive={value === "3"}
                onClick={() => {
                  helpers.setValue("3");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <RzekaIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t(
                    "cft:irrigationWaterFootprint.form.13",
                    "Rzeka/stumień/rów"
                  )}
                </p>
              </CftBox>
              <CftBox
                isActive={value === "4"}
                onClick={() => {
                  helpers.setValue("4");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <ZbiornikSztucznyIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t(
                    "cft:irrigationWaterFootprint.form.14",
                    "Staw/zbiornik retencyjny/\nzbiornik magazynowy w\ngospodarstwie"
                  )}
                </p>
              </CftBox>
              <CftBox
                isActive={value === "5"}
                onClick={() => {
                  helpers.setValue("5");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <OdwiertIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t("cft:irrigationWaterFootprint.form.15", "Odwiert/studnia")}
                </p>
              </CftBox>
              <CftBox
                isActive={value === "6"}
                onClick={() => {
                  helpers.setValue("6");
                }}
                isInvalid={meta.touched && meta.error}
                variant="medium"
              >
                <KanalizacjaIcon className="cft-box__svg-fix" />
                <p className="cft-box__paragraph">
                  {t("cft:irrigationWaterFootprint.form.16", "Główne źródło")}
                </p>
              </CftBox>
            </CftBoxWrapper>
            <CftError
              isActive={meta.touched && meta.error}
              message={meta.error}
              textAlign="center"
            />
          </>
        )}
      </CftFormField>
    );
  };

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="medium">
        <CftLabel
          label={t(
            "cft:irrigationWaterFootprint.form.17",
            "Data siewu i zbioru"
          )}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t("cft:irrigationWaterFootprint.tooltips.3")}
            />
          }
        />
        <Row>
          <Col xs="12" sm="6">
            <CftDatePicker
              name="sowingDate"
              placeholder={t("cft:irrigationWaterFootprint.form.18")}
            />
          </Col>
          <Col xs="12" sm="6">
            <CftDatePicker
              name="harvestingDate"
              placeholder={t("cft:irrigationWaterFootprint.form.19")}
              scope={{
                min: values.sowingDate,
                interval: true,
              }}
            />
          </Col>
        </Row>
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:irrigationWaterFootprint.form.1")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:irrigationWaterFootprint.tooltips.1",
                "Określ metodę nawadniania dla każdego ze zdarzeń osobno"
              )}
            />
          }
        />
        <CftFormField name="method">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <PivotIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigationWaterFootprint.form.3", "Zraszacz")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <RaingunIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t(
                      "cft:irrigationWaterFootprint.form.4",
                      "Pistolet spryskujący"
                    )}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <FloodingIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigationWaterFootprint.form.5", "Zalewanie")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "4"}
                  onClick={() => {
                    helpers.setValue("4");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DripIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t(
                      "cft:irrigationWaterFootprint.form.6",
                      "Nawadnianie kropelkowe"
                    )}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:irrigation.form.17", "Głębkość źródła wody")} />
        <CftTextField name="depthSource" adornment="m" />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:irrigation.form.18", "Dystans od źródła")} />
        <CftUnitField name="distanceSource" units={getUnits(["m", "km"])} />
      </CftFormControl>
      <CftFormControl variant="medium">
        <CftFormField name="energySource">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <EnergyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.21", "Prąd")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DieselIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.22", "Diesel")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <GrawitacjaIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.23", "Grawitacja")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={!!values.harvestingDate && !!values.sowingDate}
      >
        <CftFormControl variant="medium" withoutMarginBottom>
          <CftLabel label={t("cft:irrigationWaterFootprint.form.21")} />
        </CftFormControl>
        <CftFormControl variant="small" withoutMarginTop>
          <CftFormField name="sameParameters">
            {({ value, helpers, meta }) => (
              <>
                <CftTank display="flex" justifyContent="space-around">
                  <CftButton
                    isActive={value}
                    onClick={() => {
                      helpers.setValue(true);
                      setFieldValue("weeks", []);
                    }}
                  >
                    {t("cft:yes", "TAK")}
                  </CftButton>
                  <CftButton
                    isActive={value === false}
                    onClick={() => {
                      helpers.setValue(false);
                      setFieldValue("weeks", initialWeeks);
                    }}
                  >
                    {t("cft:no", "Nie")}
                  </CftButton>
                </CftTank>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering isConditionFulfilled={values.sameParameters}>
        <CftFormControl variant="medium">
          <CftLabel
            label={t("cft:irrigationWaterFootprint.form.9", "Źródło wody")}
            // eslint-disable-next-line react/no-children-prop
            children={
              <CftTooltip
                content={t(
                  "cft:irrigationWaterFootprint.tooltips.2",
                  "Określ źródło wody dla wykonania tego zabiegu"
                )}
              />
            }
          />
          {WaterSourceField("waterSource")}
        </CftFormControl>
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:irrigationWaterFootprint.form.7", "Ilość wody")}
          />
          <CftTextField
            name="waterAmount"
            label={t("cft:irrigationWaterFootprint.form.20", "litrów")}
            adornment="l"
          />
        </CftFormControl>
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:irrigationWaterFootprint.form.8", "Podlany obszar")}
          />
          <CftTextField name="wateredArea" adornment="%" />
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering isConditionFulfilled={!values.sameParameters}>
        {values.weeks
          .filter(el => el.irrigationDate !== null)
          .map((week, key, arr) => {
            if (week.irrigationDate === null) return null;
            return (
              <CftQuestionGroup
                // eslint-disable-next-line no-return-assign
                ref={el => (weeksGroupRefs.current[key] = el)}
                key={key}
                label={`${t("cft:irrigationWaterFootprint.form.22")} ${key +
                  1}`}
                withoutMarginBottom={key + 1 === values.weeks.length}
                hasError={errors.weeks ? !!errors.weeks[key] : false}
                onRemove={arr.length - 1 === key && handleRemoveWeek(key)}
              >
                <CftFormControl>
                  <div className="water-footprint-week-scope">
                    <CftLabel
                      label={`${format(
                        week.irrigationDate,
                        dateFormat
                      )} - ${format(
                        addWeeks(week.irrigationDate, 1),
                        dateFormat
                      )}`}
                    />
                  </div>
                  <Row>
                    <Col>
                      <CftLabel
                        label={t("cft:irrigationWaterFootprint.form.7")}
                      />
                      <CftTextField
                        name={`weeks[${key}].waterAmount`}
                        label={t(
                          "cft:irrigationWaterFootprint.form.20",
                          "litrów"
                        )}
                        adornment="l"
                      />
                    </Col>
                    <Col>
                      <CftLabel
                        label={t(
                          "cft:irrigationWaterFootprint.form.8",
                          "Podlany obszar"
                        )}
                      />
                      <CftTextField
                        name={`weeks[${key}].wateredArea`}
                        adornment="%"
                      />
                    </Col>
                    <Col>
                      <CftLabel
                        label={t("cft:irrigationWaterFootprint.form.9")}
                      />
                      <CftTextField
                        name={`weeks[${key}].waterSource`}
                        value={mapWaterSource(week.waterSource, {
                          natural: t("cft:irrigationWaterFootprint.form.11"),
                          tank: t("cft:irrigationWaterFootprint.form.12"),
                          river: t("cft:irrigationWaterFootprint.form.13"),
                          mere: t("cft:irrigationWaterFootprint.form.14"),
                          well: t("cft:irrigationWaterFootprint.form.15"),
                          mainSpring: t("cft:irrigationWaterFootprint.form.16"),
                        })}
                      />
                      <button
                        className="water-source-change-btn"
                        type="button"
                        onClick={changeWeekWaterSource(key)}
                      >
                        {t("cft:irrigationWaterFootprint.form.24")}
                      </button>
                    </Col>
                  </Row>
                </CftFormControl>
                {isWeekWaterSourceOpen[key] && (
                  <CftFormControl variant="medium">
                    <CftLabel
                      label={t(
                        "cft:irrigationWaterFootprint.form.9",
                        "Źródło wody"
                      )}
                      // eslint-disable-next-line react/no-children-prop
                      children={
                        <CftTooltip
                          content={t(
                            "cft:irrigationWaterFootprint.tooltips.2",
                            "Określ źródło wody dla wykonania tego zabiegu"
                          )}
                        />
                      }
                    />
                    {WaterSourceField(`weeks[${key}].waterSource`)}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CftButton
                        variant="active"
                        onClick={saveWeekWaterSource(key)}
                      >
                        {t("cft:irrigationWaterFootprint.form.25")}
                      </CftButton>
                    </div>
                  </CftFormControl>
                )}
              </CftQuestionGroup>
            );
          })}
      </CftConditionalRendering>
    </Form>
  );
}

export default IrrigationBothCalcForm;

export function IrrigationBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft"]);

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.irrigation}
      validationSchema={Yup.object().shape({
        sowingDate: Yup.string().required(t("cft:validation.pickDate")),
        harvestingDate: Yup.string().required(t("cft:validation.pickDate")),
        method: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        waterSource: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        waterAmount: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(t("cft:validation.required", "Wpisz wartość"))
          .typeError(
            t(
              "cft:validation.numberTypeError",
              "Wpisz poprawną wartośc liczbową"
            )
          )
          .min(1, "Wartość minimalna to 1"),
        wateredArea: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(t("cft:validation.required", "Wpisz wartość"))
          .typeError(
            t(
              "cft:validation.numberTypeError",
              "Wpisz poprawną wartośc liczbową"
            )
          )
          .min(1, "Wartość minimalna to 1"),
        depthSource: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(t("cft:validation.required", "Wpisz wartość"))
          .typeError(
            t(
              "cft:validation.numberTypeError",
              "Wpisz poprawną wartośc liczbową"
            )
          )
          .min(0, t("cft:validation.numberMinError", "Wartość minimalna to 0")),
        distanceSource: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(
              0,
              t("cft:validation.numberMinError", "Wartość minimalna to 0")
            ),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        energySource: Yup.string()
          .transform((_, v) => v.replace(/\s/g, ""))
          .required(t("cft:validation.required", "Wpisz wartość")),
        sameParameters: Yup.boolean()
          .nullable()
          .required(
            t(
              "cft:validation.requiredOption",
              t("cft:validation.requiredOption", "Wybierz opcję")
            )
          ),
        weeks: Yup.array().when("sameParameters", {
          is: false,
          then: Yup.array().of(
            Yup.object().shape({
              waterAmount: Yup.number()
                // .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                ),
              wateredArea: Yup.number()
                // .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                ),
              waterSource: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
            })
          ),
          otherwise: Yup.array(),
        }),
      })}
      onSubmit={values => {
        saveBothCalc({ irrigation: values });
        Promise.all([
          farmlandsAPI.irrigationWaterFootprintValidation(
            waterFootprintMapValues(values)
          ),
          farmlandsAPI.irigationValidation(mapValues(values)),
        ]).then(([waterFootprintData, { data }]) => {
          saveBothCalcReport({
            irigation: {
              ...waterFootprintData.data,
              ...data,
            },
          });
          history.push(routes.FARMLANDS.BOTH_CALC.CARBON_CHANGES);
        });
      }}
    >
      {children}
    </Formik>
  );
}

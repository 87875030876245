/* eslint-disable import/no-duplicates */
// Soil Texture Icons
import { ReactComponent as ClayIcon } from "../../../../../assets/icons/cft/glinowa.svg";
import { ReactComponent as MudIcon } from "../../../../../assets/icons/cft/mulowa.svg";
import { ReactComponent as SandyIcon } from "../../../../../assets/icons/cft/piaskowa.svg";

// Soil type Icons
import { ReactComponent as FineGrained } from "../../../../../assets/icons/cft/piaskowa.svg";
import { ReactComponent as MediumGrain } from "../../../../../assets/icons/cft/srednio.svg";
import { ReactComponent as CoarseGrained } from "../../../../../assets/icons/cft/grubo.svg";

// Soil Drainage Icons
import { ReactComponent as WeakIcon } from "../../../../../assets/icons/cft/slaby.svg";
import { ReactComponent as GoodIcon } from "../../../../../assets/icons/cft/dobry.svg";

// Soil pH Icons
import { ReactComponent as PhGreanIcon } from "../../../../../assets/icons/cft/ph_green.svg";
import { ReactComponent as PhPurpleIcon } from "../../../../../assets/icons/cft/ph_purple.svg";
import { ReactComponent as PhRedIcon } from "../../../../../assets/icons/cft/ph_red.svg";
import { ReactComponent as PhYellowIcon } from "../../../../../assets/icons/cft/ph_yellow.svg";

export function waterFootprintMapValues(values) {
  return {
    soil_type_id: Number(values.soilTypeAdvanced),
    soil_moisture_at_sowing_id: Number(values.averageSoilMoisture),
  };
}

export function mapValues(values) {
  return {
    texture_id: Number(values.soilTexture),
    organic_matter_id: Number(values.soilMatter),
    moisture_id: Number(values.averageSoilMoisture),
    drainage_id: Number(values.soilDrainage),
    ph_id: Number(values.ph),
  };
}

export const SOIL_TYPE = [
  {
    id: "1",
    Icon: FineGrained,
    label: {
      i18n: "cft:soilWaterFootprint.form.2",
      default: "Drobnoziarniste",
    },
  },
  {
    id: "2",
    Icon: MediumGrain,
    label: {
      i18n: "cft:soilWaterFootprint.form.3",
      default: "Średnioziarniste",
    },
  },
  {
    id: "3",
    Icon: CoarseGrained,
    label: {
      i18n: "cft:soilWaterFootprint.form.4",
      default: "Gruboziarniste",
    },
  },
];

export const SOIL_TEXTURE_TYPE = [
  {
    id: "1",
    Icon: ClayIcon,
    label: {
      i18n: "cft:soil.form.3",
      default: "Gliniasta",
    },
  },
  {
    id: "2",
    Icon: MudIcon,
    label: {
      i18n: "cft:soil.form.4",
      default: "Mułowa",
    },
  },
  {
    id: "3",
    Icon: SandyIcon,
    label: {
      i18n: "cft:soil.form.5",
      default: "Piaszczysta",
    },
  },
];

export const SOIL_TEXTURE_TYPE_ADVANCED = [
  {
    id: "201",
    label: {
      i18n: "cft:soilWaterFootprint.form.5",
      default: "Piasek",
    },
  },
  {
    id: "202",
    label: {
      i18n: "cft:soilWaterFootprint.form.6",
      default: "Piasek gliniasty",
    },
  },
  {
    id: "203",
    label: {
      i18n: "cft:soilWaterFootprint.form.7",
      default: "Glina piaszczysta",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:soilWaterFootprint.form.8",
      default: "Glina",
    },
  },
  {
    id: "103",
    label: {
      i18n: "cft:soilWaterFootprint.form.9",
      default: "Glina pylasta",
    },
  },
  {
    id: "206",
    label: {
      i18n: "cft:soilWaterFootprint.form.10",
      default: "Pył",
    },
  },
];

export const SOIL_MATTER_TYPES = [
  {
    value: 1,
    label: "< 1.72",
  },
  {
    value: 2,
    label: "1.72 - 5.16",
  },
  {
    value: 3,
    label: "5.16 - 10.32",
  },
  {
    value: 4,
    label: "> 10.32",
  },
];

export const AVERAGE_SOIL_MOISTURE_TYPES = [
  {
    id: "1",
    label: {
      i18n: "cft:soil.form.0",
      default: "Wodnista",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:soil.form.11",
      default: "Sucha",
    },
  },
];

export const SOIL_DRAINAGE_TYPES = [
  {
    id: "1",
    Icon: GoodIcon,
    label: {
      i18n: "cft:soil.form.14",
      default: "Dobry",
    },
  },
  {
    id: "2",
    Icon: WeakIcon,
    label: {
      i18n: "cft:soil.form.15",
      default: "Słaby",
    },
  },
];

export const PH_TYPES = [
  {
    id: "1",
    Icon: PhRedIcon,
    label: {
      i18n: "cft:soil.form.18",
      default: "< 5.5\n Silnie kwaśna",
    },
  },
  {
    id: "2",
    Icon: PhYellowIcon,
    label: {
      i18n: "cft:soil.form.19",
      default: "5.5 - 7.3\n Kwaśna",
    },
  },
  {
    id: "3",
    Icon: PhGreanIcon,
    label: {
      i18n: "cft:soil.form.20",
      default: "7.3 - 8.5\n Obojętna",
    },
  },
  {
    id: "4",
    Icon: PhPurpleIcon,
    label: {
      i18n: "cft:soil.form.21",
      default: "> 8.5\n Zasadowa",
    },
  },
];

import { isNil } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { CategoryLabel } from "../index";

export const CategoryRender = ({ id, defaultCategory }) => {
  const { t } = useTranslation(["articlesMeta", "report", "articlesMeta"]);

  if (isNil(id)) {
    return null;
  }

  if (!isNil(defaultCategory)) {
    return (
      <CategoryLabel id={defaultCategory}>
        <span>#{t(`articlesMeta:categories.${defaultCategory}`)}</span>
      </CategoryLabel>
    );
  }

  return id < 18 ? (
    <CategoryLabel id={id}>
      <span>#{t(`report:categories.${id}`)}</span>
    </CategoryLabel>
  ) : (
    <CategoryLabel id={id}>
      <span>#{t(`articlesMeta:categories.${id}`)}</span>
    </CategoryLabel>
  );
};

import { format, parse } from "date-fns";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { sprintf } from "sprintf-js";

import { sliceString } from "../../../services/helpers/utilities";
import { NotMobile } from "../Responsive/Responsive";

const Event = ({
  t,
  slug,
  thumb,
  location_title,
  started_at,
  title,
  summary,
}) => {
  const parsedDate = parse(started_at);
  return (
    <div className="c-event">
      <div className="c-event__details">
        <div className="c-event__details__date">
          {format(parsedDate, "DD.MM.YYYY")} {location_title}
        </div>
        <h3>{sliceString(title, 57)}</h3>
        <p>{sliceString(summary, 122)}</p>
        <Link
          className="c-btn-link"
          to={sprintf(t("urlsNames:events.single"), slug)}
        >
          {t("widgets:events.seeMore")}
        </Link>
      </div>
      {thumb && (
        <NotMobile>
          <div
            className="c-event__cover"
            style={{
              backgroundImage: `url(${thumb.file})`,
            }}
          />
        </NotMobile>
      )}
    </div>
  );
};

Event.propTypes = {};
Event.defaultProps = {};

export default withTranslation(["widgets", "urlsNames"])(Event);

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import "./ShareButtons.scss";
import classNames from "classnames";
import createShareButton from "./ShareButton";
import { objectToGetParams } from "../../../services/helpers/utilities";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/cft/social-twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/cft/social-facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/cft/social-linkedin.svg";
import { ReactComponent as MessengerIcon } from "../../../assets/icons/cft/social-messenger.svg";
import { ReactComponent as MailIcon } from "../../../assets/icons/envelope.svg";

export function facebookLink(url, { quote, hashtag }) {
  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: url,
    quote,
    hashtag,
  })}`;
}

export function messengerLink(url, { link }) {
  return `fb-messenger://share${objectToGetParams({
    link,
    app_id: "278394263278416",
  })}`;
}

export function linkedinLink(url, { title, description }) {
  return `https://linkedin.com/shareArticle${objectToGetParams({
    url,
    title,
    summary: description,
  })}`;
}

export function twitterLink(url, { title, via, hashtags = [] }) {
  return `https://twitter.com/share${objectToGetParams({
    url,
    text: title,
    via,
    hashtags: hashtags.join(","),
  })}`;
}

export function mailBody(url, {title}) {
  return `mailto:${objectToGetParams({
    body: url,
    subject: title,
  })}`;
}

export const FacebookShareButton = createShareButton(
  "facebook",
  facebookLink,
  props => {
    return {
      quote: props.quote,
      hashtag: props.hashtag,
    };
  }
);

export const MessengerShareButton = createShareButton(
  "messenger",
  messengerLink,
  props => {
    return {
      link: props.link,
    };
  }
);

export const MailShareButton = createShareButton(
  "mail",
  mailBody,
  props => {
    return {
      url: props.url,
      title: props.title,
    };
  }
);

export const LinkedinShare = createShareButton(
  "linkedin",
  linkedinLink,
  props => ({
    title: props.title,
    description: props.description,
  }),
  {
    title: PropTypes.string,
    description: PropTypes.string,
  }
);

export const TwitterShare = createShareButton(
  "twitter",
  twitterLink,
  props => ({
    hashtags: props.hashtags,
    title: props.title,
    via: props.via,
  }),
  {
    hashtags: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    via: PropTypes.string,
  }
);

class ShareButtons extends PureComponent {
  render() {
    const { shareUrl, title, hashtag, t, inline } = this.props;

    const pageTitle = title || window.document.title;
    const pageDescription = document.querySelector("meta[name='description']")
      ? document
          .querySelector("meta[name='description']")
          .getAttribute("content")
      : "";
    const pageUrl = shareUrl || window.location.href;

    return (
      <div
        className={classNames("share-article", {
          "share-article--inline": inline,
        })}
      >
        <FacebookShareButton url={pageUrl} hashtag={hashtag}>
          <div className="share-icon">
            <FacebookIcon />
          </div>
        </FacebookShareButton>
        {
          // Works only on mobile with Messenger app installed
          window && window.innerWidth <= 768 &&
          <MessengerShareButton link={pageUrl}>
            <div className="share-icon">
              <MessengerIcon />
            </div>
          </MessengerShareButton>
        }
        <LinkedinShare
          url={pageUrl}
          title={pageTitle}
          description={pageDescription}
        >
          <div className="share-icon">
            <LinkedinIcon />
          </div>
        </LinkedinShare>
        <TwitterShare url={pageUrl} title={pageTitle}>
          <div className="share-icon">
            <TwitterIcon />
          </div>
        </TwitterShare>
        <MailShareButton url={pageUrl} title={pageTitle} >
          <div className="share-icon">
            <MailIcon />
          </div>
        </MailShareButton>
      </div>
    );
  }
}

export default withTranslation(["widgets"])(ShareButtons);

/* eslint-disable no-undef */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import "./AgroCategories.scss";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { AgroCategoriesEl } from "./AgroCategoriesEl";
import { getArticlesCategory } from "../../../services/redux/articles/articlesCategories/reducer";

class AgroCategories extends Component {
  constructor(props) {
    super(props);

    this.select = React.createRef();
  }

  componentDidMount() {
    if (!this.props.agroCats.length) {
      this.props.fetchCategories();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderSingleElement(active, id, colour) {
    return (
      <li
        // onClick={this.toggleSelectMenu}
        className={`c-categories-li${active ? " c-categories-li--active" : ""}`}
      >
        <AgroCategoriesEl id={id} color={colour} active={active} />
      </li>
    );
  }

  renderCategories() {
    const { active, agroCats, t } = this.props;
    return agroCats.map(({ id, colour }) => {
      const slug = t(`categories.slugs.${id}`);
      // eslint-disable-next-line no-underscore-dangle
      const _active = parseInt(active) ? active === id : active === slug;
      return this.renderSingleElement(_active, id, colour);
    });
  }

  toggleSelectMenu = () => {
    const { current } = this.select;
    const dropdownMenu = [...current.children].find(({ classList }) =>
      classList.contains("c-categories")
    );
    if (dropdownMenu) {
      const { classList } = dropdownMenu;
      return classList.contains("isOpen")
        ? (classList.remove("isOpen"), false)
        : (classList.add("isOpen"), true);
    }
    return undefined;
  };

  render() {
    const { active, agroCats, t } = this.props;
    const activeElement = agroCats.find(({ id }) => {
      if (parseInt(active)) {
        return id === active;
      }
      const slug = t(`categories.slugs.${id}`);
      return slug === active;
    }) || { id: "all", type: "all" };
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div
              onClick={this.toggleSelectMenu}
              className="c-categories-select"
              ref={this.select}
            >
              <div className="c-categories__el--select">
                <AgroCategoriesEl
                  id={activeElement.id}
                  type={activeElement.type}
                  colour={activeElement.colour}
                  active
                />
              </div>
              <ul className="c-categories">
                <li
                  // onClick={this.toggleSelectMenu}
                  className={`c-categories-li${
                    !active ? " c-categories-li--active" : ""
                  }`}
                >
                  <AgroCategoriesEl id="all" type="all" active={!active} />
                </li>
                {this.renderCategories()}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  alertUserData: state.user,
  agroCats: state.articles.articlesCategories.agro,
});

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(getArticlesCategory()),
});

export default compose(
  withTranslation("categories"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AgroCategories);

import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftCoopSection,
  CftWarningBox,
  CftCalculationPeriodInfo,
  CftTooltip,
} from "../../components";
import TrashIcon from "../../components/icons/TrashIcon";
import ArrowRight from "../../components/icons/ArrowRight";
import PickupsFields from "./PickupsFields";
import FeedComponentsFields from "./FeedComponentsFields";
import PlusIcon from "../../components/icons/PlusIcon";

export default function ProductionCyclesFields() {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      {values.production_cycles.map((_, key) => (
        <CftCoopSection isDefaultOpen>
          {({ isOpen, setOpen }) => {
            if (isOpen) {
              return (
                <div className="cft-paper mb-4">
                  <CftTank display="block">
                    <CftFormControl alignLeft>
                      <h2
                        className="cft-section-title"
                        style={{
                          textAlign: "left",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {t(
                            "cftChickenBroiler:productionCyclesFields.title",
                            "Cykl produkcyjny"
                          )}{" "}
                          {key + 1}
                          <ArrowRight
                            onClick={() => setOpen(false)}
                            style={{
                              transform: "rotate(-90deg)",
                              marginLeft: "32px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                        <TrashIcon
                          onClick={() => {
                            setFieldValue(
                              "production_cycles",
                              values["production_cycles"].filter(
                                (_, _key) => _key !== key
                              )
                            );
                          }}
                          style={{ marginRight: "-32px", cursor: "pointer" }}
                        />
                      </h2>
                      <CftLabel
                        isFullWidth
                        innerStyle={{
                          display: "inline-block",
                          maxWidth: "calc(100% - 70px)",
                        }}
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.subtitle",
                          "Podaj informacje o cyklu produkcyjnym. Możesz podać maksymalnie 4 cykle produkcyjne w jednym kurniku referencyjnym."
                        )}
                        className="mb-3"
                      >
                        <CftTooltip
                          content={t(
                            "cftChickenBroiler:tooltip.productionCyclesFields",
                            "Cykl produkcyjny to ilość dni w którym brojlery osiągają swoją docelową masę tuszy oraz długość przerwy technologicznej (okresu pomiędzy wstawieniami piskląt do kurników). Możesz zdefiniować od 1-4 cykli produkcyjnych w jednym kurniku. Pamiętaj! Cykl produkcyjny (lub suma cykli produkcyjnych  jeżeli deklarujesz więcej niż jeden cykl) nie może być dłuższy niż okres obliczeniowy zdefiniowany w pierwszym kroku formularza. Nie martw się, jeżeli twój cykl produkcyjny lub suma wielu cykli nie jest wystarczająco długa aby wypełnić cały okres obliczeniowy, kalkulator wypełni brakujące dni za Ciebie aby kalkulacja mogla zostać przeprowadzona pomyślnie"
                          )}
                        />
                      </CftLabel>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.fields.production_cycle_lengthLabel",
                          "Długość cyklu produkcyjnego (max. 49 dni)"
                        )}
                      />
                      <CftTextField
                        isRounded
                        name={`production_cycles[${key}].production_cycle_length`}
                        placeholder={t(
                          "cftChickenBroiler:productionCyclesFields.fields.production_cycle_lengthPlaceholer",
                          "Wpisz ilość dni"
                        )}
                        isAlternative
                        mask="00"
                      />
                    </CftFormControl>
                    <CftFormControl alignLeft>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.fields.technological_break_lengthLabel",
                          "Długość przerwy technologicznej (dni)"
                        )}
                      >
                        <CftTooltip
                          content={t(
                            "cftChickenBroiler:tooltip.technological_break_length",
                            "Przerwa technologiczna to ilość dni między wstawieniami przeznaczona na czynności związane z oczyszczeniem kurnika i przygotowaniem do kolejnego cyklu produkcyjnego"
                          )}
                        />
                      </CftLabel>
                      <CftTextField
                        isRounded
                        name={`production_cycles[${key}].technological_break_length`}
                        placeholder={t(
                          "cftChickenBroiler:productionCyclesFields.fields.technological_break_lengthPlaceholder",
                          "Wpisz długość"
                        )}
                        isAlternative
                        mask="00"
                      />
                    </CftFormControl>
                    <CftFormControl alignLeft>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.fields.repetitionsLabel",
                          "Ile razy powtarza się ten cykl?"
                        )}
                      >
                        <CftTooltip
                          content={t(
                            "cftChickenBroiler:tooltip.repetitions",
                            "Podaj ile razy powtarzasz identyczny cykl produkcyjny w tym kurniku referencyjnym w wybranym okresie obliczeniowym? Jeżeli twój cykl produkcyjny lub suma wielu cykli nie jest wystarczająco długa aby wypełnić cały okres obliczeniowy, kalkulator wypełni brakujące dni za Ciebie aby kalkulacja mogla zostać przeprowadzona pomyślnie."
                          )}
                        />
                      </CftLabel>
                      <CftTextField
                        isRounded
                        name={`production_cycles[${key}].repetitions`}
                        placeholder={t(
                          "cftChickenBroiler:productionCyclesFields.fields.repetitionsPlacehodler",
                          "Wpisz ilość"
                        )}
                        isAlternative
                        mask="000"
                      />
                      <CftCalculationPeriodInfo />
                    </CftFormControl>
                    <CftFormControl alignLeft>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.fields.chicken_numberLabel",
                          "Całkowita ilość kurcząt w cyklu"
                        )}
                      >
                        <CftTooltip
                          content={t(
                            "cftChickenBroiler:tooltip.chicken_number",
                            "Podaj ile kurcząt zostało umieszczonych w kurniku w tym cyklu produkcyjnym"
                          )}
                        />
                      </CftLabel>
                      <CftTextField
                        isRounded
                        name={`production_cycles[${key}].chicken_number`}
                        placeholder={t(
                          "cftChickenBroiler:productionCyclesFields.fields.chicken_numberPlaceholder",
                          "Wpisz ilość sztuk"
                        )}
                        isAlternative
                        mask="0000000"
                      />
                    </CftFormControl>
                    <CftFormControl alignLeft>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftChickenBroiler:productionCyclesFields.fields.falls_percentLabel",
                          "Ilość upadków na cykl produkcyjny (%)"
                        )}
                      >
                        <CftTooltip
                          content={t(
                            "cftChickenBroiler:tooltip.falls_percent",
                            "Procent wstawionych kurcząt, które uległy upadkowi. Zmień wartość dla swojej hodowli"
                          )}
                        />
                      </CftLabel>
                      <CftTextField
                        isRounded
                        name={`production_cycles[${key}].falls_percent`}
                        placeholder="(%)"
                        isAlternative
                        mask="{**}"
                      />
                    </CftFormControl>
                    <PickupsFields productionCyclesId={key} />
                    <FeedComponentsFields productionCyclesId={key} />
                  </CftTank>
                </div>
              );
            }

            return (
              <div className="cft-paper mb-4">
                <CftTank display="block">
                  <CftFormControl alignLeft withoutMarginBottom>
                    <h2
                      className="cft-section-title"
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: 0,
                      }}
                    >
                      <span>
                        {t(
                          "cftChickenBroiler:productionCyclesFields.title",
                          "Cykl produkcyjny"
                        )}{" "}
                        {key + 1}
                        <ArrowRight
                          onClick={() => setOpen(true)}
                          style={{
                            transform: "rotate(90deg)",
                            marginLeft: "32px",
                            cursor: "pointer",
                          }}
                        />
                      </span>
                      <TrashIcon
                        onClick={() => {
                          setFieldValue(
                            "production_cycles",
                            values["production_cycles"].filter(
                              (_, _key) => _key !== key
                            )
                          );
                        }}
                        style={{ marginRight: "-32px", cursor: "pointer" }}
                      />
                    </h2>
                  </CftFormControl>
                </CftTank>
              </div>
            );
          }}
        </CftCoopSection>
      ))}
      {values["production_cycles"].length < 4 ? (
        <div className="cft-paper">
          <CftTank
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <CftButton
              variant="active"
              isRounded
              isAlternative
              isNegative
              isFullwidth
              onClick={() => {
                setFieldValue("production_cycles", [
                  ...values["production_cycles"],
                  {
                    production_cycle_length: "",
                    technological_break_length: "",
                    repetitions: "",
                    chicken_number: "",
                    falls_percent: "2",
                    pickups: [],
                    pickup: "0",
                    feed_components: [],
                  },
                ]);
              }}
            >
              <PlusIcon style={{ marginRight: "12px" }} />
              {t(
                "cftChickenBroiler:productionCyclesFields.fields.submit",
                "dodaj CYKL PRODUKCYJNY"
              )}
            </CftButton>
          </CftTank>
        </div>
      ) : (
        <CftWarningBox text="Zdeklarowałeś maksymalną ilość cykli produkcyjnych w tym kurniku referencyjnym" />
      )}
    </>
  );
}

import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftNumberField,
  CftTooltip,
} from "../../../../components";

// Soil categories
import { ReactComponent as SeedTreatmentIcon } from "../../../../../assets/icons/cft/ochrona_nasiona.svg";
import { ReactComponent as SoilTreatmentIcon } from "../../../../../assets/icons/cft/ochrona_gleba.svg";
import { ReactComponent as PostEmergenceIcon } from "../../../../../assets/icons/cft/ochrona_powzejsciu.svg";

function PesticideFields({ id, name }) {
  const { t } = useTranslation(["cft"]);
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:fertilizers.form.21", "Kategoria")} />
        <CftFormField name={`${name}[${id}].category`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    setFieldValue(`${name}[${id}].applicationRate`, undefined);
                    setFieldValue(`${name}[${id}].percentageRate`, undefined);
                    setFieldValue(`${name}[${id}].events`, "1");
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <SeedTreatmentIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fertilizers.form.22", " Zaprawianie nasion")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    setFieldValue(`${name}[${id}].events`, undefined);
                    setFieldValue(`${name}[${id}].applicationRate`, "");
                    setFieldValue(`${name}[${id}].percentageRate`, "");
                    helpers.setValue("2");
                  }}
                  variant="medium"
                  isInvalid={meta.touched && meta.error}
                >
                  <SoilTreatmentIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fertilizers.form.23", "Obróbka gleby")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    setFieldValue(`${name}[${id}].applicationRate`, undefined);
                    setFieldValue(`${name}[${id}].percentageRate`, undefined);
                    setFieldValue(`${name}[${id}].events`, "1");
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <PostEmergenceIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fertilizers.form.24", "Po wzejściu")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={
          ["1", "3"].indexOf(values[name][id].category) !== -1
        }
      >
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:fertilizers.form.25", "Zdarzenia")}
            // eslint-disable-next-line react/no-children-prop
            children={
              <CftTooltip
                content={t(
                  "cft:fertilizers.tooltips.5",
                  "Wybierz ilość powyżej wskazanego zdarzenia (kategorii)"
                )}
              />
            }
          />
          <CftNumberField name={`${name}[${id}].events`} min={0} />
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering
        isConditionFulfilled={values[name][id].category === "2"}
      >
        <>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fertilizers.form.27", "Dawka stosowania")}
            />
            <CftTextField
              name={`${name}[${id}].applicationRate`}
              adornment="kg/h"
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fertilizers.form.28", "Składnik aktywny %")}
            />
            <CftTextField
              name={`${name}[${id}].percentageRate`}
              adornment="%"
            />
          </CftFormControl>
        </>
      </CftConditionalRendering>
    </>
  );
}

export default PesticideFields;

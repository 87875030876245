import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  CftTank,
  CftButton,
  CftFormField,
  CftBox,
  CftClearForm,
  CftFormErrorFocus,
} from "../../components";

import routes from "../../utils/cftRoutes";
import useCftPigsValues from "../../utils/useCftPigsValues";
import CreateHerdField from "./CreateHerdField";
import ArrowRight from "../../components/icons/ArrowRight";
import TrashIcon from "../../components/icons/TrashIcon";

export default function PigsHerdFeedsForm() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const history = useHistory();
  const { store, updateStore, getProductionGroupTypeName } = useCftPigsValues();

  const isNextStepAvailable =
    store.herd_reference.production_groups.length > 0 &&
    store.herd_reference.production_groups.filter(coop => coop.isValidate)
      .length === store.herd_reference.production_groups.length;

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2
          className="cft-section-title mb-3 cft-max-wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t(
            "cftPigs:pigsHerdFeedsForm.title",
            "Informacje o stadzie i paszach"
          )}
        </h2>
        <Formik
          initialValues={store.herd_reference}
          onSubmit={() => {
            updateStore({
              herd_reference: {
                ...store.herd_reference,
                isValidate: true,
              },
            });
            history.push(routes.PIGS.EMISSIONS);
          }}
        >
          <Form style={{ width: "100%" }}>
            <h4 className="cft-section-subtitle mb-4 mt-4">
              {t(
                "cftPigs:pigsHerdFeedsForm.subtitle",
                "Wybierz grupy produkcyjne trzody występujące w Twoim stadzie"
              )}
            </h4>
            <CftFormField name="production_groups">
              {({ value, helpers }) =>
                !!value.length && (
                  <>
                    {value.map(({ production_group_type, isValidate }, key) => (
                      <div
                        key={`${production_group_type}_${key}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="cft-paper mb-2"
                      >
                        <CftBox
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          variant="fullWidth"
                          isActive={isValidate}
                          onClick={() => {
                            history.push(`${routes.PIGS.COOPS}/${key}`);
                          }}
                        >
                          <span>
                            {getProductionGroupTypeName(production_group_type)}
                          </span>
                          <ArrowRight />
                        </CftBox>
                        <TrashIcon
                          onClick={() => {
                            updateStore({
                              herd_reference: {
                                ...store.herd_reference,
                                production_groups: value.filter(
                                  (_, _key) => _key !== key
                                ),
                              },
                            });
                            helpers.setValue(
                              value.filter((_, _key) => _key !== key)
                            );
                          }}
                          style={{
                            marginTop: "16px",
                            marginLeft: "16px",
                            marginRight: "-32px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              }
            </CftFormField>
            <CreateHerdField />
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
                disabled={!isNextStepAvailable}
              >
                {t(
                  "cftTurkey:chickenBroilerCoopsForm.submit",
                  "podaj informacje o emisjach"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="pigs" />
    </div>
  );
}

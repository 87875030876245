import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftTank,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import IrrigationBothCalcForm, {
  IrrigationBothCalcFormContext,
} from "../../../forms/farmlands/bothCalc/IrrigationBothCalcForm/IrrigationBothCalc.form";

function IrrigationBothCalcPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveBothCalc, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={56}
          cropName={meta.title.bothCalc}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="bothCalc" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveBothCalc({
                    irrigation: values,
                  });
                  history.push(routes.FARMLANDS.BOTH_CALC.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:irrigationWaterFootprint.title", "Irygacja")}
          onBack={() =>
            history.push(routes.FARMLANDS.BOTH_CALC.FUEL_AND_ENERGY)
          }
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:irrigationWaterFootprint.title", "Irygacja")}</h3>
            <p>
              {t(
                "cft:irrigationWaterFootprint.subtitle",
                "W tej sekcji wpisz wszystkie zabiegi związane z nawadnianiem uprawy. Możesz dodać wiele wpisów z różnymi metodami nawadniania i źródłami wody."
              )}
            </p>
          </>
        }
      >
        <IrrigationBothCalcForm />
        <CftReportProgress type="bothCalc" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <IrrigationBothCalcFormContext>
      <IrrigationBothCalcPage />
    </IrrigationBothCalcFormContext>
  );
};

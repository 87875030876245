import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { format, addMonths } from "date-fns";
import Base64FontBNPPSans from "../../../components/common/CustomPdfFont/Base64FontBNPPSans";
import Base64FontBNPPSansCond from "../../../components/common/CustomPdfFont/Base64FontBNPPSansCond";
import Base64LogoAgronomist from "../../../components/svg-icons/Base64LogoAgronomist";
import Base64LogoBNP from "../../../components/svg-icons/Base64LogoBNP";
import { MONTHS } from "../constants";
import { installmentTypeToString, timespanTypeToString } from "./dataHelpers";
import { formatAmount } from "./formatters";

export const configurePdfTable = (columns = [], rows = []) => {
  const fontFamily = "BNPPSans";

  return {
    body: rows,
    bodyStyles: { valign: "top" },
    head: columns,
    headStyles: {
      font: fontFamily,
      fontSize: 10,
      fontStyle: "normal",
      halign: "center",
      fillColor: [0, 150, 94],
    },
    styles: {
      cellPadding: 2,
      font: fontFamily,
      fontSize: 12,
      halign: "justify",
      rowPageBreak: "auto",
    },
    theme: "grid",
  };
};

export const generatePdfReport = ({ input = null, t, language }) => {
  // eslint-disable-next-line
  const doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
  });

  doc.addFileToVFS("BNPPSans-normal.ttf", Base64FontBNPPSans);
  doc.addFileToVFS("BNPPSansCondv2-normal.ttf", Base64FontBNPPSansCond);
  doc.addFont("BNPPSans-normal.ttf", "BNPPSans", "normal");
  doc.addFont("BNPPSansCondv2-normal.ttf", "BNPPSansCondv2", "normal");
  doc.setFont("BNPPSans", "normal");
  doc.setProperties({ title: t("pdfTitle") });

  const {
    hasSubvention,
    computedCreditAmount,
    subventionAmount,
    installmentType,
    capitalInstallmentFrequency,
    interestInstallmentFrequency,
    creditRate,
    repaymentTimespan,
    generatedOffer,
    subventionReceivingYear,
    subventionReceivingMonth,
  } = input;

  const overviewTableRows = [
    [t("tabelLabels.6"), formatAmount({ amount: computedCreditAmount })],
    [t("pdf.0"), repaymentTimespan],
    [
      t("pdf.2"),
      t(timespanTypeToString({ type: capitalInstallmentFrequency })),
    ],
    [
      t("pdf.3"),
      t(timespanTypeToString({ type: interestInstallmentFrequency })),
    ],
    [t("tabelLabels.9"), t(installmentTypeToString({ type: installmentType }))],
    [t("tabelLabels.8"), `${creditRate}%`],
  ];

  if (hasSubvention) {
    const chosenMonth = Object.values(MONTHS).find(
      month => month.value === subventionReceivingMonth
    );

    overviewTableRows.splice(
      1,
      0,
      [t("tabelLabels.3"), formatAmount({ amount: subventionAmount })],
      [t("tabelLabels.4"), chosenMonth && t(chosenMonth.label)],
      [t("tabelLabels.5"), subventionReceivingYear]
    );
  }

  const simulationTablecolumns = [
    [
      [t("pdf.4")],
      [t("pdf.5")],
      [t("pdf.9")],
      [t("pdf.6")],
      [t("pdf.7")],
      [t("pdf.1")],
    ],
  ];

  const simulationTableRows = [];

  const now = new Date();

  generatedOffer.forEach(entry => {
    const chosenMonth = Object.values(MONTHS).find(
      month => month.value === Number(format(addMonths(now, entry.month), "M"))
    );

    simulationTableRows.push([
      [format(addMonths(now, entry.month), "YYYY")],
      [t(chosenMonth.label)],
      [formatAmount({ amount: entry.debt, withCurrency: false })],
      [
        entry.capitalInstallmentAmount === "0.00"
          ? "-"
          : formatAmount({
              amount: entry.capitalInstallmentAmount,
              withCurrency: false,
            }),
      ],
      [
        formatAmount({
          amount: entry.interestInstallmentAmount,
          withCurrency: false,
        }),
      ],
      [
        formatAmount({
          amount: entry.capitalWithInterestInstallmentAmount,
          withCurrency: false,
        }),
      ],
    ]);
  });

  const interestInstallmentSum = generatedOffer.reduce(
    (prev, curr) => prev + parseFloat(curr.interestInstallmentAmount),
    0
  );
  const capitalInstallmentSum = generatedOffer.reduce(
    (prev, curr) => prev + parseFloat(curr.capitalInstallmentAmount),
    0
  );

  const summaryTableRows = [
    [
      [t("pdf.8")],
      [
        formatAmount({
          amount: Math.round(capitalInstallmentSum),
          withCurrency: false,
        }),
      ],
      [formatAmount({ amount: interestInstallmentSum, withCurrency: false })],
      [
        formatAmount({
          amount: capitalInstallmentSum + interestInstallmentSum,
          withCurrency: false,
        }),
      ],
    ],
  ];

  const spacerHeight = 10;
  const headerTextLocationX = language === "pl" ? 35 : 50;
  const headerTextLocationY = 40;
  const summaryTableLocationY = headerTextLocationY + spacerHeight;

  const columnConfig = {
    0: { cellWidth: 15, halign: "right" },
    1: { cellWidth: 30, halign: "right" },
    2: { cellWidth: 35, halign: "right" },
    3: { cellWidth: 35, halign: "right" },
    4: { cellWidth: 35, halign: "right" },
    5: { cellWidth: 32, halign: "right", overflow: "linebreak" },
  };

  doc.setFontSize(20);
  doc.addImage(Base64LogoBNP, "png", 15, 13, 40, 8);
  doc.addImage(Base64LogoAgronomist, "png", 150, 13, 44, 7);
  doc.text(
    t("pdfTitle").toUpperCase(),
    headerTextLocationX,
    headerTextLocationY
  );
  doc.autoTable({
    ...configurePdfTable([], overviewTableRows),
    startY: summaryTableLocationY,
    columnStyles: {
      0: { cellWidth: 100 },
      1: { cellWidth: "auto" },
    },
  });
  doc.autoTable({
    ...configurePdfTable(simulationTablecolumns, simulationTableRows),
    startY: doc.autoTable.previous.finalY + spacerHeight,
    columnWidth: "wrap",
    columnStyles: columnConfig,
  });
  doc.autoTable({
    ...configurePdfTable([], summaryTableRows),
    startY: doc.autoTable.previous.finalY,
    columnWidth: "auto",
    columnStyles: {
      0: { cellWidth: 80, halign: "right" },
      1: { cellWidth: 35, halign: "right" },
      2: { cellWidth: 35, halign: "right" },
      3: { cellWidth: 32, halign: "right" },
    },
  });
  doc.autoTable({
    ...configurePdfTable([], [[t("summary.info")]]),
    startY: doc.autoTable.previous.finalY + spacerHeight,
    columnStyles: {
      0: { cellWidth: "auto", halign: "center", valign: "center" },
    },
    styles: {
      cellPadding: 2,
      font: "BNPPSans",
      fontSize: 10,
      halign: "justify",
      valign: "center",
      rowPageBreak: "auto",
    },
    theme: "plain",
  });

  return doc;
};

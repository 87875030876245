import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./AdditionalTool.scss";

function AdditionalTool({
  requirements,
  title,
  description,
  to,
  cover,
  thumbnailAnchor,
}) {
  const { t } = useTranslation(["landingPage"]);
  return (
    <div className="additional-tool">
      <div className="">
        <div
          className="additional-tool__cover"
          style={{
            backgroundImage: `url("${cover}")`,
            backgroundPosition: thumbnailAnchor || "top",
          }}
        >
          {requirements && (
            <span className="additional-tool__restricted">{requirements}</span>
          )}
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <Link to={to} className="c-widget__cta tools-page__cta">
        {t("landingPage:aside.getDetails")}
      </Link>
    </div>
  );
}

export default AdditionalTool;

/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint no-use-before-define: 0 */
/* global self */
import { isMobile } from "react-device-detect";
import Cookies from "js-cookie";

let deferredPrompt;
let elAddToHome;
let elAddToHomeAgreeBtn;
let elAddToHomeDisagreeBtn;

const isLocalhost = Boolean(
  // window.location.hostname === "localhost" ||
  //   // [::1] is the IPv6 localhost address.
  //   window.location.hostname === "[::1]" ||
  //   // 127.0.0.1/8 is considered localhost for IPv4.
  //   window.location.hostname.match(
  //     /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  //   )
  false
);

export function register(config) {
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }
    window.addEventListener("load", () => {
      elAddToHome = document.querySelector("#addToHomeScreen");
      elAddToHomeAgreeBtn = elAddToHome.querySelector("#addToHomeagree");
      elAddToHomeDisagreeBtn = elAddToHome.querySelector("#addToHomedisagree");
      listenBtns();
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready.then(() => {});
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

export const listenInstallPrompt = window.addEventListener(
  "beforeinstallprompt",
  e => {
    const showed = Cookies.get("pwa_info");
    if (isMobile && !showed) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // e.preventDefault();
      // toggleAddToHome(true);
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
    }
  }
);

const toggleAddToHome = show => {
  elAddToHome.style.visibility = show ? "" : "hidden";
  elAddToHome.style.opacity = show ? 1 : 0;
};

const listenBtns = () => {
  elAddToHomeDisagreeBtn.addEventListener("click", () => {
    toggleAddToHome(false);
  });
  elAddToHomeAgreeBtn.addEventListener("click", () => {
    toggleAddToHome(false);
    console.log("clicked");
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
      Cookies.set("pwa_info", true, {
        expires: 7,
        secure: true,
      });
      deferredPrompt = null;
    });
  });
};

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // eslint-disable-next-line no-param-reassign
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              // Execute callback
              console.log("update service worker");
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}

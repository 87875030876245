import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../utils/cftRoutes";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";

import "./CftHenhousesHeader.scss";

const TABS = [
  {
    name: "cftChickenBroiler:henhousesHeader.start",
    path: routes.CHICKEN_BROILER.MAIN,
    keyStore: "main",
  },
  {
    name: "cftChickenBroiler:henhousesHeader.coops",
    path: routes.CHICKEN_BROILER.COOPS,
    keyStore: "henhouses_reference",
  },
  {
    name: "cftChickenBroiler:henhousesHeader.energy",
    path: routes.CHICKEN_BROILER.EMISSIONS,
    keyStore: "emission",
  },
  {
    name: "cftChickenBroiler:henhousesHeader.report",
    path: routes.CHICKEN_BROILER.REPORT,
    keyStore: "report",
  },
];

function CftHenhousesHeader() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { store } = useCftChickenBroilerValues();

  return (
    <div className={classnames("cft-henhouses-header")}>
      {TABS.map(({ name, path, keyStore }, key) => {
        const isActive =
          location.pathname.includes(path) || store[keyStore].isValidate;

        return (
          <div
            key={keyStore}
            onClick={() => isActive && history.push(path)}
            className={classnames({ isActive })}
          >
            {key + 1}. {t(name)}
          </div>
        );
      })}
    </div>
  );
}

export default CftHenhousesHeader;

import React, { useState, useEffect, useRef } from "react";
import { filter, find, map } from "ramda";
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import Select from "react-select";

import Tag from "../../../Tag/Tag";
import { getPhotosTags } from "../../../../../services/redux/photos/reducer";
import { selectPhotosTags } from "../../../../../services/redux/photos/selectors";

import "./AddFiledForm.scss";

const mapOptions = tags =>
  map(
    tag => ({
      ...tag,
      value: tag.name,
      label: tag.name,
    }),
    tags
  );

const AddFiledForm = ({
  titleMaxLength,
  availableTags,
  getPhotosTags,
  photo,
  hasApprovedTerms,
  errors,
  onSubmit,
  userGalleryForm,
}) => {
  const [title, setTitle] = useState(photo.title);
  const [tags, setTags] = useState(mapOptions(photo.tags));
  const [condition, setCondition] = useState(!!hasApprovedTerms ? true : null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const submitButton = useRef();
  const { t } = useTranslation(["averagePrices"]);

  useEffect(() => {
    if (availableTags.length === 0) {
      getPhotosTags();
    }
  }, []);
  useEffect(() => {}, [photo]);

  const checkIsTagActive = tag =>
    find(t => t.value === tag.value)(tags) !== undefined;

  const removeTag = tag => {
    const newTags = filter(t => t.value !== tag.value)(tags);
    setTags(newTags);
  };

  const addTag = tag => setTags([...tags, tag]);

  const filterSelectedTags = optionsTags =>
    filter(tag => !checkIsTagActive(tag))(optionsTags);

  return (
    <div className="c-add-filed-form">
      <label htmlFor="title">
        {t("widgets:gallery:upload.photoTitle")}
        <input
          value={title}
          onChange={({ target }) => setTitle(target.value)}
          type="text"
          id="title"
          autoComplete="off"
          className="e-input"
          maxLength={titleMaxLength}
          name="title"
        />
        <span className="c-add-filed-form__title-max">
          {titleMaxLength - title.length}
        </span>
        {errors && errors["title"] && (
          <p className="input-error">{errors["title"]}</p>
        )}
      </label>
      {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
      <label htmlFor="tags">
        {t("widgets:gallery:upload.keywordsTitle")}
        <span className="c-add-filed-form__tags-small">
          {t("widgets:gallery:upload.keywordsSubTitle")}
        </span>
        <Select
          className="select-tags-container"
          classNamePrefix="select-tags"
          value={null}
          inputId="tags"
          options={filterSelectedTags(availableTags)}
          placeholder={t("widgets:gallery:upload.tags")}
          onChange={value => {
            setTags([...tags, value]);
          }}
        />
      </label>
      <div className="c-add-filed-form__selected-tags">
        {tags.map(tag => (
          <>
            <Tag isActive label={tag.label} onRemove={() => removeTag(tag)} />
            <input hidden name="tags_list" value={tag.id} />
          </>
        ))}
      </div>
      <p> {t("widgets:gallery:upload.keywordsPopular")}</p>
      <div className="c-add-filed-form__promoted_tags">
        {availableTags.slice(0, 12).map(tag => {
          const isActive = checkIsTagActive(tag);
          return (
            <Tag
              isActive={isActive}
              label={tag.label}
              onClick={() => (isActive ? removeTag(tag) : addTag(tag))}
            />
          );
        })}
      </div>
      {!hasApprovedTerms && (
        <div className="c-add-filed-form__terms">
          {/* eslint-disable-next-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */}
          <label className="c-checkbox">
            {t("widgets:gallery:upload.consentFirst")}
            <a href="/static/Regulamin_TwojePoleDoPopisu.pdf">
              {t("widgets:gallery:upload.consentLast")}
            </a>
            <input
              type="checkbox"
              name="terms"
              required
              onChange={e => setCondition(!!e.target.checked)}
            />
            <span
              className={`checkmark ${!!condition === false && "is-error"}`}
            />
          </label>
          {condition === false && <p className="input-error">{t("widgets:gallery:upload.required")}</p>}
        </div>
      )}
      <div className="c-add-filed-form__actions">
        <button
          ref={submitButton}
          type="submit"
          className="button--big button--yellow"
          disabled={submitDisabled}
          onClick={e => {
            if (condition === null) {
              setCondition(!!condition);
            }
          }}
        >
          {photo.title !== "" ? t("widgets:gallery:upload.update") : t("widgets:gallery:upload.add")}
        </button>
      </div>
    </div>
  );
};

AddFiledForm.propTypes = {
  titleMaxLength: PropTypes.number,
  photo: {
    title: PropTypes.string,
    tags: PropTypes.array,
  },
  hasApprovedTerms: PropTypes.bool,
};
AddFiledForm.defaultProps = {
  titleMaxLength: 50,
  photo: {
    title: "",
    tags: [],
  },
  hasApprovedTerms: false,
};

export default compose(
  withTranslation(),
  connect(
    state => ({
      availableTags: selectPhotosTags(state),
    }),
    {
      getPhotosTags,
    }
  )
)(AddFiledForm);

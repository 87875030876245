// Soil Texture Icons
import { ReactComponent as ClayIcon } from "../../../../assets/icons/cft/glinowa.svg";
import { ReactComponent as MudIcon } from "../../../../assets/icons/cft/mulowa.svg";
import { ReactComponent as SandyIcon } from "../../../../assets/icons/cft/piaskowa.svg";

// Soil Drainage Icons
import { ReactComponent as WeakIcon } from "../../../../assets/icons/cft/slaby.svg";
import { ReactComponent as GoodIcon } from "../../../../assets/icons/cft/dobry.svg";

// Soil pH Icons
import { ReactComponent as PhGreanIcon } from "../../../../assets/icons/cft/ph_green.svg";
import { ReactComponent as PhPurpleIcon } from "../../../../assets/icons/cft/ph_purple.svg";
import { ReactComponent as PhRedIcon } from "../../../../assets/icons/cft/ph_red.svg";
import { ReactComponent as PhYellowIcon } from "../../../../assets/icons/cft/ph_yellow.svg";

export function mapValues(values) {
  return {
    texture_id: Number(values.soilTexture),
    organic_matter_id: Number(values.soilMatter),
    moisture_id: Number(values.averageSoilMoisture),
    drainage_id: Number(values.soilDrainage),
    ph_id: Number(values.ph),
  };
}

export const SOIL_TEXTURE_TYPE = [
  {
    id: "1",
    Icon: ClayIcon,
    label: {
      i18n: "cft:soil.form.3",
      default: "Gliniasta",
    },
  },
  {
    id: "2",
    Icon: MudIcon,
    label: {
      i18n: "cft:soil.form.4",
      default: "Mułowa",
    },
  },
  {
    id: "3",
    Icon: SandyIcon,
    label: {
      i18n: "cft:soil.form.5",
      default: "Piaszczysta",
    },
  },
];

export const AVERAGE_SOIL_MOISTURE_TYPES = [
  {
    id: "1",
    label: {
      i18n: "cft:soil.form.0",
      default: "Wodnista",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:soil.form.11",
      default: "Sucha",
    },
  },
];

export const SOIL_DRAINAGE_TYPES = [
  {
    id: "1",
    Icon: GoodIcon,
    label: {
      i18n: "cft:soil.form.14",
      default: "Dobry",
    },
  },
  {
    id: "2",
    Icon: WeakIcon,
    label: {
      i18n: "cft:soil.form.15",
      default: "Słaby",
    },
  },
];

export const SOIL_MATTER_TYPES = [
  {
    value: 1,
    label: "< 1.72",
  },
  {
    value: 2,
    label: "1.72 - 5.16",
  },
  {
    value: 3,
    label: "5.16 - 10.32",
  },
  {
    value: 4,
    label: "> 10.32",
  },
];

export const PH_TYPES = [
  {
    id: "1",
    Icon: PhRedIcon,
    label: {
      i18n: "cft:soil.form.18",
      default: "< 5.5\n Silnie kwaśna",
    },
  },
  {
    id: "2",
    Icon: PhYellowIcon,
    label: {
      i18n: "cft:soil.form.19",
      default: "5.5 - 7.3\n Kwaśna",
    },
  },
  {
    id: "3",
    Icon: PhGreanIcon,
    label: {
      i18n: "cft:soil.form.20",
      default: "7.3 - 8.5\n Obojętna",
    },
  },
  {
    id: "4",
    Icon: PhPurpleIcon,
    label: {
      i18n: "cft:soil.form.21",
      default: "> 8.5\n Zasadowa",
    },
  },
];

export const mapSoilDetails = data => {
  const parsedData = Object.keys(data)
    .map(key => {
      if (key === "texture_id") {
        const val = SOIL_TEXTURE_TYPE.find(
          ({ id }) => Number(id) === data[key]
        );
        return {
          name: "texture",
          value: val ? val.label.default : "",
        };
      }

      if (key === "organic_matter_id") {
        const val = SOIL_MATTER_TYPES.find(
          ({ value }) => Number(value) === data[key]
        );
        return {
          name: "SOM",
          value: val ? val.label : "",
        };
      }

      if (key === "moisture_id") {
        const val = AVERAGE_SOIL_MOISTURE_TYPES.find(
          ({ id }) => Number(id) === data[key]
        );
        return {
          name: "moisture",
          value: val ? val.label.default : "",
        };
      }

      if (key === "drainage_id") {
        const val = SOIL_DRAINAGE_TYPES.find(
          ({ id }) => Number(id) === data[key]
        );
        return {
          name: "drainage",
          value: val ? val.label.default : "",
        };
      }

      if (key === "ph_id") {
        const val = PH_TYPES.find(({ id }) => Number(id) === data[key]);
        return {
          name: "ph",
          value: val ? val.label.default : "",
        };
      }

      return null;
    })
    .filter(val => val !== null);

  return parsedData;
};

import Cookies from "js-cookie";
import useSWR from "swr";
import API from "../../../../services/apiHelpers";

export function useListOfPoultryProducts() {
  const url = "/api/v1/poultry/products/";
  const { data } = useSWR(url, () => API().get(url));

  return {
    data: data ? data.data : undefined,
  };
}

export function useDataForRaportCenowy(userDefaults) {
  const { regions } = userDefaults;
  const url = "/api/v1/poultry/";
  const { data } = useSWR([url, regions], () =>
    API().get(url, {
      params: {
        limit: 20,
        ordering: "-week_start_date",
        regions_list: regions.join(","),
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

export function useListOfRegions() {
  const token = Cookies.get("user_token");

  const url = "/api/v1/poultry/regions/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

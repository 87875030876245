import { isNil } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import roundTo from "round-to";
import { isMobile } from "react-device-detect";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import { ReactComponent as DeclineIcon } from "../../../../assets/icons/cft/prices-down.svg";
import { ReactComponent as RiseIcon } from "../../../../assets/icons/cft/prices-up.svg";
import "swiper/swiper.min.css";

export const createTableRow = (
  { id, name, currentPrice, percentagePriceChange },
  weightMeasure = "tons"
) => {
  let priceChangeType = "none";
  if (percentagePriceChange > 0) {
    priceChangeType = "rise";
  }
  if (percentagePriceChange < 0) {
    priceChangeType = "decline";
  }

  const priceMeasure = (() => {
    switch (weightMeasure) {
      case "kg":
        return "zł/kg";
      case "100kg":
        return "zł/100kg";
      default:
        return "zł/t";
    }
  })();

  return {
    id,
    name,
    productData: {
      price: !isNil(currentPrice)
        ? `${roundTo(currentPrice, 2)} ${priceMeasure}`
        : "",
      priceChange: !isNil(percentagePriceChange)
        ? `${percentagePriceChange.toFixed(2)}%`
        : "",
      priceChangeType,
    },
  };
};

const TableComponent = ({ dataList }) => {
  const { t } = useTranslation(["averagePrices"]);

  return (
    <div className="prices-bar">
      {dataList.length > 0 ? (
        <Swiper
          modules={[Navigation]}
          navigation={window.innerWidth < 1200 || dataList.length > 6}
          slidesPerView={1}
          spaceBetween={0}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 0,
            },
          }}
        >
          {dataList.map(({ id, name, productData }) => {
            const { price, priceChange, priceChangeType } = productData;

            if (!price && !priceChange) {
              return (
                <SwiperSlide key={`slide-table-no-data-${id}${name}`}>
                  <div className="prices-bar__tile" key={`${id}${name}`}>
                    <span className="prices-bar__name">{name}</span>
                    <div className="prices-bar__data">
                      <span className="prices-bar__empty">
                        {t("averagePrices:noData")}
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            }

            return (
              <SwiperSlide key={`slide-table-${id}${name}`}>
                <div className="prices-bar__tile" key={`${id}${name}`}>
                  <span className="prices-bar__name">{name}</span>
                  <div className="prices-bar__data">
                    <span className="prices-bar__price">{price || "-"}</span>
                    <span
                      className={`prices-bar__change prices-bar__change--${priceChangeType}`}
                    >
                      {!!priceChangeType && priceChangeType === "rise" ? (
                        <RiseIcon />
                      ) : (
                        <DeclineIcon />
                      )}
                      <span>
                        {!!priceChangeType && priceChangeType === "rise"
                          ? "+"
                          : ""}
                        {priceChange || "-"}
                      </span>
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : (
        <div className="prices-bar__no-data">{t("averagePrices:noData")}</div>
      )}
    </div>
  );
};

export default TableComponent;

export const transportType = {
  road: [
    {
      pl: "samochód ciężarowy (>3,5t)",
      gb: "road HGV (heavy goods vehicle >3.5t)",
      id: 119,
    },
    {
      pl: "samochód dostawczy zasilany olejem napędowym (<3,5 t)",
      gb: "road LGV diesel (light goods vehicle <3.5t)",
      id: 120,
    },
    {
      pl: "samochód dostawczy zasilany benzyną (<3,5 t)",
      gb: "road LGV petrol (light goods vehicle <3.5t)",
      id: 121,
    },
    {
      pl: "samochód dostawczy zasilany CNG/LPG (<3,5 t)",
      gb: "road LGV CNG/LPG (<3.5t)",
      id: 122,
    },
  ],
  rail: [
    {
      pl: "Kolej",
      gb: "rail",
      id: 130,
    },
  ],
  air: [
    {
      pl: "lotniczy na bardzo krótkim dystansie (<800 km)",
      gb: "air very short haul (<800km)",
      id: 131,
    },
    {
      pl: "lotniczy na krótkim dystansie (800 – 1600 km)",
      gb: "air short haul (800 - 1600km)",
      id: 132,
    },
    {
      pl: "lotniczy na długim dystansie (>1600 km)",
      gb: "air long haul (>1600km)",
      id: 133,
    },
  ],
  ship: [
    {
      pl: "mały zbiornikowiec (~1000 t)",
      gb: "ship small tanker (~1000t)",
      id: 134,
    },
    {
      pl: "duży zbiornikowiec (~20 000 t)",
      gb: "ship large tanker (~20 000t)",
      id: 135,
    },
    {
      pl: "bardzo duży zbiornikowiec (~100 000 t)",
      gb: "ship very large tanker (~100 000t)",
      id: 136,
    },
    {
      pl: "mały masowiec (~2000 t)",
      gb: "ship small bulk carrier (~2000t)",
      id: 137,
    },
    {
      pl: "duży masowiec (~15 000 t)",
      gb: "ship large bulk carrier (~15 000t)",
      id: 138,
    },
    {
      pl: "bardzo duży masowiec (~70 000 t)",
      gb: "ship very large bulk carrier (~70 000t)",
      id: 139,
    },
    {
      pl: "mały kontenerowiec (~2500 t)",
      gb: "ship small container vessel (~2500t)",
      id: 140,
    },
    {
      pl: "duży kontenerowiec (~20 000 t)",
      gb: "ship large container vessel (~20 000t)",
      id: 141,
    },
  ],
};

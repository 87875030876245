import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftButton,
  CftTank,
} from "../../components";
import { ReactComponent as ClockIcon } from "../../../assets/icons/cft/kalkukacja.svg";
import routes from "../../utils/cftRoutes";
import CftProvider, { useCft } from "../../utils/CftProvider";

import "../styles/intro.scss";

function IntroDairyProductionPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { dairyProduction } = useCft();

  if (dairyProduction.details.name !== "") {
    return <Redirect to={routes.DAIRY_PRODUCTION.MAIN} />;
  }

  return (
    <CftLayout
      header={
        <CftHeader
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
        />
      }
    >
      <CftSection
        header={
          <h3>{t("cft:intro", "Już za moment rozpoczniesz KALKULACJĘ...")}</h3>
        }
      >
        <div className="intro-page">
          <CftTank mb={4}>
            <ClockIcon />
          </CftTank>
          <p>
            {t(
              "cft:intro.p1",
              "Wykonanie kalkulacji zajmie około 15 min. W każdej chwili możesz wyjść z kalkulatora,"
            )}
            <b>
              {" "}
              {t(
                "cft:intro.b",
                "a postęp kalkulacji możesz zachować na swoim komputerze."
              )}
            </b>
          </p>
          <p>
            {t(
              "cft:intro.p2",
              "Na koniec kalkulacji wygenerujuemy raport, który będziesz mógł zapisać na swoim komputerze a później wrócić do niego i zmodyfikować w razie potrzeby."
            )}
          </p>
          <CftButton
            variant="active"
            onClick={() => history.push(routes.DAIRY_PRODUCTION.DETAILS)}
          >
            {t("cft:start", "rozpocznij")}
          </CftButton>
        </div>
      </CftSection>
    </CftLayout>
  );
}

export default function() {
  return (
    <CftProvider>
      <IntroDairyProductionPage />
    </CftProvider>
  );
}

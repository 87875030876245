import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import useCftCattleValues from "../../utils/useCftCattleValues";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftRadioField,
  CftSelectField,
  CftFormErrorFocus,
} from "../../components";

const DominantRaceFields = () => {
  const { values } = useFormikContext();
  const { store, updateStore } = useCftCattleValues();
  const { t } = useTranslation([
    "cftTurkey",
    "cftChickenBroiler",
    "cftPigs",
    "cftCattle",
  ]);

  useEffect(() => {
    updateStore({
      herd_reference: {
        ...store.herd_reference,
        ...values,
      },
    });
  }, [values]);

  const calculationPeriodPhases = useMemo(
    () => [
      {
        label: t("cftCattle:calculationPeriodPhases.1", "Początek"),
        value: 1,
      },
      {
        label: t("cftCattle:calculationPeriodPhases.2", "Środek"),
        value: 2,
      },
      {
        label: t("cftCattle:calculationPeriodPhases.3", "Koniec"),
        value: 3,
      },
    ],
    []
  );

  const cattleRaces = useMemo(
    () => [
      {
        value: 1,
        label: "Limousine",
      },
      {
        value: 2,
        label: "Charolaise",
      },
      {
        value: 3,
        label: "Hereford",
      },
      {
        value: 4,
        label: "Simental",
      },
      {
        value: 5,
        label: "Angus",
      },
    ],
    []
  );

  return (
    <CftTank
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <h2
        className="cft-section-title mb-3 cft-max-wrapper"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {t(
          "cftCattle:dominantRaceFields.title",
          "Informacje o stadzie, paszach i nawozach"
        )}
      </h2>
      <h4 className="cft-section-subtitle mb-4 mt-4">
        {t(
          "cftCattle:dominantRaceFields.subtitle",
          "Parametry stada dotyczą początku, środka czy końca okresu obliczeniowego?"
        )}
      </h4>
      <CftFormControl alignLeft isAlternative>
        <div className="cft-paper">
          <CftTank flexDirection="column" mt={0} mb={0}>
            <CftRadioField
              mb={0}
              mt={0}
              variant="box"
              name="calculation_period_phases"
              options={calculationPeriodPhases}
            />
          </CftTank>
        </div>
      </CftFormControl>
      <h4 className="cft-section-subtitle mb-4 mt-4">
        {t(
          "cftCattle:dominantRaceFields.subtitle2",
          "Podaj rasę dominującą w Twoim stadzie"
        )}
      </h4>
      <CftFormControl alignLeft isAlternative>
        <div className="cft-paper">
          <CftLabel
            isFullWidth
            label={t(
              "cftCattle:dominantRaceFields.fields.dominant_race",
              "Rasa dominująca"
            )}
          />
          <CftSelectField
            name="dominant_race"
            options={cattleRaces}
            placeholder={t(
              "cftTurkey:energyCarriersFields.fields.energy_carriersPlaceholder",
              "Wybierz z listy"
            )}
            isRounded
            isAlternative
          />
        </div>
      </CftFormControl>
      <CftFormErrorFocus />
    </CftTank>
  );
};

export default DominantRaceFields;

import React, { useRef, useState } from "react";
import RSelect, { components } from "react-select";
import RSelectASync from "react-select/async";
import classNames from "classnames";

import { useOnClickOutside } from "../../../../common/NavigationBar/SearchBar";
import { ReactComponent as XIcon } from "../../../../../assets/icons/x.svg";
import { ReactComponent as PinIcon } from "../../../../../assets/icons/pin.svg";

import "./RealEstateInputs.scss";

export function Input({ name, children, defaultValue, onClear, ...props }) {
  const ref = useRef();
  return (
    <label htmlFor={name} className="real-estate-inputs__text">
      {children && (
        <div className="real-estate-inputs__text__pre">{children}</div>
      )}
      <input
        ref={ref}
        className={defaultValue && "real-estate-inputs__text--has_value"}
        autoComplete="off"
        id={name}
        name={name}
        defaultValue={defaultValue}
        {...props}
      />
      {defaultValue && onClear && (
        <span
          className="real-estate-inputs__clear"
          onClick={() => {
            ref.current.value = "";
            onClear();
          }}
        >
          <XIcon />
        </span>
      )}
    </label>
  );
}

export function CustomSelectOption({ innerRef, innerProps, ...props }) {
  return (
    <div style={{ color: "black" }} ref={innerRef} {...innerProps}>
      <div
        className={classNames(
          "estate-select__custom_option",
          `estate-select__custom_option--${props.data.type}`,
          {
            "estate-select__custom_option--is-selected": props.isSelected,
          }
        )}
      >
        <span className="checkmark_option" />
        {props.label.toLowerCase()}
      </div>
    </div>
  );
}

export function Select({ options, defaultValue, ...props }) {
  return (
    <RSelect
      className="estate-select"
      classNamePrefix="real-estate-select"
      options={options}
      defaultValue={defaultValue}
      {...props}
    />
  );
}

export function SelectAsync({ loadOptions, defaultValue, ...props }) {
  return (
    <RSelectASync
      className="estate-select"
      classNamePrefix="real-estate-select"
      loadOptions={loadOptions}
      defaultValue={defaultValue}
      {...props}
    />
  );
}

export function TextArea({ name, children, ...props }) {
  return (
    <label htmlFor={name} className="real-estate-inputs__text">
      {children && (
        <div className="real-estate-inputs__text__pre">{children}</div>
      )}
      <textarea id={name} name={name} {...props} />
    </label>
  );
}

export function Button(props) {
  return (
    <button type="button" className="real-estate-inputs__button" {...props} />
  );
}

export function CheckBox({ name, children, ...props }) {
  return (
    <label htmlFor={name} className="real-estate-inputs__checkbox">
      {children}
      <input type="checkbox" id={name} name={name} {...props} />
      <span className="real-estate-inputs__checkbox__checkmark" />
    </label>
  );
}

export function ErrorText({ children, isVisible, ...props }) {
  if (isVisible) {
    return (
      <p className="real-estate-inputs__error" {...props}>
        {children}
      </p>
    );
  }

  return null;
}

export function LocationInput({ name, children, locations, placeholder }) {
  const [isVisible, setIsVisibe] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setIsVisibe(false);
  });
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      ref={ref}
      htmlFor={name}
      className="real-estate__location-input"
      onClick={() => {
        setIsVisibe(true);
      }}
    >
      <div className="real-estate__location-wrapper">
        <div className="real-estate__location-icon">
          <PinIcon />
        </div>
        <div className="real-estate__location-selected">
          {locations.length === 0 && (
            <span className="real-estate__location-placeholder">
              {placeholder}
            </span>
          )}
          {locations.map(location => (
            <>
              {location.label}
              {", "}
            </>
          ))}
        </div>
      </div>

      {isVisible && (
        <div className="real-estate__location-input__content">
          <span
            className="real-estate__location-input__close"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setIsVisibe(false);
            }}
          >
            <XIcon />
          </span>
          {children}
        </div>
      )}
    </label>
  );
}

export function CustomLocationInput(props) {
  return (
    <div className="real-estate__location-custom-input">
      <components.Input {...props} />
    </div>
  );
}

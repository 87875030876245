import React from "react";
import PropTypes from "prop-types";

import WarningIcon from "./WarningIcon";

import "./CftWarningBox.scss";

const CftWarningBox = ({ text, ...props }) => {
  return (
    <div className="cft-warning-box" {...props}>
      <WarningIcon />
      {text}
    </div>
  );
};

export default CftWarningBox;

CftWarningBox.propTypes = {
  text: PropTypes.string.isRequired,
};

CftWarningBox.defaultProps = {};

import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  CftBox,
  CftLabel,
  CftTextField,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftSelectField,
  CftFormErrorFocus,
  CftTooltip,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import useCftOptions from "../../../../utils/useCftOptions";
import { mapValues } from "./detailsWaterFootprint.utils";

// Categories Icons
import { ReactComponent as FruitsIcon } from "../../../../../assets/icons/cft/owoce.svg";
import { ReactComponent as VegeIcon } from "../../../../../assets/icons/cft/warzywa.svg";
import { ReactComponent as WheatIcon } from "../../../../../assets/icons/cft/polauprawne.svg";
import { ReactComponent as BeansIcon } from "../../../../../assets/icons/cft/straczkowe.svg";
import { ReactComponent as PotatoesIcon } from "../../../../../assets/icons/cft/ziemniaki.svg";

import { CROP_TYPES } from "./detailsWaterFootprint.consts";
import { farmlandsAPI } from "../../../../utils/cftApi";

function DetailsWaterFootprintForm() {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const { years, getUnits } = useCftOptions();

  const generateName = name => {
    if (!values.name) {
      setFieldValue("name", `${name} - ${new Date().getFullYear()}`);
    }
  };

  return (
    <Form className="cft-form">
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsWaterFootprint.form.1", "Nazwij swoją uprawę")}
        />
        <CftTextField name="name" />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:detailsWaterFootprint.form.2", "Co uprawiasz?")}
        />
        <CftFormField name="cultivate">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "owoce"}
                  onClick={() => {
                    helpers.setValue("owoce");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("owoce");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <FruitsIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.1", "Owoce")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "warzywa"}
                  onClick={() => {
                    helpers.setValue("warzywa");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("warzywa");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <VegeIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.2", "Warzywa")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "zboża"}
                  onClick={() => {
                    helpers.setValue("zboża");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("zboża");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <WheatIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.3", "Zboża")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "bobowate"}
                  onClick={() => {
                    helpers.setValue("bobowate");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("bobowate");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <BeansIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph cft-box__paragraph--isWide">
                    {t("cft:crop.parents.4", "Bobowate lub strączkowe")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "ziemniaki"}
                  onClick={() => {
                    helpers.setValue("ziemniaki");
                    setFieldValue("crop", "14");
                    setFieldValue("seedAmount", {
                      unit: "",
                      value: "",
                    });
                    generateName("ziemniaki");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <PotatoesIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.5", "Ziemniaki")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        {values.cultivate !== "ziemniaki" && (
          <CftLabel
            label={t("cft:detailsWaterFootprint.form.2", "Co uprawiasz?")}
          />
        )}
        <CftConditionalRendering
          isConditionFulfilled={
            values.cultivate === "owoce" || values.cultivate === ""
          }
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "owoce").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "warzywa"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "warzywa").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "zboża"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "zboża").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "bobowate"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "bobowate").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsWaterFootprint.form.36", "Rok zbioru")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:detailsWaterFootprint.form.37",
                "Wpisz rok zbioru plonów dla którego chcesz wykonać kalkulację"
              )}
            />
          }
        />
        <CftSelectField name="year" options={years} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsWaterFootprint.form.38", "Areał uprawy")}
        />
        <CftUnitField name="areal" units={getUnits(["ha", "a", "m2"])} />
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={values.cultivate === "ziemniaki"}
      >
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:detailsWaterFootprint.form.39", "Ilość nasion")}
          />
          <CftUnitField name="seedAmount" units={getUnits(["kg", "t", "l"])} />
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:detailsWaterFootprint.form.40")} />
        <CftUnitField name="harvestAmount" units={getUnits(["t", "kg"])} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:detailsWaterFootprint.form.41",
            "Ilość na wyjściu z gospodarstwa"
          )}
        />
        <CftUnitField name="exit" units={getUnits(["t", "kg"])} />
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default DetailsWaterFootprintForm;

export function DetailsWaterFootprintFormContext({ children }) {
  const history = useHistory();
  const {
    waterFootprint,
    saveWaterFootprint,
    saveWaterFootprintReport,
  } = useCft();
  const { t } = useTranslation(["cft"]);
  return (
    <Formik
      enableReinitialize
      initialValues={waterFootprint.details}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .trim()
          .max(20, "Maksymalna ilość znaków to 20")
          .required(t("cft:validation.required", "Wpisz wartość")),
        cultivate: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        crop: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        year: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        harvestAmount: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        exit: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        areal: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        seedAmount: Yup.object().when("cultivate", {
          is: v => v === "ziemniaki",
          then: Yup.object().shape({
            value: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            unit: Yup.string().required(
              t("cft:validation.unitTypeError", "Wybierz jednostkę")
            ),
          }),
          otherwise: Yup.object(),
        }),
      })}
      onSubmit={values => {
        saveWaterFootprint({ details: values });
        farmlandsAPI
          .detailsWaterFootprintValidation(mapValues(values))
          .then(({ data }) => {
            saveWaterFootprintReport({
              crop: data,
            });
            history.push(routes.FARMLANDS.WATER_FOOTPRINT.SOIL);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";

import "./CftLeavingModal.scss";
import { CftActionsButtons } from "../index";
import { ReactComponent as ExitIcon } from "../../../assets/icons/cft/x.svg";
import { ReactComponent as CircleIcon } from "../../../assets/icons/cft/anuluj.svg";
import { useCft } from "../../utils/CftProvider";

// const checkIsReportAvailable = (
//   type,
//   farmlandsReport,
//   dairyProductionReport,
//   waterFootprintReport
// ) => {
//   if (!type) {
//     return true;
//   }

//   if (type === "farmlands") {
//     return !isEmpty(farmlandsReport.crop) && !isEmpty(farmlandsReport.soil);
//   }

//   if (type === "waterFootprint") {
//     return (
//       !isEmpty(waterFootprintReport.crop) && !isEmpty(waterFootprintReport.soil)
//     );
//   }

//   if (type === "both") {
//     return (
//       !isEmpty(farmlandsReport.crop) &&
//       !isEmpty(farmlandsReport.soil) &&
//       !isEmpty(dairyProductionReport.milk_production) &&
//       !isEmpty(dairyProductionReport.herd_sections)
//     );
//   }

//   return (
//     !isEmpty(dairyProductionReport.milk_production) &&
//     !isEmpty(dairyProductionReport.herd_sections)
//   );
// };

function CftLeavingModal({
  isVisible,
  hide,
  goToProfile,
  fileName,
  dataReport,
}) {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const {
    farmlands,
    farmlandsReport,
    waterFootprint,
    waterFootprintReport,
    dairyProduction,
    dairyProductionReport,
  } = useCft();

  const downloadJSON = () => {
    const file = new Blob(
      [
        dataReport ||
          JSON.stringify({
            farmlands,
            farmlandsReport,
            waterFootprint,
            waterFootprintReport,
            dairyProduction,
            dairyProductionReport,
          }),
      ],
      { type: "text/plain" }
    );
    saveAs(file, `${fileName}.json`);
  };

  const startOverflow = () => {
    document.querySelector("body").style = undefined;
    document.querySelector("html").style.overflow = "unset";
  };
  return (
    isVisible && (
      <div className="cft-leaving-overlay">
        <div className="cft-leaving-box">
          <div
            className="cft-leaving-exit"
            onClick={() => {
              startOverflow();
              hide();
            }}
          >
            <ExitIcon />
          </div>
          <div className="cft-leaving-content">
            <div className="cft-leaving-icon">
              <CircleIcon />
            </div>
            <div className="cft-leaving-title">
              {t("cft:modal.leaving.title", "TWÓJ POSTEP ZOSTANIE UTRACONY")}
            </div>
            <div className="cft-leaving-description">
              {t(
                "cft:modal.leaving.description",
                "Postęp Twojej kalkulacji zostanie utracony jeśli wrócisz do profilu użytkownika. Zalecamy zapisanie aktualnego postępu kalkulacji."
              )}
            </div>
            <div className="cft-leaving-export-button">
              <CftActionsButtons.ExportReport onClick={downloadJSON} />
            </div>

            <div
              className="cft-leaving-back-button"
              onClick={() => {
                startOverflow();
                if (goToProfile) {
                  goToProfile();
                } else {
                  history.push("/farmer");
                }
              }}
            >
              {t("cft:goBackButton", "WYJDŹ DO PROFILU")}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

CftLeavingModal.propTypes = {
  isVisible: PropTypes.bool,
  goBack: PropTypes.func,
  type: PropTypes.string,
  goToProfile: PropTypes.func,
  fileName: PropTypes.string,
  dataReport: PropTypes.string,
};

CftLeavingModal.defaultProps = {
  isVisible: false,
  fileName: "raport",
};

export default CftLeavingModal;

import routes from "./cftRoutes";

import CftComponents from "../pages/cft.components.page";
// eslint-disable-next-line import/no-duplicates
import CftComponentsDemo from "../pages/farmlands/farmlands.page";
// Głowne
import CftMainPage from "../pages/cft.page";
// Produkcja mleczarska
import IntroDairyProduction from "../pages/dairyProduction/introDairyProduction.page";
import DairyProduction from "../pages/dairyProduction/dairyProduction.page";
import DetailsDairyProduction from "../pages/dairyProduction/detailsDairyProduction.page";
import Characteristics from "../pages/dairyProduction/characteristics.page";
import CattleGrazing from "../pages/dairyProduction/cattleGrazing.page";
import Forage from "../pages/dairyProduction/forage.page";
import Manure from "../pages/dairyProduction/manure.page";
import EnergyAndProcessing from "../pages/dairyProduction/energyAndProcessing.page";
import TransportDairyProduction from "../pages/dairyProduction/transportDairyProduction.page";
import ReportDairyProduction from "../pages/dairyProduction/reportDairyProduction.page";

// Pola uprawne
import IntroFarmlands from "../pages/farmlands/introFarmlands.page";
import CalcType from "../pages/farmlands/calcType.page";
// eslint-disable-next-line import/no-duplicates
import Farmlands from "../pages/farmlands/farmlands.page";
import DetailsFarmlands from "../pages/farmlands/detailsFarmlands.page";
import Soil from "../pages/farmlands/soil.page";
import Fertilizers from "../pages/farmlands/fertilizers.page";
import FuelAndEnergy from "../pages/farmlands/fuelAndEnergy.page";
import Irrigation from "../pages/farmlands/irrigation.page";
import CarbonChanges from "../pages/farmlands/carbonChanges.page";
import TransportFarmlands from "../pages/farmlands/transportFarmlands.page";
import ReportFarmlands from "../pages/farmlands/reportFarmlands.page";

// Ślad wodny
import DetailsWaterFootprint from "../pages/farmlands/waterFootprint/detailsWaterFootprint.page";
import SoilWaterFootprint from "../pages/farmlands/waterFootprint/soilWaterFootprint.page";
import WaterFootprint from "../pages/farmlands/waterFootprint/waterFootprint.page";
import IrrigationPage from "../pages/farmlands/waterFootprint/irrigationWaterFootprint";
import ReportWaterFootprint from "../pages/farmlands/waterFootprint/reportWaterFootprint.page";

// Obie kalkulacje
import DetailsBothCalcPage from "../pages/farmlands/bothCalc/detailsBothCalc.page";
import SoilBothCalcPage from "../pages/farmlands/bothCalc/soilBothCalc.page";
import FertilizersBothCalc from "../pages/farmlands/bothCalc/fertilizersBothCalc.page";
import FuelAndEnergyBothCalc from "../pages/farmlands/bothCalc/fuelAndEnergyBothCalc.page";
import IrrigationBothCalc from "../pages/farmlands/bothCalc/irrigationBothCalc.page";
import CarbonChangesBothCalc from "../pages/farmlands/bothCalc/carbonChangesBothCalc.page";
import TransportBothCalc from "../pages/farmlands/bothCalc/transportBothCalc.page";
import BothCalc from "../pages/farmlands/bothCalc/bothCalc.page";
import ReportBothCalc from "../pages/farmlands/bothCalc/reportBothCalc.page";

// Brojler kurzy
import ChickenBroiler from "../pages/chickenBroiler";
// Indyki
import TurkeyPages from "../pages/turkey";
// Trzoda chlewna
import PigsPages from "../pages/pigs";
// Bydło opasowe
import CattlePages from "../pages/cattle";

export default [
  {
    path: `${routes.MAIN}/komponenty`,
    component: CftComponents,
  },
  {
    path: `${routes.MAIN}/demo`,
    component: CftComponentsDemo,
  },
  {
    path: routes.MAIN,
    component: CftMainPage,
  },
  {
    path: routes.DAIRY_PRODUCTION.INTRO,
    component: IntroDairyProduction,
  },
  {
    path: routes.FARMLANDS.INTRO,
    component: IntroFarmlands,
  },
  {
    path: routes.FARMLANDS.CALC_TYPE,
    component: CalcType,
  },
  {
    path: routes.FARMLANDS.MAIN,
    component: Farmlands,
  },
  {
    path: routes.DAIRY_PRODUCTION.MAIN,
    component: DairyProduction,
  },
  {
    path: routes.FARMLANDS.DETAILS,
    component: DetailsFarmlands,
  },
  {
    path: routes.FARMLANDS.SOIL,
    component: Soil,
  },
  {
    path: routes.FARMLANDS.FERTILIZERS,
    component: Fertilizers,
  },
  {
    path: routes.FARMLANDS.FUEL_AND_ENERGY,
    component: FuelAndEnergy,
  },
  {
    path: routes.FARMLANDS.IRRIGATION,
    component: Irrigation,
  },
  {
    path: routes.FARMLANDS.CARBON_CHANGES,
    component: CarbonChanges,
  },
  {
    path: routes.FARMLANDS.TRANSPORT,
    component: TransportFarmlands,
  },
  {
    path: routes.FARMLANDS.REPORT,
    component: ReportFarmlands,
  },
  {
    path: routes.DAIRY_PRODUCTION.DETAILS,
    component: DetailsDairyProduction,
  },
  {
    path: routes.DAIRY_PRODUCTION.CHARACTERISTICS,
    component: Characteristics,
  },
  {
    path: routes.DAIRY_PRODUCTION.CATTLE_GRAZING,
    component: CattleGrazing,
  },
  {
    path: routes.DAIRY_PRODUCTION.FORAGE,
    component: Forage,
  },
  {
    path: routes.DAIRY_PRODUCTION.MANURE,
    component: Manure,
  },
  {
    path: routes.DAIRY_PRODUCTION.ENERGY_AND_PROCESSING,
    component: EnergyAndProcessing,
  },
  {
    path: routes.DAIRY_PRODUCTION.TRANSPORT,
    component: TransportDairyProduction,
  },
  {
    path: routes.DAIRY_PRODUCTION.REPORT,
    component: ReportDairyProduction,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.INTRO,
    component: IntroFarmlands,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.DETAILS,
    component: DetailsWaterFootprint,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.MAIN,
    component: WaterFootprint,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.SOIL,
    component: SoilWaterFootprint,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.IRRIGATION,
    component: IrrigationPage,
  },
  {
    path: routes.FARMLANDS.WATER_FOOTPRINT.REPORT,
    component: ReportWaterFootprint,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.DETAILS,
    component: DetailsBothCalcPage,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.SOIL,
    component: SoilBothCalcPage,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.INTRO,
    component: IntroFarmlands,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.FERTILIZERS,
    component: FertilizersBothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.FUEL_AND_ENERGY,
    component: FuelAndEnergyBothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.IRRIGATION,
    component: IrrigationBothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.CARBON_CHANGES,
    component: CarbonChangesBothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.TRANSPORT,
    component: TransportBothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.MAIN,
    component: BothCalc,
  },
  {
    path: routes.FARMLANDS.BOTH_CALC.REPORT,
    component: ReportBothCalc,
  },
  {
    path: routes.CHICKEN_BROILER.MAIN,
    component: ChickenBroiler.Main,
  },
  {
    path: routes.CHICKEN_BROILER.COOPS,
    component: ChickenBroiler.Coops,
  },
  {
    path: routes.CHICKEN_BROILER.COOP,
    component: ChickenBroiler.Coop,
  },
  {
    path: routes.CHICKEN_BROILER.EMISSIONS,
    component: ChickenBroiler.Emission,
  },
  {
    path: routes.CHICKEN_BROILER.REPORT,
    component: ChickenBroiler.Report,
  },
  {
    path: routes.TURKEY.MAIN,
    component: TurkeyPages.Main,
  },
  {
    path: routes.TURKEY.COOPS,
    component: TurkeyPages.Coops,
  },
  {
    path: routes.TURKEY.COOP,
    component: TurkeyPages.Coop,
  },
  {
    path: routes.TURKEY.EMISSIONS,
    component: TurkeyPages.Emission,
  },
  {
    path: routes.TURKEY.REPORT,
    component: TurkeyPages.Report,
  },
  {
    path: routes.PIGS.MAIN,
    component: PigsPages.Main,
  },
  {
    path: routes.PIGS.COOPS,
    component: PigsPages.Coops,
  },
  {
    path: routes.PIGS.COOP,
    component: PigsPages.Coop,
  },
  {
    path: routes.PIGS.EMISSIONS,
    component: PigsPages.Emission,
  },
  {
    path: routes.PIGS.REPORT,
    component: PigsPages.Report,
  },
  {
    path: routes.CATTLE.MAIN,
    component: CattlePages.Main,
  },
  {
    path: routes.CATTLE.COOPS,
    component: CattlePages.Coops,
  },
  {
    path: routes.CATTLE.COOP,
    component: CattlePages.Coop,
  },
  {
    path: routes.CATTLE.EMISSIONS,
    component: CattlePages.Emission,
  },
  {
    path: routes.CATTLE.REPORT,
    component: CattlePages.Report,
  },
];

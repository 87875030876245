import { ANSWER_TYPE } from "../consts";

const NEED_ONGOING_FINANCING_GRAINS = {
  0: {
    question: "Oczekujesz kredytu spłacanego w ratach?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.grain,
        grain: 1,
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.grain,
        grain: 2,
      },
    ],
  },
  1: {
    question: "Czy interesuje Cię kredyt w rachunku bieżącym?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-prowadzenie-gospodarstwa",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-biezace-wydatki-gospodarstwa-rolnego",
      },
    ],
  },
  2: {
    question: "Czy kredyt z zabezpieczeniem rzeczowym?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-biezace-wydatki-bez-zabezpieczenia",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-finansowanie-wydatkow-gospodarstwa",
      },
    ],
  },
};

export default NEED_ONGOING_FINANCING_GRAINS;

import routes from "./regagriRoutes";
import RegagriExplorer from "../pages/RegagriExplorer.page";
import CultivationDetails from "../pages/CultivationDetails.page";

export default [
  {
    path: routes.MAIN,
    component: RegagriExplorer,
  },
  {
    path: routes.CULTIVATION_DETAILS.MAIN,
    component: CultivationDetails,
  },
];

import React from "react";

import "./CftInfoBox.scss";

function CftInfoBox({ children }) {
  return (
    <div className="cft-info-box">
      <div className="cft-info-box__icon">i</div>
      <p>{children}</p>
    </div>
  );
}

export default CftInfoBox;

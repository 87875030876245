import { dotFormatter } from "../../../../utils/cftUtils";
import { CROP_TYPES } from "./detailsBothCalc.consts";

import { getUnit } from "../../../../utils/useCftOptions";

export function mapValues(values) {
  return {
    name: values.name,
    year: Number(values.year),
    crop_id: Number(values.crop),
    co_products: values.products.map(({ type, value }) => ({
      name: type,
      percentage_main_value: dotFormatter(value),
    })),
    product_fresh: {
      value: dotFormatter(values.harvestAmount.value),
      unit_id: Number(values.harvestAmount.unit),
    },
    product_finished: {
      value: dotFormatter(values.exit.value),
      unit_id: Number(values.exit.unit),
    },
    field_size: {
      value: dotFormatter(values.areal.value),
      unit_id: Number(values.areal.unit),
    },
    residue: {
      value: dotFormatter(values.remainingQuantity.value),
      unit_id: Number(values.remainingQuantity.unit),
      management_id: values.residueManagement,
    },
    seed_amount:
      values.seedAmount.value === undefined
        ? undefined
        : {
            value: dotFormatter(values.seedAmount.value),
            unit_id: Number(values.seedAmount.unit),
          },
  };
}

export const mapCropDetails = (data, language = "pl") => {
  const parsedData = Object.keys(data)
    .map(key => {
      if (key === "crop_id") {
        const val = CROP_TYPES.find(({ id }) => Number(id) === data[key]);
        return {
          name: "Uprawa",
          value: val ? val.label.default : "",
        };
      }
      if (key === "year") {
        return {
          name: "Rok",
          value: `${data[key]}`,
        };
      }

      if (key === "product_fresh") {
        return {
          name: "Wielkość zbioru",
          value: `${data[key].value} ${getUnit(data[key].unit_id, language)}`,
        };
      }

      if (key === "product_finished") {
        return {
          name: "Ilość na wyjściu z gospodarstwa",
          value: `${data[key].value} ${getUnit(data[key].unit_id, language)}`,
        };
      }

      if (key === "field_size") {
        return {
          name: "Areał uprawy",
          value: `${data[key].value} ${getUnit(data[key].unit_id, language)}`,
        };
      }

      if (key === "residue") {
        return {
          name: "Pozostała ilość",
          value: `${data[key].value} ${getUnit(data[key].unit_id, language)}`,
        };
      }

      return null;
    })
    .filter(val => val !== null);

  return parsedData;
};

import React from "react";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import "./CftTooltip.scss";

const CftTooltip = ({ theme, content, placement, style }) => {
  return (
    <Tippy content={content} placement={placement} theme={theme}>
      <span className="cft-tooltip" data-tippy-root style={style}>
        i
      </span>
    </Tippy>
  );
};

CftTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  placement: PropTypes.string,
  theme: PropTypes.string,
};

CftTooltip.defaultProps = {
  theme: "green",
  placement: "top-start",
};

export default CftTooltip;

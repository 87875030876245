export const articleSchema = articleData => {
  if (!articleData) return null;
  const {
    name,
    author_name,
    published_all_at,
    thumb,
    created_at,
    updated_at,
  } = articleData;

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    url: window.location.href,
    mainEntityOfPage: window.location.href,
    headline: name,
    datePublished: published_all_at,
    author: [
      {
        "@type": "Person",
        name: author_name,
      },
    ],
    image: thumb && [thumb.file],
    dateCreated: created_at,
    dateModified: updated_at,
  };
};

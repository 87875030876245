import { lazy } from "react";
import RealEstateLP from "../../components/views/RealEstate/RealEstateLP";
import { PrivacyPolicy, Rodo } from "../../components/views/StaticPages";

const UserToolsPage = lazy(() =>
  import("../../components/blocks/UserToolsPage/UserToolsPage")
);
const AboutPageAlternative = lazy(() =>
  import("../../components/views/AboutPageAlternative/AboutPageAlternative")
);
const AboutAgroraport = lazy(() =>
  import("../../components/views/Agroraport/AboutAgroraport/AboutAgroraport")
);
const AgroCategoryPage = lazy(() =>
  import("../../components/views/Agroraport/AgroCategoryPage/AgroCategoryPage")
);
const AgroArticlesPage = lazy(() =>
  import("../../components/views/Agroraport/MainPage/AgroArticlesPage")
);
const ArticleAgroPage = lazy(() =>
  import("../../components/views/ArticlePage/ArticleAgroPage")
);
const ArticlePage = lazy(() =>
  import("../../components/views/ArticlePage/ArticlePage")
);
const MarketArticlesPage = lazy(() =>
  import("../../components/views/ArticlesPage/MarketArticlesPage")
);
const KnowledgePage = lazy(() =>
  import("../../components/views/ArticlesPage/KnowledgePage")
);
const MultimediasPage = lazy(() =>
  import("../../components/views/MultimediaPage/MultimediasPage")
);
const MultimediaPage = lazy(() =>
  import("../../components/views/MultimediaPage/MultimediaPage")
);
const CalculatorPage = lazy(() =>
  import("../../components/views/CalculatorPage/CalculatorPage")
);
const NitrogenCalculatorPage = lazy(() =>
  import("../../components/views/NitrogenCalculatorPage/NitrogenCalculatorPage")
);

const ContactPage = lazy(() =>
  import("../../components/views/ContactPage/ContactPage")
);
const ContestPage = lazy(() =>
  import("../../components/views/ContestPage/ContestPage")
);
const EventPage = lazy(() =>
  import("../../components/views/EventPage/EventPage")
);
const EventsPage = lazy(() =>
  import("../../components/views/EventsPage/EventsPage")
);
const ExpertsMainPage = lazy(() =>
  import("../../components/views/ExpertsPages/MainPage")
);
const ExpertProfilePage = lazy(() =>
  import("../../components/views/ExpertsPages/ProfilePage")
);
const ExpertContactPage = lazy(() =>
  import("../../components/views/ExpertsPages/ProfilePage/Contact")
);
const RegisterExpertPage = lazy(() =>
  import("../../components/views/ExpertsPages/RegistrationPage")
);
const SearchExperts = lazy(() =>
  import("../../components/views/ExpertsPages/SearchPage")
);
const FarmerPage = lazy(() =>
  import("../../components/views/FarmerPage/FarmerPage")
);
const FarmPage = lazy(() => import("../../components/views/FarmPage/FarmPage"));
const GalleryPage = lazy(() =>
  import("../../components/views/GalleryPage/GalleryPage")
);
const LandingPage = lazy(() =>
  import("../../components/views/LandingPage/LandingPage")
);
const ProfilPage = lazy(() =>
  import("../../components/views/ProfilPage/ProfilPage")
);
const RealEstate = lazy(() =>
  import("../../components/views/RealEstate/RealEstate")
);
const SingleRealEstate = lazy(() =>
  import("../../components/views/RealEstate/SingleRealEstate")
);
const RealEstateSubscription = lazy(() =>
  import("../../components/views/RealEstateSubscription/RealEstateSubscription")
);
const RegistrationBenefits = lazy(() =>
  import("../../components/views/RegistrationBenefits/RegistrationBenefits")
);
const SearchPage = lazy(() =>
  import("../../components/views/SearchPage/SearchPage")
);
const UserGalleryPage = lazy(() =>
  import("../../components/views/UserGalleryPage/UserGalleryPage")
);
const UserSupportPage = lazy(() =>
  import("../../components/views/UserSupportPage/UserSupportPage")
);
const WeatherStationsPage = lazy(() =>
  import("../../components/views/WeatherStationsPage/WeatherStationsPage")
);
// const FoodAndAgroServicePage = lazy(() =>
//   import("../../components/views/ArticlesPage/FoodAndAgroServicePage")
// );
const OvertimePage = lazy(() =>
  import("../../components/views/ArticlesPage/OvertimePage")
);
const QuizzesPage = lazy(() =>
  import("../../components/views/Quizzes/QuizzesPage")
);
const QuizPage = lazy(() => import("../../components/views/Quiz/QuizPage"));
const VoluntarySaleCalculationPage = lazy(() =>
  import(
    "../../components/voluntarySaleCalculation/VoluntarySaleCalculation.page"
  )
);
const CftToolPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/CftTool.page")
);
const AskExpertToolPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/AskExpertTool.page")
);
const AgriculturalProductsPricesPage = lazy(() =>
  import(
    "../../components/views/ToolsPage/tools/AgriculturalProductsPrices.page"
  )
);
const YourWeatherPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/YourWeather.page")
);
const RolnikonPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/Rolnikon.page")
);
// const AgriviPage = lazy(() =>
//   import("../../components/views/ToolsPage/tools/Agrivi.page")
// );
const SatAgroPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/SatAgro.page")
);
const CalculationToolsPage = lazy(() =>
  import("../../components/views/ToolsPage/containers/CalculationTools.page")
);
const MainToolsPage = lazy(() =>
  import("../../components/views/ToolsPage/containers/MainTools.page")
);
const EnvironmentalToolsPage = lazy(() =>
  import("../../components/views/ToolsPage/tools/EnvironmentalTools.page")
);

const SnowNotificationPage = lazy(() =>
  import("../../components/views/WeatherNotificationsPage/SnowNotificationPage")
);
const RainNotificationPage = lazy(() =>
  import("../../components/views/WeatherNotificationsPage/RainNotificationPage")
);
const FrostNotificationPage = lazy(() =>
  import(
    "../../components/views/WeatherNotificationsPage/FrostNotificationPage"
  )
);
const PrivacySettings = lazy(() =>
  import("../../components/views/StaticPages/PrivacySettings")
);
const Tomatoes = lazy(() =>
  import("../../components/views/LandingPage/Tomatoes/Tomatoes")
);
const MaintenanceMode = lazy(() =>
  import("../../components/views/MaintenanceMode/MaintenanceMode")
);
const AgroRegisterPage = lazy(() =>
  import("../../components/views/AgroRegisterPage/AgroRegisterPage")
);

export const routes = [
  { path: "/", component: LandingPage },
  { path: "/s", component: SearchPage },
  { path: "/farmer", component: FarmerPage },
  { path: "/manufacturer", component: FarmerPage },
  { path: "/tools", component: UserToolsPage },
  {
    path: "/narzedzia/satelitarny-monitoring-pol",
    component: SatAgroPage,
  },
  // {
  //   path: "/narzedzia/agrivi",
  //   component: AgriviPage,
  // },
  {
    path: "/narzedzia/agro-rejestr",
    component: AgroRegisterPage,
  },
  {
    path: "/narzedzia/rolnikon",
    component: RolnikonPage,
  },
  {
    path: "/narzedzia/pogoda-dla-twojego-gospodarstwa",
    component: YourWeatherPage,
  },
  {
    path: "/narzedzia/srednie-ceny",
    component: AgriculturalProductsPricesPage,
  },
  { path: "/narzedzia/kalkulator-emisji-co2", component: CftToolPage },
  { path: "/narzedzia/zapytaj-eksperta", component: AskExpertToolPage },
  {
    path: "/narzedzia/narzedzia-srodowiskowe",
    component: EnvironmentalToolsPage,
  },
  {
    path: "/narzedzia/:type",
    component: CalculationToolsPage,
  },
  { path: "/narzedzia", component: MainToolsPage },
  { path: "/my/profil/:userStep", component: ProfilPage },
  { path: "/uzytkownik/:stepType", component: UserSupportPage },
  { path: "/user/:stepType", component: UserSupportPage },
  // { path: "/food-and-agro", component: FoodAndAgroServicePage },
  {
    path: "/artykuly",
    component: KnowledgePage,
  },
  {
    path: "/articles",
    component: KnowledgePage,
  },
  {
    path: "/articles/analizy-food-agro",
    component: MarketArticlesPage,
  },
  {
    path: "/artykuly/analizy-food-agro",
    component: MarketArticlesPage,
  },
  { path: "/po-godzinach/quizy/:slug", component: QuizPage },
  { path: "/quizzes/:slug", component: QuizPage },
  { path: "/overtime/quizzes/:slug", component: QuizPage },
  { path: "/quizzes", component: QuizzesPage },
  { path: "/po-godzinach/quizy", component: QuizzesPage },
  { path: "/overtime/quizzes", component: QuizzesPage },
  { path: "/po-godzinach", component: OvertimePage },
  { path: "/overtime", component: OvertimePage },
  { path: "/artykuly/:slug", component: ArticlePage },
  { path: "/articles/:slug", component: ArticlePage },
  { path: "/multimedia/", component: MultimediasPage },
  { path: "/multimedia/:slug", component: MultimediaPage },
  { path: "/wydarzenia", component: EventsPage },
  { path: "/events", component: EventsPage },
  { path: "/kontakt", component: ContactPage },
  { path: "/contact", component: ContactPage },
  { path: "/events/:slug", component: EventPage },
  { path: "/wydarzenia/:slug", component: EventPage },
  { path: "/kalkulator-kredytowy", component: CalculatorPage },
  { path: "/calculator", component: CalculatorPage },
  { path: "/my/farm", component: FarmPage },
  { path: "/my/farm/:id/rain-notification", component: RainNotificationPage },
  { path: "/my/farm/:id/snow-notification", component: SnowNotificationPage },
  { path: "/my/farm/:id/frost-notification", component: FrostNotificationPage },
  { path: "/registration/:benefit", component: RegistrationBenefits },
  { path: "/pogoda-dla-twojego-gospodarstwa", component: RegistrationBenefits },
  { path: "/analizy-food-agro", component: RegistrationBenefits },
  // {
  //   path: "/systemy-zarzadzania-gospodarstwem",
  //   component: RegistrationBenefits,
  // },
  { path: "/konkurs", component: ContestPage },
  { path: "/srednie-ceny", component: RegistrationBenefits },

  { path: "/zapytaj-eksperta", component: RegistrationBenefits },
  // { path: "/satelitarny-monitoring-pol", component: RegistrationBenefits },
  { path: "/o-nas", component: AboutPageAlternative },
  { path: "/about", component: AboutPageAlternative },
  { path: "/polityka-prywatnosci", component: PrivacyPolicy },
  { path: "/polityka-cookie", component: PrivacySettings },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/cookie-policy", component: PrivacySettings },
  { path: "/rodo", component: Rodo },
  { path: "/agroraport", component: AgroArticlesPage },
  { path: "/agroraport-about", component: AboutAgroraport },
  { path: "/agroraport/:category", component: AgroCategoryPage },
  { path: "/agroraport/artykuly/:slug", component: ArticleAgroPage },
  { path: "/agroraport/article/:slug", component: ArticleAgroPage },
  { path: "/weatherstations", component: WeatherStationsPage },
  { path: "/eksperci", component: ExpertsMainPage },
  { path: "/eksperci/profil/:id", component: ExpertProfilePage },
  { path: "/eksperci/profil/:id/kontakt", component: ExpertContactPage },
  { path: "/eksperci/nowy", component: RegisterExpertPage },
  { path: "/eksperci/wyszukaj", component: SearchExperts },
  { path: "/galeria/moje-zdjecia", component: ProfilPage },
  { path: "/galeria", component: GalleryPage },
  { path: "/gallery", component: GalleryPage },
  { path: "/galeria/:slug", component: UserGalleryPage },
  { path: "/gallery/:slug", component: UserGalleryPage },
  {
    path: "/nieruchomosci",
    component: RealEstateLP,
  },
  {
    path: "/real-estate",
    component: RealEstateLP,
  },
  {
    path: "/nieruchomosci/wyszukiwarka",
    component: RealEstate,
  },
  {
    path: "/real-estate/wyszukiwarka",
    component: RealEstate,
  },
  {
    path: "/nieruchomosci/newsletter-rezygnacja",
    component: RealEstateSubscription,
  },
  {
    path: "/real-estate/newsletter-rezygnacja",
    component: RealEstateSubscription,
  },
  {
    path: "/nieruchomosci/:slug",
    component: SingleRealEstate,
  },
  {
    path: "/real-estate/:slug",
    component: SingleRealEstate,
  },
  {
    path: "/kalkulator-zuzycia-azotu",
    component: NitrogenCalculatorPage,
  },
  {
    path: "/nitrogen-consumption-calculator",
    component: NitrogenCalculatorPage,
  },
  {
    path: "/kalkulator-korzysci-dsn",
    component: VoluntarySaleCalculationPage,
  },
  {
    path: "/benefits-calculator-dsn",
    component: VoluntarySaleCalculationPage,
  },
  {
    path: "/nowy-polski-pomidor",
    component: Tomatoes,
  },
  {
    path: "/konserwacja",
    component: MaintenanceMode,
  },
];

export const routesToSitemap = routes.map(({ path }) => ({ path }));

import { useTranslation } from "react-i18next";
import { getIntPath } from "../../../../services/getIntPath";
import { articlesNavigation } from "../../../../services/helpers/articles";
import avgPricesRoutes from "../../../averagePrices/avgPricesRoutes";

export function useRoute() {
  const {
    t,
    i18n: { language },
  } = useTranslation(["navigation", "articlesMeta", "globals"]);

  const servicesPaths = articlesNavigation(t, language);

  // servicesPaths.splice(6, 0, {
  //   name: t("globals:events.title"),
  //   path: getIntPath(["/wydarzenia", "/events"], language),
  // });

  const NAVIGATION_ROUTES = [
    {
      name: t("navigation:navKnowledge"),
      path: getIntPath(["/artykuly", "/articles"], language),
      exact: true,
      children: servicesPaths,
    },
    {
      name: t("navigation:navTools"),
      path: getIntPath(["/narzedzia", "/narzedzia"], language),
      children: [
        {
          name: t("navigation:tools.optimizationTools"),
          path: getIntPath(
            [
              "/narzedzia/narzedzia-optymalizacyjne",
              "/narzedzia/narzedzia-optymalizacyjne",
            ],
            language
          ),
        },
        {
          name: t("navigation:tools.financial"),
          path: getIntPath(
            [
              "/narzedzia/narzedzia-finansowe",
              "/narzedzia/narzedzia-finansowe",
            ],
            language
          ),
        },
        {
          name: t("navigation:tools.computational"),
          path: getIntPath(
            [
              "/narzedzia/narzedzia-obliczeniowe",
              "/narzedzia/narzedzia-obliczeniowe",
            ],
            language
          ),
        },
        {
          name: t("navigation:tools.informative"),
          path: getIntPath(
            [
              "/narzedzia/narzedzia-informacyjne",
              "/narzedzia/narzedzia-informacyjne",
            ],
            language
          ),
        },
        // {
        //   name: t("navigation:tools.agroRejestr"),
        //   path: getIntPath(
        //     ["/narzedzia/agro-rejestr", "/narzedzia/nagro-rejestr"],
        //     language
        //   ),
        // },
      ],
    },
    {
      name: t("globals:events.title"),
      path: getIntPath(["/wydarzenia", "/events"], language),
    },
    {
      name: t("navigation:afterHours"),
      path: null,
      children: [
        {
          name: t("navigation:navField"),
          path: getIntPath(["/galeria", "/gallery"], language),
        },
        {
          name: t("navigation:quizy"),
          path: getIntPath(["/po-godzinach/quizy", "/quizzes"], language),
        },
        {
          name: t("navigation:ekoGame"),
          path: getIntPath(["/eko-man", "/eko-man"], language),
          isExternalLink: true,
        },
      ],
    },
    {
      name: t("navigation:navPrices"),
      path: null,
      children: [
        {
          name: t("navigation:navGrains"),
          path: getIntPath(
            [
              avgPricesRoutes.GRAINS.GRAINS_MAIN,
              avgPricesRoutes.GRAINS.GRAINS_MAIN,
            ],
            language
          ),
        },
        {
          name: t("navigation:navOils"),
          path: getIntPath(
            [avgPricesRoutes.OILS.OILS_MAIN, avgPricesRoutes.OILS.OILS_MAIN],
            language
          ),
        },
        {
          name: t("navigation:navPork"),
          path: getIntPath(
            [avgPricesRoutes.PORK_MAIN, avgPricesRoutes.PORK_MAIN],
            language
          ),
        },
        {
          name: t("navigation:navPoultry"),
          path: getIntPath(
            [
              avgPricesRoutes.POULTRY.POULTRY_MAIN,
              avgPricesRoutes.POULTRY.POULTRY_MAIN,
            ],
            language
          ),
        },
        {
          name: t("navigation:navDairy"),
          path: getIntPath(
            [
              avgPricesRoutes.DAIRY.DAIRY_MAIN,
              avgPricesRoutes.DAIRY.DAIRY_MAIN,
            ],
            language
          ),
        },
      ],
    },

    {
      name: t("navigation:navAbout"),
      path: getIntPath(["/o-nas", "/o-nas"], language),
    },
  ];

  return {
    routes: NAVIGATION_ROUTES,
  };
}

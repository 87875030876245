import { useEffect } from "react";
import { useFormikContext } from "formik";

export const getFieldErrorNames = formikErrors => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const flatKeys = errors =>
  Object.keys(errors).reduce((pre, crr) => {
    const value =
      typeof errors[crr] !== "string" && !Array.isArray(errors[crr])
        ? `${crr}.${flatKeys(errors[crr])}`
        : crr;

    return [...pre, value];
  }, []);

function CftFormErrorFocus() {
  const { errors, isSubmitting, isValidating } = useFormikContext();

  function getName(keys, errors) {
    const error = errors[keys[0]];
    if (Array.isArray(error)) {
      const k = error.reduce((val, curr, i) => {
        if (!curr) {
          return val;
        }

        if (i < val) {
          return i;
        }

        return val;
      }, error.length);

      const subkeys = Object.keys(error[k]);
      return `${keys[0]}[${k}].${subkeys[0]}`;
    }

    return keys[0];
  }

  function getElement(keys, errors) {
    const selector = getName(keys, errors);
    const alternativeSelector = flatKeys(errors)[0];
    const fieldErrorName = getFieldErrorNames(errors)[0];

    const element =
      document.querySelector(`[id="${selector}"]`) ||
      document.querySelector(`input[name="${selector}"]`) ||
      document.querySelector(`input[name="${alternativeSelector}"]`) ||
      document.querySelector(`input[name="${fieldErrorName}"]`);

    return element;
  }

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const keys = Object.keys(errors);
      if (keys.length > 0) {
        const errorElement = getElement(keys, errors);
        if (errorElement) {
          const y =
            errorElement.getBoundingClientRect().top + window.pageYOffset - 150;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
}

export default CftFormErrorFocus;

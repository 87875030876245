import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftLabel,
  CftFormControl,
  CftSelectField,
  CftTextField,
} from "../../../components";

import { forestTypes } from "./carbonChanges.consts";

function ForestBiomass() {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const {
    values,
    touched,
    setFieldTouched,
    setFieldValue,
  } = useFormikContext();

  const hasForest = values.managementPractice.carbon_changes.reduce(
    (prev, crr) => {
      if (
        [6].includes(crr.land_use.land_from) ||
        [6].includes(crr.land_use.land_to)
      ) {
        return true;
      }

      return prev;
    },
    false
  );

  useEffect(() => {
    if (hasForest) {
      setFieldTouched("managementPractice.forest_biomass.age_years", true);
      setFieldTouched("managementPractice.forest_biomass.forest_type", true);
    }

    if (
      !hasForest &&
      typeof values.managementPractice.forest_biomass !== "undefined"
    ) {
      setFieldValue("managementPractice.forest_biomass", undefined);
    }
  }, [hasForest, touched, values]);

  if (hasForest) {
    return (
      <>
        <h4 className="mb-7" style={{ textAlign: "center" }}>
          {t("cft:carbonChanges.forestBiomass.title", "Forest biomass")}
        </h4>
        <CftFormControl variant="small">
          <CftLabel
            label={t(
              "cft:carbonChanges.forestBiomass.subtitle",
              "If the forest change occurred within the last 20 years, please enter the type of forest, and for a change out of a forest, the forest age."
            )}
          />
        </CftFormControl>
        <CftFormControl variant="small">
          <CftLabel
            label={t(
              "cft:carbonChanges.forestBiomass.forest_type",
              "Forest type"
            )}
          />
          <CftSelectField
            name="managementPractice.forest_biomass.forest_type"
            options={forestTypes.map(forest => ({
              value: forest.id,
              label: forest[language],
            }))}
          />
        </CftFormControl>
        <CftFormControl variant="small">
          <CftLabel
            label={t(
              "cft:carbonChanges.forestBiomass.age_years",
              "Forest age (years)"
            )}
          />
          <CftTextField
            name="managementPractice.forest_biomass.age_years"
            mask="0000"
          />
        </CftFormControl>
      </>
    );
  }

  return null;
}

export default ForestBiomass;

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../index";
import { useCft } from "../../utils/CftProvider";

import "./CftOnboarding.scss";

export const ONBOARDING_COOKIE = "sadda3fwsdfas";

function CftOnboarding() {
  const [isVisible, setVisible] = useState(false);
  const [style, setStyle] = useState(undefined);
  const [step, setStep] = useState(0);
  const { setReportOpen } = useCft();
  const { t } = useTranslation(["cft"]);

  useEffect(() => {
    if (!Cookies.get(ONBOARDING_COOKIE)) {
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflow = "hidden";
      setVisible(true);
    }
  }, []);

  const updateStyle = newStyle => {
    if (JSON.stringify(style) !== JSON.stringify(newStyle)) {
      setStyle(currentStyle => ({
        ...currentStyle,
        ...newStyle,
      }));
    }
  };

  const handleClose = () => {
    setVisible(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.querySelector(".cft-report-progress__overlay").style = undefined;
    document.querySelector("body").style = undefined;
    document.querySelector("html").style.overflow = "unset";
    document.querySelector(".cft-footer__back").style.opacity = "0";
    Cookies.set(ONBOARDING_COOKIE, true, { secure: true });
  };

  const steps = [
    () => {
      const style = {
        width: 1,
        height: 1,
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        opacity: 1,
      };

      updateStyle(style);
    },
    () => {
      const dev = document.querySelector(".cft-report-progress__content");

      window.scrollTo({
        top: dev.getBoundingClientRect().top + window.pageYOffset,
        behavior: "smooth",
      });
      setTimeout(() => {
        if (!isMobile) {
          const dev = document.querySelector(".cft-report-progress__content");
          const box = dev.getBoundingClientRect();
          const style = {
            width: box.width,
            height: box.height,
            top: box.top,
            left: box.left,
            opacity: 1,
          };
          updateStyle(style);
        }
      }, 800);
    },
    () => {
      document.querySelector(
        ".cft-report-progress__overlay"
      ).style.backgroundColor = "transparent";
      if (!isMobile) setReportOpen(true);

      setTimeout(() => {
        if (!isMobile) {
          const dev = document.querySelector(".cft-report-progress--isOpen");
          const box = dev.getBoundingClientRect();
          const style = {
            width: box.width,
            height: box.height,
            top: box.top,
            left: box.left,
            opacity: 1,
          };
          updateStyle(style);
        }
      }, 800);
    },
    () => {
      if (!isMobile) {
        const dev = document.querySelector(".cft-footer");
        const box = dev.getBoundingClientRect();
        setReportOpen(false);

        const style = {
          width: box.width,
          height: box.height,
          top: box.top,
          left: box.left,
          opacity: 1,
          boxShadow: undefined,
        };

        updateStyle(style);
      }
    },
    () => {
      if (!isMobile) {
        const dev = document.querySelector("[data-intro='export-report']");
        const box = dev.getBoundingClientRect();
        const style = {
          width: box.width,
          height: box.height,
          top: box.top,
          left: box.left,
          opacity: 1,
        };
        updateStyle(style);
      }
    },
  ];

  const renderStep = step => {
    steps[step]();
    switch (step) {
      case 1:
        return (
          <div style={{ marginTop: "-4rem" }}>
            <h3 className="cft-onboarding__item__title">
              {t("cft:onboarding.step_1.title", "Postęp kalkulacji")}
            </h3>
            <p className="cft-onboarding__item__subtitle">
              {isMobile
                ? t(
                    "cft:onboarding.step_1.subtitle",
                    "Postęp kalkulacji Twojego raportu zawsze jest widoczny na dole ekranu. Informacja pozwala na uzyskanie informacji w ilu procentach odpowiedziałeś na wszystkie wymagane pytania"
                  )
                : t(
                    "cft:onboarding.step_1.subtitleMobile",
                    "Postęp kalkulacji Twojego raportu jest zawsze widoczny w prawym dolny rogu. Informacja pozwala na uzyskanie informacji w ilu procentach odpowiedziałeś na wszystkie pytania."
                  )}
            </p>
            <CftTank justifyContent="center" alignItems="center">
              <CftButton
                variant="transparent"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.skip", "Pomiń")}
              </CftButton>
              <CftButton
                variant="active"
                onClick={() => setStep(step => step + 1)}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.next", "Dalej")}
              </CftButton>
            </CftTank>
          </div>
        );
      case 2:
        return (
          <div className="cft-onboarding__content__step3">
            <h3 className="cft-onboarding__item__title">
              {t(
                "cft:onboarding.step_2.title",
                "Szczegółowy postęp kalkulacji"
              )}
            </h3>
            <p className="cft-onboarding__item__subtitle">
              {t(
                "cft:onboarding.step_2.subtitle",
                "Po kliknieciu informacji o postępie, możesz zobaczyć jak wygląda postęp Twojej kalkulacji w zależności na kategorie oraz liczbę wymaganych pytań w celu stworzenia pełnego raportu. Dodatkowo, po kliknieciu w poszczególną kategorie, kalkulator przejdzie do tego zestawu pytań."
              )}
            </p>
            <CftTank justifyContent="center" alignItems="center">
              <CftButton
                variant="transparent"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.skip", "Pomiń")}
              </CftButton>
              <CftButton
                variant="active"
                onClick={() => setStep(step => step + 1)}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.next", "Dalej")}
              </CftButton>
            </CftTank>
          </div>
        );
      case 3:
        return (
          <div>
            <h3 className="cft-onboarding__item__title">
              {t("cft:onboarding:step_3:title", "Przejdź dalej…")}
            </h3>
            <p className="cft-onboarding__item__subtitle">
              {t(
                "cft:onboarding:step_3.subtitle",
                "Jeśli uzupełniłeś wszystkie pola w danej kategorii i chcesz przejść dalej skorzystaj z dolnej nawigacji. Jeśli okaże się że chcesz coś zmienić, zawsze możesz kliknąć Cofnij aby wrócić do poprzedniego pytania."
              )}
            </p>
            <CftTank justifyContent="center" alignItems="center">
              <CftButton
                variant="transparent"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.skip", "Pomiń")}
              </CftButton>
              <CftButton
                variant="active"
                onClick={() => setStep(step => step + 1)}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.next", "Dalej")}
              </CftButton>
            </CftTank>
          </div>
        );
      case 4:
        return (
          <div>
            <h3 className="cft-onboarding__item__title">
              {t("cft:onboarding.step_4.title", "Zapisz postęp kalkulacji")}
            </h3>
            <p className="cft-onboarding__item__subtitle">
              {t(
                "cft:onboarding.step_4.subtitle",
                "Jeśli będziesz chciał poźniej wrócić do kalkulacji zapisz jej postęp. Pamiętaj że jeśli wyjdziesz bez zapisanej kalkulacji będziesz musiał zacząć od początku!"
              )}
            </p>
            <CftTank justifyContent="center" alignItems="center">
              <CftButton
                variant="transparent"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.skip", "Pomiń")}
              </CftButton>
              <CftButton
                variant="active"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.next", "Dalej")}
              </CftButton>
            </CftTank>
          </div>
        );
      default:
        return (
          <div>
            <h3 className="cft-onboarding__item__title">
              {t("cft:onboarding.title_1", "Witaj w kalkulatorze emisji")}{" "}
              {t("cft:onboarding.title_2", "twojego gospodarstwa")}
            </h3>
            <p className="cft-onboarding__item__subtitle">
              {t(
                "cft:onboarding:subtitle",
                "Przeprowadzimy Cię przez najważniejsze funkcje kalkulatora, aby łatwiej było nam zacząć wspólną kalkulację. Jeśli to będzie twoja pierwsza kalkulacja zalecamy przejście przez ten krótki samouczek, natomiast jeśli jesteś zaawansowanym użytkownikiem możesz pominąć samouczek w każdym momencie."
              )}
            </p>
            <CftTank justifyContent="center" alignItems="center">
              <CftButton
                variant="transparent"
                onClick={handleClose}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.skip", "Pomiń")}
              </CftButton>
              <CftButton
                variant="active"
                onClick={() => setStep(step => step + 1)}
                style={{ marginTop: "1rem" }}
              >
                {t("cft:onboarding.next", "Dalej")}
              </CftButton>
            </CftTank>
          </div>
        );
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="cft-onboarding" />
      <div className="cft-onboarding__content">{renderStep(step)}</div>
      <div className="cft-onboarding__item" style={style || undefined} />
    </>
  );
}

export default CftOnboarding;

import Cookies from "js-cookie";
import React from "react";
import useSWR from "swr";
import API from "../../../../services/apiHelpers";
import { MarketType } from "../../../averagePrices/types";

export function useDataForRaportCenowy() {
  const url = "/api/v1/market-weeks/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      params: {
        limit: 20,
        ordering: "-week_start_date",
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

export function useListOfCereals() {
  const url = "/api/v1/cereals/";
  const { data } = useSWR(url, () => API().get(url));

  return {
    data: data ? data.data.results : undefined,
  };
}

export function useListOfRegions() {
  const token = Cookies.get("user_token");
  const url = "/api/v1/market-regions/";
  const { data } = useSWR(token && url, () =>
    API().get(url, {
      params: {
        limit: 99,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );

  if (!data) {
    return undefined;
  }

  const { results } = data.data;
  const filtered = results.reduce(
    (prev, region) => {
      if (region.market_type === MarketType.Targowisko) {
        prev.markets.push(region);
      } else {
        prev.purchase.push(region);
      }
      return prev;
    },
    { purchase: [], markets: [] }
  );

  filtered.purchase.sort((a, b) => a.name.localeCompare(b.name));
  filtered.markets.sort((a, b) => a.name.localeCompare(b.name));

  return {
    regions: filtered.purchase,
    markets: filtered.markets,
  };
}

export function useFilteredCerealsData(userDefaults) {
  const [data, setFilteredRaportData] = React.useState();

  React.useEffect(() => {
    if (userDefaults) {
      const token = Cookies.get("user_token");

      const fetchData = async () => {
        const {
          market_place,
          market_type,
          region,
          interested_products,
          destination,
        } = userDefaults;

        let filters;
        if (market_type === MarketType.Targowisko) {
          filters = {
            market_type: MarketType.Targowisko,
            region,
            market_place,
          };
        } else {
          filters = {
            market_type: MarketType.Skup,
            region,
            destination,
          };
        }

        try {
          const { data } = await API().get("/api/v1/market-weeks/", {
            params: {
              limit: 20,
              ordering: "-week_start_date",
              products_list: interested_products.join(","),
              ...filters,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setFilteredRaportData(data);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error("COULD NOT FETCH THE DATA WITH GIVEN FILTERS");
        }
      };
      fetchData();
    }
  }, [userDefaults]);

  return { data };
}

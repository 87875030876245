/* eslint-disable no-undef */
import Cookies from "js-cookie";
import * as actions from "./actions";
import { objectToGetParams } from "../../../helpers/utilities";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  isError: false,
};

export default function expertsAll(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_EXPERTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }

    case actions.GET_EXPERTS_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getExperts = (page = 1, filters = {}, limit = 20) => {
  const token = Cookies.get("user_token");
  const params = objectToGetParams(filters);
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : null;
  const offset = page > 1 ? (page - 1) * limit : 0;
  return {
    type: actions.GET_EXPERTS,
    payload: {
      request: {
        url: `/api/v1/experts/${params}&limit=${limit}&offset=${offset}`,
        method: "GET",
        headers,
      },
    },
  };
};

// import * as d3 from "d3";
import { arc, entries, pie, scaleOrdinal } from "d3";
import React from "react";

const Slice = ({ fill, d }) => {
  return <path fill={fill} d={d} />;
};

const Label = ({ text, translate, fill = "#fff" }) => {
  return (
    <text y={5} transform={`translate(${translate})`} fill={fill}>
      {text}
    </text>
  );
};

const DataSet = ({ pie, arc, radius }) => {
  const slices = pie.map(item => (
    <Slice fill={item.data.value.color} d={arc()(item)} />
  ));
  const labels = pie
    .filter(d => d.data.value.fraction > 0)
    .map(d => {
      const coords =
        d.data.value.fraction > 10
          ? arc().centroid(d)
          : arc(radius + (radius - 1)).centroid(d);
      return (
        <Label
          text={`${d.data.value.fraction}%`}
          translate={coords}
          fill={d.data.value.fraction < 10 ? d.data.value.color : undefined}
        />
      );
    });
  return (
    <>
      <g className="c-poll-chart__pie-slices" strokeWidth="8">
        {slices}
      </g>
      <g>{labels}</g>
    </>
  );
};

const PieChart = ({ data, width, height, margin, innerRadius, colors }) => {
  const translation = `translate(${width / 2}, ${height / 2})`;
  const radius = Math.min(width, height) / 2 - margin / 2 + 4;
  // eslint-disable-next-line no-underscore-dangle
  const _pie = pie().value(d => d.value.value);
  const pieData = _pie(entries(data));
  const color = scaleOrdinal()
    .domain(data)
    .range(colors);

  // eslint-disable-next-line no-underscore-dangle
  const _arc = biggerRadius =>
    arc()
      .innerRadius(innerRadius) // This is the size of the donut hole
      .outerRadius(biggerRadius || radius);

  return (
    <svg width={width} height={height}>
      <g transform={translation}>
        <DataSet
          pie={pieData}
          arc={_arc}
          color={color}
          radius={radius}
          innerRadius={innerRadius}
        />
      </g>
    </svg>
  );
};

export default PieChart;

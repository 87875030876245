import React, { Component } from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import API from "../../../services/apiHelpers";
import { ButtonFix, AgroCategoriesEl } from "../index";
import "./Poll.scss";
import { PollResults } from "./PollResults";
import { PollResponse } from "./PollResponses";

function getValueFromRadioButton(name) {
  const buttons = document.getElementsByName(name);

  for (let i = 0; i < buttons.length; i += 1) {
    const button = buttons[i];

    if (button.checked) {
      return button.value;
    }
  }
  return null;
}

class Poll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poll: this.props.poll,
      token: Cookies.get("user_token"),
      voted: this.props.poll ? this.props.poll.voted : false,
      minVotes: 10,
      votes: this.countVotes(),
    };
  }

  countVotes = () =>
    this.props.poll && this.props.poll.possibilities.length
      ? this.props.poll.possibilities
          .map(item => parseInt(item.votes))
          .reduce((prev = 0, curr) => prev + curr)
      : 0;

  prepareResults = () => {
    const sum = this.countVotes();
    const resultsObject = this.props.poll.possibilities
      .map(item => ({
        label: item.answer,
        value: item.votes,
        fraction: Math.round((item.votes / sum) * 100),
      }))
      .sort((a, b) => b.value - a.value);

    return resultsObject;
  };

  handlePollSubmit = async e => {
    e.preventDefault();
    const { token } = this.state;
    if (token) {
      const possibilitieID = parseInt(getValueFromRadioButton("answer"));
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(possibilitieID)) {
        const { id } = this.props.poll;
        // setVoted(true);
        await API()
          .post(
            `/api/v1/polls/${id}/vote/`,
            {
              possibility_id: possibilitieID,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(({ data }) => {
            if (data) {
              this.setState({
                poll: data,
                voted: true,
                votes: data.votes,
              });
            }
          });
      }
    } else {
      this.setState({
        voted: true,
      });
    }
  };

  render() {
    const { className, category, report, index } = this.props;
    const { token, voted, minVotes, votes } = this.state;
    const minimum = votes >= minVotes;
    // eslint-disable-next-line no-underscore-dangle
    const _category = category || className.includes("c-poll--slide");

    const poll = this.state.poll || this.props.poll;

    if (!poll) return <div className="c-poll">brak sondy</div>;
    return (
      <>
        {index === 0 && className === "c-poll--big" ? (
          <div className="typography--h1 u-font-v-middle u-font-green mb-3">
            {voted || (voted && token && minimum)
              ? "Wyniki sondy"
              : "Zainteresował Cię temat? Weź udział w sondzie:"}
          </div>
        ) : null}
        <div
          className={`c-poll ${className}${voted ? " c-poll--voted" : ""}${
            voted && token && minimum ? " c-poll--voted-2" : ""
          }`}
        >
          {!voted || (voted && token && minimum) ? (
            <>
              <div className="c-poll-heading__row">
                <div
                  className={`d-flex mb-4${
                    _category ? " justify-content-end" : ""
                  }`}
                  style={{ minHeight: "24px" }}
                >
                  {!_category ? (
                    <span className="c-poll-heading">Sonda</span>
                  ) : (
                    category &&
                    category > 10 && (
                      <AgroCategoriesEl id={category} type="small" />
                    )
                  )}
                </div>
                <h3 className="c-poll-question">{poll.title}</h3>
              </div>
              {!voted ? (
                <form onSubmit={this.handlePollSubmit}>
                  {poll.possibilities.map(answer => (
                    <label
                      htmlFor={`answer${answer.id}`}
                      className="c-poll-answer"
                    >
                      <input
                        type="radio"
                        name="answer"
                        id={`answer${answer.id}`}
                        value={answer.id}
                      />
                      <span className="c-poll-answer__replacement" />
                      <span className="c-poll-answer__txt">
                        {answer.answer}
                      </span>
                    </label>
                  ))}
                  <div>
                    <ButtonFix
                      buttoncolor="green"
                      buttonsize="higher"
                      absolute={
                        className.includes("c-poll--side") ||
                        className.includes("c-poll--slide")
                      }
                      type="submit"
                    >
                      Głosuj
                    </ButtonFix>
                  </div>
                </form>
              ) : (
                <PollResults results={this.prepareResults()} />
              )}
            </>
          ) : null}
          <PollResponse
            voted={voted}
            token={token}
            minimum={minimum}
            report={report}
            redirect={() =>
              this.props.dispatch({
                type: "SET_REDIRECT",
                data: window.location.pathname,
              })
            }
          />
        </div>
      </>
    );
  }
}

export default connect()(Poll);

export const managementPractice = {
  arable: [
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 1,
      id: 21,
    },
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 2,
      id: 22,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 1,
      id: 17,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 2,
      id: 18,
    },
  ],
  grass: [
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 1,
      id: 13,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 2,
      id: 14,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 1,
      id: 9,
    },
    {
      pl: "Las",
      gb: "Forest",
      soil_moisture_id: 2,
      id: 10,
    },
  ],
  forest: [
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 1,
      id: 1,
    },
    {
      pl: "Trawa",
      gb: "Grass",
      soil_moisture_id: 2,
      id: 2,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 1,
      id: 5,
    },
    {
      pl: "Orny",
      gb: "Arable",
      soil_moisture_id: 2,
      id: 6,
    },
  ],
};

export const forestTypes = [
  {
    pl: "Borealny las liściasty (tajga)",
    gb: "Boreal coniferous forest",
    id: 8,
  },
  {
    pl: "Borealny układ górski",
    gb: "Boreal mountain system",
    id: 9,
  },
  {
    pl: "Borealny las tundry",
    gb: "Boreal tundra woodland",
    id: 10,
  },
  {
    pl: "Umiarkowany las kontynentalny",
    gb: "Temperate continental forest",
    id: 13,
  },
  {
    pl: "Umiarkowane układy górskie",
    gb: "Temperate mountain systems",
    id: 14,
  },
  {
    pl: "uUmiarkowany las oceaniczny",
    gb: "Temperate oceanic forest",
    id: 15,
  },
];

export const initCarbonChange = {
  year_of_change: null,
  allocation: null,
  land_use: {
    land_from: null,
    land_to: null,
  },
  tillage: {
    tillage_from: null,
    tillage_to: null,
  },
  inputs: {
    inputs_from: null,
    inputs_to: null,
  },
};

export const initManagementPractice = {
  carbon_changes: [initCarbonChange],
  forest_biomass: undefined,
};

export const managementPracticeTillages = [
  {
    pl: "Uprawa bezpłużna – uprawa zredukowana",
    gb: "no till » reduced till",
    soil_moisture_id: 1,
    id: 25,
  },
  {
    pl: "Uprawa bezpłużna – uprawa zredukowana",
    gb: "no till » reduced till",
    soil_moisture_id: 2,
    id: 26,
  },
  {
    pl: "uprawa bezpłużna – uprawa konfencjonalna",
    gb: "no till » conventional till",
    soil_moisture_id: 1,
    id: 29,
  },
  {
    pl: "uprawa bezpłużna – uprawa konfencjonalna",
    gb: "no till » conventional till",
    soil_moisture_id: 2,
    id: 30,
  },
  {
    pl: "uprawa zredukowana – uprawa bezpłużna",
    gb: "reduced till » no till",
    soil_moisture_id: 1,
    id: 33,
  },
  {
    pl: "uprawa zredukowana – uprawa bezpłużna",
    gb: "reduced till » no till",
    soil_moisture_id: 2,
    id: 34,
  },
  {
    pl: "uprawa zredukowana – uprawa konwencjonalna",
    gb: "reduced till » conventional till",
    soil_moisture_id: 1,
    id: 37,
  },
  {
    pl: "uprawa zredukowana – uprawa konwencjonalna",
    gb: "reduced till » conventional till",
    soil_moisture_id: 2,
    id: 38,
  },
  {
    pl: "uprawa konwencjonalna – uprawa bezpłużna",
    gb: "conventional till » no till",
    soil_moisture_id: 1,
    id: 41,
  },
  {
    pl: "uprawa konwencjonalna – uprawa bezpłużna",
    gb: "conventional till » no till",
    soil_moisture_id: 2,
    id: 42,
  },
  {
    pl: "uprawa konwencjonalna – uprawa zredukowana",
    gb: "conventional till » reduced till",
    soil_moisture_id: 1,
    id: 45,
  },
  {
    pl: "uprawa konwencjonalna – uprawa zredukowana",
    gb: "conventional till » reduced till",
    soil_moisture_id: 2,
    id: 46,
  },
];

export const initTillage = {
  tillageChange: "",
  tillageYearsAgo: "",
  tillageAllocation: "",
};

export const managementPracticeCovers = [
  {
    pl: "poplony – brak poplonów",
    gb: "cover crop » no cover crop",
    soil_moisture_id: 1,
    id: 53,
  },
  {
    pl: "poplony – brak poplonów",
    gb: "cover crop » no cover crop",
    soil_moisture_id: 2,
    id: 54,
  },
  {
    pl: "brak poplonów – poplony",
    gb: "no cover crop » cover crop",
    soil_moisture_id: 1,
    id: 49,
  },
  {
    pl: "brak poplonów – poplony",
    gb: "no cover crop » cover crop",
    soil_moisture_id: 2,
    id: 50,
  },
];

export const carbonInputsTypes = [
  {
    pl: "Wysoka zawartość C bez obornika",
    gb: "High C input without manure",
    id: 1,
  },
  {
    pl: "Wysoka zawartość C z obornikiem",
    gb: "High C input with manure",
    id: 2,
  },
  {
    pl: "Średnia zawartość C",
    gb: "Medium C input",
    id: 3,
  },
  {
    pl: "Niska zawartość C",
    gb: "Low C input",
    id: 4,
  },
];

export const tillageTypes = [
  {
    pl: "Pełna",
    gb: "Full",
    id: 1,
  },
  {
    pl: "Uproszczona",
    gb: "Reduced",
    id: 2,
  },
  {
    pl: "Bezorkowa",
    gb: "No-till",
    id: 3,
  },
];

export const landUseTypes = [
  {
    pl: "Uprawiany",
    gb: "Cultivated",
    id: 1,
  },
  {
    pl: "Bylina",
    gb: "Perennial",
    id: 3,
  },
  {
    pl: "Odłogowanie",
    gb: "Set aside",
    id: 4,
  },
  {
    pl: "Użytki zielone",
    gb: "Native grassland",
    id: 5,
  },
  {
    pl: "Rodzimy las",
    gb: "Native forest",
    id: 6,
  },
];

export const initCover = {
  coverChange: "",
  coverYearsAgo: "",
  coverAllocation: "",
};

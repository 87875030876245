import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import cl from "classnames";
import { useIMask } from "react-imask";
import CftError from "../CftError/CftError";

import "./CftTextField.scss";

function CftTextField({
  name,
  placeholder,
  adornment,
  value,
  onChange,
  isRounded,
  isAlternative,
  type,
  mask,
  definitions,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const adornmentRef = useRef();
  const hasMask = mask.length > 0;

  const { ref, setValue } = useIMask(
    { mask, definitions },
    {
      onAccept: (value, mask, event) => {
        if (hasMask && !!event) {
          if (onChange) {
            onChange(event);
          } else {
            field.onChange(event);
          }
        }
      },
    }
  );

  useEffect(() => {
    if (hasMask) {
      const defaultValue = value || field.value;
      setValue(`${defaultValue}`);
    }
  }, []);

  return (
    <div
      className={cl("cft-text-field", {
        "cft-text-field--isInvalid": meta.touched && meta.error,
        "cft-text-field--rounded": isRounded,
        "cft-text-field--alternative": isAlternative,
      })}
    >
      <div className="cft-text-field__input">
        {hasMask ? (
          <input
            ref={hasMask ? ref : undefined}
            type={type}
            placeholder={placeholder}
            id={field.name}
            style={{
              paddingRight: adornmentRef.current
                ? `${adornmentRef.current.clientWidth + 16}px`
                : "1rem",
            }}
            name={field.name}
            defaultValue={value || field.value}
            onBlur={field.onBlur}
            {...props}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            id={field.name}
            style={{
              paddingRight: adornmentRef.current
                ? `${adornmentRef.current.clientWidth + 16}px`
                : "1rem",
            }}
            onBlur={e => {
              const val = e.target.value || "";
              const number = Number(val.replace(/,/, "."));

              if (!Number.isNaN(number) && val !== "") {
                helpers.setValue(`${Number(number)}`.trimStart().trimEnd());
              } else {
                helpers.setValue(val.trimStart().trimEnd());
              }

              field.onBlur(e);
            }}
            name={field.name}
            value={value || field.value}
            onChange={onChange || field.onChange}
            {...props}
          />
        )}

        {adornment && (
          <div ref={adornmentRef} className="cft-text-field__adornment">
            {adornment}
          </div>
        )}
      </div>
      <CftError isActive={meta.touched && meta.error} message={meta.error} />
    </div>
  );
}

CftTextField.propTypes = {
  name: PropTypes.string.isRequired,
  adornment: PropTypes.string,
  isRounded: PropTypes.bool,
  isAlternative: PropTypes.bool,
  type: PropTypes.string,
  mask: PropTypes.string,
};

CftTextField.defaultProps = {
  adornment: null,
  isRounded: false,
  isAlternative: false,
  type: "text",
  mask: "",
};

export default CftTextField;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftActionsButtons,
  CftTank,
  CftTurkeyHeader,
  CftLeavingModal,
  CftExportData,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { TurkeyCoopsForm } from "../../forms/turkey";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";
import "../styles/progressDashboard.scss";

export default function TurkeyPage() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const history = useHistory();
  const { store } = useCftTurkeyValues();
  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          cropName={t("cftTurkey:tag", "")}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportData type="turkey" />
              <CftActionsButtons.CancelButton
                hasText={false}
                onClick={() => {
                  stopOverflow();
                  setShowLeavingModal(!showLeavingModal);
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        withNoSpace
        header={
          <CftTank display="flex" flexDirection="column" alignItems="center">
            <h3 className="cft-wrapper">
              {t("cftTurkey:tag", "kalkulator emisji")}
            </h3>
            <h4 className="cft-wrapper">
              {t("cftTurkey:tag", "Indyki rzeźne")}
            </h4>
            <CftTurkeyHeader />
          </CftTank>
        }
      >
        <TurkeyCoopsForm />
      </CftSection>
      <CftLeavingModal
        isVisible={showLeavingModal}
        hide={() => setShowLeavingModal(!showLeavingModal)}
        type="bothCalc"
        goToProfile={() => history.push(routes.MAIN)}
        dataReport={JSON.stringify(store)}
      />
    </CftLayout>
  );
}

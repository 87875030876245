import React from "react";

import "./RealEstateVideo.scss";

function RealEstateVideo() {
  return (
    <div className="real-estate-video b-section">
      <header>
        <h2>Zobacz video</h2>
      </header>
      <div className="real-estate-video__playback">
        <iframe
          title="video"
          src="https://www.youtube.com/embed/ut68fYGzG-w"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default RealEstateVideo;

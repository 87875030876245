import PropTypes from "prop-types";
import React, { Component } from "react";
import { ButtonAdditional } from "../ButtonAdditional/ButtonAdditional";
import "./Pagination.scss";

class SimplePagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  handlePageSelection(page) {
    const { currentPage } = this.state;
    let newCurrentPage = 0;
    switch (page) {
      case "next":
        newCurrentPage = currentPage + 1;
        break;

      case "prev":
        newCurrentPage = currentPage - 1;
        break;

      default:
        newCurrentPage = page;
        break;
    }
    this.setState({
      currentPage: newCurrentPage,
    });
    this.props.onPageChange(newCurrentPage);
    // onPageChange("prev");
  }

  render() {
    const { pageNumbers, maximumPages } = this.props;
    const { currentPage } = this.state;
    if (pageNumbers.length <= 1) return null;
    return (
      <div className="pagination-container">
        <ButtonAdditional
          className={`${
            currentPage === 1 ? "pagination-arrow-prev--disabled" : ""
          } pagination-arrow-prev`}
          buttoncolor="yellow"
          iconColor="#FFA203"
          onClickIcon={() => this.handlePageSelection("prev")}
        />
        <div className="pages-container">
          {pageNumbers.map(item => (
            <div
              key={item}
              className={
                item === currentPage ? "page-item--selected" : "page-item"
              }
              onClick={() => this.handlePageSelection(item)}
              role="button"
              tabIndex={0}
            >
              {item}
            </div>
          ))}
        </div>
        <ButtonAdditional
          className={`${
            maximumPages === currentPage
              ? "pagination-arrow-next--disabled"
              : ""
          } pagination-arrow-next`}
          buttoncolor="yellow"
          iconColor="#FFA203"
          onClickIcon={() => this.handlePageSelection("next")}
        />
      </div>
    );
  }
}

SimplePagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
};

export default SimplePagination;

import React from "react";
import clx from "classnames";
import { useTranslation } from "react-i18next";

import { nFormatter } from "../../utils/cftUtils";

import "./CftGraph.scss";

function getLegend(val, factor) {
  if (val === 0) {
    return 0;
  }
  return (val / factor)
    .toFixed(0)
    .split("")
    .join("");
}

function CftGraphScale({ value, legend }) {
  return (
    <>
      <span className="cft-graph__scale" style={{ bottom: value }} />
      <span className="cft-graph__legend" style={{ bottom: value }}>
        <span>{legend}</span>
      </span>
    </>
  );
}

function CftGraphBar({ children, value, legend, color }) {
  const isNegative = value < 0;

  return (
    <div
      className={clx("cft-graph__bar", {
        "cft-graph__bar--isNegative": isNegative,
      })}
    >
      <span
        className="cft-graph__bar__filed"
        style={{
          bottom: isNegative ? `${value}px` : 0,
          height: `${Math.abs(value)}px`,
          backgroundColor: color,
        }}
      >
        <span className="cft-graph__bar__tip">
          <span
            style={{
              top: isNegative ? `calc(${Math.abs(value)}px + 0.5rem)` : "-1rem",
              color,
            }}
          >
            {legend || value}
          </span>
        </span>
      </span>
      <span className="cft-graph__bar__title">{children}</span>
    </div>
  );
}

function getY(data) {
  const top = getLegend(data.max, data.factor);
  const bottom = getLegend(data.min, data.factor);
  const range = 8;
  const dif = Math.abs(top) + Math.abs(bottom);
  const scale = dif / range;
  let i = 0;
  return Array(range)
    .fill("")
    .map((_, key) => {
      let legend;
      if (key * scale <= top) {
        legend = (key + 1) * scale;
      } else {
        i += 1;
        legend = 0 - scale * i;
      }
      const formatedLegend = legend >= 1000000 ? nFormatter(legend) : legend;
      return {
        legend: Math.ceil(formatedLegend / 10) * 10,
        value: legend * data.factor,
      };
    });
}

function CftGraph({ data, indexLabel, alternativeView }) {
  const { t } = useTranslation(["cft"]);
  const minMax = data.reduce(
    (prev, curr) => {
      const min = prev.min < curr.value ? prev.min : curr.value;
      const max = prev.max > curr.value ? prev.max : curr.value;

      return {
        min: min === 0 ? 0 : min,
        max: max === 0 ? 0 : max,
        factor: curr.factor,
      };
    },
    {
      min: 0,
      max: 0,
      factor: 0,
    }
  );

  const top = minMax.min + minMax.max;

  const y = getY(minMax);
  return (
    <div
      className={clx("cft-graph", { alternative: alternativeView })}
      style={{ top: top < 0 ? `${top / 1.5}px` : `${top / 2}px` }}
    >
      <div>
        {data
          .filter(({ value }) => value !== 0)
          .map(({ label, value, legend, color }) => (
            <CftGraphBar value={value} legend={legend} color={color}>
              {indexLabel ? label : t(`cft:report.table.${label}`, label)}
            </CftGraphBar>
          ))}
        <CftGraphScale value={0} legend="0" />
        {y.map(line => (
          <CftGraphScale value={line.value} legend={line.legend} />
        ))}
      </div>
    </div>
  );
}

export default CftGraph;

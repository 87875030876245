export const formatAmount = ({ amount, showZero, withCurrency = true }) => {
  const numberWithSpaces = x => {
    const parts = x.toString().split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  };
  let tmp = amount;
  if (typeof amount === "string") {
    tmp = Number(tmp);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(tmp)) {
      return "-";
    }
  }

  const amt = numberWithSpaces(
    tmp.toLocaleString("pl-PL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
  const zero = showZero
    ? `${numberWithSpaces(
        parseFloat(0.0).toLocaleString("pl-PL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      )}${withCurrency ? " PLN" : ""}`
    : "-";

  return !amount ? zero : `${amt}${withCurrency ? " PLN" : ""}`;
};

export const parseMoney = amount => {
  let amt = "";
  if (amount) {
    amt = amount.replace(",", ".");
    if (amount.length > 1 && amount.startsWith("0")) {
      amt = amount.replace("0", "");
    }
  }
  return amt || "";
};

export const formatContributionRate = ({ rate }) =>
  rate.toString().endsWith(".") ? rate.replace(".", "") : rate;

export const buildEntry = ({
  year,
  month,
  interestPeriod,
  capitalInstallmentNumber,
  capitalInstallmentAmount,
  currentCreditAmount,
  interestInstallmentAmount,
  debt,
  currentSubventionAmount = 0,
}) => ({
  year,
  month,
  interestPeriod,
  capitalInstallmentNumber,
  capitalInstallmentAmount,
  currentCreditAmount,
  currentSubventionAmount,

  interestInstallmentAmount:
    interestInstallmentAmount > 0 ? interestInstallmentAmount : 0,

  capitalWithInterestInstallmentAmount:
    capitalInstallmentAmount + interestInstallmentAmount,
  debt,
});

/* eslint-disable react/default-props-match-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import cl from "classnames";

import CftError from "../CftError/CftError";

import "./CftNumberField.scss";

function getValue(value) {
  if (value === "") {
    return "";
  }

  if (Number.isNaN(value)) {
    return 0;
  }

  return Number(value);
}

function CftNumberField({ name, placeholder, min, max }) {
  const [field, meta, helpers] = useField(name);

  const value = getValue(field.value);

  return (
    <div
      className={cl("cft-number-field", {
        "cft-number-field--isInvalid": meta.touched && meta.error,
      })}
    >
      <div className="cft-number-field__input">
        <span
          className="cft-number-field__control cft-number-field__control--minus"
          onClick={() => {
            if (min !== null) {
              if (value > min) {
                helpers.setValue(value - 1);
              }
            } else {
              helpers.setValue(value - 1);
            }
          }}
        >
          -
        </span>
        <input
          type="number"
          placeholder={placeholder}
          {...field}
          id={field.name}
          min={min}
          defaultValue={0}
          value={value}
        />
        <span
          className="cft-number-field__control cft-number-field__control--plus"
          onClick={() => {
            if (max !== null) {
              if (value < max) {
                helpers.setValue(value + 1);
              }
            } else {
              helpers.setValue(value + 1);
            }
          }}
        >
          +
        </span>
      </div>
      <CftError isActive={meta.touched && meta.error} message={meta.error} />
    </div>
  );
}

CftNumberField.propTypes = {
  name: PropTypes.string.isRequired,
};

CftNumberField.defaultProps = {
  min: null,
  max: null,
};

export default CftNumberField;

import { useTranslation } from "react-i18next";

const units = [
  {
    value: 1,
    pl: "m",
    gb: "metre",
  },
  {
    value: 2,
    pl: "km",
    gb: "kilometre",
  },
  {
    value: 3,
    pl: "cm",
    gb: "centimetre",
  },
  {
    value: 7,
    pl: "kg",
    gb: "kilogram",
  },
  {
    value: 8,
    pl: "kg/a",
    gb: "kg / acre",
  },
  {
    value: 9,
    pl: "t",
    gb: "tonne",
  },
  {
    value: 10,
    pl: "ha",
    gb: "hectare",
  },
  {
    value: 11,
    pl: "a",
    gb: "acre",
  },
  {
    value: 12,
    pl: "t/ha",
    gb: "tonne / ha",
  },
  {
    value: 15,
    pl: "l",
    gb: "litre",
  },
  {
    value: 24,
    pl: "kg/ha",
    gb: "kg / ha",
  },
  {
    value: 56,
    pl: "m2",
    gb: "square metre",
  },
  {
    value: 45,
    pl: "lat",
    gb: "year",
  },
  {
    value: 46,
    pl: "msc.",
    gb: "months",
  },
  {
    value: 47,
    pl: "tyg.",
    gb: "week",
  },
  {
    value: 48,
    pl: "dni",
    gb: "day",
  },
  {
    value: 35,
    pl: "MJ",
    gb: "MJ",
  },
  {
    value: 36,
    pl: "kWh",
    gb: "kWh",
  },
];

export const getUnit = (id, language) => {
  const unit = units.find(({ value }) => value === id);

  return unit ? unit[language] : "";
};

export default function useCftOptions() {
  const { t, i18n } = useTranslation(["cft", "calculatorPage"]);
  const year = new Date().getFullYear();

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(),
    label: t(`calculatorPage:months.${i}`),
  }));

  const years = Array.from({ length: 250 }, (_, i) => ({
    value: (year - i).toString(),
    label: (year - i).toString(),
  }));

  const getUnits = (includeUnits = []) =>
    units
      .filter(({ pl }) => includeUnits.indexOf(pl) !== -1)
      .map(unit => ({
        value: unit.value,
        label: unit[i18n.language],
      }));

  return {
    months,
    years,
    year,
    getUnits,
  };
}

import React from "react";
import PropTypes from "prop-types";
import "./Link.scss";

// linkType - props value "cancel" or "follow"

export const Link = props => {
  return (
    <div className={`link--${props.linkType}`}>
      <a href={props.href} target="_self">
        {props.children}
      </a>
    </div>
  );
};

Link.propTypes = {
  linkType: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  linkType: "",
  href: "",
  children: null,
};

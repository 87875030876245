import React from "react";
import { useTranslation } from "react-i18next";
import clx from "classnames";

import { ReactComponent as CancelIcon } from "../../../assets/icons/cft/anuluj.svg";
import { ReactComponent as GoBackIcon } from "../../../assets/icons/cft/woc.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/cft/zapisz.svg";
import { CftButton } from "../index";

import "./CftActionsButtons.scss";

function CancelButton({ onClick, text, hasText = true }) {
  const { t } = useTranslation(["cft"]);
  return (
    <CftButton variant="text" onClick={onClick}>
      <span className="cft-actions-buttons cft-actions-buttons--cancel">
        {hasText && <span>{text || t("cft:cancel", "Anuluj")}</span>}
        <CancelIcon />
      </span>
    </CftButton>
  );
}

function GoToProfile({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <CftButton variant="text" onClick={onClick}>
      <span className="cft-actions-buttons cft-actions-buttons--goBack">
        <span>{t("cft:goBackButton", "Wróć do profilu")}</span>
        <GoBackIcon />
      </span>
    </CftButton>
  );
}

function ExportReport({ onClick, isSmall, children, noMargin }) {
  const { t } = useTranslation(["cft"]);
  return (
    <CftButton
      data-intro="export-report"
      variant="export"
      noMargin={noMargin}
      onClick={onClick}
    >
      <div
        className={clx("cft-actions-buttons cft-actions-buttons--export", {
          "cft-actions-buttons--export--isSmall": isSmall,
        })}
      >
        {isSmall ? (
          <p>{t("cft:exportReportSmall", "ZAPISZ")}</p>
        ) : (
          <p>{t("cft:exportReport", "ZAPISZ KALKULACJĘ")}</p>
        )}
        <SaveIcon />
      </div>
      {children}
    </CftButton>
  );
}

function ImportReport({ onChange, id }) {
  const { t } = useTranslation(["cft"]);
  return (
    <CftButton data-intro="import-report" variant="import">
      <label htmlFor={id} style={{ margin: "0" }}>
        <input type="file" id={id} onChange={onChange} accept=".json" />
        <div className={clx("cft-actions-buttons cft-actions-buttons--import")}>
          <p>{t("cft:chooseFile", "Wybierz plik")}</p>
          <SaveIcon />
        </div>
      </label>
    </CftButton>
  );
}

function GoToCalc({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <CftButton variant="text" onClick={onClick}>
      <span className="cft-actions-buttons cft-actions-buttons--goBackCalc">
        <GoBackIcon />
        <span>{t("cft:goBackCalculator", "Wróć do kalkulatora")}</span>
      </span>
    </CftButton>
  );
}

export default {
  CancelButton,
  GoToProfile,
  ExportReport,
  ImportReport,
  GoToCalc,
};

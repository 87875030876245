import Cookies from "js-cookie";
import useSWR from "swr";
import API from "../../../../services/apiHelpers";
import { Pork } from "../../../averagePrices/types";

export function useListOfPorkProducts() {
  const url = "/api/v1/pork/products/";
  const { data } = useSWR(url, () => API().get(url));

  return {
    data: data ? data.data : undefined,
  };
}

export function useDataForRaportCenowy(userDefaults) {
  const filters = {};
  if (userDefaults) {
    const { interested_products, regions, pork_classes } = userDefaults;

    if (interested_products[0] === Pork.MPC) {
      filters.classes_list = pork_classes.join(",");
    } else {
      filters.regions_list = regions.join(",");
    }
  }

  const url = "/api/v1/pork/";
  const dependencies = [url, filters.regions_list, filters.classes_list];
  const { data } = useSWR(dependencies, () =>
    API().get(url, {
      params: {
        limit: 20,
        ordering: "-week_start_date",
        ...filters,
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

export function useListOfRegions() {
  const token = Cookies.get("user_token");
  const authToken = token ? { Authorization: `Bearer ${token}` } : null;

  const url = "/api/v1/pork/regions/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      headers: authToken,
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

export function usePorkClasses() {
  const token = Cookies.get("user_token");
  const authToken = token ? { Authorization: `Bearer ${token}` } : null;

  const url = "/api/v1/pork/classes/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      headers: authToken,
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Step0, Step1, Step2, Step3, Step4, Step5 } from "./steps";

import "./CftOnboardingPoultry.scss";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function CftOnboardingPoultry() {
  const [isVisible, setVisible] = useState(false);
  const [style, setStyle] = useState(undefined);
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!Cookies.get(ONBOARDING_COOKIE)) {
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflow = "hidden";
      setVisible(true);
    }
  }, []);

  const updateStyle = newStyle => {
    if (JSON.stringify(style) !== JSON.stringify(newStyle)) {
      setStyle(currentStyle => ({
        ...currentStyle,
        ...newStyle,
      }));
    }
  };

  const handleClose = () => {
    setVisible(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    document.querySelector("body").style = undefined;
    document.querySelector("html").style.overflow = "unset";
    Cookies.set(ONBOARDING_COOKIE, true, { secure: true });
  };

  const renderStep = step => {
    switch (step) {
      case 1:
        return (
          <Step1
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
      case 2:
        return (
          <Step2
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
      case 3:
        return (
          <Step3
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
      case 4:
        return (
          <Step4
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
      case 5:
        return (
          <Step5
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
      default:
        return (
          <Step0
            updateStyle={updateStyle}
            handleClose={handleClose}
            setStep={setStep}
          />
        );
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className="cft-onboarding" />
      <div className="cft-onboarding__content">{renderStep(step)}</div>
      <div className="cft-onboarding__item" style={style || undefined} />
    </>
  );
}

export default CftOnboardingPoultry;

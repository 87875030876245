import * as actions from "./actions";
import i18n from "../../../i18n";

const InitialState = {
  event: "",
  title: "",
  id: 0,
  lang_code: 0,
  latitude: 0,
  longitude: 0,
  location_title: "",
  location_street: "",
  location_street_details: "",
  description: "",
  medias: [],
  agenda: "",
  ended_at: "",
  started_at: "",
  prelegents: [],
  translations: [],
  is_registered: false,
};

export default function event(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_EVENT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }

    // case actions.UNREGISTER_USER_FROM_EVENT_SUCCESS:
    case actions.REGISTER_USER_TO_EVENT_SUCCESS: {
      return {
        ...state,
        is_registered: true,
      };
    }

    case actions.UNREGISTER_USER_FROM_EVENT_FAIL:
    case actions.REGISTER_USER_TO_EVENT_FAIL:
    case actions.GET_EVENT_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }

    case actions.GET_EVENTS_PRELEGENTS_SUCCESS: {
      const {
        data: { results },
      } = action.payload;
      return {
        ...state,
        prelegents: results,
      };
    }

    case actions.GET_EVENT_TRANSLATION_SUCCESS: {
      const {
        data: { results },
      } = action.payload;
      return {
        ...state,
        translations: results.filter(
          item => item.lang_code.toLowerCase() !== i18n.language
        ),
      };
    }

    case actions.RESET_SINGLE_PAGE: {
      return {
        ...InitialState,
      };
    }

    default:
      return state;
  }
}

export const getEvent = (slug, token) => ({
  type: "GET_EVENT",
  payload: {
    request: {
      url: `/api/v2/events/${slug}/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const getEventsPrelegents = (id, token) => {
  return {
    type: "GET_EVENTS_PRELEGENTS",
    payload: {
      request: {
        url: `/api/v1/events/${id}/lecturers/`,
        method: "GET",
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : null,
      },
    },
  };
};

export const getRecentEvent = slug => ({
  type: "GET_EVENT",
  payload: {
    request: {
      url: `/api/v2/events/${slug}/`,
      method: "GET",
    },
  },
});

export const registerUserToEvent = (id, token) => ({
  type: "REGISTER_USER_TO_EVENT",
  payload: {
    request: {
      url: `/api/v1/events/${id}/register/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const unregisterUserFromEvent = (id, token) => ({
  type: "UNREGISTER_USER_FROM_EVENT",
  payload: {
    request: {
      url: `/api/v1/events/${id}/delist/`,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const getEventTranslation = (grouping_id, token) => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: "GET_EVENT_TRANSLATION",
    payload: {
      request: {
        url: `/api/v1/events/?event=${grouping_id}`,
        method: "GET",
        headers,
      },
    },
  };
};

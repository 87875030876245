import React from "react";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import "./CftErrorBox.scss";

const CftWarningBox = ({ text, ...props }) => {
  const { isValid } = useFormikContext();

  if (!isValid) {
    return (
      <div className="cft-error-box" {...props}>
        {text}
      </div>
    );
  }
  return null;
};

export default CftWarningBox;

CftWarningBox.propTypes = {
  text: PropTypes.string.isRequired,
};

CftWarningBox.defaultProps = {};

/* eslint-disable no-undef */
import React, { Component } from "react";

import { CftContext } from "./CftProvider";

const withCft = WrappedComponent => {
  return class CftLayout extends Component {
    render() {
      return (
        <CftContext.Consumer>
          {props => <WrappedComponent {...this.props} {...props} />}
        </CftContext.Consumer>
      );
    }
  };
};

export default withCft;

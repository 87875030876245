import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftButton,
  CftFormField,
  CftBox,
  CftClearForm,
  CftFormErrorFocus,
} from "../../components";
import DominantRaceFields from "./DominantRaceFields";
import FertilizingPasturesFields from "./FertilizingPasturesFields";
import routes from "../../utils/cftRoutes";
import useCftCattleValues from "../../utils/useCftCattleValues";
import CreateHerdField, { cattleProductionGroups } from "./CreateHerdField";
import ArrowRight from "../../components/icons/ArrowRight";
import TrashIcon from "../../components/icons/TrashIcon";
import API from "../../../services/apiHelpers";

export default function PigsHerdFeedsForm() {
  const token = Cookies.get("user_token");
  const { t, i18n } = useTranslation([
    "cftTurkey",
    "cftChickenBroiler",
    "cftPigs",
    "cftCattle",
  ]);
  const history = useHistory();
  const { store, updateStore } = useCftCattleValues();

  const getProductionGroupTypeName = id =>
    cattleProductionGroups.find(group => group.value === id)[i18n.language];

  const isNextStepAvailable =
    store.herd_reference.production_groups.length > 0 &&
    store.herd_reference.production_groups.filter(coop => coop.isValidate)
      .length === store.herd_reference.production_groups.length;

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Formik
          initialValues={store.herd_reference}
          validationSchema={Yup.object().shape({
            calculation_period_phases: Yup.number().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            dominant_race: Yup.number().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            fertilizer_area: Yup.number().when("fertilizing_pastures_exists", {
              is: val => val === "1",
              then: Yup.number()
                .required(
                  t(
                    "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                )
                .min(
                  1,
                  t(
                    "cftPigs:validationSchema.min",
                    "Wartość minimalna to {{min}}.",
                    { min: 1 }
                  )
                )
                .max(
                  400,
                  t(
                    "cftPigs:validationSchema.max",
                    "Wartość maksymalna to {{max}}.",
                    { max: 400 }
                  )
                ),
              otherwise: Yup.number(),
            }),
            fertilizer_quantity: Yup.number().when(
              "fertilizing_pastures_exists",
              {
                is: val => val === "1",
                then: Yup.number()
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftPigs:validationSchema.min",
                      "Wartość minimalna to {{min}}.",
                      { min: 1 }
                    )
                  )
                  .max(
                    400,
                    t(
                      "cftPigs:validationSchema.max",
                      "Wartość maksymalna to {{max}}.",
                      { max: 400 }
                    )
                  ),
                otherwise: Yup.number(),
              }
            ),

            fertilizer_type: Yup.number().when("fertilizing_pastures_exists", {
              is: val => val === "1",
              then: Yup.number().required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
              otherwise: Yup.number(),
            }),
            fertilizer_type_type: Yup.string().when(
              "fertilizing_pastures_exists",
              {
                is: val => val === "1",
                then: Yup.string().required(
                  t(
                    "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                ),
                otherwise: Yup.string(),
              }
            ),
            fertilizer_unit: Yup.string().when("fertilizing_pastures_exists", {
              is: val => val === "1",
              then: Yup.string().required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
              otherwise: Yup.string(),
            }),
            fertilizing_pastures_exists: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
          })}
          onSubmit={async (values, helpers) => {
            const {
              fertilizer_area,
              fertilizer_type,
              fertilizer_unit,
              fertilizer_quantity,
              ...rest
            } = values;
            try {
              await API().post(
                "/api/v1/ae/cattle/herd-validation/",
                {
                  ...store.herd_reference,
                  ...rest,
                  fertilizing_pastures:
                    rest.fertilizing_pastures_exists === "1"
                      ? {
                          fertilizer_area,
                          fertilizer_type,
                          fertilizer_unit,
                          fertilizer_quantity,
                        }
                      : undefined,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                herd_reference: {
                  ...store.herd_reference,
                  ...values,
                  isValidate: true,
                },
              });
              history.push(routes.CATTLE.EMISSIONS);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form style={{ width: "100%" }}>
            <DominantRaceFields />
            <h4 className="cft-section-subtitle mb-4 mt-4">
              {t(
                "cftCattle:pigsHerdFeedsForm.subtitle",
                "Wybierz wszystkie grupy produkcyjne występujące w stadzie i podaj informacje o ilości osobników. Możesz dodać maksymalnie 6 grup produkcyjnych."
              )}
            </h4>
            <CftFormField name="production_groups">
              {({ value, helpers }) =>
                !!value.length && (
                  <>
                    {value.map(({ production_group_type, isValidate }, key) => (
                      <div
                        key={`${production_group_type}_${key}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="cft-paper mb-2"
                      >
                        <CftBox
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          variant="fullWidth"
                          isActive={isValidate}
                          onClick={() => {
                            history.push(`${routes.CATTLE.COOPS}/${key}`);
                          }}
                        >
                          <span>
                            {getProductionGroupTypeName(production_group_type)}
                          </span>
                          <ArrowRight />
                        </CftBox>
                        <TrashIcon
                          onClick={() => {
                            updateStore({
                              herd_reference: {
                                ...store.herd_reference,
                                production_groups: value.filter(
                                  (_, _key) => _key !== key
                                ),
                              },
                            });
                            helpers.setValue(
                              value.filter((_, _key) => _key !== key)
                            );
                          }}
                          style={{
                            marginTop: "16px",
                            marginLeft: "16px",
                            marginRight: "-32px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              }
            </CftFormField>
            <CreateHerdField />
            <FertilizingPasturesFields />
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
                disabled={!isNextStepAvailable}
              >
                {t(
                  "cftTurkey:chickenBroilerCoopsForm.submit",
                  "podaj informacje o emisjach"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="cattle" />
    </div>
  );
}

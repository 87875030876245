import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import * as Yup from "yup";

import { ReactComponent as StartIcon } from "../../../../assets/icons/cft/start.svg";

import {
  CftBox,
  CftLabel,
  CftTextField,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftSelectField,
  CftFormErrorFocus,
  CftTooltip,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import useCftOptions from "../../../utils/useCftOptions";
import { useCft } from "../../../utils/CftProvider";
import { dairyProductionAPI } from "../../../utils/cftApi";
import {
  mapValues,
  COW_TYPES,
  getCowName,
  DATE_TIME_TYPES,
  PROTEIN_MEASURE_TYPES,
} from "./detailsDairyProduction.utils";

function DetailsDairyProductionForm() {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const { months, years, getUnits } = useCftOptions();

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:characteristics.form.1", "Nazwij swoją hodowlę")}
        />
        <CftTextField name="name" />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:characteristics.form.2", "Rasa główna")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:detailsDairyProduction.tooltips.1",
                "Wybierz rasę główną swojego stada. Jeśli masz różnorodne rasy to wykonaj obliczenia dla każdej grupy osobno"
              )}
            />
          }
        />
        <CftSelectField
          name="breed"
          options={COW_TYPES.map(({ value, label }) => ({
            value,
            label,
          }))}
          onChange={value => {
            if (!values.name) {
              setFieldValue(
                "name",
                `${getCowName(value)} - ${new Date().getFullYear()}`
              );
            }
          }}
        />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t(
            "cft:characteristics.form.3",
            "Początek okresu referencyjnego"
          )}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:detailsDairyProduction.tooltips.2",
                "Określ czy okres referencyjny na początek, środek czy koniec roku"
              )}
            />
          }
        />
        <CftFormField name="referencePeriod">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {DATE_TIME_TYPES.map(({ id, label }) => (
                  <CftBox
                    key={label.i18n}
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <StartIcon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <Row>
          <Col xs={12} lg={6}>
            <CftLabel label={t("cft:characteristics.form.7", "Miesiąc")} />
            <CftSelectField name="month" options={months} />
          </Col>
          <Col xs={12} lg={6}>
            <CftLabel label={t("cft:characteristics.form.8", "Rok")} />
            <CftSelectField name="year" options={years} />
          </Col>
        </Row>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:characteristics.form.9", "Całkowita produkcja mleka")}
        />
        <CftUnitField
          name="totalMilkProduction"
          units={getUnits(["kg", "t", "l"])}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:characteristics.form.10", "Zawartość tłuszczu")}
        />
        <CftTextField name="fatContent" adornment="%" />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:characteristics.form.11", "Zawartość białka")}
        />
        <CftFormField name="proteinContentType">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {PROTEIN_MEASURE_TYPES.map(({ id, label }) => (
                  <CftBox
                    key={label.i18n}
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                  >
                    <p className="cft-box__no-icon">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small" withoutMarginTop>
        <CftTextField name="proteinContent" adornment="%" />
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default DetailsDairyProductionForm;

export function DetailsDairyProductionContext({ children }) {
  const history = useHistory();
  const { t } = useTranslation(["cft", "cftPigs"]);

  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();

  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.details}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .trim()
          .max(20, "Maksymalna ilość znaków to 20")
          .required(
            t(
              "cft:validation:required",
              t("cft:validation.required", "Wpisz wartość")
            )
          ),
        breed: Yup.string().required(
          t(
            "cft:validation:requiredOption",
            t("cft:validation.requiredOption", "Wybierz opcję")
          )
        ),
        referencePeriod: Yup.string().required(
          t(
            "cft:validation:requiredOption",
            t("cft:validation.requiredOption", "Wybierz opcję")
          )
        ),
        totalMilkProduction: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(
              t(
                "cft:validation:required",
                t("cft:validation.required", "Wpisz wartość")
              )
            )
            .typeError(
              t(
                "cft:validation:numberTypeError",
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
            )
            .min(
              0,
              t(
                "numberMinError",
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              )
            ),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        fatContent: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(
            t(
              "cft:validation:required",
              t("cft:validation.required", "Wpisz wartość")
            )
          )
          .typeError(
            t(
              "cft:validation:numberTypeError",
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
          )
          .max(
            7,
            t(
              "cftPigs:validationSchema.max",
              "Wartość maksymalna to {{max}}.",
              { max: 7 }
            )
          )
          .min(
            0,
            t(
              "numberMinError",
              t("cft:validation.numberMinError", "Wartość minimalna to 0")
            )
          ),
        proteinContent: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(
            t(
              "cft:validation:required",
              t("cft:validation.required", "Wpisz wartość")
            )
          )
          .typeError(
            t(
              "cft:validation:numberTypeError",
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
          )
          .max(
            7,
            t(
              "cftPigs:validationSchema.max",
              "Wartość maksymalna to {{max}}.",
              { max: 7 }
            )
          )
          .min(
            0,
            t(
              "numberMinError",
              t("cft:validation.numberMinError", "Wartość minimalna to 0")
            )
          ),
        proteinContentType: Yup.string().required(
          t(
            "cft:validation:requiredOption",
            t("cft:validation.requiredOption", "Wybierz opcję")
          )
        ),
        year: Yup.string().required(
          t(
            "cft:validation:requiredOption",
            t("cft:validation.requiredOption", "Wybierz opcję")
          )
        ),
        month: Yup.string().required(
          t(
            "cft:validation:requiredOption",
            t("cft:validation.requiredOption", "Wybierz opcję")
          )
        ),
      })}
      onSubmit={values => {
        dairyProductionAPI
          .detailsValidation(mapValues(values))
          .then(({ data }) => {
            saveDairyProductionReport({ milk_production: data });
            saveDairyProduction({ details: values });
            history.push(routes.DAIRY_PRODUCTION.CHARACTERISTICS);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    directEnergy: values.sources.map(
      ({ agregator, category, consumption, label }) => ({
        energy_source_id: Number(agregator),
        category_id: Number(category),
        usage: {
          value: dotFormatter(consumption.value),
          unit_id: consumption.unit,
        },
        label,
      })
    ),
    machinery: values.consumptions.map(
      ({ operations, liters, machineryType, machineryFuel }) => ({
        machine_id: Number(machineryType),
        fuel_id: Number(machineryFuel),
        operation_number: dotFormatter(operations),
        liters: dotFormatter(liters),
      })
    ),
    processing: values.processing.map(({ type, energySource, allocation }) => ({
      type_id: Number(type),
      energy_source_id: Number(energySource),
      allocation: dotFormatter(allocation),
    })),
    storage: values.isPotatoStorage
      ? [
          {
            stored_allocation: Number(values.potatoStorage.storedAllocation),
            storage_power_source_id: Number(
              values.potatoStorage.storagePowerSource
            ),
            store_loading_power_source_id: Number(
              values.potatoStorage.storeLoadingPowerSource
            ),
            unloading_power_source_id: Number(
              values.potatoStorage.storeLoadingPowerSource
            ),
            time: {
              value: dotFormatter(values.potatoStorage.time.value),
              unit_id: values.potatoStorage.time.unit,
            },
            temperature: {
              value: dotFormatter(values.potatoStorage.temperature),
              unit_id: "5",
            },
            cipc_dose: dotFormatter(values.potatoStorage.cipcDose),
            cipc_application: dotFormatter(
              values.potatoStorage.cipcApplication
            ),
          },
        ]
      : [],
  };
}

import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { articlesNavigation } from "../../../services/helpers/articles";
import { useLinks } from "../../../services/helpers/links";

export const ArticlesCategories = ({
  activeCategory,
  setActiveCategory,
  allCategoriesTagLabel,
  showSubcategories,
  subcategories,
  activeSubcategoryId,
  articlesNav,
  defaultCat = null,
  sliceCat = true,
}) => {
  const { knowledge, press } = useLinks();
  const [sliceCategories, setSliceCategories] = useState(sliceCat);
  const {
    t,
    i18n: { language },
  } = useTranslation(["navigation", "articlesMeta", "globals"]);

  const articlesAgregators = articlesNav || articlesNavigation(t, language);
  return (
    <>
      <div className="Sections__tags">
        {allCategoriesTagLabel && (
          <div
            key="category-all"
            className={classNames({
              Sections__tag: true,
              "is-active":
                !activeCategory.id || activeCategory.id === defaultCat,
            })}
            onClick={() =>
              setActiveCategory({
                id: defaultCat,
                path: defaultCat === 53 ? press() : knowledge(),
              })
            }
          >
            {allCategoriesTagLabel}
          </div>
        )}

        {articlesAgregators
          .filter(cat => cat.id !== 90)
          .slice(0, sliceCategories ? 6 : undefined)
          .map(cat => (
            <a
              key={cat.id}
              href={cat.path || "#"}
              className={classNames({
                Sections__tag: true,
                "is-active": activeCategory.id === cat.id,
              })}
              onClick={e => {
                e.preventDefault();
                setActiveCategory(cat);
              }}
            >
              {cat.name}
            </a>
          ))}
        {articlesAgregators.length > 8 && sliceCategories && (
          <div
            key="category-more"
            className="Sections__tag"
            onClick={() => setSliceCategories(false)}
          >
            ...
          </div>
        )}
      </div>
      {showSubcategories && subcategories && (
        <div className="articles-subcategories">
          {subcategories.map(d => {
            return (
              <a
                key={d.id}
                className={classNames("articles-subcategories-el", {
                  "is-active": activeSubcategoryId === d.id,
                })}
                href={d.path}
              >
                {d.name}
              </a>
            );
          })}
        </div>
      )}
    </>
  );
};

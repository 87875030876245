import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../../index";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function Step4({ handleClose, updateStyle, setStep }) {
  const { t } = useTranslation(["cftChickenBroiler"]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      const dev =
        document.querySelector(".CftExportReport") ||
        document.querySelector(".CftExportData");
      const box = dev.getBoundingClientRect();
      const style = {
        width: box.width,
        height: box.height,
        top: box.top,
        left: box.left,
        opacity: box.right,
      };
      updateStyle(style);
    }, 800);
  }, []);

  return (
    <>
      <div>
        <h3 className="cft-onboarding__item__title">
          {t("cftChickenBroiler:onboarding.step4.title", "Pobierz kalkulację")}
        </h3>
        <p className="cft-onboarding__item__subtitle">
          {t(
            "cftChickenBroiler:onboarding.step4.subtitle",
            "Jeżeli nie możesz dokończyć swojej kalkulacji ale nie chcesz stracić postępów pracy, możesz ją zapisać na swój dysk i wrócić do niej kiedy będziesz gotowy."
          )}
        </p>
        <CftTank justifyContent="center" alignItems="center">
          <CftButton
            variant="transparent"
            onClick={handleClose}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.skip", "Pomiń")}
          </CftButton>
          <CftButton
            variant="active"
            onClick={() => setStep(step => step + 1)}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.next", "Dalej")}
          </CftButton>
        </CftTank>
      </div>
    </>
  );
}

export default Step4;

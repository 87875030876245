import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { ReactComponent as PorkIcon } from "../../../../assets/icons/pork.svg";
import { isTheSameDate } from "../../../averagePrices/date";
import { Pork } from "../../../averagePrices/types";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import {
  useDataForRaportCenowy,
  useListOfPorkProducts,
  useListOfRegions,
  usePorkClasses,
} from "./api";

const mapProductToTableRow = ({ week, product, name }) => {
  const productData = week.products.find(p => p.product.id === product.id);
  const measure = product.id === Pork.Zywiec ? "kg" : undefined;
  return createTableRow(
    {
      id: product.id,
      name,
      currentPrice: productData ? productData.current_price : undefined,
      percentagePriceChange: productData
        ? productData.percentage_price_change
        : undefined,
    },
    measure
  );
};

const mapClassToTableRow = (week, porkClass, name) => {
  const productData = week.products.find(p => p.pork_class === porkClass.id);
  return createTableRow({
    id: porkClass.id,
    name,
    currentPrice: productData ? productData.current_price : undefined,
    percentagePriceChange: productData
      ? productData.percentage_price_change
      : undefined,
  });
};

const getLastWeekDataForRegion = (weeklyData, region) => {
  const [lastAvailableWeek] = weeklyData;
  return weeklyData.find(
    week =>
      isTheSameDate(
        new Date(week.week_start_date),
        new Date(lastAvailableWeek.week_start_date)
      ) && week.region === region.id
  );
};

const PorkWidget = ({ userDefaults, onLoginClick }) => {
  const { t, i18n } = useTranslation(["averagePrices"]);
  const token = Cookies.get("user_token");
  const { data: products } = useListOfPorkProducts();
  const { data: reportData } = useDataForRaportCenowy(userDefaults);
  const { data: listOfRegions } = useListOfRegions();
  const { data: listOfClasses } = usePorkClasses();

  const userIsLogin = token !== "";

  if (!products || !listOfRegions || !reportData || !listOfClasses) {
    return null;
  }

  const selectedProduct = products.find(
    prod => prod.id === Number(userDefaults.interested_products[0])
  );
  const selectedRegions = userDefaults.regions
    ? userDefaults.regions.map(id => listOfRegions.find(prod => prod.id === id))
    : [];
  const selectedClasses = userDefaults.pork_classes
    ? userDefaults.pork_classes.map(id =>
        listOfClasses.find(cl => cl.id === id)
      )
    : [];

  const [lastWeekData] = reportData.results;

  const productList = (() => {
    if (selectedProduct.id === Pork.MPC) {
      return selectedClasses
        .map(porkClass => {
          if (!lastWeekData) {
            return undefined;
          }

          const name = `${t("averagePrices:class")} ${porkClass.name}`;

          return mapClassToTableRow(lastWeekData, porkClass, name);
        })
        .filter(Boolean);
    }

    // Zywiec, Poltusze
    return selectedRegions
      .map(region => {
        const currentWeekData = getLastWeekDataForRegion(
          reportData.results,
          region
        );
        if (!currentWeekData) {
          return undefined;
        }

        return mapProductToTableRow({
          week: currentWeekData,
          product: selectedProduct,
          name: `${t(Pork[selectedProduct.id])} ${
            i18n.language === "pl" ? region.shortcut : region.shortcut_en
          }`,
        });
      })
      .filter(Boolean);
  })();

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>
          <strong>{t("averagePrices:widget.titlePork")}</strong>
        </span>
        <div className="details">
          <span>
            <MarketWidget.PeriodSection
              startWeek={new Date(lastWeekData.week_start_date)}
              endWeek={new Date(lastWeekData.week_end_date)}
            />
            <MarketWidget.Customization
              isUserLogged={userIsLogin}
              linkTo="/srednie-ceny/wieprzowina"
              onLoginClick={onLoginClick}
            />
          </span>
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default PorkWidget;

import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftUnitField,
  CftQuestionGroup,
  CftConditionalRendering,
  CftTooltip,
} from "../../../components";
import useCftOptions from "../../../utils/useCftOptions";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { initTransport } from "../../farmlands/TransportFarmlandsForm/TransportFarmlands.form";
import { transportType } from "../../farmlands/TransportFarmlandsForm/transportFarmlands.consts";

// method icons
import { ReactComponent as DrogowyIcon } from "../../../../assets/icons/cft/drogowy.svg";
import { ReactComponent as KolejowyIcon } from "../../../../assets/icons/cft/pociogowy.svg";
import { ReactComponent as LotniczyIcon } from "../../../../assets/icons/cft/lotniczy.svg";
import { ReactComponent as StatkiemIcon } from "../../../../assets/icons/cft/morski.svg";

function Transport({ id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { values, setFieldValue } = useFormikContext();
  const { getUnits } = useCftOptions();

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:transport.form.1", "Metoda transportu")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:transport.tooltips.1",
                "Wybierz wszystkie metody transportu, które były wykorzystywane dla tej uprawy"
              )}
            />
          }
        />
        <CftFormField name={`transports[${id}].method`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "road"}
                  onClick={() => {
                    helpers.setValue("road");
                    setFieldValue(`transports[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DrogowyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {" "}
                    {t("cft:transport.form.2", "Drogowy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "rail"}
                  onClick={() => {
                    helpers.setValue("rail");
                    setFieldValue(`transports[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <KolejowyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {" "}
                    {t("cft:transport.form.3", "Kolejowy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "air"}
                  onClick={() => {
                    helpers.setValue("air");
                    setFieldValue(`transports[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <LotniczyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.4", "Lotniczy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "ship"}
                  onClick={() => {
                    helpers.setValue("ship");
                    setFieldValue(`transports[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <StatkiemIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.5", "Statkiem")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={!!values.transports[id].method}
      >
        <>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:transport.form.6", "Rodzaj transportu drogowego")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:transport.tooltips.2",
                    "Określ rodzaj samochodu"
                  )}
                />
              }
            />
            <CftSelectField
              name={`transports[${id}].type`}
              options={
                values.transports[id].method
                  ? transportType[values.transports[id].method].map(opt => ({
                      label: opt[language],
                      value: opt.id,
                    }))
                  : []
              }
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:cattleHerd.form.2", "Waga")} />
            <CftUnitField
              name={`transports[${id}].weight`}
              units={getUnits(["t", "kg"])}
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:transport.form.7", "Dystans (w obie strony)")}
            />
            <CftTextField name={`transports[${id}].distance`} adornment="km" />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:transport.form.8", "Dodaj etykietę")} />
            <CftTextField name={`transports[${id}].label`} />
          </CftFormControl>
        </>
      </CftConditionalRendering>
    </>
  );
}

function TransportType() {
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  const getError = (value, key) => {
    if (!value) {
      return false;
    }
    return !!value[key];
  };

  return (
    <>
      <CftFormField name="transports">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t(
                  "cft:transport.form.1",
                  "Metoda transportu"
                )} ${key + 1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={
                  key !== 0
                    ? () => {
                        helpers.setValue(value.filter(f => f.id !== id));
                        scrollToLastElement(groupRefs, 130);
                      }
                    : undefined
                }
                hasError={meta.touched && getError(meta.error, key)}
              >
                <Transport id={key} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initTransport()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t("cft:transport.form.11", "Dodaj dodatkowe")
                    : t("cft:transport.form.10", "Dodaj metode transportu")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default TransportType;

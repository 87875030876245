import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../utils/cftRoutes";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";

import "./CftTurkeyHeader.scss";

const TABS = [
  {
    name: "cftTurkey:henhousesHeader.start",
    path: routes.TURKEY.MAIN,
    keyStore: "main",
  },
  {
    name: "cftTurkey:henhousesHeader.coops",
    path: routes.TURKEY.COOPS,
    keyStore: "henhouses_reference",
  },
  {
    name: "cftTurkey:henhousesHeader.energy",
    path: routes.TURKEY.EMISSIONS,
    keyStore: "emission",
  },
  {
    name: "cftTurkey:henhousesHeader.report",
    path: routes.TURKEY.REPORT,
    keyStore: "report",
  },
];

function CftTurkeyHeader() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const { store } = useCftTurkeyValues();

  return (
    <div className={classnames("cft-henhouses-header")}>
      {TABS.map(({ name, path, keyStore }, key) => {
        const isActive =
          location.pathname.includes(path) || store[keyStore].isValidate;

        return (
          <div
            onClick={() => isActive && history.push(path)}
            className={classnames({ isActive })}
          >
            {key + 1}. {t(name)}
          </div>
        );
      })}
    </div>
  );
}

export default CftTurkeyHeader;

import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftUnitField,
  CftQuestionGroup,
  CftTooltip,
} from "../../../components";
import useCftOptions from "../../../utils/useCftOptions";
import { scrollToLastElement } from "../../../utils/cftUtils";

import {
  types,
  productions,
} from "../../farmlands/FertilizersFarmlandsForm/fertilizers.consts";

const initFertiliserApplication = () => ({
  id: Date.now(),
  typeCategory: "",
  type: "",
  rate: {
    unit: "",
    value: "",
  },
  production: "",
});

function FertiliserApplication({ id, values }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { setFieldValue } = useFormikContext();
  const { getUnits } = useCftOptions();

  const resetFertiliser = () => {
    setFieldValue(`applications[${id}].type`, "");
    setFieldValue(`applications[${id}].production`, "");
  };

  const fertilisersOptions = values.typeCategory
    ? types.find(({ name }) => name === values.typeCategory).fertilisers
    : [];

  const availableProductions =
    values.type === ""
      ? []
      : fertilisersOptions.find(({ id }) => id === Number(values.type))
          .production_ids;
  const productionsOptions = productions.filter(
    ({ production_id }) => availableProductions.indexOf(production_id) !== -1
  );

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:fertilizers.form.chooseType", "Wybierz typ nawozu")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fertilizers.form.0",
                "Określ wszystkie zabiegi nawożenia jakie wykonywałeś na danej uprawie."
              )}
            />
          }
        />
        <CftFormField name={`applications[${id}].typeCategory`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "Organiczny"}
                  onClick={() => {
                    helpers.setValue("Organiczny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.12", "Organiczny")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "Mineralny"}
                  onClick={() => {
                    helpers.setValue("Mineralny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.11", "Mineralny")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "Wapienny"}
                  onClick={() => {
                    helpers.setValue("Wapienny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.13", "Wapienny")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:fertilizers.form.14", "Wybierz nawóz")} />
        <CftSelectField
          name={`applications[${id}].type`}
          options={fertilisersOptions.map(opt => ({
            label: opt[language],
            value: opt.id,
          }))}
          onChange={() => {
            setFieldValue(`applications[${id}].production`, "");
          }}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fertilizers.form.15", "Wybierz region produkcji")}
        />
        <CftSelectField
          name={`applications[${id}].production`}
          options={productionsOptions.map(opt => ({
            label: opt[language],
            value: opt.production_id,
          }))}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:cattleGrazing.form.18",
            "Powierzchnia użytku zielonego"
          )}
        />
        <CftUnitField
          name={`applications[${id}].rate`}
          units={getUnits(["kg/ha", "t/ha"])}
        />
      </CftFormControl>
    </>
  );
}

function FertiliserApplications() {
  const { t } = useTranslation(["cft"]);
  const { values } = useFormikContext();
  const groupRefs = useRef([]);

  if (!values.fertilisation) {
    return null;
  }

  return (
    <>
      <CftFormField name="applications">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id, ...values }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t("cft:cattleGrazing.form.8", "Nawożenie")} ${key +
                  1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={() => {
                  helpers.setValue(value.filter(f => f.id !== id));
                  scrollToLastElement(groupRefs, 130);
                }}
                hasError={meta.touched[key] && meta.error[key]}
              >
                <FertiliserApplication id={key} values={values} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initFertiliserApplication()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t("cft:forage.form.5", "Dodaj kolejny składnik paszowy")
                    : t("cft:forage.form.6", "Dodaj składnik paszowy")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default FertiliserApplications;

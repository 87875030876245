import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
} from "../../../components";

import { tillageTypes } from "./carbonChanges.consts";

function TillageFields({ name, id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);

  const { values } = useFormikContext();

  const parentTillageFromId =
    id !== 0
      ? values.managementPractice.carbon_changes[id - 1].tillage.tillage_from
      : null;

  return (
    <>
      <h4 className="mb-7" style={{ textAlign: "center" }}>
        {t("cft:carbonChanges.tillage.title", "Tillage")}
      </h4>
      <>
        <CftFormControl>
          <CftLabel label={t("cft:carbonChanges.form.11", "Zmienione z...")} />
          <CftFormField name={`${name}.tillage.tillage_from`}>
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {tillageTypes.map(tillage => (
                    <CftBox
                      key={`tillage_from_${tillage.id}`}
                      isActive={value === tillage.id}
                      onClick={() => {
                        helpers.setValue(tillage.id);
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="small"
                    >
                      <p>{tillage[language]}</p>
                    </CftBox>
                  ))}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftFormControl>
        <CftFormControl>
          <CftLabel label={t("cft:carbonChanges.form.15", "Zmieniony na...")} />
          <CftFormField name={`${name}.tillage.tillage_to`}>
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {tillageTypes
                    .filter(tillage => {
                      if (parentTillageFromId) {
                        return parentTillageFromId === tillage.id;
                      }

                      return true;
                    })
                    .map(tillage => (
                      <CftBox
                        key={`tillage_to_${tillage.id}`}
                        isActive={value === tillage.id}
                        onClick={() => {
                          helpers.setValue(tillage.id);
                        }}
                        isInvalid={meta.touched && meta.error}
                        variant="small"
                      >
                        <p>{tillage[language]}</p>
                      </CftBox>
                    ))}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftFormControl>
      </>
    </>
  );
}

export default TillageFields;

import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftQuestionGroup,
} from "../../../components";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { ReactComponent as CowIcon } from "../../../../assets/icons/cft/cow.svg";
import { useCft } from "../../../utils/CftProvider";
import { titlesCattleHerd } from "../CharacteristicsDPForm/CattleHerdFields";
import { manureType } from "./manure.consts";

export const initManagemen = () => ({
  id: Date.now(),
  herdSection: "",
  type: "",
  allocation: "",
});

function Managemen({ id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const {
    dairyProduction: {
      characteristics: { cattleHerd },
    },
  } = useCft();

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:manure.form.8", "Kategoria zwierząt")} />
        <CftFormField name={`herd[${id}].herdSection`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {cattleHerd.map((herd, key) => {
                  if (!herd) {
                    return null;
                  }
                  return (
                    <CftBox
                      key={key}
                      isActive={value === key}
                      onClick={() => helpers.setValue(key)}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <CowIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {t(titlesCattleHerd[key])}
                      </p>
                    </CftBox>
                  );
                })}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:forage.form.12", "Typ")} />
        <CftSelectField
          name={`herd[${id}].type`}
          options={manureType.map(opt => ({
            value: opt.value,
            label: opt[language],
          }))}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:forage.form.3", "Procent")} />
        <CftTextField name={`herd[${id}].allocation`} adornment="%" />
      </CftFormControl>
    </>
  );
}

function ManureManagemenType() {
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  const getError = (value, key) => {
    if (!value) {
      return false;
    }
    return !!value[key];
  };

  return (
    <>
      <CftFormField name="herd">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t("cft:manure.form.9", "Typ obornika")} ${key + 1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={
                  key !== 0
                    ? () => {
                        helpers.setValue(value.filter(f => f.id !== id));
                        scrollToLastElement(groupRefs, 130);
                      }
                    : undefined
                }
                hasError={meta.touched && getError(meta.error, key)}
              >
                <Managemen id={key} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initManagemen()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t("cft:manure.form.10", "Dodaj kolejne")
                    : t("cft:manure.form.0", "Dodaj typ obornika")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default ManureManagemenType;

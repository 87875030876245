import Cookies from "js-cookie";
import {
  GET_USER_POIS,
  GET_POI_WEATHER,
  GET_DEFAULT_POI_WEATHER,
  REMOVE_POI,
  ADD_POI,
  UPDATE_POI,
  CLEAR_POI_WEATHER,
  GET_SYNOPTIC_COMMENT,
} from "./actions";
import { selectPoisFromResponse } from "./selectors";
import { prepareForecastObject } from "./utils";

const SUCCESS = "_SUCCESS";

const InitialState = {
  loadingPois: false,
  pois: [],
  forecast: [],
  synopticComment: null,
};

export default function user(state = InitialState, action) {
  switch (action.type) {
    case GET_USER_POIS + SUCCESS:
      return { ...state, pois: selectPoisFromResponse(action) };
    case GET_POI_WEATHER + SUCCESS:
    case GET_DEFAULT_POI_WEATHER + SUCCESS:
      return {
        ...state,
        forecast: [prepareForecastObject(action), ...state.forecast],
      };
    case CLEAR_POI_WEATHER:
      return { ...state, forecast: [] };
    case GET_SYNOPTIC_COMMENT + SUCCESS:
      return { ...state, synopticComment: action.payload.data };
    default:
      return state;
  }
}

export const getUserPois = () => {
  const token = Cookies.get("user_token");
  return {
    type: GET_USER_POIS,
    payload: {
      request: {
        url: "/api/v1/weather/",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
};

export const addUserPoi = poi => {
  const token = Cookies.get("user_token");
  return {
    type: ADD_POI,
    payload: {
      request: {
        url: "/api/v1/weather/",
        method: "POST",
        data: poi,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      options: {
        returnRejectedPromiseOnError: true,
      },
    },
  };
};

export const updateUserPoi = poi => {
  const token = Cookies.get("user_token");
  return {
    type: UPDATE_POI,
    payload: {
      request: {
        url: `/api/v1/weather/${poi.id}/`,
        method: "PATCH",
        data: poi,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      options: {
        returnRejectedPromiseOnError: true,
      },
    },
  };
};

export const removeUserPoi = poi => {
  const token = Cookies.get("user_token");
  return {
    type: REMOVE_POI,
    payload: {
      request: {
        url: `/api/v1/weather/${poi.id}/`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
};

export const getPoiWhether = poi => {
  const token = Cookies.get("user_token");
  return {
    poi,
    type: GET_POI_WEATHER,
    payload: {
      request: {
        url: `api/v1/forecast/${poi.id}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
};

export const getDefaultPoiWhether = () => {
  return {
    type: GET_DEFAULT_POI_WEATHER,
    payload: {
      request: {
        url: "api/v1/default-forecast/",
        method: "GET",
      },
    },
  };
};

export const getSynopticComment = poi => {
  const token = Cookies.get("user_token");
  return {
    poi,
    type: GET_SYNOPTIC_COMMENT,
    payload: {
      request: {
        url: "api/v1/weather_comment/",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
};

export const clearPoisWhether = () => ({
  type: CLEAR_POI_WEATHER,
});

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import { useDataForRaportCenowy } from "./api";

const DairyWidget = ({ userDefaults, onLoginClick }) => {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["averagePrices"]);
  const { data: reportData } = useDataForRaportCenowy();

  const userIsLogin = token !== "";

  if (!reportData) {
    return null;
  }

  const [lastWeekData] = reportData.results;

  const productList = (() => {
    if (!lastWeekData) {
      return [];
    }

    return lastWeekData.products
      .filter(product =>
        userDefaults.interested_products.includes(product.product.id)
      )
      .map(product => {
        return createTableRow(
          {
            id: product.id,
            name: t(`averagePrices:dairyPage.form.${product.product.id}`),
            currentPrice: product.current_price,
            percentagePriceChange: product.percentage_price_change,
          },
          "100kg"
        );
      });
  })();

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>{t("averagePrices:widget.titleDairy")}</span>
        <div className="details">
          <span>
            <MarketWidget.PeriodSection
              startWeek={new Date(lastWeekData.week_start_date)}
              endWeek={new Date(lastWeekData.week_end_date)}
            />
          </span>

          <MarketWidget.Customization
            isUserLogged={userIsLogin}
            linkTo="/srednie-ceny/nabial"
            onLoginClick={onLoginClick}
          />
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default DairyWidget;

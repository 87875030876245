import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftTank,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import IrrigationForm, {
  IrrigationFormContext,
} from "../../forms/farmlands/IrrigationForm/Irrigation.form";

function IrrigationPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveFarmlands, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={56}
          cropName={meta.title.farmlands}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="farmlands" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveFarmlands({
                    irrigation: values,
                  });
                  history.push(routes.FARMLANDS.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:irrigation.title", "Irygacja")}
          onBack={() => history.push(routes.FARMLANDS.FUEL_AND_ENERGY)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:irrigation.title", "Irygacja")}</h3>
            <p>
              {t(
                "cft:irrigation.subtitle",
                "W tej sekcji wpisz wszystkie zabiegi związane z nawadnianiem uprawy. Możesz dodać wiele wpisów z różnymi metodami nawadniania i źródłami wody."
              )}
            </p>
          </>
        }
      >
        <IrrigationForm />
        <CftReportProgress type="farmlands" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <IrrigationFormContext>
      <IrrigationPage />
    </IrrigationFormContext>
  );
};

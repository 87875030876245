import React from "react";

export const TwitterIcon = () => {
  return (
    <div className="twitter">
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M5.84863 27.5L14.7637 17.7358M26.226 5L17.7354 14.2045"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="bevel"
        />
        <path
          d="M5 5L22.4057 27.5H27.5L10.5189 5H5Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

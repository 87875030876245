import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Input.scss";

// inputstate - define input state: active, error
// haserror - define if input has correcr values
// errorMsg - pass error message
// icon - define icon that is displayed in input

export const Input = props => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = event => {
    setIsFocused(true);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleBlur = event => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <div
      className={
        props.inputstate ? `input input--${props.inputstate}` : "input"
      }
    >
      <label htmlFor={props.id}>
        <div className="input-label-text">{props.label}</div>
        <div className={`input-field ${props.icon ? "input-field--icon" : ""}`}>
          {props.prefix ? (
            <div className="input-wrapper">
              {isFocused || props.value ? (
                <div className="input-prefix">{props.prefix}</div>
              ) : (
                ""
              )}
              <input
                id={props.id}
                {...props}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </div>
          ) : (
            <input id={props.id} {...props} />
          )}

          {props.icon && (
            <div className="input-icon-container">{props.icon}</div>
          )}
        </div>
        {props.haserror ? (
          <p className="input-error">{props.errormsg}</p>
        ) : null}
      </label>
    </div>
  );
};

Input.propTypes = {
  inputstate: PropTypes.string,
  label: PropTypes.string.isRequired,
  haserror: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errormsg: PropTypes.string,
  id: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

Input.defaultProps = {
  inputstate: "",
  haserror: String(false),
  errormsg: "",
  icon: "",
};

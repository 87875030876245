/* eslint-disable react/no-danger */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./Collapser.scss";

class Collapser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  render() {
    const {
      text,
      textCollapsed,
      buttonText,
      buttonTextCollapsed,
      t,
    } = this.props;
    return this.state.collapsed ? (
      <div className={this.props.className}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
        <button
          type="button"
          className="collapser-button collapsed"
          onClick={() => this.setState({ collapsed: false })}
        >
          {t(buttonTextCollapsed)}
        </button>
      </div>
    ) : (
      <div className={`${this.props.className}`}>
        <span dangerouslySetInnerHTML={{ __html: textCollapsed }} />
        <button
          type="button"
          className="collapser-button uncollapsed"
          onClick={() => this.setState({ collapsed: true })}
        >
          {t(buttonText)}
        </button>
      </div>
    );
  }
}

Collapser.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  textCollapsed: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  buttonText: PropTypes.string,
  buttonTextCollapsed: PropTypes.string,
};
Collapser.defaultProps = {
  buttonText: "globals:buttons.collapse2",
  buttonTextCollapsed: "globals:buttons.collapse",
};

export default withTranslation(["globals"])(Collapser);

import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { Footer, Metadata, NavigationBar } from "../../components/common";

import "./PageTemplate.scss";

const PageTemplate = ({ children, meta }) => (
  <Container fluid className="prf-page-template">
    <Metadata
      photo={meta.mediaCoverUrl}
      name={meta.name}
      description={meta.description}
    />
    <NavigationBar />
    {children}
    <Footer />
  </Container>
);

PageTemplate.propTypes = {
  children: PropTypes.element.isRequired,
  meta: PropTypes.shape({
    mediaCoverUrl: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
};

PageTemplate.defaultProps = {
  meta: {
    mediaCoverUrl: undefined,
    name: undefined,
    description: undefined,
  },
};

export default PageTemplate;

export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAIL = "GET_ARTICLE_FAIL";

export const GET_ARTICLE_TRANSLATION = "GET_ARTICLE_TRANSLATION";
export const GET_ARTICLE_TRANSLATION_SUCCESS =
  "GET_ARTICLE_TRANSLATION_SUCCESS";
export const GET_ARTICLE_TRANSLATION_FAIL = "GET_ARTICLE_TRANSLATION_FAIL";

export const RESET_SINGLE_PAGE = "RESET_SINGLE_PAGE";

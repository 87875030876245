import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftFormErrorFocus,
  CftTooltip,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import {
  mapValues,
  SOIL_MOISTURE,
  SOIL_TEXTURE_TYPE,
  SOIL_TEXTURE_TYPE_ADVANCED,
} from "./soilWaterFootprint.utils";
import { farmlandsAPI } from "../../../../utils/cftApi";

function SoilWaterFootprintForm() {
  const { t } = useTranslation(["cft"]);

  return (
    <Form className="cft-form">
      <CftFormControl withoutMarginBottom>
        <CftLabel
          label={t("cft:soilWaterFootprint.form.1", "Typ gleby")}
          // eslint-disable-next-line react/no-children-prop
        />
        <CftFormField name="soilType">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_TEXTURE_TYPE.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="medium" withoutMarginTop>
        <CftFormField name="soilTypeAdvanced">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_TEXTURE_TYPE_ADVANCED.map(({ id, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                  >
                    <p className="cft-box__no-icon">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t(
            "cft:soilWaterFootprint.form.11",
            "Wilgotność gleby podczas siewu"
          )}
        />
        <CftFormField name="soilMoisture">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_MOISTURE.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default SoilWaterFootprintForm;

export function SoilWaterFootprintFormContext({ children }) {
  const history = useHistory();
  const {
    waterFootprint,
    saveWaterFootprint,
    saveWaterFootprintReport,
  } = useCft();
  const { t } = useTranslation(["cft"]);

  return (
    <Formik
      enableReinitialize
      initialValues={waterFootprint.soil}
      validationSchema={Yup.object().shape({
        soilType: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilTypeAdvanced: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilMoisture: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
      })}
      onSubmit={values => {
        saveWaterFootprint({ soil: values });
        farmlandsAPI
          .soilWaterFootprintValidation(mapValues(values))
          .then(({ data }) => {
            saveWaterFootprintReport({ soil: data });
            history.push(routes.FARMLANDS.WATER_FOOTPRINT.IRRIGATION);
          });
      }}
    >
      {children}
    </Formik>
  );
}

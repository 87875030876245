import React from "react";
import { useTranslation } from "react-i18next";

import { nFormatter } from "../../utils/cftUtils";

import "./CftTable.scss";

function CftTable({ data }) {
  const { t, i18n } = useTranslation(["cft"]);
  if (!data) {
    return null;
  }

  const headers = Object.keys(data[0]).map(name => name);

  const parseValue = value => {
    if (typeof value === "number") {
      return nFormatter(value, i18n.language);
    }

    return t(`cft:report.table.${value}`, value);
  };

  return (
    <table className="cft-table">
      <thead>
        <tr>
          {headers.map(name => (
            <th key={name}>{t(`cft:report.table.${name}`, name)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((value, key) => (
          <tr key={key}>
            {Object.keys(value).map(key => (
              <td key={key}>{parseValue(value[key])}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CftTable;

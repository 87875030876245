import React from "react";
import emptyImg from "../../../assets/img/empty.svg";
import Image from "../Image/Image";
import "./FileUploadInput.scss";

const MAX_FILE_SIZE = 5 * 1e6;

class FileUploadInput extends React.Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      file: null,
      imagePreviewUrl: null,
      error: null,
    };
  }

  onChange(e) {
    const { files } = e.target;
    const file = files[0];
    const fileSumSize = file.size;
    let error = null;
    if (fileSumSize >= MAX_FILE_SIZE) {
      error = "plik jest za duży";
    }
    const reader = new FileReader();
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;
      // eslint-disable-next-line func-names
      image.onload = function() {
        if (this.width < 250 || this.height < 250) {
          error = "szerokość i wysokość pliku jest za mała";
        }
        if (!error) {
          _this.setState({
            file,
            imagePreviewUrl: reader.result,
            error: null,
          });
          _this.props.filesReceiver(file);
        } else {
          _this.setState({ error });
        }
      };
    };
    reader.readAsDataURL(file);
  }

  render() {
    const { imagePreviewUrl, error } = this.state;
    const { warning, haserror, errormsg } = this.props;
    return (
      <>
        <div
          onDragOver={e => e.preventDefault()}
          onDrop={e => {
            e.preventDefault();
            this.onChange({ target: e.dataTransfer });
          }}
          className="file-input"
        >
          <div className="file-input-body">
            <div className="file-input-heading">Zdjęcie:</div>
            <label className="custom-file-upload" htmlFor={this.props.id}>
              <input
                id={this.props.id}
                type="file"
                multiple
                onChange={this.onChange}
                {...this.props}
              />
              <i className="fa fa-cloud-upload" />
              Wgraj zdjęcie
            </label>
            <div className="file-input-warning">{warning}</div>
            {error ? (
              <div className="form-input-error text-left">{error}</div>
            ) : null}
            {haserror ? (
              <div className="form-input-error text-left">{errormsg}</div>
            ) : null}
          </div>
          <div className="file-input-preview">
            {imagePreviewUrl ? (
              <Image src={imagePreviewUrl} alt="" />
            ) : (
              <Image src={emptyImg} alt="" />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default FileUploadInput;

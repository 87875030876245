import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { isEmpty } from "ramda";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftError,
  CftTooltip,
  CftRadioField,
} from "../../components";
import useCftPigsValues from "../../utils/useCftPigsValues";

const GrowthFactor = () => {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const { values, errors, setFieldValue } = useFormikContext();
  const { productionGroupsTypes } = useCftPigsValues();

  const growthFactorsOptions = useMemo(() => {
    const productionGroup = productionGroupsTypes.find(
      ({ id }) => id === Number(values.production_group_type)
    );
    return productionGroup ? productionGroup.growth_factors : [];
  }, [values]);

  const isGrowthFactorEmpty = useMemo(
    () => growthFactorsOptions.reduce((prev, crr) => prev + crr.value, 0) === 0,
    [growthFactorsOptions]
  );

  useEffect(() => {
    if (isGrowthFactorEmpty) {
      const [defaultOption] = growthFactorsOptions;
      setFieldValue("growth_factor", defaultOption.id);
    }
  }, [isGrowthFactorEmpty]);

  if (isGrowthFactorEmpty) {
    return null;
  }

  return (
    <>
      <CftLabel
        isFullWidth
        label={t(
          "cftPigs:growthFactor.title",
          "Określ parametry potencjału wzrostowego (kg/dzień)"
        )}
      >
        <CftTooltip
          content={t(
            "cftPigs:tooltips.growthFactor",
            "Wybierz zaplanowany przyrost dla tej grupy. Jeśli nie masz zaplanowanego przyrostu to wybierz średni"
          )}
        />
      </CftLabel>
      <CftTank mb={2} flexDirection="column">
        <CftRadioField
          variant="box"
          name="growth_factor"
          options={growthFactorsOptions.map(({ value, id }, key) => ({
            value: `${id}`,
            label: t(`cftPigs:growthFactor.option.${key}`, {
              growthFactor: value,
            }),
          }))}
        />
      </CftTank>
      <CftError
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
        isActive={!values.growth_factor && !isEmpty(errors)}
        message={t(
          "cftPigs:growthFactor.error",
          "Należy okreslić parametry potencjału wzrostowego (kg/dzień) "
        )}
      />
    </>
  );
};

export default GrowthFactor;

import React, { Component } from "react";
import { Container } from "reactstrap";
import { withRouter, Redirect } from "react-router";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clx from "classnames";

import {
  Footer,
  NavigationBar,
  Breadcrumbs,
  Button,
  Metadata,
} from "../../common";

import appLogo from "../../../assets/logo/agronomist_a copy.svg";

import InvestmentStep from "./InvestmentStep/InvestmentStep";
import CreditStep from "./CreditStep/CreditStep";
import CreditSummary from "./CreditSummary/CreditSummary";
import OfferSummary from "./OfferSummary/OfferSummary";

import withLayout from "../../../services/hoc/withLayout";

import {
  calculateCreditOffer,
  calculateCreditOfferFixedInstallments,
  calculateCreditOfferFixedInstallmentsWithSubventionQuarterly,
  calculateCreditOfferFixedInstallmentsWithSubvention,
  calculateCreditOfferDescendingInstallmentsWithSubvention,
} from "../../../services/helpers/calculator/calculator";
import { generatePdfReport } from "../../../services/helpers/calculator/pdf";
import { getRepaymentTimespanRange } from "../../../services/helpers/calculator/dataHelpers";
import {
  INVESTMENT_TYPES,
  TIMESTAMP_TYPES,
  INSTALLMENT_TYPES,
} from "../../../services/helpers/constants";
import { checkForRedirect } from "../../../services/helpers/redirect";

import "./CalculatorPage.scss";

const minimumCreditAmount = 20000;

class CalculatorPage extends Component {
  constructor(props) {
    super(props);

    this.redirect = checkForRedirect(
      this.props.history,
      this.props.i18n.language,
      "/kalkulator-kredytowy",
      "/calculator"
    );

    this.state = {
      creditParameters: {
        investmentAmount: "",
        hasSubvention: false,
        subventionAmount: "",
        subventionReceivingMonth: null,
        subventionReceivingYear: null,
        investmentType: INVESTMENT_TYPES.GROUND,
        repaymentTimespan: 30,
        creditRate: 5,
        installmentType: INSTALLMENT_TYPES.DESCENDING,
        capitalInstallmentFrequency: TIMESTAMP_TYPES.ONE_MONTH,
        interestInstallmentFrequency: TIMESTAMP_TYPES.ONE_MONTH,
        computedCreditAmount: 0,
      },
      investmentStepCompleted: false,
      creditStepCompleted: false,
      hasError: false,
      isInEditMode: false,
      generatedOffer: null,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePdfDownload = this.handlePdfDownload.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleCalculatingCreditOffer = this.handleCalculatingCreditOffer.bind(
      this
    );
    this.handleInvestmentTypeChange = this.handleInvestmentTypeChange.bind(
      this
    );
    this.handleSubventionStatusChange = this.handleSubventionStatusChange.bind(
      this
    );
    this.handleStepCompletionChange = this.handleStepCompletionChange.bind(
      this
    );
  }

  handleInputChange(value, type) {
    this.setState(state => ({
      creditParameters: { ...state.creditParameters, [type]: value },
    }));
  }

  handleSubventionStatusChange(status) {
    this.setState(state => ({
      creditParameters: {
        ...state.creditParameters,
        hasSubvention: status,
        subventionAmount: "",
        subventionReceivingMonth: null,
        subventionReceivingYear: null,
      },
      hasError: false,
    }));
  }

  handleInvestmentTypeChange(type) {
    const {
      subventionReceivingYear,
      hasSubvention,
    } = this.state.creditParameters;

    const newRange = getRepaymentTimespanRange({ type });
    const currentYear = new Date().getFullYear();

    this.setState(state => ({
      creditParameters: {
        ...state.creditParameters,
        investmentType: type,
        repaymentTimespan: newRange[1],
        subventionReceivingYear:
          subventionReceivingYear > newRange[1] + currentYear
            ? newRange[1] + currentYear - 1
            : subventionReceivingYear,
        hasSubvention: type === INVESTMENT_TYPES.GROUND ? false : hasSubvention,
      },
      hasError: false,
    }));
  }

  handleStepCompletionChange(step, status, editMode) {
    this.setState(prevState => ({
      [step]: status,
      isInEditMode: editMode || prevState.isInEditMode,
    }));
  }

  handleError(isValid) {
    this.setState({ hasError: !isValid });
  }

  handlePdfDownload() {
    const { creditParameters, generatedOffer } = this.state;
    const { t, i18n } = this.props;

    const doc = generatePdfReport({
      input: {
        ...creditParameters,
        generatedOffer: generatedOffer.entries,
        additionalInfo: { ...generatedOffer },
      },
      t,
      language: i18n.language,
    });
    const fileName = `${t("pdf-filename")}_${new Date()
      .toISOString()
      .replace("T", "_")
      .replace("Z", "")}.pdf`;
    doc.save(fileName);
    // window.open(doc.output("bloburl"), "_blank");
  }

  handleCalculatingCreditOffer() {
    const {
      investmentAmount,
      installmentType,
      creditRate,
      repaymentTimespan,
      interestInstallmentFrequency,
      capitalInstallmentFrequency,
      hasSubvention,
    } = this.state.creditParameters;
    const { creditParameters } = this.state;

    const creditAmount = investmentAmount;
    this.setState(state => ({
      creditParameters: {
        ...state.creditParameters,
        creditRate: creditRate || 5,
        repaymentTimespan: repaymentTimespan || 10,
        computedCreditAmount: creditAmount,
      },
    }));

    let offer = null;
    const offerParams = {
      ...creditParameters,
      creditRate: creditRate || 5,
      repaymentTimespan: repaymentTimespan || 10,
      creditAmount,
    };

    if (installmentType === INSTALLMENT_TYPES.DESCENDING) {
      if (hasSubvention) {
        offer = calculateCreditOfferDescendingInstallmentsWithSubvention(
          offerParams
        );
      } else {
        offer = calculateCreditOffer(offerParams);
      }
    } else if (
      installmentType === INSTALLMENT_TYPES.FIXED &&
      capitalInstallmentFrequency === TIMESTAMP_TYPES.QUARTER &&
      interestInstallmentFrequency === TIMESTAMP_TYPES.QUARTER &&
      hasSubvention
    ) {
      offer = calculateCreditOfferFixedInstallmentsWithSubventionQuarterly(
        offerParams
      );
    } else if (
      installmentType === INSTALLMENT_TYPES.FIXED &&
      capitalInstallmentFrequency === TIMESTAMP_TYPES.ONE_MONTH &&
      interestInstallmentFrequency === TIMESTAMP_TYPES.ONE_MONTH &&
      hasSubvention
    ) {
      offer = calculateCreditOfferFixedInstallmentsWithSubvention(offerParams);
    } else {
      offer = calculateCreditOfferFixedInstallments(offerParams);
    }
    this.setState({ generatedOffer: offer });
  }

  componentDidMount() {
    this.setState(state => ({
      creditParameters: {
        ...state.creditParameters,
      },
    }));
  }

  render() {
    const { currentWidth, t, isSeparate } = this.props;
    const {
      investmentAmount,
      hasSubvention,
      investmentType,
      subventionAmount,
      repaymentTimespan,
      creditRate,
      installmentType,
      capitalInstallmentFrequency,
      interestInstallmentFrequency,
      subventionReceivingMonth,
      subventionReceivingYear,
    } = this.state.creditParameters;
    const {
      hasError,
      isInEditMode,
      generatedOffer,
      creditStepCompleted,
      investmentStepCompleted,
    } = this.state;

    const creditAmount = investmentAmount;

    if (isSeparate) {
      return (
        <>
          <div
            className={clx("calculator-page", {
              "calculator-page__is-separate": isSeparate,
            })}
          >
            <div className="calculator-container">
              <h1 className="typography--h1">{t("pageTitle")}</h1>
              {!investmentStepCompleted && (
                <InvestmentStep
                  autoFocus={false}
                  hasError={hasError}
                  isInEditMode={isInEditMode}
                  investmentAmount={investmentAmount}
                  hasSubvention={hasSubvention}
                  subventionReceivingMonth={subventionReceivingMonth}
                  subventionReceivingYear={subventionReceivingYear}
                  repaymentTimespan={repaymentTimespan}
                  investmentType={investmentType}
                  creditAmount={creditAmount}
                  minimumCreditAmount={minimumCreditAmount}
                  subventionAmount={subventionAmount}
                  handleError={this.handleError}
                  creditRate={creditRate}
                  handleStepCompletionChange={this.handleStepCompletionChange}
                  handleSubventionStatusChange={
                    this.handleSubventionStatusChange
                  }
                  handleInvestmentTypeChange={this.handleInvestmentTypeChange}
                  handleInputChange={this.handleInputChange}
                  handleCalculatingCreditOffer={
                    this.handleCalculatingCreditOffer
                  }
                />
              )}
              {creditStepCompleted && investmentStepCompleted ? (
                <CreditSummary
                  creditAmount={creditAmount}
                  creditRate={creditRate}
                  installmentType={installmentType}
                  capitalInstallmentFrequency={capitalInstallmentFrequency}
                  interestInstallmentFrequency={interestInstallmentFrequency}
                  repaymentTimespan={repaymentTimespan}
                  handleStepCompletionChange={this.handleStepCompletionChange}
                  subventionAmount={subventionAmount}
                  hasSubvention={hasSubvention}
                  subventionReceivingMonth={subventionReceivingMonth}
                  subventionReceivingYear={subventionReceivingYear}
                />
              ) : investmentStepCompleted ? (
                <CreditStep
                  creditRate={creditRate}
                  installmentType={installmentType}
                  capitalInstallmentFrequency={capitalInstallmentFrequency}
                  interestInstallmentFrequency={interestInstallmentFrequency}
                  creditStepCompleted={creditStepCompleted}
                  handleCalculatingCreditOffer={
                    this.handleCalculatingCreditOffer
                  }
                  handleStepCompletionChange={this.handleStepCompletionChange}
                  handleInputChange={this.handleInputChange}
                  isInEditMode={isInEditMode}
                  hasError={hasError}
                />
              ) : null}
              {investmentStepCompleted && creditStepCompleted && (
                <OfferSummary
                  handlePdfDownload={this.handlePdfDownload}
                  generatedOffer={generatedOffer}
                  hasSubvention={hasSubvention}
                />
              )}
            </div>
          </div>
          {investmentStepCompleted && creditStepCompleted && !isSeparate && (
            <div className="additional-info">
              <p
                className="offer-disclaimer"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t("summary.info") }}
              />
              <div className="next-step-button-container">
                <a
                  rel="noopener noreferrer"
                  href="https://www.bnpparibas.pl/kontakt/oddzialy-z-obsluga-detaliczna-i-biznesowa?type=department_retail_business"
                  target="_blank"
                >
                  <Button buttonsize="small" buttoncolor="yellow">
                    {t("summary.submit2")}
                  </Button>
                </a>
                <Link to="/kredytomat">
                  <Button buttonsize="small" buttoncolor="yellow">
                    {t("summary.submit3")}
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </>
      );
    }

    return (
      <Container fluid>
        <Metadata
          name="Kalkulator kredytowy. Zobacz, ile możesz dostać"
          description="Kalkulator kredytowy dla rolników. Policz, jaką sumę kredytu możesz otrzymać. Kredyt dla rolników - Agronomist."
        />
        {this.redirect && <Redirect to={this.redirect} />}
        <NavigationBar search selected="Narzędzia" src={appLogo} />
        <div className="calculator-page">
          <Container>
            <Breadcrumbs currentName={t("pageTitle")} />
          </Container>
          <div className="calculator-container">
            <h1 className="typography--h1">{t("pageTitle")}</h1>
            {!investmentStepCompleted && (
              <InvestmentStep
                hasError={hasError}
                isInEditMode={isInEditMode}
                investmentAmount={investmentAmount}
                hasSubvention={hasSubvention}
                subventionReceivingMonth={subventionReceivingMonth}
                subventionReceivingYear={subventionReceivingYear}
                repaymentTimespan={repaymentTimespan}
                investmentType={investmentType}
                creditAmount={creditAmount}
                minimumCreditAmount={minimumCreditAmount}
                subventionAmount={subventionAmount}
                handleError={this.handleError}
                creditRate={creditRate}
                handleStepCompletionChange={this.handleStepCompletionChange}
                handleSubventionStatusChange={this.handleSubventionStatusChange}
                handleInvestmentTypeChange={this.handleInvestmentTypeChange}
                handleInputChange={this.handleInputChange}
                handleCalculatingCreditOffer={this.handleCalculatingCreditOffer}
              />
            )}
            {creditStepCompleted && investmentStepCompleted ? (
              <CreditSummary
                creditAmount={creditAmount}
                creditRate={creditRate}
                installmentType={installmentType}
                capitalInstallmentFrequency={capitalInstallmentFrequency}
                interestInstallmentFrequency={interestInstallmentFrequency}
                repaymentTimespan={repaymentTimespan}
                handleStepCompletionChange={this.handleStepCompletionChange}
                subventionAmount={subventionAmount}
                hasSubvention={hasSubvention}
                subventionReceivingMonth={subventionReceivingMonth}
                subventionReceivingYear={subventionReceivingYear}
              />
            ) : investmentStepCompleted ? (
              <CreditStep
                creditRate={creditRate}
                installmentType={installmentType}
                capitalInstallmentFrequency={capitalInstallmentFrequency}
                interestInstallmentFrequency={interestInstallmentFrequency}
                creditStepCompleted={creditStepCompleted}
                handleCalculatingCreditOffer={this.handleCalculatingCreditOffer}
                handleStepCompletionChange={this.handleStepCompletionChange}
                handleInputChange={this.handleInputChange}
                isInEditMode={isInEditMode}
                hasError={hasError}
              />
            ) : null}
            {investmentStepCompleted && creditStepCompleted && (
              <OfferSummary
                handlePdfDownload={this.handlePdfDownload}
                generatedOffer={generatedOffer}
                hasSubvention={hasSubvention}
              />
            )}
          </div>
        </div>
        {investmentStepCompleted && creditStepCompleted && (
          <div className="additional-info">
            <p
              className="offer-disclaimer"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t("summary.info") }}
            />
            <div className="next-step-button-container">
              <a
                rel="noopener noreferrer"
                href="https://www.bnpparibas.pl/kontakt/oddzialy-z-obsluga-detaliczna-i-biznesowa?type=department_retail_business"
                target="_blank"
              >
                <Button buttonsize="small" buttoncolor="yellow">
                  {t("summary.submit2")}
                </Button>
              </a>
              <Link to="/kredytomat">
                <Button buttonsize="small" buttoncolor="yellow">
                  {t("summary.submit3")}
                </Button>
              </Link>
            </div>
          </div>
        )}

        <Footer windowWidth={currentWidth} />
      </Container>
    );
  }
}

CalculatorPage.propTypes = {
  currentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSeparate: PropTypes.bool,
};

CalculatorPage.defaultProps = {
  currentWidth: 586,
  isSeparate: false,
};

export default withTranslation(["calculatorPage"])(
  withLayout(withRouter(CalculatorPage))
);

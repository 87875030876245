import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftFormErrorFocus,
  CftTooltip,
  CftRadioField,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import { farmlandsAPI } from "../../../../utils/cftApi";
import {
  AVERAGE_SOIL_MOISTURE_TYPES,
  mapValues,
  PH_TYPES,
  SOIL_DRAINAGE_TYPES,
  SOIL_MATTER_TYPES,
  SOIL_TEXTURE_TYPE,
  SOIL_TEXTURE_TYPE_ADVANCED,
  SOIL_TYPE,
  waterFootprintMapValues,
} from "./soilBothCalc.utils";

function SoilBothCalcForm() {
  const { t } = useTranslation(["cft"]);

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl>
        <CftLabel
          label={t("cft:soil.form.1", "Tekstura gleby")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:soil.form.2",
                "Określ teksturę gleby dla tej uprawy. Jeśli w uprawie występuje różnorodna tekstura to podziel kalkulację na mniejsze uprawy aby otrzymać rzetelne wyniki"
              )}
            />
          }
        />
        <CftFormField name="soilTexture">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_TEXTURE_TYPE.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl withoutMarginBottom>
        <CftLabel
          label={t("cft:soilWaterFootprint.form.1", "Typ gleby")}
          // eslint-disable-next-line react/no-children-prop
        />
        <CftFormField name="soilType">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_TYPE.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="medium" withoutMarginTop>
        <CftFormField name="soilTypeAdvanced">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_TEXTURE_TYPE_ADVANCED.map(({ id, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                  >
                    <p className="cft-box__no-icon">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:soil.form.6", "Materia organiczna gleby (SOM)")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:soil.form.7",
                "Określi wartość materii organicznej. Jeśli nie posiadasz aktualnych badań, to ustaw wskaźnik na poziomie 1,72-5,15"
              )}
            />
          }
        />
        <CftRadioField name="soilMatter" options={SOIL_MATTER_TYPES} />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:soil.form.8", "Średnia wilgotność gleby")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:soil.form.9",
                "Określ średnią wilgotność gleby dla tej uprawy. Jeśli w uprawie występuje różnorodna wilgotność to podziel kalkulację na mniejsze uprawy aby otrzymać rzetelne wyniki"
              )}
            />
          }
        />
        <CftFormField name="averageSoilMoisture">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {AVERAGE_SOIL_MOISTURE_TYPES.map(({ id, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                  >
                    <p className="cft-box__no-icon">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:soil.form.12", "Drenaż gleby")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:soil.form.13",
                "Określ drenaż gleby dla tej uprawy. Jeśli w uprawie występuje różnorodny drenaż to podziel kalkulację na mniejsze uprawy aby otrzymać rzetelne wyniki"
              )}
            />
          }
        />
        <CftFormField name="soilDrainage">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {SOIL_DRAINAGE_TYPES.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:soil.form.16", "Zaznacz pH gleby")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:soil.form.17",
                "Określ odczyn pH gleby. Najlepiej sprawdź wyniki ostatnich badań. Jeśli takich nie posiadasz to wybierz pH Lekko kwaśne"
              )}
            />
          }
        />
        <CftFormField name="ph">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {PH_TYPES.map(({ id, Icon, label }) => (
                  <CftBox
                    isActive={value === id}
                    onClick={() => helpers.setValue(id)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    <Icon className="cft-box__svg-fix" />
                    <p className="cft-box__paragraph">
                      {t(label.i18n, label.default)}
                    </p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default SoilBothCalcForm;

export function SoilBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft"]);

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.soil}
      validationSchema={Yup.object().shape({
        soilTexture: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilType: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilMatter: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilTypeAdvanced: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        averageSoilMoisture: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        soilDrainage: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        ph: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
      })}
      onSubmit={values => {
        saveBothCalc({ soil: values });
        Promise.all([
          farmlandsAPI.soilWaterFootprintValidation(
            waterFootprintMapValues(values)
          ),
          farmlandsAPI.soilValidation(mapValues(values)),
        ]).then(([waterFootprintData, { data }]) => {
          saveBothCalcReport({
            soil: {
              ...waterFootprintData.data,
              ...data,
            },
          });
          history.push(routes.FARMLANDS.BOTH_CALC.FERTILIZERS);
        });
      }}
    >
      {children}
    </Formik>
  );
}

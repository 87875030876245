import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CftFormControl, CftButton, CftTank } from "../index";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";

function CftDairyProductionRestricted({ children }) {
  const { t } = useTranslation(["cft"]);
  const { progress } = useCft();
  const history = useHistory();

  const { details, characteristics } = progress.dairyProduction;

  if (details.complete !== 100 || characteristics.complete !== 100) {
    return (
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:carbonChanges.form.1", "Brak wymaganych danych")}
          <small>
            {t(
              "cft:requirements.dairyProductionRestricted.1",
              "Aby rozpocząć wypełnianie danych dla wybranekgo kroku musisz uzupełnić informację dotyczące"
            )}{" "}
            <b> {t("cft:types.detailsDairyProduction", "Szczegóły hodowli")}</b>{" "}
            {t("cft:requirements.dairyProductionRestricted.2", "oraz")}{" "}
            <b>{t("cft:characteristics.title", "Charakterystyka stada")}</b>
          </small>
        </h3>
        <CftTank display="flex" justifyContent="space-around">
          <CftButton onClick={() => history.push(routes.DAIRY_PRODUCTION.MAIN)}>
            {t("cft:goBack", "Wróć")}
          </CftButton>
        </CftTank>
      </CftFormControl>
    );
  }

  return <>{children}</>;
}

export default CftDairyProductionRestricted;

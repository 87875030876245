export const INVESTMENT_TYPES = {
  GROUND: "groundPurchase",
  INFRASTRUCTURE: "infrastructurePurchase",
  EQUIPMENT: "equipmentPurchase",
};

export const INSTALLMENT_TYPES = {
  DESCENDING: "descending",
  FIXED: "fixed",
};

export const TIMESTAMP_TYPES = {
  ONE_MONTH: "1month",
  QUARTER: "3months",
  HALF_YEAR: "6months",
  ONE_YEAR: "12months",
};

export const MONTHS = {
  JANUARY: { label: "months.0", value: 1 },
  FEBRUARY: { label: "months.1", value: 2 },
  MARCH: { label: "months.2", value: 3 },
  APRIT: { label: "months.3", value: 4 },
  MAY: { label: "months.4", value: 5 },
  JUNE: { label: "months.5", value: 6 },
  JULY: { label: "months.6", value: 7 },
  AUGUST: { label: "months.7", value: 8 },
  SEPTEMBER: { label: "months.8", value: 9 },
  OCTOBER: { label: "months.9", value: 10 },
  NOVEMBER: { label: "months.10", value: 11 },
  DECEMBER: { label: "months.11", value: 12 },
};

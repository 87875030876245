import { useState } from "react";

export const KEY_STORAGE = "xc4jVyEzvW123AA";

export const initialStore = {
  type: "chicken",
  main: {
    postal_code: "",
    name: "",
    calculation_period_length: "365",
    isValidate: false,
  },
  henhouses_reference: {
    coops: [],
    count: 1,
    isValidate: false,
  },
  emission: {
    bedding: {
      bedding_period: "1",
      bedding_type: "1",
      quantity: 0,
    },
    energy_carriers: [],
    transport: [],
    isValidate: false,
  },
  report: {
    name: "",
    postal_code: "",
    calculation_period_length: 0,
    total_emission: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
    },
    total_emission_per_animal: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
    },
    total_emission_per_production_cycle: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
    },
    total_emission_per_kilogram_of_product: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
    },
    housing: [],
    total_chicken_sum: 0,
  },
};

export default function useCftChickenBroilerValues() {
  const [store, setStore] = useState(() => {
    const storeCached = localStorage.getItem(`cft:${KEY_STORAGE}`);

    if (storeCached) {
      return JSON.parse(storeCached);
    }

    localStorage.setItem(`cft:${KEY_STORAGE}`, JSON.stringify(initialStore));
    return initialStore;
  });

  const updateStore = data => {
    setStore(state => {
      localStorage.setItem(
        `cft:${KEY_STORAGE}`,
        JSON.stringify({ ...state, ...data })
      );
      return {
        ...state,
        ...data,
      };
    });
  };

  return { store, updateStore };
}

import Cookies from "js-cookie";
import * as actions from "./actions";

const InitialState = {
  count: -1,
  next: "",
  previous: "",
  results: [],
  isError: false,
};

export default function eventsAll(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_EVENTS:
      return {
        ...state,
        count: -1,
        next: "",
        previous: "",
        results: [],
      };
    // case actions.GET_EVENTS_RECENT_PREV:
    // case actions.GET_EVENTS_RECENT_NEXT: {
    //   return {
    //     ...state,
    //     isError: false,
    //   };
    // }

    case actions.GET_EVENTS_RECENT_SUCCESS:
    case actions.GET_EVENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }

    case actions.GET_EVENTS_RECENT_FAIL:
    case actions.GET_EVENTS_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getEvents = (token, page, url) => ({
  type: "GET_EVENTS",
  payload: {
    request: {
      url:
        url ||
        `/api/v1/events-recent/?lang_code=SET_LANG_CODE&limit=20&offset=${(page -
          1) *
          20}`,
      method: "GET",
      headers: {
        Authorization: token && `Bearer ${token}`,
      },
    },
  },
});

// Events for view when user is not logged
export const getEventsRecent = () => ({
  type: "GET_EVENTS_RECENT",
  payload: {
    request: {
      url: "/api/v1/events-recent/?lang_code=SET_LANG_CODE",
      method: "GET",
    },
  },
});

export const getEventsRecentNext = url => ({
  type: "GET_EVENTS_RECENT_NEXT",
  payload: {
    request: {
      url: `/api/v1/${url.split("/v1/")[1]}`,
      method: "GET",
    },
  },
});

export const getEventsRecentPrev = url => ({
  type: "GET_EVENTS_RECENT_PREV",
  payload: {
    request: {
      url: `/api/v1/${url.split("/v1/")[1]}`,
      method: "GET",
    },
  },
});

export const getListOfEvents = ({
  lang_code = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : "PL",
  publish_status = 3,
  type = "GET_EVENTS",
  past = false,
  event__invitation_required,
  event,
} = {}) => ({
  type,
  payload: {
    request: {
      url: "/api/v2/events/",
      method: "GET",
      params: {
        lang_code,
        publish_status,
        past,
        event__invitation_required,
        event,
      },
    },
  },
});

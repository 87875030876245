import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import { useDataForRaportCenowy, useListOfCereals } from "./api";

const DefaultUserWidget = ({ onLoginClick }) => {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["averagePrices"]);
  const { data: cereals } = useListOfCereals(token);
  const { data: reportData } = useDataForRaportCenowy();
  const userIsLogin = token !== "";

  if (!reportData || !cereals) {
    return (
      <MarketWidget>
        <MarketWidget.Header>
          <span>{t("averagePrices:widget.titleCereals")}</span>
          <div className="details" />
        </MarketWidget.Header>
        <MarketWidget.Table dataList={[]} />
      </MarketWidget>
    );
  }

  const [lastWeekData] = reportData.results;

  const productList = (() => {
    if (!lastWeekData) {
      return [];
    }

    return cereals.map(cereal => {
      const productData = lastWeekData.products.find(
        product => product.product.id === cereal.id
      );

      return createTableRow({
        id: cereal.id,
        name: t(`averagePrices:cereals.${cereal.id}`),
        currentPrice: productData ? productData.current_price : undefined,
        percentagePriceChange: productData
          ? productData.percentage_price_change
          : undefined,
      });
    });
  })();

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>{t("averagePrices:widget.titleCereals")}</span>
        <div className="details">
          <span>
            {t("averagePrices:widget.allData")}
            <MarketWidget.PeriodSection
              startWeek={new Date(lastWeekData.week_start_date)}
              endWeek={new Date(lastWeekData.week_end_date)}
            />
          </span>
          <MarketWidget.Customization
            isUserLogged={userIsLogin}
            linkTo="/srednie-ceny/zboza"
            onLoginClick={onLoginClick}
          />
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default DefaultUserWidget;

import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftQuestionGroup,
  CftConditionalRendering,
} from "../../../components";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { feedType, newFeedType } from "./forage.consts";
// Icons

export const initFeedHerd = () => ({
  id: Date.now(),
  itemCategory: "",
  item: "",
  percentage: "",
});

function FeedHerd({ id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:forage.form.1", "Kategoria paszy")} />
        <CftFormField name={`feed[${id}].itemCategory`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {newFeedType.map((b, key) => (
                  <CftBox
                    key={key}
                    isActive={value === b.value}
                    onClick={() => {
                      helpers.setValue(b.value);
                      setFieldValue(`${b.value}[${id}].item`, "");
                    }}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    {b.icon}
                    <p className="cft-box__paragraph">{b[language]}</p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={values.feed[id].itemCategory !== ""}
      >
        <CftFormControl variant="small">
          <CftLabel label={t("cft:forage.form.2", "Składnik paszowy")} />
          <CftSelectField
            name={`feed[${id}].item`}
            options={
              feedType[values.feed[id].itemCategory]
                ? feedType[values.feed[id].itemCategory].map(opt => ({
                    value: opt.value,
                    label: opt[language],
                  }))
                : []
            }
          />
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:forage.form.3", "Procent")} />
        <CftTextField name={`feed[${id}].percentage`} adornment="%" />
      </CftFormControl>
    </>
  );
}

function FeedForEntireHerd() {
  const { t } = useTranslation(["cft"]);
  const { values } = useFormikContext();
  const groupRefs = useRef([]);

  const getError = (value, key) => {
    if (!value) {
      return false;
    }
    return !!value[key];
  };

  if (values.approach !== "2") {
    return null;
  }
  return (
    <>
      <CftFormField name="feed">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t("cft:forage.form.2", "Składnik paszowy")} ${key +
                  1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={
                  key !== 0
                    ? () => {
                        helpers.setValue(value.filter(f => f.id !== id));
                        scrollToLastElement(groupRefs, 130);
                      }
                    : undefined
                }
                hasError={meta.touched && getError(meta.error, key)}
              >
                <FeedHerd id={key} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initFeedHerd()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t("cft:forage.form.5", "Dodaj kolejny składnik paszowy")
                    : t("cft:forage.form.6", "Dodaj składnik paszowy")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default FeedForEntireHerd;

import React from "react";
import { Spinner } from "reactstrap";
import "./Loader.scss";

export const Loader = ({ style = {}, ...props }) => {
  const classes = () =>
    `loading${props.small ? " loading--small" : ""}${
      props.absolute ? " loading--abs" : ""
    } ${props.className}`;
  // const style = { style };
  return (
    <div className={classes()} style={style}>
      <Spinner style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};

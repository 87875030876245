/* eslint-disable no-undef */
import PropTypes from "prop-types";
import React from "react";
import Cookies from "js-cookie";
import { withTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { compose } from "recompose";
import { Redirect, Link } from "react-router-dom";

import appLogo from "../../assets/logo/agronomist_a.svg";
import withLayout from "../../services/hoc/withLayout";
import {
  Footer,
  Metadata,
  NavigationBar,
  PageHeader,
} from "../../components/common/index";
import headerBackground from "../../assets/img/tools/regagri/photo.jpg";
import featureA from "../../assets/img/tools/regagri/estimation.jpg";
import featureB from "../../assets/img/tools/regagri/results.jpg";
import featureC from "../../assets/img/tools/regagri/agriculture.jpg";
import { ReactComponent as DataIcon } from "../../assets/icons/tools/data_sources.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/tools/clock.svg";
import { ReactComponent as EmissionsIcon } from "../../assets/icons/tools/emissions.svg";

import AdditionalTools from "../../components/views/ToolsPage/components/AdditionalTools";

import "./RegagriExplorer.scss";
import regagriRoutes from "../utils/regagriRoutes";
import VideosPreview from "../../components/blocks/Videos/VideosPreview";
import Image from "../../components/common/Image/Image";

const RegagriExplorer = props => {
  const token = Cookies.get("user_token");
  const { currentWidth, t, i18n } = props;
  if (token) {
    return <Redirect to={regagriRoutes.CULTIVATION_DETAILS.MAIN} />;
  }
  return (
    <>
      <Container fluid className="tools-page tools-page--regagri">
        <Metadata
          photo={appLogo}
          name={t("regagri:meta.title")}
          description={t("regagri:meta.description")}
        />
        <NavigationBar />
        <PageHeader
          breadcrumbs={{
            path: [
              {
                name: t("navigation:navTools"),
                url: "/narzedzia",
              },
            ],
            currentName: t("regagri:regagriExplorer.header.0"),
          }}
          title={t("regagri:regagriExplorer.header.0")}
          subtitle={
            <>
              <span>{t("regagri:regagriExplorer.header.1")}</span>
              <span>{t("regagri:regagriExplorer.header.2")}</span>
            </>
          }
          backgroundSrc={headerBackground}
        />
        <Container className="tools-page__features">
          <Row>
            <Col xs={12} className="tools-page__features__header">
              <h1>{t("regagri:regagriExplorer.features.0")}</h1>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t("regagri:regagriExplorer.features.1"),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="tools-page__features__feature">
              <Image
                src={featureA}
                alt={t("regagri:regagriExplorer.features.2")}
                width="370px"
              />
              <div>
                <h3>{t("regagri:regagriExplorer.features.2")}</h3>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t("regagri:regagriExplorer.features.3"),
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="tools-page__features__feature isReverse">
              <div className="isLeft">
                <h3>{t("regagri:regagriExplorer.features.4")}</h3>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t("regagri:regagriExplorer.features.5"),
                  }}
                />
              </div>
              <Image
                src={featureB}
                alt={t("regagri:regagriExplorer.features.4")}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="tools-page__features__feature">
              <Image
                src={featureC}
                alt={t("regagri:regagriExplorer.features.6")}
              />
              <div>
                <h3>{t("regagri:regagriExplorer.features.6")}</h3>
                <p>{t("regagri:regagriExplorer.features.7")}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid className="tools-page__gain">
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="tools-page__titile">
                  {t("regagri:regagriExplorer.gain.0")}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} className="tools-page__gain__content">
                <div className="gain-icon">
                  <DataIcon />
                </div>
                <p>{t("regagri:regagriExplorer.gain.1")}</p>
              </Col>
              <Col xs={6} md={4} className="tools-page__gain__content">
                <div className="gain-icon">
                  <ClockIcon />
                </div>
                <p>{t("regagri:regagriExplorer.gain.2")}</p>
              </Col>
              <Col xs={12} md={4} className="tools-page__gain__content">
                <div className="gain-icon">
                  <EmissionsIcon />
                </div>
                <p>{t("regagri:regagriExplorer.gain.3")}</p>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className="tools-page__video">
          <Row>
            <Col xs={12} md={5}>
              <h3>{t("regagri:regagriExplorer.video.0")}</h3>
              <p>{t("regagri:regagriExplorer.video.1")}</p>
            </Col>
            <Col xs={12} md={7}>
              <VideosPreview
                video={{
                  title: t("regagri:regagriExplorer.video.2"),
                  video_url:
                    i18n.language === "pl"
                      ? "https://www.youtube.com/watch?v=p9h6sSjw4yw"
                      : "https://www.youtube.com/watch?v=bf7bEc7KgGI",
                }}
              />
            </Col>
          </Row>
        </Container>
        <Container fluid className="tools-page__proceed">
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="tools-page__titile">
                  {t("regagri:regagriExplorer.others.3")}
                </h1>
                <Link
                  to="/uzytkownik/login"
                  className="c-widget__cta tools-page__cta"
                >
                  {t("regagri:regagriExplorer.others.4")}
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="tools-page__help">
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="tools-page__titile">
                  {t("regagri:regagriExplorer.others.0")}
                </h1>
                <Link to="/kontakt" className="c-widget__cta tools-page__cta">
                  {t("regagri:regagriExplorer.others.1")}
                </Link>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid className="tools-page__additional">
          <Container>
            <Row>
              <Col xs={12}>
                <h1 className="tools-page__titile">
                  {t("regagri:regagriExplorer.others.2")}
                </h1>
              </Col>
            </Row>
            <AdditionalTools slug="agroemisja" />
          </Container>
        </Container>
        <Footer windowWidth={currentWidth} />
      </Container>
    </>
  );
};

RegagriExplorer.propTypes = {
  currentWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RegagriExplorer.defaultProps = {
  currentWidth: "",
};

export default compose(withTranslation(["regagri", "navigation"]))(
  withLayout(RegagriExplorer)
);

import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftLabel,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftSelectField,
  CftTextField,
  CftTooltip,
} from "../../../../components";
import { transportType } from "./transportBothCalc.consts";
import useCftOptions from "../../../../utils/useCftOptions";

// method icons
import { ReactComponent as DrogowyIcon } from "../../../../../assets/icons/cft/drogowy.svg";
import { ReactComponent as KolejowyIcon } from "../../../../../assets/icons/cft/pociogowy.svg";
import { ReactComponent as LotniczyIcon } from "../../../../../assets/icons/cft/lotniczy.svg";
import { ReactComponent as StatkiemIcon } from "../../../../../assets/icons/cft/morski.svg";

function TransportTypeFields({ id, name }) {
  const { setFieldValue, values } = useFormikContext();
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:transport.form.1", "Metoda transportu")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:transport.tooltips.1",
                "Wybierz wszystkie metody transportu, które były wykorzystywane dla tej uprawy"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].method`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "road"}
                  onClick={() => {
                    helpers.setValue("road");
                    setFieldValue(`${name}[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DrogowyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.2", "Drogowy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "rail"}
                  onClick={() => {
                    helpers.setValue("rail");
                    setFieldValue(`${name}[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <KolejowyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.3", "Kolejowy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "air"}
                  onClick={() => {
                    helpers.setValue("air");
                    setFieldValue(`${name}[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <LotniczyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.4", "Lotniczy")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "ship"}
                  onClick={() => {
                    helpers.setValue("ship");
                    setFieldValue(`${name}[${id}].type`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <StatkiemIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:transport.form.5", "Statkiem")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={!!values.transports[id].method}
      >
        <>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:transport.form.6", "Rodzaj transportu drogowego")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:transport.tooltips.2",
                    "Określ rodzaj samochodu"
                  )}
                />
              }
            />
            <CftSelectField
              name={`${name}[${id}].type`}
              options={
                values.transports[id].method
                  ? transportType[values.transports[id].method].map(opt => ({
                      label: opt[language],
                      value: opt.id,
                    }))
                  : []
              }
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:cattleHerd.form.2", "Waga")} />
            <CftUnitField
              name={`${name}[${id}].weight`}
              units={getUnits(["t", "kg"])}
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:transport.form.7", "Dystans (w obie strony)")}
            />
            <CftTextField name={`${name}[${id}].distance`} adornment="km" />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:transport.form.8", "Dodaj etykietę")} />
            <CftTextField name={`${name}[${id}].label`} />
          </CftFormControl>
        </>
      </CftConditionalRendering>
    </>
  );
}

export default TransportTypeFields;

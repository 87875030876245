import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftActionsButtons,
  CftTank,
  CftHenhousesHeader,
  CftLeavingModal,
  CftChickenBroilerReport,
  CftExportData,
} from "../../components";
import routes from "../../utils/cftRoutes";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import "../styles/progressDashboard.scss";

export default function ChickenBroilerReportPage() {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const history = useHistory();
  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const { store } = useCftChickenBroilerValues();
  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          cropName={t("cftChickenBroiler:tag", "")}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportData type="chicken" isReport />
              <CftActionsButtons.CancelButton
                hasText={false}
                onClick={() => {
                  stopOverflow();
                  setShowLeavingModal(!showLeavingModal);
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        withNoSpace
        header={
          <CftTank display="flex" flexDirection="column" alignItems="center">
            <h3 className="cft-wrapper">
              {t("cftChickenBroiler:tag", "kalkulator emisji")}
            </h3>
            <h4 className="cft-wrapper">
              {t("cftChickenBroiler:tag", "Brojler kurzy")}
            </h4>
            <CftHenhousesHeader />
          </CftTank>
        }
      >
        <CftChickenBroilerReport />
      </CftSection>
      <CftLeavingModal
        isVisible={showLeavingModal}
        hide={() => setShowLeavingModal(!showLeavingModal)}
        type="bothCalc"
        goToProfile={() => history.push(routes.MAIN)}
        dataReport={JSON.stringify(store)}
      />
    </CftLayout>
  );
}

import useSWR from "swr";
import API from "../../../../services/apiHelpers";

export function useListOfDairyProducts() {
  const url = "/api/v1/dairy/products/";
  const { data } = useSWR(url, () => API().get(url));

  return {
    data: data ? data.data : undefined,
  };
}

export function useDataForRaportCenowy() {
  const url = "/api/v1/dairy/";
  const dependencies = [url];
  const { data } = useSWR(dependencies, () =>
    API().get(url, {
      params: {
        limit: 3,
        ordering: "-week_start_date",
      },
    })
  );

  return {
    data: data ? data.data : undefined,
  };
}

import React from "react";
import "./ToggleSwitch.scss";

export const ToggleSwitch = ({ handleChange, checked, children }) => {
  return (
    <div className="c-toggleswitch">
      <div className="c-toggleswitch-label">{children}</div>
      <div
        className={`c-toggleswitch-controller c-controller${
          checked ? " c-controller--active" : ""
        }`}
        onClick={handleChange}
      >
        <div className="c-controller-bar" />
        <div className="c-controller-diode" />
      </div>
    </div>
  );
};

import { dotFormatter } from "../../../../utils/cftUtils";

export function mapValues(values) {
  return {
    name: values.name,
    year: Number(values.year),
    crop_id: Number(values.crop),
    product_fresh: {
      value: dotFormatter(values.harvestAmount.value),
      unit_id: Number(values.harvestAmount.unit),
    },
    product_finished: {
      value: dotFormatter(values.exit.value),
      unit_id: Number(values.exit.unit),
    },
    field_size: {
      value: dotFormatter(values.areal.value),
      unit_id: Number(values.areal.unit),
    },
    seed_amount:
      values.seedAmount.value === undefined
        ? undefined
        : {
            value: dotFormatter(values.seedAmount.value),
            unit_id: Number(values.seedAmount.unit),
          },
  };
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftBox,
  CftProgressCircle,
  CftActionsButtons,
  CftTank,
  CftExportReport,
  CftLeavingModal,
} from "../../components";

import {
  ReactComponent as CropIcon,
  ReactComponent as HerdIcon,
} from "../../../assets/icons/cft/cow.svg";

import { ReactComponent as PastureIcon } from "../../../assets/icons/cft/confident.svg";
import { ReactComponent as FeedIcon } from "../../../assets/icons/cft/pasza_wloknista.svg";
import { ReactComponent as ManureIcon } from "../../../assets/icons/cft/obornik.svg";
import { ReactComponent as EnergyIcon } from "../../../assets/icons/cft/energia.svg";
import { ReactComponent as TransportIcon } from "../../../assets/icons/cft/drogowy.svg";
import { ReactComponent as RaportIcon } from "../../../assets/icons/cft/detale.svg";
import { useCft } from "../../utils/CftProvider";
import routes from "../../utils/cftRoutes";

import "../styles/progressDashboard.scss";

export default function DairyProductionPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { meta, progress } = useCft();
  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          cropName={meta.title.dairyProduction}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <CftActionsButtons.GoToProfile
                onClick={() => {
                  setShowLeavingModal(!showLeavingModal);
                  stopOverflow();
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        header={
          <CftTank display="flex" flexDirection="row" alignItems="center">
            <h3 className="progress-dashboard-header cft-wrapper">
              {meta.title.dairyProduction}
            </h3>
            <CftExportReport type="dairyProduction" />
          </CftTank>
        }
      >
        <div className="progress-dashboard cft-wrapper">
          <CftBox onClick={() => history.push(routes.DAIRY_PRODUCTION.DETAILS)}>
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.details.complete}
              />
              <CropIcon />
              <p>
                {t("cft:types.detailsDairyProduction", "Szczegóły hodowli")}
              </p>
            </div>
          </CftBox>
          <CftBox
            onClick={() =>
              history.push(routes.DAIRY_PRODUCTION.CHARACTERISTICS)
            }
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.characteristics.complete}
              />
              <HerdIcon />
              <p>{t("cft:types.characteristics", "Charakterystyka stada")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.DAIRY_PRODUCTION.CATTLE_GRAZING)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.cattleGrazing.complete}
              />
              <PastureIcon />
              <p>{t("cft:types.cattleGrazing", "Wypas bydła")}</p>
            </div>
          </CftBox>
          <CftBox onClick={() => history.push(routes.DAIRY_PRODUCTION.FORAGE)}>
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.forage.complete}
              />
              <FeedIcon />
              <p>{t("cft:types.forage", "Pasza")}</p>
            </div>
          </CftBox>
          <CftBox onClick={() => history.push(routes.DAIRY_PRODUCTION.MANURE)}>
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.manure.complete}
              />
              <ManureIcon />
              <p>{t("cft:types.manure", "Obornik")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() =>
              history.push(routes.DAIRY_PRODUCTION.ENERGY_AND_PROCESSING)
            }
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.energyAndProcessing.complete}
              />
              <EnergyIcon />
              <p>{t("cft:types.fuelAndEnergy", "Energia i przetwarzanie")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.DAIRY_PRODUCTION.TRANSPORT)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.dairyProduction.transport.complete}
              />
              <TransportIcon />
              <p>{t("cft:types.transport", "Transport")}</p>
            </div>
          </CftBox>
          <CftBox onClick={() => history.push(routes.DAIRY_PRODUCTION.REPORT)}>
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.reports.dairyProduction.complete}
              />
              <RaportIcon />
              <p>{t("cft:types.report", "Raport")}</p>
            </div>
          </CftBox>
        </div>
        <CftLeavingModal
          isVisible={showLeavingModal}
          hide={() => setShowLeavingModal(!showLeavingModal)}
          type="dairyProduction"
          goToProfile={() => history.push(routes.MAIN)}
        />
      </CftSection>
    </CftLayout>
  );
}

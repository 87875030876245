import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues({
  name,
  year,
  month,
  totalMilkProduction,
  referencePeriod,
  breed,
  fatContent,
  proteinContent,
  proteinContentType,
}) {
  return {
    name,
    variety: `${breed}`,
    reporting_year: year,
    date_time: referencePeriod,
    date_month: month,
    product_dry: {
      value: dotFormatter(totalMilkProduction.value),
      unit_id: `${totalMilkProduction.unit}`,
    },
    fat_content: dotFormatter(fatContent),
    protein_content: dotFormatter(proteinContent),
    protein_measure: proteinContentType,
  };
}

export const COW_TYPES = [
  {
    label: "Ayrshire",
    value: 1,
  },
  {
    label: "Brown Swiss",
    value: 2,
  },
  {
    label: "Fleckvieh",
    value: 3,
  },
  {
    label: "Friesian",
    value: 4,
  },
  {
    label: "Girolando",
    value: 5,
  },
  {
    label: "Guernsey",
    value: 6,
  },
  {
    label: "Holstein",
    value: 7,
  },
  {
    label: "Illawarra",
    value: 8,
  },
  {
    label: "Jersey",
    value: 9,
  },
  {
    label: "Meuse Rhine Issel / Rotbunt",
    value: 10,
  },
  {
    label: "Montbéliarde",
    value: 11,
  },
  {
    label: "Normande",
    value: 12,
  },
  {
    label: "Norwegian Red",
    value: 13,
  },
  {
    label: "Randall",
    value: 14,
  },
];

export const DATE_TIME_TYPES = [
  {
    id: "1",
    label: {
      i18n: "cft:characteristics.form.4",
      default: "Start",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:characteristics.form.5",
      default: "Środek",
    },
  },
  {
    id: "3",
    label: {
      i18n: "cft:characteristics.form.6",
      default: "Koniec",
    },
  },
];

export const PROTEIN_MEASURE_TYPES = [
  {
    id: "1",
    label: {
      i18n: "cft:characteristics.form.12",
      default: "Zawartość białka ogólnego",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:characteristics.form.13",
      default: "Zawartość białka surowego",
    },
  },
];

export function getCowName(id) {
  const type = COW_TYPES.find(({ value }) => value === Number(id));

  return type ? type.label : id;
}

export const mapMilkProduction = data => {
  const parsedData = Object.keys(data)
    .map(key => {
      if (key === "variety") {
        return {
          name: "Rasa główna",
          value: getCowName(data[key]),
        };
      }

      if (key === "date_time") {
        const val = DATE_TIME_TYPES.find(({ id }) => Number(id) === data[key]);
        return {
          name: "Początek okresu referencyjnego",
          value: val ? val.label.default : "",
        };
      }

      if (key === "fat_content") {
        return {
          name: "Zawartość tłuszczu",
          value: `${data[key]}%`,
        };
      }

      if (key === "protein_measure") {
        const val = PROTEIN_MEASURE_TYPES.find(
          ({ id }) => Number(id) === data[key]
        );
        return {
          name: "Typ",
          value: val ? val.label.default : "",
        };
      }

      if (key === "protein_content") {
        return {
          name: "Zawartość białka",
          value: `${data[key]}%`,
        };
      }

      return null;
    })
    .filter(val => val !== null);

  return parsedData;
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useCft } from "../../../utils/CftProvider";
import { dairyProductionInit } from "../../../utils/cftDairyProductionInitialValues";
import { ReactComponent as ExitIcon } from "../../../../assets/icons/cft/x.svg";
import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftNumberField,
  CftQuestionGroup,
} from "../../../components";

const getInitCattleHerd = id => {
  const weight = [152, 152, 368, 509, 550, 509];

  return {
    animals: "",
    liveWeight: weight[id],
    soldAnimals: "",
    soldWeight: weight[id],
    purchasedAnimals: "",
    purchasedWeight: weight[id],
  };
};

export const titlesCattleHerd = [
  "cft:titlesCattleHerd.1",
  "cft:titlesCattleHerd.2",
  "cft:titlesCattleHerd.3",
  "cft:titlesCattleHerd.4",
  "cft:titlesCattleHerd.5",
  "cft:titlesCattleHerd.6",
];

function CattleHerdFields({ id, value, isTouched }) {
  const [isModal, setModal] = useState("");
  const { t } = useTranslation(["cft"]);
  const { saveDairyProduction } = useCft();

  return (
    <>
      <CftQuestionGroup label={t(titlesCattleHerd[id])}>
        <CftFormControl variant="small">
          <CftLabel label={t(titlesCattleHerd[id])} />
          <CftFormField name={`cattleHerd[${id}]`}>
            {({ value, helpers, meta }) => (
              <>
                <CftTank display="flex" justifyContent="space-around">
                  <CftButton
                    isActive={!!value}
                    isInvalid={value === null && meta.touched && meta.error}
                    onClick={() => {
                      if (isTouched) {
                        setModal("add");
                      } else {
                        helpers.setValue(getInitCattleHerd(id));
                        saveDairyProduction({
                          cattleGrazing: dairyProductionInit.cattleGrazing,
                          forage: dairyProductionInit.forage,
                          manure: dairyProductionInit.manure,
                        });
                      }
                    }}
                  >
                    {t("cft:yes", "TAK")}
                  </CftButton>
                  <CftButton
                    isInvalid={value === null && meta.touched && meta.error}
                    isActive={value === false}
                    onClick={() => {
                      if (isTouched) {
                        setModal("remove");
                      } else {
                        helpers.setValue(false);
                      }
                    }}
                  >
                    {t("cft:no", "Nie")}
                  </CftButton>
                </CftTank>
                {value === null && (
                  <CftError
                    isActive={meta.touched && meta.error}
                    message={meta.error}
                    textAlign="center"
                  />
                )}
              </>
            )}
          </CftFormField>
        </CftFormControl>
        {!!value && (
          <>
            <CftFormControl variant="small">
              <CftTank mb={4} justifyContent="center">
                <CftLabel
                  label={t("cft:cattleHerd.title", "Zwierzęta w gospodarstwie")}
                />
              </CftTank>
              <Row>
                <Col xs={12} md={6}>
                  <CftLabel label={t("cft:cattleHerd.form.1", "Liczba")} />
                  <CftNumberField name={`cattleHerd[${id}].animals`} />
                </Col>
                <Col xs={12} md={6}>
                  <CftLabel label={t("cft:cattleHerd.form.2", "Waga")} />
                  <CftTextField
                    adornment="kg"
                    name={`cattleHerd[${id}].liveWeight`}
                  />
                </Col>
              </Row>
            </CftFormControl>
            <CftFormControl variant="small">
              <CftTank mb={4} justifyContent="center">
                <CftLabel
                  label={t("cft:cattleHerd.form.3", "Zwierzęta zakupione")}
                />
              </CftTank>
              <Row>
                <Col xs={12} md={6}>
                  <CftLabel label={t("cft:cattleHerd.form.1", "Liczba")} />
                  <CftNumberField name={`cattleHerd[${id}].purchasedAnimals`} />
                </Col>
                <Col xs={12} md={6}>
                  <CftLabel
                    label={t("cft:cattleHerd.form.4", "Waga startowa")}
                  />
                  <CftTextField
                    adornment="kg"
                    name={`cattleHerd[${id}].purchasedWeight`}
                  />
                </Col>
              </Row>
            </CftFormControl>
            <CftFormControl variant="small">
              <CftTank mb={4} justifyContent="center">
                <CftLabel
                  label={t("cft:cattleHerd.form.5", "Zwierzęta sprzedane")}
                />
              </CftTank>
              <Row>
                <Col xs={12} md={6}>
                  <CftLabel label={t("cft:cattleHerd.form.1", "Liczba")} />
                  <CftNumberField name={`cattleHerd[${id}].soldAnimals`} />
                </Col>
                <Col xs={12} md={6}>
                  <CftLabel
                    label={t("cft:cattleHerd.form.5", "Zwierzęta sprzedane")}
                  />
                  <CftTextField
                    adornment="kg"
                    name={`cattleHerd[${id}].soldWeight`}
                  />
                </Col>
              </Row>
            </CftFormControl>
          </>
        )}
      </CftQuestionGroup>
      {isModal && (
        <div className="cft-saving-overlay">
          <div className="cft-saving-box">
            <div className="cft-saving-exit" onClick={() => setModal("")}>
              <ExitIcon />
            </div>
            <div className="cft-saving-content">
              <div className="cft-saving-title">
                {t("cft:cattleHerd.modal.title", "ZMIANA KLUCZOWYCH DANYCH")}
              </div>
              <div className="cft-saving-description">
                {t(
                  "cft:cattleHerd.modal.description",
                  "Modyfikacja stada, wymagać będzie ponownego wypełnienia kalkulacji. Czy na pewno chcesz modyfikować stado?"
                )}
              </div>
              <CftFormField name={`cattleHerd[${id}]`}>
                {({ helpers }) => (
                  <>
                    <div
                      className="cft-saving-back-button"
                      onClick={() => {
                        if (isModal === "add") {
                          helpers.setValue(getInitCattleHerd(id));
                        } else {
                          helpers.setValue(false);
                        }
                        saveDairyProduction({
                          cattleGrazing: dairyProductionInit.cattleGrazing,
                          forage: dairyProductionInit.forage,
                          manure: dairyProductionInit.manure,
                        });
                        setModal("");
                      }}
                    >
                      {t("cft:cattleHerd.modal.modify", "Modyfikuj")}
                    </div>
                  </>
                )}
              </CftFormField>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CattleHerdFields;

import React, { useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useHover } from "use-events";

import "./CftChooseBox.scss";

function CftChooseBox({ leftChildren, onClick, leftBox, rightBox }) {
  const [isHovered, bind] = useHover();
  const [isLeftActive, setLeftActive] = useState(false);
  const [isRightActive, setRightActive] = useState(false);

  return (
    <div className={classnames("cft-choose-box-container")}>
      <div className={classnames("cft-choose-box-buttons")}>
        <div
          {...bind}
          className={classnames("cft-box-left", {
            "cft-box-left--isHovered": isHovered,
            "cft-box-left--isActive": isLeftActive,
          })}
          onClick={e => {
            setLeftActive(!isLeftActive);
            setRightActive(false);
            if (onClick) {
              onClick(e);
            }
          }}
        >
          {leftBox}
        </div>
        <div
          {...bind}
          className={classnames("cft-box-right", {
            "cft-box-right--isHovered": isHovered,
            "cft-box-right--isActive": isRightActive,
          })}
          onClick={() => {
            setRightActive(!isRightActive);
            setLeftActive(false);
          }}
        >
          {rightBox}
        </div>
      </div>

      <div>{isLeftActive && leftChildren}</div>
    </div>
  );
}

CftChooseBox.propTypes = {
  leftChildren: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
    .isRequired,
  rightBox: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  leftBox: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
};

CftChooseBox.defaultProps = {
  onClick: null,
};

export default CftChooseBox;

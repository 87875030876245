import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CftFormErrorFocus } from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import HerdFields from "./HerdFields";
import GrasslandFertilisationFileds from "./GrasslandFertilisationFileds";
import FertiliserApplications from "./FertiliserApplications";
import { dairyProductionAPI } from "../../../utils/cftApi";
import { mapValues } from "./cattleGrazingDP.utils";

function CattleGrazingDPForm() {
  const { values } = useFormikContext();

  return (
    <Form className="cft-form narrow-labels">
      {values.herd.map((value, key) => (
        <HerdFields value={value} id={key} />
      ))}
      <GrasslandFertilisationFileds />
      <FertiliserApplications />
      <CftFormErrorFocus />
    </Form>
  );
}

export default CattleGrazingDPForm;

export function CattleGrazingDPFormContext({ children }) {
  const history = useHistory();
  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();
  const { t } = useTranslation(["cft"]);

  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.cattleGrazing}
      validationSchema={Yup.object().shape({
        herd: Yup.array().of(
          Yup.object()
            .shape({
              days: Yup.number()
                .required(
                  t(
                    "cft:validation:required",
                    t("cft:validation.required", "Wpisz wartość")
                  )
                )
                .typeError(
                  t(
                    "cft:validation:numberTypeError",
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                )
                .min(
                  0,
                  t(
                    "numberMinError",
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  )
                ),
              hours: Yup.number()
                .required(
                  t(
                    "cft:validation:required",
                    t("cft:validation.required", "Wpisz wartość")
                  )
                )
                .typeError(
                  t(
                    "cft:validation:numberTypeError",
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                )
                .min(
                  0,
                  t(
                    "numberMinError",
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  )
                ),
              category: Yup.string().required(
                t(
                  "cft:validation:required",
                  t("cft:validation.required", "Wpisz wartość")
                )
              ),
              quality: Yup.string().required(
                t(
                  "cft:validation:required",
                  t("cft:validation.required", "Wpisz wartość")
                )
              ),
            })
            .nullable()
        ),
        fertilisation: Yup.object()
          .shape({
            fertilisationApproach: Yup.string().required(
              t(
                "cft:validation:required",
                t("cft:validation.required", "Wpisz wartość")
              )
            ),
            grazingArea: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(
                  t(
                    "cft:validation:required",
                    t("cft:validation.required", "Wpisz wartość")
                  )
                )
                .typeError(
                  t(
                    "cft:validation:numberTypeError",
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                )
                .min(
                  0,
                  t(
                    "numberMinError",
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  )
                ),
              unit: Yup.string().required(
                t(
                  "unitTypeError",
                  t("cft:validation.unitTypeError", "Wybierz jednostkę")
                )
              ),
            }),
          })
          .nullable(),
        applications: Yup.array().of(
          Yup.object()
            .shape({
              typeCategory: Yup.string().required(
                t(
                  "cft:validation:requiredOption",
                  t("cft:validation.requiredOption", "Wybierz opcję")
                )
              ),
              type: Yup.string().required(
                t(
                  "cft:validation:requiredOption",
                  t("cft:validation.requiredOption", "Wybierz opcję")
                )
              ),
              rate: Yup.object().shape({
                value: Yup.number()
                  .transform((_, v) => Number(v.replace(/,/, ".")))
                  .required(
                    t(
                      "cft:validation:required",
                      t("cft:validation.required", "Wpisz wartość")
                    )
                  )
                  .typeError(
                    t(
                      "cft:validation:numberTypeError",
                      t(
                        "cft:validation.numberTypeError",
                        "Wpisz poprawną wartośc liczbową"
                      )
                    )
                  ),
                unit: Yup.string().required(
                  t(
                    "cft:validation:unitTypeError",
                    t("cft:validation.unitTypeError", "Wybierz jednostkę")
                  )
                ),
              }),
              production: Yup.string().required(
                t(
                  "cft:validation:requiredOption",
                  t("cft:validation.requiredOption", "Wybierz opcję")
                )
              ),
            })
            .nullable()
        ),
      })}
      onSubmit={values => {
        const parsedValues = mapValues(values);
        Promise.all([
          dairyProductionAPI.grazingValidation({
            grazing: parsedValues.grazing,
          }),
          dairyProductionAPI.dairyFertiliserValidation({
            fertilisation_approach: parsedValues.fertilisation_approach,
            fertilisers: parsedValues.fertilisers,
            grazing_area: parsedValues.grazing_area,
          }),
        ]).then(([grazing, dairyFertiliser]) => {
          saveDairyProductionReport({
            ...grazing.data,
            ...dairyFertiliser.data,
          });
          history.push(routes.DAIRY_PRODUCTION.FORAGE);
          saveDairyProduction({ cattleGrazing: values });
        });
      }}
    >
      {children}
    </Formik>
  );
}

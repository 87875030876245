/* eslint-disable no-param-reassign, prefer-destructuring, dot-notation */
import { applyMiddleware, createStore } from "redux";
import Cookies from "js-cookie";
import { composeWithDevTools } from "redux-devtools-extension";
import axiosMiddleware from "redux-axios-middleware";
import rootReducer from "./rootReducer";
import client from "../apiHelpers";
import i18n from "../../i18n";

export default function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        axiosMiddleware(client(), {
          interceptors: {
            request: [
              ({ getState }, config) => {
                if (config.url.includes("SET_LANG_CODE")) {
                  const lang =
                    getState().user.user.default_language || i18n.language;
                  config.url = config.url.replace(
                    "SET_LANG_CODE",
                    lang.toUpperCase()
                  );
                }
                const token = Cookies.get("user_token");
                if (token !== undefined && config.headers !== undefined) {
                  config.headers.common["Authorization"] = `Bearer ${token}`;
                }
                return config;
              },
            ],
          },
        })
      )
    )
  );

  return store;
}

import { pmt } from "./bankingHelpers";

export const getInterestInstallmentAmountWithSubvention = (
  {
    capitalWithInterestInstallmentAmount,
    creditRate,
    interestInstallmentFrequency,
    creditAmount,
    previousEntry,
  },
  { isFirstEntry }
) => {
  const currentCreditAmount = isFirstEntry
    ? creditAmount
    : previousEntry.currentCreditAmount;

  return capitalWithInterestInstallmentAmount !== 0
    ? (currentCreditAmount * creditRate) / 100 / interestInstallmentFrequency
    : (currentCreditAmount * creditRate) / 100 / 12;
};

export const matchPaymentPeriod = ({ capitalInstallmentFrequency, month }) => {
  if (capitalInstallmentFrequency === 12) {
    if (month % 12 === 0) {
      return 12;
    }
    return month % 12;
  }
  if (capitalInstallmentFrequency === 4) {
    if (Math.ceil((month % 12) / 3) === 0) {
      return 4;
    }
    return Math.ceil((month % 12) / 3);
  }
  return undefined;
};

export const getCapitalWithInterestInstallmentAmountWithSubvention = ({
  creditRate,
  capitalInstallmentFrequency,
  month,
  subventionRepaymentMonth,
  installmentsCountAfterSubvention,
  creditBaseAfterSubvention,
  numberOfInterestInstallments,
  creditAmount,
}) => {
  if (
    matchPaymentPeriod({ capitalInstallmentFrequency, month }) !==
    matchPaymentPeriod({ capitalInstallmentFrequency, month: month + 1 })
  ) {
    const isAfterSubvention = month > subventionRepaymentMonth;

    const interestInstallmentsCount = isAfterSubvention
      ? installmentsCountAfterSubvention
      : numberOfInterestInstallments;
    const currentCreditBase = isAfterSubvention
      ? creditBaseAfterSubvention
      : creditAmount;

    return -pmt(
      creditRate / 100 / capitalInstallmentFrequency,
      interestInstallmentsCount,
      currentCreditBase
    );
  }
  return 0;
};

export const getCapitalInstallmentAmountWithSubvention = ({
  capitalWithInterestInstallmentAmount,
  interestInstallmentAmount,
}) =>
  capitalWithInterestInstallmentAmount !== 0
    ? capitalWithInterestInstallmentAmount - interestInstallmentAmount
    : 0;

export const getCreditAmountWithSubvention = (
  {
    month,
    subventionRepaymentMonth,
    creditAmount,
    capitalInstallmentAmount,
    subventionAmount,
    previousEntry,
  },
  { isFirstEntry }
) => {
  const currentCreditAmount = isFirstEntry
    ? creditAmount
    : previousEntry.currentCreditAmount;

  return month === subventionRepaymentMonth
    ? currentCreditAmount - capitalInstallmentAmount - subventionAmount
    : currentCreditAmount - capitalInstallmentAmount;
};

export const getCurrentInstallmentInterestPeriod = (
  { periodNo, previousEntry, interestInstallmentFrequency, creditPeriod },
  { isFirstEntry }
) => {
  if (isFirstEntry) {
    return periodNo;
  }
  if (
    previousEntry.interestPeriod + 1 >
    Math.ceil((creditPeriod / 12) * interestInstallmentFrequency)
  ) {
    return "";
  }
  return previousEntry.interestPeriod + 1;
};

export const getCurrentInstallmentMonth = (
  { year, interestInstallmentFrequency, previousEntry, repaymentTimespan },
  { isFirstEntry }
) => {
  if (isFirstEntry) {
    return year * (12 / interestInstallmentFrequency);
  }
  if (
    previousEntry.month + 12 / interestInstallmentFrequency >
    repaymentTimespan * 12
  ) {
    return "";
  }
  return previousEntry.month + 12 / interestInstallmentFrequency;
};

export const getFirstCapitalInstallmentAfterSubvention = ({
  subventionRepaymentMonth,
  output,
  periodKey = "month",
}) =>
  output.find(
    entry =>
      entry[periodKey] > subventionRepaymentMonth &&
      parseFloat(entry.capitalInstallmentAmount) > 0
  );

export const getFirstAnyInstallmentAfterSubvention = ({
  subventionRepaymentMonth,
  output,
  periodKey = "month",
}) => output.find(entry => entry[periodKey] === subventionRepaymentMonth + 1);

import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Disclaimer,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import imageUrl from "../assets/images/kredyt_28.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Kredyt na wznowienie produkcji po klęsce żywiołowej"
    ),
    title: "Kredyt na wznowienie produkcji po klęsce żywiołowej",
    description:
      "Posiadasz lub oczekujesz na sporządzenie protokołu z oszacowania zakresu i wysokości szkód? Szukasz preferencyjnego kredytu by wznowić produkcję po zaistniałej klęsce?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania preferencyjnego?",
    subtitle:
      "Korzystając z preferencyjnego kredytu masz możliwość w szybki sposób odbudować potencjał produkcyjny utracony w związku z zaistniałą szkodą.",
    benefits: [
      {
        title: "Niski koszt kredytu",
        description: "Kredyt z dopłatą ARiMR do oprocentowania",
        icon: "Percent",
      },
      {
        title: "Proste procedury",
        description:
          "Bank zrealizuje za Ciebie wszelkie formalności z ARiMR w związku z udzieleniem preferencyjnego kredytu",
        icon: "TickRounded",
      },
      {
        title: "Wysokie kwoty kredytu",
        description:
          "Możliwość uzyskania kredytu do wysokości oszacowanych szkód przez komisję powołaną przez wojewodę",
        icon: "MoneyPlus",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się ze środków, które pomogą Ci odbudować potencjał gospodarstwa po zaistniałej klęsce.",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='https://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Zabezpieczenie",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        title: "Kredyt z dopłatą ARiMR na wznowienie produkcji po klęsce",
        list: [
          "Niskie oprocentowanie nawet od 0,5%",
          "4 lata (liczone od dnia wystąpienia klęski)",
          "Do wysokości oszacowanych szkód (do 8 mln PLN)",
          "Brak lub zabezpieczenie rzeczowe np. hipoteka",
          "Jednorazowo / miesięcznie / kwartalnie/ półrocznie / rocznie",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Kredyt z dopłatą ARiMR na wznowienie produkcji po klęsce`;
          },
        },
      },
    ],
  },
};

const KredytKleskowy = () => (
  <PageTemplate
    meta={{
      name: "Kredyt dla gospodarstw dotkniętych klęską żywiołową",
      description:
        "Jeśli Twoje gospodarstwo zostało dotknięte klęską żywiołową, niskooprocentowany kredyt z dopłatą ARiMR pozwoli Ci stanąć na nogi - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytKleskowy;

/* eslint-disable no-undef */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import {
  NavigationBar,
  Footer,
  Metadata,
  Breadcrumbs,
} from "../../../common/index";
import withLayout from "../../../../services/hoc/withLayout";
import "../StaticPage.scss";
import Cookies from "js-cookie";

class Rodo extends Component {
  render() {
    const { t } = this.props;
    const lang = Cookies.get("user_lang");

    return (
      <>
        <NavigationBar search={false} />
        <Metadata
          name={t("metaInfo:rodoPage.title")}
          description={t("metaInfo:rodoPage.description")}
        />
        <Container>
          <Row className="static">
            <Col xs={12}>
              <Breadcrumbs currentName="RODO" />
              <h1 className="typography--h1">RODO</h1>
              <p className="static__paragraph static__paragraph--bigger">
                {lang !== "gb" && "Szanowni Państwo,"}
                {lang === "gb" && "Dear Sir or Madam,"}
              </p>
              <br />

              <p className="static__paragraph static__paragraph--big">
                {lang !== "gb" &&
                  `od 25 maja 2018 roku obowiązuje Rozporządzenie Parlamentu
                Europejskiego i Rady (EU) 2016/679 z dnia 27 kwietnia 2016 roku
                w sprawie ochrony osób fizycznych w związku z przetwarzaniem ich
                danych osobowych i w sprawie swobodnego przepływu takich danych
                oraz uchylenia dyrektywy 95/46/WE (określane jako RODO, GDPR lub
                Ogólne Rozporządzenie o Ochronie Danych Osobowych). Celem RODO
                jest ujednolicenie zasad przetwarzania danych osobowych w całej
                Unii Europejskiej oraz ustandaryzowanie informacji kierowanych
                do klientów o ich prawach. Z uwagi na to, że jesteśmy częścią
                międzynarodowej grupy finansowej BNP Paribas, przekazujemy
                również Państwu, w załączonych osobno dokumentach, dodatkowe
                informacje o zasadach przetwarzania danych osobowych,
                obowiązujących w Banku jako członka Grupy BNP Paribas.`}

                {lang === "gb" &&
                  "On 25 May 2018 the Regulation of the European Parliament and of the Council (EU) 2016/679 dated 27 April 2016 shall become effective. The Regulation is on the protection of natural persons in connection with their personal data processing, the free flow of such data, and on the abrogation of directive 95/46/EC (defined as RODO, GDPR or the General Data Protection Regulation). The Purpose of GDPR is to unify personal data processing principles in the whole European Union and to standardise the information distributed to customers about their rights. As we are part of the international financial group BNP Paribas, we are also providing you, in the documents attached separately, with additional information on the principles of personal data processing, applicable at the BNP Paribas Bank Polska S.A."}

                <br />
                <br />
                <ul>
                  {lang !== "gb" && (
                    <>
                      <li>
                        <a
                          rel="noreferrer noopener"
                          href="/static/pdf/Klauzula_informacyjna_Klient_indywidualny_PL.pdf"
                          target="_blank"
                        >
                          Klauzula informacyjna BNP Paribas Bank Polska S.A.
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer noopener"
                          href="/static/pdf/Kontakt_Inspektor_Ochrony_Danych_IODO_PL.pdf"
                          target="_blank"
                        >
                          Inspektor Ochrony Danych (IOD)
                        </a>
                      </li>
                    </>
                  )}
                  {lang === "gb" && (
                    <>
                      <li>
                        <a
                          rel="noreferrer noopener"
                          href="/static/pdf/Klauzula_informacyjna_Klient_indywidualny_ENG.pdf"
                          target="_blank"
                        >
                          Data protection notice BNP Paribas Bank Polska S.A.
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noreferrer noopener"
                          href="/static/pdf/Kontakt_Inspektor_Ochrony_Danych_IODO_EN.pdf"
                          target="_blank"
                        >
                          Data Protection Officer
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </p>
              <br />
              <br />
            </Col>
          </Row>
        </Container>
        <Footer windowWidth={0} />
      </>
    );
  }
}

export default withTranslation("metaInfo")(withRouter(withLayout(Rodo)));

/* eslint-disable react/jsx-indent */
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftSelectField,
  CftFormControl,
  CftTextField,
  CftWarningBox,
  CftErrorBox,
  CftTooltip,
} from "../../components";
import useCftAeOptions from "../../utils/useCftAeOptions";

export default function FeedComponentsFields({ productionCyclesId, fieldKey }) {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const { setFieldValue, values } = useFormikContext();
  const {
    feedTypesOptions,
    mainFeedTypesOptions,
    getFeedTypeName,
  } = useCftAeOptions();

  const options = useMemo(() => {
    if (
      !values.production_cycles[productionCyclesId][fieldKey].tmp ||
      values.production_cycles[productionCyclesId][fieldKey].tmp.length === 0
    ) {
      return mainFeedTypesOptions;
    }

    const category =
      values.production_cycles[productionCyclesId][fieldKey].tmp[0].value;

    return feedTypesOptions.filter(
      ({ value, parent }) =>
        !values.production_cycles[productionCyclesId][fieldKey].feed_components
          .map(({ feed_type }) => feed_type)
          .includes(value) && parent === category
    );
  }, [values]);

  const restOfCycle = useMemo(() => {
    const targetAge = values.production_cycles[productionCyclesId][fieldKey]
      .target_age
      ? Number(
          values.production_cycles[productionCyclesId][fieldKey].target_age
        )
      : 0;

    const applicationDays = values.production_cycles[productionCyclesId][
      fieldKey
    ].feed_components.reduce((prev, acc) => {
      return prev + Number(acc.application_days);
    }, 0);

    return targetAge - applicationDays;
  }, [values]);

  return (
    <>
      {restOfCycle > 0 && (
        <>
          <h5 style={{ display: "flex", justifyContent: "space-between" }}>
            {t(
              "cftChickenBroiler:feedComponentsFields.fields.feed_componentsTitle",
              "Stosowane pasze"
            )}
            <CftTooltip
              content={t(
                "cftChickenBroiler:tooltip.feed_componentsTitle",
                "Z listy pasz wybiesz rodzaj paszy, podaj ilość zużytej paszy oraz ilość dni jej stosowania. Możesz zdeklarować maksymalnie 4-ry pasze które stosujesz na cykl produkcyjny."
              )}
            />
          </h5>
          <CftLabel
            isFullWidth
            label={t(
              "cftChickenBroiler:feedComponentsFields.fields.feed_componentsLabel",
              "Podaj informacje o stosowanych paszach (zdeklaruj maksymalnie 4-ry stosowane pasze)"
            )}
            className="mb-3"
          />
        </>
      )}
      {values.production_cycles[productionCyclesId][
        fieldKey
      ].feed_components.map(({ feed_type }, key) => (
        <CftTank key={feed_type} display="block" mt={key && 2}>
          <CftTank
            mb={2}
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <span className="cft-badge">
              {key + 1}. {getFeedTypeName(feed_type, t)}
            </span>
            <button
              className="cft-close-badge"
              type="button"
              onClick={() =>
                setFieldValue(
                  `production_cycles[${productionCyclesId}].${fieldKey}.feed_components`,
                  values.production_cycles[productionCyclesId][
                    fieldKey
                  ].feed_components.filter(feed => feed.feed_type !== feed_type)
                )
              }
            />
          </CftTank>
          <CftFormControl alignLeft>
            <CftLabel
              isFullWidth
              label={t(
                "cftTurkey:feedComponentsFields.fields.calorific_valueLabel2",
                "Kaloryczność paszy (kJ)"
              )}
            />
            <CftTextField
              isRounded
              name={`production_cycles[${productionCyclesId}].${fieldKey}.feed_components[${key}].calorific_value`}
              placeholder={t(
                "cftTurkey:feedComponentsFields.fields.calorific_valuePlaceholder",
                "Wpisz liczbę"
              )}
              isAlternative
              type="number"
            />
          </CftFormControl>
          <CftFormControl alignLeft>
            <CftLabel
              isFullWidth
              label={t(
                "cftTurkey:feedComponentsFields.fields.quantityLabel",
                "Ilość podanej paszy (kg)"
              )}
            />
            <CftTextField
              isRounded
              name={`production_cycles[${productionCyclesId}].${fieldKey}.feed_components[${key}].quantity`}
              placeholder={t(
                "cftTurkey:feedComponentsFields.fields.calorific_valuePlaceholder",
                "Wpisz liczbę"
              )}
              isAlternative
              type="number"
            />
          </CftFormControl>
          <CftFormControl
            alignLeft
            withoutMarginBottom={
              values.production_cycles[productionCyclesId][fieldKey]
                .feed_components.length === 4
            }
          >
            <CftLabel
              isFullWidth
              label={t(
                "cftTurkey:feedComponentsFields.fields.application_daysLabel",
                "Ilość dni stosowania paszy w tym cyklu"
              )}
            >
              <CftTooltip
                content={t(
                  "cftTurkey:tooltip.FemaleTurkeysFields.calorific_value",
                  "Aby poprawnie przeprowadzić kalkulację, ilość dni stosowania pasz nie może przekroczyć ilości dni cyklu produkcyjnego. Aby ułatwić Ci obliczenia, w polu tekstowym wyświetlimy ilość pozostałych w cyklu produkcyjnym dni dla których możesz zdeklarować pasze. Pamiętaj! Jeżeli nie podasz wystarczającej ilości paszy, wypełnimy pozostałe dni cyklu  produkcyjnego domyślną paszą: Grower kukurydza."
                )}
              />
            </CftLabel>
            <CftTextField
              isRounded
              name={`production_cycles[${productionCyclesId}].${fieldKey}.feed_components[${key}].application_days`}
              placeholder={t(
                "cftTurkey:feedComponentsFields.fields.calorific_valuePlaceholder",
                "Wpisz liczbę"
              )}
              adornment={
                <span className="test">
                  {t(
                    "cftTurkey:feedComponentsFields.fields.application_daysAdornment",
                    "pozostałe w tym cyklu:"
                  )}{" "}
                  {restOfCycle}
                </span>
              }
              isAlternative
              type="number"
            />
          </CftFormControl>
        </CftTank>
      ))}
      {values.production_cycles[productionCyclesId][fieldKey].feed_components
        .length < 4 ? (
        <>
          {restOfCycle > 0 && (
            <CftFormControl alignLeft withoutMarginBottom>
              <hr className="hr-section" />
              <CftLabel
                isFullWidth
                label={
                  values.production_cycles[productionCyclesId][fieldKey]
                    .feed_components.length > 0
                    ? t(
                        "cftChickenBroiler:feedComponentsFields.fields.feed_componentsAddNext",
                        "Dodaj kolejną paszę"
                      )
                    : t(
                        "cftChickenBroiler:feedComponentsFields.fields.feed_componentsAdd",
                        "Dodaj paszę"
                      )
                }
              />
              <CftSelectField
                blurInputOnSelect={false}
                closeMenuOnSelect={false}
                name={`production_cycles[${productionCyclesId}].${fieldKey}.tmp`}
                options={options}
                onChange={value => {
                  setFieldValue(
                    `production_cycles[${productionCyclesId}].${fieldKey}.tmp`,
                    value
                  );

                  if (value.length === 2) {
                    setFieldValue(
                      `production_cycles[${productionCyclesId}].${fieldKey}.feed_components`,
                      [
                        ...values.production_cycles[productionCyclesId][
                          fieldKey
                        ].feed_components,
                        {
                          feed_type: value[1].value,
                          calorific_value: 3100,
                          quantity: 120,
                          application_days: 0,
                          production: "1",
                        },
                      ]
                    );
                    setFieldValue(
                      `production_cycles[${productionCyclesId}].${fieldKey}.tmp`,
                      []
                    );
                  }
                }}
                label={t(
                  "cftTurkey:feedComponentsFields.fields.feed_componentsPlaceholder",
                  "Wybierz z listy"
                )}
                isRounded
                isAlternative
                isMulti
              />
            </CftFormControl>
          )}
        </>
      ) : (
        <CftWarningBox text="Zdeklarowałeś maksymalną ilość typów paszy" />
      )}

      {!values.production_cycles[productionCyclesId][fieldKey].feed_components
        .length && restOfCycle > 0 ? (
        <CftErrorBox
          text={t(
            "cftTurkey:productionCyclesFields.fields.feed_componentsError",
            "Minimum 1 pasz."
          )}
        />
      ) : null}
    </>
  );
}

FeedComponentsFields.propTypes = {
  productionCyclesId: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
};

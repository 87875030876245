export const toolNameToId = name => {
  switch (name.toLowerCase()) {
    case "findoor":
      return 1;
    case "satagro":
    case "monitorfield":
      return 2;
    case "agrivi":
      return 4;
    case "rolnikon":
      return 8;
    default:
      return 0;
  }
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftActionsButtons,
  CftReportProgress,
  CftExportReport,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import EnergyAndProcessingDPForm, {
  EnergyAndProcessingDPFormContext,
} from "../../forms/dairyProduction/EnergyAndProcessingDPForm/EnergyAndProcessingDP.form";

function EnergyAndProcessingPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { saveDairyProduction } = useCft();
  const { submitForm, values, isSubmitting } = useFormikContext();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={70}
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveDairyProduction({
                    energyAndProcessing: values,
                  });
                  history.push(routes.DAIRY_PRODUCTION.MAIN);
                }}
              />
              <CftExportReport type="dairyProduction" />
            </div>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:energyAndProcessing.title", "Energia i przetwarzanie")}
          onBack={() => history.push(routes.DAIRY_PRODUCTION.MANURE)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>
              {t("cft:energyAndProcessing.title", "Energia i przetwarzanie")}
            </h3>
            <p>
              {t(
                "cft:energyAndProcessing.subtitle",
                "Wybierz źródło energii i wprowadź ilość zużytą podczas rocznego okresu odniesienia."
              )}
            </p>
          </>
        }
      >
        <EnergyAndProcessingDPForm />
        <CftReportProgress type="dairyProduction" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <EnergyAndProcessingDPFormContext>
      <EnergyAndProcessingPage />
    </EnergyAndProcessingDPFormContext>
  );
};

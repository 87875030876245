import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftFormErrorFocus,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import FeedForEntireHerd, { initFeedHerd } from "./FeedForEntireHerd";
import { ReactComponent as CowIcon } from "../../../../assets/icons/cft/cow.svg";
import FeedPerAnimal, {
  initFeedHerd as initFeedHerdAnimal,
} from "./FeedPerAnimal";
import { dairyProductionAPI } from "../../../utils/cftApi";
import { mapValues } from "./forage.utils";

function ForageDPForm() {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const { dairyProduction } = useCft();

  const initFeedPerAnimal = () => {
    const feeds = dairyProduction.characteristics.cattleHerd.map(herd => {
      if (!herd) {
        return herd;
      }

      return {
        animals: [initFeedHerdAnimal()],
      };
    });
    setFieldValue("herd", feeds);
  };

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl>
        <CftLabel label={t("cft:forage.form.9", "Powierzchnia")} />
        <CftFormField name="approach">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    initFeedPerAnimal();
                    setFieldValue("feed", []);
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CowIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:forage.form.0", "Spożycie masy suchej\nna zwierzę")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    setFieldValue("feed", [initFeedHerd()]);
                    setFieldValue("herd", []);
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CowIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t(
                      "cft:forage.form.11",
                      "Średnie zużycie paszy\nprzez całe stado"
                    )}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <FeedForEntireHerd />
      <FeedPerAnimal />
      <CftFormErrorFocus />
    </Form>
  );
}

export default ForageDPForm;

export function ForageDPFormContext({ children }) {
  const history = useHistory();
  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);

  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.forage}
      validationSchema={Yup.object().shape({
        approach: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        feed: Yup.array().of(
          Yup.object()
            .shape({
              itemCategory: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              item: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              percentage: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .max(
                  100,
                  t(
                    "cftPigs:validationSchema.max",
                    "Wartość maksymalna to {{max}}.",
                    { max: 100 }
                  )
                )
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
            })
            .nullable()
        ),
        herd: Yup.array().of(
          Yup.object()
            .shape({
              animals: Yup.array().of(
                Yup.object()
                  .shape({
                    itemCategory: Yup.string().required(
                      t("cft:validation.requiredOption", "Wybierz opcję")
                    ),
                    item: Yup.string().required(
                      t("cft:validation.requiredOption", "Wybierz opcję")
                    ),
                    dryMmatterValue: Yup.number()
                      .transform((_, v) => Number(v.replace(/,/, ".")))
                      .required(t("cft:validation.required", "Wpisz wartość"))
                      .typeError(
                        t(
                          "cft:validation.numberTypeError",
                          "Wpisz poprawną wartośc liczbową"
                        )
                      )
                      .min(
                        0,
                        t(
                          "cft:validation.numberMinError",
                          "Wartość minimalna to 0"
                        )
                      ),
                  })
                  .nullable()
              ),
            })
            .nullable()
        ),
      })}
      onSubmit={values => {
        dairyProductionAPI
          .feedValidation(mapValues(values))
          .then(({ data }) => {
            saveDairyProductionReport(data);
            saveDairyProduction({ forage: values });
            history.push(routes.DAIRY_PRODUCTION.MANURE);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ButtonFix, Input } from "../index";
import "./UserTelephone.scss";

export const UserTelephone = ({ open, updateData }) => {
  const { t } = useTranslation(["globals", "user", "validation"]);
  return (
    <div
      className={`c-event-registration ${open && "c-event-registration--open"}`}
    >
      {t("globals:events.reqTelephone")}
      <Formik
        validationSchema={Yup.object().shape({
          telephone: Yup.string()
            .required(t("validation:required"))
            .matches(
              /^\d{2}\s*\d{3}(\s*)\d{2}(\s*)\d{2}$/,
              t("validation:telephone")
            ),
        })}
        initialValues={{
          telephone: "",
        }}
        onSubmit={values => {
          updateData(values);
        }}
      >
        {({ values, handleSubmit, errors, touched, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <div className="c-event-registration__row">
              <Input
                id="telephone"
                label={t("user:inputs.telephone")}
                type="telephone"
                name="telephone"
                value={values.telephone}
                onChange={handleChange}
                haserror={touched.telephone && errors.telephone}
                errormsg={touched.telephone && errors.telephone}
                inputstate={touched.telephone && errors.telephone && "error"}
              />
              <ButtonFix type="submit" buttoncolor="yellow">
                {t("globals:buttons.save")}
              </ButtonFix>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

import { useFormikContext } from "formik";
import React, { useEffect, useCallback } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Loader } from "../../components/common";
import {
  RegagriBox,
  RegagriFormControl,
  RegagriLabel,
  RegagriTextField,
  RegagriTooltip,
} from "../components";
import regagriApi, { carbonInputsLangMapper } from "../utils/regagriApi";

const RegagriSoilTypes = ({ typesData, selectedTypes, setSelectedTypes }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["regagri"]);
  const { values, setFieldValue } = useFormikContext();

  const getSoilRange = (soil, geo) => {
    regagriApi
      .carbonSoilRange({
        lat: geo[0],
        lon: geo[1],
        soil_types: soil.map(({ name, ...rest }) => ({ ...rest })),
      })
      .then(({ data }) => {
        setFieldValue(
          "organicMatter",
          Math.floor(data.soil_organic_matter_max * 100) / 100
        );
      });
  };

  const debounceCb = useCallback(
    _.debounce((soil, geo) => getSoilRange(soil, geo), 500),
    []
  );

  useEffect(() => {
    if (
      values.soil.length > 0 &&
      !values.soil.find(el => Number(el.area) === 0 || Number(el.area) > 100)
    ) {
      debounceCb(values.soil, values.geo);
    }
  }, [values.soil, values.geo]);

  const formPropperValuesMap = arr => {
    return arr.map((el, i) => ({
      id: el.id,
      area: values.soil[i] ? values.soil[i].area : "",
      name: el.name,
    }));
  };

  const handleAddSelectedType = () => {
    setSelectedTypes(() => {
      const filteredSoil = typesData.filter(
        el => el.id !== selectedTypes[0].id
      );
      const newSelectedTypes = [...selectedTypes];
      const randomId =
        filteredSoil[Math.floor(Math.random() * filteredSoil.length)].id;
      newSelectedTypes.push({
        id: randomId,
        isAllTypesVisible: false,
        name: typesData.find(f => f.id === randomId).name,
      });

      setFieldValue("soil", formPropperValuesMap(newSelectedTypes));
      return newSelectedTypes;
    });
  };

  return (
    <div className="regagri-soil-types">
      <RegagriFormControl variant="medium" withoutMarginBottom>
        <RegagriLabel
          className="headline"
          label={t("regagri:cultivationDetails.form.soilTypes.title")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <RegagriTooltip
              content={t("regagri:cultivationDetails.form.soilTypes.tooltip")}
            />
          }
        />
      </RegagriFormControl>
      {selectedTypes.length === 0 ? (
        <Loader />
      ) : (
        <>
          <RegagriFormControl variant="full" alignLeft>
            {selectedTypes.map((selectedType, index) => (
              <div id="soil">
                <div className="types">
                  <div className="box">
                    <RegagriBox isActive>
                      {
                        carbonInputsLangMapper(
                          language === "pl" ? t : undefined,
                          {
                            soil_types: typesData,
                            practices: [],
                          }
                        ).soil_types.find(soil => soil.id === selectedType.id)
                          .name
                      }
                    </RegagriBox>
                    <div className="buttons">
                      <div className="change">
                        <Button
                          buttonsize="small"
                          buttoncolor="underline"
                          onClick={() => {
                            const newTypes = [...selectedTypes];
                            newTypes[index] = {
                              ...selectedTypes[index],
                              isAllTypesVisible: !selectedTypes[index]
                                .isAllTypesVisible,
                            };
                            setFieldValue(
                              "soil",
                              formPropperValuesMap(newTypes)
                            );
                            setSelectedTypes(newTypes);
                          }}
                        >
                          {t(
                            "regagri:cultivationDetails.form.soilTypes.buttons.1"
                          )}
                        </Button>
                      </div>
                      {index === 1 && (
                        <div className="delete">
                          <Button
                            buttonsize="small"
                            buttoncolor="underline"
                            onClick={() => {
                              const newTypes = [...selectedTypes].splice(0, 1);
                              setFieldValue(
                                "soil",
                                formPropperValuesMap(newTypes)
                              );
                              setSelectedTypes(newTypes);
                            }}
                          >
                            {t(
                              "regagri:cultivationDetails.form.soilTypes.buttons.2"
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="area">
                    <RegagriLabel
                      label={t(
                        "regagri:cultivationDetails.form.soilTypes.area.label"
                      )}
                    />
                    <RegagriTextField
                      name={`soil[${index}].area`}
                      adornment="%"
                      placeholder={t(
                        "regagri:cultivationDetails.form.soilTypes.area.placeholder"
                      )}
                    />
                  </div>
                </div>
                {selectedTypes[index].isAllTypesVisible && (
                  <div className="all-types">
                    {typesData
                      .filter(item =>
                        selectedTypes.length === 1
                          ? item.id !== selectedType.id
                          : item.id !== selectedTypes[0].id &&
                            item.id !== selectedTypes[1].id
                      )
                      .map(soil => (
                        <RegagriBox
                          onClick={() => {
                            const newTypes = [...selectedTypes];
                            newTypes[index] = {
                              ...selectedTypes[index],
                              id: soil.id,
                              name: soil.name,
                              isAllTypesVisible: false,
                            };
                            setFieldValue(
                              "soil",
                              formPropperValuesMap(newTypes)
                            );
                            setSelectedTypes(newTypes);
                          }}
                        >
                          {language === "pl"
                            ? t(
                                `cultivationDetails.form.soilTypes.names.${
                                  soil.id
                                }`
                              )
                            : soil.name}
                        </RegagriBox>
                      ))}
                  </div>
                )}
                {selectedTypes.length === 1 && (
                  <div className="add-btn">
                    <Button
                      buttonsize="small"
                      buttoncolor="green"
                      onClick={handleAddSelectedType}
                    >
                      {t("regagri:cultivationDetails.form.soilTypes.buttons.0")}
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </RegagriFormControl>
          <RegagriFormControl variant="small" alignLeft>
            <div className="organic-matter">
              <RegagriLabel
                label={t(
                  "regagri:cultivationDetails.form.soilTypes.organicMatter.label"
                )}
                // eslint-disable-next-line react/no-children-prop
                children={
                  <RegagriTooltip
                    content={t(
                      "regagri:cultivationDetails.form.soilTypes.organicMatter.tooltip"
                    )}
                  />
                }
              />
              <RegagriTextField
                name="organicMatter"
                adornment="%"
                placeholder={t(
                  "regagri:cultivationDetails.form.soilTypes.organicMatter.placeholder"
                )}
              />
            </div>
          </RegagriFormControl>
        </>
      )}
    </div>
  );
};

export default RegagriSoilTypes;

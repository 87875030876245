import React from "react";
import PropTypes from "prop-types";
import "./CategoryLabel.scss";

// categorystate - props value define category state, basic or active

export const CategoryLabel = props => {
  return (
    <p
      className={`category--${props.categorystate} category-label--${props.id}`}
      {...props}
    >
      {props.children}
    </p>
  );
};

CategoryLabel.propTypes = {
  categorystate: PropTypes.string,
  children: PropTypes.node,
};

CategoryLabel.defaultProps = {
  categorystate: "basic",
  children: null,
};

import React from "react";
import {
  Hero,
  Benefits,
  InsuranceScope,
  DownloadDocuments,
  Disclaimer,
  ContactForm,
  ScrollToSection,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_33.png";
import fileA from "../../assets/prf/pdf/02 Generali OZE Karta produktu.pdf";
import fileB from "../../assets/prf/pdf/03 Generali OZE Ustandaryzowany dokument.pdf";
import fileC from "../../assets/prf/pdf/04 Generali OZE OWU.pdf";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Ubezpieczenie instalacji odnawialnych źródeł energii"
    ),
    title: "Ubezpieczenie instalacji odnawialnych źródeł energii",
    description:
      "Poszukujesz najlepszej ochrony ubezpieczeniowej instalacji fotowoltaicznych, kolektorów słonecznych lub pomp ciepła?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Dlaczego warto ubezpieczyć odnawialne źródła energii?",
    subtitle:
      "Ponieważ nie przewidzisz ich uszkodzeń, kradzieży czy zniszczenia",
    benefits: [
      {
        title:
          "Ochrona od wszelkich zdarzeń – poza wyłączonymi w warunkach ubezpieczenia",
        icon: "Umbrella",
      },
      {
        title: "Ubezpieczenie od kradzieży i wandalizmu w zakresie podstawowym",
        icon: "PaperShield",
      },
      {
        title: "Rekompensata za stratę w produkcji energii w razie przestoju",
        icon: "MoneyPlus",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź ubezpieczenie spełniające Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe aby porozmawiać z Ekspertem ds. Ubezpieczeń lub odwiedź nas w Banku BNP.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Formalności załatwimy drogą elektroniczną, abyś Ty mógł spokojnie i bezpiecznie prowadzić  gospodarstwo",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Karta produktu",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileA,
      },
      {
        name: "Ustandaryzowany dokument",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileB,
      },
      {
        name: "Ogólne Warunki Ubezpieczenia",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Treści zawarte na niniejszej stronie mają charakter wyłącznie reklamowy i informacyjny oraz nie stanowią oferty w rozumieniu Kodeksu Cywilnego. Świadczenie ochrony ubezpieczeniowej następuje na warunkach określonych w umowie zawartej z Klientem za pośrednictwem Banku BNP Paribas, działającego jako agent ubezpieczeniowy, przez Generali Towarzystwo Ubezpieczeń S.A. oraz w Ogólnych Warunkach Ubezpieczeń. Szczegółowe warunki ubezpieczenia, w tym ograniczenia i wyłączenia odpowiedzialności z nimi związane, jak również informacje na temat zasad działania poszczególnych produktów i kosztów z nimi związanych określone są w obowiązujących dla danego produktu Ogólnych Warunkach Ubezpieczenia, przekazywanych każdorazowo klientowi przed zawarciem umowy ubezpieczenia. Generali Towarzystwo Ubezpieczeń S.A. z siedzibą w Warszawie przy ul. Postępu 15B, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 10623, kapitał zakładowy 191.000.000 PLN wpłacony w całości, NIP 526-23-49-108, należąca do Grupy Generali, figurującej w Rejestrze Grup Ubezpieczeniowych prowadzonym przez IVASS.",
    ],
  },
  INSURANCE_SCOPE_1: {
    title: "Przedmiot ubezpieczenia",
    items: [
      "instalacje fotowoltaiczne (moc do 150 kWp) – na dachu lub na gruncie",
      "kolektory słoneczne (moc do 300 kWt) – na dachu lub na gruncie",
      "pompy ciepła (moc do 300 kWt) – napowietrzne i głębinowe",
    ],
    icon: "PhotovoltaicsSun",
  },
  INSURANCE_SCOPE_2: {
    title: "Zakres ubezpieczenia",
    items: [
      "ochrona od wszelkich zdarzeń losowych, utraty i aktów wandalizmu:",
      "uszkodzenie instalacji przez grad, wiatr lub przepięcie",
      "uszkodzenie instalacji w wyniku uderzenia kamieniem lub butelką",
      "kradzież części instalacji",
      "przegryzienie okablowania przez kuny",
      "strata w produkcji energii elektrycznej",
    ],
    icon: "TodoListHand",
    bg: "green",
    iconBg: "white",
    isReversed: true,
  },
  INSURANCE_SCOPE_3: {
    title: "Zalety ubezpieczenia",
    description:
      "Produkujesz energię, dbasz o środowisko i oszczędzasz na rachunkach za prąd. Spraw, aby tak pozostało – wybierz dobre ubezpieczenie odnawialnych źródeł energii. Skorzystaj z:",
    items: [
      "szerokiego zakresu ochrony w tym zabezpieczenia spłaty kredytu",
      "odpowiedzialności za szkody wyrządzone przez dzikie zwierzęta",
      "pokrycia kosztów drobnych prac murarskich",
      "odszkodowania za przerwę w działaniu instalacji w wyniku uszkodzeń",
      "pokrycia kosztów związanych z demontażem i utylizacją uszkodzonych elementów",
    ],
    icon: "PhotovoltaicsShield",
    bg: "white",
    iconBg: "orange",
  },
};

const UbezpieczeniaMieniaFotowoltaika = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_1} id="ofert" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_2} />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_3} />
    <ScrollToSection
      onClick={() => {
        window.location.href = `/kredytomat/kontakt?product_url=${
          window.location.pathname
        }&product_name=Ubezpieczenie instalacji odnawialnych źródeł energii`;
      }}
      text="Zapytaj o ofertę"
    />
    <TempShareButtonsComponent />
    <Benefits withArrow {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm id="contact" />
  </PageTemplate>
);

export default UbezpieczeniaMieniaFotowoltaika;

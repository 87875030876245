export const GET_PHOTOS = "GET_PHOTOS";
export const VOTE_ON_PHOTO = "VOTE_ON_PHOTO";
export const GET_MORE_PHOTOS = "GET_MORE_PHOTOS";

export const GET_PHOTOS_MOST_POPULAR = "GET_PHOTOS_MOST_POPULAR";
export const VOTE_ON_PHOTO_MOST_POPULAR = "VOTE_ON_PHOTO_MOST_POPULAR";

export const GET_PHOTOS_LATEST = "GET_PHOTOS_LATEST";
export const VOTE_ON_PHOTO_LATEST = "VOTE_ON_PHOTO_LATEST";

export const GET_PHOTOS_RELATED = "GET_PHOTOS_RELATED";
export const CLEAR_PHOTOS_RELATED = "CLEAR_PHOTOS_RELATED";

export const GET_PHOTOS_REJECTED = "GET_PHOTOS_REJECTED";
export const CLEAR_PHOTOS_REJECTED = "CLEAR_PHOTOS_REJECTED";

export const ADD_PHOTO = "ADD_PHOTO";

export const GET_PHOTOS_TAGS = "GET_PHOTOS_TAGS";

export const REMOVE_PHOTO = "REMOVE_PHOTO";

export const GET_PROFILE_META = "GET_PROFILE_META";

export const UPDATE_PHOTO = "UPDATE_PHOTO";

export const GET_PHOTO = "GET_PHOTO";

export const UNVOTE_ON_PHOTO = "UNVOTE_ON_PHOTO";

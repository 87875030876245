import React from "react";
import {
  Hero,
  Benefits,
  InsuranceScope,
  DownloadDocuments,
  Disclaimer,
  ContactForm,
  ScrollToSection,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_7.png";
// eslint-disable-next-line import/no-unresolved
import fileA from "../../assets/prf/pdf/02 Generali produkcja zwierzęca Karta produktu.pdf";
// eslint-disable-next-line import/no-unresolved
import fileB from "../../assets/prf/pdf/03 Generali produkcja zwierzęca Ustandaryzowany dokument.pdf";
// eslint-disable-next-line import/no-unresolved
import fileC from "../../assets/prf/pdf/04 Generali produkcja zwierzęca Ogólne Warunki Ubezpieczenia.pdf";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Ubezpieczenie produkcji zwierzęcej w sektorze drobiarskim"
    ),
    title: "Ubezpieczenie produkcji zwierzęcej w sektorze drobiarskim",
    description:
      "Poszukujesz najlepszej ochrony ubezpieczeniowej od utraconego lub nieosiągniętego zysku z produkcji drobiu?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Dlaczego warto ubezpieczyć produkcje zwierzęcą?",
    subtitle:
      "W okresach letnich nie trudno o przegrzanie ptaków czy awarię wentylacji. W przypadku wystąpienia ptasiej grypy odszkodowanie z budżetu państwa może nie pokryć wszystkich poniesionych strat, zwłaszcza w czasie blokady.",
    benefits: [
      {
        title:
          "Patrzymy na produkcję zwierzęcą szeroko, biorąc pod uwagę m.in. koszty paszy, piskląt, transportu, dezynfekcji",
        icon: "TickRounded",
      },
      {
        title:
          "Odpowiadamy tak długo, jak trwają negatywne skutki na produkcję, max do 12 miesięcy od zajścia zdarzenia",
        icon: "Calendar",
      },
      {
        title:
          "Przy ptasiej grypie odpowiadamy również wtedy, gdy Twoja ferma jest na obszarze zapowietrzonym oraz zagrożonym",
        icon: "TickRounded",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź ubezpieczenie spełniające Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe aby porozmawiać z Ekspertem ds. Ubezpieczeń lub odwiedź nas w Banku BNP.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Formalności załatwimy drogą elektroniczną, abyś Ty mógł spokojnie i bezpiecznie prowadzić  gospodarstwo",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Karta produktu",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileA,
      },
      {
        name: "Ustandaryzowany dokument",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileB,
      },
      {
        name: "Ogólne Warunki Ubezpieczenia",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Treści zawarte na niniejszej stronie mają charakter wyłącznie reklamowy i informacyjny oraz nie stanowią oferty w rozumieniu Kodeksu Cywilnego. Świadczenie ochrony ubezpieczeniowej następuje na warunkach określonych w umowie zawartej z Klientem za pośrednictwem Banku BNP Paribas, działającego jako agent ubezpieczeniowy, przez Generali Towarzystwo Ubezpieczeń S.A. oraz w Ogólnych Warunkach Ubezpieczeń. Szczegółowe warunki ubezpieczenia, w tym ograniczenia i wyłączenia odpowiedzialności z nimi związane, jak również informacje na temat zasad działania poszczególnych produktów i kosztów z nimi związanych określone są w obowiązujących dla danego produktu Ogólnych Warunkach Ubezpieczenia, przekazywanych każdorazowo klientowi przed zawarciem umowy ubezpieczenia. Generali Towarzystwo Ubezpieczeń S.A. z siedzibą w Warszawie przy ul. Postępu 15B, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 10623, kapitał zakładowy 191.000.000 PLN wpłacony w całości, NIP 526-23-49-108, należąca do Grupy Generali, figurującej w Rejestrze Grup Ubezpieczeniowych prowadzonym przez IVASS.",
    ],
  },
  INSURANCE_SCOPE_1: {
    title: "Przedmiot ubezpieczenia",
    description:
      "Przedmiotem ubezpieczenia jest nieosiągnięty lub utracony zysk z prowadzonej produkcji drobiu. Obejmujemy ochroną na etapie odchowu, nieśności oraz tuczu.",
    items: [
      "kury nioski",
      "brojlery kurze, indycze, kacze, gęsie ",
      "stada rodzicielskie kurze, indycze, kacze, gęsie",
    ],
    icon: "Chickens",
  },
  INSURANCE_SCOPE_2: {
    title: "Zakres ubezpieczenia",
    description: "Szeroki zakres ochrony, w tym:",
    items: [
      "ptasia grypa, Salmonella i Histomonoza",
      "choroby rejestrowane np. choroba Mareka, choroba Gumboro",
      "szkody pośrednie, np. restrykcje w przemieszczaniu, różnica cen ",
      "awaria urządzeń wentylacyjnych, przepięcia, przerwy w dopływie prądu ",
      "przegrzanie, zatrucie stada",
    ],
    icon: "TodoListHand",
    bg: "green",
    iconBg: "white",
    isReversed: true,
  },
  INSURANCE_SCOPE_3: {
    title: "Zalety ubezpieczenia",
    description: "Straty wynikające z:",
    items: [
      "utylizacji zwierząt",
      "przedłużenia cyklu ",
      "różnicy cen sprzedaży",
      "restrykcji obowiązujących w okręgu zapowietrzonym i zagrożonym ",
      "dezynfekcji, transportu oraz inne koszty pośrednie powstałe wskutek zdarzenia",
    ],
    icon: "ChickensShield",
    bg: "white",
    iconBg: "orange",
  },
};

const UbezpieczeniaMieniaZwierzetaInne = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_1} id="ofert" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_2} />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_3} />
    <ScrollToSection
      onClick={() => {
        window.location.href = `/kredytomat/kontakt?product_url=${
          window.location.pathname
        }&product_name=Ubezpieczenie produkcji zwierzęcej w sektorze drobiarskim`;
      }}
      text="Zapytaj o ofertę"
    />
    <TempShareButtonsComponent />
    <Benefits withArrow {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm id="contact" />
  </PageTemplate>
);

export default UbezpieczeniaMieniaZwierzetaInne;

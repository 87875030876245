import React from "react";
import { MARKET_AGREGATOR_CATEGORY } from "../../../services/helpers/articles";
import useCategories from "../../categories/useCategories";
import ArticlesPage from "./ArticlesPage";

const MarketArticlesPage = ({ parentCategory, category }) => {
  const categories = useCategories();

  const subcategories = MARKET_AGREGATOR_CATEGORY.categoriesList
    .split(",")
    .map(id => {
      const category = categories.categories.find(c => c.id === Number(id));
      return category;
    });

  const marketCategoryData = {
    id: MARKET_AGREGATOR_CATEGORY.id,
    subcategories,
    subcategories_id: subcategories.map(c => c.id),
  };
  const props = parentCategory
    ? {
        category,
        parentCategory: marketCategoryData,
      }
    : {
        category: marketCategoryData,
      };
  return <ArticlesPage {...props} />;
};

export default MarketArticlesPage;

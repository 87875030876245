import { reduce } from "ramda";

export function getAddresses(addresses = []) {
  const reducedAddresses = reduce(
    (acc, elem) => {
      if (acc.indexOf(elem) !== -1) {
        return acc;
      }
      return [...acc, elem];
    },
    [],
    addresses.filter(address => !!address)
  );
  return reducedAddresses.join(", ");
}

export function getSearchLabel(addresses = []) {
  return addresses.filter(address => !!address).join(", ");
}

import React, { useState } from "react";
// import { isEmpty } from "ramda";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import * as html2pdf from "html2pdf.js";
import * as JSZip from "jszip";
import { saveAs } from "file-saver";
import { isMobile } from "react-device-detect";
import { CftActionsButtons, CftSavingModal } from "..";
import { ReactComponent as CsvIcon } from "../../../assets/icons/cft/csv.svg";
import { ReactComponent as PdfIcon } from "../../../assets/icons/cft/pdf.svg";
import { ReactComponent as PdfCsvIcon } from "../../../assets/icons/cft/pdf+csv.svg";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";
import useCftPigsValues from "../../utils/useCftPigsValues";
import "./CftExportData.scss";

const pdfOpt = {
  margin: 0.5,
  filename: "raport.pdf",
  useCORS: true,
  image: { type: "jpeg", quality: 1 },
  html2canvas: {
    scale: isMobile ? 1 : 2,
    scrollX: 0,
    scrollY: 0,
    width: 1210,
    logging: true,
  },
  jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
};

function CftExportData({ isDashboard, type, isSmall, noMargin, isReport }) {
  const { store: turkeyStore } = useCftTurkeyValues();
  const { store: chickenStore } = useCftChickenBroilerValues();
  const { store: pigsStore } = useCftPigsValues();
  const [isOpen, setOpen] = useState(false);
  const [isModal, setModal] = useState(false);

  const isReportAvailable = isReport;

  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  const handleOverflow = () => {
    if (!isOpen) {
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else if (isOpen) {
      document.querySelector("body").style = undefined;
      document.querySelector("html").style.overflow = "unset";
    }
  };

  const getStoreData = () => {
    switch (type) {
      case "turkey":
        return turkeyStore;
      case "pigs":
        return pigsStore;

      default:
        return chickenStore;
    }
  };

  const downloadJSON = () => {
    const file = new Blob([JSON.stringify(getStoreData())], {
      type: "text/plain",
    });
    saveAs(file, `raport_${Date.now()}.json`);
  };

  const getHtmlTemplate = () => {
    const styles = document.querySelectorAll("style");
    const report = document.querySelector("#report");
    // const header = document.querySelector(".cft-header");

    const htmlTemplate = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      ${Array.from(styles)
        .map(({ outerHTML }) => outerHTML)
        .join("")}
    </head>
    <body>
    <div id="pdf">
    
    ${report.outerHTML}
    </div>
    </body>
    </html>
    `;

    return htmlTemplate;
  };

  const downloadPDF = () => {
    const htmlTemplate = getHtmlTemplate();
    html2pdf()
      .set(pdfOpt)
      .from(htmlTemplate)
      .outputPdf("blob")
      .then(result => {
        const pdf = new File([result], `raport_${Date.now()}.pdf`);
        saveAs(pdf, `raport_${Date.now()}.pdf`);
      });
  };

  const downoladZip = async () => {
    const zip = new JSZip();
    const json = JSON.stringify(getStoreData());
    const htmlTemplate = getHtmlTemplate();

    html2pdf()
      .set(pdfOpt)
      .from(htmlTemplate)
      .outputPdf("blob")
      .then(result => {
        const pdf = new File([result], "report.pdf");
        zip.file(`raport_${Date.now()}.json`, json);
        zip.file(`raport_${Date.now()}.pdf`, pdf);
        zip.generateAsync({ type: "blob" }).then(content => {
          saveAs(content, `raport_${Date.now()}.zip`);
        });
      });
  };

  const { t } = useTranslation(["cft"]);

  return (
    <div className="CftExportData">
      <CftActionsButtons.ExportReport
        isSmall={isSmall}
        onClick={() => {
          if (!isModal) {
            handleOverflow();
            setOpen(!isOpen);
          }
        }}
        noMargin={noMargin}
      >
        <div
          className={classnames("cft-export", {
            "cft-export-isVisible": isOpen,
            "cft-export-isDashboard": isDashboard,
          })}
        >
          <div className="cft-export-overlay" />

          <div
            className={classnames("cft-export-container", {
              "cft-export-container-isVisible": isOpen,
              "cft-export-container-isDashboard": isDashboard,
            })}
          >
            <div className="cft-export-header">
              <h4>{t("cft:export:title", "Jaki plik chcesz pobrać?")}</h4>
            </div>
            <div
              className={classnames("cft-export-buttons", {
                "cft-export-buttons-isVisible": isOpen,
              })}
            >
              <div
                className="cft-export-button"
                onClick={() => {
                  downloadJSON();
                  setModal(!isModal);
                  setTimeout(() => stopOverflow(), 100);
                }}
              >
                <div className="cft-export-button-header">
                  <CsvIcon />
                  <h6> {t("cft:export:json:icon", "Edytowalny")}</h6>
                </div>
                <div className="cft-export-button-body">
                  <div className="cft-export-button-format">
                    {t("cft:export:json:title", "PLIK W FORMACIE .JSON")}
                  </div>
                  <p>
                    {t(
                      "cft:export:json:subtitle",
                      "Pozwala na ponowną zmiane danych w kalkulacji, ale nie jest możliwe odczytanie pliku."
                    )}
                  </p>
                </div>
              </div>
              <div className="cft-export-line" />

              <div
                className={classnames("cft-export-button", {
                  "cft-export-button--disabled": !isReportAvailable,
                })}
                onClick={() => {
                  if (isReportAvailable) {
                    downloadPDF();
                    setModal(!isModal);
                    setTimeout(() => stopOverflow(), 100);
                  }
                }}
              >
                <div className="cft-export-button-header">
                  <PdfIcon />

                  <h6> {t("cft:export:pdf:icon", "Tylko do odczytu")}</h6>
                </div>
                <div className="cft-export-button-body">
                  <div className="cft-export-button-format">
                    {t("cft:export:pdf:title", "PLIK W FORMACIE .PDF")}
                  </div>
                  <p>
                    {t(
                      "cft:export:pdf:subtitle",
                      "Pozwala na swobodne zapoznanie się z raportem oraz jego odczyt. Brak możliwości edycji pliku."
                    )}
                  </p>
                </div>
              </div>
              <div className="cft-export-line" />
              <div
                className={classnames("cft-export-button", {
                  "cft-export-button--disabled": !isReportAvailable,
                })}
                onClick={() => {
                  if (isReportAvailable) {
                    downoladZip();
                    setModal(!isModal);
                    setTimeout(() => stopOverflow(), 100);
                  }
                }}
              >
                <div className="cft-export-button-header">
                  <PdfCsvIcon />
                  <h6>{t("cft:export:zip:icon", "Oba pliki")}</h6>
                </div>
                <div className="cft-export-button-body">
                  <div className="cft-export-button-format">
                    {t("cft:export:zip:title", "PACZKA W FORMACIE .ZIP")}
                  </div>
                  <p>
                    {t(
                      "cft:export:zip:subtitle",
                      "Folder będzie zawierał oba formaty .pdf oraz .json"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CftSavingModal isVisible={isModal} hide={() => setModal(false)} />
      </CftActionsButtons.ExportReport>
    </div>
  );
}

CftExportData.propTypes = {
  isDashboard: PropTypes.bool,
  isReport: PropTypes.bool,
};

CftExportData.defaultProps = {
  isDashboard: false,
  isReport: false,
};

export default CftExportData;

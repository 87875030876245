import React, { useMemo } from "react";
import { useParams, useHistory, generatePath } from "react-router-dom";
import { Grid, Typography, TileChoose } from "agronomist-ui-components";

import { ReactComponent as GoBackIcon } from "../../assets/icons/cft/woc.svg";
import PageTemplate from "../helpers/PageTemplate";

import { SEEDS_MAP, TITLE_MAP } from "../consts/seeds";
import { ANSWER_TYPE, WIZARD_URL } from "../consts/consts";

const getQuestion = (seed, grain) => {
  const questions = SEEDS_MAP[seed];

  if (grain) {
    return questions[grain];
  }

  return questions[0];
};

const getTitle = seed => {
  return TITLE_MAP[seed];
};

const Wizard = () => {
  const { seed, grain } = useParams();
  const history = useHistory();

  const question = useMemo(() => getQuestion(seed, grain), [seed, grain]);
  const title = useMemo(() => getTitle(seed), [seed]);

  const onAnswerClick = (type, grain) => {
    if (type === ANSWER_TYPE.end) {
      return history.push(grain);
    }

    return history.push(
      generatePath(WIZARD_URL, {
        seed,
        grain,
      })
    );
  };

  const onGoBack = () => {
    if (grain) {
      return history.goBack();
    }

    return history.push("/kredytomat");
  };

  return (
    <PageTemplate>
      <Grid.Container fluid background="#ECECEC" py="3.125rem">
        <Grid.Container position="relative">
          <span className="prf-go-back-btn" onClick={onGoBack}>
            <GoBackIcon />
            cofnij
          </span>
          <Grid.Row>
            <Grid.Col xs={12}>
              <Typography
                variant="h2"
                isUpperCase
                textAlign="center"
                color="brandGreen"
                mb={4}
              >
                {question.title || title}
              </Typography>
              <Typography textAlign="center">{question.question}</Typography>
              <Grid.Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                py={5}
              >
                {question.answers.map((answer, idx) => (
                  <TileChoose
                    mx={2}
                    mb={3}
                    key={`${seed}_${idx}`}
                    onClick={() => onAnswerClick(answer.type, answer.grain)}
                    text={answer.text}
                    icon={answer.icon}
                    isAutoHeight
                  />
                ))}
              </Grid.Box>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Grid.Container>
    </PageTemplate>
  );
};

export default Wizard;

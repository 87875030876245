import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import favicon from "../../../assets/logo/favicon.png";
import ogPhoto from "../../../assets/logo/logo.jpg";
import { articleSchema } from "./helpers";

export const Metadata = ({
  name,
  description,
  photo,
  type,
  noTitleSufix,
  articleData,
}) => {
  let titleName = name || "Agronomist";
  const photoUrl = photo || ogPhoto;
  const { t, i18n } = useTranslation(["contactPage"]);
  const productionUrl = "https://agronomist.pl";

  if (!noTitleSufix) titleName += " – Agronomist";

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": productionUrl.replace(/^http:\/\//i, "https://"),
    url: productionUrl.replace(/^http:\/\//i, "https://"),
    logo: productionUrl + favicon,
    address: {
      "@type": "PostalAddress",
      streetAddress: t("contactPage:boxes.2.content.addressStreet"),
      addressLocality: t("contactPage:boxes.2.content.addressCity"),
      postalCode: t("contactPage:boxes.2.content.addressPostcode"),
      addressCountry: "PL",
    },
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: t("contactPage:boxes.2.content.phone"),
        contactType: "customer service",
      },
    ],
  };

  const articleStructuredData =
    type === "article" ? articleSchema(articleData) : null;

  return (
    <Helmet
      encodeSpecialCharacters
      htmlAttributes={{ lang: i18n.language === "gb" ? "en" : i18n.language }}
    >
      <title>{titleName}</title>
      <meta property="og:title" content={titleName} />
      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      <link
        rel="canonical"
        href={encodeURI(window.location.href).replace(
          /^http:\/\//i,
          "https://"
        )}
      />
      <meta
        property="og:url"
        content={encodeURI(window.location.href).replace(
          /^http:\/\//i,
          "https://"
        )}
      />
      {type ? <meta property="og:type" content={type} /> : null}
      {photoUrl ? <meta property="og:image" content={photoUrl} /> : null}

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {articleStructuredData ? (
        <script type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      ) : null}
    </Helmet>
  );
};

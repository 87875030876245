import React from "react";
import "./Alert.scss";

export const Alert = props => {
  return <div className="c-alert">{props.children}</div>;
};

Alert.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  children: null,
};

export const detailsFarmlandsForm = {
  name: "",
  cultivate: "",
  crop: "",
  areal: {
    unit: "",
    value: "",
  },
  seedAmount: {
    unit: undefined,
    value: undefined,
  },
  exit: "",
  year: "",
  residueManagement: "",
  remainingQuantity: "",
  extraProducts: null,
  products: [],
};

export const soilFarmlands = {
  soilTexture: "",
  soilMatter: "",
  averageSoilMoisture: "",
  soilDrainage: "",
  ph: "",
};

export const fertilizersFarmlands = {
  fertilizers: [],
  plantProtectionProducts: [],
};

export const fuelAndEnergyFarmlands = {
  sources: [],
  consumptions: [],
  processing: [],
  potatoStorage: {},
  isPotatoStorage: undefined,
};

export const irrigationFarmlands = {
  events: [],
};

export const carbonChangesFarmlands = {
  converted: null,
  managementPractice: null,
};

export const transportFarmlands = {
  used: null,
  transports: [],
};

export const farmlandsReport = {
  crop: {},
  soil: {},
  fertilisers: [],
  pesticides: [],
  direct_energy: [],
  machinery: [],
  processing: [],
  storage: [],
  irigation: [],
  land_management: {},
  transport: [],
};

export const farmlandsInit = {
  details: detailsFarmlandsForm,
  soil: soilFarmlands,
  fertilizers: fertilizersFarmlands,
  fuelAndEnergy: fuelAndEnergyFarmlands,
  irrigation: irrigationFarmlands,
  carbonChanges: carbonChangesFarmlands,
  transport: transportFarmlands,
};

import React, { useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftFormControl,
  CftFormErrorFocus,
  CftQuestionGroup,
  CftButton,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import { scrollToLastElement } from "../../../../utils/cftUtils";
import EnergySourceTypeFields from "./EnergySourceTypeFields";
import EnergyConsumptionFields from "./EnergyConsumptionFields";
import ProcessingTypeFields from "./ProcessingTypeFields";
import PotatoStorageFields from "./PotatoStorageFields";
import { mapValues } from "./fuelAndEnergyBothCalc.utils";
import { farmlandsAPI } from "../../../../utils/cftApi";

function FuelAndEnergyBothCalcForm() {
  const { values, setFieldValue, errors } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);
  const consumptionsRefs = useRef([]);
  const processingRefs = useRef([]);

  const initSource = () => ({
    id: Date.now(),
    agregator: "",
    consumption: {
      value: "",
      unit: "",
    },
    category: "",
    label: "",
    type: "",
  });

  const initConsumption = () => ({
    id: Date.now(),
    type: "",
    operations: "",
    machineryFuel: "",
    machineryType: "",
  });

  const initProcessing = () => ({
    id: Date.now(),
    type: "",
    energySource: "",
    allocation: "",
    energyType: "",
  });

  return (
    <Form className="cft-form narrow-labels">
      {values.sources.map((source, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (groupRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fuelAndEnergy.form.25", "Źródło energii")} ${key +
            1}`}
          withoutMarginBottom={key + 1 === values.sources.length}
          onRemove={() => {
            setFieldValue(
              "sources",
              values.sources.filter(f => f.id !== source.id)
            );
            scrollToLastElement(groupRefs, 130);
          }}
          hasError={errors.sources ? !!errors.sources[key] : false}
        >
          <EnergySourceTypeFields id={key} name="sources" />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("sources", [...values.sources, initSource()]);
            scrollToLastElement(groupRefs, 130);
          }}
        >
          {values.sources.length
            ? t("cft:fuelAndEnergy.form.26", "Dodaj kolejny źródło energi")
            : t("cft:fuelAndEnergy.form.27", "Dodaj źródło energi")}
        </CftButton>
      </CftFormControl>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t(
            "cft:fuelAndEnergy.form.28",
            "Zużycie energii podczas prac na polu"
          )}
          <small>
            {t(
              "cft:fuelAndEnergy.form.29",
              "Wpisz wszystkie dodatkowe operacje na polu, które nie zostały uwzględnione w poprzednich punktach."
            )}
          </small>
        </h3>
      </CftFormControl>
      {values.consumptions.map((consumption, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (consumptionsRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fuelAndEnergy.form.53", "Zużycie energii")}  ${key +
            1}`}
          withoutMarginBottom={key + 1 === values.consumptions.length}
          onRemove={() => {
            setFieldValue(
              "consumptions",
              values.sources.filter(f => f.id !== consumption.id)
            );
            scrollToLastElement(consumptionsRefs, 130);
          }}
          hasError={errors.consumptions ? !!errors.consumptions[key] : false}
        >
          <EnergyConsumptionFields id={key} name="consumptions" />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("consumptions", [
              ...values.consumptions,
              initConsumption(),
            ]);
            scrollToLastElement(consumptionsRefs, 130);
          }}
        >
          {values.consumptions.length
            ? t(
                "cft:fuelAndEnergy.form.30",
                "Dodaj inne zużycie energii w terenie"
              )
            : t("cft:fuelAndEnergy.form.31", "Dodaj zużycie energii w terenie")}
        </CftButton>
      </CftFormControl>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:fuelAndEnergy.form.32", "Przetwarzanie")}
          <small>
            {t(
              "cft:fuelAndEnergy.form.33",
              "Wypełnij tę sekcję, aby oszacować emisję związaną z czynnościami przetwarzania, takimi jak mycie i sortowanie."
            )}
          </small>
        </h3>
      </CftFormControl>
      {values.processing.map((p, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (processingRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fuelAndEnergy.form.32", "Przetwarzanie")} ${key +
            1}`}
          withoutMarginBottom={key + 1 === values.processing.length}
          onRemove={() => {
            setFieldValue(
              "processing",
              values.processing.filter(f => f.id !== p.id)
            );
            scrollToLastElement(processingRefs, 130);
          }}
          hasError={errors.processing ? !!errors.processing[key] : false}
        >
          <ProcessingTypeFields id={key} name="processing" />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("processing", [
              ...values.processing,
              initProcessing(),
            ]);
            scrollToLastElement(processingRefs, 130);
          }}
        >
          {values.consumptions.length
            ? t("cft:fuelAndEnergy.form.30", "Dodaj przetwarzanie")
            : t("cft:fuelAndEnergy.form.30", "Dodaj kolejne")}
        </CftButton>
      </CftFormControl>
      <PotatoStorageFields />
      <CftFormErrorFocus />
    </Form>
  );
}

export default FuelAndEnergyBothCalcForm;

export function FuelAndEnergyBothCalcFormContext({ children }) {
  const history = useHistory();

  const { t } = useTranslation();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.fuelAndEnergy}
      validationSchema={Yup.object().shape({
        sources: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            agregator: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            consumption: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              unit: Yup.string().required(
                t("cft:validation.unitTypeError", "Wybierz jednostkę")
              ),
            }),
            category: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            label: Yup.string()
              .trim()
              .max(20, "Maksymalna ilość znaków to 20")
              .required(t("cft:validation.required", "Wpisz wartość")),
          })
        ),
        consumptions: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            machineryType: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            machineryFuel: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            operations: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .integer("Tylko wartości całkowite")
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            liters: Yup.number()
              .required(t("cft:validation.required", "Wpisz wartość"))
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              )
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              ),
          })
        ),
        processing: Yup.array().of(
          Yup.object().shape({
            energyType: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            type: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            energySource: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            allocation: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
          })
        ),
        potatoStorage: Yup.mixed().when("isPotatoStorage", {
          is: v => v === true,
          then: Yup.object().shape({
            storedAllocation: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            energyType: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            storagePowerSource: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            energyTypeUnloading: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            unloadingPowerSource: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            storeLoadingPowerSource: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            time: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              unit: Yup.string().required(
                t("cft:validation.unitTypeError", "Wybierz jednostkę")
              ),
            }),
            temperature: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            cipcDose: Yup.number()
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            cipcApplication: Yup.number()
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
          }),
          otherwise: Yup.object(),
        }),
      })}
      onSubmit={values => {
        saveBothCalc({ fuelAndEnergy: values });
        Promise.all([
          farmlandsAPI.directEnergyValidation({
            direct_energy: mapValues(values).directEnergy,
          }),
          farmlandsAPI.machineryValidation({
            machinery: mapValues(values).machinery,
          }),
          farmlandsAPI.processingValidation({
            processing: mapValues(values).processing,
          }),
          farmlandsAPI.storageValidation({
            storage: mapValues(values).storage,
          }),
        ]).then(([direct_energy, machinery, processing, storage]) => {
          saveBothCalcReport({
            direct_energy: direct_energy.data.direct_energy,
            machinery: machinery.data.machinery,
            processing: processing.data.processing,
            storage: storage.data.storage,
          });
          history.push(routes.FARMLANDS.BOTH_CALC.IRRIGATION);
        });
      }}
    >
      {children}
    </Formik>
  );
}

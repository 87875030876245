import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftLabel,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftTextField,
  CftSelectField,
  CftTooltip,
} from "../../../../components";

// Energy types Icons
import { ReactComponent as AgregatorIcon } from "../../../../../assets/icons/cft/agregator.svg";
import { ReactComponent as EnergyIcon } from "../../../../../assets/icons/cft/energia.svg";
import { ReactComponent as CHPIcon } from "../../../../../assets/icons/cft/chp.svg";

import { energySourcesTypes } from "./fuelAndEnergyBothCalc.consts";

function EnergySourceTypeFields({ id, name }) {
  const { setFieldValue, values } = useFormikContext();
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);

  const sourceType = values[name][id].type;

  const getSourceTypeUnits = () => {
    if (!sourceType || !values[name][id].agregator) {
      return [];
    }
    const source = energySourcesTypes[sourceType].find(
      ({ value }) => value === values[name][id].agregator
    );
    return source
      ? source.units.map(unit => ({ value: unit.value, label: unit.pl }))
      : [];
  };

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:fuelAndEnergy.form.25", "Źródło energii")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fuelAndEnergy:tooltips.3",
                "Wskaż wszystkie źródła energii związane bezpośrednio z szacowaną uprawą"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "agregator"}
                  onClick={() => {
                    helpers.setValue("agregator");
                    setFieldValue(`${name}[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <AgregatorIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.15", "Agregator")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "electricity"}
                  onClick={() => {
                    helpers.setValue("electricity");
                    setFieldValue(`${name}[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <EnergyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.16", "Prąd")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "chp"}
                  onClick={() => {
                    helpers.setValue("chp");
                    setFieldValue(`${name}[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CHPIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.17", "CHP")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={!!sourceType}>
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:fuelAndEnergy.form.18", "Agregator prądu")}
            name={`${name}[${id}].agregator`}
            // eslint-disable-next-line react/no-children-prop
            children={
              <CftTooltip
                content={t(
                  "cft:fuelAndEnergy:tooltips.4",
                  "Określ źródło prądu dla tej uprawy"
                )}
              />
            }
          />
          <CftSelectField
            name={`${name}[${id}].agregator`}
            options={
              energySourcesTypes[sourceType]
                ? energySourcesTypes[sourceType].map(option => ({
                    label: option[language],
                    value: option.value,
                  }))
                : []
            }
            onChange={() => {
              setFieldValue(`${name}[${id}].consumption`, {
                unit: "",
                value: "",
              });
            }}
          />
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering
        isConditionFulfilled={!!values[name][id].agregator}
      >
        <CftFormControl variant="small">
          <CftLabel
            label={t(
              "cft:fuelAndEnergy.form.20",
              "Pobór energii dla całego pola"
            )}
          />
          <CftUnitField
            name={`${name}[${id}].consumption`}
            units={getSourceTypeUnits()}
          />
        </CftFormControl>
      </CftConditionalRendering>

      <CftFormControl>
        <CftLabel
          label={t("cft:energyAndProcessing:form.6", "Kategoria")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fuelAndEnergy:tooltips.5",
                "Określ czy źródło energii dotyczy pola czy też innego obiektu związanego z tą uprawą np. magazyn"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].category`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.22", "Pole")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.23", "Obiekt")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:fuelAndEnergy.form.24", "Dodaj etykiete")} />
        <CftTextField name={`${name}[${id}].label`} />
      </CftFormControl>
    </>
  );
}

export default EnergySourceTypeFields;

/* eslint-disable func-names */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftErrorBoxForm,
  CftClearForm,
  CftFormErrorFocus,
} from "../../components";
import routes from "../../utils/cftRoutes";
import API from "../../../services/apiHelpers";
import ProductionCyclesFields from "./ProductionCyclesFields";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import BackToCoops from "./BackToCoops";

export default function ChickenBroilerCoopForm() {
  const token = Cookies.get("user_token");
  const { updateStore, store } = useCftChickenBroilerValues();
  const { t } = useTranslation(["cftChickenBroiler"]);
  const history = useHistory();
  const { id } = useParams();

  if (!id) {
    return <Redirect to={routes.CHICKEN_BROILER.COOPS} />;
  }

  const coop = store.henhouses_reference.coops[Number(id)];

  if (!coop) {
    return <Redirect to={routes.CHICKEN_BROILER.COOPS} />;
  }

  const calculationPeriodLength = Number(store.main.calculation_period_length);

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Formik
          enableReinitialize
          initialValues={coop}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              t(
                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            production_cycles: Yup.array().of(
              Yup.object().shape({
                production_cycle_length: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    49,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax",
                      "Wartość maksymalna to 49."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                technological_break_length: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    70,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax70",
                      "Wartość maksymalna to 70."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                repetitions: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cftChickenBroiler:errors.calculationPeriodLength2", {
                      calculationPeriodLength,
                    }),
                    function() {
                      const [, form] = this.from;
                      const wholeCalculationPeriodLength = form.value.production_cycles
                        .map(productionCycle => {
                          if (!productionCycle.repetitions) {
                            return 0;
                          }

                          if (
                            !productionCycle.production_cycle_length ||
                            !productionCycle.technological_break_length
                          ) {
                            return 0;
                          }

                          return (
                            (Number(
                              productionCycle.technological_break_length
                            ) +
                              Number(productionCycle.production_cycle_length)) *
                            Number(productionCycle.repetitions)
                          );
                        })
                        .reduce((prev, acc) => prev + acc, 0);

                      return (
                        wholeCalculationPeriodLength <= calculationPeriodLength
                      );
                    }
                  ),
                chicken_number: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                falls_percent: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin001",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    10,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax10",
                      "Wartość maksymalna to 10."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                pickups: Yup.array().of(
                  Yup.object().shape({
                    day: Yup.number()
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                          "Wartość minimalna to 1."
                        )
                      )
                      .test(
                        "is-right-pickupDay",
                        t(
                          "cftChickenBroiler:errors.is-right-pickupDay",
                          "Przekroczenie okresu produkcyjnego"
                        ),
                        function() {
                          const [, production] = this.from;
                          const applicationDays = production.value.pickups.reduce(
                            (prev, acc) => {
                              if (!acc.day) {
                                return prev;
                              }

                              return prev + Number(acc.day);
                            },
                            0
                          );
                          const productionCycleleft =
                            Number(production.value.production_cycle_length) -
                            1;

                          return applicationDays <= productionCycleleft;
                        }
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    quantity: Yup.number()
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                          "Wartość minimalna to 1."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      )
                      .test(
                        "is-right-pickupQuantity",
                        t(
                          "cftChickenBroiler:errors.pickupQuantity",
                          "Przekroczenie ilość kurcząt"
                        ),
                        function() {
                          const [, production] = this.from;
                          const applicationDays = production.value.pickups.reduce(
                            (prev, acc) => {
                              if (!acc.quantity) {
                                return prev;
                              }

                              return prev + Number(acc.quantity);
                            },
                            0
                          );
                          const productionCycleleft =
                            Number(production.value.chicken_number) - 1;

                          return applicationDays <= productionCycleleft;
                        }
                      ),
                  })
                ),
                feed_components: Yup.array()
                  .of(
                    Yup.object().shape({
                      feed_type: Yup.string().required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                      calorific_value: Yup.number()
                        .min(
                          1,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                            "Wartość minimalna to 1."
                          )
                        )
                        .max(
                          2147483647,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                            "Wartość maksymalna to 2147483647."
                          )
                        )
                        .required(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                            "Pole wymagane"
                          )
                        )
                        .typeError(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                            "Podaj wartość liczbową"
                          )
                        )
                        .integer(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                            "Podaj wartość całkowitą"
                          )
                        ),
                      quantity: Yup.number()
                        .min(
                          1,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                            "Wartość minimalna to 1."
                          )
                        )
                        .max(
                          2147483647,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                            "Wartość maksymalna to 2147483647."
                          )
                        )
                        .required(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                            "Pole wymagane"
                          )
                        )
                        .typeError(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                            "Podaj wartość liczbową"
                          )
                        )
                        .integer(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                            "Podaj wartość całkowitą"
                          )
                        ),
                      application_days: Yup.number()
                        .min(
                          1,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                            "Wartość minimalna to 1."
                          )
                        )
                        .max(
                          2147483647,
                          t(
                            "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                            "Wartość maksymalna to 2147483647."
                          )
                        )
                        .required(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                            "Pole wymagane"
                          )
                        )
                        .test(
                          "is-right-applicationDays",
                          t(
                            "cftChickenBroiler:errors.is-right-pickupDay",
                            "Przekroczenie okresu produkcyjnego"
                          ),
                          function() {
                            const [, production] = this.from;
                            const applicationDays = production.value.feed_components.reduce(
                              (prev, acc) => {
                                return prev + Number(acc.application_days);
                              },
                              0
                            );
                            const productionCycleleft =
                              Number(production.value.production_cycle_length) -
                              applicationDays;

                            return productionCycleleft >= 0;
                          }
                        )
                        .typeError(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                            "Podaj wartość liczbową"
                          )
                        )
                        .integer(
                          t(
                            "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                            "Podaj wartość całkowitą"
                          )
                        ),
                      production: Yup.string().required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    })
                  )
                  .min(1),
              })
            ),
          })}
          onSubmit={async (values, helpers) => {
            try {
              await API().post(
                "/api/v1/ae/chicken/henhouse-validation/",
                values,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                henhouses_reference: {
                  ...store.henhouses_reference,
                  coops: store.henhouses_reference.coops.map((coop, key) => {
                    if (key === Number(id)) {
                      return {
                        ...values,
                        isValidate: true,
                      };
                    }

                    return coop;
                  }),
                },
              });

              history.push(routes.CHICKEN_BROILER.COOPS);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form>
            <BackToCoops />
            <h2 className="cft-section-title mb-3">{coop.name}</h2>
            <div className="cft-paper mb-2">
              <CftFormControl alignLeft withoutMarginBottom>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftChickenBroiler:chickenBroilerCoopForm.fields.nameLabel",
                    "Nazwa kurnika"
                  )}
                />
                <CftTextField
                  isRounded
                  name="name"
                  placeholder={t(
                    "cftChickenBroiler:chickenBroilerCoopForm.fields.namePlaceholder",
                    "Wpisz nazwę"
                  )}
                  isAlternative
                />
              </CftFormControl>
            </div>
            <ProductionCyclesFields />
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftErrorBoxForm
                text={t(
                  "cftChickenBroiler:form.error.main",
                  "Wypełnij brakujące pola żeby przejść dalej"
                )}
              />
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftChickenBroiler:chickenBroilerCoopForm.fields.submit",
                  "Zapisz kurnik"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="chicken" />
    </div>
  );
}

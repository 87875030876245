import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Hero,
  Benefits,
  Typography,
  Video,
  ArticlesSection,
  TileNeeds,
} from "agronomist-ui-components";
import { generatePath } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { getListOfArticles } from "../../services/redux/articles/widget/reducer";
import { formatDate } from "../../services/helpers/articles";
import PageTemplate from "../helpers/PageTemplate";
import { MAIN_SEEDS } from "../consts/seeds";
import { WIZARD_URL } from "../consts/consts";
import { scrollToElement } from "../helpers/utils";

import imageUrl from "../assets/images/main.png";
import mobileImageUrl from "../assets/images/main_rwd.png";

const COPY = {
  pl: {
    HERO: {
      breadcrumbs: [{ title: "Agronomist", url: "/" }, { title: "Kredytomat" }],
      title: "Agronomist – Kredytomat",
      description:
        "Poszukujesz finansowania, prostego i szybkiego dostępu do rachunku poprzez bankowość elektroniczną,  a może  chciałbyś ubezpieczyć majątek lub porozmawiać o swoich potrzebach finansowania z Ekspertem Agro?",
      imageUrl,
      mobileImageUrl,
      button: {
        title: "Sprawdź Kredytomat",
        link: "#ofert",
      },
    },
    BENEFITS: {
      title: "Jak to działa?",
      subtitle:
        "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
      benefits: [
        {
          title: "Określ swoje potrzeby",
          description:
            "Ustaw intersujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
          icon: "Bulb",
        },
        {
          title: "Otrzymasz dopasowaną ofertę",
          description:
            "Wyślij na swój adres email otrzymaną propozycję produktów, abyś zawsze mógł do niej wrócić.",
          icon: "PaperBulb",
        },
        {
          title: "Porozmawiaj o ofercie z naszym Ekspertem",
          description:
            "Pozostaw telefon lub adres email. Skontaktujemy się z Tobą aby omówić szczegóły oferty.",
          icon: "MessagesBulb",
        },
      ],
    },
    TILES_NEEDS: [
      {
        icon: "CropsMoney",
        title: "Potrzebuję bieżącego finansowania",
        description: "(zakup nawozów, nasion, pasz, środków ochrony roślin)",
        url: generatePath(WIZARD_URL, {
          seed: MAIN_SEEDS.needOngoingFinancing,
        }),
      },
      {
        icon: "LandsArrowsUp",
        title: "Chcę rozwinąć swoje gospodarstwo",
        description: "(inwestycje w rozwój Twojego gospodarstwa)",
        url: "/kredytomat/nowe-perspektywy-rozwoju-twojego-gospodarstwa",
      },
      {
        icon: "NaturalDisaster",
        title: "Dotknęła mnie klęska żywiołowa",
        description: "(wznowienie produkcji po klęsce żywiołowej)",
        url: "/kredytomat/kredyt-na-wznowienie-produkcji-po-klesce-zywiolowej",
      },
      {
        icon: "LaptopAccount",
        title: "Chcę otworzyć rachunek bankowy",
        description:
          "(konto dopasowane do Twoich potrzeb z dostępem do bankowości internetowej)",
        url: "/kredytomat/rachunek-biezacy-dla-gospodarstw-rolnych",
      },
      {
        icon: "ShieldPlantGrowth",
        title: "Poszukuję ubezpieczenia",
        description: "(ubezpieczenia majątkowe, upraw oraz na życie i zdrowie)",
        url: generatePath(WIZARD_URL, {
          seed: MAIN_SEEDS.lookingForInsurance,
        }),
      },
    ],
    meta: {
      name: "Opcje finansowania dla rolników - Agronomist",
      description:
        "Potrzebujesz kredytu na finansowanie lub dalszy rozwój gospodarstwa? Poznaj Kredytomat Agronomist i znajdź rozwiązanie dla siebie - Agronomist",
      mediaCoverUrl: imageUrl,
    },
    video: {
      title: "Sprawdź jak możesz wyszukać odpowiedni produkt",
      subtitle:
        "Obejrzyj krótki film jak skorzystać z Kredytomatu, aby znaleźć produkty dopasowane do potrzeb Twojego gospodarstwa.",
      youTubeVideoUrl: "https://www.youtube.com/embed/dhBvIOAgb0g",
    },
    text: {
      t1: "W czym możemy Ci pomóc?",
      t2:
        "Przeglądaj ofertę Banku BNP Paribas kierowaną do osób prowadzących gospodarstwa rolne. Poznaj mocne strony naszych produktów. Znajdź ofertę dla siebie.",
    },
    articlesSection: {
      title: "Potrzebujesz dodatkowych informacji?",
      subtitle: "Dedykowana oferta dla rolników.",
      toAllText: "Wszystkie wpisy",
    },
  },
  gb: {
    HERO: {
      breadcrumbs: [{ title: "Agronomist", url: "/" }, { title: "Kredytomat" }],
      title: "Agronomist – Kredytomat",
      description:
        "Are you are looking for financing, a quick and easy access to your account via the electronic banking, or maybe you would like to insure your assets or talk to an Agro Expert about your financing needs?",
      imageUrl,
      mobileImageUrl,
      button: {
        title: "Explore Kredytomat",
        onClick: () => scrollToElement("#ofert"),
      },
    },
    BENEFITS: {
      title: "How does it work?",
      subtitle:
        "Kredytomat is an easy and user-friendly search engine of the bank offering to help you select the products that are best suited to your farming needs.",
      benefits: [
        {
          title: "Define your needs",
          description:
            "Set the search criteria you are interested in to receive the offer that best suits your needs.",
          icon: "Bulb",
        },
        {
          title: "You will receive a tailor-made offer",
          description:
            "Send the offered products information to your email to be able to come back to it any time",
          icon: "PaperBulb",
        },
        {
          title: "Talk to our Expert about the offer",
          description:
            "Leave your phone number or email address. We’ll contact you to discuss the details of the offer.",
          icon: "MessagesBulb",
        },
      ],
    },
    TILES_NEEDS: [
      {
        icon: "CropsMoney",
        title: "I need day-to-day financing",
        description:
          "(to buy the fertilizers, seeds, feed, crop protection products)",
        url: generatePath(WIZARD_URL, {
          seed: MAIN_SEEDS.needOngoingFinancing,
        }),
      },
      {
        icon: "LandsArrowsUp",
        title: "I want growth for my farm",
        description: "(investment into the growth of your farm)",
        url: "/kredytomat/nowe-perspektywy-rozwoju-twojego-gospodarstwa",
      },
      {
        icon: "NaturalDisaster",
        title: "I suffered a natural disaster",
        description: "(restarting production again after a natural disaster)",
        url: "/kredytomat/kredyt-na-wznowienie-produkcji-po-klesce-zywiolowej",
      },
      {
        icon: "LaptopAccount",
        title: "I want to open a bank account",
        description:
          "(an account suited to your needs with access to internet banking)",
        url: "/kredytomat/rachunek-biezacy-dla-gospodarstw-rolnych",
      },
      {
        icon: "ShieldPlantGrowth",
        title: "I'm looking for insurance",
        description: "(property, crop as well as life and health insurance)",
        url: generatePath(WIZARD_URL, {
          seed: MAIN_SEEDS.lookingForInsurance,
        }),
      },
    ],
    meta: {
      name: "Financing options for farmers - Agronomist",
      description:
        "Are you are looking for financing, a quick and easy access to your account via the electronic banking, or maybe you would like to insure your assets or talk to an Agro Expert about your financing needs?",
      mediaCoverUrl: imageUrl,
    },
    video: {
      title: "See how you can find the right product",
      subtitle:
        "Watch a short film on how to use the Kredytomat to find  the products suited to the needs of your farm.",
      youTubeVideoUrl: "https://www.youtube.com/embed/wcwb1VY2n3I",
    },
    text: {
      t1: "How can we help you?",
      t2:
        "Find out the BNP Paribas offer dedicated to individuals managing farms. Learn the strengths of our products. Find the offer for you.",
    },
    articlesSection: {
      title: "Do you need additional information?",
      subtitle: "Dedicated offer for farmers.",
      toAllText: "All entries",
    },
  },
};

const LandingPage = () => {
  const { t, i18n } = useTranslation(["articlesMeta"]);
  const articles = useSelector(state => state.articles.articlesAll.results);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      getListOfArticles({
        categories_list: "80,51,83,89,81,87,61",
        ordering: "-published_at",
        limit: 4,
      })
    );
  }, [dispatch]);

  const getTag = useCallback(categories => {
    if (categories.length === 0) {
      return undefined;
    }

    const { id } = categories[0];

    return {
      name: t(`articlesMeta:categories.${id}`),
      url: "/food-and-agro",
    };
  }, []);

  return (
    <PageTemplate meta={COPY[i18n.language].meta}>
      <Hero {...COPY[i18n.language].HERO} isImageFullSize isLight />
      <Benefits
        {...COPY[i18n.language].BENEFITS}
        withArrow
        pt="4rem"
        pb="4rem"
      />
      <Grid.Container fluid background="#00965e" id="ofert">
        <Grid.Container py="4rem">
          <Typography
            variant="h2"
            color="white"
            isUpperCase
            textAlign="center"
            mb="1rem"
          >
            {COPY[i18n.language].text.t1}
          </Typography>
          <Grid.Row>
            <Grid.Col md={{ size: 6, offset: 3 }}>
              <Typography color="white" textAlign="center" mb="2rem">
                {COPY[i18n.language].text.t2}
              </Typography>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col
              display="flex"
              flexDirection={["column", "column", "row"]}
              alignItems={["center", "center", "stretch"]}
              justifyContent="center"
            >
              {COPY[i18n.language].TILES_NEEDS.map((tile, index) => {
                const isLast =
                  index === COPY[i18n.language].TILES_NEEDS.length - 1;

                return (
                  <TileNeeds
                    key={tile.title}
                    icon={tile.icon}
                    title={tile.title}
                    description={tile.description}
                    to={tile.url}
                    mb="1rem"
                    mr={!isLast ? "1rem" : "0"}
                  />
                );
              })}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Grid.Container>
      <Grid.Container py="4rem">
        <Video {...COPY[i18n.language].video} />
      </Grid.Container>
      <Grid.Container>
        <Grid.Container>
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "#e5e5e5",
            }}
          />
        </Grid.Container>
      </Grid.Container>
      <Grid.Container pt="2rem" pb="4rem">
        <ArticlesSection
          linkToAll="/"
          toAllText={COPY[i18n.language].articlesSection.toAllText}
          title={COPY[i18n.language].articlesSection.title}
          subtitle={COPY[i18n.language].articlesSection.subtitle}
          articles={articles.map(
            ({ published_at, name, summary, thumb, slug, categories }) => ({
              coverUrl: thumb !== null ? thumb.file : "",
              date: formatDate(published_at),
              tag: getTag(categories),
              title: name,
              description: summary,
              url: `/artykuly/${slug}`,
            })
          )}
        />
      </Grid.Container>
    </PageTemplate>
  );
};

export default LandingPage;

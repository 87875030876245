import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  const feed_components =
    values.approach === "1"
      ? values.herd
          .map((val, key) => {
            if (val) {
              return val.animals.reduce((prev, curr) => {
                return [
                  ...prev,
                  {
                    feed_item: curr.item,
                    herd_section: key + 1,
                    dry_matter: {
                      value: dotFormatter(curr.dryMmatterValue),
                      unit_id: 7,
                    },
                  },
                ];
              }, []);
            }
            return val;
          })
          .filter(val => val !== false)
          .reduce((prev, curr) => {
            return [...prev, ...curr];
          }, [])
      : values.feed.map(({ item, percentage }) => ({
          feed_item: item,
          percentage: dotFormatter(percentage),
        }));
  return {
    feed_aproche: values.approach,
    feed_components,
  };
}

import { format } from "date-fns";
import { find } from "ramda";
import { useTranslation } from "react-i18next";
import { getIntPath } from "../getIntPath";
import { useLinks } from "./links";

export const stringDateRevered = date => {
  const dateSplited = date.split("-");

  return `${dateSplited[2]}.${dateSplited[1]}.${dateSplited[0]}`;
};

export const formatDate = date => {
  return format(date, "DD.MM.YYYY");
};

export const articleCategoryToString = (articlesCategories, category) => {
  const selectedCategory = articlesCategories.find(
    item => item.id === category
  );
  if (selectedCategory) {
    return selectedCategory.type_name;
  }
  return null;
};

export const articleCategoryIcon = (articlesCategories, category) => {
  const selectedCategory = articlesCategories.find(
    item => item.id === category
  );
  if (selectedCategory) {
    return selectedCategory.icon;
  }
  return null;
};

export const articleTagToString = (articlesTags, tagId) => {
  const selectedTag = articlesTags.find(item => item.id === tagId);
  if (selectedTag) {
    return selectedTag.name;
  }
  return null;
};

export const pageRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start + 1) / step)).keys()),
    x => start + x * step
  );
};

export const getMainCategory = (articleCategories = []) => {
  if (articleCategories.length === 0) {
    return undefined;
  }

  return articleCategories[0].id;
};

export const getSecondaryCategory = (articleCategories = []) => {
  if (articleCategories.length < 2) {
    return undefined;
  }

  return articleCategories[1].id;
};

export const getPromotedArticle = (categoryID, articlesCategories = []) => {
  if (categoryID === undefined) {
    return undefined;
  }
  const category = find(category => category.id === Number(categoryID))(
    articlesCategories
  );

  return category ? category.promoted_article : undefined;
};

const ARTICLE_ACCESS_LABELS = {
  2: "articlesMeta:access.user",
  3: "articlesMeta:access.premium",
};

const articleTag = (article, t) => {
  const { access, labels } = article;
  if (access && ARTICLE_ACCESS_LABELS[access]) {
    return {
      name: t(ARTICLE_ACCESS_LABELS[access]),
      isPremium: true,
    };
  }

  if (labels && labels.length) {
    return {
      name: labels[0],
      isPremium: false,
    };
  }

  return null;
};

export const serializeArticleDataToCard = (article, t, linkFn) => {
  const categoryId = getMainCategory(article.categories);
  return {
    coverUrl: article.thumb !== null ? article.thumb.file : "",
    date: formatDate(article.published_at),
    title: article.short_name || article.name,
    description: article.short_summary || article.summary,
    category: {
      name: t(`articlesMeta:categories.${categoryId}`),
    },
    tag: articleTag(article, t),
    url:
      linkFn && typeof linkFn === "function"
        ? linkFn(article.slug, categoryId, article.access)
        : null,
  };
};

export const MARKET_AGREGATOR_CATEGORY = {
  id: "market-articles-page",
  categoriesList: "18,23,28,34,40,42,43,93,39",
};

export const articlesNavigation = (t, language) => {
  const nav = [
    {
      name: t("navigation:navMacro"),
      path: getIntPath(
        ["/artykuly/analizy-makro", "/articles/analizy-makro"],
        language
      ),
      id: 90,
    },
    {
      name: t("navigation:navArticles"),
      path: getIntPath(
        ["/artykuly/analizy-food-agro", "/articles/analizy-food-agro"],
        language
      ),
      ...MARKET_AGREGATOR_CATEGORY,
    },
    {
      name: t("articlesMeta:categories.57"),
      path: getIntPath(
        ["/artykuly/zielone-zmiany", "/articles/zielone-zmiany"],
        language
      ),
      id: 57,
    },
    {
      name: t("articlesMeta:categories.75"),
      path: getIntPath(["/artykuly/finanse", "/articles/finanse"], language),
      id: 75,
    },
    {
      name: t("articlesMeta:categories.79"),
      path: getIntPath(
        ["/artykuly/rola-prawnika", "/articles/rola-prawnika"],
        language
      ),
      id: 79,
    },
    {
      name: t("articlesMeta:categories.72"),
      path: getIntPath(
        [
          "/articles/food-and-agro-conference",
          "/articles/food-and-agro-conference",
        ],
        language
      ),
      id: 72,
    },
    {
      name: t("navigation:navWomen"),
      path: getIntPath(
        ["/artykuly/dla-kobiet", "/articles/dla-kobiet"],
        language
      ),
      id: 68,
    },
    {
      name: t("articlesMeta:categories.53"),
      path: getIntPath(["/artykuly/prasa", "/articles/prasa"], language),
      id: 53,
    },
  ];
  return nav;
};

export const pressNavigation = (t, language) => {
  const nav = [
    {
      name: t("articlesMeta:categories.54"),
      path: getIntPath(["/artykuly/polska", "/articles/polska"], language),
      id: 54,
    },
    {
      name: t("articlesMeta:categories.55"),
      path: getIntPath(
        ["/artykuly/unia-europejska", "/articles/unia-europejska"],
        language
      ),
      id: 55,
    },
    {
      name: t("articlesMeta:categories.56"),
      path: getIntPath(["/artykuly/swiat", "/articles/swiat"], language),
      id: 56,
    },
  ];
  return nav;
};

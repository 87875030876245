import {
  increaseStarsOnPhoto,
  prepareTagsOptions,
  decreaseStarsOnPhoto,
} from "./utils";
import {
  GET_PHOTOS,
  VOTE_ON_PHOTO,
  GET_PHOTOS_LATEST,
  GET_PHOTOS_MOST_POPULAR,
  VOTE_ON_PHOTO_LATEST,
  VOTE_ON_PHOTO_MOST_POPULAR,
  GET_PHOTOS_RELATED,
  CLEAR_PHOTOS_RELATED,
  ADD_PHOTO,
  GET_PHOTOS_TAGS,
  GET_PHOTOS_REJECTED,
  REMOVE_PHOTO,
  GET_PROFILE_META,
  UPDATE_PHOTO,
  GET_PHOTO,
  UNVOTE_ON_PHOTO,
  GET_MORE_PHOTOS,
} from "./actions";

const SUCCESS = "_SUCCESS";

const InitialState = {
  search: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
  mostPopular: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
  latest: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
  related: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
  rejected: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
  tags: [],
  profileMeta: {
    first_name: "",
    last_name: "",
    user_type: -1,
    slug: "",
    used_tags: [],
  },
};

export default function photos(state = InitialState, action) {
  switch (action.type) {
    case GET_PROFILE_META:
      return { ...state, profileMeta: InitialState.profileMeta };
    case GET_PHOTOS + SUCCESS:
      return { ...state, search: { ...action.payload.data } };
    case GET_PHOTOS_LATEST + SUCCESS:
      return { ...state, latest: { ...action.payload.data } };
    case GET_PHOTOS_MOST_POPULAR + SUCCESS:
      return { ...state, mostPopular: { ...action.payload.data } };
    case GET_PHOTOS_RELATED + SUCCESS:
      return { ...state, related: { ...action.payload.data } };
    case GET_PHOTOS_REJECTED + SUCCESS:
      return { ...state, rejected: { ...action.payload.data } };
    case GET_PROFILE_META + SUCCESS:
      return { ...state, profileMeta: action.payload.data };
    case VOTE_ON_PHOTO:
    case VOTE_ON_PHOTO_LATEST:
    case VOTE_ON_PHOTO_MOST_POPULAR:
      return {
        ...state,
        mostPopular: {
          ...state.mostPopular,
          results: increaseStarsOnPhoto(action, state.mostPopular.results),
        },
        latest: {
          ...state.latest,
          results: increaseStarsOnPhoto(action, state.latest.results),
        },
        search: {
          ...state.search,
          results: increaseStarsOnPhoto(action, state.search.results),
        },
        related: {
          ...state.related,
          results: increaseStarsOnPhoto(action, state.related.results),
        },
      };
    case UNVOTE_ON_PHOTO:
      return {
        ...state,
        mostPopular: {
          ...state.mostPopular,
          results: decreaseStarsOnPhoto(action, state.mostPopular.results),
        },
        latest: {
          ...state.latest,
          results: decreaseStarsOnPhoto(action, state.latest.results),
        },
        search: {
          ...state.search,
          results: decreaseStarsOnPhoto(action, state.search.results),
        },
        related: {
          ...state.related,
          results: decreaseStarsOnPhoto(action, state.related.results),
        },
      };
    case CLEAR_PHOTOS_RELATED:
      return { ...state, related: InitialState.related };
    case GET_PHOTOS_TAGS + SUCCESS:
      return { ...state, tags: prepareTagsOptions(action) };
    case GET_MORE_PHOTOS + SUCCESS:
      return {
        ...state,
        search: {
          ...action.payload.data,
          results: [...state.search.results, ...action.payload.data.results],
        },
      };
    default:
      return state;
  }
}

export const getPhotos = ({
  params = {
    ordering: "-published_at",
  },
  type = GET_PHOTOS,
}) => ({
  type,
  payload: {
    request: {
      url: "/api/v1/photos/",
      method: "GET",
      params,
    },
  },
});

export const getProfileMeta = ({ slug }) => ({
  type: GET_PROFILE_META,
  payload: {
    request: {
      url: `/api/v1/photos-user-meta/${slug}/`,
      method: "GET",
    },
  },
});

export const getPopularPhotos = ({ params } = {}) => ({
  type: GET_PHOTOS_MOST_POPULAR,
  payload: {
    request: {
      url: "/api/v1/photos/popular/",
      method: "GET",
      params,
    },
  },
});

export const updatePhoto = ({ title, tags_list, slug } = {}) => ({
  type: UPDATE_PHOTO,
  payload: {
    request: {
      url: `/api/v1/photos/${slug}/`,
      method: "PATCH",
      data: {
        title,
        tags_list,
      },
    },
  },
});

export const addPhoto = data => ({
  type: ADD_PHOTO,
  payload: {
    request: {
      url: "/api/v1/photos/",
      method: "POST",
      data,
    },
    options: {
      returnRejectedPromiseOnError: true,
    },
  },
});

export const removePhoto = ({ slug }) => ({
  type: REMOVE_PHOTO,
  payload: {
    request: {
      url: `/api/v1/photos/${slug}/`,
      method: "DELETE",
    },
  },
});

export const getPhoto = ({ slug }) => ({
  type: GET_PHOTO,
  payload: {
    request: {
      url: `/api/v1/photos/${slug}/`,
      method: "GET",
    },
  },
});

export const voteOnPhoto = ({ slug, type = VOTE_ON_PHOTO }) => ({
  type,
  payload: {
    request: {
      url: `/api/v1/photos/${slug}/vote/`,
      method: "POST",
    },
  },
  slug,
});

export const unvoteOnPhoto = ({ slug, type = UNVOTE_ON_PHOTO }) => ({
  type,
  payload: {
    request: {
      url: `/api/v1/photos/${slug}/cancel-vote/`,
      method: "POST",
    },
  },
  slug,
});

export const clearRelatedPhotos = () => ({
  type: CLEAR_PHOTOS_RELATED,
});

export const getPhotosTags = ({ limit } = { limit: 999 }) => ({
  type: GET_PHOTOS_TAGS,
  payload: {
    request: {
      url: "/api/v1/photos-tags/",
      method: "GET",
      params: {
        limit,
      },
    },
  },
});

import Cookies from "js-cookie";
import plIcon from "../../assets/icons/langs/pl.svg";
import gbIcon from "../../assets/icons/langs/eng.svg";
import i18n from "../../i18n";

const langs = [
  {
    icon: plIcon,
    lang_code: "pl",
    name: "Polski",
  },
  {
    icon: gbIcon,
    lang_code: "gb",
    name: "English",
  },
];

let currLang = Cookies.get("user_lang") || "pl";

export const Langs = () => ({
  activeLang: () => {
    return langs.find(item => item.lang_code === currLang);
  },
  unactiveLangs: () => {
    return langs.filter(item => item.lang_code !== currLang);
  },
  switchLang: (lang_code, reload = false) => {
    const newLang = lang_code.toLowerCase();
    if (reload) i18n.changeLanguage(newLang);
    currLang = i18n.language;
    Cookies.set("user_lang", newLang, {
      expires: new Date(`${new Date().getFullYear() + 1}`),
      secure: true,
    });
  },
});

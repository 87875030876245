/* eslint-disable func-names */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftErrorBoxForm,
  CftFormErrorFocus,
  CftClearForm,
} from "../../components";
import routes from "../../utils/cftRoutes";
import API from "../../../services/apiHelpers";
import ProductionCyclesFields from "./ProductionCyclesFields";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";
import BackToCoops from "./BackToCoops";

export default function TurkeyCoopForm() {
  const token = Cookies.get("user_token");
  const { updateStore, store } = useCftTurkeyValues();
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const history = useHistory();
  const { id } = useParams();

  if (!id) {
    return <Redirect to={routes.TURKEY.COOPS} />;
  }

  const coop = store.henhouses_reference.coops[Number(id)];

  if (!coop) {
    return <Redirect to={routes.TURKEY.COOPS} />;
  }

  const calculationPeriodLength = Number(store.main.calculation_period_length);

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Formik
          enableReinitialize
          initialValues={coop}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            production_cycles: Yup.array().of(
              Yup.object().shape({
                production_cycle_length: Yup.number()
                  .typeError(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                      "Podaj wartość liczbową"
                    )
                  )
                  .integer(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                      "Podaj wartość całkowitą"
                    )
                  )
                  .min(
                    7,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin7",
                      "Wartość minimalna to 7."
                    )
                  )
                  .max(
                    210,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax210",
                      "Wartość maksymalna to 210."
                    )
                  )
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                technological_break_length: Yup.number()
                  .typeError(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                      "Podaj wartość liczbową"
                    )
                  )
                  .integer(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                      "Podaj wartość całkowitą"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    70,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax70",
                      "Wartość maksymalna to 70."
                    )
                  )
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                repetitions: Yup.number()
                  .typeError(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                      "Podaj wartość liczbową"
                    )
                  )
                  .integer(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                      "Podaj wartość całkowitą"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cftTurkey:errors.calculationPeriodLength2", {
                      calculationPeriodLength,
                    }),
                    function() {
                      const [, form] = this.from;
                      const wholeCalculationPeriodLength = form.value.production_cycles
                        .map(productionCycle => {
                          if (!productionCycle.repetitions) {
                            return 0;
                          }

                          if (
                            !productionCycle.production_cycle_length ||
                            !productionCycle.technological_break_length
                          ) {
                            return 0;
                          }

                          return (
                            (Number(
                              productionCycle.technological_break_length
                            ) +
                              Number(productionCycle.production_cycle_length)) *
                            Number(productionCycle.repetitions)
                          );
                        })
                        .reduce((prev, acc) => prev + acc, 0);

                      return (
                        wholeCalculationPeriodLength <= calculationPeriodLength
                      );
                    }
                  ),
                male_turkeys: Yup.object()
                  .shape({
                    target_age: Yup.number()
                      .typeError(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                          "Podaj wartość liczbową"
                        )
                      )
                      .integer(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                          "Podaj wartość całkowitą"
                        )
                      )
                      .min(
                        7,
                        t(
                          "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin7",
                          "Wartość minimalna to 7."
                        )
                      )
                      .max(
                        210,
                        t(
                          "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax210",
                          "Wartość maksymalna to 210."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      )
                      .test(
                        "is-right-target_age",
                        t(
                          "cftChickenBroiler:errors.is-right-target_age",
                          "Przekroczenie wieku docelowego. Wartość nie powinna przekroczyć długość cyklu produkcyjnego."
                        ),
                        function(value) {
                          const [, production] = this.from;

                          return (
                            production.value.production_cycle_length >= value
                          );
                        }
                      ),
                    falls_percent: Yup.number()
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin001",
                          "Wartość minimalna to 1."
                        )
                      )
                      .max(
                        10,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax10",
                          "Wartość maksymalna to 10."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    quantity: Yup.number()
                      .typeError(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                          "Podaj wartość liczbową"
                        )
                      )
                      .integer(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                          "Podaj wartość całkowitą"
                        )
                      )
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                          "Wartość minimalna to 1."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    feed_components: Yup.array()
                      .of(
                        Yup.object().shape({
                          feed_type: Yup.string().required(
                            t(
                              "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                              "Pole wymagane"
                            )
                          ),
                          calorific_value: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          quantity: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          application_days: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .test(
                              "is-right-applicationDaysMale",
                              "Przekroczenie docelowy wiek indorów",
                              function() {
                                const [, production] = this.from;

                                if (!production.value.feed_components) {
                                  return true;
                                }

                                const targetAge = production.value.target_age
                                  ? Number(production.value.target_age)
                                  : 0;
                                const applicationDays = production.value.feed_components.reduce(
                                  (prev, acc) => {
                                    return prev + Number(acc.application_days);
                                  },
                                  0
                                );

                                const productionCycleleft =
                                  targetAge - applicationDays;

                                return productionCycleleft >= 0;
                              }
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          production: Yup.string().required(
                            t(
                              "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                              "Pole wymagane"
                            )
                          ),
                        })
                      )
                      .min(1),
                  })
                  .test(
                    "is-right-productionChoose",
                    "Należy wybrać rodzaj produkcji.",
                    function() {
                      const [, production] = this.from;
                      return (
                        production.value.female_turkeys !== null ||
                        production.value.male_turkeys !== null
                      );
                    }
                  )
                  .nullable(),
                female_turkeys: Yup.object()
                  .shape({
                    target_age: Yup.number()
                      .typeError(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                          "Podaj wartość liczbową"
                        )
                      )
                      .integer(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                          "Podaj wartość całkowitą"
                        )
                      )
                      .min(
                        7,
                        t(
                          "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin7",
                          "Wartość minimalna to 7."
                        )
                      )
                      .max(
                        210,
                        t(
                          "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax210",
                          "Wartość maksymalna to 210."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      )
                      .test(
                        "is-right-target_age",
                        t(
                          "cftChickenBroiler:errors.is-right-target_age",
                          "Przekroczenie wieku docelowego. Wartość nie powinna przekroczyć długość cyklu produkcyjnego."
                        ),
                        function(value) {
                          const [, production] = this.from;

                          return (
                            production.value.production_cycle_length >= value
                          );
                        }
                      ),
                    falls_percent: Yup.number()
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin001",
                          "Wartość minimalna to 1."
                        )
                      )
                      .max(
                        10,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax10",
                          "Wartość maksymalna to 10."
                        )
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    quantity: Yup.number()
                      .typeError(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                          "Podaj wartość liczbową"
                        )
                      )
                      .integer(
                        t(
                          "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                          "Podaj wartość całkowitą"
                        )
                      )
                      .min(
                        1,
                        t(
                          "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                          "Wartość minimalna to 1."
                        )
                      )
                      .test(
                        "is-right-applicationDaysFemale",
                        "Przekroczenie docelowy wiek indyczek",
                        function() {
                          const [, production] = this.from;

                          if (!production.value.feed_components) {
                            return true;
                          }

                          const targetAge = production.value.target_age
                            ? Number(production.value.target_age)
                            : 0;
                          const applicationDays = production.value.feed_components.reduce(
                            (prev, acc) => {
                              return prev + Number(acc.application_days);
                            },
                            0
                          );

                          const productionCycleleft =
                            targetAge - applicationDays;

                          return productionCycleleft >= 0;
                        }
                      )
                      .required(
                        t(
                          "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                          "Pole wymagane"
                        )
                      ),
                    feed_components: Yup.array()
                      .of(
                        Yup.object().shape({
                          feed_type: Yup.string().required(
                            t(
                              "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                              "Pole wymagane"
                            )
                          ),
                          calorific_value: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          quantity: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          application_days: Yup.number()
                            .min(
                              1,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                                "Wartość minimalna to 1."
                              )
                            )
                            .max(
                              2147483647,
                              t(
                                "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                                "Wartość maksymalna to 2147483647."
                              )
                            )
                            .required(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                                "Pole wymagane"
                              )
                            )
                            .test(
                              "is-right-applicationDays",
                              t(
                                "cftChickenBroiler:errors.is-right-pickupDay",
                                "Przekroczenie okresu produkcyjnego"
                              ),
                              function() {
                                // const [, production] = this.from;
                                // const applicationDays = production.value.feed_components.reduce(
                                //   (prev, acc) => {
                                //     return prev + Number(acc.application_days);
                                //   },
                                //   0
                                // );
                                // const productionCycleleft =
                                //   Number(
                                //     production.value.production_cycle_length
                                //   ) - applicationDays;

                                return true;
                              }
                            )
                            .typeError(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                                "Podaj wartość liczbową"
                              )
                            )
                            .integer(
                              t(
                                "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                                "Podaj wartość całkowitą"
                              )
                            ),
                          production: Yup.string().required(
                            t(
                              "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                              "Pole wymagane"
                            )
                          ),
                        })
                      )
                      .min(1),
                  })
                  .nullable(),
              })
            ),
          })}
          onSubmit={async (values, helpers) => {
            try {
              await API().post("/api/v1/ae/turkey/barn-validation/", values, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              updateStore({
                henhouses_reference: {
                  ...store.henhouses_reference,
                  coops: store.henhouses_reference.coops.map((coop, key) => {
                    if (key === Number(id)) {
                      return {
                        ...values,
                        isValidate: true,
                      };
                    }

                    return coop;
                  }),
                },
              });

              history.push(routes.TURKEY.COOPS);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form>
            <BackToCoops />
            <h2 className="cft-section-title mb-3">{coop.name}</h2>
            <div className="cft-paper mb-2">
              <CftFormControl alignLeft withoutMarginBottom>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftTurkey:chickenBroilerCoopForm.fields.nameLabel",
                    "Nazwa indycznika"
                  )}
                />
                <CftTextField
                  isRounded
                  name="name"
                  placeholder={t(
                    "cftTurkey:chickenBroilerCoopForm.fields.namePlaceholder",
                    "Wpisz nazwę"
                  )}
                  isAlternative
                />
              </CftFormControl>
            </div>
            <ProductionCyclesFields />
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftErrorBoxForm
                text={t(
                  "cftTurkey:form.error.main",
                  "Wypełnij brakujące pola żeby przejść dalej"
                )}
              />
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftTurkey:chickenBroilerCoopForm.fields.submit",
                  "Zapisz indycznik"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="turkey" />
    </div>
  );
}

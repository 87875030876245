import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftSelectField,
  CftFormControl,
  CftTextField,
} from "../../components";
import useCftAeOptions from "../../utils/useCftAeOptions";

export default function TransportFields() {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { getVehicleName, vehicleTypesOptions } = useCftAeOptions();
  const { setFieldValue, values } = useFormikContext();

  const options = useMemo(
    () =>
      vehicleTypesOptions.filter(
        ({ value }) =>
          !values.transport
            .map(({ vehicle_type }) => vehicle_type)
            .includes(value)
      ),
    [values]
  );

  return (
    <>
      <h2 className="cft-section-title mb-3">
        {t(
          "cftChickenBroiler:transportFields.title",
          " Informacje o transporcie"
        )}
      </h2>
      <h4 className="cft-section-subtitle mb-7 cft-wrapper-cb">
        {t(
          "cftChickenBroiler:transportFields.subtitle",
          "Podaj informacje o przejechanym dystansie lub o ilości zużytych paliw. Możesz wybrać 4 rodzaje pojazdów."
        )}
      </h4>
      <div className="cft-paper">
        {!!values["transport"].length && (
          <CftTank alignLeft display="block">
            {values["transport"].map(({ vehicle_type }, key) => (
              <CftTank key={vehicle_type} display="block" mt={key && 2}>
                <CftTank
                  mb={2}
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                >
                  <span className="cft-badge">
                    {key + 1}. {getVehicleName(vehicle_type, t)}
                  </span>
                  <button
                    className="cft-close-badge"
                    type="button"
                    onClick={() =>
                      setFieldValue(
                        "transport",
                        values["transport"].filter(
                          carrier => carrier.vehicle_type !== vehicle_type
                        )
                      )
                    }
                  />
                </CftTank>
                {Number(
                  values.transport[key].gasoline ||
                    values.transport[key].diesel ||
                    0
                ) <= 0 && (
                  <CftFormControl alignLeft>
                    <CftLabel
                      isFullWidth
                      label={t(
                        "cftChickenBroiler:transportFields.fields.distanceLabel",
                        "Dystans (km)"
                      )}
                    />
                    <CftTextField
                      name={`transport[${key}].distance`}
                      isAlternative
                      mask="000000000"
                    />
                  </CftFormControl>
                )}
                {Number(values.transport[key].distance || 0) <= 0 && (
                  <>
                    {!getVehicleName(vehicle_type, t).includes("diesel") ? (
                      <CftFormControl alignLeft>
                        <CftLabel
                          isFullWidth
                          label={t(
                            "cftChickenBroiler:transportFields.fields.gasolineLabel",
                            "Benzyna (l)"
                          )}
                        />
                        <CftTextField
                          name={`transport[${key}].gasoline`}
                          isAlternative
                          mask="000000000"
                        />
                      </CftFormControl>
                    ) : (
                      <CftFormControl alignLeft>
                        <CftLabel
                          isFullWidth
                          label={t(
                            "cftChickenBroiler:transportFields.fields.dieselLabel",
                            "Olej napędowy (l)"
                          )}
                        />
                        <CftTextField
                          name={`transport[${key}].diesel`}
                          isAlternative
                          mask="000000000"
                        />
                      </CftFormControl>
                    )}
                  </>
                )}
              </CftTank>
            ))}
          </CftTank>
        )}
        {!!options.length && (
          <CftFormControl alignLeft withoutMarginBottom>
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:transportFields.fields.transportLabel",
                "Dodaj z listy"
              )}
            />
            <CftSelectField
              name="tmp"
              options={options}
              onChange={value => {
                setFieldValue("transport", [
                  ...values["transport"],
                  {
                    vehicle_type: value,
                    distance: 0,
                    diesel: 0,
                    gasoline: 0,
                  },
                ]);
              }}
              placeholder={t(
                "cftChickenBroiler:transportFields.fields.transportPlaceholder",
                "Wybierz z listy"
              )}
              isRounded
              isAlternative
            />
          </CftFormControl>
        )}
      </div>
    </>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Oleaginous } from "../../../averagePrices/types";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import { useDataForRaportCenowy, useListOfOleaginousCereals } from "./api";

const OleaginousWidget = ({ userDefaults, onLoginClick }) => {
  const { t } = useTranslation(["averagePrices"]);
  const token = Cookies.get("user_token");
  const { data: oleaginous } = useListOfOleaginousCereals();
  const { data: reportData } = useDataForRaportCenowy();

  const userIsLogin = token !== "";

  if (!reportData || !oleaginous) {
    return null;
  }

  const [lastWeekData] = reportData.results;

  const cerealsInTable = oleaginous.filter(ol =>
    userDefaults.interested_products.includes(ol.id)
  );

  const productList = (() => {
    if (!lastWeekData) {
      return [];
    }

    return cerealsInTable.map(ol => {
      const productData = lastWeekData.products.find(
        product => product.product.id === ol.id
      );

      return createTableRow({
        id: ol.id,
        name: t(Oleaginous[ol.id]),
        currentPrice: productData ? productData.current_price : undefined,
        percentagePriceChange: productData
          ? productData.percentage_price_change
          : undefined,
      });
    });
  })();

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>
          <strong>{t("averagePrices:widget.titleOleaginous")}</strong>
        </span>

        <div className="details">
          <MarketWidget.PeriodSection
            startWeek={new Date(lastWeekData.week_start_date)}
            endWeek={new Date(lastWeekData.week_end_date)}
          />
          <MarketWidget.Customization
            isUserLogged={userIsLogin}
            linkTo="/srednie-ceny/oleiste/rzepak"
            onLoginClick={onLoginClick}
          />
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default OleaginousWidget;

import React, { useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftFormControl,
  CftButton,
  CftQuestionGroup,
  CftFormErrorFocus,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import { scrollToLastElement } from "../../../../utils/cftUtils";
import { farmlandsAPI } from "../../../../utils/cftApi";
import { mapValues } from "./fertilizersBothCalc.utils";

import FertilizerTypeFields from "./FertilizersBothCalcTypeFields";
import PesticideFields from "./PesticideFields";

function FertilizersBothCalcForm() {
  const { values, setFieldValue, errors } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const fertilizersGroupRefs = useRef([]);
  const plantProtectionProductsGroupRefs = useRef([]);

  const initFertilizer = () => ({
    id: Date.now(),
    type: "",
    fertiliser: "",
    productions: "",
    rate: {
      value: "",
      unit: "",
    },
    method: "",
    inhibitor: null,
  });

  const initPlantProtectionProduct = () => ({
    id: Date.now(),
    category: "",
    events: "",
    applicationRate: "",
    percentageRate: "",
  });

  return (
    <Form className="cft-form narrow-labels">
      {values.fertilizers.map((fertilizer, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (fertilizersGroupRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fertilizers.form.1", "Aplikacja nawozu")} ${key +
            1}`}
          withoutMarginBottom={key + 1 === values.fertilizers.length}
          onRemove={() => {
            setFieldValue(
              "fertilizers",
              values.fertilizers.filter(f => f.id !== fertilizer.id)
            );
            scrollToLastElement(fertilizersGroupRefs, 130);
          }}
          hasError={errors.fertilizers ? !!errors.fertilizers[key] : false}
        >
          <FertilizerTypeFields
            id={key}
            name="fertilizers"
            values={values.fertilizers[key]}
          />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("fertilizers", [
              ...values.fertilizers,
              initFertilizer(),
            ]);
            scrollToLastElement(fertilizersGroupRefs, 130);
          }}
        >
          {values.fertilizers.length
            ? t("cft:fertilizers.form.2", "Dodaj kolejny nawóz")
            : t("cft:fertilizers.form.3", "Dodaj nawóz")}
        </CftButton>
      </CftFormControl>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:fertilizers.form.5", "Środki ochrony roślin")}
          <small>
            {t(
              "cft:fertilizers.form.6",
              "Wprowadź dane dotyczące stosowania pestycydów, fumigantów, herbicydów, fungicydów itp."
            )}
          </small>
        </h3>
      </CftFormControl>
      {values.plantProtectionProducts.map((product, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (plantProtectionProductsGroupRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fertilizers.form.4", "Ochrona roślin")} ${key + 1}`}
          withoutMarginBottom={
            key + 1 === values.plantProtectionProducts.length
          }
          onRemove={() => {
            setFieldValue(
              "plantProtectionProducts",
              values.plantProtectionProducts.filter(f => f.id !== product.id)
            );
            scrollToLastElement(plantProtectionProductsGroupRefs, 130);
          }}
          hasError={
            errors.plantProtectionProducts
              ? !!errors.plantProtectionProducts[key]
              : false
          }
        >
          <PesticideFields id={key} name="plantProtectionProducts" />
        </CftQuestionGroup>
      ))}

      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("plantProtectionProducts", [
              ...values.plantProtectionProducts,
              initPlantProtectionProduct(),
            ]);
            scrollToLastElement(plantProtectionProductsGroupRefs, 130);
          }}
        >
          {values.plantProtectionProducts.length
            ? t("cft:fertilizers.form.7", "Dodaj kolejny środek")
            : t("cft:fertilizers.form.8", "Dodaj środek")}
        </CftButton>
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default FertilizersBothCalcForm;

export function FertilizersBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.fertilizers}
      validationSchema={Yup.object().shape({
        fertilizers: Yup.array().of(
          Yup.object().shape({
            fertiliser: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            productions: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            rate: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              unit: Yup.string().required(
                t("cft:validation.unitTypeError", "Wybierz jednostkę")
              ),
            }),
            method: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            inhibitor: Yup.boolean()
              .nullable()
              .required(t("cft:validation.requiredOption", "Wybierz opcję")),
          })
        ),
        plantProtectionProducts: Yup.array().of(
          Yup.object().shape({
            category: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            events: Yup.mixed().when("category", {
              is: v => v === "1" || v === "3",
              then: Yup.number()
                .min(1, "Musi wystąpić co najmniej jedno zdarzenie")
                .required(t("cft:validation.required", "Wpisz wartość")),
              otherwise: Yup.mixed().nullable(),
            }),
            applicationRate: Yup.mixed().when("category", {
              is: v => v === "2",
              then: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              otherwise: Yup.mixed().nullable(),
            }),
            percentageRate: Yup.mixed().when("category", {
              is: v => v === "2",
              then: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .max(
                  100,
                  t(
                    "cftPigs:validationSchema.max",
                    "Wartość maksymalna to {{max}}.",
                    { max: 100 }
                  )
                )
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              otherwise: Yup.mixed().nullable(),
            }),
          })
        ),
      })}
      onSubmit={values => {
        saveBothCalc({ fertilizers: values });
        const parsedValues = mapValues(values);

        Promise.all([
          farmlandsAPI.fertilizersValidation({
            fertilisers: parsedValues.fertilisers,
          }),
          farmlandsAPI.pesticideValidation({
            pesticides: parsedValues.pesticides,
          }),
        ]).then(([fertilisers, pesticides]) => {
          saveBothCalcReport({
            fertilisers: fertilisers.data.fertilisers,
            pesticides: pesticides.data.pesticides,
          });
          history.push(routes.FARMLANDS.BOTH_CALC.FUEL_AND_ENERGY);
        });
      }}
    >
      {children}
    </Formik>
  );
}

import { parse } from "date-fns";

export const prepareForecastObject = ({ meta, payload }) => {
  const { poi } = meta.previousAction;
  const forecast = payload.data;
  return {
    id: new Date().getTime() * Math.random(),
    name: poi ? poi.name : "Warszawa",
    frostAlert: poi ? poi.frost_notification_enabled : null,
    forecast: forecast.map(datum => ({
      ...datum,
      at_date: parse(datum.at_date),
    })),
  };
};

import React from "react";
import PropTypes from "prop-types";
import "./Button.scss";

// buttondisabled - props value if set, set to "inactive"
// buttonsize - props value "small" or "big"
// buttoncolor - props value "yellow" or "green"

export const Button = props => {
  return (
    <div
      {...props}
      className={
        props.buttondisabled
          ? `button--${props.buttonsize} button--${props.buttondisabled}`
          : `button--${props.buttonsize} 
            button--${props.buttoncolor}`
      }
    >
      <p>{props.children}</p>
    </div>
  );
};

Button.propTypes = {
  buttondisabled: PropTypes.string,
  buttonsize: PropTypes.string.isRequired,
  buttoncolor: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Button.defaultProps = {
  buttondisabled: "",
  children: null,
};

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    transport: values.transports.map(({ weight, distance, label, type }) => ({
      mode_id: Number(type),
      weight: {
        value: dotFormatter(weight.value),
        unit_id: weight.unit,
      },
      distance: {
        value: dotFormatter(distance),
        unit_id: "2",
      },
      name: label,
    })),
  };
}

import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Col, Modal, Row } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  RegagriFormControl,
  RegagriLabel,
  RegagriSection,
  RegagriTooltip,
} from "../components";
import { getProperTooltip } from "./RegagriPractices";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { carbonInputsLangMapper } from "../utils/regagriApi";

const RegagriReport = ({ data }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["regagri"]);
  const {
    details: { name, size, soilType },
    explorer: { reductionPotential, income, climate },
    chart,
    potential,
  } = data;
  const [isModalOpen, setIsModalOpen] = useState(0);

  const productsEmission = [
    {
      color: "#FD0059",
      id: 1,
      key: t("regagri:cultivationDetails.form.report.charts.emisson.emmisions"),
    },
    {
      color: "#3CC13B",
      id: 2,
      key: t(
        "regagri:cultivationDetails.form.report.charts.emisson.netEmissions"
      ),
    },
    {
      color: "#458FFF",
      id: 3,
      key: t("regagri:cultivationDetails.form.report.charts.emisson.baseline"),
    },
  ];

  const emissionData = chart.map(el => ({
    name: el.year,
    [t(
      "regagri:cultivationDetails.form.report.charts.emisson.emmisions"
    )]: el.emissions,
    [t(
      "regagri:cultivationDetails.form.report.charts.emisson.netEmissions"
    )]: el.net_emissions,
    [t(
      "regagri:cultivationDetails.form.report.charts.emisson.baseline"
    )]: el.emissions_baseline,
  }));

  const productsSoilOrganicMatter = [
    {
      color: "#458FFF",
      id: 1,
      key: t(
        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.potential"
      ),
    },
    {
      color: "#FFA203",
      id: 2,
      key: t(
        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.baseline"
      ),
    },
    {
      color: "#3CC13B",
      id: 3,
      key: t(
        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.som"
      ),
    },
  ];

  const soilOrganicMatterData = chart.map(el => ({
    name: el.year,
    [t(
      "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.potential"
    )]: el.som_potential,
    [t(
      "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.baseline"
    )]: el.som_baseline,
    [t(
      "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.som"
    )]: el.som,
  }));

  const renderChart = (chartData, products, key) => {
    return (
      <ResponsiveContainer width="100%" height={!key ? 400 : 300}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
          onClick={() => {
            if (isModalOpen === 0 && key) {
              setIsModalOpen(key);
            }
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {!isMobile && <Tooltip />}
          <XAxis dataKey="name" />
          <YAxis />
          <Legend />
          {products.map(product => (
            <Line
              type="monotone"
              key={product.key}
              dataKey={product.key}
              stroke={product.color}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const renderTooltip = id => {
    if (getProperTooltip(t, id) !== "") {
      return <RegagriTooltip content={getProperTooltip(t, id)} />;
    }
    return <></>;
  };

  return (
    <div className="regagri-report">
      {isMobile && (
        <Modal
          modalClassName="regagri-report--full-screen-chart"
          isOpen={isModalOpen > 0}
          onRequestClose={() => setIsModalOpen(0)}
          centered
          style={{
            overlay: {
              zIndex: "1000",
              backgroundColor: "rgba(0,0,0,0.6)",
              overflowY: "scroll",
            },
            content: {
              maxWidth: "100vh",
              width: "auto",
              margin: "auto",
              borderRadius: 0,
              bottom: "unset",
              overflowX: "hidden",
              backgroundColor: "#f1f1f1",
            },
          }}
        >
          {(() => {
            switch (isModalOpen) {
              case 1:
                return (
                  <>
                    <RegagriLabel
                      className="headline"
                      label={t(
                        "regagri:cultivationDetails.form.report.charts.emisson.title"
                      )}
                    />
                    {renderChart(emissionData, productsEmission)}
                    <span className="legend">
                      {t(
                        "regagri:cultivationDetails.form.report.charts.emisson.year"
                      )}
                    </span>
                    <span className="unit">
                      {t(
                        "regagri:cultivationDetails.form.report.charts.emisson.unit"
                      )}
                    </span>
                  </>
                );
              case 2:
                return (
                  <>
                    <RegagriLabel
                      className="headline"
                      label={t(
                        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.title"
                      )}
                    />
                    {renderChart(
                      soilOrganicMatterData,
                      productsSoilOrganicMatter
                    )}
                    <span className="legend">
                      {t(
                        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.year"
                      )}
                    </span>
                    <span className="unit">
                      {t(
                        "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.unit"
                      )}
                    </span>
                  </>
                );
              default:
                return <></>;
            }
          })()}
          <button
            type="button"
            className="tools-page__modal--close"
            onClick={() => setIsModalOpen(0)}
          >
            <CloseIcon />
          </button>
        </Modal>
      )}
      <RegagriSection>
        <RegagriFormControl variant="full" alignLeft>
          <h3>{t("regagri:cultivationDetails.form.report.title")}</h3>
          <div className="details">
            <RegagriLabel
              className="headline"
              label={t("regagri:cultivationDetails.form.report.details.title")}
            />
            <p>
              <strong>
                {t("regagri:cultivationDetails.form.report.details.name")}:
              </strong>
              {name}
            </p>
            <p>
              <strong>
                {t("regagri:cultivationDetails.form.report.details.size")}:
              </strong>
              {size} {t("regagri:cultivationDetails.form.report.details.unit")}
            </p>
            <p>
              <strong>
                {t("regagri:cultivationDetails.form.report.details.soilType")}:
              </strong>
              {soilType.map(
                (soil, index) =>
                  `${
                    language === "pl"
                      ? t(
                          `regagri:cultivationDetails.form.soilTypes.names.${
                            soil.id
                          }`
                        )
                      : soil.name
                  } ${soil.area}% ${soilType.length - 1 === index ? "" : "| "}`
              )}
            </p>
          </div>
          <div className="explorer">
            <RegagriLabel
              className="headline"
              label={t("regagri:cultivationDetails.form.report.explorer.title")}
            />
            <Row>
              <Col xs={12} md={4}>
                <div className="custom-border">
                  <h4>
                    {t(
                      "regagri:cultivationDetails.form.report.explorer.reductionPotential.title"
                    )}
                  </h4>
                  <p className="number">{reductionPotential.value}</p>
                  <p className="unit">
                    {t(
                      "regagri:cultivationDetails.form.report.explorer.reductionPotential.unit"
                    )}
                  </p>
                  <Row>
                    <Col xs={6}>
                      <h6>
                        {t(
                          "regagri:cultivationDetails.form.report.explorer.reductionPotential.reduction"
                        )}
                      </h6>
                      <p className="number">{reductionPotential.reduction}</p>
                      <p className="unit">
                        {t(
                          "regagri:cultivationDetails.form.report.explorer.reductionPotential.unit"
                        )}
                      </p>
                    </Col>
                    <Col xs={6}>
                      <h6>
                        {t(
                          "regagri:cultivationDetails.form.report.explorer.reductionPotential.removals"
                        )}
                      </h6>
                      <p className="number">{reductionPotential.removals}</p>
                      <p className="unit">
                        {t(
                          "regagri:cultivationDetails.form.report.explorer.reductionPotential.unit"
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="custom-border">
                  <h4>
                    {t(
                      "regagri:cultivationDetails.form.report.explorer.income.title"
                    )}
                  </h4>
                  <h6>
                    {t(
                      "regagri:cultivationDetails.form.report.explorer.income.reduction"
                    )}
                  </h6>
                  <p className="number">{income.reduction}</p>
                  <p className="unit">
                    {t(
                      "regagri:cultivationDetails.form.report.explorer.income.unit"
                    )}
                  </p>
                </div>
              </Col>
              {climate.length && climate.temp ? (
                <Col xs={12} md={4}>
                  <div className="custom-border">
                    <h4>
                      {t(
                        "regagri:cultivationDetails.form.report.explorer.climate.title"
                      )}
                    </h4>
                    <Row>
                      <Col xs={6}>
                        <h6>
                          {t(
                            "regagri:cultivationDetails.form.report.explorer.climate.length.title"
                          )}
                        </h6>
                        <p className="number">{climate.length}</p>
                        <p
                          className="unit"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "regagri:cultivationDetails.form.report.explorer.climate.length.unit"
                            ),
                          }}
                        />
                      </Col>
                      <Col xs={6}>
                        <h6>
                          {t(
                            "regagri:cultivationDetails.form.report.explorer.climate.temp.title"
                          )}
                        </h6>
                        <p className="number">{climate.temp}</p>
                        <p
                          className="unit"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: t(
                              "regagri:cultivationDetails.form.report.explorer.climate.temp.unit"
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
          <div className="emissions">
            <RegagriLabel
              className="headline"
              label={t(
                "regagri:cultivationDetails.form.report.emissions.title"
              )}
            />
            {carbonInputsLangMapper(language === "pl" ? t : undefined, {
              soil_types: [],
              practices: potential,
            }).practices.map(el => {
              return (
                <section className="table">
                  <h4>{el.name}</h4>
                  {el.practices.map(p => {
                    return (
                      <div>
                        <p>
                          {p.name}
                          {renderTooltip(p.id)}
                        </p>
                        <p>{p.value}%</p>
                      </div>
                    );
                  })}
                </section>
              );
            })}
          </div>
          <div className="charts">
            <div className="emission">
              <RegagriLabel
                className="headline"
                label={t(
                  "regagri:cultivationDetails.form.report.charts.emisson.title"
                )}
              />
              <span className="unit">
                {t(
                  "regagri:cultivationDetails.form.report.charts.emisson.unit"
                )}
              </span>
              {renderChart(emissionData, productsEmission, 1)}
              <span className="legend">
                {t(
                  "regagri:cultivationDetails.form.report.charts.emisson.year"
                )}
              </span>
            </div>
            <div className="soil-ogranic-matter">
              <RegagriLabel
                className="headline"
                label={t(
                  "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.title"
                )}
              />
              <span className="unit">
                {t(
                  "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.unit"
                )}
              </span>
              {renderChart(soilOrganicMatterData, productsSoilOrganicMatter, 2)}
              <span className="legend">
                {t(
                  "regagri:cultivationDetails.form.report.charts.soilOrganicMatter.year"
                )}
              </span>
            </div>
          </div>
          <div className="more">
            <RegagriLabel
              className="headline"
              label={
                <div>
                  {t(
                    "regagri:cultivationDetails.form.report.more.regenerativeFarming.title"
                  )}{" "}
                  <a
                    href="https://300codes.agronomist.pl/artykuly/brakuje-wiedzy-na-temat-rolnictwa-regeneracyjnego"
                    target="_blank"
                    rel="nofolow noopener noreferrer"
                  >
                    {t(
                      "regagri:cultivationDetails.form.report.more.regenerativeFarming.link"
                    )}
                  </a>
                </div>
              }
            />
            <p className="description">
              {t("regagri:cultivationDetails.form.report.more.description")}
            </p>
          </div>
        </RegagriFormControl>
      </RegagriSection>
    </div>
  );
};

export default RegagriReport;

import React from "react";
import PropTypes from "prop-types";
import "./Typography.scss";

// typographyType - props value beetwen h1-h5"

export const Typography = props => {
  switch (props.typographyType) {
    case "h1":
      return (
        <h1 className={`typography--${props.typographyType}`}>
          {props.children}
        </h1>
      );
    case "h2":
      return (
        <h2 className={`typography--${props.typographyType}`}>
          {props.children}
        </h2>
      );
    case "h3":
      return (
        <h3 className={`typography--${props.typographyType}`}>
          {props.children}
        </h3>
      );
    case "h4":
      return (
        <h4 className={`typography--${props.typographyType}`}>
          {props.children}
        </h4>
      );
    case "h5":
      return (
        <h5 className={`typography--${props.typographyType}`}>
          {props.children}
        </h5>
      );
    default:
      return (
        <p className={`typography--${props.typographyType}`}>
          {props.children}
        </p>
      );
  }
};

Typography.propTypes = {
  children: PropTypes.node,
  typographyType: PropTypes.string,
};

Typography.defaultProps = {
  children: null,
  typographyType: "h2",
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftTank,
  CftActionsButtons,
  CftReportNavigation,
  CftExportReport,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import WaterFootprintGraph from "../graphs/WaterFootprintGraph";

function ReportWaterFootprintPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { waterFootprintReport } = useCft();

  return (
    <CftLayout
      header={
        <>
          <CftHeader />
          {isMobile && (
            <div
              style={{
                position: "fixed",
                top: "1%",
                zIndex: "1000",
                right: "1%",
              }}
            >
              <CftExportReport type="waterFootprint" isSmall noMargin />
            </div>
          )}
          <CftReportNavigation type="waterFootprint" />
        </>
      }
    >
      <CftSection
        isReport
        header={
          <CftTank
            className="report-hero"
            flexDirection="column"
            alignItems="flex-start"
          >
            <CftActionsButtons.GoToCalc
              onClick={() =>
                history.push(routes.FARMLANDS.WATER_FOOTPRINT.MAIN)
              }
            />
            <h3>{t("cft:reportWaterFootprint.title")}</h3>
            <p className="full-width">{waterFootprintReport.crop.name}</p>
          </CftTank>
        }
      >
        <WaterFootprintGraph type="waterFootprint" />
      </CftSection>
    </CftLayout>
  );
}

export default ReportWaterFootprintPage;

import React from "react";
import PropTypes from "prop-types";
import "./ButtonAdditional.scss";
import { ArrowIcon } from "../index";

// buttoncolor - props value "grey" or "green"

export const ButtonAdditional = props => {
  return (
    <div
      className={`buttonAdditional--${props.buttoncolor} ${props.className}`}
      onClick={props.onClick}
      role="button"
      tabIndex={0}
    >
      <p>{props.children}</p>
      <ArrowIcon
        color={props.iconColor}
        selected={props.iconSelected}
        onClick={props.onClickIcon}
      />
    </div>
  );
};

ButtonAdditional.propTypes = {
  className: PropTypes.string,
  buttoncolor: PropTypes.string.isRequired,
  children: PropTypes.node,
  iconColor: PropTypes.string,
  iconSelected: PropTypes.bool,
  onClickIcon: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

ButtonAdditional.defaultProps = {
  className: "",
  children: null,
  iconColor: "#9B9B9B",
  iconSelected: false,
};

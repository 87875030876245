import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftTooltip,
  CftErrorBoxForm,
  CftClearForm,
  CftFormErrorFocus,
} from "../../components";
import API from "../../../services/apiHelpers";
import routes from "../../utils/cftRoutes";
import { parseError } from "../../utils/cftUtils";
import useCftPigsValues from "../../utils/useCftPigsValues";

export default function PigsMainForm() {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const { store, updateStore } = useCftPigsValues();
  const history = useHistory();

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2 className="cft-section-title mb-3">
          {t("cftTurkey:form.main.title", "Informacje o produkcji")}
        </h2>
        <h4 className="cft-section-subtitle mb-7">
          {t(
            "cftTurkey:form.main.subtitle",
            "Podaj podstawowe informacje o swojej produkcji"
          )}
        </h4>
        <Formik
          touched
          enableReinitialize
          initialValues={store.main}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            postal_code: Yup.string()
              .matches(
                /\d{2}-\d{3}?$/,
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.postalCodeErrorMatch",
                  "Podaj poprawy kod pocztowy."
                )
              )
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
            calculation_period_length: Yup.number()
              .typeError(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                  "Podaj wartość liczbową"
                )
              )
              .integer(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                  "Podaj wartość całkowitą"
                )
              )
              .min(
                2,
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.calculationPeriodLengthErrorMin",
                  "Wartość minimalna to 2."
                )
              )
              .max(
                365,
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.calculationPeriodLengthErrorMax",
                  "Wartość maksymalna to 365."
                )
              )
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
          })}
          onSubmit={async (values, helpers) => {
            try {
              await API().post(
                "/api/v1/ae/pigs/entry-data-validation/",
                values,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                main: { ...values, isValidate: true },
              });
              history.push(routes.PIGS.COOPS);
            } catch (error) {
              helpers.setErrors(parseError(error.response.data));
            }
          }}
        >
          <Form style={{ width: "100%" }}>
            <div className="cft-paper">
              <CftFormControl alignLeft isAlternative>
                <CftLabel
                  label={t(
                    "cftTurkey:chickenBroilerMainForm.fields.name",
                    "Nazwa"
                  )}
                  isFullWidth
                />
                <CftTextField
                  isRounded
                  name="name"
                  placeholder={t(
                    "cftTurkey:chickenBroilerMainForm.fields.namePlaceholder",
                    "Wpisz nazwę"
                  )}
                  isAlternative
                />
              </CftFormControl>
              <CftFormControl alignLeft isAlternative>
                <CftLabel
                  label={t(
                    "cftTurkey:chickenBroilerMainForm.fields.postalCodeLabel",
                    "Lokalizacja (kod pocztowy)"
                  )}
                  isFullWidth
                />
                <CftTextField
                  mask="00-000"
                  isRounded
                  name="postal_code"
                  placeholder={t(
                    "cftTurkey:chickenBroilerMainForm.fields.postalCodePlaceholder",
                    "Wpisz kod pocztowy"
                  )}
                  isAlternative
                />
              </CftFormControl>
              <CftFormControl alignLeft isAlternative>
                <CftLabel
                  label={t(
                    "cftTurkey:chickenBroilerMainForm.fields.calculationPeriodLengthLabel",
                    "Długość okresu obliczeniowego (dni)"
                  )}
                  isFullWidth
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.calculationPeriodLength",
                      "Okres obliczeniowy to całkowity okres, dla którego chcesz obliczyć wartość emisji Twojej hodowli. Pamiętaj! Minimalna długość okresu obliczeniowego to suma długości jednego cyklu produkcyjnego"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  mask="000"
                  isRounded
                  name="calculation_period_length"
                  placeholder={t(
                    "cftTurkey:chickenBroilerMainForm.fields.calculationPeriodLengthPlaceholder",
                    "Wpisz dni"
                  )}
                  isAlternative
                />
              </CftFormControl>
              <CftErrorBoxForm
                text={t(
                  "cftTurkey:form.error.main",
                  "Wypełnij brakujące pola żeby przejść dalej"
                )}
              />
            </div>
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftPigs:pigsMainForm.fields.submit",
                  "podaj informacje o stadzie i paszach"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="pigs" />
    </div>
  );
}

import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import CftWarningBox from "../CftWarningBox/CftWarningBox";

const CftCalculationPeriodInfo = () => {
  const { values } = useFormikContext();
  const { store } = useCftChickenBroilerValues();
  const { t } = useTranslation(["cftChickenBroiler"]);

  const calculationPeriodLength = Number(store.main.calculation_period_length);

  if (values.production_cycles.length) {
    const wholeCalculationPeriodLength = values.production_cycles
      .map(productionCycle => {
        if (!productionCycle.repetitions) {
          return 0;
        }

        if (
          !productionCycle.production_cycle_length ||
          !productionCycle.technological_break_length
        ) {
          return 0;
        }

        return (
          (Number(productionCycle.technological_break_length) +
            Number(productionCycle.production_cycle_length)) *
          Number(productionCycle.repetitions)
        );
      })
      .reduce((prev, acc) => prev + acc, 0);

    if (wholeCalculationPeriodLength > calculationPeriodLength) {
      return (
        <CftWarningBox
          text={t("cftChickenBroiler:errors.calculationPeriodLength", {
            calculationPeriodLength,
            wholeCalculationPeriodLength,
          })}
        />
      );
    }

    return null;
  }

  return null;
};

export default CftCalculationPeriodInfo;

import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Disclaimer,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import imageUrl from "../assets/images/kredyt_1.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Finansowanie zakupu i montażu instalacji fotowoltaicznej"
    ),
    title: "Finansowanie zakupu i montażu instalacji fotowoltaicznej",
    description:
      "Chciałbyś zakupić instalację fotowoltaiczną i wykorzystywać wyprodukowaną energię w gospodarstwie? Masz dotację na tą inwestycję? Szukasz dodatkowych środków?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Jeśli chcesz założyć panele fotowoltaiczne w swoim gospodarstwie, ale brakuje Ci środków, skorzystaj z naszej oferty na sfinansowanie tej inwestycji.",
    benefits: [
      {
        title: "Niski udział własny",
        description: "Od 0%",
        icon: "Percent",
      },
      {
        title: "Dogodny sposób spłaty",
        description:
          "Spłata kapitału dostosowana do wpływów z produkcji oraz możliwa karencja w spłacie kapitału do 2 lat",
        icon: "MoneyPlus",
      },
      {
        title: "Proste procedury",
        description: "Nie wymagany biznes plan",
        icon: "TickRounded",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się ze zrealizowanej inwestycji w Twoim gospodarstwie!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='https://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    // footer: {
    //   text: "Przedmiot inwestycji możesz ubezpieczyć",
    //   link: "/kredytomat/ubezpieczenie-odnawialnych-zrodel-energii",
    // },
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Minimalny wkład własny",
      "Zabezpieczenie",
      "Karencja w spłacie kapitału",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        isRecommended: true,
        title: "Pożyczka Europejska Fotowoltaika",
        list: [
          "Szybka decyzja, finansowanie kwoty brutto",
          "7 lat",
          "brak",
          "0%",
          "Zastaw rejestrowy, cesja z umowy o dofinansowanie, weksel",
          "Brak",
          "Raty miesięczne lub kwartalne lub półroczne lub roczne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Pożyczka Europejska Fotowoltaika`;
          },
        },
      },
      {
        title: "Unia+",
        list: [
          "Długi okres kredytowania",
          "15 lat",
          "brak",
          "0%",
          "Zastaw rejestrowy, cesja z umowy o dofinansowanie",
          "Do 2 lat",
          "Raty miesięczne lub kwartalne lub półroczne lub roczne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Unia+`;
          },
        },
      },
    ],
  },
};

const KredytyNaInstalacjePVZDotacja = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyNaInstalacjePVZDotacja;

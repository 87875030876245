/* eslint-disable func-names */
import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftFormErrorFocus,
  CftButton,
  CftTank,
  CftConditionalRendering,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";

import LandManagement from "./LandManagement";
// import ConvertedFields from "./ConvertedFields";
// import CatchFields from "./CatchFields";
// import PracticesFields from "./PracticesFields";
import { initManagementPractice } from "./carbonChanges.consts";
import { farmlandsAPI } from "../../../utils/cftApi";

function CarbonChangesForm() {
  const { t } = useTranslation(["cft"]);
  const { values, setFieldValue } = useFormikContext();
  const { farmlands } = useCft();
  const history = useHistory();

  if (farmlands.soil.averageSoilMoisture === "") {
    return (
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:carbonChanges.form.1", "Brak wymaganych danych")}
          <small>
            {t(
              "cft:carbonChanges.form.2",
              "Aby rozpocząć wypełnianie danych dla Zmian węglowych musisz uzupełnić informację dotyczące Charakterystyki gleby"
            )}
          </small>
        </h3>
        <CftTank display="flex" justifyContent="space-around">
          <CftButton onClick={() => history.push(routes.FARMLANDS.SOIL)}>
            {t("cft:soil.title", "Charakterystyka gleby")}
          </CftButton>
        </CftTank>
      </CftFormControl>
    );
  }

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.3",
            "Czy w ciągu ostatnich 20 lat jakakolwiek część tego pola została przekształcona z lub na grunt orny, łąkę lub las?"
          )}
        />
        <CftFormField name="converted">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("managementPractice", initManagementPractice);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("managementPractice", null);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.converted}>
        <LandManagement soilMoistureId={farmlands.soil.averageSoilMoisture} />
      </CftConditionalRendering>
      <CftFormErrorFocus />
    </Form>
  );
}

export default CarbonChangesForm;

export function CarbonChangesFormContext({ children }) {
  const history = useHistory();
  const { farmlands, saveFarmlands, saveFarmlandsReport } = useCft();
  const { t } = useTranslation(["cft", "cftTurkey", "cftPigs"]);
  const currentYear = new Date().getFullYear();

  return (
    <Formik
      enableReinitialize
      initialValues={farmlands.carbonChanges}
      validationSchema={Yup.object().shape({
        managementPractice: Yup.object().when("converted", {
          is: v => v === true,
          then: Yup.object().shape({
            carbon_changes: Yup.array().of(
              Yup.object().shape({
                year_of_change: Yup.number()
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftPigs:validationSchema.min",
                      "Wartość minimalna to {{min}}.",
                      { min: 1 }
                    )
                  )
                  .min(
                    currentYear - 250,
                    t(
                      "cftPigs:validationSchema.min",
                      "Wartość minimalna to {{min}}.",
                      { min: currentYear - 250 }
                    )
                  )
                  .max(
                    currentYear,
                    t(
                      "cftPigs:validationSchema.max",
                      "Wartość maksymalna to {{max}}.",
                      { max: currentYear }
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cft:carbonChanges.nextError"),
                    function(value, form) {
                      const [, carbonChanges] = this.from;
                      const key = Number(
                        form.path.replace(/(^.*\[|\].*$)/g, "")
                      );

                      if (key === 0) {
                        return true;
                      }

                      const yearOfChange =
                        carbonChanges.value.carbon_changes[key - 1]
                          .year_of_change;

                      if (!!yearOfChange && !!value) {
                        return value < yearOfChange;
                      }

                      return true;
                    }
                  )
                  .nullable(),
                allocation: Yup.number()
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftPigs:validationSchema.min",
                      "Wartość minimalna to {{min}}.",
                      { min: 1 }
                    )
                  )
                  .max(
                    100,
                    t(
                      "cftPigs:validationSchema.max",
                      "Wartość maksymalna to {{max}}.",
                      { max: 100 }
                    )
                  )
                  .nullable(),
                land_use: Yup.object().shape({
                  land_from: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .nullable(),
                  land_to: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .test(
                      "is-right-land_to",
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      ),
                      function(value, form) {
                        const [, , carbonChanges] = this.from;
                        const key = Number(
                          form.path.replace(/(^.*\[|\].*$)/g, "")
                        );

                        if (key === 0) {
                          return true;
                        }

                        const landFromParent =
                          carbonChanges.value.carbon_changes[key - 1].land_use
                            .land_from;

                        if (!!landFromParent && !!value) {
                          return value === landFromParent;
                        }

                        return true;
                      }
                    )
                    .nullable(),
                }),
                tillage: Yup.object().shape({
                  tillage_from: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .nullable(),
                  tillage_to: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .test(
                      "is-right-land_to",
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      ),
                      function(value, form) {
                        const [, , carbonChanges] = this.from;
                        const key = Number(
                          form.path.replace(/(^.*\[|\].*$)/g, "")
                        );

                        if (key === 0) {
                          return true;
                        }

                        const landFromParent =
                          carbonChanges.value.carbon_changes[key - 1].tillage
                            .tillage_from;

                        if (!!landFromParent && !!value) {
                          return value === landFromParent;
                        }

                        return true;
                      }
                    )
                    .nullable(),
                }),
                inputs: Yup.object().shape({
                  inputs_from: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .nullable(),
                  inputs_to: Yup.number()
                    .required(
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .test(
                      "is-right-land_to",
                      t(
                        "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      ),
                      function(value, form) {
                        const [, , carbonChanges] = this.from;
                        const key = Number(
                          form.path.replace(/(^.*\[|\].*$)/g, "")
                        );

                        if (key === 0) {
                          return true;
                        }

                        const landFromParent =
                          carbonChanges.value.carbon_changes[key - 1].inputs
                            .inputs_from;

                        if (!!landFromParent && !!value) {
                          return value === landFromParent;
                        }

                        return true;
                      }
                    )
                    .nullable(),
                }),
              })
            ),
            forest_biomass: Yup.object().when("carbon_changes", {
              is: carbon_changes =>
                carbon_changes.reduce((prev, crr) => {
                  if (
                    [6].includes(crr.land_use.land_from) ||
                    [6].includes(crr.land_use.land_to)
                  ) {
                    return true;
                  }

                  return prev;
                }, false),
              then: Yup.object().shape({
                forest_type: Yup.number()
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .nullable(),
                age_years: Yup.number()
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  )
                  .nullable(),
              }),
              otherwise: Yup.object().nullable(),
            }),
          }),
          otherwise: Yup.object().nullable(),
        }),
      })}
      onSubmit={values => {
        saveFarmlands({ carbonChanges: values });
        farmlandsAPI
          .carbonChangesValidation({
            land_management: values.converted ? values.managementPractice : {},
          })
          .then(({ data }) => {
            saveFarmlandsReport(data);
            history.push(routes.FARMLANDS.TRANSPORT);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import properties from "../../../../../services/redux/properties";
import { ConditionalRender } from "../../../../common";
import { ReactComponent as PinIcon } from "../../../../../assets/icons/pin.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/icons/expand.svg";
import { ReactComponent as BrowserIcon } from "../../../../../assets/icons/browser.svg";

import "./PromotedProperties.scss";

function PromotedProperties() {
  const promotedPropertiesList = useSelector(
    properties.selectors.selectPromotedProperty
  );

  if (promotedPropertiesList.length === 0) {
    return null;
  }
  return (
    <Row className="suggested-properties">
      <Col className="suggested-properties__header" xs={12}>
        <h3>
          Zobacz Promowane oferty{" "}
          <Link to="/nieruchomosci/wyszukiwarka">zobacz wszystkie</Link>
        </h3>
      </Col>
      {promotedPropertiesList.map((prop, key) => (
        <Col key={key} xs={12} md={3}>
          <Link
            className="suggested-properties__single-link"
            to={`/nieruchomosci/${prop.slug}`}
          >
            <div className="suggested-properties__single single-property">
              <div
                className="single-property__thumbnail"
                style={{
                  backgroundImage: `url("${prop.thumbnail_link}")`,
                }}
              >
                {prop.promoted && (
                  <span className="single-property__promoted">Promowane</span>
                )}
              </div>
              <div className="single-property__content">
                <Row>
                  <Col xs={12}>
                    <h4 className="single-property__content__title">
                      {prop.name}
                    </h4>
                    <div className="single-property__content__address">
                      <PinIcon />
                      <span>
                        {[
                          prop.town_name,
                          prop.borough_name,
                          prop.county_name,
                          prop.province_name,
                        ]
                          .filter(address => !!address)
                          .join(", ")}
                      </span>
                    </div>
                    <div className="single-property__content__area">
                      <ConditionalRender
                        isConditionFulfilled={!!prop.land_area}
                      >
                        <span>
                          <ExpandIcon />
                          {prop.land_area} m<sup>2</sup>
                        </span>
                      </ConditionalRender>
                      <ConditionalRender
                        isConditionFulfilled={!!prop.local_area}
                      >
                        <span>
                          <BrowserIcon />
                          {prop.local_area} m<sup>2</sup>
                        </span>
                      </ConditionalRender>
                    </div>
                    {/* <p className="single-property__content__properties">
                      <ConditionalRender
                        isConditionFulfilled={!!propertyType.name}
                      >
                        <div>
                          <span />
                          {propertyType.name}
                        </div>
                      </ConditionalRender>
                      <ConditionalRender
                        isConditionFulfilled={!!transactionType.name}
                      >
                        <div>
                          <span />
                          {transactionType.name}
                        </div>
                      </ConditionalRender>
                    </p> */}
                  </Col>
                  <Col xs={12} className="single-property__content--price">
                    <div className="single-property__content__price">
                      {prop.price.toLocaleString()} zł
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
}

export default PromotedProperties;

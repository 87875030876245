import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftFormErrorFocus,
  CftButton,
  CftTank,
  CftConditionalRendering,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";

import ConvertedFields from "./ConvertedFields";
import CatchFields from "./CatchFields";
import PracticesFields from "./PracticesFields";
import {
  initManagementPractice,
  initCover,
  initTillage,
} from "./carbonChangesBothCalc.consts";
import { farmlandsAPI } from "../../../../utils/cftApi";

function CarbonChangesBothCalcForm() {
  const { t } = useTranslation(["cft"]);
  const { values, setFieldValue } = useFormikContext();
  const { bothCalc } = useCft();
  const history = useHistory();

  if (bothCalc.soil.averageSoilMoisture === "") {
    return (
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:carbonChanges.form.1", "Brak wymaganych danych")}
          <small>
            {t(
              "cft:carbonChanges.form.2",
              "Aby rozpocząć wypełnianie danych dla Zmian węglowych musisz uzupełnić informację dotyczące Charakterystyki gleby"
            )}
          </small>
        </h3>
        <CftTank display="flex" justifyContent="space-around">
          <CftButton
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.SOIL)}
          >
            {t("cft:soil.title", "Charakterystyka gleby")}
          </CftButton>
        </CftTank>
      </CftFormControl>
    );
  }

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.3",
            "Czy w ciągu ostatnich 20 lat jakakolwiek część tego pola została przekształcona z lub na grunt orny, łąkę lub las?"
          )}
        />
        <CftFormField name="converted">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("managementPractice", initManagementPractice);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("managementPractice", undefined);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.converted}>
        <ConvertedFields
          name="managementPractice"
          soilMoistureId={bothCalc.soil.averageSoilMoisture}
        />
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.4",
            "Czy w ciągu ostatnich 20 lat zmieniłeś praktyki uprawy na tym polu?"
          )}
        />
        <CftFormField name="practices">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("managementPracticeTillage", initTillage);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("managementPracticeTillage", undefined);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.practices}>
        <PracticesFields
          name="managementPracticeTillage"
          soilMoistureId={bothCalc.soil.averageSoilMoisture}
        />
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.5",
            "Czy w ciągu ostatnich 20 lat rozpocząłeś lub zaprzestałeś uprawy okrywowe lub międzyplon?"
          )}
        />
        <CftFormField name="catched">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("managementCoverChange", initCover);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("managementCoverChange", undefined);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.catched}>
        <CatchFields
          name="managementCoverChange"
          soilMoistureId={bothCalc.soil.averageSoilMoisture}
        />
      </CftConditionalRendering>
      <CftFormErrorFocus />
    </Form>
  );
}

export default CarbonChangesBothCalcForm;

export function CarbonChangesBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);

  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.carbonChanges}
      validationSchema={Yup.object().shape({
        managementPractice: Yup.object().when("converted", {
          is: v => v === true,
          then: Yup.object().shape({
            landUseBase: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            landUse: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            forestType: Yup.mixed().when(["landUseBase", "landUse"], {
              is: (v1, v2) =>
                ["10", "18", "forest"].indexOf(v1) !== -1 ||
                ["10", "18", "forest"].indexOf(v2) !== -1,
              then: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              otherwise: Yup.mixed().nullable(),
            }),
            forestYear: Yup.mixed().when(["landUseBase", "landUse"], {
              is: (v1, v2) =>
                ["10", "18", "forest"].indexOf(v1) !== -1 ||
                ["10", "18", "forest"].indexOf(v2) !== -1,
              then: Yup.string()
                .trim()
                .required(t("cft:validation.required", "Wpisz wartość")),
              otherwise: Yup.mixed().nullable(),
            }),
            yearsAgo: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            landUseAllocation: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
          }),
          otherwise: Yup.object().nullable(),
        }),
        managementPracticeTillage: Yup.object().when("practices", {
          is: v => v === true,
          then: Yup.object().shape({
            tillageChange: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            tillageYearsAgo: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            tillageAllocation: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
          }),
          otherwise: Yup.object().nullable(),
        }),
        managementCoverChange: Yup.object().when("catched", {
          is: v => v === true,
          then: Yup.object().shape({
            coverChange: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            coverYearsAgo: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            coverAllocation: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
          }),
          otherwise: Yup.object().nullable(),
        }),
      })}
      onSubmit={values => {
        saveBothCalc({ carbonChanges: values });
        farmlandsAPI
          .carbonChangesValidation({
            land_management: values.converted ? values.managementPractice : {},
          })
          .then(({ data }) => {
            saveBothCalcReport(data);
            history.push(routes.FARMLANDS.BOTH_CALC.TRANSPORT);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import React, { useEffect, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { reduce } from "ramda";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import API from "../../../../../services/apiHelpers";
import properties from "../../../../../services/redux/properties";
import { SEARCH_LIMIT } from "../../../../../services/redux/properties/properties.actions";
import {
  Input,
  Select,
  SelectAsync,
  Button,
  CustomSelectOption,
  LocationInput,
  CustomLocationInput,
} from "../RealEstateInputs/RealEstateInputs";
import { getSearchLabel } from "../../utils/realEstateUtils";
import "./RealEstateSearch.scss";

const formatGroupLabel = data => (
  <div className="real-estate-select__format-group-label">
    <span>{data.label}</span>
    <span className="real-estate-select__format-group-label__count">
      {data.options.length}
    </span>
  </div>
);

function RealEstateSearch({ isLPMode = false }) {
  const form = useRef(null);
  const [locations, setLocations] = React.useState([]);
  const { t, i18n } = useTranslation("realEstate");
  const location = useLocation();
  const history = useHistory();
  const search = queryString.parse(location.search);
  const dispatch = useDispatch();
  const langCode = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : i18n.language.toUpperCase();

  const propertyTypes = useSelector(properties.selectors.selectPropertyTypes);
  const provinces = useSelector(properties.selectors.selectProvinces);
  const propertyTypesOptions = propertyTypes.map(type => ({
    label: type.name,
    value: type.id,
  }));

  const checkLocations = async () => {
    let defaultProvinces = [];
    let defaultBoroughs = [];
    let defaultCounties = [];
    let defaultTowns = [];

    if (search.provinces_list) {
      defaultProvinces = await API()
        .get(properties.api.PROVINCES, {
          params: {
            ids: search.provinces_list,
          },
        })
        .then(({ data }) => {
          return data.results.map(({ name, id }) => ({
            label: name,
            value: id,
            type: "provinces_list",
          }));
        })
        .catch(() => {
          return [];
        });
    }

    if (search.boroughs_list) {
      defaultBoroughs = await API()
        .get(properties.api.BOROUGHS, {
          params: {
            ids: search.boroughs_list,
            lang_code: langCode,
          },
        })
        .then(({ data }) => {
          return data.results.map(({ name, id }) => ({
            label: name,
            value: id,
            type: "boroughs_list",
          }));
        })
        .catch(() => {
          return [];
        });
    }

    if (search.counties_list) {
      defaultCounties = await API()
        .get(properties.api.COUNTIES, {
          params: {
            ids: search.counties_list,
            lang_code: langCode,
          },
        })
        .then(({ data }) => {
          return data.results.map(({ name, province_name, id }) => ({
            label: getSearchLabel([name, province_name]),
            value: id,
            type: "counties_list",
          }));
        })
        .catch(() => {
          return [];
        });
    }

    if (search.towns_list) {
      defaultTowns = await API()
        .get(properties.api.COUNTIES, {
          params: {
            ids: search.towns_list,
            lang_code: langCode,
          },
        })
        .then(({ data }) => {
          return data.results.map(
            ({ name, id, county_name, province_name }) => ({
              label: getSearchLabel([name, county_name, province_name]),
              value: id,
              type: "towns_list",
            })
          );
        })
        .catch(() => {
          return [];
        });
    }

    const defaultLocations = [
      ...defaultProvinces,
      ...defaultBoroughs,
      ...defaultCounties,
      ...defaultTowns,
    ];

    if (defaultLocations.length > 0) {
      return setLocations(defaultLocations);
    }
    return null;
  };

  useEffect(() => {
    if (propertyTypes.length === 0) {
      dispatch(properties.actions.getTransactionTypes());
      dispatch(properties.actions.getPropertyTypes());
      dispatch(properties.actions.getProvince());
    }

    if (locations.length === 0) {
      checkLocations();
    }

    if (!search.offset) {
      API()
        .get(properties.api.PROMOTED_PROPERTIES, {
          params: {
            ...search,
            limit: isLPMode ? 4 : 3,
            lang_code: langCode,
          },
        })
        .then(({ data }) => {
          dispatch(properties.actions.setPromotedProperties(data));
          const excluded_ids = reduce(
            (acc, elem) => {
              return [...acc, elem.id];
            },
            [],
            data
          ).join(",");
          if (!isLPMode) {
            API()
              .get(properties.api.PROPERTIES, {
                params: {
                  ordering: "-published_at",
                  limit: SEARCH_LIMIT,
                  excluded_ids,
                  lang_code: langCode,
                  ...search,
                },
              })
              .then(({ data }) => {
                dispatch(properties.actions.setProperties(data));
              });
          }
        });
    } else {
      dispatch(properties.actions.getProperties(search));
    }
  }, [location]);

  const isFilterClearable = () => {
    if (Object.keys(search).length === 1 && search.offset) {
      return false;
    }
    return location.search.length !== 0;
  };

  const onSearch = (params, clear = false) => {
    if (clear) {
      return history.push(
        `${location.pathname}?${queryString.stringify(params, {
          skipNull: true,
          skipEmptyString: true,
          arrayFormat: "comma",
        })}`
      );
    }
    return history.push(
      `${location.pathname}?${queryString.stringify(
        { ...search, ...params },
        {
          skipNull: true,
          skipEmptyString: true,
          arrayFormat: "comma",
        }
      )}`
    );
  };

  const onKeyDown = e => {
    if (e.keyCode === 13 || e.charCode === 13) {
      e.target.blur();
    }
  };

  const loadOptions = async inputValue => {
    const [boroughs, counties, towns, provinces] = await axios.all([
      API().get(properties.api.BOROUGHS, {
        params: {
          search: inputValue,
          lang_code: langCode,
        },
      }),
      API().get(properties.api.COUNTIES, {
        params: {
          search: inputValue,
          lang_code: langCode,
        },
      }),
      API().get(properties.api.TOWNS, {
        params: {
          search: inputValue,
          lang_code: langCode,
        },
      }),
      API().get(properties.api.PROVINCES, {
        params: {
          search: inputValue,
          lang_code: langCode,
        },
      }),
    ]);

    return [
      {
        label: "Gminy",
        options: boroughs.data.results.map(({ name, id }) => ({
          label: name,
          value: id,
          type: "boroughs_list",
        })),
      },
      {
        label: "Powiaty",
        options: counties.data.results.map(({ name, province_name, id }) => ({
          label: getSearchLabel([name, province_name]),
          value: id,
          type: "counties_list",
        })),
      },
      {
        label: "Miasta",
        options: towns.data.results.map(
          ({ name, id, county_name, province_name }) => ({
            label: getSearchLabel([name, county_name, province_name]),
            value: id,
            type: "towns_list",
          })
        ),
      },
      {
        label: "Województwa",
        options: provinces.data.results.map(({ name, id }) => ({
          label: name,
          value: id,
          type: "provinces_list",
        })),
      },
    ];
  };

  return (
    <header className="real-estate-search">
      <h3>{t("search.title")}</h3>
      <p>{t("search.subtitle")}</p>
      <form ref={form}>
        <Row>
          <Col xs={12} md={4}>
            <Input
              placeholder="Wpisz nazwę lub numer oferty"
              defaultValue={search.search}
              name="search"
              onKeyDown={onKeyDown}
              onBlur={({ target }) => {
                if (target.value !== search.search && target.value.length > 0) {
                  onSearch({ search: target.value });
                }
                if (target.value.length === 0 && !!search.search) {
                  onSearch({ search: null });
                }
              }}
              onClear={() => {
                onSearch({ search: null });
              }}
            />
          </Col>
          <Col xs={12} md={4}>
            <LocationInput
              locations={locations}
              placeholder="Wybierz lokalizację z listy lub wpisz ręcznie"
            >
              <SelectAsync
                placeholder="Wybierz lokalizację z listy lub wpisz ręcznie"
                loadOptions={loadOptions}
                isClearable={false}
                formatGroupLabel={formatGroupLabel}
                isMulti
                menuIsOpen
                defaultOptions={provinces.map(({ id, name }) => ({
                  label: name,
                  value: id,
                  type: "provinces_list",
                }))}
                hideSelectedOptions={false}
                loadingMessage={() => <>Wyszukiwanie...</>}
                noOptionsMessage={() => (
                  <>Brak, spróbuj zmienić wpisaną frazę</>
                )}
                value={locations}
                components={{
                  Option: CustomSelectOption,
                  Input: CustomLocationInput,
                }}
                onChange={values => {
                  if (values === null) {
                    onSearch({
                      boroughs_list: null,
                      counties_list: null,
                      towns_list: null,
                      provinces_list: null,
                    });
                    return setLocations([]);
                  }

                  const boroughs_list = [];
                  const counties_list = [];
                  const towns_list = [];
                  const provinces_list = [];
                  values.forEach(({ type, value }) => {
                    if (type === "boroughs_list") {
                      boroughs_list.push(value);
                    }
                    if (type === "counties_list") {
                      counties_list.push(value);
                    }
                    if (type === "towns_list") {
                      towns_list.push(value);
                    }
                    if (type === "provinces_list") {
                      provinces_list.push(value);
                    }
                  });
                  onSearch({
                    boroughs_list,
                    counties_list,
                    towns_list,
                    provinces_list,
                  });
                  return setLocations(values);
                }}
              />
            </LocationInput>
          </Col>
          <Col xs={12} md={4}>
            <Select
              name="property_type"
              placeholder="Wszystkie kategorie"
              isClearable
              options={propertyTypesOptions}
              value={propertyTypesOptions.find(
                type => String(type.value) === search.property_type
              )}
              onChange={type => {
                if (type && type.value !== search.property_type) {
                  onSearch({ property_type: type.value });
                }
                if (!type && !!search.property_type) {
                  onSearch({ property_type: null });
                }
              }}
              onClear={() => {
                onSearch({ property_type: null });
              }}
            />
          </Col>
        </Row>
        {!isLPMode && (
          <Row>
            <Col xs={12} md={4}>
              <p className="real-estate-search__param">
                Powierzchnia gruntu{" "}
                <small>
                  (m<sup>2</sup>)
                </small>
              </p>
              <div className="real-estate-search__group">
                <Input
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  name="land_area_gte"
                  defaultValue={search.land_area_gte}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.land_area_gte &&
                      target.value.length > 0
                    ) {
                      onSearch({ land_area_gte: target.value });
                    }
                    if (target.value.length === 0 && !!search.land_area_gte) {
                      onSearch({ land_area_gte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ land_area_gte: null });
                  }}
                >
                  od
                </Input>
                <Input
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  name="land_area_lte"
                  defaultValue={search.land_area_lte}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.land_area_lte &&
                      target.value.length > 0
                    ) {
                      onSearch({ land_area_lte: target.value });
                    }
                    if (target.value.length === 0 && !!search.land_area_lte) {
                      onSearch({ land_area_lte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ land_area_lte: null });
                  }}
                >
                  do
                </Input>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <p className="real-estate-search__param">
                Powierzchnia lokalu{" "}
                <small>
                  (m<sup>2</sup>)
                </small>
              </p>
              <div className="real-estate-search__group">
                <Input
                  name="local_area_gte"
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  defaultValue={search.local_area_gte}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.local_area_gte &&
                      target.value.length > 0
                    ) {
                      onSearch({ local_area_gte: target.value });
                    }
                    if (target.value.length === 0 && !!search.local_area_gte) {
                      onSearch({ local_area_gte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ local_area_gte: null });
                  }}
                >
                  od
                </Input>
                <Input
                  name="local_area_lte"
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  defaultValue={search.local_area_lte}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.local_area_lte &&
                      target.value.length > 0
                    ) {
                      onSearch({ local_area_lte: target.value });
                    }
                    if (target.value.length === 0 && !!search.local_area_lte) {
                      onSearch({ local_area_lte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ local_area_lte: null });
                  }}
                >
                  do
                </Input>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <p className="real-estate-search__param">
                Cena <small>(zł)</small>
              </p>
              <div className="real-estate-search__group">
                <Input
                  name="price_gte"
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  defaultValue={search.price_gte}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.price_gte &&
                      target.value.length > 0
                    ) {
                      onSearch({ price_gte: target.value });
                    }
                    if (target.value.length === 0 && !!search.price_gte) {
                      onSearch({ price_gte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ price_gte: null });
                  }}
                >
                  od
                </Input>
                <Input
                  name="price_lte"
                  onInput={e => {
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, "")
                      .replace(/(\..*)\./g, "$1");
                  }}
                  defaultValue={search.price_lte && `${search.price_lte} zł`}
                  onKeyDown={onKeyDown}
                  onBlur={({ target }) => {
                    if (
                      target.value !== search.price_lte &&
                      target.value.length > 0
                    ) {
                      onSearch({ price_lte: target.value });
                    }
                    if (target.value.length === 0 && !!search.price_lte) {
                      onSearch({ price_lte: null });
                    }
                  }}
                  onClear={() => {
                    onSearch({ price_lte: null });
                  }}
                >
                  do
                </Input>
              </div>
            </Col>
            {isFilterClearable() && (
              <Col xs={12} className="real-estate-search__clear">
                <Button
                  type="button"
                  onClick={() => {
                    form.current.reset();
                    onSearch(
                      {
                        offset: search.offset,
                      },
                      true
                    );
                    setLocations([]);
                  }}
                >
                  Wyczyść wszystkie filtry
                </Button>
              </Col>
            )}
          </Row>
        )}
        {isLPMode && (
          <Row>
            <Col xs={12} className="real-estate-search__clear">
              <Button
                type="button"
                onClick={() => {
                  history.push(`/nieruchomosci/wyszukiwarka${location.search}`);
                }}
              >
                Szukaj
              </Button>
            </Col>
          </Row>
        )}
      </form>
    </header>
  );
}

export default RealEstateSearch;

import React from "react";
import PropTypes from "prop-types";

export const SearchIcon = props => {
  return (
    <div
      className="searchIcon"
      onClick={props.onClick}
      role="button"
      tabIndex={0}
    >
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
      >
        <title>ico/search</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ico/search"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M23.6157713,21.8060495 L23.6314367,21.8215868 C24.1368879,22.3340915 24.1203172,23.1593672 23.5935662,23.6526748 C23.3514383,23.878595 23.0355412,24 22.7110513,24 C22.3686386,24 22.0355926,23.8651432 21.8062698,23.6313122 L18.8366839,20.7497898 C17.7214769,21.4385222 16.4233452,21.8141509 15.06979,21.8141509 C11.1764395,21.8141509 8,18.722863 8,14.9070754 C8,11.0912878 11.1764395,8 15.06979,8 C18.9631405,8 22.1395801,11.0912878 22.1395801,14.9070754 C22.1395801,16.4144568 21.6398265,17.8469642 20.7454526,19.0213288 L23.6157713,21.8060495 Z M18.6669939,18.3601468 C19.6054284,17.4442185 20.1395801,16.2153899 20.1395801,14.9070754 C20.1395801,12.2082027 17.8705508,10 15.06979,10 C12.2690292,10 10,12.2082027 10,14.9070754 C10,17.6059482 12.2690292,19.8141509 15.06979,19.8141509 C16.2701112,19.8141509 17.4050001,19.4072811 18.3102684,18.6767485 L18.6674931,18.6930791 L18.6669939,18.3601468 Z M15.06979,19.2515723 C17.551561,19.2515723 19.5615048,17.2955806 19.5615048,14.9070754 C19.5615048,12.5184648 17.5517423,10.5625786 15.06979,10.5625786 C12.5878377,10.5625786 10.5780752,12.5184648 10.5780752,14.9070754 C10.5780752,17.2956861 12.5878377,19.2515723 15.06979,19.2515723 Z M15.06979,21.2515723 C11.495248,21.2515723 8.57807524,18.4126009 8.57807524,14.9070754 C8.57807524,11.4015499 11.495248,8.56257861 15.06979,8.56257861 C18.644332,8.56257861 21.5615048,11.4015499 21.5615048,14.9070754 C21.5615048,18.4125122 18.6441349,21.2515723 15.06979,21.2515723 Z"
            id="Shape"
            fill="#2E3033"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </div>
  );
};

SearchIcon.propTypes = {
  onClick: PropTypes.func,
};

SearchIcon.defaultProps = {
  onClick: () => {},
};

import React, { useState } from "react";
import "./CookiesPolicy.scss";
import Cookies from "js-cookie";
import { Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { ReactComponent as CookieIcon } from "../../../assets/icons/cookie.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import Collapser from "../Collapser/Collapser";

export default () => {
  const [accepted, setAccepted] = useState(Cookies.get("cookies_accepted"));

  if (accepted) return null;

  return (
    <div className="cookies-policy-wrapper">
      <Container className="cookies-container">
        <Row>
          <div className="icon">
            <CookieIcon />
          </div>
          <div className="description">
            W naszym serwisie stosuje się pliki cookies, które są zapisywane na
            dysku urządzenia końcowego użytkownika w celu ułatwienia nawigacji
            oraz dostosowania serwisu do preferencji użytkownika. Szczegółowe
            informacje o plikach cookies znajdziesz w Polityce prywatności.
            Zablokowanie zapisywania plików cookies na urządzeniu końcowym lub
            ich usunięcie możliwe jest w po właściwym skonfigurowaniu ustawień
            przeglądarki internetowej. Więcej o blokowaniu i usuwaniu plików
            cookies znajdziesz w{" "}
            <Link to="/polityka-prywatnosci">Polityce prywatności</Link>.
            Zablokowanie możliwości zapisywania plików cookies może spowodować
            utrudnienia lub brak działania niektórych funkcji serwisu.
            Niedokonanie zmian ustawień przeglądarki internetowej na ustawienia
            blokujące zapisywanie plików cookies jest jednoznaczne z wyrażeniem
            zgody na ich zapisywanie.
          </div>
          <div className="description--short">
            <Collapser
              buttonText="Mniej"
              buttonTextCollapsed="Więcej"
              textCollapsed={`<span>
                    W naszym serwisie stosuje się pliki cookies, które są zapisywane na
                    dysku urządzenia końcowego użytkownika w celu ułatwienia nawigacji
                    oraz dostosowania serwisu do preferencji użytkownika. Szczegółowe
                    informacje o plikach cookies znajdziesz w Polityce prywatności.
                    Zablokowanie zapisywania plików cookies na urządzeniu końcowym lub
                    ich usunięcie możliwe jest w po właściwym skonfigurowaniu ustawień
                    przeglądarki internetowej. Więcej o blokowaniu i usuwaniu plików
                    cookies znajdziesz w <Link to="/polityka-prywatnosci">Polityce prywatności</Link>.
                    Zablokowanie możliwości zapisywania plików cookies może spowodować
                    utrudnienia lub brak działania niektórych funkcji serwisu.
                    Niedokonanie zmian ustawień przeglądarki internetowej na ustawienia
                    blokujące zapisywanie plików cookies jest jednoznaczne z wyrażeniem
                    zgody na ich zapisywanie.
                  </span>`}
              text={`<span>
                    W naszym serwisie stosuje się pliki cookies, które są
                    zapisywane na dysku urządzenia końcowego w celu ułatwienia
                    nawigacji oraz dostosowania serwisu do preferencji
                    użytkownika. Szczegółowe informacje o plikach cookies
                    znajdziesz w
                    <a href="/polityka-prywatnosci">
                      Polityce prywatności.
                    </a>
                  </span>`}
            />
          </div>
        </Row>
        <Row className="buttons-container">
          <Link to="/polityka-cookie" className="close-button" type="button">
            Ustawienia plików cookies
          </Link>
          <Link
            to="/polityka-prywatnosci"
            className="close-button"
            type="button"
          >
            Czytaj więcej
          </Link>
          <button
            onClick={() => {
              setAccepted(true);
              Cookies.set("cookies_accepted", true, {
                expires: new Date(`${new Date().getFullYear() + 1}`),
                secure: true,
              });
            }}
            className="close-button"
            type="button"
          >
            Akceptuję
          </button>
          <button
            onClick={() => {
              setAccepted(true);
            }}
            type="button"
            className="close"
          >
            <CrossIcon />
          </button>
        </Row>
      </Container>
    </div>
  );
};

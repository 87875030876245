const categories = [
  {
    id: 18,
    url: "zboza-i-oleiste",
    label: "categories.slugs.18",
  },
  {
    id: 23,
    url: "owoce-i-warzywa",
    label: "categories.slugs.23",
  },
  {
    id: 28,
    url: "mieso",
    label: "categories.slugs.28",
  },
  {
    id: 34,
    url: "nabial",
    label: "categories.slugs.34",
  },
  {
    id: 39,
    url: "pozostale-rynki",
    label: "categories.slugs.39",
  },

  {
    id: 72,
    url: "food-and-agro-conference",
    label: "categories.slugs.72",
  },
  {
    id: 46,
    url: "finanse-i-prawo",
    label: "categories.slugs.46",
  },
  {
    id: 53,
    url: "prasa",
    label: "categories.slugs.53",
  },
  {
    id: 57,
    url: "zielone-zmiany",
    label: "categories.slugs.57",
  },
  {
    id: 68,
    url: "dla-kobiet",
    label: "categories.slugs.68",
  },
];

export const women = 68;
export const highlighted = [46, 53, 57];

export const highlightedCategories = categories.filter(
  ({ id }) => highlighted.indexOf(id) !== -1
);

export const forWomen = categories.find(({ id }) => id === 68);
export const subcategoriesForWomen = [
  {
    id: 69,
    url: "dla-kobiet/pasje-i-trendy",
    label: "categories.slugs.69",
  },
  {
    id: 70,
    url: "dla-kobiet/dom-i-ogrod",
    label: "categories.slugs.70",
  },
  {
    id: 71,
    url: "dla-kobiet/strefa-biznesu",
    label: "categories.slugs.71",
  },
];

export const highlightedsubcategories = [
  {
    id: 58,
    url: "zielone-zmiany/energia-elektryczna",
    label: "categories.slugs.58",
  },
  {
    id: 59,
    url: "zielone-zmiany/energia-odnawialna",
    label: "categories.slugs.59",
  },
  {
    id: 60,
    url: "zielone-zmiany/gleba",
    label: "categories.slugs.60",
  },
  {
    id: 61,
    url: "zielone-zmiany/maszyny-i-urzadzenia",
    label: "categories.slugs.61",
  },
  {
    id: 62,
    url: "zielone-zmiany/nawozy-i-sor",
    label: "categories.slugs.62",
  },
  {
    id: 63,
    url: "zielone-zmiany/opakowania",
    label: "categories.slugs.63",
  },
  {
    id: 64,
    url: "zielone-zmiany/rolnictwo-ekologiczne",
    label: "categories.slugs.64",
  },
  {
    id: 65,
    url: "zielone-zmiany/rozwiazania-it",
    label: "categories.slugs.65",
  },
  {
    id: 66,
    url: "zielone-zmiany/srodowisko",
    label: "categories.slugs.66",
  },
  {
    id: 67,
    url: "zielone-zmiany/zarzadzanie-woda",
    label: "categories.slugs.67",
  },
  {
    id: 47,
    url: "finanse-i-prawo/biznes",
    label: "categories.slugs.47",
  },
  {
    id: 48,
    url: "finanse-i-prawo/doradztwo",
    label: "categories.slugs.48",
  },
  {
    id: 49,
    url: "finanse-i-prawo/dotacje",
    label: "categories.slugs.49",
  },
  {
    id: 50,
    url: "finanse-i-prawo/finanse-i-prawo",
    label: "categories.slugs.50",
  },
  {
    id: 51,
    url: "finanse-i-prawo/inwestycje",
    label: "categories.slugs.51",
  },
  {
    id: 52,
    url: "finanse-i-prawo/mlody-rolnik",
    label: "categories.slugs.52",
  },
  {
    id: 74,
    url: "finanse-i-prawo/nieruchomosci",
    label: "categories.slugs.74",
  },
  {
    id: 54,
    url: "prasa/polska",
    label: "categories.slugs.54",
  },
  {
    id: 55,
    url: "prasa/unia-europejska",
    label: "categories.slugs.55",
  },
  {
    id: 56,
    url: "prasa/swiat",
    label: "categories.slugs.56",
  },
];

export const allCategories = {
  "18": "Zboża i oleiste",
  "19": "Zboża i pasze",
  "20": "Oleiste",
  "21": "Wyroby piekarnicze",
  "22": "Makarony",
  "23": "Owoce i warzywa",
  "24": "Owoce",
  "25": "Warzywa",
  "26": "Soki i nektary",
  "27": "Mrożonki",
  "28": "Mięso",
  "29": "Drób",
  "30": "Wieprzowina",
  "31": "Wołowina",
  "32": "Grypa ptaków",
  "33": "Asf",
  "34": "Nabiał",
  "35": "Mleko",
  "36": "Sery",
  "37": "Jogurty",
  "38": "Jaja",
  "39": "Rynek żywności",
  "40": "Ryby",
  "41": "Cukier",
  "42": "Słodycze i przekąski",
  "43": "Napoje",
  "44": "Kawa i herbata",
  "45": "Inne",
  "46": "Finanse i prawo",
  "47": "Biznes",
  "48": "Doradztwo",
  "49": "Dotacje",
  "50": "Finanse i prawo",
  "51": "Inwestycje",
  "52": "Młody rolnik",
  "53": "Przegląd prasy",
  "54": "Polska",
  "55": "Unia Europejska",
  "56": "Świat",
  "57": "Zielone zmiany",
  "58": "Energia elektryczna",
  "59": "Energia odnawialna",
  "60": "Gleba",
  "61": "Maszyny i urządzenia",
  "62": "Nawozy i śor",
  "63": "Opakowania",
  "64": "Rolnictwo ekologiczne",
  "65": "Rozwiązania it",
  "66": "Środowisko",
  "67": "Zarządzanie wodą",
  "68": "Agro na obcasach",
  "69": "Pasje i trendy",
  "70": "Dom i ogród",
  "71": "Strefa biznesu",
  "74": "Nieruchomości",
  "72": "Food&Agro Conference",
  "91": "Rolnictwo regeneracyjne",
  "92": "Energia",
};

export default categories;

export function getIntPath(paths = ["/"], language = "pl") {
  const [plPath, gbPath] = paths;
  switch (language) {
    case "gb":
      return gbPath || plPath;

    default:
      return plPath;
  }
}

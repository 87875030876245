import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftBox,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftButton,
  CftFormErrorFocus,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";

// Categories Icons
import { ReactComponent as WaterIcon } from "../../../../assets/icons/cft/wodny.svg";
import { ReactComponent as CO2Icon } from "../../../../assets/icons/cft/emisjagazow.svg";
import { ReactComponent as BothIcon } from "../../../../assets/icons/cft/obie.svg";

function CalcTypeForm() {
  const { t } = useTranslation(["cft"]);
  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl>
        <CftFormField name="type">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "slad-wodny"}
                  onClick={() => {
                    helpers.setValue("slad-wodny");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <WaterIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:calcType.types.1")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "emisja-gazow-cieplarnianych"}
                  onClick={() => {
                    helpers.setValue("emisja-gazow-cieplarnianych");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CO2Icon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:calcType.types.2")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "obie-kalkulacje"}
                  onClick={() => {
                    helpers.setValue("obie-kalkulacje");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <BothIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:calcType.types.3")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftButton variant="active" isFullwidth type="submit">
          {t("cft:start")}
        </CftButton>
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default CalcTypeForm;

export function CalcTypeFormContext({ children }) {
  const history = useHistory();
  const { farmlands, saveFarmlands } = useCft();
  const { t } = useTranslation(["cft"]);
  return (
    <Formik
      enableReinitialize
      initialValues={farmlands.details}
      validationSchema={Yup.object().shape({
        type: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
      })}
      onSubmit={values => {
        saveFarmlands({ details: values });
        switch (values.type) {
          case "slad-wodny": {
            if (farmlands.details.name !== "") {
              history.push(routes.FARMLANDS.WATER_FOOTPRINT.MAIN);
              break;
            }
            history.push(routes.FARMLANDS.WATER_FOOTPRINT.INTRO);
            break;
          }
          case "emisja-gazow-cieplarnianych": {
            if (farmlands.details.name !== "") {
              history.push(routes.FARMLANDS.MAIN);
              break;
            }
            history.push(routes.FARMLANDS.INTRO);
            break;
          }
          case "obie-kalkulacje": {
            history.push(routes.FARMLANDS.BOTH_CALC.INTRO);
            break;
          }
          default:
            break;
        }
      }}
    >
      {children}
    </Formik>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";
import {
  RegagriFormControl,
  RegagriLabel,
  RegagriTextField,
  RegagriTooltip,
} from "../components";

const RegagriFertilizers = () => {
  const { t } = useTranslation(["regagri"]);

  return (
    <div className="regagri-fertilizers">
      <RegagriFormControl variant="medium">
        <RegagriLabel
          className="headline"
          label={t("regagri:cultivationDetails.form.fertilizers.title")}
        />
      </RegagriFormControl>
      <RegagriFormControl variant="small" alignLeft>
        <div className="quantity">
          <RegagriLabel
            label={t(
              "regagri:cultivationDetails.form.fertilizers.quantity.label"
            )}
            // eslint-disable-next-line react/no-children-prop
            children={
              <RegagriTooltip
                content={t(
                  "regagri:cultivationDetails.form.fertilizers.quantity.tooltip"
                )}
              />
            }
          />
          <RegagriTextField
            name="quantity"
            adornment="kg N / ha"
            placeholder={t(
              "regagri:cultivationDetails.form.fertilizers.quantity.placeholder"
            )}
          />
        </div>
      </RegagriFormControl>
      <RegagriFormControl variant="small" alignLeft>
        <RegagriLabel
          label={t(
            "regagri:cultivationDetails.form.fertilizers.applicationArea.label"
          )}
        />
        <RegagriTextField
          name="applicationArea"
          adornment="%"
          placeholder={t(
            "regagri:cultivationDetails.form.fertilizers.applicationArea.placeholder"
          )}
        />
      </RegagriFormControl>
      <RegagriFormControl variant="small" alignLeft>
        <RegagriLabel
          label={t("regagri:cultivationDetails.form.fertilizers.price.label")}
        />
        <RegagriTextField
          name="price"
          adornment="€"
          placeholder={t(
            "regagri:cultivationDetails.form.fertilizers.price.placeholder"
          )}
        />
      </RegagriFormControl>
    </div>
  );
};

export default RegagriFertilizers;

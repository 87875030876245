/* eslint-disable no-undef */
import Cookies from "js-cookie";

const InitialState = {
  popular: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
  newest: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
  categories: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
  promoted: {
    count: -1,
    next: "",
    previous: "",
    results: [],
  },
};

export default function articlesAll(state = InitialState, action) {
  switch (action.type) {
    case "GET_ARTICLES_POPULAR_WIDGET_SUCCESS":
      return {
        ...state,
        popular: action.payload.data,
      };
    case "GET_ARTICLES_NEWEST_WIDGET_SUCCESS":
      return {
        ...state,
        newest: action.payload.data,
      };
    case "GET_ARTICLE_CATEGORIES_SUCCESS":
      return {
        ...state,
        categories: action.payload.data,
      };
    default:
      return state;
  }
}

export const getListOfArticles = ({
  lang_code = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : "PL",
  publish_status = 3,
  membership = 1,
  type = "GET_ARTICLES",
  category,
  search,
  grouping_id,
  article_type_id,
  phrase,
  tag,
  limit,
  offset,
  ordering,
  categories_list,
} = {}) => ({
  type,
  payload: {
    request: {
      url: "/api/v2/articles/",
      method: "GET",
      params: {
        category,
        search,
        grouping_id,
        lang_code,
        publish_status,
        membership,
        article_type_id,
        ordering,
        limit,
        phrase,
        offset,
        tag,
        categories_list,
      },
    },
  },
});
export const getListOfArticleCategories = ({
  lang_code = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : "PL",
  membership = 1,
  type = "GET_ARTICLE_CATEGORIES",
  parent_category,
  limit,
  ordering,
} = {}) => ({
  type,
  payload: {
    request: {
      url: "/api/v1/article-categories/",
      method: "GET",
      params: {
        parent_category,
        limit,
        ordering,
        membership,
        lang_code,
      },
    },
  },
});

export const getListOfPromotedArticles = ({
  lang_code = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : "PL",
  type = "GET_PROMOTED_ARTICLES",
  category,
} = {}) => ({
  type,
  payload: {
    request: {
      url: "/api/v2/promoted-articles/",
      method: "GET",
      params: {
        category,
        lang_code,
      },
    },
  },
});

import React from "react";
import { PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";

import "./CftChickenBroilerReport.scss";

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  percent,
  fontSize,
  index,
  payload,
}) => {
  const RADIAN = Math.PI / 180;
  const sin = Math.sin(RADIAN * midAngle);
  const cos = Math.cos(RADIAN * midAngle);
  const startX = cx + outerRadius * cos;
  const startY = cy + outerRadius * -sin;
  const middleY = cy + (outerRadius + 50 * Math.abs(sin)) * -sin;
  let endX = startX + (cos >= 0 ? 1 : -1) * 90;
  let textAnchor = cos >= 0 ? "start" : "end";
  const isMirrored =
    midAngle > -270 && midAngle < -210 && percent < 0.04 && index % 2 === 1;
  if (isMirrored) {
    endX = startX + outerRadius * -cos * 2 + 100;
    textAnchor = "start";
  }

  return (
    <g>
      <path
        d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
        fill="none"
        stroke={payload.color}
        strokeWidth={1}
      />
      <text
        x={endX + (cos >= 0 || isMirrored ? 1 : -1) * 12}
        y={middleY + fontSize / 2}
        fill={payload.color}
        textAnchor={textAnchor}
        fontSize={fontSize}
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

function CftChickenBroilerReportChart({ report }) {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const data = [
    {
      name: t(
        "cftChickenBroiler:cftChickenBroilerReport.tabel.total_carrier_use",
        "Nośniki energii"
      ),
      value: report.total_emission.carrier_use,
      color: "#66C09E",
    },
    {
      name: t(
        "cftChickenBroiler:cftChickenBroilerReport.table.total_bedding_use",
        "Produkcja Ściółki"
      ),
      value: report.total_emission.bedding_use,
      color: "#B176FE",
    },
    {
      name: t(
        "cftChickenBroiler:cftChickenBroilerReport.table.total_feed_production",
        "Paszy"
      ),
      value: report.total_emission.feed_production,
      color: "#EF6F6C",
    },

    {
      name: t(
        "cftChickenBroiler:cftChickenBroilerReport.table.total_excrement",
        "Związane z odchodami"
      ),
      value: report.total_emission.excrement,
      color: "#A4A7F8",
    },
    {
      name: t(
        "cftChickenBroiler:cftChickenBroilerReport.table.total_transport_use",
        "Transport"
      ),
      value: report.total_emission.transport_use,
      color: "#F59B00",
    },
  ]
    .filter(({ value }) => value > 0)
    .sort((a, b) => b.value - a.value);

  const sum = data.reduce((prev, crr) => {
    return prev + crr.value;
  }, 0);

  return (
    <div className="cft-chicken-broiler-report-chart">
      <PieChart width={400} height={300}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          labelLine={false}
          paddingAngle={2}
          label={renderCustomizedLabel}
          minAngle={10}
          outerRadius={100}
          fontSize={14}
          fill=""
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
      <div className="cft-chicken-broiler-report-chart__legend">
        {data.map(({ name, value, color }) => (
          <div>
            <span>
              <span className="dot" style={{ backgroundColor: color }} />
              {name}
            </span>
            <span>{`${((value * 100) / sum).toFixed(2)}%`}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CftChickenBroilerReportChart;

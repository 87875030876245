import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./ProfilMenu.scss";
import { Link } from "react-router-dom";
import { ArrowIcon } from "../index";

class ProfilMenu extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="profil-menu-container">
        <div className="menu-decoration" />
        <ul className="profil-menu-list">
          <li>
            <Link to="/my/profil/data">
              <span>{t("user:profile.tab1")}</span>
              <ArrowIcon color="#9B9B9B" />
            </Link>
          </li>
          <li>
            <Link to="/my/profil/passwordReminder">
              <span>{t("user:profile.tab3")}</span>
              <ArrowIcon color="#9B9B9B" />
            </Link>
          </li>
          <li>
            <Link to="/" onClick={() => this.props.logout()}>
              <span>{t("user:profile.logout")}</span>
              <ArrowIcon color="#9B9B9B" />
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default withTranslation(["user"])(ProfilMenu);

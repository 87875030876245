import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { components } from "react-select";
import clx from "classnames";

import {
  CftLabel,
  CftFormControl,
  CftSelectField,
  CftTooltip,
  CftButton,
} from "../../components";
import useCftPigsValues from "../../utils/useCftPigsValues";

import "./CreateHerdField.scss";

const CustomMenuWrapper = props => {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);

  return (
    <components.Menu
      {...props}
      maxMenuHeight={props.maxMenuHeight - 50}
      minMenuHeight={props.minMenuHeight + 50}
    >
      {props.children}{" "}
      <CftButton
        isFullwidth
        isActive={!!props.selectProps.selectedProductionGroups.length}
        onClick={() => {
          props.selectProps.onSaveSelected();
          props.selectProps.onMenuClose();
        }}
        disabled={!props.selectProps.selectedProductionGroups.length}
      >
        {t("cftPigs:pigsHerdFeedsForm.customMenuBtn", "Zapisz wybór")}
      </CftButton>
    </components.Menu>
  );
};

const CustomOption = ({ innerRef, innerProps, children, ...props }) => {
  return (
    <div
      className="create-herd-field__custom-option"
      ref={innerRef}
      {...innerProps}
      {...props}
    >
      <span
        className={clx({
          "is-active": props.selectProps.selectedProductionGroups
            .map(({ production_group_type }) => production_group_type)
            .includes(props.value),
        })}
      />
      {children}
    </div>
  );
};

const CustomMenu = ({ innerRef, innerProps, children, ...props }) => {
  return (
    <>
      <components.MenuList
        className="create-herd-field__custom-menu"
        ref={innerRef}
        {...innerProps}
        {...props}
        maxHeight={props.maxHeight - 50}
      >
        {children}
      </components.MenuList>
    </>
  );
};

export default function CreateHerdField() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const { updateStore, productionGroupsTypes } = useCftPigsValues();
  const { setFieldValue, values } = useFormikContext();

  const productionGroupsTypesOptions = useMemo(() => {
    const productionGroupTypes = values.production_groups.map(
      ({ production_group_type }) => production_group_type
    );

    return productionGroupsTypes
      .filter(({ id }) => !productionGroupTypes.includes(id))
      .map(({ id, name }) => ({
        label: name,
        value: id,
      }));
  }, [productionGroupsTypes, values]);

  useEffect(() => {
    setFieldValue("production_groups_tmp", []);
  }, []);

  if (productionGroupsTypesOptions.length) {
    return (
      <>
        <div className="cft-paper mb-6">
          <CftFormControl alignLeft>
            <CftLabel
              isFullWidth
              label={t("cftPigs:pigsHerdFeedsForm.select", "Grupa produkcyjna")}
              className="mb-4"
            >
              <CftTooltip
                content={t(
                  "cftPigs:tooltips.productionGroupType",
                  "Dodaj tyle grup produkcyjnych, które występują w Twoim stadzie"
                )}
              />
            </CftLabel>
            <CftSelectField
              blurInputOnSelect={false}
              onSaveSelected={() => {
                updateStore({
                  herd_reference: {
                    ...values,
                    production_groups: [
                      ...values.production_groups,
                      ...values.production_groups_tmp,
                    ],
                  },
                });
                setFieldValue("production_groups", [
                  ...values.production_groups,
                  ...values.production_groups_tmp,
                ]);
                setFieldValue("production_groups_tmp", []);
              }}
              selectedProductionGroups={values.production_groups_tmp || []}
              components={{
                Option: CustomOption,
                MenuList: CustomMenu,
                Menu: CustomMenuWrapper,
              }}
              closeMenuOnSelect={false}
              name="tmp"
              options={productionGroupsTypesOptions}
              onChange={productionGroupType => {
                if (
                  values.production_groups_tmp
                    .map(({ production_group_type }) => production_group_type)
                    .includes(productionGroupType)
                ) {
                  setFieldValue(
                    "production_groups_tmp",
                    values.production_groups_tmp.filter(
                      ({ production_group_type }) =>
                        production_group_type !== productionGroupType
                    )
                  );
                } else {
                  setFieldValue("production_groups_tmp", [
                    ...values.production_groups_tmp,
                    {
                      production_group_type: productionGroupType,
                      growth_factor: "",
                      pigs_number: "",
                      rearing_period: "",
                      target_weight: "",
                      repetition: "",
                      feed_type: "",
                      feed_port: "",
                      isValid: false,
                    },
                  ]);
                }

                setFieldValue("tmp", "");
              }}
              placeholder={t(
                "cftTurkey:transportFields.fields.transportPlaceholder",
                "Wybierz z listy"
              )}
              isRounded
              isAlternative
            />
          </CftFormControl>
        </div>
      </>
    );
  }

  return null;
}

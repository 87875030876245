import { useTranslation } from "react-i18next";
import { formatDate, getMainCategory } from "./articles";

export const MULTIMEDIA_TYPES_VALUES = {
  Video: 1,
  Podcast: 2,
  PhotoGallery: 3,
};

export const MUTLIMEDIA_TYPES = [
  { labelKey: "multimedia:types.video", id: MULTIMEDIA_TYPES_VALUES.Video },
  { labelKey: "multimedia:types.podcast", id: MULTIMEDIA_TYPES_VALUES.Podcast },
  {
    labelKey: "multimedia:types.photo-gallery",
    id: MULTIMEDIA_TYPES_VALUES.PhotoGallery,
  },
];

const MULTIMEDIA_MAP = {
  [MULTIMEDIA_TYPES_VALUES.Video]: "video",
  [MULTIMEDIA_TYPES_VALUES.Podcast]: "podcast",
  [MULTIMEDIA_TYPES_VALUES.PhotoGallery]: "gallery",
};

export const useMultimediaTypes = () => {
  const { t } = useTranslation(["multimedia"]);

  return MUTLIMEDIA_TYPES.map(({ id, labelKey }) => {
    return {
      id,
      name: t(labelKey),
    };
  });
};

export const serializeMultimediaDataToCard = (multimedia, linkFn, size) => {
  return {
    coverUrl: !!multimedia.cover_photo ? multimedia.cover_photo.file : "",
    date: formatDate(multimedia.published_at),
    title: multimedia.short_title || multimedia.title,
    description: multimedia.short_summary || multimedia.summary,
    type: MULTIMEDIA_MAP[multimedia.multimedia_type],
    size: size || "default",
    url: linkFn ? linkFn(multimedia.slug) : null,
  };
};

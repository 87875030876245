import React from "react";
import { useTranslation } from "react-i18next";
// import { useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../components/icons/ArrowRight";
import routes from "../../utils/cftRoutes";
// import useCftTurkeyValues from "../../utils/useCftTurkeyValues";

export default function BackToCoops() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  // const { store, updateStore } = useCftTurkeyValues();
  // const { values } = useFormikContext();
  // const { id } = useParams();
  const history = useHistory();

  return (
    <button
      className="back-coop"
      type="button"
      onClick={() => {
        history.push(routes.CATTLE.COOPS);
      }}
    >
      <ArrowRight
        style={{
          transform: "rotate(180deg)",
          marginRight: "16px",
          color: "#828385",
        }}
      />
      {t("cftPigs:backToCoops", "Informacje o stadzie i paszach")}
    </button>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

import { CftReport, CftCard, CftTable, CftMobileReportWrapper } from "../index";

export default function CftReportDetailsSection({ data }) {
  const { t } = useTranslation(["cft"]);

  if (!data.length) {
    return null;
  }

  return (
    <>
      {data.map(({ name, rows }) => {
        if (!rows.length) {
          return null;
        }

        return (
          <CftCard key={name.i18n}>
            <CftReport.Box>
              <CftReport.Title>{t(name.i18n, name.default)}</CftReport.Title>
            </CftReport.Box>
            <CftMobileReportWrapper>
              <CftTable data={rows} />
            </CftMobileReportWrapper>
          </CftCard>
        );
      })}
    </>
  );
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftBox,
  CftProgressCircle,
  CftActionsButtons,
  CftTank,
  CftExportReport,
  CftLeavingModal,
} from "../../../components";
import { ReactComponent as CropIcon } from "../../../../assets/icons/cft/crops.svg";
import { ReactComponent as SoilIcon } from "../../../../assets/icons/cft/ochrona_gleba.svg";
import { ReactComponent as FeritilizerIcon } from "../../../../assets/icons/cft/nawoz_organiczny.svg";
import { ReactComponent as EnergyIcon } from "../../../../assets/icons/cft/energia.svg";
import { ReactComponent as IrrigationIcon } from "../../../../assets/icons/cft/irygacja.svg";
import { ReactComponent as CarbonIcon } from "../../../../assets/icons/cft/sladweglowy.svg";
import { ReactComponent as TransportIcon } from "../../../../assets/icons/cft/drogowy.svg";
import { ReactComponent as ReportIcon } from "../../../../assets/icons/cft/detale.svg";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";

import "../../styles/progressDashboard.scss";

export default function BothCalcPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { meta, progress } = useCft();
  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          cropName={meta.title.bothCalc}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftActionsButtons.GoToProfile
                onClick={() => {
                  stopOverflow();
                  setShowLeavingModal(!showLeavingModal);
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        header={
          <CftTank display="flex" flexDirection="row" alignItems="center">
            <h3 className="progress-dashboard-header cft-wrapper">
              {meta.title.bothCalc}
            </h3>
            <CftExportReport type="bothCalc" />
          </CftTank>
        }
      >
        <div className="progress-dashboard cft-wrapper">
          <CftBox
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.DETAILS)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle value={progress.bothCalc.details.complete} />
              <CropIcon />
              <p>{t("cft:types.detailsFarmlands", "Detale uprawy")}</p>
            </div>
          </CftBox>
          <CftBox onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.SOIL)}>
            <div className="progress-dashboard__box">
              <CftProgressCircle value={progress.bothCalc.soil.complete} />
              <SoilIcon />
              <p>{t("cft:types.soil", "Gleba")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.FERTILIZERS)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.bothCalc.fertilizers.complete}
              />
              <FeritilizerIcon />
              <p>{t("cft:types.fertilizers", "Nawozy")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() =>
              history.push(routes.FARMLANDS.BOTH_CALC.FUEL_AND_ENERGY)
            }
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.bothCalc.fuelAndEnergy.complete}
              />
              <EnergyIcon />
              <p>{t("cft:types.fuelAndEnergy", "Paliwo i energia")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.IRRIGATION)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.bothCalc.irrigation.complete}
              />
              <IrrigationIcon />
              <p>{t("cft:types.irrigation", "Irygacja")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() =>
              history.push(routes.FARMLANDS.BOTH_CALC.CARBON_CHANGES)
            }
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={progress.bothCalc.carbonChanges.complete}
              />
              <CarbonIcon />
              <p>{t("cft:types.carbonChanges", "Zmiany węglowe")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.TRANSPORT)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle value={progress.bothCalc.transport.complete} />
              <TransportIcon />
              <p>{t("cft:types.transport", "Transport")}</p>
            </div>
          </CftBox>
          <CftBox
            onClick={() => history.push(routes.FARMLANDS.BOTH_CALC.REPORT)}
          >
            <div className="progress-dashboard__box">
              <CftProgressCircle
                value={(
                  (progress.bothCalc.details.complete +
                    progress.bothCalc.soil.complete +
                    progress.bothCalc.fertilizers.complete +
                    progress.bothCalc.fuelAndEnergy.complete +
                    progress.bothCalc.irrigation.complete +
                    progress.bothCalc.carbonChanges.complete +
                    progress.bothCalc.transport.complete) /
                  7
                ).toFixed()}
              />
              <ReportIcon />
              <p>{t("cft:types.report", "Raport")}</p>
            </div>
          </CftBox>
        </div>
        <CftLeavingModal
          isVisible={showLeavingModal}
          hide={() => setShowLeavingModal(!showLeavingModal)}
          type="bothCalc"
          goToProfile={() => history.push(routes.MAIN)}
        />
      </CftSection>
    </CftLayout>
  );
}

import useSWR from "swr";
import API from "../../services/apiHelpers";

export const useProducts = () => {
  const { data: { data } = { data: undefined } } = useSWR(
    "/api/v1/products/",
    () => API().get("/api/v1/products/")
  );

  return {
    products: data,
  };
};

export const useProduct = (slug = "") => {
  const { data: { data } = { data: undefined } } = useSWR(
    "/api/v1/products/",
    () => API().get("/api/v1/products/")
  );

  const product = data
    ? data.find(product => product.slug === slug)
    : undefined;

  return {
    isLoading: !data,
    isError: !!data && !product,
    product,
  };
};

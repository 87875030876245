import React from "react";
import PropTypes from "prop-types";

export const WeatherIcon = ({ active, color, forecast, size }) => (
  <div className={`weather-icon ${active ? ".active" : ""}`}>
    {forecast === "fog" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M22.125 14.827c-.117-2.659-2.344-4.727-5.156-4.727-2.49 0-4.6 1.566-5.098 3.782h-.293c-1.406 0-2.578 1.3-2.578 2.836 0 1.477 1.113 2.6 2.578 2.6v-.473c-1.201 0-2.11-.915-2.11-2.127 0-1.27.968-2.363 2.11-2.363h.674l.03-.207c.38-2.068 2.343-3.575 4.687-3.575 2.636 0 4.687 1.98 4.687 4.49v.237h.235c.966 0 1.64 1.005 1.64 1.89 0 .946-.703 1.655-1.64 1.655v.473c1.2 0 2.109-.916 2.109-2.127 0-1.064-.732-2.216-1.875-2.364zm-10.078 3.991h9.375v.473h-9.375v-.473zm0 1.818h9.375v.473h-9.375v-.473zm0-3.636h9.375v.473h-9.375V17z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "wind" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M8 17.325h13.547c.863 0 1.594.756 1.594 1.65 0 .894-.73 1.65-1.594 1.65-.863 0-1.594-.756-1.594-1.65h-.531c0 1.203.963 2.2 2.125 2.2s2.125-.997 2.125-2.2c0-1.203-.963-2.2-2.125-2.2H8v.55zM8 15.4h14.875c1.162 0 2.125-.997 2.125-2.2 0-1.203-.963-2.2-2.125-2.2s-2.125.997-2.125 2.2h.531c0-.894.73-1.65 1.594-1.65.863 0 1.594.756 1.594 1.65 0 .894-.73 1.65-1.594 1.65H8v.55zm0 3.85h8.5c.598 0 1.063.481 1.063 1.1 0 .619-.465 1.1-1.063 1.1-.598 0-1.063-.481-1.063-1.1h-.53c0 .894.73 1.65 1.593 1.65s1.594-.756 1.594-1.65c0-.894-.73-1.65-1.594-1.65H8v.55z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "sleet" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd">
          <path d="M12.31 23.722l1.876-3.341.41.238-1.875 3.341-.41-.238zm-2.343 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm4.687 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm2.344 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm2.344 0l1.875-3.341.41.238-1.875 3.341-.41-.238zM11.578 19.39h1.64v-.48h-1.64c-1.201 0-2.11-.93-2.11-2.16 0-1.29.968-2.4 2.11-2.4h.674l.03-.21c.38-2.1 2.343-3.63 4.687-3.63 2.636 0 4.687 2.01 4.687 4.56v.24h.235c.966 0 1.64 1.02 1.64 1.92 0 .96-.703 1.68-1.64 1.68h-2.11v.48h2.11c1.2 0 2.109-.93 2.109-2.16 0-1.08-.732-2.25-1.875-2.4-.117-2.7-2.344-4.8-5.156-4.8-2.49 0-4.6 1.59-5.098 3.84h-.293C10.172 13.87 9 15.19 9 16.75c0 1.5 1.113 2.64 2.578 2.64zm2.832-1.67l-.41-.24.38-.6-.38-.6.41-.24.381.6h1.523v.48h-1.523l-.38.6zm4.746-1.68l.41.24-.38.6.38.6-.41.24-.38-.6h-1.524v-.48h1.523l.381-.6zm-2.373 3.3l-.586.39-.234-.42.586-.39v-1.56h.469v1.56l.586.39-.235.42-.586-.39zm0-4.92l.586-.39.235.42-.586.39v1.56h-.47v-1.56l-.585-.39.234-.42.586.39zm-1.553.03l.47.09-.147.72.937.96-.351.36-.938-.96-.703.15-.088-.48.674-.15.146-.69zm3.106 4.86l-.469-.09.147-.72-.938-.96.352-.36.937.96.703-.15.088.48-.674.15-.146.69zm-2.197-2.13l.351.36-.937.96.146.72-.469.09-.146-.69-.674-.15.088-.48.703.15.938-.96zm1.289-.6l-.352-.36.938-.96-.147-.72.469-.09.146.69.674.15-.088.48-.703-.15-.937.96z" />
        </g>
      </svg>
    )}
    {forecast === "snow" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M11.578 19.39h1.64v-.48h-1.64c-1.201 0-2.11-.93-2.11-2.16 0-1.29.968-2.4 2.11-2.4h.674l.03-.21c.38-2.1 2.343-3.63 4.687-3.63 2.636 0 4.687 2.01 4.687 4.56v.24h.235c.966 0 1.64 1.02 1.64 1.92 0 .96-.703 1.68-1.64 1.68h-2.11v.48h2.11c1.2 0 2.109-.93 2.109-2.16 0-1.08-.732-2.25-1.875-2.4-.117-2.7-2.344-4.8-5.156-4.8-2.49 0-4.6 1.59-5.098 3.84h-.293C10.172 13.87 9 15.19 9 16.75c0 1.5 1.113 2.64 2.578 2.64zm2.549.6l-.41-.24.38-.6-.38-.6.41-.24.38.6h1.524v.48h-1.523l-.381.6zm4.746-1.68l.41.24-.38.6.38.6-.41.24-.38-.6h-1.524v-.48h1.523l.381-.6zm-2.373 3.3l-.586.39-.234-.42.586-.39v-1.56h.468v1.56l.586.39-.234.42-.586-.39zm0-4.92l.586-.39.234.42-.586.39v1.56h-.468v-1.56l-.586-.39.234-.42.586.39zm-1.553.03l.469.09-.146.72.937.96-.352.36-.937-.96-.703.15-.088-.48.674-.15.146-.69zm3.106 4.86l-.469-.09.146-.72-.937-.96.352-.36.937.96.703-.15.088.48-.674.15-.146.69zm-2.198-2.13l.352.36-.937.96.146.72-.469.09-.146-.69-.674-.15.088-.48.703.15.937-.96zm1.29-.6l-.352-.36.937-.96-.146-.72.469-.09.146.69.674.15-.088.48-.703-.15-.937.96z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "clear-day" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M16 20.25A4.254 4.254 0 0 0 20.25 16 4.254 4.254 0 0 0 16 11.75 4.254 4.254 0 0 0 11.75 16 4.254 4.254 0 0 0 16 20.25zm0-8A3.761 3.761 0 0 1 19.75 16 3.761 3.761 0 0 1 16 19.75 3.761 3.761 0 0 1 12.25 16 3.761 3.761 0 0 1 16 12.25zM15.75 8h.5v2.5h-.5V8zm0 13.5h.5V24h-.5v-2.5zm5.75-5.75H24v.5h-2.5v-.5zm-13.5 0h2.5v.5H8v-.5zm13.063-5.188l.375.376-1.25 1.25-.375-.376 1.25-1.25zm-9.25 9.25l.374.375-1.25 1.25-.374-.375 1.25-1.25zm9.25 1.625l-1.25-1.25.375-.375 1.25 1.25-.375.375zM10.938 10.563l1.25 1.25-.376.376-1.25-1.25.376-.376z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "cloudy" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M22.125 14.773C22.008 12.088 19.781 10 16.97 10c-2.49 0-4.6 1.581-5.098 3.818h-.293C10.172 13.818 9 15.131 9 16.682c0 1.491 1.113 2.625 2.578 2.625h10.313c1.2 0 2.11-.925 2.11-2.148 0-1.074-.733-2.237-1.876-2.386zm-.234 4.057H11.578c-1.201 0-2.11-.925-2.11-2.148 0-1.283.968-2.387 2.11-2.387h.674l.03-.208c.38-2.088 2.343-3.61 4.687-3.61 2.637 0 4.687 1.999 4.687 4.534v.239h.235c.966 0 1.64 1.014 1.64 1.91 0 .954-.703 1.67-1.64 1.67z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "partly-cloudy-day" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M22.25 15.191c-.125-2.589-2.437-4.634-5.25-4.634-.5 0-.969.064-1.406.192-.625-.448-1.344-.704-2.094-.704-1.937 0-3.5 1.598-3.5 3.58 0 .543.125.927.313 1.278-.657.512-1.063 1.343-1.063 2.302 0 1.47 1.063 2.556 2.5 2.556H22c1.188 0 2-.83 2-2.045 0-1.15-.687-2.365-1.75-2.525zM10.5 13.625c0-1.694 1.344-3.068 3-3.068.531 0 1.031.128 1.5.415-1.469.608-2.594 1.854-2.937 3.42h-.313c-.375 0-.687.096-1 .256-.187-.32-.25-.607-.25-1.023zM22 19.25H11.75c-1.187 0-2-.83-2-2.045 0-1.279.906-2.302 2-2.302h.719l.031-.223c.375-2.078 2.281-3.612 4.5-3.612 2.625 0 4.75 1.95 4.75 4.347v.255H22c.875 0 1.5 1.087 1.5 2.046 0 .927-.594 1.534-1.5 1.534zM8 13.37h1.5v.51H8v-.51zM13.25 8h.5v1.534h-.5V8zm-4.062.831l1.5 1.534-.375.384-1.5-1.535.375-.383z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "partly-cloudy-night" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M23.14 15.296c-.132-2.664-2.59-4.77-5.578-4.77-1.095 0-2.125.296-2.955.823A3.717 3.717 0 0 0 11.72 10c-.233 0-.465.033-.698.066l-.365.066.2.329c.232.328.332.723.332 1.118a2.109 2.109 0 0 1-2.126 2.105 2.55 2.55 0 0 1-.697-.098L8 13.454v.395a3.46 3.46 0 0 0 .598 1.809c.199.362.498.658.83.92-.067.264-.1.527-.1.79 0 1.514 1.129 2.632 2.656 2.632h10.891C24.137 20 25 19.145 25 17.895c0-1.184-.73-2.434-1.86-2.599zm-14.11.099a3.202 3.202 0 0 1-.466-1.217c.166.033.332.033.498.033 1.461 0 2.657-1.152 2.657-2.632 0-.362-.067-.724-.233-1.053h.233c.963 0 1.826.428 2.457 1.119-.93.723-1.594 1.677-1.86 2.829h-.332c-1.029 0-1.925.658-2.357 1.579a3.707 3.707 0 0 1-.598-.658zm13.845 4.079h-10.89c-1.262 0-2.126-.856-2.126-2.106 0-1.315.963-2.368 2.125-2.368h.764l.033-.23c.399-2.138 2.424-3.717 4.781-3.717 2.79 0 5.047 2.006 5.047 4.473v.263h.266c.93 0 1.594 1.119 1.594 2.106 0 .954-.631 1.579-1.594 1.579z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "sleet" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M22.125 14.773C22.008 12.088 19.781 10 16.97 10c-2.49 0-4.6 1.581-5.098 3.818h-.293C10.172 13.818 9 15.131 9 16.682c0 1.491 1.113 2.625 2.578 2.625h10.313c1.2 0 2.11-.925 2.11-2.148 0-1.074-.733-2.237-1.876-2.386zm-.234 4.057H11.578c-1.201 0-2.11-.925-2.11-2.148 0-1.283.968-2.387 2.11-2.387h.674l.03-.208c.38-2.088 2.343-3.61 4.687-3.61 2.637 0 4.687 1.999 4.687 4.534v.239h.235c.966 0 1.64 1.014 1.64 1.91 0 .954-.703 1.67-1.64 1.67zm-9.58 4.892l1.875-3.341.41.238-1.875 3.341-.41-.238zm-2.344 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm4.687 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm2.344 0l1.875-3.341.41.238-1.875 3.341-.41-.238zm2.344 0l1.875-3.341.41.238-1.875 3.341-.41-.238z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "clear-night" && (
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M15.983 22.1c-1.816 0-3.583-.81-4.727-2.165l-.282-.305.073-.168h.611c2.53 0 4.601-2.064 4.601-4.587 0-1.613-1.047-3.215-2.673-4.06l-.353-.188.012-.182.373-.14c.624-.252 1.542-.405 2.365-.405 3.37 0 6.117 2.74 6.117 6.1 0 3.36-2.748 6.1-6.117 6.1zm.852-7.225a5.182 5.182 0 0 1-4.709 5.147 5.777 5.777 0 0 0 3.857 1.503c3.047 0 5.54-2.487 5.54-5.525 0-3.038-2.493-5.525-5.54-5.525-.486 0-1.04.066-1.54.178 1.48 1.019 2.392 2.606 2.392 4.222z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
    {forecast === "rain" && (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 32 32"
      >
        <path
          d="M22.1 14.8C22 12.1 19.8 10 17 10 14.5 10 12.4 11.6 11.9 13.8L11.6 13.8C10.2 13.8 9 15.1 9 16.7 9 18.2 10.1 19.3 11.6 19.3L21.9 19.3C23.1 19.3 24 18.4 24 17.2 24 16.1 23.3 14.9 22.1 14.8ZM21.9 18.8L11.6 18.8C10.4 18.8 9.5 17.9 9.5 16.7 9.5 15.4 10.4 14.3 11.6 14.3L12.3 14.3 12.3 14.1C12.7 12 14.6 10.5 17 10.5 19.6 10.5 21.7 12.5 21.7 15L21.7 15.3 21.9 15.3C22.9 15.3 23.5 16.3 23.5 17.2 23.5 18.1 22.8 18.8 21.9 18.8ZM12.3 23.7L14.2 20.4 14.6 20.6 12.7 24 12.3 23.7ZM10 23.7L11.8 20.4 12.3 20.6 10.4 24 10 23.7ZM14.7 23.7L16.5 20.4 16.9 20.6 15.1 24 14.7 23.7ZM17 23.7L18.9 20.4 19.3 20.6 17.4 24 17 23.7ZM19.3 23.7L21.2 20.4 21.6 20.6 19.8 24 19.3 23.7Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    )}
  </div>
);

WeatherIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number,
  forecast: PropTypes.string.isRequired,
};

WeatherIcon.defaultProps = {
  size: 32,
  color: "black",
  active: false,
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "ramda";
import DOMPurify from "dompurify";

import { selectContentBar } from "../../../services/redux/ui/selectors";
import { getContentBar } from "../../../services/redux/ui/reducer";

import "./ContentBar.scss";

const ContentBar = ({ getContentBar, bar }) => {
  useEffect(() => {
    if (bar.id === -1) {
      getContentBar();
    }
  }, [bar]);

  if (!bar.active || isEmpty(bar.content)) {
    return null;
  }

  const clean = DOMPurify.sanitize(bar.content);

  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: clean }}
      className="c-content-bar"
    />
  );
};

ContentBar.propTypes = {};
ContentBar.defaultProps = {};

export default connect(
  state => ({
    bar: selectContentBar(state),
  }),
  {
    getContentBar,
  }
)(ContentBar);

import React from "react";
import "./CftTank.scss";

function CftTank({
  children,
  display,
  justifyContent,
  alignItems,
  m,
  p,
  mt,
  mb,
  mr,
  ml,
  pt,
  pb,
  pl,
  pr,
  width,
  height,
  flexDirection,
  className,
  style,
}) {
  const parseUnit = unit => {
    if (typeof unit === "string") {
      return unit;
    }

    if (typeof unit === "number") {
      return `${unit * 0.5}rem`;
    }

    return null;
  };

  return (
    <div
      className={`cft-tank ${className}`}
      style={{
        display,
        justifyContent,
        alignItems,
        width,
        height,
        flexDirection,
        margin: parseUnit(m),
        marginLeft: parseUnit(ml),
        marginRight: parseUnit(mr),
        marginTop: parseUnit(mt),
        marginBottom: parseUnit(mb),
        padding: parseUnit(p),
        paddingTop: parseUnit(pt),
        paddingBottom: parseUnit(pb),
        paddingLeft: parseUnit(pl),
        paddingRight: parseUnit(pr),
        ...style,
      }}
    >
      {children}
    </div>
  );
}

CftTank.defaultProps = {
  display: "flex",
  justifyContent: null,
  alignItems: null,
  flexDirection: null,
  m: null,
  p: null,
  mt: null,
  mb: null,
  mr: null,
  ml: null,
  pt: null,
  pb: null,
  pl: null,
  pr: null,
  className: "",
};

export default CftTank;

import * as actions from "./actions";
import i18n from "../../../i18n";

const InitialState = {
  id: 0,
  lang_code: 0,
  grouping_id: "",
  published_at: "",
  creator: {
    first_name: "",
    last_name: "",
    user_type: 0,
  },
  name: "",
  author_name: "",
  author_image: "",
  content: "",
  category: {
    id: 0,
    lang_code: 0,
    name: "",
    created_at: "",
    updated_at: "",
  },
  article_type: 0,
  thumb: {
    id: "",
    name: "",
    file: "",
    description: "",
    size: 0,
    mime_type: 0,
    created_at: "",
    updated_at: "",
  },
  medias: [],
  created_at: "",
  updated_at: "",
  translations: [],
};

export default function article(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_ARTICLE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }
    case actions.GET_ARTICLE_TRANSLATION_SUCCESS: {
      const {
        data: { results },
      } = action.payload;
      return {
        ...state,
        translations: results.filter(
          item => item.lang_code.toLowerCase() !== i18n.language
        ),
      };
    }
    case actions.GET_ARTICLE_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    case actions.RESET_SINGLE_PAGE: {
      return {
        ...InitialState,
      };
    }
    default:
      return state;
  }
}

export const getArticle = (slug, token, report) => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const type = report ? "agroraport-articles" : "articles";
  return {
    type: "GET_ARTICLE",
    payload: {
      request: {
        url: `/api/v2/${type}/${slug}/`,
        method: "GET",
        headers,
      },
    },
  };
};

export const getArticleTranslation = (grouping_id, token) => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: "GET_ARTICLE_TRANSLATION",
    payload: {
      request: {
        url: `/api/v2/articles/?grouping_id=${grouping_id}`,
        method: "GET",
        headers,
      },
    },
  };
};

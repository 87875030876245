/* eslint-disable func-names */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftClearForm,
  CftSelectField,
  CftTooltip,
  CftFormErrorFocus,
  CftFormField,
} from "../../components";
import routes from "../../utils/cftRoutes";
import API from "../../../services/apiHelpers";
import useCftPigsValues from "../../utils/useCftPigsValues";
import BackToCoops from "./BackToCoops";
import GrowthFactor from "./GrowthFactor";

export default function PigsHerdFeedForm() {
  const token = Cookies.get("user_token");
  const {
    updateStore,
    store,
    getProductionGroupTypeName,
    feedTypes,
    feedPorts,
  } = useCftPigsValues();
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const history = useHistory();
  const { id } = useParams();

  if (!id) {
    return <Redirect to={routes.PIGS.COOPS} />;
  }

  const herd = store.herd_reference.production_groups[Number(id)];

  if (!herd) {
    return <Redirect to={routes.PIGS.COOPS} />;
  }

  const calculationPeriodLength = Number(store.main.calculation_period_length);

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Formik
          enableReinitialize
          initialValues={herd}
          validationSchema={Yup.object().shape({
            growth_factor: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            pigs_number: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            rearing_period: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .test(
                "is-right-calculationPeriodLength",
                t("cftChickenBroiler:errors.calculationPeriodLength2", {
                  calculationPeriodLength,
                }),
                function(value) {
                  return Number(value || 0) <= calculationPeriodLength;
                }
              ),
            target_weight: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .min(
                1,
                t(
                  "cftPigs:validationSchema.min",
                  "Wartość minimalna to {{min}}.",
                  { min: 1 }
                )
              )
              .max(
                400,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 400 }
                )
              ),
            repetition: Yup.string()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .test(
                "is-right-calculationPeriodLength",
                t("cftChickenBroiler:errors.calculationPeriodLength2", {
                  calculationPeriodLength,
                }),
                function(value) {
                  const [{ value: values }] = this.from;
                  return (
                    Number(value || 0) * Number(values.rearing_period || 0) <=
                    calculationPeriodLength
                  );
                }
              ),
            feed_type: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            feed_port: Yup.string().test(
              "is-right-calculationPeriodLength",
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              ),
              function(value) {
                const [values] = this.from;
                if (!values.value.feed_type) return true;

                if ([13, 14].includes(values.value.feed_type) && !value) {
                  return false;
                }

                return true;
              }
            ),
          })}
          onSubmit={async (values, helpers) => {
            try {
              await API().post(
                "/api/v1/ae/pigs/production-group-validation/",
                {
                  ...values,
                  feed_port: [13, 14].includes(Number(values.feed_type))
                    ? values.feed_port
                    : undefined,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                herd_reference: {
                  ...store.herd_reference,
                  production_groups: store.herd_reference.production_groups.map(
                    (coop, key) => {
                      if (key === Number(id)) {
                        return {
                          ...values,
                          isValidate: true,
                        };
                      }
                      return coop;
                    }
                  ),
                },
              });
              history.push(routes.PIGS.COOPS);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form>
            <BackToCoops />
            <h2 className="cft-section-title mb-3 mt-4">
              {getProductionGroupTypeName(herd.production_group_type)}
            </h2>
            <h4 className="cft-section-subtitle mb-4 mt-5">
              {t(
                "cftPigs:pigsHerdFeedForm.subtitle",
                "Informacje o osobnikach z grupy produkcyjnej trzody"
              )}
            </h4>
            <div className="cft-paper mb-2">
              <GrowthFactor />
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.pigs_number",
                    "Ilość osobników w stadzie (szt)"
                  )}
                />
                <CftTextField
                  isRounded
                  name="pigs_number"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.rearing_period",
                    "Okres odchowu (dni)"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.rearing_period",
                      "Podaj planowany okres tuczu"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="rearing_period"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.target_weight",
                    "Waga docelowa osobnika (kg)"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.target_weight",
                      "Podaj docelową wagę zwierzęcia w tej grupie"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="target_weight"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.repetition",
                    "Ilość rotacji w okresie obliczeniowym"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.repetition",
                      "Pamiętaj, że ilość rotacji pomnożona przez okres odchowu nie może być większa niż długość okresu obliczeniowego"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="repetition"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.feed_type1",
                    "Podaj informacje o paszy stosowanej dla tej grupy produkcyjnej. Możesz zdeklarować jedną paszę."
                  )}
                  className="mb-3"
                />
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.feed_type2",
                    "Wybierz paszę"
                  )}
                />
                <CftSelectField
                  name="feed_type"
                  options={feedTypes.reduce((prev, crr) => {
                    return [
                      ...prev,
                      ...crr.elements.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })),
                    ];
                  }, [])}
                  label={t(
                    "cftChickenBroiler:feedComponentsFields.fields.feed_componentsPlaceholder",
                    "Wybierz z listy"
                  )}
                  isRounded
                  isAlternative
                />
              </CftFormControl>
              <CftFormField name="feed_port" withInput={false}>
                {({ context }) =>
                  [13, 14].includes(Number(context.values.feed_type)) && (
                    <CftFormControl alignLeft>
                      <CftLabel
                        isFullWidth
                        label={t(
                          "cftPigs:pigsHerdFeedForm.labels.feed_port",
                          "Lokalizacja portu"
                        )}
                      />
                      <CftSelectField
                        name="feed_port"
                        options={feedPorts.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                        label={t(
                          "cftChickenBroiler:feedComponentsFields.fields.feed_componentsPlaceholder",
                          "Wybierz z listy"
                        )}
                        isRounded
                        isAlternative
                      />
                    </CftFormControl>
                  )
                }
              </CftFormField>
            </div>
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftPigs:pigsHerdFeedForm.fields.submit",
                  "Zapisz informacje"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="pigs" />
    </div>
  );
}

import React, { useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftBox,
  CftTank,
  CftLabel,
  CftTextField,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftButton,
  CftConditionalRendering,
  CftQuestionGroup,
  CftSelectField,
  CftFormErrorFocus,
  CftTooltip,
} from "../../../../components";
import routes from "../../../../utils/cftRoutes";
import { useCft } from "../../../../utils/CftProvider";
import useCftOptions from "../../../../utils/useCftOptions";
import { scrollToLastElement } from "../../../../utils/cftUtils";
import { farmlandsAPI } from "../../../../utils/cftApi";
import { mapValues } from "./detailsBothCalc.utils";

// Categories Icons
import { ReactComponent as FruitsIcon } from "../../../../../assets/icons/cft/owoce.svg";
import { ReactComponent as VegeIcon } from "../../../../../assets/icons/cft/warzywa.svg";
import { ReactComponent as WheatIcon } from "../../../../../assets/icons/cft/polauprawne.svg";
import { ReactComponent as BeansIcon } from "../../../../../assets/icons/cft/straczkowe.svg";
import { ReactComponent as PotatoesIcon } from "../../../../../assets/icons/cft/ziemniaki.svg";

import { CROP_TYPES, residueManagementTypes } from "./detailsBothCalc.consts";

function DetailsBothCalcForm() {
  const { values, setFieldValue, errors } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const { years, getUnits } = useCftOptions();
  const productsGroupRefs = useRef([]);

  const generateName = name => {
    if (!values.name) {
      setFieldValue("name", `${name} - ${new Date().getFullYear()}`);
    }
  };

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsFarmlands.form.1", "Nazwij swoją uprawę")}
        />
        <CftTextField name="name" />
      </CftFormControl>
      <CftFormControl>
        <CftLabel label={t("cft:detailsFarmlands.form.2", "Co uprawiasz?")} />
        <CftFormField name="cultivate">
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "owoce"}
                  onClick={() => {
                    helpers.setValue("owoce");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("owoce");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <FruitsIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.1", "Owoce")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "warzywa"}
                  onClick={() => {
                    helpers.setValue("warzywa");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("warzywa");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <VegeIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.2", "Warzywa")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "zboża"}
                  onClick={() => {
                    helpers.setValue("zboża");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("zboża");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <WheatIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.3", "Zboża")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "bobowate"}
                  onClick={() => {
                    helpers.setValue("bobowate");
                    setFieldValue("crop", "");
                    setFieldValue("seedAmount", {
                      unit: undefined,
                      value: undefined,
                    });
                    generateName("bobowate");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <BeansIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph cft-box__paragraph--isWide">
                    {t("cft:crop.parents.4", "Bobowate lub strączkowe")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "ziemniaki"}
                  onClick={() => {
                    helpers.setValue("ziemniaki");
                    setFieldValue("crop", "14");
                    setFieldValue("seedAmount", {
                      unit: "",
                      value: "",
                    });
                    generateName("ziemniaki");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <PotatoesIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:crop.parents.5", "Ziemniaki")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        {values.cultivate !== "ziemniaki" && (
          <CftLabel label={t("cft:detailsFarmlands.form.2", "Co uprawiasz?")} />
        )}
        <CftConditionalRendering
          isConditionFulfilled={
            values.cultivate === "owoce" || values.cultivate === ""
          }
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "owoce").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "warzywa"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "warzywa").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "zboża"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "zboża").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
        <CftConditionalRendering
          isConditionFulfilled={values.cultivate === "bobowate"}
        >
          <CftFormField name="crop">
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {CROP_TYPES.filter(({ parent }) => parent === "bobowate").map(
                    ({ id, label, Icon }) => (
                      <CftBox
                        isActive={value === id}
                        onClick={() => helpers.setValue(id)}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        <Icon className="cft-box__svg-fix" />
                        <p className="cft-box__paragraph">
                          {t(label.i18n, label.default)}
                        </p>
                      </CftBox>
                    )
                  )}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftConditionalRendering>
      </CftFormControl>

      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsFarmlands.form.36", "Rok zbioru")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:detailsFarmlands.form.37",
                "Wpisz rok zbioru plonów dla którego chcesz wykonać kalkulację"
              )}
            />
          }
        />
        <CftSelectField name="year" options={years} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:detailsFarmlands.form.38", "Areał uprawy")} />
        <CftUnitField name="areal" units={getUnits(["ha", "a", "m2"])} />
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={values.cultivate === "ziemniaki"}
      >
        <CftFormControl variant="small">
          <CftLabel label={t("cft:detailsFarmlands.form.39", "Ilość nasion")} />
          <CftUnitField name="seedAmount" units={getUnits(["kg", "t", "l"])} />
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:detailsFarmlands.form.40")} />
        <CftUnitField name="harvestAmount" units={getUnits(["t", "kg"])} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:detailsWaterFootprint.form.41")} />
        <CftUnitField name="exit" units={getUnits(["t", "kg"])} />
      </CftFormControl>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t(
            "cft:detailsFarmlands.form.41",
            "Zarządzanie resztkami pożniwnymi"
          )}

          <small>
            {t(
              "cft:detailsFarmlands.form.42",
              " Dla większości upraw podano domyślne wartości ciężaru suchej masy. Jeśli masz lepsze dane, możesz zmienić wartość domyślną."
            )}
          </small>
        </h3>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:detailsFarmlands.form.44", "Pozostała ilość")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:detailsFarmlands.form.43",
                "Oszacuj lub wprowadź dokładne dane dotyczące ilości resztek pożniwnych jeśli dysponujesz takimi danymi."
              )}
            />
          }
        />
        <CftUnitField
          name="remainingQuantity"
          units={getUnits(["kg/ha", "t/ha"])}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:detailsFarmlands.form.45",
            "Gospodarka pozostałościami"
          )}
        />
        <CftSelectField
          name="residueManagement"
          options={residueManagementTypes.map(({ value, label }) => ({
            label: t(`cft:crop.residueManagementTypes.${value}`, label),
            value,
          }))}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:detailsFarmlands.form.46", "Produkty dodatkowe")}
          <small>
            {t(
              "cft:detailsFarmlands.form.47",
              "Czy są jakieś dodatkowe produkty pochodzące z tej uprawy które poźniej wykorzystujesz albo sprzedajesz?"
            )}
          </small>
        </h3>
        <CftFormField name="extraProducts">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around" mt={4}>
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("products", [
                      {
                        id: Date.now(),
                        type: "",
                        value: "",
                      },
                    ]);
                  }}
                >
                  {t("cft:yes", "TAK")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("products", []);
                  }}
                >
                  {t("cft:no", "Nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={values.extraProducts}>
        {values.products.map((product, key) => (
          <CftQuestionGroup
            // eslint-disable-next-line no-return-assign
            ref={el => (productsGroupRefs.current[key] = el)}
            key={key}
            label={`${t(
              "cft:detailsFarmlands.form.46",
              "Produkty dodatkowe"
            )} ${key + 1}`}
            withoutMarginBottom={key + 1 === values.products.length}
            onRemove={
              key !== 0
                ? /* eslint-disable */
                  () => {
                    setFieldValue(
                      "products",
                      values.products.filter(p => p.id !== product.id)
                    );
                  }
                : undefined
              /* eslint-enable */
            }
            hasError={errors.products ? !!errors.products[key] : false}
          >
            <CftFormControl variant="small">
              <CftLabel
                label={t(
                  "cft:detailsFarmlands.form.48",
                  "Typ produktu ubocznego"
                )}
              />
              <CftTextField name={`products[${key}].type`} />
            </CftFormControl>
            <CftFormControl variant="small">
              <CftLabel
                label={t(
                  "cft:detailsFarmlands.form.49",
                  "Wartość dotycząca upraw"
                )}
              />
              <CftTextField name={`products[${key}].value`} adornment="%" />
            </CftFormControl>
          </CftQuestionGroup>
        ))}
        <CftFormControl variant="small">
          <CftButton
            isFullwidth
            onClick={() => {
              setFieldValue("products", [
                ...values.products,
                {
                  id: Date.now(),
                  type: "",
                  value: "",
                },
              ]);
              scrollToLastElement(productsGroupRefs, 130);
            }}
          >
            {t("cft:detailsFarmlands.form.50", "Dodaj kolejne")}
          </CftButton>
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormErrorFocus />
    </Form>
  );
}

export default DetailsBothCalcForm;

export function DetailsBothCalcFormContext({ children }) {
  const history = useHistory();
  const { bothCalc, saveBothCalc, saveBothCalcReport } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);
  return (
    <Formik
      enableReinitialize
      initialValues={bothCalc.details}
      validationSchema={Yup.object().shape({
        extraProducts: Yup.boolean()
          .nullable()
          .required(
            t(
              "cft:validation.requiredOption",
              t("cft:validation.requiredOption", "Wybierz opcję")
            )
          ),
        name: Yup.string()
          .trim()
          .max(20, "Maksymalna ilość znaków to 20")
          .required(t("cft:validation.required", "Wpisz wartość")),
        cultivate: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        crop: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        year: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        harvestAmount: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        exit: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        areal: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(1, "Wartość minimalna to 1"),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        remainingQuantity: Yup.object().shape({
          value: Yup.number()
            .transform((_, v) => Number(v.replace(/,/, ".")))
            .required(t("cft:validation.required", "Wpisz wartość"))
            .typeError(
              t(
                "cft:validation.numberTypeError",
                "Wpisz poprawną wartośc liczbową"
              )
            )
            .min(
              0,
              t("cft:validation.numberMinError", "Wartość minimalna to 0")
            ),
          unit: Yup.string().required(
            t("cft:validation.unitTypeError", "Wybierz jednostkę")
          ),
        }),
        residueManagement: Yup.string().required(
          t("cft:validation.requiredOption", "Wybierz opcję")
        ),
        products: Yup.array().when("extraProducts", {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              type: Yup.string()
                .transform((_, v) => v.replace(/\s/g, ""))
                .max(20, "Maksymalna ilość znaków to 20")
                .required(t("cft:validation.required", "Wpisz wartość")),
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .integer("Tylko wartości całkowite")
                .max(
                  100,
                  t(
                    "cftPigs:validationSchema.max",
                    "Wartość maksymalna to {{max}}.",
                    { max: 100 }
                  )
                )
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
            })
          ),
          otherwise: Yup.array(),
        }),
        seedAmount: Yup.object().when("cultivate", {
          is: v => v === "ziemniaki",
          then: Yup.object().shape({
            value: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            unit: Yup.string().required(
              t("cft:validation.unitTypeError", "Wybierz jednostkę")
            ),
          }),
          otherwise: Yup.object(),
        }),
      })}
      onSubmit={values => {
        saveBothCalc({ details: values });
        farmlandsAPI.detailsValidation(mapValues(values)).then(({ data }) => {
          saveBothCalcReport({
            crop: data,
          });
          history.push(routes.FARMLANDS.BOTH_CALC.SOIL);
        });
      }}
    >
      {children}
    </Formik>
  );
}

import React from "react";
import { useTranslation } from "react-i18next";

import { nFormatter } from "../../utils/cftUtils";
import "./CftReport.scss";

export function CftReportValue({ title, value, unit }) {
  const { i18n } = useTranslation(["cft"]);
  return (
    <div className="cft-report__summary__box">
      <header className="cft-report__summary__header">{title}</header>
      <p className="cft-report__summary__value">
        {typeof value === "string"
          ? value
          : nFormatter(Number(value).toFixed(2), i18n.language)}
        <small>{unit}</small>
      </p>
    </div>
  );
}

function CftReportBox(props) {
  return (
    <div
      className="cft-report__box"
      style={{
        paddingLeft: props.paddingLeft,
        paddingRight: props.paddingRight,
        paddingTop: props.paddingTop,
        paddingBottom: props.paddingBottom,
      }}
      {...props}
    />
  );
}

function CftReportTitle(props) {
  // eslint-disable-next-line jsx-a11y/heading-has-content
  return <h3 className="cft-report__title" {...props} />;
}

function CftReporDivider(props) {
  return <hr className="cft-report__divider" {...props} />;
}

function CftReportSummary({ data }) {
  const { t } = useTranslation(["cft"]);

  return (
    <div className="cft-report__summary">
      <CftReportTitle>
        {t("cft:report.title", "Całkowita emisja")}
      </CftReportTitle>
      {data.map(({ value, unit, title }) => (
        <React.Fragment key={title}>
          <CftReportValue title={title} value={value} unit={unit} />
          <CftReporDivider />
        </React.Fragment>
      ))}
    </div>
  );
}

export default {
  Summary: CftReportSummary,
  Title: CftReportTitle,
  Divider: CftReporDivider,
  Box: CftReportBox,
};

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import "./CftSavingModal.scss";
import { ReactComponent as SaveIcon } from "../../../assets/icons/cft/zapisz.svg";
import { ReactComponent as ExitIcon } from "../../../assets/icons/cft/x.svg";

function CftSavingModal({ isVisible, hide }) {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();

  const startOverflow = () => {
    document.querySelector("body").style = undefined;
    document.querySelector("html").style.overflow = "unset";
  };

  return (
    isVisible && (
      <div className="cft-saving-overlay">
        <div className="cft-saving-box">
          <div
            className="cft-saving-exit"
            onClick={() => {
              startOverflow();
              hide();
            }}
          >
            <ExitIcon />
          </div>
          <div className="cft-saving-content">
            <div className="cft-saving-icon">
              <SaveIcon style={{ height: "auto" }} />
            </div>
            <div className="cft-saving-title">
              {t("cft:modal.saving.title", "TWOJA KALKULACJA ZOSTAŁA POBRANA")}
            </div>
            <div className="cft-saving-description">
              {t(
                "cft:modal.saving.description",
                "Twój postęp kalkulacji został zapisany i pobrany na twój komputer. W celu powrotu do kalkulacji przejdź do Kalkulator CO2 w twoim profilu i wybierz plik. Po pozytywnym wgraniu pliku powrócisz do kalkulacji w tym samym miejscu gdzie skończyłeś"
              )}
            </div>
            <div
              className="cft-saving-back-button"
              onClick={() => {
                startOverflow();
                history.push("/farmer");
              }}
            >
              {t("cft:modal.saving.goBack", "WYJDŹ DO PROFILU")}
            </div>
          </div>
        </div>
      </div>
    )
  );
}

CftSavingModal.propTypes = {
  isVisible: PropTypes.bool,
  goBack: PropTypes.func,
};

CftSavingModal.defaultProps = {
  isVisible: false,
};

export default CftSavingModal;

/* eslint-disable func-names */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftClearForm,
  CftTooltip,
  CftFormErrorFocus,
} from "../../components";
import routes from "../../utils/cftRoutes";
import API from "../../../services/apiHelpers";
import useCftCattleValues from "../../utils/useCftCattleValues";
import BackToCoops from "./BackToCoops";
import { cattleProductionGroups } from "./CreateHerdField";
import FeedComponentsFields from "./FeedComponentsFields";

export default function PigsHerdFeedForm() {
  const token = Cookies.get("user_token");
  const { updateStore, store } = useCftCattleValues();
  const { t, i18n } = useTranslation([
    "cftTurkey",
    "cftChickenBroiler",
    "cftPigs",
    "cftCattle",
  ]);
  const history = useHistory();
  const { id } = useParams();

  if (!id) {
    return <Redirect to={routes.PIGS.COOPS} />;
  }

  const herd = store.herd_reference.production_groups[Number(id)];

  if (!herd) {
    return <Redirect to={routes.PIGS.COOPS} />;
  }

  const calculationPeriodLength = Number(store.main.calculation_period_length);

  const getProductionGroupTypeName = id =>
    cattleProductionGroups.find(group => group.value === Number(id))[
      i18n.language
    ];

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Formik
          enableReinitialize
          initialValues={herd}
          validationSchema={Yup.object().shape({
            animals_quantity: Yup.string().required(
              t(
                "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                "Pole wymagane"
              )
            ),
            rearing_period: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .test(
                "is-right-calculationPeriodLength",
                t("cftChickenBroiler:errors.calculationPeriodLength2", {
                  calculationPeriodLength,
                }),
                function(value) {
                  return Number(value || 0) <= calculationPeriodLength;
                }
              ),
            animal_weight: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .min(
                1,
                t(
                  "cftPigs:validationSchema.min",
                  "Wartość minimalna to {{min}}.",
                  { min: 1 }
                )
              )
              .max(
                600,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 600 }
                )
              ),
            repetition: Yup.string()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .test(
                "is-right-calculationPeriodLength",
                t("cftChickenBroiler:errors.calculationPeriodLength2", {
                  calculationPeriodLength,
                }),
                function(value) {
                  const [{ value: values }] = this.from;
                  return (
                    Number(value || 0) * Number(values.rearing_period || 0) <=
                    calculationPeriodLength
                  );
                }
              ),
            grazing_days: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .min(
                1,
                t(
                  "cftPigs:validationSchema.min",
                  "Wartość minimalna to {{min}}.",
                  { min: 1 }
                )
              )
              .max(
                400,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 400 }
                )
              ),
            grazing_hours: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .min(
                1,
                t(
                  "cftPigs:validationSchema.min",
                  "Wartość minimalna to {{min}}.",
                  { min: 1 }
                )
              )
              .max(
                400,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 400 }
                )
              ),
            milk_production: Yup.number()
              .required(
                t(
                  "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              )
              .min(
                0,
                t(
                  "cftPigs:validationSchema.min",
                  "Wartość minimalna to {{min}}.",
                  { min: 0 }
                )
              )
              .max(
                400,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 400 }
                )
              ),
            feed_components: Yup.array()
              .of(
                Yup.object().shape({
                  feed_type: Yup.string().required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                  quantity: Yup.number()
                    .min(
                      1,
                      t(
                        "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                        "Wartość minimalna to 1."
                      )
                    )
                    .max(
                      2147483647,
                      t(
                        "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                        "Wartość maksymalna to 2147483647."
                      )
                    )
                    .required(
                      t(
                        "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                        "Pole wymagane"
                      )
                    )
                    .typeError(
                      t(
                        "cftChickenBroiler:chickenBroilerMainForm.fields.errorNumber",
                        "Podaj wartość liczbową"
                      )
                    )
                    .integer(
                      t(
                        "cftChickenBroiler:chickenBroilerMainForm.fields.errorinteger",
                        "Podaj wartość całkowitą"
                      )
                    ),
                })
              )
              .min(1),
          })}
          onSubmit={async (values, helpers) => {
            try {
              await API().post(
                "/api/v1/ae/cattle/production-group-validation/",
                {
                  ...values,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                herd_reference: {
                  ...store.herd_reference,
                  production_groups: store.herd_reference.production_groups.map(
                    (coop, key) => {
                      if (key === Number(id)) {
                        return {
                          ...values,
                          isValidate: true,
                        };
                      }
                      return coop;
                    }
                  ),
                },
              });
              history.push(routes.CATTLE.COOPS);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form>
            <BackToCoops />
            <h2 className="cft-section-title mb-3 mt-4">
              {getProductionGroupTypeName(herd.production_group_type)}
            </h2>
            <h4 className="cft-section-subtitle mb-4 mt-5">
              {t(
                "cftPigs:pigsHerdFeedForm.subtitle",
                "Informacje o osobnikach z grupy produkcyjnej trzody"
              )}
            </h4>
            <div className="cft-paper mb-2">
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.pigs_number",
                    "Ilość osobników w stadzie (szt)"
                  )}
                />
                <CftTextField
                  isRounded
                  name="animals_quantity"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.animals_quantity",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftPigs:pigsHerdFeedForm.labels.rearing_period",
                    "Okres odchowu (dni)"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.rearing_period",
                      "Podaj planowany okres tuczu"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="rearing_period"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:pigsHerdFeedForm.labels.target_weight",
                    "Masa osobnika (kg)"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.target_weight",
                      "Podaj docelową wagę zwierzęcia w tej grupie"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="animal_weight"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:pigsHerdFeedForm.labels.repetition",
                    "Ilość wymian w okresie obliczeniowym"
                  )}
                >
                  <CftTooltip
                    content={t(
                      "cftPigs:tooltips.repetition",
                      "Pamiętaj, że ilość rotacji pomnożona przez okres odchowu nie może być większa niż długość okresu obliczeniowego"
                    )}
                  />
                </CftLabel>
                <CftTextField
                  isRounded
                  name="repetition"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
            </div>
            <h4 className="cft-section-subtitle mb-4 mt-5">
              {t(
                "cftCattle:pigsHerdFeedForm.subtitle3",
                "Podaj informacje o wypasie"
              )}
            </h4>
            <div className="cft-paper mb-2">
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:pigsHerdFeedForm.labels.grazing_days",
                    "Liczba dni wypasu w okresie obliczeniowym"
                  )}
                  className="mb-3"
                />
                <CftTextField
                  isRounded
                  name="grazing_days"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:pigsHerdFeedForm.labels.grazing_hours",
                    "Liczba godzin wypasu w ciągu dnia"
                  )}
                  className="mb-3 mt-4"
                />
                <CftTextField
                  isRounded
                  name="grazing_hours"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000"
                />
              </CftFormControl>
            </div>
            <h4 className="cft-section-subtitle mb-4 mt-5">
              {t("cftCattle:pigsHerdFeedForm.subtitle2", "Stosowane pasze")}
            </h4>
            <div className="cft-paper mb-2">
              <FeedComponentsFields />
            </div>
            <h4 className="cft-section-subtitle mb-4 mt-5">
              {t(
                "cftCattle:pigsHerdFeedForm.subtitle4",
                "Podaj informacje o ilości produkowanego mleka na okres obliczeniowy"
              )}
            </h4>
            <div className="cft-paper mb-2">
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:pigsHerdFeedForm.labels.milk_production",
                    "Ilość produkowanego mleka (kg)"
                  )}
                  className="mb-3"
                />
                <CftTextField
                  isRounded
                  name="milk_production"
                  placeholder={t(
                    "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="00000"
                />
              </CftFormControl>
            </div>
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftPigs:pigsHerdFeedForm.fields.submit",
                  "Zapisz informacje"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="cattle" />
    </div>
  );
}

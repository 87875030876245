import React, { useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import {
  CftFormControl,
  CftFormErrorFocus,
  CftQuestionGroup,
  CftButton,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import { scrollToLastElement } from "../../../utils/cftUtils";
import EventTypeFields from "./EventTypeFields";
import { farmlandsAPI } from "../../../utils/cftApi";
import { mapValues } from "./irrigation.utils";

function IrrigationForm() {
  const { values, setFieldValue, errors } = useFormikContext();
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  const initEvent = () => ({
    id: Date.now(),
    type: "",
    waterAmount: "",
    wateredArea: "",
    waterSource: "",
    depthSource: "",
    distanceSource: {
      unit: "",
      value: "",
    },
    energySource: "",
  });

  return (
    <Form className="cft-form narrow-labels">
      {values.events.map((source, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (groupRefs.current[key] = el)}
          key={key}
          label={`${t("cft:fertilizers.form.25", "Events")} ${key + 1}`}
          withoutMarginBottom={key + 1 === values.events.length}
          onRemove={() => {
            setFieldValue(
              "events",
              values.events.filter(f => f.id !== source.id)
            );
            scrollToLastElement(groupRefs, 130);
          }}
          hasError={errors.events ? !!errors.events[key] : false}
        >
          <EventTypeFields id={key} name="events" />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftButton
          isFullwidth
          onClick={() => {
            setFieldValue("events", [...values.events, initEvent()]);
            scrollToLastElement(groupRefs, 130);
          }}
        >
          {values.events.length
            ? t("cft:irrigation.form.25", "Dodaj kolejny zdarzenie")
            : t("cft:irrigation.form.26", "Dodaj zdarzenie")}
        </CftButton>
      </CftFormControl>
      <CftFormErrorFocus />
    </Form>
  );
}

export default IrrigationForm;

export function IrrigationFormContext({ children }) {
  const history = useHistory();
  const { farmlands, saveFarmlands, saveFarmlandsReport } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);

  return (
    <Formik
      enableReinitialize
      initialValues={farmlands.irrigation}
      validationSchema={Yup.object().shape({
        events: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            waterAmount: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            wateredArea: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .integer("Tylko wartości całkowite")
              .max(
                100,
                t(
                  "cftPigs:validationSchema.max",
                  "Wartość maksymalna to {{max}}.",
                  { max: 100 }
                )
              )
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            waterSource: Yup.string().required(
              t("cft:validation.requiredOption", "Wybierz opcję")
            ),
            depthSource: Yup.number()
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .required(t("cft:validation.required", "Wpisz wartość"))
              .typeError(
                t(
                  "cft:validation.numberTypeError",
                  "Wpisz poprawną wartośc liczbową"
                )
              )
              .min(
                0,
                t("cft:validation.numberMinError", "Wartość minimalna to 0")
              ),
            distanceSource: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              unit: Yup.string().required(
                t("cft:validation.unitTypeError", "Wybierz jednostkę")
              ),
            }),
            energySource: Yup.string()
              .transform((_, v) => v.replace(/\s/g, ""))
              .required(t("cft:validation.required", "Wpisz wartość")),
          })
        ),
      })}
      onSubmit={values => {
        saveFarmlands({ irrigation: values });
        farmlandsAPI.irigationValidation(mapValues(values)).then(({ data }) => {
          saveFarmlandsReport(data);
          history.push(routes.FARMLANDS.CARBON_CHANGES);
        });
      }}
    >
      {children}
    </Formik>
  );
}

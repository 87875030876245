import React from "react";
import { Col, Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

import { ReactComponent as GasEmissionIcon } from "../../../assets/icons/cft/emisja.svg";
import { ReactComponent as CostStructureIcon } from "../../../assets/icons/cft/koszta.svg";
import { ReactComponent as SpecificInformatioIcon } from "../../../assets/icons/cft/raport.svg";
import { ReactComponent as PerformancenIcon } from "../../../assets/icons/cft/wydajnosc.svg";
import { CftExportReport, CftTank } from "../index";

import "./CftReportNavigation.scss";

export function GasEmissionNav({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <div className="cft-report-navigation__cta" onClick={onClick}>
      <GasEmissionIcon />
      <span>
        {t("cft:costStructure.report.navigation.emission", "Emisja gazów")}
      </span>
    </div>
  );
}

export function PerformancenNav({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <div className="cft-report-navigation__cta" onClick={onClick}>
      <PerformancenIcon />
      <span>
        {t("cft:costStructure.report.navigation.performance", "Wydajność")}
      </span>
    </div>
  );
}
export function SpecificInformationNav({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <div className="cft-report-navigation__cta" onClick={onClick}>
      <SpecificInformatioIcon />
      <span>
        {t("cft:costStructure.report.navigation.details", "Dane szczegółowe")}
      </span>
    </div>
  );
}
export function CostStructureNav({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <div className="cft-report-navigation__cta" onClick={onClick}>
      <CostStructureIcon />
      <span>
        {t(
          "cft:costStructure.report.navigation.structure",
          "Struktura kosztów"
        )}
      </span>
    </div>
  );
}
export function WaterFootprintNav({ onClick }) {
  const { t } = useTranslation(["cft"]);
  return (
    <div className="cft-report-navigation__cta" onClick={onClick}>
      <GasEmissionIcon />
      <span>
        {t("cft:costStructure.report.navigation.waterFootprint", "Ślad wodny")}
      </span>
    </div>
  );
}

function CftReportNavigation({ type }) {
  const onClick = selector => {
    const element = document.querySelector(selector);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 200;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <nav className="cft-report-navigation">
      <Container>
        <Row>
          <Col xs={12}>
            <CftTank
              className="cft-report-navigation__actions"
              justifyContent="space-between"
            >
              <CftTank className="cft-report-navigation__sections">
                {type === "waterFootprint" ? (
                  <>
                    <WaterFootprintNav
                      onClick={() => onClick("#waterFootprint")}
                    />
                  </>
                ) : (
                  <>
                    <GasEmissionNav onClick={() => onClick("#gasEmission")} />
                    {/* <PerformancenNav onClick={() => onClick("#performancen")} /> */}
                    <SpecificInformationNav
                      onClick={() => onClick("#specificInformation")}
                    />
                    <CostStructureNav
                      onClick={() => onClick("#costStructure")}
                    />
                    <WaterFootprintNav
                      onClick={() => onClick("#waterFootprint")}
                    />
                  </>
                )}
                {!isMobile && (
                  <div style={{ marginLeft: "1rem" }}>
                    <CftExportReport type={type} isSmall noMargin />
                  </div>
                )}
              </CftTank>
            </CftTank>
          </Col>
        </Row>
      </Container>
    </nav>
  );
}

export default CftReportNavigation;

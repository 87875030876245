import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftTank,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import FuelAndEnergyBothCalcForm, {
  FuelAndEnergyBothCalcFormContext,
} from "../../../forms/farmlands/bothCalc/FuelAndEnergyBothCalcForm/FuelAndEnergyBothCalc.form";

function FuelAndEnergyBothCalcPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveBothCalc, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={42}
          cropName={meta.title.bothCalc}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="bothCalc" />
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveBothCalc({
                    fuelAndEnergy: values,
                  });
                  history.push(routes.FARMLANDS.BOTH_CALC.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:fuelAndEnergy.title", "Bezpośrednie zużycie energii")}
          onBack={() => history.push(routes.FARMLANDS.BOTH_CALC.FERTILIZERS)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>
              {t("cft:fuelAndEnergy.title", "Bezpośrednie zużycie energii")}
            </h3>
            <p>
              {t(
                "cft:fuelAndEnergy.subtitle",
                "Wprowadź dane dotyczące energii elektrycznej i paliw używanych do produkcji roślinnej i ich przetwarzania w gospodarstwie."
              )}
            </p>
          </>
        }
      >
        <FuelAndEnergyBothCalcForm />
        <CftReportProgress type="bothCalc" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <FuelAndEnergyBothCalcFormContext>
      <FuelAndEnergyBothCalcPage />
    </FuelAndEnergyBothCalcFormContext>
  );
};

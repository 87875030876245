export const LOG_USER = "LOG_USER";
export const LOG_USER_FAIL = "LOG_USER_FAIL";
export const LOG_USER_SUCCESS = "LOG_USER_SUCCESS";
export const LOG_OUT = "LOG_OUT";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAIL = "GET_USER_DATA_FAIL";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

export const CHANGE_PASSWORD_USER_PANEL = "CHANGE_PASSWORD_USER_PANEL";
export const CHANGE_PASSWORD_USER_PANEL_SUCCESS =
  "CHANGE_PASSWORD_USER_PANEL_SUCCESS";
export const CHANGE_PASSWORD_USER_PANEL_FAIL =
  "CHANGE_PASSWORD_USER_PANEL_FAIL";

export const ACTIVATE_USER = "ACTIVATE_USER";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAIL = "ACTIVATE_USER_FAIL";

export const SET_REDIRECT = "SET_REDIRECT";

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useHover } from "use-events";

import "./CftBox.scss";

function CftBox({ children, onClick, isActive, isInvalid, variant, ...props }) {
  const [isHoveredState, bind] = useHover();
  const [isActiveState, setActiveState] = useState(isActive);
  const isRenderProp = typeof children === "function";

  useEffect(() => {
    if (isActive !== null) {
      setActiveState(isActive);
    }
  }, [isActive]);

  return (
    <div
      {...bind}
      className={classnames("cft-box", {
        "cft-box--isHovered": isHoveredState,
        "cft-box--isActive": isActive || isActiveState,
        "cft-box--isInvalid": isInvalid,
        "cft-box--isSmall": variant === "small",
        "cft-box--isMedium": variant === "medium",
        "cft-box--isLarge": variant === "large",
        "cft-box--isFullWidth": variant === "fullWidth",
      })}
      onClick={e => {
        // eslint-disable-next-line valid-typeof
        if (typeof isActive === "null") {
          setActiveState(value => !value);
        }
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      {isRenderProp ? children({ isHoveredState, isActiveState }) : children}
    </div>
  );
}

export function CftBoxWrapper({ children }) {
  return <div className="cft-box-wrapper">{children}</div>;
}

export function CftBoxAnimationContent({ children }) {
  return (
    <div className="cft-box__animation-btn-box">
      <div className="cft-box__animation-btn-box__content">{children}</div>
    </div>
  );
}

CftBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  variant: PropTypes.oneOf(["small", "medium", "large", "fullWidth"]),
};

CftBox.defaultProps = {
  onClick: null,
  isActive: null,
  variant: "small",
};

export default CftBox;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftTank,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";

import SoilFarmlandsForm, {
  SoilFarmlandsFormContext,
} from "../../forms/farmlands/SoilFarmlandsForm/SoilFarmlands.form";

function SoilPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveFarmlands, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={14}
          cropName={meta.title.farmlands}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="farmlands" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveFarmlands({
                    soil: values,
                  });
                  history.push(routes.FARMLANDS.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:soil.title", "Charakterystyka gleby")}
          onBack={() => history.push(routes.FARMLANDS.DETAILS)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:soil.title", "Charakterystyka gleby")}</h3>
            <p>
              {t(
                "cft:soil.subtitle",
                "W swojej ocenie określ charakterystykę gleby na polu."
              )}
            </p>
            <p className="soil-page__article">
              {t(
                "cft:soil.article",
                "Jeśli nie wiesz, gdzie zodbyć informację o glebie, przejdź do artykułu"
              )}
              <Link to="/articles/obecny-system-badania-probek-gleb-w-polsce">
                {t(
                  "cft:soil.link",
                  "Czym jest i gdzie sprawdzę swoją charakterystykę gleby"
                )}
              </Link>
            </p>
          </>
        }
      >
        <SoilFarmlandsForm />
        <CftReportProgress type="farmlands" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <SoilFarmlandsFormContext>
      <SoilPage />
    </SoilFarmlandsFormContext>
  );
};

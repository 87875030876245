import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import TransportType from "./TransportType";

import {
  CftFormField,
  CftError,
  CftFormControl,
  CftFormErrorFocus,
  CftButton,
  CftTank,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import { initTransport } from "../../farmlands/TransportFarmlandsForm/TransportFarmlands.form";
import { farmlandsAPI } from "../../../utils/cftApi";
import { mapValues } from "../../farmlands/TransportFarmlandsForm/transportFarmlands.utils";

function TransporDPForm() {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation(["cft"]);

  return (
    <Form className="cft-form narrow-labels">
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t(
            "cft:transport.formDairyProduction.1",
            "Czy korzystasz z transportu dla tej hodowli?"
          )}
        </h3>
        <CftFormField name="used">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value === true}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("transports", [initTransport()]);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("transports", []);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      {!!values.used && <TransportType />}
      <CftFormErrorFocus />
    </Form>
  );
}

export default TransporDPForm;

export function TransporDPFormContext({ children }) {
  const history = useHistory();
  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();
  const { t } = useTranslation(["cft"]);
  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.transport}
      validationSchema={Yup.object().shape({
        transports: Yup.mixed().when("used", {
          is: v => typeof v === "boolean",
          then: Yup.array().of(
            Yup.object().shape({
              method: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              type: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              weight: Yup.object().shape({
                value: Yup.number()
                  .transform((_, v) => Number(v.replace(/,/, ".")))
                  .required(t("cft:validation.required", "Wpisz wartość"))
                  .typeError(
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                  .min(
                    0,
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  ),
                unit: Yup.string().required(
                  t("cft:validation.unitTypeError", "Wybierz jednostkę")
                ),
              }),
              distance: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              label: Yup.string()
                .required(t("cft:validation.required", "Wpisz wartość"))
                .trim()
                .max(20, "Maksymalna ilość znaków to 20"),
            })
          ),
          otherwise: Yup.array(),
        }),
      })}
      onSubmit={values => {
        farmlandsAPI.transportValidation(mapValues(values)).then(({ data }) => {
          saveDairyProductionReport(data);
          saveDairyProduction({ transport: values });
          history.push(routes.DAIRY_PRODUCTION.REPORT);
        });
      }}
    >
      {children}
    </Formik>
  );
}

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useArticlesInfinite } from "../../../services/apiHooks/articles";
import { ArticlesCategories } from "../../blocks/Articles/NewArticlesCategories";
import ArticlesContainer from "../../common/Layouts/ArticlesContainer";
import "./style.scss";
import { Metadata } from "../../common";
import { pressNavigation } from "../../../services/helpers/articles";
import { allCategories } from "../../../services/helpers/articlesCategories/categories";

const ArticlesPage = ({ category, parentCategory }) => {
  const pressCategories = [53, 54, 55, 56];
  const makroCategories = [90];

  const [activeCategory, setActiveCategory] = useState({
    id: category
      ? pressCategories.includes(category.id) ||
        makroCategories.includes(category.id)
        ? category.id
        : null
      : null,
  });

  const [activeSubcategory, setActiveSubcategory] = useState({ id: null });
  const {
    t,
    i18n: { language },
  } = useTranslation(["articlesMeta", "metaInfo"]);
  const history = useHistory();

  const categoriesToParams = category =>
    category.subcategories_id && category.subcategories_id.length
      ? category.subcategories_id.join(",")
      : category.id;

  const handlePressFilterCategoriesList = () => {
    if (category && category.id === 53) return pressCategories.join(",");
    if (category && category.id === 90) return makroCategories.join("");
    if (category && category.id && pressCategories.includes(category.id))
      return category.id;
    if (activeSubcategory.id) return categoriesToParams(activeSubcategory);
    if (activeCategory.id) return categoriesToParams(activeCategory);
    return [
      ...Object.keys(allCategories)
        .filter(id => !pressCategories.includes(Number(id)))
        .map(id => Number(id)),
    ].join(",");
  };

  const {
    articlesData,
    nextPage,
    nextPageAvailable,
    isLoading,
    isValidating,
  } = useArticlesInfinite({
    category: activeCategory,
    categoriesList: handlePressFilterCategoriesList(),
  });

  useEffect(() => {
    if (!category) {
      return;
    }
    if (parentCategory && parentCategory.id) {
      setActiveCategory(parentCategory);
      setActiveSubcategory(category);
      return;
    }
    if (activeCategory !== category.id) {
      setActiveCategory(category);
    }
  }, []);

  const handlePageTitle = () => {
    if (category && category.id) {
      if (pressCategories.includes(category.id))
        return t("articlesMeta:categories.53");
      if (makroCategories.includes(category.id))
        return t("articlesMeta:categories.90");
    }
    return t("articlesMeta:title");
  };

  return (
    <>
      <Helmet>
        <meta key="robots" name="robots" content="noindex,nofollow" />
      </Helmet>
      {[75, 18, 23, 28, 34, 39, 53, 57, 68, 90].indexOf(activeCategory.id) !==
      -1 ? (
        <Metadata
          name={t(`metaInfo:titles.${activeCategory.id}`)}
          description={t(`metaInfo:description.${activeCategory.id}`)}
        />
      ) : (
        <Metadata
          name={t("metaInfo:articlesPage.title")}
          description={t("metaInfo:articlesPage.description")}
        />
      )}
      <ArticlesContainer
        title={<h2>{handlePageTitle()}</h2>}
        categories={
          <div className="ArticleSection__tags--space">
            {!makroCategories.includes(category ? category.id : false) && (
              <ArticlesCategories
                activeCategory={
                  category && pressCategories.includes(category.id)
                    ? activeSubcategory || activeCategory
                    : activeCategory
                }
                allCategoriesTagLabel={t("articlesMeta:categories.all")}
                setActiveCategory={cat => {
                  history.push(cat.path);
                }}
                articlesNav={
                  category
                    ? pressCategories.includes(category.id)
                      ? pressNavigation(t, language)
                      : null
                    : null
                }
                defaultCat={
                  category
                    ? pressCategories.includes(category.id)
                      ? 53
                      : null
                    : null
                }
                sliceCat={
                  category ? !pressCategories.includes(category.id) : true
                }
                subcategories={
                  category
                    ? pressCategories.includes(category.id)
                      ? []
                      : activeCategory.subcategories
                    : activeCategory.subcategories
                }
                activeSubcategoryId={parentCategory ? category.id : null}
                showSubcategories={
                  category ? !pressCategories.includes(category.id) : true
                }
              />
            )}
          </div>
        }
        isLoading={isLoading}
        isValidating={isValidating}
        articlesPages={articlesData}
        type="article"
        loadMore={nextPageAvailable}
        loadMoreAction={nextPage}
        hideCat={pressCategories}
      />
    </>
  );
};

export default ArticlesPage;

import React from "react";
import { Col, Row } from "reactstrap";

import { ReactComponent as MagIcon } from "../../../../../assets/icons/mag.svg";
import { ReactComponent as ChatIcon } from "../../../../../assets/icons/chat.svg";
import { ReactComponent as DocIcon } from "../../../../../assets/icons/doc.svg";

import "./RealEstateHowItWork.scss";

function RealEstateHowItWork() {
  return (
    <div className="real-estate-wow-it-work b-section">
      <header>
        <h2>Jak to działa?</h2>
        <hr />
      </header>
      <Row>
        <Col xs={12} md={4}>
          <div className="icon">
            <MagIcon />
          </div>
          <h3>
            1. Wyszukaj
            <br />
            nieruchomość
          </h3>
          <p>
            Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę
            jak najbardziej dopasowaną do Twoich potrzeb. Wyszukiwarka działa w
            sposób dynamiczny, po ustawieniu kryterium poniżej otrzymasz
            aktualne oferty. Ustaw lokalizację, kategorię nieruchomości,
            powierzchnię w gruntu w przypadku gruntów lub powierzchnię lokalu w
            przypadku budynków, a następnie wybierz interesującą Cię ofertę.
          </p>
        </Col>
        <Col xs={12} md={4}>
          <div className="icon">
            <ChatIcon />
          </div>
          <h3>
            2. Skontaktuj się
            <br />z przedstawicielem
          </h3>
          <p>
            Napisz wiadomość e-mail do opiekuna oferty, lub zadzwoń do niego na
            podany numer telefonu. Możesz też napisać wiadomość błyskawiczną
            wypełniając udostępniony formularz, klikając „wyślij”.
          </p>
        </Col>
        <Col xs={12} md={4}>
          <div className="icon">
            <DocIcon />
          </div>
          <h3>
            3. Otrzymaj szczegółowe
            <br />
            informacje o nieruchomości
          </h3>
          <p>
            Dopytaj o wszystkie intersujące Cię kwestie dotyczące tej
            nieruchomości i przejdź wspólnie z opiekunem do procedury zakupu.
          </p>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={12}>
          <p className="real-estate-wow-it-work__note">
            Zapoznaj się z{" "}
            <a href="/static/pdf/Regulamin_Nieruchomosci.pdf">
              Regulaminem Modułu Nieruchomości.
            </a>
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default RealEstateHowItWork;

import Cookies from "js-cookie";
import { flatten } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { ReactComponent as CerealIcon } from "../../../../assets/icons/cereal.svg";
import { MarketType } from "../../../averagePrices/types";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import {
  useFilteredCerealsData,
  useListOfCereals,
  useListOfRegions,
} from "./api";

const CerealsWidget = ({ userDefaults, onLoginClick }) => {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["averagePrices"]);
  const { data: reportData } = useFilteredCerealsData(userDefaults);
  const { data: cereals } = useListOfCereals(token);
  const regionsData = useListOfRegions();

  const userIsLogin = token !== "";

  if (!reportData || !cereals) {
    return null;
  }

  const [lastWeekData] = reportData.results;

  const cerealsInTable = cereals.filter(cereal =>
    userDefaults.interested_products.includes(cereal.id)
  );

  const productList = (() => {
    if (!lastWeekData) {
      return [];
    }

    return cerealsInTable.map(cereal => {
      const productData = lastWeekData.products.find(
        product => product.product.id === cereal.id
      );

      return createTableRow({
        id: cereal.id,
        name: t(`averagePrices:cereals.${cereal.id}`),
        currentPrice: productData ? productData.current_price : undefined,
        percentagePriceChange: productData
          ? productData.percentage_price_change
          : undefined,
      });
    });
  })();

  let marketType = "";
  let regionName = "";
  if (regionsData) {
    const { markets, regions } = regionsData;
    const { market_type, region, market_place } = userDefaults;

    if (market_type === MarketType.Targowisko) {
      const subregions = markets.reduce(
        (prev, curr) => prev.concat(curr.markets),
        []
      );
      regionName = flatten(subregions).find(
        subregion => subregion.id === market_place
      ).name;
      marketType = t("averagePrices:cerealsPage.marketplace");
    } else {
      regionName = regions.find(point => point.id === region).name;
      marketType = t("averagePrices:widget.purchasing");
    }
  }

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>
          {t("averagePrices:widget.titleCereals")} - {marketType}
        </span>
        <div className="details">
          <span>
            {regionName && regionName}
            <MarketWidget.PeriodSection
              startWeek={new Date(lastWeekData.week_start_date)}
              endWeek={new Date(lastWeekData.week_end_date)}
            />
          </span>
          <MarketWidget.Customization
            isUserLogged={userIsLogin}
            linkTo="/srednie-ceny/zboza"
            onLoginClick={onLoginClick}
          />
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default CerealsWidget;

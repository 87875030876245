import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import clx from "classnames";
import { useTranslation } from "react-i18next";

import {
  CftReport,
  CftReportValue,
  CftProgress,
  CftCard,
  CftButton,
  CftMobileReportWrapper,
} from "../index";
import { ReactComponent as CancelIcon } from "../../../assets/icons/cft/anuluj.svg";

import "./CftCostStructure.scss";

function CftUnitSwitch({ isChecked, onChange, disabled }) {
  const { t } = useTranslation(["cft"]);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
    <label
      className={clx("cft-unit-switch", {
        "cft-unit-switch--isDisabled": disabled,
      })}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className="slider">
        <span className="label label--tonne">
          {t("cft:costStructure.perTonne", "per tonne")}
        </span>
        <span className="label">
          {t("cft:costStructure.perValue", "per value")}
        </span>
      </span>
    </label>
  );
}

function CftCostStructureTable({ title, data, onAdd, onChange }) {
  const onUpdate = (value, key) => {
    const updateData = data.map((data, k) => {
      if (k === key) {
        const isPerUnit = data.perUnit;
        const number = Number(value.replace(/,/, "."));
        const parsedValue = Number.isNaN(number) ? 0 : number;
        return {
          ...data,
          value,
          total: isPerUnit
            ? data.netValue * Number(parsedValue)
            : Number(parsedValue),
        };
      }

      return data;
    });

    onChange(updateData);
  };

  const onNameUpdate = (value, key) => {
    const updateData = data.map((data, k) => {
      if (k === key) {
        return {
          ...data,
          name: value,
        };
      }

      return data;
    });

    onChange(updateData);
  };

  const onDelete = key => {
    const updateData = data.filter((_, k) => k !== key);

    onChange(updateData);
  };

  const onSwitch = key => {
    const updateData = data.map((data, k) => {
      if (k === key) {
        const isPerUnit = !data.perUnit;
        return {
          ...data,
          perUnit: !data.perUnit,
          total: isPerUnit ? data.netValue * data.value : data.value,
        };
      }

      return data;
    });

    onChange(updateData);
  };
  const { t } = useTranslation(["cft"]);

  const hasEmptyName = data.filter(({ name }) => !name.length).length >= 1;

  return (
    <CftMobileReportWrapper>
      <div className="cft-cost-structure-table">
        <CftCard>
          <CftReport.Title>{title}</CftReport.Title>
          {!!data.length && (
            <table className="cft-table">
              <thead>
                <tr>
                  <th>{t("cft:costStructure.table.product", "Product")}</th>
                  <th>{t("cft:costStructure.table.amount", "Net amount")}</th>
                  <th>{t("cft:costStructure.table.value", "Value (pln)")}</th>
                  <th>{t("cft:costStructure.table.units", "Units")}</th>
                  <th>{t("cft:costStructure.table.total", "Total (pln)")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  (
                    { name, netValue, unit, total, perUnit, value, id },
                    key
                  ) => (
                    <tr key={id}>
                      <td>
                        {key === 0 ? (
                          t(name, name)
                        ) : (
                          <div className="cft-cost-structure-table__action">
                            <CancelIcon onClick={() => onDelete(key)} />
                            <div className="cft-text-field">
                              <div className="cft-text-field__input">
                                <input
                                  defaultValue={name}
                                  type="text"
                                  onChange={({ target }) => {
                                    onNameUpdate(target.value, key);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                      <td>
                        {netValue} {unit}
                      </td>
                      <td>
                        <div className="cft-text-field">
                          <div className="cft-text-field__input">
                            <input
                              className="cft-text-field__input__number"
                              defaultValue={value}
                              type="number"
                              onChange={({ target }) => {
                                onUpdate(target.value, key);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <CftUnitSwitch
                          isChecked={!perUnit}
                          onChange={() => onSwitch(key)}
                          disabled={key !== 0}
                        />
                      </td>
                      <td>{total}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          )}
          <CftButton variant="active" onClick={onAdd} disabled={hasEmptyName}>
            {t("cft:costStructure.table.button", "add item")}
          </CftButton>
        </CftCard>
      </div>
    </CftMobileReportWrapper>
  );
}

const productInit = name => ({
  name,
  netValue: 9,
  unit: "t",
  perUnit: false,
  value: 0,
  total: 0,
  id: Date.now(),
});

const cropsInit = name => ({
  name,
  netValue: 9,
  unit: "t",
  perUnit: false,
  value: 0,
  total: 0,
  id: Date.now(),
});

const energytInit = {
  name: "cft:costStructure.table.irrigation",
  netValue: 9,
  unit: "t",
  perUnit: false,
  value: 0,
  total: 0,
  id: Date.now(),
};

const transportInit = {
  name: "cft:costStructure.table.road",
  netValue: 9,
  unit: "t",
  perUnit: false,
  value: 0,
  total: 0,
  id: Date.now(),
};

function calculateSum(list = [], unit = 10) {
  return list.reduce(
    (prev, curr) => {
      const { total } = curr;
      const perHectare = total / unit;
      const perTonne = total / (curr.netValue || 1);

      return {
        max: prev.max > total ? prev.max : total,
        min: prev.min < total ? prev.min : total,
        total: prev.total + total,
        perHectare: prev.perHectare + perHectare,
        perTonne: prev.perTonne + perTonne,
      };
    },
    {
      max: 0,
      min: 0,
      total: 0,
      perHectare: 0,
      perTonne: 0,
    }
  );
}

function CftCostStructure({ name }) {
  const [product, setProduct] = useState([productInit(name)]);
  const [crops, setCrops] = useState([cropsInit(name)]);
  const [energy, setEnergy] = useState([energytInit]);
  const [transport, setTransport] = useState([transportInit]);
  const [other, setOther] = useState([]);

  const income = calculateSum(crops);
  const expenditure = calculateSum([
    ...product,
    ...energy,
    ...transport,
    ...other,
  ]);

  const breakdownOfExpenditures = {
    product: income - expenditure.total,
    crops: calculateSum(product),
    energy: calculateSum(energy),
    transport: calculateSum(transport),
    other: calculateSum(other),
  };

  const max = [
    breakdownOfExpenditures.product,
    breakdownOfExpenditures.crops,
    breakdownOfExpenditures.energy,
    breakdownOfExpenditures.transport,
    breakdownOfExpenditures.other,
  ].reduce((prev, curr) => {
    return prev <= curr.total ? curr.total : prev;
  }, 0);

  const percentage = value => {
    const p = (value * 100) / max;
    return Number.isNaN(p) ? 0 : p;
  };

  const percentageBreakdownOfExpenditures = value => {
    const p = (value * 100) / expenditure.total;
    return Number.isNaN(p) ? 0 : p;
  };
  const { t } = useTranslation(["cft"]);

  return (
    <>
      <CftCard>
        <CftReport.Box>
          <Row className="cft-cost-structure">
            <Col xs={12} md={2}>
              <CftReport.Title>
                {t("cft:costStructure.report.income", "Przychód")}
              </CftReport.Title>
              <CftReportValue
                title={t("cft:costStructure.total", "Razem (zł)")}
                value={income.total}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perHectare", "za hektart")}
                value={income.perHectare}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perTonne", "za tone")}
                value={income.perTonne}
                unit="PLN"
              />
              <CftReport.Divider />
            </Col>
            <Col xs={12} md={2}>
              <CftReport.Title>
                {t("cft:costStructure.report.expenditures", "Wydatki")}
              </CftReport.Title>
              <CftReportValue
                title={t("cft:costStructure.total", "Razem (zł)")}
                value={expenditure.total}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perHectare", "za hektart")}
                value={expenditure.perHectare}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perTonne", "za tone")}
                value={expenditure.perTonne}
                unit="PLN"
              />
              <CftReport.Divider />
            </Col>
            <Col xs={12} md={2}>
              <CftReport.Title>
                {t("cft:costStructure.report.profit", "Profit")}
              </CftReport.Title>
              <CftReportValue
                title={t("cft:costStructure.total", "Razem (zł)")}
                value={income.total - expenditure.total}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perHectare", "za hektart")}
                value={income.perHectare - expenditure.perHectare}
                unit="PLN"
              />
              <CftReport.Divider />
              <CftReportValue
                title={t("cft:costStructure.perTonne", "za tone")}
                value={income.perTonne - expenditure.perTonne}
                unit="PLN"
              />
              <CftReport.Divider />
            </Col>
            <Col xs={12} md={6}>
              <CftReport.Title>
                {t(
                  "cft:costStructure.report.expenditures",
                  "Breakdown of expenditures"
                )}
              </CftReport.Title>
              <div className="cft-cost-structure__breakdown">
                <h4>{t("cft:costStructure.report.product", "Product")}</h4>
                <CftProgress
                  indicatorValue={percentageBreakdownOfExpenditures(
                    breakdownOfExpenditures.product.total
                  )}
                  value={percentage(breakdownOfExpenditures.product.total)}
                  color="#00965E"
                  withValueIndicator
                />
                <h4>
                  {t("cft:costStructure.report.crops", "Crops and inputs")}
                </h4>
                <CftProgress
                  indicatorValue={percentageBreakdownOfExpenditures(
                    breakdownOfExpenditures.crops.total
                  )}
                  value={percentage(breakdownOfExpenditures.crops.total)}
                  color="#FFA203"
                  withValueIndicator
                />
                <h4>
                  {t(
                    "cft:costStructure.report.energy",
                    "Energy. fuel and water"
                  )}
                </h4>
                <CftProgress
                  indicatorValue={percentageBreakdownOfExpenditures(
                    breakdownOfExpenditures.energy.total
                  )}
                  value={percentage(breakdownOfExpenditures.energy.total)}
                  color="#0069FF"
                  withValueIndicator
                />
                <h4>{t("cft:costStructure.report.transport", "Transport")}</h4>
                <CftProgress
                  indicatorValue={percentageBreakdownOfExpenditures(
                    breakdownOfExpenditures.transport.total
                  )}
                  value={percentage(breakdownOfExpenditures.transport.total)}
                  color="#E92B5C"
                  withValueIndicator
                />
                <h4>{t("cft:costStructure.report.other", "Other")}</h4>
                <CftProgress
                  indicatorValue={percentageBreakdownOfExpenditures(
                    breakdownOfExpenditures.other.total
                  )}
                  value={percentage(breakdownOfExpenditures.other.total)}
                  color="#666F85"
                  withValueIndicator
                />
              </div>
            </Col>
          </Row>
        </CftReport.Box>
      </CftCard>
      <CftCostStructureTable
        title={t("cft:costStructure.report.income", "Przychód")}
        data={crops}
        onAdd={() =>
          setCrops(state => [
            ...state,
            { value: 0, total: 0, name: "", id: Date.now() },
          ])
        }
        onChange={updated => setCrops(updated)}
      />
      <CftCostStructureTable
        title={t("cft:costStructure.report.expenditures", "Wydatki")}
        data={product}
        onAdd={() =>
          setProduct(state => [
            ...state,
            { value: 0, total: 0, name: "", id: Date.now() },
          ])
        }
        onChange={updated => setProduct(updated)}
      />
      <CftCostStructureTable
        title={t("cft:costStructure.report.energy", "Energia. Paliwo i woda")}
        data={energy}
        onAdd={() =>
          setEnergy(state => [
            ...state,
            { value: 0, total: 0, name: "", id: Date.now() },
          ])
        }
        onChange={updated => setEnergy(updated)}
      />
      <CftCostStructureTable
        title={t("cft:costStructure.report.transport", "Transport")}
        data={transport}
        onAdd={() =>
          setTransport(state => [
            ...state,
            { value: 0, total: 0, name: "", id: Date.now() },
          ])
        }
        onChange={updated => setTransport(updated)}
      />
      <CftCostStructureTable
        title={t("cft:costStructure.report.other", "Inny")}
        data={other}
        onAdd={() =>
          setOther(state => [
            ...state,
            { value: 0, total: 0, name: "", id: Date.now() },
          ])
        }
        onChange={updated => setOther(updated)}
      />
    </>
  );
}

export default CftCostStructure;

import React from "react";
import {
  Hero,
  Benefits,
  DownloadDocuments,
  Disclaimer,
  InsuranceScope,
  ContactForm,
  ScrollToSection,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_30.png";

// eslint-disable-next-line import/no-unresolved
import fileA from "../../assets/prf/pdf/02 Generali Sprzęt Rolniczy Karta produktu.pdf";
// eslint-disable-next-line import/no-unresolved
import fileB from "../../assets/prf/pdf/04 Generali Sprzęt Rolniczy Ogólne Warunki Ubezpieczenia.pdf";
// eslint-disable-next-line import/no-unresolved
import fileC from "../../assets/prf/pdf/03 Generali Sprzęt Rolniczy Ustandaryzowany dokument.pdf";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Ubezpieczenie maszyn rolniczych"),
    title: "Ubezpieczenie maszyn rolniczych",
    description:
      "Poszukujesz najlepszej ochrony ubezpieczeniowej maszyn rolniczych wykorzystywanych intensywnie w Twoim gospodarstwie rolnym lub usługach świadczonych w rolnictwie?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Dlaczego warto ubezpieczyć maszyny rolnicze?",
    subtitle:
      "Ponieważ nie przewidzisz ich zniszczeń, uszkodzeń, kradzieży oraz udziału w kolizji czy wypadku",
    benefits: [
      {
        title: "Dedykowany dla maszyn rolniczych zakres ochrony Agro Casco",
        icon: "Tractor",
      },
      {
        title: "Odpowiedzialność za kradzież z każdego miejsca na terenie RP",
        icon: "PaperBookmark",
      },
      {
        title: "Ochrona podczas wykonywania usług międzysąsiedzkich",
        icon: "Shield",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź ubezpieczenie spełniające Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe aby porozmawiać z Ekspertem ds. Ubezpieczeń lub odwiedź nas w Banku BNP.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Formalności załatwimy drogą elektroniczną, abyś Ty mógł spokojnie i bezpiecznie prowadzić  gospodarstwo",
        icon: "HandBulb",
      },
    ],
    withArrow: fileC,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Karta produktu",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileA,
      },
      {
        name: "Ustandaryzowany dokument",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
      {
        name: "Ogólne Warunki Ubezpieczenia",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileB,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Treści zawarte na niniejszej stronie mają charakter wyłącznie reklamowy i informacyjny oraz nie stanowią oferty w rozumieniu Kodeksu Cywilnego. Świadczenie ochrony ubezpieczeniowej następuje na warunkach określonych w umowie zawartej z Klientem za pośrednictwem Banku BNP Paribas, działającego jako agent ubezpieczeniowy, przez Generali Towarzystwo Ubezpieczeń S.A. oraz w Ogólnych Warunkach Ubezpieczeń. Szczegółowe warunki ubezpieczenia, w tym ograniczenia i wyłączenia odpowiedzialności z nimi związane, jak również informacje na temat zasad działania poszczególnych produktów i kosztów z nimi związanych określone są w obowiązujących dla danego produktu Ogólnych Warunkach Ubezpieczenia, przekazywanych każdorazowo klientowi przed zawarciem umowy ubezpieczenia. Generali Towarzystwo Ubezpieczeń S.A. z siedzibą w Warszawie przy ul. Postępu 15B, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 10623, kapitał zakładowy 191.000.000 PLN wpłacony w całości, NIP 526-23-49-108, należąca do Grupy Generali, figurującej w Rejestrze Grup Ubezpieczeniowych prowadzonym przez IVASS.",
    ],
  },
  INSURANCE_SCOPE_1: {
    title: "Przedmiot ubezpieczenia Agro Casco",

    items: [
      "maszyny rolnicze samobieżne, np. ciągniki rolnicze, kombajny",
      "maszyny rolnicze ciągnięte, np. przyczepy, agregaty, rozsiewacze",
      "maszyny rolnicze zawieszane, np. siewniki, agregaty, opryskiwacze",
      "narzędzia rolnicze, np. ładowacze, hedery do zbioru zbóż, przystawki",
      "maszyny stacjonarne, np. śrutownik, suszarnia, robot udojowy",
    ],
    icon: "Tractor",
  },
  INSURANCE_SCOPE_2: {
    title: "Zakres ubezpieczenia",
    description: "Najszerszy zakres ochrony na rynku w tym:",
    items: [
      "Agro Casco",
      "OC posiadaczy pojazdów mechanicznych ",
      "NNW kierowcy i pasażerów ",
      "OC z tytułu usług międzysąsiedzkich i porada prawna",
      "Ogień i inne zdarzenia losowe dla maszyn stacjonarnych",
    ],
    icon: "TodoListHand",
    bg: "green",
    iconBg: "white",
    isReversed: true,
  },
  INSURANCE_SCOPE_3: {
    title: "Zalety ubezpieczenia",
    description:
      "Ubezpieczenie tworzymy pod potrzeby każdego użytkownika maszyn rolniczych. Powiedz jakiego ryzyka się obawiasz a my dobierzemy najlepsze rozwiązanie. Rolnicy cenią sobie szczególnie:",
    items: [
      "możliwość ochrony od szkód w szybach",
      "pokrycie kosztów naprawy zniszczeń spowodowanych wciągnięciem ciała obcego przez pracującą maszynę ",
      "odpowiedzialność za szkody w oponach w trakcie pracy maszyny",
      "odpowiedzialność za kradzież maszyny z każdego miejsca w Polsce",
    ],
    icon: "TractorShield",
    bg: "white",
    iconBg: "orange",
  },
};

const UbezpieczeniaMieniaMaszynyRolnicze = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_1} id="ofert" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_2} />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_3} />
    <ScrollToSection
      onClick={() => {
        window.location.href = `/kredytomat/kontakt?product_url=${
          window.location.pathname
        }&product_name=Ubezpieczenie maszyn rolniczych`;
      }}
      text="Zapytaj o ofertę"
    />
    <TempShareButtonsComponent />
    <Benefits withArrow {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm id="contact" />
  </PageTemplate>
);

export default UbezpieczeniaMieniaMaszynyRolnicze;

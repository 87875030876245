import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./Widget.scss";

const getURL = (path = "") => {
  if (path.includes("http")) {
    return path;
  }

  return `${window.location.origin}${path}`;
};

const Widget = ({
  t,
  to,
  cta,
  children,
  cover,
  isHorizontal,
  className,
  isExternalLink,
  href,
  target,
  rel,
}) => {
  if (href) {
    return (
      <div
        className={classnames(`c-widget ${className}`, {
          "c-widget--isHorizontal": isHorizontal,
        })}
        style={{ backgroundImage: `url(${cover})` }}
      >
        <div className="c-widget__content">{children}</div>

        <a className="c-widget__cta" href={href}>
          {t(cta)}
        </a>
      </div>
    );
  }
  return (
    <div
      className={classnames(`c-widget ${className}`, {
        "c-widget--isHorizontal": isHorizontal,
      })}
      style={{ backgroundImage: `url(${cover})` }}
    >
      <div className="c-widget__content">{children}</div>
      {isExternalLink ? (
        <a className="c-widget__cta" href={getURL(to)} target={target}>
          {t(cta)}
        </a>
      ) : (
        <Link className="c-widget__cta" to={to} rel={rel}>
          {t(cta)}
        </Link>
      )}
    </div>
  );
};

Widget.propTypes = {
  to: PropTypes.string,
  cta: PropTypes.string,
  isHorizontal: PropTypes.bool,
  className: PropTypes.string,
  isExternalLink: PropTypes.bool,
};
Widget.defaultProps = {
  to: "/",
  cta: "globals:buttons.details",
  isHorizontal: false,
  className: "",
  isExternalLink: false,
};

export default withTranslation(["globals"])(Widget);

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftBox,
  CftButton,
  CftBoxAnimationContent,
  CftTank,
  CftActionsButtons,
  CftLeavingModal,
} from "../components";
import routes from "../utils/cftRoutes";
import { useCft } from "../utils/CftProvider";

import { ReactComponent as CowIcon } from "../../assets/icons/cft/cow.svg";
import { ReactComponent as CropIcon } from "../../assets/icons/cft/polauprawne.svg";
import { ReactComponent as ChickenIcon } from "../../assets/icons/cft/chicken.svg";
import { ReactComponent as TurkeyIcon } from "../../assets/icons/cft/turkey.svg";
import { ReactComponent as PigIcon } from "../../assets/icons/cft/pig.svg";
import { ReactComponent as CattleIcon } from "../../assets/icons/cft/cattle.svg";
import { ONBOARDING_COOKIE } from "../components/CftOnboarding/CftOnboarding";
import useCftChickenBroilerValues from "../utils/useCftChickenBroilerValues";
import useCftTurkeyValues from "../utils/useCftTurkeyValues";
import useCftPigsValues from "../utils/useCftPigsValues";

function CFTPage() {
  const history = useHistory();
  const { t } = useTranslation([
    "cft",
    "cftChickenBroiler",
    "cftTurkey",
    "cftPigs",
    "cftCattle",
  ]);
  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const [isFileLoaded, setFileLoaded] = useState(false);
  const {
    saveDairyProduction,
    saveDairyProductionReport,
    saveFarmlands,
    saveFarmlandsReport,
    saveWaterFootprint,
    saveWaterFootprintReport,
  } = useCft();

  const { updateStore: updateChickenStore } = useCftChickenBroilerValues();
  const { updateStore: updateTurkeyStore } = useCftTurkeyValues();
  const { updateStore: updatePigsStore } = useCftPigsValues();

  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  setShowLeavingModal(!showLeavingModal);
                  stopOverflow();
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>
              {t(
                "cft:fulltitle",
                "Agroemisja – kalkulator emisji gazów cieplarnianych"
              )}
            </h3>
            <p>
              {t(
                "cft:main.subtitle",
                "Wybierz uprawę lub hodowlę zwierząt gospodarskich, dla których chcesz dokonać kalkulacji emisji gazów cieplarnianych w Twoim gospodarstwie."
              )}
            </p>
          </>
        }
      >
        <CftTank
          justifyContent="center"
          alignItems="center"
          style={{ flexWrap: "wrap" }}
        >
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.FARMLANDS.CALC_TYPE)}
          >
            <CropIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p>{t("cft:types.farmlands", "Pola uprawne")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.DAIRY_PRODUCTION.INTRO)}
          >
            <CowIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p> {t("cft:types.dairyProduction", "Produkcja mleczarska")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.CHICKEN_BROILER.MAIN)}
          >
            <ChickenIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p>{t("cftChickenBroiler:tag", "Brojler kurzy")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.TURKEY.MAIN)}
          >
            <TurkeyIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p>{t("cftTurkey:tag", "Indyki")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.PIGS.MAIN)}
          >
            <PigIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p>{t("cftPigs:tag", "Trzoda chlewna")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
          <CftBox
            variant="medium"
            onClick={() => history.push(routes.CATTLE.MAIN)}
          >
            <CattleIcon className="cft-box__svg-fix" />
            <CftBoxAnimationContent>
              <p>{t("cftCattle:tag", "Bydło opasowe")}</p>
              <CftButton isFullwidth isActive>
                {t("cft:start", "rozpocznij")}
              </CftButton>
            </CftBoxAnimationContent>
          </CftBox>
        </CftTank>
        {isFileLoaded ? (
          <CftTank
            mt={6}
            mr={1}
            ml={1}
            flexDirection="column"
            alignItems="center"
          >
            <h5 style={{ marginBottom: "1rem", fontWeight: "bold" }}>
              {t(
                "cft:import:done",
                "Plik został załadowany. Możesz przejść do kalkulacji."
              )}
            </h5>
          </CftTank>
        ) : (
          <CftTank
            mt={6}
            mr={1}
            ml={1}
            flexDirection="column"
            alignItems="center"
          >
            <h5 style={{ marginBottom: "1rem", fontWeight: "bold" }}>
              {t("cft:import:title", "Chcesz dokończyć kalkulację?")}
            </h5>
            <p style={{ marginBottom: "0.5rem", textAlign: "center" }}>
              {t("cft:import:subtitleP1", "Kliknij")}
              <strong>{t("cft:import:subtitleP2", " Wybierz plik ")}</strong>
              {t("cft:import:subtitleP3", "aby wrócić do swojej kalkulacji.")}
            </p>
            <CftActionsButtons.ImportReport
              id="importReport"
              onChange={e => {
                if (e.target.files.length > 0) {
                  const reader = new FileReader();

                  reader.addEventListener("load", () => {
                    const report = JSON.parse(reader.result);

                    Cookies.remove(ONBOARDING_COOKIE);

                    if (typeof report.type === "undefined") {
                      saveDairyProduction(report.dairyProduction);
                      saveDairyProductionReport(report.dairyProductionReport);
                      saveFarmlands(report.farmlands);
                      saveFarmlandsReport(report.farmlandsReport);
                      saveWaterFootprint(report.waterFootprint);
                      saveWaterFootprintReport(report.waterFootprintReport);
                      setFileLoaded(true);
                    }

                    if (report.type === "turkey") {
                      updateTurkeyStore(report);

                      setTimeout(() => {
                        history.push(routes.TURKEY.MAIN);
                      }, 300);
                    }

                    if (report.type === "chicken") {
                      updateChickenStore(report);

                      setTimeout(() => {
                        history.push(routes.CHICKEN_BROILER.MAIN);
                      }, 300);
                    }

                    if (report.type === "pigs") {
                      updatePigsStore(report);

                      setTimeout(() => {
                        history.push(routes.PIGS.MAIN);
                      }, 300);
                    }
                  });

                  reader.readAsText(e.target.files[0]);
                }
              }}
            />
          </CftTank>
        )}

        <CftLeavingModal
          isVisible={showLeavingModal}
          type="both"
          hide={() => setShowLeavingModal(!showLeavingModal)}
        />
      </CftSection>
    </CftLayout>
  );
}

export default CFTPage;

import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { isMobileSafari } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import {
  connectTool,
  setToolInStore,
} from "../../../services/redux/tools/reducer";
import Image from "../Image/Image";
import { ButtonFix, Loader, Typography } from "../index";
import "./TopicCard.scss";

class TopicCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.redirectButton = React.createRef();

    this.renderButtonForToolCard = this.renderButtonForToolCard.bind(this);
    this.generatLinkForTool = this.generatLinkForTool.bind(this);
  }

  generatLinkForTool() {
    // event.stopPropagation();
    const { toolName } = this.props;
    const token = Cookies.get("user_token");

    this.setState({
      loading: true,
    });

    if (token && toolName) {
      this.props.postTool(toolName, token).then(({ payload }) => {
        // console.log(payload);
        if (payload.data && payload.data.url_redirect) {
          if (isMobileSafari) {
            const windowReference = window.open();
            windowReference.location = payload.data.url_redirect;
          } else {
            window.open(payload.data.url_redirect);
          }
        }
      });
    }
  }

  renderButtonForToolCard() {
    const {
      hasToolsPermission,
      changePathTo,
      registrationBenefitPath,
      buttonText,
      history,
      pathBlank,
    } = this.props;
    let buttonChildren = <div />;

    if (hasToolsPermission) {
      if (!changePathTo) {
        // this.generatLinkForTool();
        buttonChildren = (
          <ButtonFix
            buttonsize="small"
            buttoncolor="yellow"
            buttoncenter="center"
            absolute
            onClick={() => {
              this.generatLinkForTool();
              // if (tools[toolName]) {
              //   let event = document.createEvent("HTMLEvents");
              //   event.initEvent("click", true, false);
              //   this.redirectButton.current.dispatchEvent(event);
              // } else {
              // }
              // alert("generuj narzedzie");
            }}
          >
            {buttonText}
          </ButtonFix>
        );
      } else {
        buttonChildren = (
          <ButtonFix
            buttonsize="small"
            buttoncenter="center"
            buttoncolor="yellow"
            absolute
            onClick={() => {
              if (pathBlank) {
                window.open(
                  `${changePathTo}`,
                  "_blank" // <- This is what makes it open in a new window.
                );
              } else {
                history.push(changePathTo);
              }
            }}
          >
            {buttonText}
          </ButtonFix>
        );
      }
    } else {
      buttonChildren = (
        // <Link to={`${registrationBenefitPath}`}>{buttonText}</Link>
        <ButtonFix
          buttonsize="small"
          buttoncolor="yellow"
          buttoncenter="center"
          absolute
          onClick={() => history.push(registrationBenefitPath)}
        >
          {buttonText}
        </ButtonFix>
      );
    }

    return buttonChildren;
  }

  componentDidUpdate(prevProps, prevState) {
    const { tools, isError, toolName } = this.props;
    if (this.state.loading && isError) {
      // eslint-disable-next-line no-alert
      alert("wystąpił błąd ");
    }
    if (prevState.loading && (tools[toolName] || isError)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { src, textInfo, children, isPremium, t, location } = this.props;
    const { loading } = this.state;

    return (
      <div className="topicCard position-relative">
        <Image className="card-image" src={src} alt="" />
        {isPremium && (
          <div className="premium-wrapper">
            <span>{t("globals:buttons.premium")}</span>
          </div>
        )}
        <div className="card-content">
          {location.pathname.match("/") ? (
            <h3 className="typography--h2">{textInfo}</h3>
          ) : (
            <Typography typographyType="h2">{textInfo}</Typography>
          )}

          {children}
          {this.renderButtonForToolCard()}
        </div>
        {loading ? <Loader absolute /> : null}
      </div>
    );
  }
}

TopicCard.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string.isRequired,
  changePathTo: PropTypes.string,
  textInfo: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  toolName: PropTypes.string,
  postTool: PropTypes.func.isRequired,
  hasToolsPermission: PropTypes.bool,
  registrationBenefitPath: PropTypes.string.isRequired,
  pathBlank: PropTypes.string,
  isPremium: PropTypes.bool,
};

TopicCard.defaultProps = {
  children: null,
  toolName: "",
  hasToolsPermission: false,
  changePathTo: "",
  pathBlank: false,
  isPremium: false,
};

const mapStateToProps = state => ({
  tools: state.tools.tools,
  isError: state.tools.isError,
});

const mapDispatchToProps = dispatch => {
  return {
    postTool: (toolName, token) => dispatch(connectTool(toolName, token)),
    setTool: (toolName, toolURL) => {
      dispatch(setToolInStore(toolName, toolURL));
    },
    resetTool: () => {},
  };
};

export default compose(
  withTranslation(["globals"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withRouter(TopicCard));

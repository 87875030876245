import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { formatAmount } from "../../../../services/helpers/calculator/formatters";
import {
  installmentTypeToString,
  timespanTypeToString,
  getYearsSuffixTranslationKey,
} from "../../../../services/helpers/calculator/dataHelpers";

import { Button } from "../../../common";
import { MONTHS } from "../../../../services/helpers/constants";

import "./CreditSummary.scss";

class CreditSummary extends Component {
  // eslint-disable-next-line class-methods-use-this
  renderComputedValue({ label, value }) {
    return (
      <div>
        <p className="computed-value-description--summary">{label}</p>
        <p className="computed-value--summary">{value}</p>
      </div>
    );
  }

  render() {
    const {
      creditAmount,
      repaymentTimespan,
      creditRate,
      installmentType,
      capitalInstallmentFrequency,
      interestInstallmentFrequency,
      handleStepCompletionChange,
      subventionAmount,
      subventionReceivingMonth,
      subventionReceivingYear,
      hasSubvention,
      t,
      i18n,
    } = this.props;

    // Na ekranie z „ofertą” w obszarze Twój kredyt, obok oprocentowania powinien być wyświetlany rodzaj rat, a częstotliwość spłat kapitału i odsetek poniżej obok siebie.

    const yearsSuffixTranslationKey = getYearsSuffixTranslationKey({
      i18n,
      repaymentTimespan,
    });

    const chosenMonth = Object.values(MONTHS).find(
      month => month.value === subventionReceivingMonth
    );

    return (
      <div className="credit-container credit-container--dark">
        <h2 className="typography--h2">{t("secondStep.subTitle")}</h2>
        <div className="credit-completed-summary-container">
          <div className="credit-summary-grid">
            {this.renderComputedValue({
              label: t("tabelLabels.6"),
              value: formatAmount({ amount: creditAmount }),
            })}
            {this.renderComputedValue({
              label: t("tabelLabels.7"),
              value: `${repaymentTimespan} ${t(yearsSuffixTranslationKey)}`,
            })}
            {this.renderComputedValue({
              label: t("tabelLabels.8"),
              value: `${creditRate}%`,
            })}
          </div>
          {hasSubvention && (
            <>
              <hr />
              <div className="credit-summary-grid">
                {this.renderComputedValue({
                  label: t("tabelLabels.3"),
                  value: formatAmount({ amount: subventionAmount }),
                })}
                {this.renderComputedValue({
                  label: t("tabelLabels.13"),
                  value: subventionReceivingYear,
                })}
                {this.renderComputedValue({
                  label: t("tabelLabels.12"),
                  value:
                    t(chosenMonth.label)
                      .charAt(0)
                      .toUpperCase() + t(chosenMonth.label).slice(1),
                })}
              </div>
            </>
          )}
          <hr />
          <div className="credit-summary-grid">
            {this.renderComputedValue({
              label: t("tabelLabels.9"),
              value: t(
                installmentTypeToString({
                  type: installmentType,
                })
              ),
            })}
            {this.renderComputedValue({
              label: t("tabelLabels.10"),
              value: t(
                timespanTypeToString({
                  type: capitalInstallmentFrequency,
                })
              ),
            })}
            {this.renderComputedValue({
              label: t("tabelLabels.11"),
              value: t(
                timespanTypeToString({
                  type: interestInstallmentFrequency,
                })
              ),
            })}
          </div>
          <div className="summary-button-container">
            <Button
              buttoncolor="yellow"
              buttonsize="small"
              onClick={() =>
                handleStepCompletionChange(
                  "investmentStepCompleted",
                  false,
                  true
                )
              }
            >
              {t("globals:buttons.changeData")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

CreditSummary.propTypes = {
  handleStepCompletionChange: PropTypes.func.isRequired,
  creditAmount: PropTypes.number,
  repaymentTimespan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  installmentType: PropTypes.string,
  capitalInstallmentFrequency: PropTypes.string,
  interestInstallmentFrequency: PropTypes.string,
  subventionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subventionReceivingMonth: PropTypes.number,
  subventionReceivingYear: PropTypes.number,
  hasSubvention: PropTypes.bool,
};

CreditSummary.defaultProps = {
  creditAmount: 0,
  repaymentTimespan: 0,
  subventionAmount: 0,
  subventionReceivingMonth: 0,
  subventionReceivingYear: 0,
  creditRate: 0,
  installmentType: "",
  capitalInstallmentFrequency: "",
  interestInstallmentFrequency: "",
  hasSubvention: false,
};

export default withTranslation(["calculatorPage", "globals"])(CreditSummary);

import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";

function CftFormField({ name, children, withInput }) {
  const [field, meta, helpers] = useField(name);
  const context = useFormikContext();

  return (
    <>
      {withInput && (
        <input
          id={name}
          name={name}
          style={{ width: 0, height: 0, opacity: 0, visibility: "hidden" }}
        />
      )}
      {children({ field, meta, helpers, value: field.value, context })}
    </>
  );
}

CftFormField.propTypes = {
  name: PropTypes.string.isRequired,
  withInput: PropTypes.bool,
};

CftFormField.defaultProps = {
  withInput: true,
};

export default CftFormField;

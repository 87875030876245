import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftTank,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import TransportFarmlandsForm, {
  TransportFarmlandsFormContext,
} from "../../forms/farmlands/TransportFarmlandsForm/TransportFarmlands.form";

function TransportFarmlandsPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveFarmlands, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={88}
          cropName={meta.title.farmlands}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="farmlands" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveFarmlands({
                    transport: values,
                  });
                  history.push(routes.FARMLANDS.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:transport.title", "Transport")}
          onBack={() => history.push(routes.FARMLANDS.CARBON_CHANGES)}
          onNext={submitForm}
          nextText={t("cft:report.btn", "Wygeneruj raport")}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:transport.title", "Transport")}</h3>
            <p>
              {t(
                "cft:transport.subtitle",
                "Należy uwzględnić transport przychodzący środków produkcji - takich jak pasze lub nawozy od sprzedawcy - do gospodarstwa. Dobrą praktyką jest uwzględnienie również transportu wychodzącego gotowych produktów do miejsca przetwarzania."
              )}
            </p>
          </>
        }
      >
        <TransportFarmlandsForm />
        <CftReportProgress type="farmlands" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <TransportFarmlandsFormContext>
      <TransportFarmlandsPage />
    </TransportFarmlandsFormContext>
  );
};

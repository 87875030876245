import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
} from "../../components";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";

export default function CreateCoopField() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const { setFieldValue, values, setFieldError } = useFormikContext();
  const { updateStore } = useCftTurkeyValues();

  if (values.coops.length) {
    return null;
  }

  return (
    <>
      <h4 className="cft-section-subtitle mb-7">
        {t(
          "cftTurkey:createCoopField.title",
          "Podaj informacje o indycznikach"
        )}
      </h4>
      <div className="cft-paper mb-6">
        <CftFormControl alignLeft>
          <CftLabel
            isFullWidth
            label={t(
              "cftTurkey:createCoopField.fields.countLabel",
              "Na podstawie ilu indyczników chcesz dokonać obliczeń?"
            )}
            className="mb-4"
          />
          <CftLabel
            isFullWidth
            label={t(
              "cftTurkey:createCoopField.fields.countSublabel",
              "Ilość indyczników referencyjnych (max 6)"
            )}
          />
          <CftTextField
            mask="0"
            isRounded
            name="count"
            placeholder={t(
              "cftTurkey:createCoopField.fields.countPlaceholder",
              "Podaj ilość"
            )}
            isAlternative
          />
        </CftFormControl>
        <CftTank
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <CftButton
            variant="active"
            isRounded
            isAlternative
            onClick={async () => {
              const validationSchema = Yup.object().shape({
                count: Yup.number()
                  .typeError(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorNumber",
                      "Podaj wartość liczbową"
                    )
                  )
                  .integer(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorinteger",
                      "Podaj wartość całkowitą"
                    )
                  )
                  .min(
                    1,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    6,
                    t(
                      "cftTurkey:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax6",
                      "Wartość maksymalna to 6."
                    )
                  )
                  .required(
                    t(
                      "cftTurkey:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
              });

              try {
                await validationSchema.validate({
                  count: values.count,
                });
              } catch (error) {
                return setFieldError("count", error.errors.join("."));
              }

              const coops = Array(Number(values.count))
                .fill(null)
                .map(() => ({
                  name: "",
                  production_cycles: [
                    {
                      production_cycle_length: "",
                      technological_break_length: "",
                      repetitions: "",
                      male_turkeys: null,
                      female_turkeys: null,
                    },
                  ],
                  isValidate: false,
                }));

              updateStore({
                henhouses_reference: {
                  count: values.count,
                  coops,
                },
              });
              return setFieldValue("coops", coops);
            }}
          >
            {t("cftTurkey:createCoopField.fields.submit", "UTWORZ indyczniki")}
          </CftButton>
        </CftTank>
      </div>
    </>
  );
}

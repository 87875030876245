import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import { ReactComponent as UploadIcon } from "../../../../../assets/icons/upload.svg";
import { ReactComponent as XIcon } from "../../../../svg-icons/x.svg";
import Image from "../../../Image/Image";
import "./AddFiledImage.scss";

const AddFiledImage = ({
  t,
  onUploadCallback,
  imageUrl,
  onRemoveCallback,
  isReadOnly,
  errors,
}) => {
  const [imageBlob, setImageBlob] = useState(imageUrl || null);

  const onDrop = useCallback(acceptedFiles => {
    if (onUploadCallback !== undefined) {
      onUploadCallback({
        file: acceptedFiles[0],
        url: URL.createObjectURL(acceptedFiles[0]),
      });
    } else {
      setImageBlob(URL.createObjectURL(acceptedFiles[0]));
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ noDrag: true, onDrop });

  useEffect(() => {}, [imageUrl]);

  return (
    <>
      {imageBlob !== null && (
        <div className="c-add-filed-image--preview">
          <Image src={imageBlob} alt="preview" />
          {!isReadOnly && (
            <button
              type="button"
              onClick={() => {
                setImageBlob(null);
                if (onRemoveCallback !== undefined) {
                  onRemoveCallback();
                }
              }}
            >
              <XIcon /> {t("widgets:common.remove")}
            </button>
          )}
        </div>
      )}
      <div
        className={`c-add-filed-image ${
          imageBlob !== null ? "c-add-filed-image--hide" : ""
        }`}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} name="image" />
          <UploadIcon />
          <p className="c-add-filed-image__formats">
            {t("widgets:gallery.upload.files")}
          </p>
          <span className="button--big button--yellow">
            {t("widgets:gallery.upload.cta")}
          </span>
        </div>
      </div>

      {errors && errors["image"] && (
        <p className="c-add-filed-image-error">{errors["image"]}</p>
      )}
    </>
  );
};

AddFiledImage.propTypes = {};
AddFiledImage.defaultProps = {
  isReadOnly: false,
};

export default compose(
  withTranslation(["widgets"]),
  connect(state => ({
    articlesCategories: state.articles.articlesCategories.results,
  }))
)(AddFiledImage);

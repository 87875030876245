/* eslint-disable no-useless-concat */
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const LinkContent = ({ name, color }) => (
  <>
    <span className="c-categories__el-name">{name}</span>
    <span
      className="c-categories__el-line"
      style={{ backgroundColor: color }}
    />
  </>
);

const mapStateToProps = (state, { id, color }) => {
  const cat = state.articles.articlesCategories.agro.find(
    item => item.id === id
  );
  return {
    color: cat ? cat.colour : color,
  };
};

const ConnectedLinkContent = connect(mapStateToProps)(LinkContent);

export const AgroCategoriesEl = ({ type, id, color, active }) => {
  const AgroLink = Link;
  const { t } = useTranslation(["report"]);
  const name = t(`categories.${id}`);
  const slug = t(`categories.slugs.${id}`);

  const isAgro = id < 18;

  return (
    <AgroLink
      className={
        `c-categories__el ${type ? `c-categories__el--${type}` : ""} ${
          isAgro ? "c-categories__el--agro" : undefined
        }` + `${active ? " c-categories__el--active" : ""}`
      }
      to={type === "all" ? "/agroraport" : `/agroraport/${slug}`}
    >
      <ConnectedLinkContent id={id} name={name} color={color} />
    </AgroLink>
  );
};

import * as actions from "./actions";

const InitialState = {
  toolsURL: [],
  tools: [],
  isError: false,
  formSentIds: [],
};

export default function tools(state = InitialState, action) {
  switch (action.type) {
    case actions.POST_TOOL: {
      return {
        ...state,
        loading: true,
        isError: false,
      };
    }

    case actions.POST_TOOL_SUCCESS: {
      const { data } = action.payload;
      return {
        toolsURL: [...state.tools, data.url_redirect],
        isError: false,
        loading: false,
      };
    }

    case actions.SET_TOOL_IN_STORE: {
      const toolName = JSON.parse(action.payload.config.data).tool_name;
      const toolURL = action.payload.data.url_redirect;
      // window.open(toolURL, "_blank");
      return {
        ...state,
        tools: { ...state.tools, [toolName]: toolURL },
        loading: false,
      };
    }

    case actions.POST_TOOL_FAIL: {
      return {
        ...state,
        isError: true,
        loading: false,
      };
    }
    case actions.GET_TOOL_ACCESS_STATUS_SUCCESS: {
      const { tool_choice } = action.payload.data;
      return {
        ...state,
        formSentIds: Array.from(
          new Set([...state.formSentIds, tool_choice])
        ).filter(id => id),
      };
    }
    default:
      return state;
  }
}

export const connectTool = (toolName, token) => {
  return {
    types: ["POST_TOOL", "SET_TOOL_IN_STORE", "POST_TOOL_FAIL"],
    payload: {
      request: {
        url: `/api/v1/access-tool/${toolName}/`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          tool_name: toolName,
        },
      },
    },
  };
};

export const setToolInStore = (toolName, toolURL) => ({
  type: "SET_TOOL_IN_STORE",
  toolName,
  toolURL,
});

export const getToolAccessStatus = (token, toolId) => {
  return {
    types: [
      actions.GET_TOOL_ACCESS_STATUS,
      actions.GET_TOOL_ACCESS_STATUS_SUCCESS,
      actions.GET_TOOL_ACCESS_STATUS_FAIL,
    ],
    payload: {
      request: {
        url: "/api/v1/request_tool/",
        params: { tool_selected: toolId },
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  };
};

export const postToolRequest = (token, toolId, businessType) => ({
  type: actions.REQUEST_TOOL_ACCESS,
  payload: {
    request: {
      url: "/api/v1/request_tool/",
      data: { tool_choice: toolId, business_type: businessType },
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

import React, { useEffect, useMemo, useState } from "react";
import { YouTubeGetID } from "../../../services/helpers/video-cover";

export function getMediaUrlMeta(mediaURL, autoplay = false) {
  if (mediaURL.match(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)) {
    const v = YouTubeGetID(mediaURL);
    let url = `https://www.youtube.com/embed/${v}`;
    if (autoplay) {
      url += "?rel=0&autoplay=1";
    }
    return {
      provider: "youtube",
      url,
    };
  }

  if (mediaURL.includes("vimeo")) {
    const url = mediaURL.replace(
      "https://vimeo.com/",
      "https://player.vimeo.com/video/"
    );

    return {
      provider: "vimeo",
      url: autoplay ? `${url}?autoplay=1&loop=1&autopause=0` : url,
    };
  }

  return {
    provider: "unknown",
    url: null,
  };
}

function VideoEmbed({ mediaURL, autoplay, ...props }) {
  const [media, setMedia] = useState(null);
  useEffect(() => {
    if (!media) {
      const nMedia = getMediaUrlMeta(mediaURL, autoplay);
      setMedia(nMedia);
    }
  }, [mediaURL]);
  if (!media) return "";

  switch (media.provider) {
    case "youtube":
      return (
        <iframe
          title="video"
          src={media.url}
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; autoplay, fullscreen"
          allowFullScreen
          {...props}
        />
      );
    case "vimeo":
      return (
        <iframe
          title="video"
          src={media.url}
          allowFullScreen
          allow="autoplay; fullscreen"
          {...props}
        />
      );
    default:
      return (
        <div className="VideosPreview__noVideo">
          <h3>Nieznane źródło wideo</h3>
        </div>
      );
  }
}

export default VideoEmbed;

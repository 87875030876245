/* eslint-disable no-restricted-globals */
import { INSTALLMENT_TYPES, TIMESTAMP_TYPES } from "../constants";

export const validateAmount = ({ amount, optional }) => {
  const floatingAmount = parseFloat(amount);

  if (optional) {
    return !isNaN(floatingAmount) && floatingAmount >= 0;
  }

  return !isNaN(floatingAmount) && floatingAmount > 0;
};

export const validateSubvention = ({ investmentAmount, subventionAmount }) => {
  const floatingInvestmentAmount = parseFloat(investmentAmount);
  const floatingSubventionAmount = parseFloat(subventionAmount);
  return (
    !isNaN(floatingInvestmentAmount) &&
    floatingSubventionAmount + 2000 <= floatingInvestmentAmount
  );
};

export const isIncorrectCombination = ({ params }) => {
  const cif = params.capitalInstallmentFrequency;
  const iif = params.interestInstallmentFrequency;
  const it = params.installmentType;

  if (
    cif === TIMESTAMP_TYPES.ONE_MONTH &&
    iif === TIMESTAMP_TYPES.QUARTER &&
    it === INSTALLMENT_TYPES.DESCENDING
  ) {
    return "secondStep.warnings.1";
  }
  if (
    (cif === TIMESTAMP_TYPES.QUARTER &&
      iif === TIMESTAMP_TYPES.ONE_MONTH &&
      it === INSTALLMENT_TYPES.FIXED) ||
    (cif === TIMESTAMP_TYPES.HALF_YEAR &&
      iif === TIMESTAMP_TYPES.ONE_MONTH &&
      it === INSTALLMENT_TYPES.FIXED) ||
    (cif === TIMESTAMP_TYPES.HALF_YEAR &&
      iif === TIMESTAMP_TYPES.QUARTER &&
      it === INSTALLMENT_TYPES.FIXED) ||
    (cif === TIMESTAMP_TYPES.ONE_YEAR &&
      iif === TIMESTAMP_TYPES.ONE_MONTH &&
      it === INSTALLMENT_TYPES.FIXED) ||
    (cif === TIMESTAMP_TYPES.ONE_YEAR &&
      iif === TIMESTAMP_TYPES.QUARTER &&
      it === INSTALLMENT_TYPES.FIXED) ||
    (cif === TIMESTAMP_TYPES.ONE_MONTH &&
      iif === TIMESTAMP_TYPES.QUARTER &&
      it === INSTALLMENT_TYPES.FIXED)
  ) {
    return "secondStep.warnings.2";
  }
  return "";
};

export const validateMoney = value => {
  const reg = new RegExp("^\\d+([\\.\\,]\\d{1,2}|[\\.\\,])?$");
  if (value === "") {
    return true;
  }
  if (value.length > 10) {
    return false;
  }
  return reg.test(value);
};

/* eslint-disable no-undef */
import * as Sentry from "@sentry/browser";
import "bootstrap/dist/css/bootstrap.min.css";
import "core-js";
import "normalize.css";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import React, { Suspense } from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { SWRConfig } from "swr";
import { Loader } from "./components/common/index";
import App from "./components/index";
import "./i18n";
import "./index.scss";
import configureStore from "./services/redux/store";
import * as serviceWorker from "./serviceWorker";
import CftProvider from "./cft/utils/CftProvider";

const isLocalhost = window.location.origin.includes("localhost");

if (!isLocalhost) {
  Sentry.init({
    dsn: "https://225c0f4a23d8444dbdacd91d5efd3dea@sentry.io/1399579",
  });
}

const store = configureStore();

console.info("mode:", process.env.NODE_ENV);

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <SWRConfig
        value={{
          errorRetryCount: 1,
          revalidateOnFocus: false,
        }}
      >
        <CftProvider>
          <Helmet
            htmlAttributes={{
              lang: Cookies.get("user_lang")
                ? Cookies.get("user_lang").toLowerCase()
                : "pl",
            }}
          />
          <App />
        </CftProvider>
      </SWRConfig>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// const PWAconfig = {
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting;
//     if (waitingServiceWorker) {
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   },
// };

// serviceWorker.register(PWAconfig);
serviceWorker.unregister();

export const Period = {
  weekly: "week",
  monthly: null,
};

export const Cereal = {
  pszenica: 1,
  żyto: 2,
  jęczmień: 3,
  kukurydza: 4,
  owies: 5,
  pszenżyto: 6,
  1: "averagePrices:cereals.1",
  2: "averagePrices:cereals.2",
  3: "averagePrices:cereals.3",
  4: "averagePrices:cereals.4",
  5: "averagePrices:cereals.5",
  6: "averagePrices:cereals.6",
};

export const MarketType = {
  Targowisko: 1,
  Skup: 2,
};

export const MarketTypeName = {
  Targowisko: "averagePrices:cerealsPage.form.marketplaces",
  Skup: "averagePrices:cerealsPage.form.purchasing",
};

export const Destination = {
  Paszowe: 1,
  Konsumpcyjne: 2,
  Browarniane: 4,
  1: "Paszowe",
  2: "Konsumpcyjne",
  4: "Browarniane",
};

export const ProductFamily = {
  Cereals: 1,
  Oleaginous: 2,
  Poultry: 3,
  Pork: 4,
  Dairy: 5,
};

export const Pork = {
  Zywiec: 16,
  Poltusze: 17,
  MPC: 18,
  16: "averagePrices:pork.16",
  17: "averagePrices:pork.17",
  18: "averagePrices:pork.18",
};

export const Poultry = {
  kurczeta: 10,
  indory: 11,
  indyczki: 12,
  kaczki: 13,
  gesi: 14,
  kury: 15,
  10: "averagePrices:poultry.10",
  11: "averagePrices:poultry.11",
  12: "averagePrices:poultry.12",
  13: "averagePrices:poultry.13",
  14: "averagePrices:poultry.14",
  15: "averagePrices:poultry.15",
};

export const Oleaginous = {
  nasionaRzepaku: 7,
  srutaRzepakowa: 8,
  makuchRzepakowy: 9,
  7: "averagePrices:oleaginous.7",
  8: "averagePrices:oleaginous.8",
  9: "averagePrices:oleaginous.9",
};

export const Dairy = {
  sery: 1,
  produktyPlynne: 4,
  maslo: 2,
  proszkiMleczne: 3,
  1: "averagePrices:dairy.1",
  2: "averagePrices:dairy.2",
  3: "averagePrices:dairy.3",
  4: "averagePrices:dairy.4",
};

export const DairyCategories = {
  1: {
    serEdamski: 19,
    serGouda: 20,
    twarog: 21,
    19: "averagePrices:dairy.19",
    20: "averagePrices:dairy.20",
    21: "averagePrices:dairy.21",
  },
  4: {
    mlekoSpozywczePasteryzowane: 22,
    jogurtNaturalny: 23,
    kefir: 24,
    smietanaISmietanka: 25,
    22: "averagePrices:dairy.22",
    23: "averagePrices:dairy.23",
    24: "averagePrices:dairy.24",
    25: "averagePrices:dairy.25",
  },
  2: {
    masloWBlokach: 26,
    masloKonfekcjonowane: 27,
    26: "averagePrices:dairy.26",
    27: "averagePrices:dairy.27",
  },
  3: {
    pelneMlekoWProszku: 28,
    odtłuszczoneMlekoWProszku: 29,
    28: "averagePrices:dairy.28",
    29: "averagePrices:dairy.29",
  },
};

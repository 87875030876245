import { flatten } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { isTheSameDate } from "../../../averagePrices/date";
import MarketWidget, { createTableRow } from "../MarketWidget/MarketWidget";
import {
  useDataForRaportCenowy,
  useListOfPoultryProducts,
  useListOfRegions,
} from "./api";
import { Poultry } from "../../../averagePrices/types";

const getLastWeekDataForRegion = (weeklyData, region) => {
  const [lastAvailableWeek] = weeklyData;
  return weeklyData.find(
    week =>
      isTheSameDate(
        new Date(week.week_start_date),
        new Date(lastAvailableWeek.week_start_date)
      ) && week.region === region.id
  );
};

const PoultryWidget = ({ userDefaults, onLoginClick }) => {
  const { t, i18n } = useTranslation(["averagePrices"]);
  const token = Cookies.get("user_token");
  const { data: poultryProducts } = useListOfPoultryProducts();
  const { data: reportData } = useDataForRaportCenowy(userDefaults);
  const { data: listOfRegions } = useListOfRegions();

  const userIsLogin = token !== "";

  if (!poultryProducts || !listOfRegions || !reportData) {
    return null;
  }

  const selectedProducts = userDefaults.interested_products.map(id =>
    poultryProducts.find(prod => prod.id === id)
  );
  const selectedRegions = userDefaults.regions.map(id =>
    listOfRegions.find(prod => prod.id === id)
  );

  const [lastWeekData] = reportData.results;

  const productList = (() => {
    const dataForProducts = selectedRegions.map(region => {
      const currentWeekData = getLastWeekDataForRegion(
        reportData.results,
        region
      );
      if (!currentWeekData) {
        return [];
      }

      return selectedProducts.map(product => {
        const productData = currentWeekData.products.find(
          p => p.product.id === product.id
        );
        const name = `${t(Poultry[product.id])} ${
          i18n.language === "pl" ? region.shortcut : region.shortcut_en
        }`;
        return createTableRow({
          id: product.id,
          name,
          currentPrice: productData ? productData.current_price : undefined,
          percentagePriceChange: productData
            ? productData.percentage_price_change
            : undefined,
        });
      });
    });

    return flatten(dataForProducts);
  })();

  return (
    <MarketWidget>
      <MarketWidget.Header>
        <span>{t("averagePrices:widget.titlePoultry")}</span>
        <div className="details">
          <span>
            <MarketWidget.PeriodSection
              startWeek={new Date(lastWeekData.week_start_date)}
              endWeek={new Date(lastWeekData.week_end_date)}
            />
          </span>
          <MarketWidget.Customization
            isUserLogged={userIsLogin}
            linkTo="/srednie-ceny/drob"
            onLoginClick={onLoginClick}
          />
        </div>
      </MarketWidget.Header>
      <MarketWidget.Table dataList={productList} />
    </MarketWidget>
  );
};

export default PoultryWidget;

import React, { useState, createContext, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import {
  dairyProductionInit,
  dairyProductionReport as dairyProductionReportInit,
} from "./cftDairyProductionInitialValues";
import {
  farmlandsInit,
  farmlandsReport as farmlandsReportInit,
} from "./cftFarmlandsInitialValues";
import {
  waterFootprintInit,
  waterFootprintReport as waterFootprintReportInit,
} from "./cftWaterFootprintInitialValues";
import {
  bothCalcInit,
  bothCalcReport as bothCalcReportInit,
} from "./cftBothCalcInitialValues";
import { checkIP } from "./cftApi";

import { calculateGlobalProgress, calculateGlobalReport } from "./cftUtils";
import { ONBOARDING_COOKIE } from "../components/CftOnboarding/CftOnboarding";

export const DAIRY_PRODUCTION_KEY = "__VEpmNOLT5sFoM12o__";
export const FARMLANDS_KEY = "__aiJPyd7KyX1OXwPA2__";
export const WATER_FOOTPRINT_KEY = "__AcJPyd7sKsadyX1OXwlk__";
export const BOTH_CALC_KEY = "__ALIVIc0asSAcGA24SYG1__";
export const DAIRY_PRODUCTION_REPORT_KEY = "__b1pRv5pfYS__";
export const FARMLANDS_REPORT_KEY = "__2XwHhJSPrg__";
export const WATER_FOOTPRINT_REPORT_KEY = "__21gSHGPScA__";
export const BOTH_CALC_REPORT_KEY = "__55cSSVXaxS__";

export const CftContext = createContext();

export default function CftProvider(props) {
  const [reportIsOpen, setReportOpen] = useState(false);
  const [dairyProductionReport, setDairyProductionReport] = useState(
    dairyProductionReportInit
  );
  const [farmlandsReport, setFarmlandsReport] = useState(farmlandsReportInit);
  const [dairyProduction, setDairyProduction] = useState(dairyProductionInit);
  const [farmlands, setFarmlands] = useState(farmlandsInit);
  const [waterFootprint, setWaterFootprint] = useState(waterFootprintInit);
  const [waterFootprintReport, setWaterFootprintReport] = useState(
    waterFootprintReportInit
  );
  const [bothCalc, setBothCalc] = useState(bothCalcInit);
  const [bothCalcReport, setBothCalcReport] = useState(bothCalcReportInit);
  const [total, setTotal] = useState(1);
  const [validIp, setValidIp] = useState(null);

  useEffect(() => {
    const dairyProductionStore = localStorage.getItem(DAIRY_PRODUCTION_KEY);
    const farmlandsStore = localStorage.getItem(FARMLANDS_KEY);
    const waterFootprintStore = localStorage.getItem(WATER_FOOTPRINT_KEY);
    const bothCalcStore = localStorage.getItem(BOTH_CALC_KEY);
    const dairyProductionReportStore = localStorage.getItem(
      DAIRY_PRODUCTION_REPORT_KEY
    );
    const farmlandsReportStore = localStorage.getItem(FARMLANDS_REPORT_KEY);
    const waterFootprintReportStore = localStorage.getItem(
      WATER_FOOTPRINT_REPORT_KEY
    );
    const bothCalcReportStore = localStorage.getItem(BOTH_CALC_REPORT_KEY);

    if (dairyProductionStore) {
      setDairyProduction({
        ...dairyProduction,
        ...JSON.parse(dairyProductionStore),
      });
    }
    if (farmlandsStore) {
      setFarmlands({
        ...farmlands,
        ...JSON.parse(farmlandsStore),
      });
    }
    if (waterFootprintStore) {
      setWaterFootprint({
        ...waterFootprint,
        ...JSON.parse(waterFootprintStore),
      });
    }
    if (bothCalcStore) {
      setBothCalc({
        ...bothCalc,
        ...JSON.parse(bothCalcStore),
      });
    }
    if (dairyProductionReportStore) {
      setDairyProductionReport(JSON.parse(dairyProductionReportStore));
    }
    if (farmlandsReportStore) {
      setFarmlandsReport(JSON.parse(farmlandsReportStore));
    }
    if (waterFootprintReportStore) {
      setWaterFootprintReport(JSON.parse(waterFootprintReportStore));
    }
    if (bothCalcReportStore) {
      setBothCalcReport(JSON.parse(bothCalcReportStore));
    }
    if (validIp === null) {
      checkIP
        .then(() => {
          setValidIp(true);
        })
        .catch(() => {
          setValidIp(false);
        });
    }
  }, []);

  const clearCftData = () => {
    Cookies.remove(ONBOARDING_COOKIE);
    localStorage.removeItem(DAIRY_PRODUCTION_KEY);
    localStorage.removeItem(DAIRY_PRODUCTION_REPORT_KEY);
    localStorage.removeItem(FARMLANDS_KEY);
    localStorage.removeItem(FARMLANDS_REPORT_KEY);
    localStorage.removeItem(WATER_FOOTPRINT_KEY);
    localStorage.removeItem(WATER_FOOTPRINT_REPORT_KEY);
    localStorage.removeItem(BOTH_CALC_KEY);
    localStorage.removeItem(BOTH_CALC_REPORT_KEY);
    setDairyProductionReport(dairyProductionReportInit);
    setFarmlandsReport(farmlandsReportInit);
    setDairyProduction(dairyProductionInit);
    setFarmlands(farmlandsInit);
    setWaterFootprint(waterFootprintInit);
    setWaterFootprintReport(waterFootprintReportInit);
    setBothCalc(bothCalcInit);
    setBothCalcReport(bothCalcReportInit);
  };

  return (
    <CftContext.Provider
      value={{
        dairyProduction,
        setDairyProduction,
        farmlands,
        setFarmlands,
        setFarmlandsReport,
        setWaterFootprint,
        setWaterFootprintReport,
        setBothCalc,
        setBothCalcReport,
        bothCalc,
        bothCalcReport,
        total,
        setTotal,
        dairyProductionReport,
        setDairyProductionReport,
        farmlandsReport,
        waterFootprint,
        waterFootprintReport,
        reportIsOpen,
        setReportOpen,
        clearCftData,
        validIp,
      }}
    >
      {props.children}
    </CftContext.Provider>
  );
}

export function useCft() {
  const {
    dairyProduction,
    setDairyProduction,
    farmlands,
    setFarmlands,
    setWaterFootprint,
    setBothCalc,
    setBothCalcReport,
    bothCalc,
    bothCalcReport,
    total,
    setTotal,
    dairyProductionReport,
    setDairyProductionReport,
    farmlandsReport,
    setFarmlandsReport,
    setWaterFootprintReport,
    reportIsOpen,
    setReportOpen,
    clearCftData,
    validIp,
    waterFootprint,
    waterFootprintReport,
  } = useContext(CftContext);
  const { t } = useTranslation(["cft"]);

  const saveDairyProduction = data =>
    setDairyProduction(values => {
      localStorage.setItem(
        DAIRY_PRODUCTION_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });

  const saveFarmlands = data => {
    return setFarmlands(values => {
      localStorage.setItem(
        FARMLANDS_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });
  };

  const saveWaterFootprint = data => {
    return setWaterFootprint(values => {
      localStorage.setItem(
        WATER_FOOTPRINT_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });
  };

  const saveWaterFootprintReport = data => {
    return setWaterFootprintReport(values => {
      localStorage.setItem(
        WATER_FOOTPRINT_REPORT_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });
  };

  const saveDairyProductionReport = data =>
    setDairyProductionReport(values => {
      localStorage.setItem(
        DAIRY_PRODUCTION_REPORT_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });

  const saveFarmlandsReport = data =>
    setFarmlandsReport(values => {
      localStorage.setItem(
        FARMLANDS_REPORT_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });

  const saveBothCalc = data => {
    return setBothCalc(values => {
      localStorage.setItem(
        BOTH_CALC_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });
  };

  const saveBothCalcReport = data => {
    return setBothCalcReport(values => {
      localStorage.setItem(
        BOTH_CALC_REPORT_KEY,
        JSON.stringify({
          ...values,
          ...data,
        })
      );
      return {
        ...values,
        ...data,
      };
    });
  };

  const meta = {
    title: {
      dairyProduction: dairyProduction.details.name
        ? dairyProduction.details.name
        : t("cft:types.dairyProduction", "Produkcja mleczarska"),
      farmlands: farmlands.details.name
        ? farmlands.details.name
        : t("cft:types.farmlands", "Pola uprawne"),
      waterFootprint: waterFootprint.details.name
        ? waterFootprint.details.name
        : t("cft:types.farmlands", "Pola uprawne"),
      bothCalc: bothCalc.details.name
        ? bothCalc.details.name
        : t("cft:types.farmlands", "Pola uprawne"),
    },
  };

  const progress = {
    farmlands: calculateGlobalProgress(farmlands),
    dairyProduction: calculateGlobalProgress(dairyProduction),
    waterFootprint: calculateGlobalProgress(waterFootprint),
    bothCalc: calculateGlobalProgress(bothCalc),
    reports: {
      waterFootprint: calculateGlobalReport(waterFootprintReport),
      farmlands: calculateGlobalReport(farmlandsReport),
      dairyProduction: calculateGlobalReport(dairyProductionReport),
      bothCalc: calculateGlobalReport(bothCalcReport),
    },
  };

  return {
    dairyProduction,
    setDairyProduction,
    farmlands,
    setFarmlands,
    total,
    setTotal,
    meta,
    progress,
    saveDairyProduction,
    saveFarmlands,
    saveWaterFootprint,
    saveWaterFootprintReport,
    waterFootprint,
    waterFootprintReport,
    dairyProductionReport,
    saveDairyProductionReport,
    farmlandsReport,
    saveFarmlandsReport,
    reportIsOpen,
    setReportOpen,
    clearCftData,
    validIp,
    bothCalc,
    bothCalcReport,
    saveBothCalc,
    saveBothCalcReport,
  };
}

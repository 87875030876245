import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftQuestionGroup,
  CftTooltip,
} from "../../../components";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { titlesCattleHerd } from "../CharacteristicsDPForm/CattleHerdFields";
import { feedType, newFeedType } from "./forage.consts";

export const initFeedHerd = () => ({
  id: Date.now(),
  itemCategory: "",
  item: "",
  dryMmatterValue: "",
});

function FeedHerd({ id, name }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft", "cftChickenBroiler"]);
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:forage.form.1", "Kategoria paszy")} />
        <CftFormField name={`${name}[${id}].itemCategory`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {newFeedType.map((b, key) => (
                  <CftBox
                    key={key}
                    isActive={value === b.value}
                    onClick={() => {
                      helpers.setValue(b.value);
                      setFieldValue(`${b.value}[${id}].item`, "");
                    }}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    {b.icon}
                    <p className="cft-box__paragraph">{b[language]}</p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormField name={`${name}[${id}].itemCategory`}>
        {({ value }) =>
          value ? (
            <>
              <CftFormControl variant="small">
                <CftLabel label={t("cft:forage.form.2", "Składnik paszowy")} />
                <CftSelectField
                  name={`${name}[${id}].item`}
                  options={
                    feedType[value]
                      ? feedType[value].map(opt => ({
                          value: opt.value,
                          label: opt[language],
                        }))
                      : []
                  }
                />
              </CftFormControl>
            </>
          ) : null
        }
      </CftFormField>

      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:others.forage.1", "Średnie dzienne DMI")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:forage.tooltips.1",
                "Wprowadź wartość suchej masy spożywanej przez jedno zwierzę"
              )}
            />
          }
        />
        <CftTextField name={`${name}[${id}].dryMmatterValue`} adornment="kg" />
      </CftFormControl>
    </>
  );
}

function FeedPerAnimal() {
  const { t } = useTranslation(["cft"]);
  const { values } = useFormikContext();
  const groupRefs = useRef([]);

  if (values.approach !== "1") {
    return null;
  }
  return (
    <>
      <CftFormField name="herd">
        {({ value }) => (
          <>
            {value.map((herd, key) => {
              if (!herd) {
                return null;
              }
              return (
                <CftTank key={key} width="100%" display="felx">
                  <h3 className="cft-headline">{t(titlesCattleHerd[key])}</h3>
                  <CftFormField name={`herd[${key}].animals`}>
                    {({ value, helpers, meta }) => (
                      <div>
                        {value.map(({ id }, k) => (
                          <CftQuestionGroup
                            key={id}
                            label={`${t("cft:forage.title")} ${k + 1}`}
                            withoutMarginBottom={k + 1 === value.length}
                            onRemove={
                              k !== 0
                                ? () =>
                                    helpers.setValue(
                                      value.filter(f => f.id !== id)
                                    )
                                : undefined
                            }
                            hasError={meta.touched[k] && meta.error[k]}
                          >
                            <FeedHerd id={k} name={`herd[${key}].animals`} />
                          </CftQuestionGroup>
                        ))}
                        <CftFormControl variant="small">
                          <CftTank display="flex" justifyContent="space-around">
                            <CftButton
                              onClick={() => {
                                helpers.setValue([...value, initFeedHerd()]);
                                scrollToLastElement(groupRefs, 90);
                              }}
                            >
                              {value.length
                                ? t(
                                    "cftChickenBroiler:feedComponentsFields.fields.feed_componentsAddNext",
                                    "Dodaj kolejną paszę"
                                  )
                                : t(
                                    "cftChickenBroiler:feedComponentsFields.fields.feed_componentsAdd",
                                    "Dodaj paszę"
                                  )}
                            </CftButton>
                          </CftTank>
                        </CftFormControl>
                      </div>
                    )}
                  </CftFormField>
                </CftTank>
              );
            })}
          </>
        )}
      </CftFormField>
    </>
  );
}

export default FeedPerAnimal;

export const detailsWaterFootprintForm = {
  name: "",
  cultivate: "",
  crop: "",
  areal: {
    unit: "",
    value: "",
  },
  seedAmount: {
    unit: undefined,
    value: undefined,
  },
  exit: "",
  harvestAmount: "",
  year: "",
};

export const soilWaterFootprint = {
  soilType: "",
  soilTypeAdvanced: "",
  soilMoisture: "",
};

export const waterFootprintReport = {
  crop: {},
  soil: {},
  irigation: [],
};

export const irrigationWaterFootprint = {
  sowingDate: "",
  harvestingDate: "",
  method: "",
  waterSource: "",
  waterAmount: "",
  wateredArea: "",
  sameParameters: null,
  weeks: [],
};

export const waterFootprintInit = {
  details: detailsWaterFootprintForm,
  soil: soilWaterFootprint,
  irrigation: irrigationWaterFootprint,
};

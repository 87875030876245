import Cookies from "js-cookie";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import AdditionalTool from "./AdditionalTool";

import { TOOLS, TOOLS_AUTHENTICATION } from "../constans";

const getShuffledArr = arr => {
  return arr.reduce(
    (newArr, _, i) => {
      const rand = i + Math.floor(Math.random() * (newArr.length - i));
      // eslint-disable-next-line no-param-reassign
      [newArr[rand], newArr[i]] = [newArr[i], newArr[rand]];
      return newArr;
    },
    [...arr]
  );
};

const AdditionalTools = ({ slug }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const token = Cookies.get("user_token");

  const toolsList = getShuffledArr(TOOLS.filter(tool => slug !== tool.slug));

  const requirementsMap = new Map([
    [TOOLS_AUTHENTICATION.PREMIUM, "premium"],
    [
      TOOLS_AUTHENTICATION.LOGGED,
      t("cft:landingPage.others.8", "dla zalogowanych"),
    ],
    [TOOLS_AUTHENTICATION.NOT_LOGGED_IN, null],
  ]);

  return (
    <Row>
      <Col xs={12} className="tools-page__additional__tools">
        {toolsList
          .slice(0, 4)
          .map(
            ({
              slug,
              title,
              href,
              description,
              cover,
              requirement,
              thumbnailAnchor,
            }) => (
              <AdditionalTool
                key={slug}
                title={title[language]}
                to={token ? href.user : href.anonymous}
                description={description[language]}
                cover={cover}
                thumbnailAnchor={thumbnailAnchor}
                requirements={requirementsMap.get(requirement) || null}
              />
            )
          )}
      </Col>
    </Row>
  );
};

export default AdditionalTools;

import React from "react";
import PropTypes from "prop-types";

import "./CftProgressCircle.scss";

function CftProgressCircle({ value, color }) {
  const R = 30;
  const strokeDasharray = 2 * Math.PI * R;
  const strokeDashoffset = strokeDasharray * (1 - value * 0.01);

  return (
    <div className="cft-progress-circle">
      <svg className="cft-progress-circle__progress" viewBox="0 0 70 70">
        <circle
          cx="35"
          cy="35"
          r="30"
          fill="none"
          stroke="#e6e6e6"
          strokeWidth="5"
        />
        <circle
          className="cft-progress-circle__progress__value"
          cx="35"
          cy="35"
          r="30"
          fill="none"
          strokeWidth="5"
          stroke={color}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <span
        className="cft-progress-circle__value"
        style={{
          color,
        }}
      >
        {value}%
      </span>
    </div>
  );
}

CftProgressCircle.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
};

CftProgressCircle.defaultProps = {
  color: "#00965E",
};

export default CftProgressCircle;

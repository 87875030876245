const CFT_MAIN = "/agroemisja";
const CFT_DAIRY_PRODUCTION = "/produkcja-mleczarska";
const CFT_FARMLANDS = "/pola-uprawne";
const CFT_WATER_FOOTPRINT = "/slad-wodny";
const CFT_BOTH_CALC = "/obie-kalkulacje";
const CFT_CHICKEN_BROILER = "/brojler-kurzy";
const CFT_TURKEY = "/indyki";
const CFT_PIGS = "/trzoda-chlewna";
const CFT_CATTLE = "/bydlo-opasowe";

export default {
  MAIN: CFT_MAIN,
  DAIRY_PRODUCTION: {
    MAIN: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}`,
    INTRO: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/intro`,
    DETAILS: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/szczegoly-hodowli`,
    CHARACTERISTICS: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/charakterystyka-stada`,
    CATTLE_GRAZING: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/wypas-bydla`,
    FORAGE: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/pasza`,
    MANURE: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/obornik`,
    ENERGY_AND_PROCESSING: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/energia-i-przetwarzanie`,
    TRANSPORT: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/transport`,
    REPORT: `${CFT_MAIN}${CFT_DAIRY_PRODUCTION}/raport`,
  },
  FARMLANDS: {
    MAIN: `${CFT_MAIN}${CFT_FARMLANDS}`,
    INTRO: `${CFT_MAIN}${CFT_FARMLANDS}/intro`,
    DETAILS: `${CFT_MAIN}${CFT_FARMLANDS}/detale-uprawy`,
    SOIL: `${CFT_MAIN}${CFT_FARMLANDS}/gleba`,
    FERTILIZERS: `${CFT_MAIN}${CFT_FARMLANDS}/nawozy`,
    FUEL_AND_ENERGY: `${CFT_MAIN}${CFT_FARMLANDS}/paliwo-i-energia`,
    IRRIGATION: `${CFT_MAIN}${CFT_FARMLANDS}/irygacja`,
    CARBON_CHANGES: `${CFT_MAIN}${CFT_FARMLANDS}/zmiany-weglowe`,
    TRANSPORT: `${CFT_MAIN}${CFT_FARMLANDS}/transport`,
    REPORT: `${CFT_MAIN}${CFT_FARMLANDS}/raport`,
    CALC_TYPE: `${CFT_MAIN}${CFT_FARMLANDS}/typ`,
    WATER_FOOTPRINT: {
      MAIN: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}`,
      INTRO: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}/intro`,
      DETAILS: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}/detale-uprawy`,
      SOIL: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}/gleba`,
      IRRIGATION: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}/irygacja`,
      REPORT: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_WATER_FOOTPRINT}/raport`,
    },
    BOTH_CALC: {
      MAIN: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}`,
      INTRO: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/intro`,
      DETAILS: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/detale-uprawy`,
      SOIL: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/gleba`,
      FERTILIZERS: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/nawozy`,
      FUEL_AND_ENERGY: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/paliwo-i-energia`,
      IRRIGATION: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/irygacja`,
      CARBON_CHANGES: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/zmiany-weglowe`,
      TRANSPORT: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/transport`,
      REPORT: `${CFT_MAIN}${CFT_FARMLANDS}${CFT_BOTH_CALC}/raport`,
    },
  },
  CHICKEN_BROILER: {
    MAIN: `${CFT_MAIN}${CFT_CHICKEN_BROILER}`,
    COOPS: `${CFT_MAIN}${CFT_CHICKEN_BROILER}/kurniki`,
    COOP: `${CFT_MAIN}${CFT_CHICKEN_BROILER}/kurniki/:id`,
    EMISSIONS: `${CFT_MAIN}${CFT_CHICKEN_BROILER}/emisje`,
    REPORT: `${CFT_MAIN}${CFT_CHICKEN_BROILER}/raport`,
  },
  TURKEY: {
    MAIN: `${CFT_MAIN}${CFT_TURKEY}`,
    COOPS: `${CFT_MAIN}${CFT_TURKEY}/indyczniki`,
    COOP: `${CFT_MAIN}${CFT_TURKEY}/indyczniki/:id`,
    EMISSIONS: `${CFT_MAIN}${CFT_TURKEY}/emisje`,
    REPORT: `${CFT_MAIN}${CFT_TURKEY}/raport`,
  },
  PIGS: {
    MAIN: `${CFT_MAIN}${CFT_PIGS}`,
    COOPS: `${CFT_MAIN}${CFT_PIGS}/stado-i-pasza`,
    COOP: `${CFT_MAIN}${CFT_PIGS}/stado-i-pasza/:id`,
    EMISSIONS: `${CFT_MAIN}${CFT_PIGS}/emisje`,
    REPORT: `${CFT_MAIN}${CFT_PIGS}/raport`,
  },
  CATTLE: {
    MAIN: `${CFT_MAIN}${CFT_CATTLE}`,
    COOPS: `${CFT_MAIN}${CFT_CATTLE}/stado-i-pasza`,
    COOP: `${CFT_MAIN}${CFT_CATTLE}/stado-i-pasza/:id`,
    EMISSIONS: `${CFT_MAIN}${CFT_CATTLE}/emisje`,
    REPORT: `${CFT_MAIN}${CFT_CATTLE}/raport`,
  },
};

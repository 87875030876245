export default {
  selectSearchResults: state => state.properties.search,
  selectTransactionTypes: state => state.properties.transactionTypes.results,
  selectPropertyTypes: state => state.properties.propertyTypes.results,
  selectPropertyType: id => state =>
    state.properties.propertyTypes.results.find(type => type.id === id) || {
      id: -1,
      name: null,
    },
  selectTransactionType: id => state =>
    state.properties.transactionTypes.results.find(type => type.id === id) || {
      id: -1,
      name: null,
    },
  selectProperty: state => state.properties.property,
  selectPropertyKeeper: state => state.properties.property.keeper_data,
  selectPropertyMedia: state => state.properties.medias.list,
  selectProvinces: state => state.properties.provinces.results,
  selectPromotedProperty: state => state.properties.promoted.list,
  selectSuggestedProperty: state => state.properties.suggested,
};

import { Button } from "agronomist-ui-components";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import Footer from "../Footer/Footer";
import { Loader } from "../Loader/Loader";
import MarketPricesWidget from "../MarketPricesWidget/MarketPricesWidget";
import NavigationBar from "../NavigationBar/NavigationBar";
import "../Sections/Sections.scss";
import "./ArticlesContainer.scss";
import { ArticleCardWrapper } from "../Article/ArticleCardWrapper";

const ArticlesContainer = ({
  title,
  className,
  categories,
  articlesPages,
  background = "light",
  type = "article", // or "multimedia"
  isLoading = false,
  isValidating = false,
  loadMore,
  loadMoreAction,
  hideCat = [],
}) => {
  const { t } = useTranslation(["globals"]);
  const isArticle = type === "article";
  const isMobile = window.innerWidth < 768;

  return (
    <Container fluid>
      <NavigationBar />
      <Container>
        <MarketPricesWidget />
      </Container>
      <div className={`Sections--${background}`}>
        <Container>
          <section
            className={classNames("b-section", className, {
              "has-categories": !!categories,
            })}
          >
            <header>{title}</header>
            {categories}
            {articlesPages.length
              ? articlesPages.map((articles, pageIndex) => {
                  return (
                    <Row key={pageIndex}>
                      {articles.results.length ? (
                        articles.results.map((article, index) => {
                          const big =
                            pageIndex === 0 && index < (isMobile ? 1 : 2);
                          const cardType = big ? "big" : "default";

                          if (type === "article") {
                            return (
                              <Col
                                sm={12}
                                md={6}
                                lg={big ? 6 : 3}
                                key={article.id}
                                className="mb-20"
                              >
                                <ArticleCardWrapper
                                  article={article}
                                  cardType={cardType}
                                />
                              </Col>
                            );
                          }
                          if (type === "multimedia") {
                            return (
                              <Col
                                sm={12}
                                md={6}
                                lg={big ? 6 : 3}
                                key={article.id}
                                className="mb-20"
                              >
                                <ArticleCardWrapper
                                  article={article}
                                  cardType={cardType}
                                  isMultimedia
                                />
                              </Col>
                            );
                          }
                          return null;
                        })
                      ) : !isLoading ? (
                        <div className="EmptyArticlesState">
                          <p>{t("globals:articles.none")}</p>
                        </div>
                      ) : null}
                    </Row>
                  );
                })
              : null}
            {isValidating && (
              <div className="ArticlesLoader">
                <Loader absolute />
              </div>
            )}
            {loadMore && loadMoreAction && (
              <div className="ArticlesLoadMore">
                <Button isPrimary onClick={loadMoreAction}>
                  {isArticle
                    ? t("globals:buttons.loadMoreArticles")
                    : t("globals:buttons.loadMoreMultimedia")}
                </Button>
              </div>
            )}
          </section>
        </Container>
      </div>
      {/* <FooterPosts /> */}
      <Footer />
    </Container>
  );
};

export default ArticlesContainer;

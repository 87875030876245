export const getQueryStringValue = (key, url) => {
  const params = url ? `?${url.split("?")[1]}` : window.location.search;
  return decodeURIComponent(
    params.replace(
      new RegExp(
        `^(?:.*[&\\?]${
          encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") // eslint-disable-line
        }(?:\\=([^&]*))?)?.*$`,
        "i"
      ),
      "$1"
    )
  );
};

export function objectToGetParams(object) {
  return `?${Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join("&")}`;
}

export function sliceString(txt = "", toSlice = 10) {
  if (txt.length > toSlice) {
    return `${txt.slice(0, toSlice)}…`;
  }

  return txt;
}

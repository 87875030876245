import React from "react";
import PropTypes from "prop-types";
import cl from "classnames";

import "./CftFormControl.scss";

function CftFormControl({
  children,
  variant,
  withoutMarginBottom,
  withoutMarginTop,
  alignLeft,
  isAlternative,
}) {
  return (
    <div
      className={cl("cft-form-control", {
        "cft-form-control--full": variant === "full",
        "cft-form-control--medium": variant === "medium",
        "cft-form-control--small": variant === "small",
        "cft-form-control--withoutMarginBottom": withoutMarginBottom,
        "cft-form-control--withoutMarginTop": withoutMarginTop,
        "cft-form-control--alignLeft": alignLeft,
        "cft-form-control--isAlternative": isAlternative,
      })}
    >
      {children}
    </div>
  );
}

CftFormControl.propTypes = {
  variant: PropTypes.oneOf(["full", "medium", "small"]),
  withoutMarginBottom: PropTypes.bool,
  withoutMarginTop: PropTypes.bool,
  isAlternative: PropTypes.bool,
};

CftFormControl.defaultProps = {
  variant: "full",
  withoutMarginBottom: false,
  withoutMarginTop: false,
  isAlternative: false,
};

export default CftFormControl;

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "..";

import useBanners from "../../views/ArticlesPage/useBanners";

import "./PageHeader.scss";

function PageHeader({
  breadcrumbs,
  title,
  subtitle,
  backgroundSrc,
  isBalck,
  slug,
}) {
  const { banner } = useBanners({ slug });

  return (
    <div
      className={classnames("PageHeader", {
        "PageHeader--black": isBalck,
      })}
      style={{
        backgroundImage: `url("${banner ? banner.file : backgroundSrc}")`,
      }}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <Breadcrumbs
              currentName={breadcrumbs.currentName}
              path={breadcrumbs.path}
            />
            <header>
              <h1>{title}</h1>
              <h3>{subtitle}</h3>
            </header>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

PageHeader.propTypes = {
  backgroundSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.shape({
    currentName: PropTypes.string.isRequired,
    path: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
  isBalck: PropTypes.bool,
};

PageHeader.defaultProps = {
  breadcrumbs: {
    currentName: "",
    path: [],
  },
  backgroundSrc: "",
  isBalck: false,
};

export default PageHeader;

import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import { ReactComponent as ChevronIcon } from "../../../assets/icons/chevron-up.svg";
import { ReactComponent as Chevron2Icon } from "../../../assets/icons/chevron-down.svg";
import "./ReadMoreLess.scss";

function ReadMoreLess({ children, t }) {
  const [isReadMore, setReadMore] = useState(false);

  return (
    <div className="ReadMoreLess">
      {isReadMore ? (
        <div className="ReadMoreLess__content">{children[1]}</div>
      ) : (
        <div className="ReadMoreLess__content">{children[0]}</div>
      )}
      <div className="ReadMoreLess__button">
        <button type="button" onClick={() => setReadMore(!isReadMore)}>
          {isReadMore
            ? t("globals:component.less")
            : t("globals:component.more")}
          {isReadMore ? <ChevronIcon /> : <Chevron2Icon />}
        </button>
      </div>
    </div>
  );
}

export default withTranslation(["globals"])(ReadMoreLess);

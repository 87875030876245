/* eslint-disable no-undef */
import Cookies from "js-cookie";
import * as actions from "./actions";

import { ONBOARDING_COOKIE } from "../../../cft/components/CftOnboardingPoultry/CftOnboardingPoultry";
import { KEY_STORAGE as TURKEY_KEY_STORAGE } from "../../../cft/utils/useCftTurkeyValues";
import { KEY_STORAGE as CHICKEN_KEY_STORAGE } from "../../../cft/utils/useCftChickenBroilerValues";
import { KEY_STORAGE as PIGS_KEY_STORAGE } from "../../../cft/utils/useCftPigsValues";
import { KEY_STORAGE as CATTLE_KEY_STORAGE } from "../../../cft/utils/useCftCattleValues";

const InitialState = {
  token: "",
  resetPasswordStatus: 0,
  changePasswordStatus: 0,
  user: {
    id: -1,
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    user_type: 0,
    consumer_type: 0,
    verification_account_number: "",
    verification_address: "",
    defaults_markets: [],
    consumer_status: -1,
    allowed_tools: -1,
    telephone: "",
    is_consumer_bank_client: false,
    is_expert: false,
    is_email_confirmed: true,
    categories: [],
  },
  isError: false,
  loggedIn: false,
  isRegistered: false,
  changedData: false,
  redirect: false,
};

export default function user(state = InitialState, action) {
  switch (action.type) {
    case actions.REGISTER_USER_SUCCESS: {
      const { data } = action.payload;
      const { token, expired_at } = data;
      // TODOOOOOO expired_at
      const expires = new Date(expired_at);
      Cookies.set("user_token", token, {
        expires, // expired_at
        secure: true,
      });

      // window.location = `${window.location.origin}/user/registrationFollow`;

      return {
        ...state,
        token: data.token,
        user: data.user,
        isError: false,
        isRegistered: true,
      };
    }
    case actions.LOG_OUT: {
      Cookies.remove("user_token");
      Cookies.remove("user_client_type");
      Cookies.remove("user_type");
      Cookies.remove(ONBOARDING_COOKIE);
      localStorage.removeItem(`cft:${TURKEY_KEY_STORAGE}`);
      localStorage.removeItem(`cft:${CHICKEN_KEY_STORAGE}`);
      localStorage.removeItem(`cft:${PIGS_KEY_STORAGE}`);
      localStorage.removeItem(`cft:${CATTLE_KEY_STORAGE}`);
      return {
        ...InitialState,
      };
    }
    case actions.LOG_USER_SUCCESS: {
      const { data } = action.payload;
      const { token } = data;
      if (token) {
        let { expired_at: expiredAt } = data;

        expiredAt = new Date(expiredAt);

        Cookies.set("user_token", token, {
          expires: expiredAt,
          secure: true,
        });
        Cookies.set("user_client_type", data.user.consumer_type, {
          expires: expiredAt,
          secure: true,
        });
        Cookies.set("user_type", data.user.user_type, {
          expires: expiredAt,
          secure: true,
        });

        !!data.user.favorite_tags &&
          data.user.favorite_tags.forEach((item, index) => {
            data.user.favorite_tags[index].translations = JSON.parse(
              item.translations
            );
          });
        return {
          ...state,
          token: data.token,
          user: data.user,
          isError: false,
          loggedIn: true,
        };
      }
      return {
        ...state,
      };
    }
    case actions.ACTIVATE_USER_SUCCESS:
    case actions.UPDATE_USER_SUCCESS: {
      const { data } = action.payload;
      const token = Cookies.get("user_token");
      return {
        ...state,
        token,
        user: data,
        isError: false,
        changedData: true,
      };
    }
    case actions.GET_USER_DATA_SUCCESS: {
      const { data } = action.payload;
      const token = Cookies.get("user_token");
      Cookies.set("user_client_type", data.consumer_type, { secure: true });
      Cookies.set("user_lang", data.default_language.toLowerCase(), {
        secure: true,
      });
      !!data.favorite_tags &&
        data.favorite_tags.forEach((item, index) => {
          data.favorite_tags[index].translations = JSON.parse(
            item.translations
          );
        });
      return {
        ...state,
        token,
        user: data,
        isError: false,
      };
    }
    case actions.DELETE_USER_SUCCESS: {
      return { ...state };
    }
    case actions.UPDATE_USER_FAIL:
    case actions.DELETE_USER_FAIL:
    case actions.REGISTER_USER_FAIL:
    case actions.LOG_USER_FAIL:
    case actions.ACTIVATE_USER_FAIL:
    case action.GET_USER_DATA_FAIL: {
      const { data } = action.error.response;
      return {
        ...state,
        isError: true,
        errors: data,
      };
    }

    case actions.CLEAR_ERRORS: {
      const updateState = state;
      delete updateState.errors;
      updateState.isError = false;
      delete updateState.errors;

      return {
        ...updateState,
        loggedIn: false,
        isRegistered: false,
      };
    }

    case actions.CLEAR_ALERT: {
      return {
        ...state,
        changedData: false,
      };
    }

    case actions.SET_REDIRECT: {
      if (action.data) {
        Cookies.set("redirect", action.data, { secure: true });
      } else {
        Cookies.remove("redirect");
      }
      return {
        ...state,
        redirect: action.data,
      };
    }

    default:
      return state;
  }
}

export const logUserIn = (email, password) => ({
  type: "LOG_USER",
  payload: {
    request: {
      url: "/api/v1/login/",
      method: "POST",
      data: { email, password },
    },
  },
});

export const registerUser = (
  firstName,
  lastName,
  email,
  password,
  userType,
  code,
  default_language
) => ({
  type: "REGISTER_USER",
  payload: {
    request: {
      url: "/api/v1/register/",
      method: "POST",
      data: {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        consumer_type: userType,
        promotion_code: code,
        default_language,
      },
    },
    options: {
      returnRejectedPromiseOnError: true,
    },
  },
});

export const activateUser = code => ({
  type: actions.ACTIVATE_USER,
  payload: {
    request: {
      url: "/api/v1/activate/",
      method: "POST",
      data: {
        code,
      },
    },
  },
});

export const updateUserData = (fields, userId, token) => ({
  type: "UPDATE_USER",
  payload: {
    request: {
      url: `/api/v1/users/${userId}/`,
      method: "PATCH",
      data: { ...fields },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    options: {
      returnRejectedPromiseOnError: true,
    },
  },
});

export const getUserData = token => ({
  type: "GET_USER_DATA",
  payload: {
    request: {
      url: "/api/v1/users/me/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const resetPassword = (email, callback_url) => ({
  type: actions.RESET_PASSWORD,
  payload: {
    request: {
      url: "/api/v1/remind-password/",
      method: "POST",
      data: { email, callback_url },
    },
  },
});

export const changePassword = (code, password) => ({
  type: actions.CHANGE_PASSWORD,
  payload: {
    request: {
      url: "/api/v1/remind-password-complete/",
      method: "POST",
      data: { code, password },
    },
  },
});

export const changePasswordUserPanel = (
  id,
  token,
  previous_password,
  new_password
) => ({
  type: actions.CHANGE_PASSWORD_USER_PANEL,
  payload: {
    request: {
      url: `/api/v1/users/${id}/change_password/`,
      method: "POST",
      data: { previous_password, new_password },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const deleteUser = (token, userId) => ({
  type: "DELETE_USER",
  payload: {
    request: {
      url: `/api/v1/users/${userId}/`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const clearErrors = () => ({
  type: actions.CLEAR_ERRORS,
});

export const clearAlert = () => ({
  type: actions.CLEAR_ALERT,
});

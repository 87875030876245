import Cookies from "js-cookie";
import * as actions from "./actions";

const InitialState = {
  regions: [],
  specializations: [],
  isError: false,
};

export default function expertsUtilities(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_REGIONS_SUCCESS: {
      const {
        data: { results },
      } = action.payload;
      return {
        ...state,
        regions: results,
      };
    }
    case actions.GET_SPECS_SUCCESS: {
      const {
        data: { results },
      } = action.payload;
      return {
        ...state,
        specializations: results,
      };
    }
    case actions.GET_REGIONS_FAIL:
    case actions.GET_SPECS_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getRegions = () => {
  let headers;
  const token = Cookies.get("user_token");
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: actions.GET_REGIONS,
    payload: {
      request: {
        url: "/api/v1/regions/?limit=20",
        method: "GET",
        headers,
      },
    },
  };
};
export const getSpecializations = () => {
  let headers;
  const token = Cookies.get("user_token");
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: actions.GET_SPECS,
    payload: {
      request: {
        url: "/api/v1/specializations/?limit=100",
        method: "GET",
        headers,
      },
    },
  };
};

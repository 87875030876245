import * as actions from "./actions";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  isError: false,
};

export default function articlesTags(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_ARTICLES_TAGS_SUCCESS: {
      const { data } = action.payload;
      data.results.forEach((item, index) => {
        data.results[index].translations = JSON.parse(item.translations);
      });

      return {
        ...state,
        ...data,
        isError: false,
      };
    }
    case actions.GET_ARTICLES_TAGS_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getArticlesTags = () => ({
  type: "GET_ARTICLES_TAGS",
  payload: {
    request: {
      url: "/api/v1/article-tags/?limit=20",
      method: "GET",
      // headers: {
      //   Authorization: `Bearer ${token}`
      // }
    },
  },
});

import rolnikonBackground from "../../../assets/img/tools/rolnikon/rolnikon.jpg";
import satagroBackground from "../../../assets/img/tools/satagro/satagro.jpg";
// import agriviBackground from "../../../assets/img/tools/agrivi/agrivi.jpg";
import widgetBackground from "../../../assets/tools/common/ekspert_boczne.jpg";
import azotBackground from "../../../assets/tools/common/azotu.jpg";
import yourWeatherBackground from "../../../assets/img/tools/yourWeather/twojapogoda.jpg";
import cftBackground from "../../../assets/img/tools/cft-bg.jpg";
// import calcBackground from "../../../assets/tools/common/ekonomizator.jpg";
import calcinvBackground from "../../../assets/tools/common/kalkulator inwestycyjny.jpg";
import priceBackground from "../../../assets/tools/common/ceny.jpg";
import srodowiskoweBackground from "../../../assets/tools/common/srodowiskowe.jpg";
import regagriBackground from "../../../assets/img/tools/regagri/photo.jpg";
import kredytomatCover from "../../../assets/tools/common/hero_250x250.jpg";
import kredytomatIcon from "../../../assets/tools/pictures/kredytomat.png";
import azotIcon from "../../../assets/tools/pictures/calculator-nitrogin.png";
import agroemisjaIcon from "../../../assets/tools/pictures/agroemisja.png";
import calcinvIcon from "../../../assets/tools/pictures/calculator.png";
import pricesIcon from "../../../assets/tools/pictures/current-prices.png";
import weatherIcon from "../../../assets/tools/pictures/weather.png";
import askExpertIcon from "../../../assets/tools/pictures/ask-expert.png";
import satagroIcon from "../../../assets/tools/pictures/satagro.png";
import rolnikonIcon from "../../../assets/tools/pictures/rolnikon.png";
import regagriIcon from "../../../assets/tools/pictures/regagri.png";
import envToolsIcon from "../../../assets/tools/pictures/env-tools.png";
import agroRejestrIcon from "../../../assets/tools/pictures/agroRejestr.png";
import agroRejestrCover from "../../../assets/tools/fieldCardApp/agroRejestrCover.jpeg";

export const TOOLS_AUTHENTICATION = {
  NOT_LOGGED_IN: "not-logged-in",
  LOGGED: "logged",
  PREMIUM: "premium",
};

export const TOOLS_AUTHENTICATION_MAP = [
  {
    label: {
      gb: "without logging in",
      pl: "bez logowania",
    },
    value: TOOLS_AUTHENTICATION.NOT_LOGGED_IN,
  },
  {
    label: {
      gb: "for logged-in users",
      pl: "dla zalogowanych",
    },
    value: TOOLS_AUTHENTICATION.LOGGED,
  },
  {
    label: {
      gb: "for premium users",
      pl: "dla użytkowników premium",
    },
    value: TOOLS_AUTHENTICATION.PREMIUM,
    tooltip: {
      gb: "User verified as a client of BNP Paribas Bank",
      pl: "Użytkownik zweryfikowany jako klient Banku BNP Paribas",
    },
  },
];
export const TOOLS_TYPES = {
  OPTIMIZATION: "optimization",
  CALCULATION: "calculation",
  FINANCIAL: "financial",
  INFORMATION: "information",
  REGAGRI: "regagri",
};
export const TOOLS_TYPES_META = {
  [TOOLS_TYPES.OPTIMIZATION]: {
    title: {
      pl: "Narzędzia Optymalizujące",
      gb: "Cost Economiser",
    },
  },
  [TOOLS_TYPES.CALCULATION]: {
    title: {
      pl: "Narzędzia Obliczeniowe",
      gb: "Calculation Tools",
    },
  },
  [TOOLS_TYPES.FINANCIAL]: {
    title: {
      pl: "Narzędzia Finansowe",
      gb: "Financial tools",
    },
  },
  [TOOLS_TYPES.INFORMATION]: {
    title: {
      pl: "Narzędzia Informacyjne",
      gb: "Information Tools",
    },
  },
  [TOOLS_TYPES.REGAGRI]: {
    title: {
      pl: "RegAgri Explorer",
      gb: "RegAgri Explorer",
    },
  },
};
export const TOOLS = [
  {
    slug: "agro-rejestr",
    cover: agroRejestrCover,
    icon: agroRejestrIcon,
    thumbnailAnchor: "bottom center",
    href: {
      user: "/narzedzia/agro-rejestr",
      anonymous: "/narzedzia/agro-rejestr",
    },
    title: {
      pl: "Wkrótce nowość!",
      gb: "New tool coming soon!",
    },
    list: {
      pl: [
        "Wprowadź dane dotyczące roślin uprawianych na Twoich polach. Wybierz rodzaj uprawy i wykonywane zabiegi",
        "Rejestruj zakupy nawozów, środków ochrony roślin i innych produktów potrzebnych do prowadzenia działalności",
        "Generuj szybko finansowe podsumowanie swoich upraw",
      ],
      gb: [
        "Enter data on plants grown on your fields. Select the cultivation type and treatments carried out",
        "Record your purchases of fertilizers, plant protection means and other products needed for your operations",
        "Create quickly financial summary of your crops is just a few clicks away",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.FINANCIAL],
    description: {
      pl:
        "Prowadź ewidencję upraw, kosztów i sprzedaży plonów. Oceniaj rentowność Twoich zbiorów w dłuższej perspektywie",
      gb:
        "Keep records of crops, costs and crop sales. Assess the profitability of your harvest in the long term",
    },
    requirement: TOOLS_AUTHENTICATION.LOGGED,
  },
  {
    slug: "kredytomat",
    cover: kredytomatCover,
    icon: kredytomatIcon,
    href: {
      user: "/kredytomat",
      anonymous: "/kredytomat",
    },
    title: {
      pl: "Kredytomat",
      gb: "Kredytomat",
    },
    list: {
      pl: [
        "Określ swoją potrzebę dotyczącą bieżącej produkcji lub planów rozwojowych",
        "W kilku krokach sprecyzuj swoje kryteria i otrzymaj dopasowaną ofertę finansowania",
        "Skontaktuj się z Ekspertem Agro, który wyjaśni Ci wszystkie szczegóły",
      ],
      gb: [
        "Identify your need for ongoing production or development plans",
        "In a few steps, specify your criteria and receive a tailored financing offer",
        "Contact an Agro Expert who will explain all the details to you",
      ],
    },
    types: [TOOLS_TYPES.FINANCIAL],
    requirement: TOOLS_AUTHENTICATION.NOT_LOGGED_IN,
    description: {
      pl:
        "Twoja potrzeba w kilku prostych krokach znajdzie dopasowane rozwiązanie finansowe",
      gb:
        "Your need will find a suitable financial solution in a few simple steps",
    },
    thumbnailAnchor: "bottom",
  },
  {
    slug: "kalkulator-zuzycia-azotu",
    cover: azotBackground,
    icon: azotIcon,
    href: {
      user: "/kalkulator-zuzycia-azotu",
      anonymous: "/kalkulator-zuzycia-azotu",
    },
    title: {
      pl: "Kalkulator zużycia azotu",
      gb: "Nitrogen consumption calculator",
    },
    list: {
      pl: [
        "Łatwo wyliczysz swoją produkcję azotu pochodzenia zwierzęcego",
        "Sprawdzisz ile nawozu organicznego możesz użyć na swoich polach, a ile musisz składować",
        "Bądź w zgodzie z przepisami Dyrektywy Azotanowej",
      ],
      gb: [
        "You will easily calculate the production of nitrogen from animal origin",
        "You will check how much organic fertiliser you can use on your fields and how much you have to store",
        "Comply with the Nitrates Directive",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.NOT_LOGGED_IN,
    description: {
      pl: "Wylicz wartość azotu z produkcji zwierzęcej",
      gb: "Calculate the nitrogen value of your animal production",
    },
    thumbnailAnchor: "bottom",
  },
  {
    slug: "agroemisja",
    cover: cftBackground,
    icon: agroemisjaIcon,
    href: {
      user: "/agroemisja",
      anonymous: "/narzedzia/kalkulator-emisji-co2",
    },
    title: {
      pl: "Agroemisja - kalkulator emisji gazów cieplarnianych ",
      gb: "Agroemisja – greenhouse gas emission calculator",
    },
    list: {
      pl: [
        "Oszacuj swoją emisyjność gazów cieplarnianych oraz ślad wodny w gospodarstwie",
        "Sprawdź, w którym elemencie produkcji masz najwyższą emisyjność czy ślad wodny i jak je zmniejszyć",
        "Wyprzedź przepisy i już teraz weryfikuj i redukuj swój wpływ na środowisko naturalne",
      ],
      gb: [
        "Estimate the greenhouse gas emissions on your farm",
        "Identify the part of the production process with the highest emission and find out how to reduce it",
        "Stay ahead of legislation and verify and reduce your farm’s environmental impact now",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.LOGGED,
    description: {
      pl: "Oszacuj emisyjność swojej produkcji rolniczej oraz śladu wodnego",
      gb: "Estimate the emissivity of your plant and dairy production",
    },
    thumbnailAnchor: "bottom",
  },
  {
    slug: "kalkulator-kredytowy",
    cover: calcinvBackground,
    icon: calcinvIcon,
    href: {
      user: "/kalkulator-kredytowy",
      anonymous: "/kalkulator-kredytowy",
    },
    title: {
      pl: "Kalkulator kredytowy",
      gb: "Loan calculator",
    },
    list: {
      pl: [
        "Określ swoją potrzebę inwestycyjną i dopasuj sposób jej finansowania",
        "Wprowadź podstawowe dane jak wysokość finansowania, wartość dotacji i uzyskaj przewidywany harmonogram ratalny",
        "W przypadku pytań odwiedź najbliższy oddział Banku BNP Paribas",
      ],
      gb: [
        "Define your investment need and find a suitable way of its financing",
        "Enter basic data such as the amount of funding, the amount of subsidy and get a predicted instalment payment schedule",
        "If you have any questions, please visit the nearest branch of BNP Paribas Bank",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.FINANCIAL],
    requirement: TOOLS_AUTHENTICATION.NOT_LOGGED_IN,
    description: {
      pl:
        "Jedyny na rynku kalkulator finansowania inwestycji dedykowany produkcji rolniczej",
      gb:
        "The only calculator on the market dedicated to agricultural production",
    },
  },
  {
    slug: "srednie-ceny-zboza",
    cover: priceBackground,
    icon: pricesIcon,
    href: {
      user: "/srednie-ceny/zboza",
      anonymous: "/narzedzia/srednie-ceny",
    },
    title: {
      pl: "Aktualne ceny produktów rolnych lokalnych w skupach",
      gb: "Current prices of agricultural products",
    },
    list: {
      gb: [
        "Stay up to date with prices of agricultural products at collection points and at local markets",
        "Verify the price trends on the markets for cereals, oil crops, pork and poultry",
        "Decide when best to sell or buy agricultural products",
      ],
      pl: [
        "Model obliczeniowy dostarczany jest przez ekspertów Banku BNP Paribas",
        "Weryfikuj trendy cenowe na rynkach zbóż, roślin oleistych, wieprzowiny i drobiu",
        "Decyduj, kiedy najlepiej sprzedać lub kupić produkty rolne",
      ],
    },
    types: [TOOLS_TYPES.INFORMATION, TOOLS_TYPES.FINANCIAL],
    requirement: TOOLS_AUTHENTICATION.LOGGED,
    description: {
      pl: "Trzymaj rękę na pulsie i sprawdź aktualne ceny",
      gb: "Keep your finger on the pulse and check the current prices",
    },
    thumbnailAnchor: "right bottom",
  },
  {
    slug: "pogoda-dla-twojego-gospodarstwa",
    cover: yourWeatherBackground,
    icon: weatherIcon,
    href: {
      user: "/my/farm",
      anonymous: "/narzedzia/pogoda-dla-twojego-gospodarstwa",
    },
    title: {
      pl: "Twoja Pogoda",
      gb: "Your weather",
    },
    list: {
      pl: [
        "Precyzyjna prognoza pogody dla Twojego gospodarstwa",
        "Nie daj się zaskoczyć warunkom atmosferycznym w planowaniu zabiegów agrotechnicznych",
        "Alerty przymrozkowe, opadowe i śniegowe pozwolą uniknąć ewentualnych strat",
      ],
      gb: [
        "Accurate weather forecast for your farm",
        "Do not be surprised by the weather conditions in planning agrotechnical treatments",
        "Frost, rainfall and snow alerts will avoid possible losses",
      ],
    },
    types: [TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.LOGGED,
    description: {
      pl:
        "Miej pogodę pod kontrolą. Precyzyjna prognoza pogody dedykowana produkcji rolniczej",
      gb:
        "Keep the weather under control. Accurate weather forecast for your farm",
    },
    thumbnailAnchor: "right center",
  },
  {
    slug: "zapytaj-eksperta",
    cover: widgetBackground,
    icon: askExpertIcon,
    href: {
      user: "/eksperci",
      anonymous: "/narzedzia/zapytaj-eksperta",
    },
    title: {
      pl: "Zapytaj eksperta",
      gb: "Ask an expert",
    },
    list: {
      pl: [
        "Profesjonalni doradcy na wyciągnięcie ręki, bez konieczności wychodzenia z domu",
        "Wybierz eksperta z interesującej Cię tematyki i zadaj mu pytanie",
        "Uzyskaj szybką i rzeczową odpowiedź",
      ],
      gb: [
        "Professional advisers at your fingertips, without leaving your home",
        "Choose an expert on the topic you are interested in and ask him a question",
        "Get a quick and factual answer",
      ],
    },
    types: [TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.LOGGED,
    description: {
      pl: "Trudne decyzje? Zadaj pytanie zweryfikowanemu ekspertowi",
      gb: "Difficult decisions? Ask a verified expert",
    },
  },
  {
    slug: "satelitarny-monitoring-pol",
    cover: satagroBackground,
    icon: satagroIcon,
    href: {
      user: "/narzedzia/satelitarny-monitoring-pol",
      anonymous: "/narzedzia/satelitarny-monitoring-pol",
    },
    title: {
      pl: "SatAgro SATELITARNY MONITORING PÓL",
      gb: "SatAgro SATELLITE FIELD MONITORING",
    },
    list: {
      pl: [
        "Monitoruj swoje pola z wykorzystaniem danych z kosmosu",
        "Optymalizuj procesy nawożenia, weryfikuj stan upraw przy wykorzystaniu zdjęć satelitarnych",
        "Uzyskaj instrukcje do maszyn rolnictwa preczyjnego w prosty sposób",
      ],
      gb: [
        "Monitor your fields using data from space",
        "Optimise fertilisation processes, verify the crop condition with satellite images",
        "Get instructions for precision farming machines in an easy way",
      ],
    },
    types: [TOOLS_TYPES.OPTIMIZATION],
    requirement: TOOLS_AUTHENTICATION.PREMIUM,
    description: {
      pl: "Trudne decyzje? Zadaj pytanie zweryfikowanemu ekspertowi",
      gb: "Use space data to optimize fertilization",
    },
  },
  {
    slug: "rolnikon",
    cover: rolnikonBackground,
    icon: rolnikonIcon,
    href: {
      user: "/narzedzia/rolnikon",
      anonymous: "/narzedzia/rolnikon",
    },
    title: {
      pl: "RolnikON SYSTEM ZARZĄDZANIA GOSPODARSTWEM",
      gb: "RolnikON FARM MANAGEMENT SYSTEM",
    },
    list: {
      pl: [
        "Zarządzaj kompleksowo swoim gospodarstwem rolnym",
        "Wykonaj wszystkie czynności przy pomocy komputera lub telefonu",
        "Rozliczaj się z instytucjami poprzez aktywne formularze",
      ],
      gb: [
        "Manage comprehensively your agricultural farm",
        "Perform all activities with a computer or telephone",
        "Settle your accounts with institutions through active forms",
      ],
    },
    types: [TOOLS_TYPES.OPTIMIZATION],
    requirement: TOOLS_AUTHENTICATION.PREMIUM,
    description: {
      pl: "Wszystkie dane dotyczące Twojego gospodarstwa w jednym miejscu",
      gb: "All your farm data in one place",
    },
    thumbnailAnchor: "right bottom",
  },
  // {
  //   slug: "agrivi",
  //   cover: agriviBackground,
  //   href: {
  //     user: "/narzedzia/agrivi",
  //     anonymous: "/narzedzia/agrivi",
  //   },
  //   title: {
  //     pl: "Agrivi SYSTEM ZARZĄDZANIA GOSPODARSTWEM",
  //     gb: "Agrivi FARM MANAGEMENT SYSTEM",
  //   },
  //   list: {
  //     pl: [
  //       "Zarządzaj swoim przedsiębiorstwem rolnym z jednego miejsca",
  //       "Planuj prace maszyn i pracowników oraz weryfikuj stany magazynowe",
  //       "Analizuj przygotowane raporty i podejmuj kluczowe decyzje",
  //     ],
  //     gb: [
  //       "Manage your agricultural enterprise from one place",
  //       "Plan machinery and staff work and verify the inventory",
  //       "Analyze prepared reports and make key decisions",
  //     ],
  //   },
  //   types: [TOOLS_TYPES.OPTIMIZATION],
  //   requirement: TOOLS_AUTHENTICATION.PREMIUM,
  //   description: {
  //     pl: "Zarządzaj swoim przedsiębiorstwem rolnym z dowolnego miejsca",
  //     gb: "Manage your agricultural business from anywhere",
  //   },
  //   thumbnailAnchor: "right center",
  // },
  {
    slug: "srodowiskowe",
    cover: srodowiskoweBackground,
    icon: envToolsIcon,
    href: {
      user: "/narzedzia/narzedzia-srodowiskowe",
      anonymous: "/narzedzia/narzedzia-srodowiskowe",
    },
    title: {
      pl: "Narzędzia środowiskowe",
      gb: "Environmental tools",
    },
    list: {
      pl: [
        "Oblicz przychód i rozchód składników odżywczych, a następnie nadwyżki/deficytu i wydajności wykorzystania składników odżywczych w gospodarstwie",
        "Wylicz ilość azotu wymywanego z Twojego pola",
        "Zwiększ swoją świadomość w zakresie wpływu na środowisko naturalne",
      ],
      gb: [
        "Calculate the balance of nutrients, followed by the surplus/deficit and the efficiency of nutrient use on your farm",
        "Calculate the amount of nitrogen leached from your field",
        "Increase your awareness of the environmental impact",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.NOT_LOGGED_IN,
    description: {
      pl: "Oblicz swój wpływ na środowisko naturalne",
      gb: "Calculate your environmental impact",
    },
  },
  {
    slug: "regagri",
    cover: regagriBackground,
    icon: regagriIcon,
    href: {
      user: "/narzedzia/regagri-explorer",
      anonymous: "/narzedzia/regagri-explorer",
    },
    title: {
      pl: "RegAgri Explorer",
      gb: "RegAgri Explorer",
    },
    list: {
      pl: [
        "Oszacuj potencjał swojej gleby do redukcji emisji i wychwytywania CO2 z atmosfery",
        "Sprawdź wpływ różnych praktyk rolnictwa regeneracyjnego na redukcję emisji dla wybranego obszaru",
        "Profesjonalne narzędzie oparte na wiarygodnym modelu i źródłach danych",
      ],
      gb: [
        "Estimate the soil sequestration potential for next years",
        "Check impact of different regenerative farming practice on emission reduction for selected area",
        "Professional tool based on reliable model and data sources",
      ],
    },
    types: [TOOLS_TYPES.CALCULATION, TOOLS_TYPES.INFORMATION],
    requirement: TOOLS_AUTHENTICATION.LOGGED,
    description: {
      pl:
        "Oszacuj potencjał swojej gleby do redukcji emisji i wychwytywania CO2 z atmosfery",
      gb: "Estimate the soil sequestration potential for next years",
    },
  },
];

/* eslint-disable import/no-unresolved */
import React from "react";
import {
  Hero,
  Benefits,
  InsuranceScope,
  DownloadDocuments,
  Disclaimer,
  ContactForm,
  Headline,
  BenefitsTable,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";

import imageUrl from "../assets/images/kredyt_37.png";
import fileA from "../../assets/prf/pdf/02 Generali Życie i zdrowie Karta produktu.pdf";
import fileB from "../../assets/prf/pdf/03 Generali Zycie i zdrowie Ustandaryzowany dokument.pdf";
import fileC from "../../assets/prf/pdf/04 Generali Życie i zdrowie Ogólne Warunki Ubezpieczenia.pdf";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Ubezpiecz to, co najcenniejsze: życie i zdrowie"
    ),
    title: "Ubezpiecz to, co najcenniejsze: życie i zdrowie",
    description:
      "Poszukujesz najlepszej ochrony ubezpieczeniowej w przypadku zachorowania lub nieszczęśliwego wypadku? Z nami Ty i Twoi bliscy poczujecie się bezpieczni.",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Dlaczego warto posiadać ubezpieczenie życia i zdrowia?",
    subtitle:
      "Ponieważ warto zabezpieczyć spłatę kredytu w przypadku nieszczęśliwego zdarzenia, aby jego spłatą nie obciążać bliskich nam osób.",
    benefits: [
      {
        title: "Pokrycie kosztów utrzymania i obsługi zadłużenia",
        icon: "MoneyPlus",
      },
      {
        title:
          "Szeroki zakres obejmujący śmierć w NNW przy pracy rolniczej oraz w następstwie choroby zakaźnej w rolnictwie",
        icon: "PaperBookmark",
      },
      {
        title: "Szeroki zakres uszczerbków na zdrowiu w następstwie wypadku",
        icon: "PaperBookmark",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak wykupić ubezpieczenie?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź ubezpieczenie spełniające Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe aby porozmawiać z Ekspertem ds. Ubezpieczeń lub odwiedź nas w Banku BNP.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Formalności załatwimy drogą elektroniczną, abyś Ty mógł spokojnie i bezpiecznie prowadzić  gospodarstwo",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Karta produktu",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileA,
      },
      {
        name: "Ustandaryzowany dokument",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileB,
      },
      {
        name: "Ogólne Warunki Ubezpieczenia",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Treści zawarte na niniejszej stronie mają charakter wyłącznie reklamowy i informacyjny oraz nie stanowią oferty w rozumieniu Kodeksu Cywilnego. Świadczenie ochrony ubezpieczeniowej następuje na warunkach określonych w umowie zawartej z Klientem za pośrednictwem Banku BNP Paribas, działającego jako agent ubezpieczeniowy, przez Generali Towarzystwo Ubezpieczeń S.A. oraz w Ogólnych Warunkach Ubezpieczeń. Szczegółowe warunki ubezpieczenia, w tym ograniczenia i wyłączenia odpowiedzialności z nimi związane, jak również informacje na temat zasad działania poszczególnych produktów i kosztów z nimi związanych określone są w obowiązujących dla danego produktu Ogólnych Warunkach Ubezpieczenia, przekazywanych każdorazowo klientowi przed zawarciem umowy ubezpieczenia. Generali Towarzystwo Ubezpieczeń S.A. z siedzibą w Warszawie przy ul. Postępu 15B, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 10623, kapitał zakładowy 191.000.000 PLN wpłacony w całości, NIP 526-23-49-108, należąca do Grupy Generali, figurującej w Rejestrze Grup Ubezpieczeniowych prowadzonym przez IVASS.",
    ],
  },
  INSURANCE_SCOPE_1: {
    title: "Przedmiotem ubezpieczenia jest:",
    items: [
      "życie i zdrowie Ubezpieczonego",
      "życie dziecka Ubezpieczonego",
      "życie współmałżonka Ubezpieczonego",
    ],
    icon: "HeartEcg",
  },
  INSURANCE_SCOPE_3: {
    title: "Zalety ubezpieczenia",
    description:
      "Z ubezpieczenia mogą skorzystać osoby od 16. do 70. roku życia. Dla rolników ważne są:",
    items: [
      "szeroki zakres i wysokie sumy ubezpieczenia ",
      "wysokość składki niezależna od wieku i zawodu ",
      "brak wymaganych badań lekarskich i szybki proces zawarcia polisy",
      "ochrona w przypadku poważnego zachorowania",
      "refundacja zakupów środków ortopedycznych",
    ],
    icon: "HeartEcgShield",
    bg: "white",
    iconBg: "orange",
  },
  BENEFITS_TABLE: {
    packages: [
      "Pokrycie kosztów utrzymania i obsługi zadłużenia",
      "Trwały uszczerbek na zdrowiu w NNW (1% SU za 1% uszczerbku)",
      "Poważne zachorowanie",
      "Pobyt Ubezpieczonego w szpitalu w następstwie choroby",
      "Pobyt w szpitalu w NNW",
      "Operacje chirurgiczne w następstwie choroby i NNW",
      "Koszty leczenia i pakiet świadczeń pielęgnacyjnych związanych z NNW",
      "Śmierć w NNW",
      "Śmierć w NNW w ruchu komunikacyjnym",
      "Śmierć współmałżonka / partnera w NNW",
      "Śmierć dziecka w NNW",
      "Śmierć w następstwie choroby zakaźnej w rolnictwie",
      "Śmierć w NNW przy pracy rolniczej",
      "Zryczałtowane koszty pogrzebu",
      "Zryczałtowane koszty pogrzebu współmałżonka",
      "Zryczałtowane koszty pogrzebu dziecka",
      "Renta dla każdego z osieroconych dzieci w następstwie śmierci Ubezpieczonego",
      "Składka miesięczna",
    ],
    features: [
      {
        title: "VIP Agro",
        list: [
          "60 000 zł",
          "45 000 zł",
          "5 000 zł",
          "60 zł/dzień",
          "15 000 zł",
          "3 000 zł",
          "20 000 zł",
          "95 000 zł",
          "145 000 zł",
          "25 000 zł",
          "9 000 zł",
          "145 000 zł",
          "195 000 zł",
          "45 000 zł",
          "10 000 zł",
          "3 000 zł",
          "5 000 zł",
          "100 zł",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=VIP Agro`;
          },
        },
      },
      {
        title: "VIP",
        list: [
          "60 000 zł",
          "43 000 zł",
          "5 000 zł",
          "60 zł/dzień",
          "14 000 zł",
          "2 800 zł",
          "20 000 zł",
          "90 000 zł",
          "140 000 zł",
          "25 000 zł",
          "9 000 zł",
          "-",
          "-",
          "45 000 zł",
          "10 000 zł",
          "3 000 zł",
          "5 000 zł",
          "75 zł",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=VIP`;
          },
        },
      },
      {
        title: "Pakiet 3",
        list: [
          "60 000 zł",
          "40 000 zł",
          "4 000 zł",
          "-",
          "13 000 zł",
          "2 600 zł",
          "20 000 zł",
          "80 000 zł",
          "130 000 zł",
          "-",
          "-",
          "-",
          "-",
          "40 000 zł",
          "-",
          "-",
          "-",
          "50 zł",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=x`;
          },
        },
      },
    ],
  },
};

const UbezpieczeniaNaZycie = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_1} />
    <Headline
      mt={5}
      mb={4}
      title="Zakres ubezpieczenia "
      subtitle="Tabela ze skumulowanymi kwotami świadczeń i najczęściej występującymi zdarzeniami"
    />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_3} />
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default UbezpieczeniaNaZycie;

import React from "react";
import { ReactComponent as XIcon } from "../../svg-icons/x.svg";
import "./Tag.scss";

const Tag = ({ t, label, isActive, onRemove, ...props }) => {
  return (
    <div
      className={`c-tag ${isActive ? "c-tag__active" : ""} ${
        props.onClick ? "c-tag__clickable" : ""
      }`}
      {...props}
    >
      #{label}
      {onRemove && (
        <span
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onRemove(e);
          }}
        >
          <XIcon />
        </span>
      )}
    </div>
  );
};

Tag.propTypes = {};
Tag.defaultProps = {};

export default Tag;

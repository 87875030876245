/* eslint-disable no-undef */
import * as actions from "./actions";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  isError: false,
};

export default function articlesSlider(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_SLIDER_ARTICLES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }
    case actions.GET_SLIDER_ARTICLES_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getSliderArticles = (token, report) => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  const endpoint = report
    ? "agroraport-articles/?lang_code=SET_LANG_CODE&limit=4"
    : "articles-newest/?lang_code=SET_LANG_CODE";
  return {
    type: "GET_SLIDER_ARTICLES",
    payload: {
      request: {
        url: `/api/v2/${endpoint}`,
        method: "GET",
        headers,
      },
    },
  };
};

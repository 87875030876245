import React from "react";
import { ButtonFix } from "..";
import "./ExpertCard.scss";
import { isIE } from "react-device-detect";

export const ExpertCard = ({
  img,
  position,
  name,
  description,
  id,
  onlyprofile,
}) => (
  <div className={`expert-card${onlyprofile ? " expert-card--short" : ""}`}>
    <div className="expert-card__top">
      <div
        className="expert-card__img"
        style={{ backgroundImage: `url(${img})` }}
      />
      <p className="expert-card__p expert-card__p--bigger">{position}</p>
      <p className="expert-card__name">{name}</p>
      <p className="expert-card__p">
        {isIE
          ? description.slice(0, 150) + (description.length > 149 ? "..." : "")
          : description}
      </p>
    </div>
    <div
      className={`expert-card__links${
        onlyprofile ? " expert-card__links--full" : ""
      }`}
    >
      <ButtonFix
        buttoncolor="green"
        buttonsize="big"
        link={`/eksperci/profil/${id}`}
      >
        Profil
      </ButtonFix>
      {!onlyprofile && (
        <ButtonFix
          buttoncolor="yellow"
          buttonsize="big"
          link={`/eksperci/profil/${id}/kontakt`}
        >
          Kontakt
        </ButtonFix>
      )}
    </div>
  </div>
);

/* eslint-disable no-undef */
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
// import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { checkForRedirect } from "../../../../services/helpers/redirect";
import withLayout from "../../../../services/hoc/withLayout";
import Image from "../../../common/Image/Image";
import {
  Breadcrumbs,
  Footer,
  Metadata,
  NavigationBar,
} from "../../../common/index";
import "../StaticPage.scss";

const PrivacyPolicy = props => {
  const { i18n, t } = useTranslation("metaInfo");
  const redirect = checkForRedirect(
    props.history,
    i18n.language,
    "/polityka-prywatnosci",
    "/privacy-policy"
  );

  return (
    <>
      <NavigationBar search={false} />
      {redirect && <Redirect to={redirect} />}
      <Metadata
        name={t("metaInfo:privacyPolicyPage.title")}
        description={t("metaInfo:privacyPolicyPage.description")}
      />
      <Container>
        {i18n.language === "pl" ? (
          <Row className="static" style={{ marginBottom: "100px" }}>
            <Col xs={12}>
              <Breadcrumbs currentName="Polityka prywatności" />
              <h1 className="typography--h1" style={{ marginTop: "100px" }}>
                Polityka prywatności
              </h1>
              <p className="static__paragraph static__paragraph--bigger">
                POLITYKA PRYWATNOŚCI I WYKORZYSTYWANIA PLIKÓW COOKIES W
                PLATFORMIE INTERNETOWEJ Agronomist.pl należącej do Banku BNP
                Paribas S.A.
              </p>
              <br />
              <p className="static__paragraph static__paragraph--big">
                <strong>Administrowanie danymi osobowymi</strong> <br />
                Dane osobowe przekazywane przez użytkowników Serwisu
                Internetowego BNP Paribas Bank Polska S.A., zwanego dalej
                „Serwisem”, za pomocą formularzy elektronicznych są przez BNP
                Paribas Bank Polska S.A. z siedzibą w Warszawie, ul. Kasprzaka 2
                (zwany dalej „Bankiem”) przechowywane na zasadach określonych w
                prawie, w szczególności w rozporządzeniu Parlamentu
                Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                sprawie ochrony osób fizycznych w związku z przetwarzaniem
                danych osobowych i w sprawie swobodnego przepływu takich danych
                oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
                ochronie danych) (Dz.Urz. UE L 119 z 04.05.2016).
                <br />
                <br />
                <strong>Użytkownik</strong> – Każda osoba fizyczna korzystająca
                z Platformy.
              </p>
              <br />
              <br />
              <p className="static__paragraph static__paragraph--big">
                <strong>Administrowanie danymi osobowymi</strong> <br />
                Dane osobowe przekazywane przez użytkowników Serwisu
                Internetowego BNP Paribas Bank Polska S.A., zwanego dalej
                „Serwisem”, za pomocą formularzy elektronicznych są przez BNP
                Paribas Bank Polska S.A. z siedzibą w Warszawie, ul. Kasprzaka 2
                (zwany dalej „Bankiem”) przechowywane na zasadach określonych w
                prawie, w szczególności w rozporządzeniu Parlamentu
                Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
                sprawie ochrony osób fizycznych w związku z przetwarzaniem
                danych osobowych i w sprawie swobodnego przepływu takich danych
                oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
                ochronie danych) (Dz.Urz. UE L 119 z 04.05.2016).
                <br />
                <br />
                Bank zbiera wyłącznie dane użytkowników Serwisu, które posłużą
                do świadczenia wybranej przez użytkowników Serwisu usługi,
                udzielenia odpowiedzi na skierowane do Banku wystąpienie, w
                celach marketingowych związanych z reklamą produktów i usług
                bankowych, przekazania informacji na temat oferty Banku oraz
                archiwalnym i statystycznym. Dane podawane przez użytkowników
                Serwisu w czasie wypełniania formularza online chronione są za
                pomocą protokołu SSL (Secure Socket Layer).
                <br /> <br />
                <strong>Ujawnianie danych osobowych</strong> <br />
                Zebrane przez Bank dane osobowe użytkowników Serwisu mogą być
                przekazane wyłącznie podmiotom upoważnionym przez przepisy prawa
                oraz podmiotom na rzecz, których użytkownik Serwisu udzielił
                zgodę na przetwarzanie swoich danych osobowych.
                <br /> <br />
                Zebrane dane osobowe nie zostaną ujawnione żadnym innym osobom
                ani podmiotom, chyba że będzie to wymagane przepisami prawa lub
                zostanie zawarta stosowna umowa gwarantująca ochronę praw i
                prywatności.
                <br /> <br />
                <strong>Cookies - co to jest? </strong> <br />
                Są to małe pliki tekstowe pochodzące z witryny www zapamiętywane
                przez przeglądarkę internetową użytkownika serwisu. Pliki cookie
                dzielą się na tymczasowe - pamiętane do czasu zamknięcia
                przeglądarki i cookie z określonym terminem wygaśnięcia, które
                są zapisywane przez przeglądarkę na dłużej.
                <br /> <br />
                Samo wysłanie cookie do przeglądarki w żaden sposób nie może
                naruszać prywatności użytkownika. Informacje zawarte w cookie
                wykorzystywane mogą być jedynie przez stronę internetową, z
                której zostały wysłane. Wyłączenie obsługi cookie w
                przeglądarce, z której korzystasz może spowodować utrudnienia
                lub brak działania niektórych funkcji serwisu.
                <br /> <br />
                <br />
                <strong>
                  Za pomocą technologii cookie zbieramy jedynie anonimowe dane
                  statystyczne o użytkownikach służące do poprawy wygody
                  korzystania z serwisu oraz minimalizacji uciążliwości reklam.
                  Nie zbieramy żadnych danych identyfikujących użytkowników jako
                  osoby lub klientów BNP Paribas Bank Polska S.A..
                </strong>
                <br /> <br />
                Niniejsza witryna internetowa korzysta z Google Analytics,
                usługi analizy oglądalności stron internetowych udostępnianej
                przez Google, Inc. (“Google”). Google Analytics używa “cookies”,
                czyli plików tekstowych umieszczanych na komputerze użytkownika
                w celu umożliwienia witrynie przeanalizowania sposobu, w jaki
                użytkownicy z niej korzystają. Informacje generowane przez
                cookie na temat korzystania z witryny przez użytkownika
                (włącznie z jego adresem IP) będą przekazywane spółce Google i
                przechowywane przez nią na serwerach w Stanach Zjednoczonych.
                Google będzie korzystała z tych informacji w celu oceny
                korzystania z witryny przez użytkownika, tworzenia raportów
                dotyczących ruchu na stronach dla operatorów witryn oraz
                świadczenia innych usług związanych z ruchem na witrynach
                internetowych i korzystaniem z internetu. Google może również
                przekazywać te informacje osobom trzecim, jeżeli będzie
                zobowiązana to uczynić na podstawie przepisów prawa lub w
                przypadku, gdy osoby te przetwarzają takie informacje w imieniu
                Google. Google nie będzie łączyła adresu IP użytkownika z
                żadnymi innymi danymi będącymi w jej posiadaniu. Użytkownik może
                zrezygnować z cookies wybierając odpowiednie ustawienia na
                przeglądarce, jednak należy pamiętać, że w takim przypadku
                korzystanie z wszystkich funkcji witryny może okazać się
                niemożliwe. Korzystając z niniejszej witryny internetowej
                użytkownik wyraża zgodę na przetwarzanie przez Google
                dotyczących go danych w sposób i w celach określonych powyżej.
                <br /> <br />
                Dostawcy zewnętrzni w tym Google mogą wyświetlać reklamy zlecane
                przez&nbsp;BNP Paribas Bank Polska S.A. w witrynach
                internetowych udostępniających swoją powierzchnię reklamową.
                Dostawcy ci, w tym Google, mogą używać plików cookie do
                wyświetlania reklam w oparciu o poprzednie odwiedziny
                użytkownika na stronach www.bnpparibas.pl i stronach z nią
                powiązanych. Użytkownik może zrezygnować z wykorzystywania
                plików cookie Google i wyłączyć je na stronie{" "}
                <a
                  href="https://www.google.pl/privacy/ads/"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  opcji reklam Google
                  <span className="sr-only"> Otwiera się w nowym oknie.</span>
                </a>
                .
                <br /> <br />
                Poniżej szczegółowo opisujemy cele pozyskiwania w platformie
                plików cookies oraz rodzaje i ich przeznaczenie.
              </p>
              <ol>
                <li>
                  Pliki cookies w serwisie stosowane są w celu:
                  <ul>
                    <li>
                      Optymalizacji korzystania ze stron internetowych Banku
                      przez użytkowników oraz dostosowania zawartości stron
                      internetowych do ich preferencji. Pliki te w szczególności
                      pozwalają rozpoznać urządzenie Użytkownika Serwisu i
                      odpowiednio wyświetlić stronę internetową, dostosowaną do
                      indywidualnych potrzeb Użytkownika;
                    </li>
                    <li>
                      tworzenia statystyk, które pomagają zrozumieć, w jaki
                      sposób Użytkownicy Serwisu korzystają ze stron
                      internetowych, co umożliwia ulepszanie struktury i
                      zawartości tych stron;
                    </li>
                    <li>
                      rozpoznawania źródła, z jakiego Użytkownik trafił na
                      stronę internetową Banku.
                    </li>
                  </ul>
                </li>
                <li>
                  W ramach serwisu mogą znajdować się odwołania do innych
                  serwisów internetowych, które stosują własne polityki
                  prywatności. Rekomendujemy zapoznanie się z każdą z takich
                  polityk, ponieważ Bank nie ponosi jakiejkolwiek
                  odpowiedzialności za zasady zachowania prywatności
                  obowiązujące w tych serwisach.
                </li>
                <li>
                  W serwisie wykorzystywane są następujące rodzaje plików
                  cookie:
                  <ol>
                    <li>
                      sesyjne (session cookies) – są plikami tymczasowymi,
                      przechowywanymi na urządzeniu końcowym Użytkownika do
                      czasu wylogowania, opuszczenia strony internetowej lub
                      wyłączenia oprogramowania (przeglądarki internetowej);
                    </li>
                    <li>
                      stałe (persistent cookies) – pliki cookies, które
                      przechowywane są na urządzeniu końcowym Użytkownika przez
                      czas określony w parametrach plików cookies lub do czasu
                      ich usunięcia przez Użytkownika.
                    </li>
                    <li>
                      cookies podmiotów zewnętrznych (third parties cookies) –
                      to pliki pochodzące np. z serwerów reklamowych, serwerów
                      firm i dostawców usług współpracujących z właścicielem
                      danej strony internetowej. Te pliki pozwalają dostosowywać
                      reklamy – dzięki którym korzystanie ze stron internetowych
                      może być bezpłatne – do preferencji i zwyczajów ich
                      użytkowników. Pozwalają również ocenić skuteczność działań
                      reklamowych (np. dzięki zliczaniu, ile osób kliknęło w
                      daną reklamę i przeszło na stronę internetową
                      reklamodawcy).
                    </li>
                    <li>
                      trackingowe - ciasteczka te przechowują informacje
                      niezbędne do ustalenia źródła pochodzenia ruchu
                      internetowego i łączenia z zachowaniem użytkownika na
                      stronie. Dane zbierana są anonimowo. Informacje gromadzone
                      w tych ciasteczkach służą do badania efektywności źródeł
                      ruchu internetowego w ramach prowadzonych przez Bank
                      kampanii internetowych i działań marketingowych.
                    </li>
                    <li>
                      analityczne - ciasteczka te instalowane są przez różnego
                      rodzaju systemy analityczne badające statystyczne i
                      ilościowe zjawiska na stronach Banku związane z przepływem
                      i zachowaniem użytkowników. Dane w nich zawarte są
                      gromadzone i przetwarzane są w sposób anonimowy i
                      wykorzystywane są przez systemy analityczne w celu
                      optymalizacji stron Banku. Czas trwania tych ciasteczek
                      jest ustalany sesyjne lub na inny okres.
                    </li>
                  </ol>
                </li>
                <li>
                  Pliki cookies na stronach Serwisu z reguły nie zbierają danych
                  osobowych.
                </li>
                <li>
                  W Platformie stosowane są następujące pliki cookie
                  <br /> <br />
                  <table className="table-responsive">
                    <colgroup>
                      {" "}
                      <col width="150" />
                      <col width="151" />
                      <col />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>
                          <p dir="ltr">Nazwa cookie</p>
                        </td>
                        <td>
                          <p dir="ltr">Rodzaj cookie</p>
                        </td>
                        <td>
                          <p dir="ltr">Przeznaczenie cookie</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">tdpeh</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku jest zapisywany unikalny
                            identyfikator kliknięcia w reklamę nadany przez
                            system mierzący pochodzenie źródła reklamy.
                            Ciasteczko ustawiane tylko w momencie przyjścia na
                            platformę z innego serwisu uczestniczącego w
                            kampanii reklamowej.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">affid</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku jest przekazywany identyfikator
                            strony internetowej w systemie mierzącym pochodzenie
                            źródła reklamy z którego dotarł użytkownik na
                            platformę.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">extraid</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są dodatkowe informacje
                            dotyczące sesji użytkownika.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">__utma</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są dane dotyczące
                            użytkownika i jego sesji wykorzystywane w narzędziu
                            Google Analytics.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">__utmb</p>
                        </td>
                        <td>
                          <p dir="ltr">Sesyjne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są dane dotyczące sesji
                            użytkownika wykorzystywane w narzędziu Google
                            Analytics.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">__utmz</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są dane dotyczące źródła
                            wizyty użytkownika wykorzystywane w narzędziu Google
                            Analytics.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">__utmv</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są dodatkowe informacje
                            dotyczące użytkownika wykorzystywane w narzędziu
                            Google Analytics.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">smuuid</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są anonimowe informacje
                            dotyczące użytkownika.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">uid</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są anonimowe informacje
                            dotyczące użytkownika.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">orgsrc</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są informacje dotyczące
                            wizyt użytkownika.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">lstsrc</p>
                        </td>
                        <td>
                          <p dir="ltr">Permanentne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są informacje dotyczące
                            wizyt użytkownika.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p dir="ltr">sid</p>
                        </td>
                        <td>
                          <p dir="ltr">Sesyjne</p>
                        </td>
                        <td>
                          <p dir="ltr">
                            W tym ciasteczku zapisywane są informacje dotyczące
                            bieżącej
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              </ol>
              <br />
              <p className="static__paragraph static__paragraph--big">
                <strong>
                  Zbieranie na komputerze użytkownika plików cookies można
                  wyłączyć albo ograniczyć wybierając właściwe ustawienia w
                  poszczególnych przeglądarkach poniżej pokazujemy jak wywołać
                  właściwe ekrany w czterech przykładowych przeglądarkach.
                </strong>
                <br /> <br />
              </p>
              <table className="table-responsive">
                <colgroup>
                  <col width="100" />
                  <col width="451" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <p dir="ltr">
                        <Image
                          // eslint-disable-next-line global-require
                          src={require("./../../../../assets/c_ie.jpeg")}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>Ustawienia -&gt; Opcje internetowe -&gt; Prywatność</td>
                    <td>
                      <a href="https://www.bnpparibas.pl/_fileserver/item/1502289">
                        Zobacz instrukcję
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">
                        <Image
                          // eslint-disable-next-line global-require
                          src={require("./../../../../assets/c_ff.jpeg")}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      Opcje -&gt; Pasek narzędzi nawigacyjnych -&gt; Prywatność
                    </td>
                    <td>
                      <a href="https://www.bnpparibas.pl/_fileserver/item/1502290">
                        Zobacz instrukcję
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">
                        <Image
                          // eslint-disable-next-line global-require
                          src={require("./../../../../assets/c_op.jpeg")}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      Ustawienia -&gt; Preferencję -&gt; Zaawansowane -&gt;
                      Ciasteczka
                    </td>
                    <td>
                      <a href="https://www.bnpparibas.pl/_fileserver/item/1502291">
                        Zobacz instrukcję
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">
                        <Image
                          // eslint-disable-next-line global-require
                          src={require("./../../../../assets/c_sa.jpeg")}
                          alt=""
                        />
                      </p>
                    </td>
                    <td>
                      Ustawienia (koło zębate) -&gt; Preferencje -&gt;
                      Prywatność
                    </td>
                    <td>
                      <a href="https://www.bnpparibas.pl/_fileserver/item/1502292">
                        {" "}
                        Zobacz instrukcję
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
            </Col>
          </Row>
        ) : (
          <Row className="static">
            <Col xs={12}>
              <Breadcrumbs currentName="Privacy policy" />
              <h1 className="typography--h1">Privacy policy</h1>
              <p className="static__paragraph static__paragraph--bigger">
                PRIVACY AND COOKIES POLICY OF Agronomist.pl, a website owned by
                BNP Paribas Bank Polska S.A.
              </p>
              <br />
              <p className="static__paragraph static__paragraph--big">
                <strong>Personal data administration </strong> <br />
                Personal data provided by users of the BNP Paribas Bank Polska
                S.A. Website, hereinafter referred to as the "Website", via
                electronic forms, is stored by BNP Paribas Bank Polska S.A.,
                with its registered office in Warsaw, ul. Kasprzaka 2
                (hereinafter "the Bank") in accordance with the terms and
                conditions specified in the law, in particular, Regulation (EU)
                2016/679 of the European Parliament and of the Council of 27
                April 2016 on the protection of individuals with regard to the
                processing of personal data and on the free movement of such
                data, and repealing Directive 95/46/EC (General Data Protection
                Regulation) (Official Journal of the European Union L 136 of
                31.5.2005). EU L 119 of 04.05.2016).
                <br />
                <br />
                The Bank only collects the data of Website users, which will be
                used to provide the service selected by the Website users, to
                respond to requests addressed to the Bank, for marketing
                purposes related to advertising of bank products and services,
                to provide information on the Bank's offer and for archiving and
                statistical purposes. The data provided by users of the Website
                while filling in the on-line form are protected by SSL (Secure
                Socket Layer).
                <br /> <br />
                <strong>Disclosure of personal data</strong> <br />
                The personal data of the Website users collected by the Bank may
                be transferred only to entities authorized by law and entities
                for the benefit of which the Website user has given their
                consent to process their personal data.
                <br />
                <br />
                The collected personal data will not be disclosed to any other
                persons or entities unless it is required by law or an
                appropriate agreement is concluded to ensure the protection of
                rights and privacy.
                <br /> <br />
                <strong>Cookies - what are they?</strong> <br />
                Those are small text files originating from a website and stored
                by the website user's web browser. Cookies are divided into
                session cookies - remembered until the browser is closed - and
                persistent cookies, which are stored by the browser for longer.
                <br />
                <br />
                Merely sending a cookie to the browser may not infringe the
                user's privacy in any way. The information contained in a cookie
                may only be used by the website from which it was sent.
                Disabling cookies in the browser you are using may cause
                difficulties or failure of some website functions.
                <br /> <br />
                <strong>
                  Using cookie technology, we only collect anonymous statistical
                  data about users in order to improve the user experience and
                  minimize the nuisance of advertisements. We do not collect any
                  data identifying users as persons or customers of BNP Paribas
                  Bank Polska S.A..
                </strong>{" "}
                <br /> <br />
                The website uses Google Analytis, i.e. a a web analysis service
                provided by Google.Inc ("Google") that generates statistics
                about visits to a website. Google Analytics uses "cookies",
                which are text files placed on your computer, to help the
                website analyze how users use the site. Any information about
                the use of the website by its visitors (including the viewer's
                IP address), generated by a cookie, is transmitted to Google and
                stored on US servers. Google uses this information to evaluate
                the use of the website by its visitors, compile reports on
                website traffic for website operators, and render further
                services related to website and Internet use Google may also
                transfer the above information to third parties where required
                by law or where such third parties process information on behalf
                of Google. Google will not correlate an IP address of a user
                with any other data stored by Google. A user may decline cookies
                by modifying the browser settings, but doing so may affect the
                ability to access all the website features. By using the
                website, a user hereby consents to having his/her data processed
                by Google in the manner and for the purpose described above.
                <br />
                <br />
                Third-party providers including Google may display
                advertisements commissioned by BNP Paribas Bank Polska S.A. on
                websites that provide their advertising space. These providers,
                including Google, may use cookies to display advertisements
                based on the user's previous visits to www.bnpparibas.pl and
                related websites. The user can opt out of the use of Google
                cookies and disable them on the{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  Google page
                  <span className="sr-only"> Otwiera się w nowym oknie.</span>
                </a>
                .
                <br /> <br />
                Below we describe in detail the purposes for which cookies are
                collected on the website, as well as the types of cookies and
                their purpose.
              </p>
              <ol>
                <li>
                  The Cookies on the website are used for:
                  <ul>
                    <li>
                      Optimizing the use of the Bank's websites by users and
                      adapting the content of websites to their preferences.
                      These files, in particular, allow to recognize the device
                      of the Website User and properly display the website,
                      tailored to the individual needs of the User;
                    </li>
                    <li>
                      Creating statistics that help to understand how Website
                      Users use websites, which allows for improving the
                      structure and content of these pages;
                    </li>
                    <li>
                      Recognizing the source from which the User came to the
                      Bank's website.
                    </li>
                  </ul>
                </li>
                <li>
                  The website may contain links to other websites which have
                  their own privacy policies. We recommend that you familiarise
                  yourself with any such policies, as the Bank does not accept
                  any responsibility for the privacy practices of these sites.
                </li>
                <li>
                  The following types of cookies are used on the website:
                  <ul>
                    <li>
                      session cookies - those are temporary files, stored on the
                      User's end device until logging out, leaving the website,
                      or switching off the software (web browser);
                    </li>
                    <li>
                      persistent cookies - cookies that are stored on the User's
                      end device for the time specified in the parameters of
                      cookies or until they are deleted by the User.
                    </li>
                    <li>
                      third party cookies - those are files from, for example,
                      advertising servers, company servers, and service
                      providers cooperating with the owner of a given website.
                      Such cookies allow for tailoring advertising - which can
                      make the use of websites free of charge - to the
                      preferences and habits of their users. They also make it
                      possible to assess the effectiveness of advertising
                      activities (e.g. by counting how many people clicked on a
                      given advertisement and went to the advertiser's website).
                    </li>
                    <li>
                      tracking cookies - these cookies store information
                      necessary to determine the source of internet traffic and
                      link to user behaviour on the website.
                    </li>
                  </ul>
                  <p>
                    <br />
                    Data is collected anonymously. The information collected in
                    these cookies is used to test the effectiveness of internet
                    traffic sources as part of the Bank's on-line campaigns and
                    marketing activities. <br />
                  </p>
                  <ul>
                    <li>
                      analytical - these cookies are installed by various
                      analytical systems that study statistical and quantitative
                      phenomena on the Bank's websites related to the flow and
                      behaviour of users. The data contained in them is
                      collected and processed anonymously and is used by
                      analytical systems to optimize the Bank's websites. The
                      duration of these cookies is set for a session or for
                      another period.
                    </li>
                  </ul>
                </li>
                <li>
                  Cookies on the Website generally do not collect personal data.
                </li>
              </ol>
              <br />
              <p>
                <strong>The website uses the following cookies:</strong>
              </p>
              <table className="table-responsive">
                <colgroup>
                  {" "}
                  <col width="100" />
                  <col width="121" />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <p dir="ltr">Cookie name</p>
                    </td>
                    <td>
                      <p dir="ltr">Cookie type</p>
                    </td>
                    <td>
                      <p dir="ltr">Cookie purpose</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">tdpeh</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores a unique identifier of an ad click
                        allocated by a system that measures the source of the
                        advertising. The cookie is set up only when visiting the
                        Bank’s website from another website participating in the
                        advertising campaign.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">affid</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie includes a website identifier in the system
                        that measures the source of advertising that routed the
                        user to the Bank’s website.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">extraid</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores additional information regarding the
                        user’s session.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">__utma</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores data about the user and the user’s
                        session used for Google Analytics.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">__utmb</p>
                    </td>
                    <td>
                      <p dir="ltr">Sesyjne</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores data about the user’s session used
                        for Google Analytics.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">__utmz</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores data about the source of the user’s
                        visit used for Google Analytics.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">__utmv</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores additional information about the user
                        used for Google Analytics.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">smuuid</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores anonymous information concerning the
                        user.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">uid</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores anonymous information concerning the
                        user.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">orgsrc</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores information concerning the user’s
                        visits.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">lstsrc</p>
                    </td>
                    <td>
                      <p dir="ltr">Persistent</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores information concerning the user’s
                        visits.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">sid</p>
                    </td>
                    <td>
                      <p dir="ltr">Sesyjne</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        This cookie stores information concerning the current
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">smsession</p>
                    </td>
                    <td>
                      <p dir="ltr">Sesyjne</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        A cookie that saves the number of user sessions on side
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">_gcl_au</p>
                    </td>
                    <td>
                      <p dir="ltr">3 months</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Additional cookies are stored in this cookie user
                        information used in Google Analytics tool.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">_ga</p>
                    </td>
                    <td>
                      <p dir="ltr">2 years</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Additional cookies are stored in this cookie user
                        information used in Google Analytics tool.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">_fbp</p>
                    </td>
                    <td>
                      <p dir="ltr">3 months</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Facebook uses cookies to user profiling and how most
                        closely match your ad to user profile.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">_cfduid</p>
                    </td>
                    <td>
                      <p dir="ltr">1 year</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Identifying harmful visitors, filtering traffic,
                        reducing the chance block valid users i provision of
                        personalized services
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">dctraffic</p>
                    </td>
                    <td>
                      <p dir="ltr">1 month</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        The cookie stores information about the source of the
                        traffic from what the user came in
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">dcid</p>
                    </td>
                    <td>
                      <p dir="ltr">1 month</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        The cookie stores information about the source of the
                        traffic from what the user came in
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">dcmedium</p>
                    </td>
                    <td>
                      <p dir="ltr">1 month</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        The cookie stores information about the source of the
                        traffic from what the user came in
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">fr</p>
                    </td>
                    <td>
                      <p dir="ltr">3 months</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Facebook uses cookies to user profiling and how most
                        closely match your ad to user profile.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">smsessioncount</p>
                    </td>
                    <td>
                      <p dir="ltr">1 month</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        A cookie that saves the number of user sessions on side
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">dcsource</p>
                    </td>
                    <td>
                      <p dir="ltr">1 month</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        The cookie stores information about the source of the
                        traffic from what the user came in
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">_dc_gtm_UA-62673449-12</p>
                    </td>
                    <td>
                      <p dir="ltr">Session</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Google Analytics is a free tool for analytics to help us
                        understand how users use our website website. Google
                        Analytics collects information anonymously, without
                        identifying individuals users. Google Analytics uses its
                        own a set of cookies for tracking interactions visitors.
                        These files are used for storing information such as:
                        visit time, where the traffic comes from and which
                        subpages are the users are browsing. Thanks to this
                        information, we are in able to continuously improve our
                        website, so that it best meets the requirements and
                        tastes our clients
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p dir="ltr">IDE</p>
                    </td>
                    <td>
                      <p dir="ltr">1 year 24 days</p>
                    </td>
                    <td>
                      <p dir="ltr">
                        Google uses these cookies to be able to personalize
                        advertisements on your websites (e.g. in Google search
                        engine) - especially when the user is not logged into
                        his account Google. Cookies are also used for displaying
                        advertisements outside of Google pages. Their the main
                        advertising cookie is named &quot;IDE&quot; i is saved
                        in browsers under the domain doubleclick.net. Cookie
                        &quot;AID&quot;, &quot;DSID&quot; and &quot;TAID&quot;,
                        thanks to which there is a possibility of linking user
                        activities on different devices, if you have previously
                        logged into a Google account on one of them. This allows
                        the advertising to be coordinated displayed on various
                        devices and running measuring events leading to
                        conversion.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="static__paragraph static__paragraph--big">
                <br />
                <strong>
                  Cookies may be disabled or restricted on the user’s computer
                  by selecting the relevant settings in specific internet
                  browsers.
                </strong>
                <br /> <br />
              </p>
              <br />
              <br />
            </Col>
          </Row>
        )}
      </Container>

      <Footer windowWidth={0} />
    </>
  );
};

export default withLayout(PrivacyPolicy);

/* eslint-disable no-use-before-define */
import { Object } from "core-js";
import {
  allPass,
  chain,
  complement,
  fromPairs,
  isEmpty,
  toPairs,
  pipe,
} from "ramda";

export function scrollToLastElement(refs, offset = 0, timeout = 100) {
  setTimeout(() => {
    if (refs.current) {
      const filtredRefs = refs.current.filter(ref => ref !== null);
      if (filtredRefs.length) {
        const lastIndex = filtredRefs.length - 1;
        const lastElement = filtredRefs[lastIndex];
        const y =
          lastElement.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, timeout);
}
// TODO: FIX ME!
function bo(obj) {
  return Object.keys(obj)
    .filter(key => ["id", "label"].indexOf(key) === -1)
    .reduce(
      (prev, curr) => {
        const value = obj[curr];
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          typeof value === "boolean"
        ) {
          if (value === "empty") {
            return {
              allFields: prev.allFields,
              completedFields: prev.completedFields,
            };
          }

          return {
            allFields: prev.allFields + 1,
            completedFields: !isEmpty(value)
              ? prev.completedFields + 1
              : prev.completedFields,
          };
        }
        // eslint-disable-next-line no-use-before-define
        const progress = fo(value);

        return {
          allFields: prev.allFields + progress.allFields,
          completedFields: prev.completedFields + progress.completedFields,
        };
      },
      {
        allFields: 0,
        completedFields: 0,
      }
    );
}

function fo(value) {
  if (Array.isArray(value)) {
    return value.reduce(
      (prev, curr) => {
        if (curr === null) {
          return {
            allFields: prev.allFields + 1,
            completedFields: prev.completedFields,
          };
        }

        if (typeof curr === "boolean") {
          return {
            allFields: prev.allFields + 1,
            completedFields: prev.completedFields + 1,
          };
        }

        const progress = bo(curr);
        return {
          allFields: prev.allFields + progress.allFields,
          completedFields: prev.completedFields + progress.completedFields,
        };
      },
      {
        allFields: 0,
        completedFields: 0,
      }
    );
  }

  if (value === null) {
    return {
      allFields: 1,
      completedFields: 0,
    };
  }

  if (value === undefined) {
    return {
      allFields: 0,
      completedFields: 0,
    };
  }

  return bo(value);
}

export function calculateProgress(fields = {}) {
  return Object.keys(fields).reduce(
    (prev, curr) => {
      const value = fields[curr];

      if (value === null) {
        return {
          allFields: prev.allFields + 1,
          completedFields: prev.completedFields,
        };
      }

      if (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean"
      ) {
        return {
          allFields: prev.allFields + 1,
          completedFields: !isEmpty(value)
            ? prev.completedFields + 1
            : prev.completedFields,
        };
      }
      const progress = fo(value);

      return {
        allFields: prev.allFields + progress.allFields,
        completedFields: prev.completedFields + progress.completedFields,
      };
    },
    {
      allFields: 0,
      completedFields: 0,
    }
  );
}

export function calculateGlobalProgress(state) {
  return Object.keys(state).reduce((prev, curr) => {
    const progress = calculateProgress(state[curr]);
    const complete = (progress.completedFields * 100) / progress.allFields;
    return {
      ...prev,
      [curr]: {
        allFields: progress.allFields,
        completedFields: progress.completedFields,
        complete: Number.isNaN(complete) ? 0 : Math.floor(complete),
      },
    };
  }, {});
}

export function calculateGlobalReport(state) {
  const keys = Object.keys(state);

  if (!keys.length) {
    return {
      allSteps: 0,
      completedSteps: 0,
      complete: 0,
    };
  }
  const complete = Object.keys(state).reduce((prev, curr) => {
    if (state[curr] === null) {
      return prev;
    }
    return isEmpty(state[curr]) ? prev : prev + 1;
  }, 0);

  return {
    allSteps: keys.length,
    completedSteps: complete,
    complete: Math.floor((complete * 100) / keys.length),
  };
}

export function nFormatter(n, local = "pl") {
  // eslint-disable-next-line no-compare-neg-zero
  if (n === -0) {
    return 0;
  }
  const formatter = Intl.NumberFormat(local === "gb" ? "en" : local, {
    notation: "compact",
  });
  return formatter.format(n);
}

export function dotFormatter(value) {
  if (typeof value === "string") {
    return Number(value.replace(/,/, "."));
  }

  return Number(value);
}

export function isPlainObject(obj) {
  return (
    obj !== null &&
    typeof obj === "object" &&
    (!obj.constructor || obj.constructor === Object) &&
    Object.prototype.toString.call(obj) === "[object Object]"
  );
}

export function flattenProps(obj) {
  const jamming = allPass([isPlainObject, complement(isEmpty)]);
  const jam = ([k, v]) => (jamming(v) ? go(v) : [[k, v]]);
  const go = pipe(
    toPairs,
    chain(jam)
  );
  return fromPairs(go(obj));
}

export function parseError(error) {
  return Object.keys(error).reduce((prev, crr) => {
    return {
      ...prev,
      [crr]: error[crr].join("."),
    };
  }, {});
}

import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { dotFormatter } from "../../../utils/cftUtils";
import { CftFormField, CftError, CftFormErrorFocus } from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import CattleHerdFields from "./CattleHerdFields";
import { mapCattleGrazingHerd } from "../CattleGrazingDPForm/cattleGrazingDP.utils";
import { dairyProductionAPI } from "../../../utils/cftApi";
import { mapValues } from "./characteristicsDP.utils";

function CharacteristicsDPForm() {
  const { values } = useFormikContext();
  const { dairyProduction } = useCft();

  const isTouched = dairyProduction.characteristics.cattleHerd.some(
    el => el !== null
  );

  const hasChanged =
    JSON.stringify(values.cattleHerd) ===
    JSON.stringify(dairyProduction.characteristics.cattleHerd);

  return (
    <Form className="cft-form narrow-labels">
      {values.cattleHerd.map((value, key) => (
        <CattleHerdFields
          key={key}
          id={key}
          value={value}
          isTouched={isTouched && hasChanged}
        />
      ))}
      <CftFormField name="quantityError">
        {({ meta }) => (
          <>
            <CftError
              isActive={meta.touched && meta.error}
              message={meta.error}
              textAlign="center"
            />
          </>
        )}
      </CftFormField>
      <CftFormErrorFocus />
    </Form>
  );
}

export default CharacteristicsDPForm;

export function CharacteristicsDPFormContext({ children }) {
  const history = useHistory();
  const { t } = useTranslation(["cft"]);

  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();

  const validation = Yup.number()
    .required(
      t(
        "cft:validation:required",
        t("cft:validation.required", "Wpisz wartość")
      )
    )
    .typeError(
      t(
        "cft:validation:numberTypeError",
        t("cft:validation.numberTypeError", "Wpisz poprawną wartośc liczbową")
      )
    )
    .min(
      0,
      t(
        "numberMinError",
        t("cft:validation.numberMinError", "Wartość minimalna to 0")
      )
    );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...dairyProduction.characteristics,
        quantityError: 0,
      }}
      validationSchema={Yup.object().shape({
        cattleHerd: Yup.array().of(
          Yup.lazy(value => {
            if (value === null) {
              return Yup.boolean()
                .nullable()
                .required(
                  t(
                    "cft:validation:requiredOption",
                    t("cft:validation.requiredOption", "Wybierz opcję")
                  )
                );
            }

            if (value === false) {
              return Yup.mixed().notRequired();
            }

            return Yup.object().shape({
              animals: validation,
              liveWeight: Yup.number()
                .transform((_, v) => dotFormatter(v))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              soldAnimals: validation,
              soldWeight: Yup.number()
                .transform((_, v) => dotFormatter(v))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              purchasedAnimals: validation,
              purchasedWeight: Yup.number()
                .transform((_, v) => dotFormatter(v))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
            });
          })
        ),
        quantityError: Yup.number().when("cattleHerd", {
          is: val => {
            const selectedHerd = val.filter(
              herd => herd !== false && herd !== null
            );
            return selectedHerd.length === 0;
          },
          then: Yup.number().min(1, "Należy wybrać przynajmniej jedno stado."),
          otherwise: Yup.number().notRequired(),
        }),
      })}
      onSubmit={values => {
        dairyProductionAPI
          .characteristicsValidation(mapValues(values))
          .then(({ data }) => {
            saveDairyProductionReport(data);
            saveDairyProduction({
              characteristics: values,
              cattleGrazing: {
                ...dairyProduction.cattleGrazing,
                herd: mapCattleGrazingHerd(
                  values.cattleHerd,
                  dairyProduction.cattleGrazing.herd
                ),
              },
            });
            history.push(routes.DAIRY_PRODUCTION.CATTLE_GRAZING);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import useSWR from "swr";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import API from "../../../services/apiHelpers";

function useBanners({ slug }) {
  const { i18n } = useTranslation();
  const url = `/api/v1/banners/?search=${slug}`;

  const { data } = useSWR([slug, url], () =>
    API().get(url, {
      params: {
        lang_code: Cookies.get("user_lang")
          ? Cookies.get("user_lang").toUpperCase()
          : i18n.language.toUpperCase(),
      },
    })
  );

  const parseBannerData = data => {
    if (!data) {
      return null;
    }

    const {
      data: { results },
    } = data;

    if (results.length === 0) {
      return null;
    }

    return results[0].banner;
  };

  return {
    banner: parseBannerData(data),
  };
}

export default useBanners;

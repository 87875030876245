import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftUnitField,
  CftQuestionGroup,
} from "../../../components";
import useCftOptions from "../../../utils/useCftOptions";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { beddingType } from "./manure.consts";

export const initBedding = () => ({
  id: Date.now(),
  type: "",
  amount: {
    unit: "",
    value: "",
  },
});

function Bedding({ id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:manure.form.1", "Typ ściółki")} />
        <CftFormField name={`bedding[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {beddingType.map((b, key) => (
                  <CftBox
                    key={key}
                    isActive={value === b.value}
                    onClick={() => helpers.setValue(b.value)}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    {b.icon}
                    <p className="cft-box__paragraph">{b[language]}</p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:manure.form.2", "Ilość")} />
        <CftUnitField
          name={`bedding[${id}].amount`}
          units={getUnits(["t", "kg"])}
        />
      </CftFormControl>
    </>
  );
}

function BeddingType() {
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  const getError = (value, key) => {
    if (!value) {
      return false;
    }
    return !!value[key];
  };

  return (
    <>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:manure.form.3", "Ściółka")}
          <small>
            {t(
              "cft:manure.form.4",
              "Wybierz rodzaj(e) ściółki stosowane do hodowli."
            )}
          </small>
        </h3>
      </CftFormControl>
      <CftFormField name="bedding">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t("cft:manure.form.5", "Składnik ściółki")} ${key +
                  1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={
                  key !== 0
                    ? () => {
                        helpers.setValue(value.filter(f => f.id !== id));
                        scrollToLastElement(groupRefs, 130);
                      }
                    : undefined
                }
                hasError={meta.touched && getError(meta.error, key)}
              >
                <Bedding id={key} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initBedding()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t("cft:manure.form.5", "Dodaj kolejny składnik ściólki")
                    : t("cft:manure.form.6", "Dodaj składnik ściólki")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default BeddingType;

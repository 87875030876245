import { format } from "date-fns";

export const mapValues = values => {
  return {
    irigation_start_date: format(values.sowingDate, "YYYY-MM-DD"),
    irigation_end_date: format(values.harvestingDate, "YYYY-MM-DD"),
    method_id: Number(values.method),
    water_source_id: values.waterSource ? Number(values.waterSource) : "1",
    irrigation_events: values.weeks.map(week => ({
      water_added: {
        value: Number(week.waterAmount),
        unit_id: "15",
      },
      water_source_id: Number(week.waterSource),
      allocation: Number(week.wateredArea),
    })),
  };
};

export const mapWaterSource = (id, translations) => {
  switch (id) {
    case "1":
      return translations.natural;
    case "2":
      return translations.tank;
    case "3":
      return translations.river;
    case "4":
      return translations.mere;
    case "5":
      return translations.well;
    case "6":
      return translations.mainSpring;
    default:
      return "";
  }
};

import { ErrorMessage, useFormikContext } from "formik";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import classNames from "classnames";
import { Button, Checkbox } from "../../components/common";
import {
  RegagriBox,
  RegagriFormControl,
  RegagriLabel,
  RegagriTextField,
  RegagriTooltip,
} from "../components";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as ChevronRight } from "../../components/averagePrices/PriceReport/chevronRight.svg";

export const getProperTooltip = (t, id) => {
  switch (id) {
    case 7:
      return t(
        "regagri:cultivationDetails.form.report.emissions.soilDisturbance.tooltip1"
      );
    case 8:
      return t(
        "regagri:cultivationDetails.form.report.emissions.soilDisturbance.tooltip2"
      );
    case 9:
      return t(
        "regagri:cultivationDetails.form.report.emissions.livingRoot.tooltip1"
      );
    case 16:
      return t(
        "regagri:cultivationDetails.form.report.emissions.livingRoot.tooltip2"
      );
    case 11:
      return t(
        "regagri:cultivationDetails.form.report.emissions.carbonInpot.tooltip1"
      );
    case 3:
      return t(
        "regagri:cultivationDetails.form.report.emissions.carbonInpot.tooltip2"
      );
    case 23:
      return t(
        "regagri:cultivationDetails.form.report.emissions.afforestation.tooltip"
      );
    default:
      return "";
  }
};

const RegagriPractices = ({ practicesData }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["regagri"]);
  const [selectedPractices, setSelectedPractices] = useState([]);
  const [modalData, setModalData] = useState({
    isVisible: false,
    parentId: "",
    childId: "",
  });
  const { values, setFieldValue, errors } = useFormikContext();

  const handleSelectBox = practice => () => {
    if (!selectedPractices.find(item => item.id === practice.id)) {
      setSelectedPractices(() => {
        return [practice];
      });
    } else {
      setSelectedPractices(prevState => {
        const newPractices = [...prevState].filter(el => el.id !== practice.id);
        return [...newPractices];
      });
    }
  };

  const handleCheckboxOnChange = (practice, child) => () => {
    let newPractices = [...values.practices];
    const boxValues = newPractices.find(f => f.name === practice.name);
    [...selectedPractices].forEach(el => {
      if (!boxValues) {
        newPractices.push({
          name: el.name,
          id: practice.id,
          practices: [
            ...(boxValues ? boxValues.practices : []),
            ...[...el.practices].filter(f => f.id === child.id),
          ],
        });
      } else {
        newPractices = newPractices.map(p => {
          if (p.name === practice.name) {
            let newChildren = [...p.practices];
            if (!newChildren.find(c => c.id === child.id)) {
              newChildren.push({
                ...el.practices.find(c => c.id === child.id),
              });
            } else {
              newChildren = newChildren.filter(c => c.id !== child.id);
            }
            return {
              ...p,
              practices: newChildren,
            };
          }
          return p;
        });
      }
    });
    setFieldValue("practices", newPractices);
  };

  const handleToggleModal = isVisible => {
    setModalData(prevState => ({ ...prevState, isVisible }));
  };

  const handleOpenModalWithData = (parentName, childId) => () => {
    setModalData(prevState => ({
      ...prevState,
      isVisible: true,
      parentName,
      childId,
    }));
    setFieldValue("modalInput", "");
  };

  const handleSaveModal = () => {
    const isChecboxExist =
      values.practices.length > 0 &&
      values.practices.find(f => f.name === modalData.parentName) &&
      values.practices
        .find(f => f.name === modalData.parentName)
        .practices.find(c => c.id === modalData.childId);

    if (isChecboxExist) {
      setFieldValue(
        "practices",
        values.practices.map(el => ({
          ...el,
          practices: el.practices.map(c => ({
            ...c,
            ...(c.id === modalData.childId
              ? { value: Number(values.modalInput) }
              : {}),
          })),
        }))
      );
    }

    setSelectedPractices(prevState => {
      const newSelectedPractices = [...prevState].map(practice => ({
        ...practice,
        ...(practice.name === modalData.parentName
          ? {
              ...practice,
              practices: [...practice.practices].map(child => ({
                ...child,
                ...(child.id === modalData.childId
                  ? { value: Number(values.modalInput) }
                  : {}),
              })),
            }
          : {}),
      }));
      return newSelectedPractices;
    });
  };

  const checkboxes = name => (
    <div className="checkboxes">
      {selectedPractices.map(practice => {
        const formPractice = values.practices.find(
          f => f.name === practice.name
        );
        const childrens = practice.practices.map(child => (
          <Checkbox
            key={`${practice.id}_${child.id}_checkbox`}
            label={
              <div className="checkbox text">
                {language === "pl"
                  ? t(
                      `cultivationDetails.form.practices.names.${
                        practice.id
                      }.practices.${child.id}.name`
                    )
                  : child.name}
                {child.value !== null && (
                  <span>
                    <strong>
                      {(formPractice &&
                        formPractice.practices.find(c => c.id === child.id) &&
                        formPractice.practices.find(c => c.id === child.id)
                          .value) ||
                        child.value}
                      %
                    </strong>
                    <Button
                      buttonsize="small"
                      buttoncolor="underline"
                      onClick={handleOpenModalWithData(practice.name, child.id)}
                    >
                      {t("regagri:cultivationDetails.form.practices.button")}
                    </Button>
                    {getProperTooltip(t, child.id) && (
                      <RegagriTooltip content={getProperTooltip(t, child.id)} />
                    )}
                  </span>
                )}
              </div>
            }
            checked={
              !!values.practices.find(
                f =>
                  practice.name === f.name &&
                  f.practices.some(s => s.id === child.id)
              )
            }
            onChange={handleCheckboxOnChange(practice, child)}
          />
        ));
        if ((name && name === practice.name) || !name) {
          return childrens;
        }
        return <></>;
      })}
    </div>
  );

  return (
    <div className="regagri-practices" id="practices">
      <RegagriFormControl variant="medium">
        <RegagriLabel
          className="headline"
          label={t("regagri:cultivationDetails.form.practices.title")}
        />
        <p className="disclaimer">
          {t("regagri:cultivationDetails.form.practices.disclaimer")}
        </p>
        {/* <h4>{t("regagri:cultivationDetails.form.practices.subtitle")}</h4> */}
      </RegagriFormControl>
      <RegagriFormControl variant="full">
        <div className="types">
          {practicesData &&
            practicesData
              .map((e, i) => ({
                ...e,
                id: i + 1,
                practices: e.practices.map(c => ({ ...c, value: 0 })),
              }))
              .map(practice => {
                const isActive = !!selectedPractices.find(
                  item => item.id === practice.id
                );
                return (
                  <>
                    <RegagriBox
                      key={`${practice.id}_box`}
                      onClick={handleSelectBox(practice)}
                      isActive={isActive}
                    >
                      <p>
                        {language === "pl"
                          ? t(
                              `cultivationDetails.form.practices.names.${
                                practice.id
                              }.name`
                            )
                          : practice.name}
                      </p>
                      {isMobile && (
                        <div
                          className={classNames("arrow", {
                            "arrow--is-open": isActive,
                          })}
                        >
                          <ChevronRight />
                        </div>
                      )}
                    </RegagriBox>
                    {isMobile && checkboxes(practice.name)}
                  </>
                );
              })}
        </div>
        {!isMobile && checkboxes()}
        <div className="form-error">
          <ErrorMessage name="practices" />
        </div>
      </RegagriFormControl>
      <Modal
        isOpen={modalData.isVisible}
        onRequestClose={() => handleToggleModal(false)}
        centered
        style={{
          overlay: {
            zIndex: "1000",
            backgroundColor: "rgba(0,0,0,0.6)",
            overflowY: "scroll",
          },
          content: {
            maxWidth: "970px",
            width: "auto",
            margin: "auto",
            borderRadius: 0,
            bottom: "unset",
            overflowX: "hidden",
            backgroundColor: "#f1f1f1",
          },
        }}
      >
        <div className="practices-modal-content">
          <RegagriFormControl variant="small" alignLeft>
            <h4>
              {t("regagri:cultivationDetails.form.practices.modal.title")}
            </h4>
            <RegagriTextField
              adornment="%"
              name="modalInput"
              placeholder={t(
                "regagri:cultivationDetails.form.practices.modal.placeholder"
              )}
            />
            <div className="save-button">
              <Button
                buttonsize="small"
                buttoncolor="green"
                onClick={() => {
                  if (!Object.keys(errors).includes("modalInput")) {
                    handleSaveModal();
                    handleToggleModal(false);
                  }
                }}
              >
                {t("regagri:cultivationDetails.form.practices.modal.button")}
              </Button>
            </div>
            <button
              type="button"
              className="tools-page__modal--close"
              onClick={() => {
                handleToggleModal(false);
                setFieldValue("modalInupt", 0);
              }}
            >
              <CloseIcon />
            </button>
          </RegagriFormControl>
        </div>
      </Modal>
    </div>
  );
};

export default RegagriPractices;

import React, { useState } from "react";
import { ContactForm, ContactFormSuccess } from "agronomist-ui-components";
import { useLocation } from "react-router";
import queryString from "query-string";

import { onContactSubmit } from "../helpers/utils";
import PageTemplate from "../helpers/PageTemplate";

const COPY = {
  title: "DOWIEDZ SIĘ WIĘCEJ O NASZEJ OFERCIE",
  subtitle:
    "Chcesz dowiedzieć się więcej o warunkach finansowania? Wypełnij formularz kontaktowy aby pracownik Banku BNP Paribas skontaktował się z Tobą.",
  provideList: [
    "Pomożemy dopełnić formalności",
    "Dobierzemy odpowiednie warunki finansowania",
  ],
  textBtn: "Zamów rozmowę z Agro Ekspertem",
  isExtended: true,
};

const Contact = () => {
  const [isSend, setSend] = useState(false);
  const { search } = useLocation();

  const { product_url, product_name } = queryString.parse(search);

  return (
    <PageTemplate>
      {isSend ? (
        <ContactFormSuccess link="/articles/jeden-kredyt-wiele-mozliwosci" />
      ) : (
        <ContactForm
          {...COPY}
          onSubmit={(values, formikHelpers) => {
            return onContactSubmit(
              { ...values, product_url, product_name },
              formikHelpers
            ).finally(() => {
              setSend(true);
            });
          }}
        />
      )}
    </PageTemplate>
  );
};

export default Contact;

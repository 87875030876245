import React, { useState } from "react";
import classnames from "classnames";
import { NavLink as Link } from "react-router-dom";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/chevron-down.svg";
import LanguageNavigation from "../LanguageNavigation";

import { useRoute } from "./Navigation.hooks";

import "./Navigation.scss";

function Navigation({ isMobile, onClose }) {
  const { routes } = useRoute();
  return (
    <nav
      className={classnames("navigation-menu", {
        "navigation-menu--mobile": isMobile,
      })}
    >
      <ul className="main-group">
        {routes.map((element, key) => (
          <NavigationElement
            isMobile={isMobile}
            key={key}
            element={element}
            onClose={onClose}
          />
        ))}
      </ul>
      {isMobile && <LanguageNavigation isMobile />}
    </nav>
  );
}

const NavigationElement = ({ element, isMobile, onClose }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenOnMobile = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <li
      onClick={() => handleOpenOnMobile()}
      className={`${isMobile && isOpen ? "is-open-for-mobile" : ""}`}
    >
      <header
        className={classnames({
          "with-children": !!element.children,
        })}
        role="presentation"
      >
        {element.path ? (
          <Link exact={element.exact} to={element.path}>
            {element.name}
          </Link>
        ) : (
          element.name
        )}
        {!!element.children && <ChevronDown />}
      </header>
      {!!element.children && (
        <ul className="sub-group">
          {element.children.map((subelement, k) => (
            <li key={k}>
              <header>
                {subelement.path ? (
                  subelement.isExternalLink ? (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.replace(
                          `${window.location.origin}${subelement.path}`
                        );
                      }}
                    >
                      {subelement.name}
                    </a>
                  ) : (
                    <Link
                      exact={subelement.exact}
                      to={subelement.path}
                      onClick={() => {
                        if (isMobile) {
                          onClose();
                        }
                      }}
                    >
                      {subelement.name}
                    </Link>
                  )
                ) : (
                  subelement.name
                )}
              </header>
              {!!subelement.children && (
                <ul className="child-group">
                  {subelement.children.map((child, k) => (
                    <li key={k}>
                      <Link exact={child.exact} to={child.path}>
                        {child.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default Navigation;

import React from "react";
import { Link } from "react-router-dom";
import agronomistLogo from "../../../assets/logo/agronomist_a.svg";
import appLogo from "../../../assets/img/report/logo.svg";
import Image from "../Image/Image";
import { ButtonFix } from "../index";

export const PollResponse = ({ voted, token, minimum, redirect, report }) => {
  if (voted && token && !minimum) {
    return (
      <div className="c-poll-info">
        <Image
          src={report ? appLogo : agronomistLogo}
          style={{ maxWidth: "270px" }}
          alt=""
        />
        <p>
          Dziękujemy za Twój udział w sondzie.
          <br />
          <br />
          Wyniki pojawią się po uzyskaniu
          <br />
          wystarczającej ilości głosów.
        </p>
      </div>
    );
  }
  if (voted && !token) {
    return (
      <div className="c-poll-info c-poll--notuser">
        <p>
          <strong>
            Tylko zalogowani użytkownicy mogą wziąć udział w sondzie
          </strong>
        </p>
        <ButtonFix
          link="/uzytkownik/login"
          buttoncolor="yellow"
          buttoncenter="center"
          buttonsize="higher"
          onClick={redirect}
        >
          Zaloguj się
        </ButtonFix>
        <p>
          <strong>
            Nie masz jeszcze konta?{" "}
            <Link to="/uzytkownik/rejestracja">Zarejestruj się</Link>
          </strong>
        </p>
      </div>
    );
  }
  return null;
};

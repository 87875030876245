import { combineReducers } from "redux";
import user from "./user/reducer";
import articlesSlider from "./articles/articlesSlider/reducer";
import articlesCategories from "./articles/articlesCategories/reducer";
import articlesTags from "./articles/articlesTags/reducer";
import articlesAll from "./articles/articlesAll/reducer";
import widget from "./articles/widget/reducer";
import report from "./articles/report/reducer";
import article from "./article/reducer";
import eventsAll from "./events/eventsAll/reducer";
import userEvents from "./events/userEvents/reducer";
import event from "./event/reducer";
import tools from "./tools/reducer";
import weather from "./weather/reducer";
import contact from "./contact/reducer";
import expertsAll from "./experts/expertsAll/reducer";
import expertsUtilities from "./experts/expertsUtilities/reducer";
import expertSingle from "./experts/expertSingle/reducer";
import photos from "./photos/reducer";
import ui from "./ui/reducer";
import properties from "./properties";

export default combineReducers({
  user,
  articles: combineReducers({
    articlesAll,
    articlesTags,
    articlesCategories,
    articlesSlider,
    report,
    widget,
  }),
  article,
  events: combineReducers({
    eventsAll,
  }),
  userEvents,
  event,
  tools,
  weather,
  contact,
  experts: combineReducers({
    expertsAll,
    expertsUtilities,
    expertSingle,
  }),
  photos,
  ui,
  properties: properties.reducer,
});

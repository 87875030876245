import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import properties from "../../../../../services/redux/properties";

import "./RealEstateCategories.scss";

function RealEstateCategories() {
  const propertyTypes = useSelector(properties.selectors.selectPropertyTypes);

  return (
    <div className="real-estate-categories b-section">
      <header>
        <h2>kategorie</h2>
        <hr />
      </header>
      <div className="real-estate-categories__property-types">
        {propertyTypes.map(prop => (
          <div key={prop.id} className="real-estate-categories__property-type">
            <div
              className="thumbnail"
              style={{ backgroundImage: `url("${prop.thumbnail}")` }}
            />
            <h6>{prop.name}</h6>
            <Link to={`/nieruchomosci/wyszukiwarka?property_type=${prop.id}`}>
              Zobacz
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RealEstateCategories;

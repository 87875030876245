export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const GET_EVENTS_RECENT = "GET_EVENTS_RECENT";
export const GET_EVENTS_RECENT_SUCCESS = "GET_EVENTS_RECENT_SUCCESS";
export const GET_EVENTS_RECENT_FAIL = "GET_EVENTS_RECENT_FAIL";

export const GET_EVENTS_RECENT_PREV = "GET_EVENTS_RECENT_PREV";
export const GET_EVENTS_RECENT_NEXT = "GET_EVENTS_RECENT_NEXT";

import PigsPage from "./Pigs.page";
import PigsHerdFeedPage from "./PigsHerdFeed.page";
import PigsHerdFeedsPage from "./PigsHerdFeeds.page";
import PigsEmissionsPage from "./PigsEmissions.page";
import PigsReportPage from "./PigsReport.page";

export default {
  Main: PigsPage,
  Coop: PigsHerdFeedPage,
  Coops: PigsHerdFeedsPage,
  Emission: PigsEmissionsPage,
  Report: PigsReportPage,
};

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    irigation: values.events.map(
      ({
        depthSource,
        distanceSource,
        waterSource,
        wateredArea,
        waterAmount,
        energySource,
        type,
      }) => ({
        method_id: Number(type),
        water_source_id: Number(waterSource),
        allocation: dotFormatter(wateredArea),
        pumping_depth: {
          value: dotFormatter(depthSource),
          unit_id: "1",
        },
        horizontal_distance: {
          value: dotFormatter(distanceSource.value),
          unit_id: distanceSource.unit,
        },
        water_added: {
          value: dotFormatter(waterAmount),
          unit_id: "15",
        },
        power_source_id: Number(energySource),
      })
    ),
  };
}

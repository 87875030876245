import axios from "axios";

const getApiUrlFromEnv = (env, server, version) => {
  let url = "";
  switch (process.env.REACT_APP_API_ENV) {
    case "production":
      url = "https://api.agronomist.pl/";
      break;

    case "stage":
      url = "https://meltida-api.agronomist.pl/";
      break;

    default:
    case "develop":
      url = "https://api.devel.bnp.kerris.co/";
      break;
  }

  return server ? `${url}api/v${version}` : url;
};

export const getAppUrl = () => {
  switch (process.env.REACT_APP_API_ENV) {
    case "production":
      return "https://agronomist.pl/";

    case "stage":
      return "https://300codes.agronomist.pl/";

    case "develop":
      return "https://devel.bnp.kerris.co/";
    default:
      return "http://localhost:3000/";
  }
};

export default (fromServer = false, version = 1) => {
  return axios.create({
    baseURL:
      process.env.NODE_ENV !== "development"
        ? getApiUrlFromEnv(process.env.REACT_APP_API_ENV, fromServer, version)
        : "",
  });
};

import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBoxWrapper,
  CftBox,
  CftUnitField,
} from "../../../components";
import useCftOptions from "../../../utils/useCftOptions";

// Icons
import { ReactComponent as ConfidentIcon } from "../../../../assets/icons/cft/trawa.svg";
import { ReactComponent as RangelandIcon } from "../../../../assets/icons/cft/hay.svg";

const iniGrasslandFertilisation = {
  fertilisationApproach: "",
  grazingArea: {
    value: "",
    unit: "",
  },
};

function GrasslandFertilisationFileds() {
  const { t } = useTranslation(["cft"]);
  const { values } = useFormikContext();
  const { getUnits } = useCftOptions();

  const value = values.fertilisation;

  return (
    <>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:cattleGrazing.form.13", "Nawożenie użytków zielonych")}
          <small>
            {t(
              "cft:cattleGrazing.form.14",
              "Czy nawozisz użytki zielone gdzie stado się wypasa?"
            )}
          </small>
        </h3>
        <CftFormField name="fertilisation">
          {({ value, helpers }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={!!value}
                  onClick={() => {
                    helpers.setValue(iniGrasslandFertilisation);
                  }}
                >
                  {t("cft:yes", "Tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                  }}
                >
                  {t("cft:no", "Nie")}
                </CftButton>
              </CftTank>
            </>
          )}
        </CftFormField>
      </CftFormControl>
      {!!value && (
        <>
          <CftFormControl>
            <CftLabel label={t("cft:cattleGrazing.form.15", "Powierzchnia")} />
            <CftFormField name="fertilisation.fertilisationApproach">
              {({ value, helpers, meta }) => (
                <>
                  <CftBoxWrapper>
                    <CftBox
                      isActive={value === "1"}
                      onClick={() => helpers.setValue("1")}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <ConfidentIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {t(
                          "cft:cattleGrazing.form.16",
                          "Wyłącznie powierzchnia\npastwiska"
                        )}
                      </p>
                    </CftBox>
                    <CftBox
                      isActive={value === "2"}
                      onClick={() => helpers.setValue("2")}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <RangelandIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {t(
                          "cft:cattleGrazing.form.17",
                          "Łączna powierzchnia\npastwiska, upraw\nkiszonki zielonej i siana"
                        )}
                      </p>
                    </CftBox>
                  </CftBoxWrapper>
                  <CftError
                    isActive={meta.touched && meta.error}
                    message={meta.error}
                    textAlign="center"
                  />
                </>
              )}
            </CftFormField>
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t(
                "cft:cattleGrazing.form.18",
                "Powierzchnia użytku zielonego"
              )}
            />
            <CftUnitField
              name="fertilisation.grazingArea"
              units={getUnits(["m2", "ha", "a"])}
            />
          </CftFormControl>
        </>
      )}
    </>
  );
}

export default GrasslandFertilisationFileds;

export const fv = (rate, nperiod, pmt, pv, type = 0) => {
  // eslint-disable-next-line no-restricted-properties
  const pow = Math.pow(1 + rate, nperiod);
  let fv = 0;

  if (rate) {
    fv = (pmt * (1 + rate * type) * (1 - pow)) / rate - pv * pow;
  } else {
    fv = -1 * (pv + pmt * nperiod);
  }

  return fv;
};

export const pmt = (rate, nperiod, pv, fv = 0, type = 0) => {
  if (rate === 0) {
    return -(pv + fv) / nperiod;
  }

  // eslint-disable-next-line no-restricted-properties
  const pvif = Math.pow(1 + rate, nperiod);
  let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

  if (type === 1) {
    pmt /= 1 + rate;
  }

  return pmt;
};

export const ipmt = (rate, period, nperiod, pv, future = 0, typeInput = 0) => {
  const type = typeof typeInput === "undefined" ? 0 : typeInput;
  const pmtValue = pmt(rate, nperiod, pv, future, type);
  let interest;

  if (period === 1) {
    if (type === 1) {
      interest = 0;
    } else {
      interest = -pv;
    }
  } else if (type === 1) {
    interest = fv(rate, period - 2, pmtValue, pv, 1) - pmtValue;
  } else {
    interest = fv(rate, period - 1, pmtValue, pv, 0);
  }

  return interest * rate;
};

export const ppmt = (rate, per, nper, pv, fv = 0, type) =>
  pmt(rate, nper, pv, fv, type) - ipmt(rate, per, nper, pv, fv, type);

export const calculateCreditAmount = ({ investmentAmount }) => investmentAmount;

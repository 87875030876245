import { ANSWER_TYPE } from "../consts";

const LOOKING_FOR_INSURANCE_GRAINS = {
  0: {
    question: "Co chcesz ubezpieczyć?",
    answers: [
      {
        text: "Majątek w gospodarstwie",
        type: ANSWER_TYPE.grain,
        grain: 1,
        icon: "House",
      },
      {
        text: "Życie i zdrowie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenia-na-zycie",
        icon: "HeartEcgShield",
      },
      {
        text: "Uprawy rolne",
        type: ANSWER_TYPE.grain,
        grain: 2,
        icon: "Crops",
      },
      {
        text: "Maszyny rolnicze",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-maszyn-rolniczych",
        icon: "Tractor",
      },
    ],
  },
  1: {
    title: "Chcę ubezpieczyć swój majątek",
    question: "Wybierz jaki składnik majątku chcesz ubezpieczyć?",
    answers: [
      // {
      //   text: "Zwierzęta",
      //   type: ANSWER_TYPE.end,
      //   grain: "/kredytomat/ubezpieczenie-zwierzat-gospodarskich",
      //   icon: "Cows",
      // },
      {
        text: "Produkcja drobiu",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-drobiu",
        icon: "Chickens",
      },
      {
        text: "Samochód",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-samochoodu",
        icon: "Car",
      },
      {
        text: "Budynki gospodarstwa",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-budynkow",
        icon: "Farm",
      },
      // {
      //   text: "Instalacje OZE, w tym fotowoltaikę",
      //   type: ANSWER_TYPE.end,
      //   grain: "/kredytomat/ubezpieczenie-odnawialnych-zrodel-energii",
      //   icon: "PhotovoltaicsSun",
      // },
    ],
  },
  2: {
    title: "Chcę ubezpieczyć uprawy rolne",
    question: "Wybierz sezon",
    answers: [
      {
        text: "Wiosna",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-upraw-w-sezonie-wiosennym",
        icon: "CalendarFlower",
      },
      {
        text: "Jesień",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/ubezpieczenie-upraw-w-sezonie-jesiennym",
        icon: "CalendarLeaf",
      },
    ],
  },
};

export default LOOKING_FOR_INSURANCE_GRAINS;

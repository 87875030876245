import { useTranslation } from "react-i18next";
import { mapCropDetails } from "../forms/farmlands/DetailsFarmlandsForm/detailsFarmlands.utils";
import { mapSoilDetails } from "../forms/farmlands/SoilFarmlandsForm/soilFarmlands.utils";

const mapDetails = (data, language) => {
  const report = Object.keys(data).reduce((prev, curr) => {
    if (curr === "crop") {
      return [
        ...prev,
        {
          name: {
            i18n: "cft:types.detailsFarmlands",
            default: "Detale uprawy",
          },
          rows: mapCropDetails(data[curr], language),
        },
      ];
    }

    if (curr === "soil") {
      return [
        ...prev,
        {
          name: {
            i18n: "cft:types.soil",
            default: "Gleba",
          },
          rows: mapSoilDetails(data[curr], language),
        },
      ];
    }

    return prev;
  }, []);

  return report;
};

const useFarmlandsDetailsReport = report => {
  const { i18n } = useTranslation();
  return {
    details: mapDetails(report, i18n.language),
  };
};

export default useFarmlandsDetailsReport;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  CftTank,
  CftButton,
  CftFormField,
  CftBox,
  CftWarningBox,
  CftClearForm,
  CftFormErrorFocus,
  CftTooltip,
} from "../../components";

import routes from "../../utils/cftRoutes";
import CreateCoopField from "./CreateCoopField";
import useCftTurkeyValues from "../../utils/useCftTurkeyValues";
import ArrowRight from "../../components/icons/ArrowRight";
import TrashIcon from "../../components/icons/TrashIcon";
import PlusIcon from "../../components/icons/PlusIcon";

export default function ChickenBroilerCoopsForm() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const history = useHistory();
  const { store, updateStore } = useCftTurkeyValues();

  const isNextStepAvailable =
    store.henhouses_reference.coops.length > 0 &&
    store.henhouses_reference.coops.filter(coop => coop.isValidate).length ===
      store.henhouses_reference.coops.length;

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2
          className="cft-section-title mb-3 cft-max-wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t(
            "cftTurkey:chickenBroilerCoopsForm.title",
            "indyczniki referencyjne"
          )}

          <CftTooltip
            style={{ position: "absolute", right: "0" }}
            content={t(
              "cftTurkey:tooltip.coopsForm",
              "Indycznik referencyjny to jeden wzorcowy indycznik w którym podasz informacje o rożnych cyklach produkcyjnych i na podstawie którego dokonasz obliczeń emisji dla swojej hodowli. Możesz zdefiniować od 1 do 6 indyczników referencyjnych"
            )}
          />
        </h2>
        <Formik
          initialValues={store.henhouses_reference}
          onSubmit={() => {
            updateStore({
              henhouses_reference: {
                ...store.henhouses_reference,
                isValidate: true,
              },
            });
            history.push(routes.TURKEY.EMISSIONS);
          }}
        >
          <Form style={{ width: "100%" }}>
            <CreateCoopField />
            <CftFormField name="coops">
              {({ value, helpers }) =>
                !!value.length && (
                  <>
                    <h4 className="cft-section-subtitle mb-6">
                      {t(
                        "cftTurkey:chickenBroilerCoopsForm.subtitle",
                        "Podaj informacje o cyklach produkcyjnych we wszystkich kurnikach"
                      )}
                    </h4>
                    {value.map(({ name, isValidate }, key) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="cft-paper mb-2"
                      >
                        <CftBox
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          variant="fullWidth"
                          isActive={isValidate}
                          onClick={() => {
                            history.push(`${routes.TURKEY.COOPS}/${key}`);
                          }}
                        >
                          <span>
                            {name || `${t("cftTurkey:coop")} ${key + 1}`}
                          </span>
                          <ArrowRight />
                        </CftBox>
                        <TrashIcon
                          onClick={() => {
                            updateStore({
                              henhouses_reference: {
                                ...store.henhouses_reference,
                                coops: value.filter((_, _key) => _key !== key),
                              },
                            });
                            helpers.setValue(
                              value.filter((_, _key) => _key !== key)
                            );
                          }}
                          style={{
                            marginTop: "16px",
                            marginLeft: "16px",
                            marginRight: "-32px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                    {value.length < 6 ? (
                      <div className="cft-paper">
                        <CftTank
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <CftButton
                            variant="active"
                            isRounded
                            isAlternative
                            isNegative
                            onClick={() => {
                              const coops = [
                                ...value,
                                {
                                  name: "",
                                  production_cycles: [
                                    {
                                      production_cycle_length: "",
                                      technological_break_length: "",
                                      repetitions: "",
                                      male_turkeys: null,
                                      female_turkeys: null,
                                    },
                                  ],
                                  isValidate: false,
                                },
                              ];
                              updateStore({
                                henhouses_reference: {
                                  ...store.henhouses_reference,
                                  coops,
                                },
                              });
                              helpers.setValue(coops);
                            }}
                          >
                            <PlusIcon style={{ marginRight: "12px" }} />
                            {t(
                              "cftTurkey:chickenBroilerCoopsForm.addHenhouses_reference",
                              "dodaj indycznik"
                            )}
                          </CftButton>
                        </CftTank>
                      </div>
                    ) : (
                      <CftWarningBox
                        style={{ maxWidth: "570px" }}
                        text="Zdeklarowałeś maksymalną ilość indyczników referencyjnych"
                      />
                    )}
                  </>
                )
              }
            </CftFormField>
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
                disabled={!isNextStepAvailable}
              >
                {t(
                  "cftTurkey:chickenBroilerCoopsForm.submit",
                  "podaj informacje o emisjach"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="turkey" />
    </div>
  );
}

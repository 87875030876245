export const detailsBothCalcForm = {
  name: "",
  cultivate: "",
  crop: "",
  areal: {
    unit: "",
    value: "",
  },
  seedAmount: {
    unit: undefined,
    value: undefined,
  },
  harvestAmount: "",
  exit: "",
  year: "",
  residueManagement: "",
  remainingQuantity: "",
  extraProducts: null,
  products: [],
};

export const soilBothCalc = {
  soilTexture: "",
  soilType: "",
  soilTypeAdvanced: "",
  soilMatter: "",
  averageSoilMoisture: "",
  soilDrainage: "",
  ph: "",
};

export const fertilizersBothCalc = {
  fertilizers: [],
  plantProtectionProducts: [],
};

export const fuelAndEnergyBothCalc = {
  sources: [],
  consumptions: [],
  processing: [],
  potatoStorage: {},
  isPotatoStorage: undefined,
};

export const irrigationBothCalc = {
  sowingDate: "",
  harvestingDate: "",
  method: "",
  waterSource: "",
  waterAmount: "",
  wateredArea: "",
  depthSource: "",
  distanceSource: "",
  energySource: "",
  sameParameters: null,
  weeks: [],
};

export const carbonChangesBothCalc = {
  converted: null,
  practices: null,
  catched: null,
  managementPractice: null,
  managementPracticeTillage: null,
  managementCoverChange: null,
};

export const transportBothCalc = {
  used: null,
  transports: [],
};

export const bothCalcReport = {
  crop: {},
  soil: {},
  fertilisers: [],
  pesticides: [],
  direct_energy: [],
  machinery: [],
  processing: [],
  storage: [],
  irigation: [],
  land_management: [],
  transport: [],
};

export const bothCalcInit = {
  details: detailsBothCalcForm,
  soil: soilBothCalc,
  fertilizers: fertilizersBothCalc,
  fuelAndEnergy: fuelAndEnergyBothCalc,
  irrigation: irrigationBothCalc,
  carbonChanges: carbonChangesBothCalc,
  transport: transportBothCalc,
};

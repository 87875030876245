import React from "react";
import { getComponent, AvailableComponents } from "agronomist-ui-components";

import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";
import TempShareButtonsComponent from "./TempShareButtonsComponent";

const PageComponent = ({ name, props }) => {
  const Element = getComponent(name);

  if (AvailableComponents.SHARE_PLACEHOLDER === name) {
    return <TempShareButtonsComponent />;
  }

  if (AvailableComponents.INVESTMENT_CALCULATOR_PLACEHOLDER === name) {
    return (
      <Element {...props}>
        <CalculatorPage isSeparate />
      </Element>
    );
  }

  if (Element) {
    return <Element {...props} />;
  }

  return null;
};

const PrfProduct = ({ page, language }) => {
  return (
    <>
      {page[language].map(component => (
        <PageComponent
          key={`prf_${component.id}_${language}`}
          name={component.type}
          props={component.props}
        />
      ))}
    </>
  );
};

export default PrfProduct;

import { useState } from "react";
import { useTranslation } from "react-i18next";

import routes from "./cftRoutes";

export const KEY_STORAGE = "adasdKKs92J";

export const PIGS_TABS = [
  {
    name: "cftPigs:header.start",
    path: routes.CATTLE.MAIN,
    keyStore: "main",
  },
  {
    name: "cftPigs:header.coops",
    path: routes.CATTLE.COOPS,
    keyStore: "herd_reference",
  },
  {
    name: "cftPigs:header.energy",
    path: routes.CATTLE.EMISSIONS,
    keyStore: "emission",
  },
  {
    name: "cftPigs:header.report",
    path: routes.CATTLE.REPORT,
    keyStore: "report",
  },
];

export const initialStore = {
  type: "cattle",
  main: {
    postal_code: "",
    name: "",
    calculation_period_length: "365",
    isValidate: false,
  },
  herd_reference: {
    production_groups: [],
    production_groups_tmp: [],
    calculation_period_phases: "",
    dominant_race: "",
    fertilizer_area: "",
    fertilizer_quantity: "",
    fertilizer_type: "",
    fertilizer_type_type: "",
    fertilizer_unit: "",
    fertilizing_pastures_exists: "",
    isValidate: false,
  },
  emission: {
    bedding: {
      bedding_period: "1",
      bedding_type: "1",
      quantity: 0,
    },
    energy_carriers: [],
    transport: [],
    isValidate: false,
  },
  report: {
    calculation_period_phases: "0",
    dominant_race: "",
    fertilizing_pastures: {
      fertilizer_area: 0,
      fertilizer_type: "",
      fertilizer_unit: "",
      fertilizer_quantity: 0,
    },
    production_groups: [
      {
        name: "",
        carbon_footprint: 0,
        feed_production: 0,
        excrement: 0,
        grazing: 0,
        enteric_fermentation: 0,
      },
    ],
    name: "",
    postal_code: "",
    calculation_period_length: 0,
    total_emission: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
      grazing: 0,
      fertilization: 0,
      enteric_fermentation: 0,
    },
    total_emission_per_animal: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
      grazing: 0,
      fertilization: 0,
      enteric_fermentation: 0,
    },
    total_emission_per_production_cycle: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
      grazing: 0,
      fertilization: 0,
      enteric_fermentation: 0,
    },
    total_emission_per_kilogram_of_product: {
      carbon_footprint: 0,
      carrier_use: 0,
      transport_use: 0,
      bedding_use: 0,
      feed_production: 0,
      excrement: 0,
      grazing: 0,
      fertilization: 0,
      enteric_fermentation: 0,
    },
  },
};

const PRODUCTION_GROUPS_TYPES = i18n => [
  {
    id: 1,
    name: i18n("cftPigs:productionGroupsTypes.1"),
    growth_factors: [
      { id: 1, value: 0.4 },
      { id: 2, value: 0.5 },
      { id: 3, value: 0.6 },
    ],
  },
  {
    id: 2,
    name: i18n("cftPigs:productionGroupsTypes.2"),
    growth_factors: [
      { id: 1, value: 0.4 },
      { id: 2, value: 0.5 },
      { id: 3, value: 0.6 },
    ],
  },
  {
    id: 3,
    name: i18n("cftPigs:productionGroupsTypes.3"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 4,
    name: i18n("cftPigs:productionGroupsTypes.4"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 5,
    name: i18n("cftPigs:productionGroupsTypes.5"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 6,
    name: i18n("cftPigs:productionGroupsTypes.6"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 7,
    name: i18n("cftPigs:productionGroupsTypes.7"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 8,
    name: i18n("cftPigs:productionGroupsTypes.8"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 9,
    name: i18n("cftPigs:productionGroupsTypes.9"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 10,
    name: i18n("cftPigs:productionGroupsTypes.10"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 11,
    name: i18n("cftPigs:productionGroupsTypes.11"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 12,
    name: i18n("cftPigs:productionGroupsTypes.12"),
    growth_factors: [
      { id: 1, value: 0.7 },
      { id: 2, value: 0.85 },
      { id: 3, value: 1.0 },
    ],
  },
  {
    id: 13,
    name: i18n("cftPigs:productionGroupsTypes.13"),
    growth_factors: [
      { id: 1, value: 0.0 },
      { id: 2, value: 0.0 },
      { id: 3, value: 0.0 },
    ],
  },
  {
    id: 14,
    name: i18n("cftPigs:productionGroupsTypes.14"),
    growth_factors: [
      { id: 1, value: 0.0 },
      { id: 2, value: 0.0 },
      { id: 3, value: 0.0 },
    ],
  },
];

const FEED_TYPES = i18n => [
  {
    name: i18n("cftPigs:feedTypes.1.name"),
    elements: [
      {
        id: 1,
        name: i18n("cftPigs:feedTypes.1.elements.1"),
      },
      { id: 2, name: i18n("cftPigs:feedTypes.1.elements.2") },
      { id: 3, name: i18n("cftPigs:feedTypes.1.elements.3") },
      {
        id: 4,
        name: i18n("cftPigs:feedTypes.1.elements.4"),
      },
      {
        id: 5,
        name: i18n("cftPigs:feedTypes.1.elements.5"),
      },
      { id: 6, name: i18n("cftPigs:feedTypes.1.elements.6") },
    ],
  },
  {
    name: i18n("cftPigs:feedTypes.2.name"),
    elements: [
      { id: 7, name: i18n("cftPigs:feedTypes.2.elements.7") },
      { id: 8, name: i18n("cftPigs:feedTypes.2.elements.8") },
      { id: 9, name: i18n("cftPigs:feedTypes.2.elements.9") },
    ],
  },
  {
    name: i18n("cftPigs:feedTypes.3.name"),
    elements: [
      {
        id: 10,
        name: i18n("cftPigs:feedTypes.3.elements.10"),
      },
      {
        id: 11,
        name: i18n("cftPigs:feedTypes.3.elements.11"),
      },
    ],
  },
  {
    name: i18n("cftPigs:feedTypes.4.name"),
    elements: [
      {
        id: 12,
        name: i18n("cftPigs:feedTypes.4.elements.12"),
      },
      {
        id: 13,
        name: i18n("cftPigs:feedTypes.4.elements.13"),
      },
      {
        id: 14,
        name: i18n("cftPigs:feedTypes.4.elements.14"),
      },
    ],
  },
];

const FEED_PORTS = i18n => [
  {
    id: 1,
    name: i18n("cftPigs:feedPorts.1"),
  },
  { id: 2, name: i18n("cftPigs:feedPorts.2") },
  { id: 3, name: i18n("cftPigs:feedPorts.3") },
  { id: 4, name: i18n("cftPigs:feedPorts.4") },
];

export default function useCftTurkeyValues() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const [store, setStore] = useState(() => {
    const storeCached = localStorage.getItem(`cft:${KEY_STORAGE}`);

    if (storeCached) {
      return JSON.parse(storeCached);
    }

    localStorage.setItem(`cft:${KEY_STORAGE}`, JSON.stringify(initialStore));
    return initialStore;
  });

  const updateStore = data => {
    setStore(state => {
      localStorage.setItem(
        `cft:${KEY_STORAGE}`,
        JSON.stringify({ ...state, ...data })
      );
      return {
        ...state,
        ...data,
      };
    });
  };

  const getProductionGroupTypeName = id => {
    const group = PRODUCTION_GROUPS_TYPES(t).find(
      type => Number(type.id) === Number(id)
    );

    return group.name;
  };

  const getFeedTypeName = type => {
    const feedTypes = FEED_TYPES(t).reduce((prev, crr) => {
      return [...prev, ...crr.elements];
    }, []);

    return feedTypes.find(({ id }) => id === type).name;
  };

  return {
    store,
    updateStore,
    i18n: t,
    productionGroupsTypes: PRODUCTION_GROUPS_TYPES(t),
    feedTypes: FEED_TYPES(t),
    feedPorts: FEED_PORTS(t),
    getProductionGroupTypeName,
    getFeedTypeName,
  };
}

/* global window */

import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import { gtmRun, pixelRun, pixelInit } from "../services/helpers/tracers";
import AppRouting from "./AppRouting";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== "development") {
      this.setState({ error });
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      gtmRun();
      pixelInit();
      pixelRun();
    }, 5000);
  }

  render() {
    return <AppRouting />;
  }
}

export default App;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import {
  Button,
  Input,
  RadioButton,
  SelectInput,
  AmountInput,
  RangeInput,
} from "../../../common";

import {
  validateSubvention,
  validateAmount,
} from "../../../../services/helpers/calculator/validators";
import {
  getRepaymentTimespanRange,
  getRepaymentMaxValue,
  getAcceptedYearValues,
  getAcceptedMonthValues,
  getYearsSuffixTranslationKey,
  range,
} from "../../../../services/helpers/calculator/dataHelpers";
import { MONTHS } from "../../../../services/helpers/constants";

import "./InvestmentStep.scss";

const maxInvestmentValue = 9999999999.99;
const acceptedRepaymentTimespanValues = range(0, 30);

class InvestmentStep extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subventionAmountTouched: false,
      investmentAmountTouched: false,
    };
  }

  render() {
    const {
      investmentStepCompleted,
      handleStepCompletionChange,
      investmentAmount,
      hasSubvention,
      handleSubventionStatusChange,
      investmentType,
      handleInputChange,
      creditAmount,
      minimumCreditAmount,
      subventionAmount,
      hasError,
      isInEditMode,
      handleError,
      subventionReceivingMonth,
      subventionReceivingYear,
      handleCalculatingCreditOffer,
      repaymentTimespan,
      creditRate,
      autoFocus,
      t,
      i18n,
    } = this.props;
    const { investmentAmountTouched, subventionAmountTouched } = this.state;

    const investmentAmountValid = validateAmount({
      amount: investmentAmount,
      optional: true,
    });
    const subventionAmountValid = validateAmount({
      amount: subventionAmount,
      optional: true,
    });
    const subventionAmountNotTooBig = validateSubvention({
      investmentAmount,
      subventionAmount,
    });
    const isCreditAmountValid = creditAmount >= minimumCreditAmount;

    const acceptedYearValues = getAcceptedYearValues({ investmentType });
    const acceptedMonthValues = getAcceptedMonthValues({
      subventionReceivingYear,
      t,
    });

    const chosenYear = acceptedYearValues.find(
      year => year === subventionReceivingYear
    );
    const chosenMonth = Object.values(MONTHS).find(
      month => month.value === subventionReceivingMonth
    );
    const yearsSuffixTranslationKey = getYearsSuffixTranslationKey({
      i18n,
      repaymentTimespan,
    });

    const countDecimals = value => {
      if (value % 1 !== 0) return value.toString().split(".")[1].length;
      return 0;
    };

    return (
      <>
        <div className="investment-container">
          <AmountInput
            id="investment-amount"
            label={t("inputs.0")}
            name="investmentAmount"
            autoFocus={autoFocus}
            icon={<div className="input-adornment-text">PLN</div>}
            value={investmentAmount}
            onValueChange={value => {
              this.setState({ investmentAmountTouched: true });
              handleError(investmentAmountValid);

              if (
                value &&
                value.floatValue &&
                value.floatValue > maxInvestmentValue
              ) {
                return;
              }

              if (value.floatValue === 0 || !value.floatValue) {
                handleInputChange("", "investmentAmount");
              } else {
                handleInputChange(value.floatValue, "investmentAmount");
              }
            }}
            placeholder={0}
            inputstate={
              !investmentAmountValid && investmentAmountTouched ? "error" : ""
            }
            haserror={!investmentAmountValid && investmentAmountTouched}
          />
          <p className="investment-type-description">
            {t("firstStep.content")}
          </p>
          <p className="has-subvention-question">{t("inputs.1")}</p>
          <div className="subvention-fields-container">
            <RadioButton
              id="has-subvention"
              label={t("globals:inputs.yes")}
              value
              checked={hasSubvention}
              onChange={() => {
                handleSubventionStatusChange(true);
                this.setState({ subventionAmountTouched: false });
              }}
            />
            <RadioButton
              id="has-subvention"
              label={t("globals:inputs.no")}
              value={false}
              checked={!hasSubvention}
              onChange={() => {
                handleSubventionStatusChange(false);
                this.setState({ subventionAmountTouched: false });
              }}
            />
          </div>
          {hasSubvention && (
            <>
              <div className="subvention-amount-container">
                <AmountInput
                  id="subvention-amount"
                  label={t("inputs.2")}
                  type="text"
                  name="subventionAmount"
                  value={subventionAmount}
                  icon={<div className="input-adornment-text">PLN</div>}
                  onValueChange={value => {
                    this.setState({ subventionAmountTouched: true });
                    handleError(subventionAmountValid);

                    if (
                      value &&
                      value.floatValue &&
                      value.floatValue > maxInvestmentValue
                    ) {
                      return;
                    }

                    if (value.floatValue === 0 || !value.floatValue) {
                      handleInputChange("", "subventionAmount");
                    } else {
                      handleInputChange(value.floatValue, "subventionAmount");
                    }
                  }}
                  placeholder={0}
                  inputstate={
                    subventionAmountTouched &&
                    (!subventionAmountValid || !subventionAmountNotTooBig)
                      ? "error"
                      : ""
                  }
                  haserror={
                    subventionAmountTouched &&
                    (!subventionAmountValid || !subventionAmountNotTooBig)
                      ? "error"
                      : ""
                  }
                />
              </div>
              <p className="subvention-receiving-date-question">
                {t("inputs.3")}
              </p>
              <div className="subvention-receiving-date-container">
                <SelectInput
                  placeholder={t("inputs.5")}
                  label=""
                  haserror={false}
                  errormsg=""
                  onChange={option => {
                    handleInputChange(option.value, "subventionReceivingYear");
                    const months = getAcceptedMonthValues({
                      t,
                      subventionReceivingYear: option.value,
                    });

                    handleInputChange(
                      months[0] && months[0].value,
                      "subventionReceivingMonth"
                    );
                  }}
                  value={chosenYear && { value: chosenYear, label: chosenYear }}
                  options={acceptedYearValues.map(year => ({
                    value: year,
                    label: year,
                  }))}
                  id="subvention-receiving-year"
                />
                <SelectInput
                  placeholder={t("inputs.4")}
                  label=""
                  haserror={null}
                  errormsg={t("firstStep.warnings.4")}
                  onChange={option => {
                    handleInputChange(option.value, "subventionReceivingMonth");
                  }}
                  value={
                    chosenMonth && {
                      label: t(chosenMonth.label),
                      value: chosenMonth.value,
                    }
                  }
                  options={acceptedMonthValues}
                  id="subvention-receiving-month"
                />
              </div>
            </>
          )}
          <div className="input-with-slider">
            <Input
              id="repayment-timespan"
              // label={`Okres spłaty (1-${getRepaymentMaxValue({
              //   type: investmentType
              // })} lat)`}
              label={t("inputs.8").replace(
                "$YEAR",
                getRepaymentMaxValue({
                  type: investmentType,
                })
              )}
              type="text"
              name="repaymentTimespan"
              value={repaymentTimespan}
              placeholder={10}
              icon={
                <div className="input-adornment-text">
                  {t(yearsSuffixTranslationKey).toUpperCase()}
                </div>
              }
              onClick={event => {
                const input = event.target;
                input.focus();
                input.select();
              }}
              onChange={event => {
                const inAcceptedRange = acceptedRepaymentTimespanValues
                  .filter(
                    el =>
                      el < getRepaymentMaxValue({ type: investmentType }) + 1
                  )
                  .includes(Number(event.target.value));

                if (inAcceptedRange) {
                  handleInputChange(event.target.value, "repaymentTimespan");
                }
              }}
            />
            <RangeInput
              value={repaymentTimespan}
              range={getRepaymentTimespanRange({ type: investmentType })}
              onChange={event => {
                handleInputChange(event.target.value, "repaymentTimespan");
              }}
            />
          </div>
          <div className="input-with-slider custom-margin">
            <Input
              id="creditRate"
              label={t("inputs.9.label")}
              type="text"
              name="creditRate"
              placeholder={5}
              value={creditRate}
              icon={<div className="input-adornment-text">%</div>}
              onClick={event => {
                const input = event.target;
                input.focus();
                input.select();
              }}
              onChange={event => {
                const value = event.target.value.replace(",", ".");

                if (
                  (value >= 2 &&
                    value <= 30 &&
                    (value && countDecimals(value) <= 1)) ||
                  value === ""
                ) {
                  handleInputChange(value, "creditRate");
                }
              }}
            />
            <RangeInput
              value={creditRate}
              range={[2, 30]}
              onChange={event => {
                handleInputChange(event.target.value, "creditRate");
              }}
              step={0.1}
            />
          </div>
          <p className="credit-description">{t("secondStep.creditDesc")}</p>
        </div>
        {!investmentStepCompleted && (
          <>
            <div className="next-step-button-container">
              <Button
                buttondisabled={
                  hasError ||
                  (hasSubvention && !subventionAmount) ||
                  (hasSubvention && !subventionReceivingMonth) ||
                  (hasSubvention && !subventionReceivingYear) ||
                  (hasSubvention && !subventionAmountNotTooBig) ||
                  !isCreditAmountValid ||
                  (!investmentAmountValid && investmentAmountTouched)
                    ? "disabled"
                    : ""
                }
                buttonsize="small"
                buttoncolor="yellow"
                onClick={() => {
                  handleStepCompletionChange("creditStepCompleted", false);
                  handleStepCompletionChange("investmentStepCompleted", true);
                  if (isInEditMode) {
                    handleCalculatingCreditOffer();
                  }
                }}
              >
                {isInEditMode
                  ? t("globals:buttons.update")
                  : t("globals:buttons.next")}
              </Button>
            </div>
          </>
        )}
      </>
    );
  }
}

InvestmentStep.propTypes = {
  investmentStepCompleted: PropTypes.bool,
  hasSubvention: PropTypes.bool,
  hasError: PropTypes.bool,
  isInEditMode: PropTypes.bool,
  handleError: PropTypes.func.isRequired,
  handleStepCompletionChange: PropTypes.func.isRequired,
  handleSubventionStatusChange: PropTypes.func.isRequired,
  handleCalculatingCreditOffer: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  investmentAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subventionReceivingMonth: PropTypes.number,
  subventionReceivingYear: PropTypes.number,
  repaymentTimespan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditAmount: PropTypes.number,
  minimumCreditAmount: PropTypes.number,
  subventionAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  investmentType: PropTypes.string,
  autoFocus: PropTypes.bool,
};

InvestmentStep.defaultProps = {
  autoFocus: true,
  investmentStepCompleted: false,
  hasSubvention: false,
  hasError: false,
  isInEditMode: false,
  investmentAmount: 0,
  subventionReceivingMonth: 0,
  subventionReceivingYear: 0,
  repaymentTimespan: 0,
  creditRate: 0,
  creditAmount: 0,
  minimumCreditAmount: 0,
  subventionAmount: 0,
  investmentType: "",
};

export default withTranslation(["calculatorPage", "globals"])(InvestmentStep);

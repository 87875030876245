import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Button } from "../../../common";

import { formatAmount } from "../../../../services/helpers/calculator/formatters";

import "./OfferSummary.scss";

class OfferSummary extends Component {
  render() {
    const { handlePdfDownload, hasSubvention, t } = this.props;
    const {
      entries,
      installmentAmountAfterSubvention,
    } = this.props.generatedOffer;

    const firstInstallmentAmount =
      entries && entries[0].capitalWithInterestInstallmentAmount;
    const lastInstallmentAmount =
      entries &&
      entries[entries.length - 1].capitalWithInterestInstallmentAmount;
    const interestInstallmentsSum =
      entries &&
      entries.reduce(
        (prev, curr) => prev + parseFloat(curr.interestInstallmentAmount),
        0
      );

    let interestInstallmentAmount;

    if (hasSubvention) {
      const firstElemWithSubverntion = entries.find(
        elem =>
          elem.capitalInstallmentAmount === installmentAmountAfterSubvention
      );
      interestInstallmentAmount =
        entries && firstElemWithSubverntion
          ? parseFloat(
              Number(installmentAmountAfterSubvention) +
                Number(firstElemWithSubverntion.interestInstallmentAmount)
            )
          : installmentAmountAfterSubvention;
    }

    return (
      <>
        <div className="offer-container">
          <h2 className="typography--h2">{t("summary.labels.4")}:</h2>
          <div className="offer-calculation-details">
            <div className="offer-calculation-detail">
              <div className="offer-calculation-detail--description">
                {t("summary.labels.0")}:
              </div>
              <div className="offer-calculation-detail--value">
                {formatAmount({
                  amount: firstInstallmentAmount,
                })}
              </div>
            </div>
            {hasSubvention && installmentAmountAfterSubvention ? (
              <div className="offer-calculation-detail">
                <div className="offer-calculation-detail--description">
                  {t("summary.labels.1")}:
                </div>
                <div className="offer-calculation-detail--value">
                  {formatAmount({
                    amount: interestInstallmentAmount,
                  })}
                </div>
              </div>
            ) : null}
            <div className="offer-calculation-detail">
              <div className="offer-calculation-detail--description">
                {t("summary.labels.2")}:
              </div>
              <div className="offer-calculation-detail--value">
                {formatAmount({
                  amount: lastInstallmentAmount,
                })}
              </div>
            </div>
            <div className="offer-calculation-detail">
              <div className="offer-calculation-detail--description">
                {t("summary.labels.3")}:
              </div>
              <div className="offer-calculation-detail--value">
                {formatAmount({
                  amount: interestInstallmentsSum,
                })}
              </div>
            </div>
          </div>
          <div className="offer-calculation-description">
            <Button
              buttonsize="small"
              buttoncolor="yellow"
              onClick={() => handlePdfDownload()}
            >
              {t("summary.submit")}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

OfferSummary.propTypes = {
  handlePdfDownload: PropTypes.func.isRequired,
  hasSubvention: PropTypes.bool.isRequired,
  generatedOffer: PropTypes.shape({
    installmentAmountAfterSubvention: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        capitalInstallmentAmount: PropTypes.string,
        capitalInstallmentNumber: PropTypes.number,
        capitalWithInterestInstallmentAmount: PropTypes.string,
        currentCreditAmount: PropTypes.string,
        interestInstallmentAmount: PropTypes.string,
        interestPeriod: PropTypes.number,
        month: PropTypes.number,
        year: PropTypes.number,
      })
    ),
  }).isRequired,
};

export default withTranslation(["calculatorPage"])(OfferSummary);

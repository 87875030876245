import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftRadioField,
  CftSelectField,
} from "../../components";

const FertilizingPasturesFields = () => {
  const { values } = useFormikContext();
  const { t, i18n } = useTranslation([
    "cftTurkey",
    "cftChickenBroiler",
    "cftPigs",
    "cftCattle",
  ]);

  const fertilizingPastures = useMemo(
    () => [
      {
        label: t("cftCattle:fertilizing_pastures.yes", "Tak"),
        value: 1,
      },
      {
        label: t("cftCattle:fertilizing_pastures.no", "Nie"),
        value: 0,
      },
    ],
    []
  );

  const fertilizerUnit = useMemo(
    () => [
      {
        label: t("cftCattle:fertilizerUnit.0", "Tony na hektar"),
        value: 1,
      },
      {
        label: t("cftCattle:fertilizerUnit.1", "Całkowite zużycie w tonach"),
        value: 2,
      },
    ],
    []
  );

  const fertilizerType = useMemo(
    () => [
      {
        label: t("cftCattle:fertilizing_pastures.0", "Syntetyczny"),
        value: "synthetic",
      },
      {
        label: t("cftCattle:fertilizing_pastures.1", "Organiczny"),
        value: "organic",
      },
    ],
    []
  );

  const fertilizerTypes = useMemo(() => {
    const fertilizers = {
      organic: [
        {
          value: 1,
          pl: "Produkty pofermentacyjne (bydło)",
          gb: "Digestates cattle",
        },
        {
          value: 2,
          pl: "Produkty pofermentacyjne (świnie)",
          gb: "Digestates pigs",
        },
        {
          value: 3,
          pl: "Produkty pofermentacyjne (konie)",
          gb: "Digestates horses",
        },
        {
          value: 4,
          pl: "Produkty pofermentacyjne (drób)",
          gb: "Digestates poultry",
        },
        {
          value: 5,
          pl: "Obornik bydlęcy",
          gb: "Cattle manure",
        },
        {
          value: 6,
          pl: "Gnojowica bydlęca",
          gb: "Cattle slurry",
        },
      ],
      synthetic: [
        {
          value: 1,
          pl: "Azotan amonu granulowany",
          gb: "Granular ammonium nitrate",
        },
        {
          value: 2,
          pl: "Azotan amonowo – wapniowy",
          gb: "Ammonium-calcium nitrate",
        },
        {
          value: 3,
          pl: "Mocznik",
          gb: "Urea",
        },
        {
          value: 4,
          pl: "Saletra amonowo – mocznikowa",
          gb: "Ammonium-urea nitrate",
        },
      ],
    };

    return fertilizers[values.fertilizer_type_type];
  }, [fertilizerType, values]);

  const isFertilizingPasturesExists = !!Number(
    values.fertilizing_pastures_exists
  );

  const isFertilizingTypeExists = !!values.fertilizer_type_type;

  return (
    <CftTank
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <h4 className="cft-section-subtitle mb-4 mt-4">
        {t(
          "cftCattle:fertilizingPasturesFields.subtitle",
          "Podaj informacje o stosowanych nawozach"
        )}
      </h4>
      <CftFormControl alignLeft isAlternative>
        <div className="cft-paper">
          <CftLabel
            isFullWidth
            label={t(
              "cftCattle:dominantRaceFields.fields.fertilizing_pastures",
              "Czy nawozisz pastwisko?"
            )}
          />
          <CftTank flexDirection="column" mt={0} mb={0}>
            <CftRadioField
              mb={0}
              mt={0}
              variant="dots"
              name="fertilizing_pastures_exists"
              options={fertilizingPastures}
            />
          </CftTank>

          {isFertilizingPasturesExists && (
            <>
              <CftLabel
                isFullWidth
                label={t(
                  "cftCattle:dominantRaceFields.fields.fertilizer_area_label",
                  "Wielkość pastwiska"
                )}
              />
              <CftTextField
                adornment="ha"
                placeholder={t(
                  "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                  "Wpisz ilość"
                )}
                name="fertilizer_area"
                isAlternative
                mask="000000000"
              />
            </>
          )}
        </div>
        {isFertilizingPasturesExists && (
          <>
            <CftTank flexDirection="column" mt={4}>
              <div className="cft-paper">
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftCattle:dominantRaceFields.fields.fertilizer_type_type_label",
                    "Podaj rodzaj stosowanego nawozu"
                  )}
                />
                <CftRadioField
                  mb={0}
                  mt={0}
                  variant="dots"
                  name="fertilizer_type_type"
                  options={fertilizerType}
                />
              </div>
            </CftTank>
          </>
        )}
        {isFertilizingTypeExists && isFertilizingPasturesExists && (
          <CftTank flexDirection="column" mt={4}>
            <div className="cft-paper">
              <CftLabel
                isFullWidth
                label={t(
                  "cftCattle:dominantRaceFields.fields.fertilizer_type_label",
                  "Podaj ilość stosowanego nawozu w jednostce"
                )}
              />
              <CftRadioField
                mb={0}
                mt={0}
                variant="dots"
                name="fertilizer_unit"
                options={fertilizerUnit}
              />
              <CftLabel
                isFullWidth
                label={t(
                  "cftTurkey:energyCarriersFields.fields.fertilizer_typeLabel",
                  "Rodzaj nawozu"
                )}
              />
              <CftTank mt={3} mb={3}>
                <CftSelectField
                  name="fertilizer_type"
                  options={fertilizerTypes.map(fertilizer => ({
                    value: fertilizer.value,
                    label: fertilizer[i18n.language],
                  }))}
                  placeholder={t(
                    "cftTurkey:energyCarriersFields.fields.energy_carriersPlaceholder",
                    "Wybierz z listy"
                  )}
                  isRounded
                  isAlternative
                />
              </CftTank>

              <CftLabel
                isFullWidth
                label={t(
                  "cftCattle:dominantRaceFields.fields.fertilizer_quantity_label",
                  "Ilość nawozu (t)"
                )}
              />
              <CftTextField
                placeholder={t(
                  "cftPigs:pigsHerdFeedForm.fields.pigs_number",
                  "Wpisz ilość"
                )}
                name="fertilizer_quantity"
                isAlternative
                mask="000000000"
              />
            </div>
          </CftTank>
        )}
      </CftFormControl>
    </CftTank>
  );
};

export default FertilizingPasturesFields;

export const POST_TOOL = "POST_TOOL";
export const POST_TOOL_SUCCESS = "POST_TOOL_SUCCESS";
export const POST_TOOL_FAIL = "POST_TOOL_FAIL";

export const SET_TOOL_IN_STORE = "SET_TOOL_IN_STORE";

export const GET_TOOL_ACCESS_STATUS = "GET_TOOL_ACCESS_STATUS";
export const GET_TOOL_ACCESS_STATUS_SUCCESS = "GET_TOOL_ACCESS_STATUS_SUCCESS";
export const GET_TOOL_ACCESS_STATUS_FAIL = "GET_TOOL_ACCESS_STATUS_FAIL";

export const REQUEST_TOOL_ACCESS = "REQUEST_TOOL_ACCESS";
export const REQUEST_TOOL_ACCESS_STATUS_SUCCESS =
  "REQUEST_TOOL_ACCESS_STATUS_SUCCESS";
export const REQUEST_TOOL_ACCESS_STATUS_FAIL =
  "REQUEST_TOOL_ACCESS_STATUS_FAIL";

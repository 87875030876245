import React from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
// import appLogo from "../../../assets/logo/agronomist_a copy.svg";
import appLogo from "../../../assets/logo/bnp.png";
import CookiesPolicy from "../CookiesPolicy/CookiesPolicy";
import Image from "../Image/Image";
import { Header } from "../index";

import { ReactComponent as TwitterIcon } from "../../../assets/icons/cft/social-twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/cft/social-facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/cft/social-linkedin.svg";
// import { ReactComponent as MessengerIcon } from "../../../assets/icons/cft/social-messenger.svg";

import "./Footer.scss";

const Footer = () => {
  const { t, i18n } = useTranslation(["navigation"]);

  const terms = {
    gb: "/static/pdf/Regulamin_strony_EN.pdf",
    pl: "/static/pdf/Regulamin_strony_PL.pdf",
  };
  const footerTabsNames = [
    {
      tabName: t("navigation:navContact"),
      redirectTo: i18n.language === "pl" ? "/kontakt" : "/contact",
    },
    {
      tabName: t("navigation:navTerms"),
      redirectTo: terms[i18n.language],
      target: true,
    },
    {
      tabName: t("navigation:navPP"),
      redirectTo:
        i18n.language === "pl" ? "/polityka-prywatnosci" : "/privacy-policy",
    },
    { tabName: t("navigation:navGDPR"), redirectTo: "/rodo" },
    {
      tabName: t("navigation:navSecurity"),
      redirectTo: "https://www.bnpparibas.pl/bezpieczenstwo",
      isExternal: true,
    },
  ];

  const socials = [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/bnpparibas_pl",
    },
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/BNPParibasBankPolska/",
    },
    {
      icon: <LinkedinIcon />,
      link: "https://www.linkedin.com/company/bank-bnp-paribas",
    },
  ];

  return (
    <>
      <CookiesPolicy />
      <footer className="footer footer--fitted">
        <Container>
          <Row className="align-items-center">
            <Col xl={6} xs={6}>
              <div className="footer__app-logo-wrapper">
                <a
                  href="https://www.bnpparibas.pl/rolnicy"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <Image
                    className="footer__app-logo"
                    src={appLogo}
                    alt=""
                    width="auto"
                    height="auto"
                  />
                </a>
              </div>
            </Col>
            <Col xl={6} xs={6}>
              <div className="footer__social-media">
                {socials.map((el, index) => (
                  <a
                    key={`social-${index}`}
                    href={el.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    className="footer__social-media-icon"
                  >
                    {el.icon}
                  </a>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
        <div className="footer__break" />
        <Container>
          <Row className="align-items-center">
            <Col xs={12} xl={9}>
              <ul className="footer__footer-menu">
                {footerTabsNames.map(item => (
                  <li
                    key={item.tabName}
                    className="footer__footer-menu__menu-item"
                  >
                    {item.isExternal ? (
                      <a
                        href={item.redirectTo}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Header
                          headertype="footer"
                          className="footer__footer-menu__menu-item__header"
                        >
                          {item.tabName.toUpperCase()}
                        </Header>
                      </a>
                    ) : (
                      <Link
                        to={item.redirectTo}
                        target={item.target ? "_blank" : ""}
                      >
                        <Header
                          headertype="footer"
                          className="footer__footer-menu__menu-item__header"
                        >
                          {item.tabName.toUpperCase()}
                        </Header>
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} xl={3} className="footer__author-column">
              <p className="footer__footer-signature">
                Created by
                <span>
                  <a
                    href="https://300.codes"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    300.codes{" "}
                  </a>{" "}
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default withRouter(Footer);

import React from "react";
import { ReactComponent as SandIcon } from "../../../../assets/icons/cft/piasek.svg";
import { ReactComponent as PaperIcon } from "../../../../assets/icons/cft/gazety.svg";
import { ReactComponent as SawdustIcon } from "../../../../assets/icons/cft/trociny.svg";
import { ReactComponent as StrawIcon } from "../../../../assets/icons/cft/sloma.svg";

export const manureType = [
  {
    pl: "Gnojówka",
    gb: "Solid storage",
    value: 1,
  },
  {
    pl: "Obornik",
    gb: "Dry lot",
    value: 2,
  },
  {
    pl: "Gnojowica pokryta naturalnym kożuchem",
    gb: "Liquid slurry with natural crust cover",
    value: 3,
  },
  {
    pl: "Gnojowica nie pokryta naturalnym kożuchem",
    gb: "Liquid slurry without natural crust cover",
    value: 4,
  },
  {
    pl: "Uncovered anaerobic lagoon",
    gb: "Uncovered anaerobic lagoon",
    value: 5,
  },
  {
    pl: "Pit storage below animal confinements",
    gb: "Pit storage below animal confinements",
    value: 6,
  },
  {
    pl: "Anaerobic digester",
    gb: "Anaerobic digester",
    value: 7,
  },
  {
    pl: "Deep bedding - no mixing",
    gb: "Deep bedding - no mixing",
    value: 8,
  },
  {
    pl: "Deep bedding - active mixing",
    gb: "Deep bedding - active mixing",
    value: 9,
  },
  {
    pl: "Composting - forced aeration",
    gb: "Composting - forced aeration",
    value: 10,
  },
  {
    pl: "Composting - non-forced aeration",
    gb: "Composting - non-forced aeration",
    value: 11,
  },
  {
    pl: "Poultry manure with litter",
    gb: "Poultry manure with litter",
    value: 12,
  },
  {
    pl: "Poultry manure without litter",
    gb: "Poultry manure without litter",
    value: 13,
  },
  {
    pl: "Aerobic treatment - natural aeration",
    gb: "Aerobic treatment - natural aeration",
    value: 14,
  },
  {
    pl: "Aerobic treatment - forced aeration",
    gb: "Aerobic treatment - forced aeration",
    value: 15,
  },
  {
    pl: "Daily spread",
    gb: "Daily spread",
    value: 16,
  },
  {
    pl: "Pasture",
    gb: "Pasture",
    value: 17,
  },
];
export const beddingType = [
  {
    pl: "Słoma",
    gb: "straw",
    icon: <StrawIcon />,
    value: 621,
  },
  {
    pl: "Piasek",
    gb: "sand",
    icon: <SandIcon />,
    value: 622,
  },
  {
    pl: "Gazety/papier",
    gb: "newspaper",
    icon: <PaperIcon />,
    value: 623,
  },
  {
    pl: "Trociny",
    gb: "sawdust",
    icon: <SawdustIcon />,
    value: 624,
  },
];

export function mapValues(values) {
  const herd_sections = values.cattleHerd.map((v, key) => {
    if (v) {
      const {
        animals,
        liveWeight,
        purchasedAnimals,
        purchasedWeight,
        soldWeight,
        soldAnimals,
      } = v;
      return {
        herd_section: key + 1,
        animals,
        live_weight: {
          value: liveWeight,
          unit_id: "7",
        },
        sold_animals: soldAnimals,
        sold_weight: {
          value: soldWeight,
          unit_id: "7",
        },
        purchased_animals: purchasedAnimals,
        purchased_weight: {
          value: purchasedWeight,
          unit_id: "7",
        },
      };
    }

    return null;
  });

  return {
    herd_sections: herd_sections.filter(val => val !== null),
  };
}

export function mapHerdSections(data, t) {
  const parsedData = data
    .map(herd => {
      if (!herd) {
        return null;
      }

      return {
        name: t(`cft:titlesCattleHerd.${herd.herd_section}`),
        animals: herd.animals,
        live_weight: `${herd.live_weight.value} kg`,
        sold_animals: herd.sold_animals,
        sold_weight: `${herd.sold_weight.value} kg`,
        purchased_animals: herd.purchased_animals,
        purchased_weight: `${herd.purchased_weight.value} kg`,
      };
    })
    .filter(val => val !== null);

  return parsedData;
}

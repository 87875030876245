import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { Redirect, useHistory } from "react-router";
import LanguageNavigation from "../../components/common/NavigationBar/LanguageNavigation";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import {
  RegagriLayout,
  RegagriHeader,
  RegagriSection,
  RegagriTank,
  RegagriActionsButtons,
} from "../components";
import modalImg from "../../assets/img/tools/regagri/rounded.jpg";
import "./CultivationDetails.scss";
import { Breadcrumbs, Button, Footer } from "../../components/common";
import Form from "../forms/Form";
import "../../components/common/Button/Button.scss";
import regagriRoutes from "../utils/regagriRoutes";
import Image from "../../components/common/Image/Image";

function CultivationDetails() {
  const { t } = useTranslation(["regagri", "navigation"]);
  const token = Cookies.get("user_token");

  if (!token) {
    return <Redirect to={regagriRoutes.MAIN} />;
  }

  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTutorialModalOpen, setTutorialIsModalOpen] = useState(
    !Cookies.get("regagri_tutorial")
  );

  const stopOverflow = active => {
    if (active) {
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "";
      document.querySelector("html").style.overflow = "";
    }
  };

  const onTutorialClose = () => {
    setTutorialIsModalOpen(false);
    stopOverflow(false);
  };

  const handleCloseTutorialModal = () => {
    setTutorialIsModalOpen(false);
    stopOverflow(false);
    Cookies.set("regagri_tutorial", true, {
      expires: new Date(`${new Date().getFullYear() + 1}`),
      secure: true,
    });
  };

  const onClose = () => {
    setIsModalOpen(false);
    stopOverflow(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    stopOverflow(false);
  };

  useEffect(() => {
    stopOverflow(isTutorialModalOpen);
  }, [isTutorialModalOpen]);

  return (
    <div className="cultivation-details-page">
      <Modal
        isOpen={isTutorialModalOpen}
        onRequestClose={onTutorialClose}
        style={{
          overlay: {
            zIndex: "1000",
            backgroundColor: "rgba(0,0,0,0.6)",
            overflowY: "scroll",
          },
          content: {
            top: "140px",
            maxWidth: "970px",
            width: "auto",
            margin: "auto",
            borderRadius: 0,
            bottom: "unset",
            overflowX: "hidden",
            backgroundColor: "#f1f1f1",
          },
        }}
      >
        <Container fluid className="tools-page__modal">
          <Image src={modalImg} alt={t("regagri:regagriExplorer.modal.0")} />
          <h1>{t("regagri:regagriExplorer.modal.0")}</h1>
          <p className="text">{t("regagri:regagriExplorer.modal.1")}</p>
          <p className="text">{t("regagri:regagriExplorer.modal.2")}</p>
          <Button
            onClick={handleCloseTutorialModal}
            buttonsize="small"
            buttoncolor="green"
          >
            {t("regagri:regagriExplorer.modal.3")}
          </Button>
          <button
            type="button"
            className="tools-page__modal--close"
            onClick={handleCloseTutorialModal}
          >
            <CloseIcon />
          </button>
        </Container>
      </Modal>
      <RegagriLayout
        withoutRedirect
        header={
          <RegagriHeader
            actions={
              <RegagriTank
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <LanguageNavigation />
                <RegagriActionsButtons.CancelButton
                  onClick={() => {
                    setIsModalOpen(true);
                    stopOverflow(true);
                  }}
                  text={t("regagri:cultivationDetails.header.0")}
                />
              </RegagriTank>
            }
            title={
              <h4 className="header-title">
                {t("regagri:cultivationDetails.header.1")}
              </h4>
            }
          />
        }
        footer={<Footer />}
      >
        <RegagriSection
          header={
            <>
              <Breadcrumbs
                path={[
                  {
                    name: t("navigation:navTools"),
                    url: "/narzedzia",
                  },
                ]}
                currentName={t("regagri:cultivationDetails.breadcrumb")}
              />
              <h3>{t("regagri:cultivationDetails.header.2")}</h3>
              <p>{t("regagri:cultivationDetails.header.3")}</p>
            </>
          }
        >
          <>
            <Form />
            <Modal
              isOpen={isModalOpen}
              onRequestClose={onClose}
              className="regagri-modal-container"
              style={{
                overlay: {
                  zIndex: "1000",
                  backgroundColor: "rgba(0,0,0,0.6)",
                  overflowY: "scroll",
                },
                content: {
                  maxWidth: "970px",
                  width: "auto",
                  margin: "auto",
                  borderRadius: 0,
                  bottom: "unset",
                  overflowX: "hidden",
                  backgroundColor: "#f1f1f1",
                },
              }}
            >
              <Container fluid className="tools-page__modal">
                <Image
                  src={modalImg}
                  alt={t("regagri:cultivationDetails.leavingModal.0")}
                />
                <h1>{t("regagri:cultivationDetails.leavingModal.0")}</h1>
                <p className="text">
                  {t("regagri:cultivationDetails.leavingModal.1")}
                </p>
                <Button
                  onClick={() => {
                    handleCloseModal();
                    history.push("/");
                  }}
                  buttonsize="small"
                  buttoncolor="green"
                  style={{ marginRight: "30px" }}
                >
                  {t("regagri:cultivationDetails.leavingModal.2")}
                </Button>
                <Button
                  onClick={handleCloseModal}
                  buttonsize="small"
                  buttoncolor="red"
                >
                  {t("regagri:cultivationDetails.leavingModal.3")}
                </Button>
              </Container>
            </Modal>
          </>
        </RegagriSection>
        <div id="regagri-report-portal" />
      </RegagriLayout>
    </div>
  );
}

export default CultivationDetails;

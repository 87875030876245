import React from "react";
import {
  Hero,
  Benefits,
  InsuranceScope,
  DownloadDocuments,
  Disclaimer,
  PackageTable,
  ContactForm,
  Headline,
  ScrollToSection,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement } from "../helpers/utils";

import fileA from "../../assets/prf/pdf/02 Generali Ubezpieczenie upraw Karta produktu.pdf";
import fileB from "../../assets/prf/pdf/03 Generali Ubezpieczenie upraw Ustandaryzowany dokument.pdf";
import fileC from "../../assets/prf/pdf/05 Generali Ubezpieczenie upraw OWU.pdf";

import imageUrl from "../assets/images/kredyt_35.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Ubezpieczenie upraw rolnych w sezonie wiosennym"
    ),
    title: "Ubezpieczenie upraw rolnych w sezonie wiosennym",
    description:
      "Potrzebujesz ochrony upraw, które stanowią podstawowe źródło Twoich dochodów? Skorzystaj z możliwości ubezpieczenia z dotacją z budżetu państwa aż do 65% składki z polisy.",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Dlaczego warto ubezpieczyć uprawy?",
    subtitle:
      "Wystarczy chwila, aby niesprzyjająca aura zniszczyła owoce Twojej ciężkiej pracy.",
    benefits: [
      {
        title:
          "Wypłata odszkodowania wg sum określonych na polisie – nawet w przypadku spadku cen płodów rolnych",
        icon: "MoneyPlus",
      },
      {
        title: "Odpowiedzialność za szkody na polu lub jego części",
        icon: "HandsParallel",
      },
      {
        title:
          "Solidna likwidacja szkód wykonywana wyłącznie przez fachowców z branży agro",
        icon: "CallcenterPerson",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak wykupić ubezpieczenie?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź ubezpieczenie spełniające Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe aby porozmawiać z Ekspertem ds. Ubezpieczeń lub odwiedź nas w Banku BNP.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Formalności załatwimy drogą elektroniczną, abyś Ty mógł spokojnie i bezpiecznie prowadzić  gospodarstwo",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DOWNLOAD_DOCUMENTS: {
    title: "Dokumenty do pobrania",
    files: [
      {
        name: "Karta produktu",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileA,
      },
      {
        name: "Ustandaryzowany dokument",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileB,
      },
      {
        name: "Ogólne Warunki Ubezpieczenia",
        details: "Produkt 2021, Plik .pdf 0.4mb",
        url: fileC,
      },
    ],
  },
  DISCLAIMER: {
    notes: [
      "Treści zawarte na niniejszej stronie mają charakter wyłącznie reklamowy i informacyjny oraz nie stanowią oferty w rozumieniu Kodeksu Cywilnego. Świadczenie ochrony ubezpieczeniowej następuje na warunkach określonych w umowie zawartej z Klientem za pośrednictwem Banku BNP Paribas, działającego jako agent ubezpieczeniowy, przez Generali Towarzystwo Ubezpieczeń S.A. oraz w Ogólnych Warunkach Ubezpieczeń. Szczegółowe warunki ubezpieczenia, w tym ograniczenia i wyłączenia odpowiedzialności z nimi związane, jak również informacje na temat zasad działania poszczególnych produktów i kosztów z nimi związanych określone są w obowiązujących dla danego produktu Ogólnych Warunkach Ubezpieczenia, przekazywanych każdorazowo klientowi przed zawarciem umowy ubezpieczenia. Generali Towarzystwo Ubezpieczeń S.A. z siedzibą w Warszawie przy ul. Postępu 15B, zarejestrowana w rejestrze przedsiębiorców prowadzonym przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 10623, kapitał zakładowy 191.000.000 PLN wpłacony w całości, NIP 526-23-49-108, należąca do Grupy Generali, figurującej w Rejestrze Grup Ubezpieczeniowych prowadzonym przez IVASS.",
    ],
  },
  INSURANCE_SCOPE_1: {
    title: "Przedmiot ubezpieczenia",
    items: [
      "ziarno lub nasiona np. w zbożach, rzepaku, kukurydzy",
      "kolby i nadziemne części roślin w kukurydzy przeznaczonej na paszę",
      "korzeń w burakach cukrowych i pastewnych oraz bulwy w ziemniakach",
      "części warzyw gruntowych przeznaczonych do konsumpcji",
      "owoce w owocach ziarnkowych",
    ],
    icon: "Crops",
  },
  INSURANCE_SCOPE_3: {
    title: "Zalety ubezpieczenia",
    description:
      "Ubezpieczenie tworzymy pod indywidualne potrzeby producenta rolnego. Powiedz jakiego ryzyka się obawiasz, a my dobierzemy najlepsze rozwiązanie. Rolnicy cenią sobie rozszerzenie ochrony o",
    items: [
      "odpowiedzialność za szkody już od 8% ubytku w plonie ",
      "wypłatę odszkodowania do 100% sumy ubezpieczenia ",
      "odpowiedzialność w deszczu nawalnym za zastoiska wodne do 30 września",
      "ubezpieczenie od ryzyka ognia, bez względu na przyczynę powstania szkody",
      "szkody jakościowe i ilościowe w owocach i warzywach",
    ],
    icon: "CropsShield",
    bg: "white",
    iconBg: "orange",
  },
  PACKAGE_TABLE: {
    packages: [
      {
        label: "Pakiet PLUS L",
        slug: "a",
      },
      {
        label: "Pakiet BAZA L",
        slug: "b",
      },
      {
        label: "Pakiet BONUS L",
        slug: "c",
      },
      {
        label: "Pakiet GRAD",
        slug: "xl",
      },
    ],
    filters: [
      {
        label: "buraki",
        includes: "a,b,c,1",
      },
      {
        label: "kukurydza",
        includes: "a,b,c,2",
      },
      {
        label: "rośliny oleiste",
        includes: "a,b,c,3",
      },
      {
        label: "rośliny strączkowe",
        includes: "a,b,c,4",
      },
      {
        label: "zboża jare",
        includes: "a,b,c,5",
      },
      {
        label: "zboża ozime",
        includes: "a,b,c,6",
      },
      {
        label: "ziemniaki",
        includes: "a,b,c,7",
      },
      {
        label: "owoce",
        includes: "xl,1,+",
      },
      {
        label: "warzywa",
        includes: "xl,1,-",
      },
    ],
    features: {
      a: [
        {
          label: "Grad",
          color: "mountainMeadow",
        },
        {
          label: "Przymrozki wiosenne",
          color: "shamrockGreen",
        },
        {
          label: "Deszcz nawalny i huragany",
          color: "darkSpringGreen",
        },
      ],
      b: [
        {
          label: "Grad",
          color: "mountainMeadow",
        },
        {
          label: "Przymrozki wiosenne",
          color: "shamrockGreen",
        },
        {
          label: "-",
          color: "brightGray",
        },
      ],
      c: [
        {
          label: "Grad",
          color: "mountainMeadow",
        },
        {
          label: "Deszcz nawalny i huragany",
          color: "darkSpringGreen",
        },
        {
          label: "-",
          color: "brightGray",
        },
      ],
      xl: [
        {
          label: "Grad",
          color: "mountainMeadow",
        },
        {
          label: "-",
          color: "brightGray",
        },
        {
          label: "-",
          color: "brightGray",
        },
      ],
    },
  },
};

const UbezpieczeniaUprawyJare = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_1} id="ofert" />
    <Headline mt={5} mb={4} title="Porównaj zakres ubezpieczenia" />
    <PackageTable
      {...COPY.PACKAGE_TABLE}
      filterTitle="Wybierz kategorię ubezepieczeń"
      disclaimer="Wszystkie warunki ubezpieczeń dostępne są w poniższych załącznikach"
    />
    <InsuranceScope {...COPY.INSURANCE_SCOPE_3} />
    <ScrollToSection
      onClick={() => {
        window.location.href = `/kredytomat/kontakt?product_url=${
          window.location.pathname
        }&product_name=Ubezpieczenie upraw rolnych w sezonie wiosennym`;
      }}
      text="Zapytaj o ofertę"
    />
    <TempShareButtonsComponent />
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <DownloadDocuments {...COPY.DOWNLOAD_DOCUMENTS} />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm id="contact" />
  </PageTemplate>
);

export default UbezpieczeniaUprawyJare;

import React from "react";
import {
  Button,
  Grid,
  Hero,
  Benefits,
  BenefitsTable,
  Disclaimer,
  LogosArval,
  ContactForm,
  Typography,
  List,
  Icon,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";

import imageUrl from "../assets/images/kredyt_3.png";
import imageUrl2 from "../assets/images/kredyt_5.png";
import { scrollToElement, onContactSubmit } from "../helpers/utils";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Wynajem ARVAL. Nowa forma użytkowania auta"),
    title: "Wynajem ARVAL. Nowa forma użytkowania auta",
    description:
      "Chcesz korzystać z nowego i dobrze wyposażonego samochodu, a przy tym nie martwić się o jego serwisowanie?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z najmu długoterminowego?",
    subtitle:
      "Najem długoterminowy pozwala na użytkowanie nowego samochodu za stałą miesięczną opłatą przez cały okres finansowania. Pozostałe zalety:",
    benefits: [
      {
        title: "Oszczędzasz pieniądze",
        description: "Brak wymogu wkładu własnego i opłaty wstępnej",
        icon: "GiftDollar",
      },
      {
        title: "Zyskujesz czas i wygodę",
        description: "Odpowiadamy za przeglądy techniczne i serwis samochodu",
        icon: "ArmchairDollar",
      },
      {
        title: "Jesteś bezpieczny",
        description:
          "Assistance 24h w kraju i na terenie UE, bez limitu odległości, gwarantowana pomoc w podróży",
        icon: "ClockDollar",
      },
    ],
  },
  LIST: {
    items: [
      "brak nagłych wydatków np. naprawy, wzrost składek za ubezpieczenie",
      "brak ryzyka szybkiej utraty wartości pojazdu",
    ],
  },
  LIST2: {
    title: "Nasza oferta",
    description:
      "Wybierz model, ustal parametry umowy i ciesz się nowym samochodem, a my gwarantujemy:",
    items: [
      "stałe koszty przez cały okres finansowania",
      "samochód zawsze w najlepszym stanie technicznym",
      "likwidacja szkód i samochód zastępczy",
    ],
  },
  SERVICES: [
    {
      title: "Obsługa serwisowa",
      description:
        "Wszystkie naprawy serwisowe, obsługę techniczną i przeglądy przeprowadzamy bezpłatnie w ramach Twojej miesięcznej raty.",
      icon: "Wrench",
    },
    {
      title: "Assistance",
      description: "Otrzymujesz opiekę Assistance 24 godziny na dobę.",
      icon: "CallcenterHeadset",
    },
    {
      title: "Samochód zastępczy",
      description:
        "W przypadku unieruchomienia Twojego pojazdu, możesz skorzystać z samochodu zastępczego.",
      icon: "Car",
    },
    {
      title: "Ubezpieczenie",
      description:
        "Wraz z samochodem otrzymujesz pełen pakiet ubezpieczeń: OC, AC oraz NNW.",
      icon: "Shield",
    },
    {
      title: "Karty paliwowe",
      description:
        "Bez względu na liczbę wynajmowanych samochodów otrzymujesz karty paliwowe, a także atrakcyjne rabaty na paliwo.",
      icon: "Distributor",
    },
    {
      title: "Aplikacja My Arval Mobile",
      description:
        "Dzięki aplikacji My Arval Mobile otrzymasz wsparcie w codziennym użytkowaniu Twojego samochodu np. przy wyszukiwaniu serwisu.",
      icon: "Mobile",
    },
    {
      title: "Finansowanie",
      description:
        "Otrzymujesz atrakcyjne finansowanie na okres od 30 do 60 miesięcy. Nie wymagamy wpłaty własnej.",
      icon: "Money",
    },
  ],
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description: "Ciesz się nowym samochodem w Twoim gospodarstwie!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego.",
      "Dostawcą usługi jest Arval Service Lease Polska Sp. z o.o. z siedzibą w Warszawie, przy ul. Wołoskiej 24 (zwany dalej „Arval”), wpisany do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000029819, NIP 521-30-33-865 o kapitale zakładowym w wysokości 11.500.000 złotych. Arval jest częścią grupy BNP Paribas. Bank BNP Paribas Spółka Akcyjna z siedzibą w Warszawie przy ul. Kasprzaka 2, 01-211 Warszawa, zarejestrowany w rejestrze przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod nr KRS 0000011571, posiadający NIP 526-10-08-546 oraz kapitał zakładowy w wysokości 147 418 918 zł, w całości wpłacony, jest podmiotem pośredniczącym w zawieraniu umów najmu w ramach programu Auto Plan.",
      "Arval zawiera Umowy Najmu z Klientami i świadczy usługi wynajmu na swoje wyłączne ryzyko i odpowiedzialność przy jednoczesnym zwolnieniu Banku z wszelkiej odpowiedzialności względem Arval lub osób trzecich z tytułu Umów Najmu i z odpowiedzialności wobec Arval za właściwe wypełnienie Umowy Najmu przez Klienta.",
    ],
  },
  BENEFITS_TABLE: {
    packages: [
      "Pakiet usług w cenie raty: ubezpieczenie, serwis",
      "brak nagłych wydatków np. naprawy, wzrost składek za ubezpieczenie",
      "brak ryzyka szybkiej utraty wartości pojazdu",
    ],
    features: [
      {
        title: "Wynajem długoterminowy",
        list: [
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="TickSharp" width="6rem" color="brandOrange" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="TickSharp" width="6rem" color="brandOrange" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="TickSharp" width="6rem" color="brandOrange" />
          </Grid.Box>,
        ],
      },
      {
        title: "KUPNO ",
        list: [
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
        ],
      },
      {
        title: "KREDYT",
        list: [
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
        ],
      },
      {
        title: "LEASING",
        list: [
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
          <Grid.Box display="flex" justifyContent="center" alignItems="center">
            <Icon name="X2" width="3.5rem" />
          </Grid.Box>,
        ],
      },
    ],
    action: {
      text: "ZAPYTAJ O OFERTĘ",
      onClick: () => {
        window.location.href = `/kredytomat/kontakt?product_url=${
          window.location.pathname
        }&product_name=Wynajem długoterminowy`;
      },
    },
  },
};

const ArvalNajemDlugoterminowy = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <Grid.Container>
      <Grid.Row>
        <Grid.Col lg={{ size: 6, offset: 3 }} pb="2rem">
          <Typography variant="h2" textAlign="center">
            PRZEWAGI WYNAJMU DŁUGOTERMINOWEGO AUT NAD KUPNEM, KREDYTEM I
            LEASINGIEM:
          </Typography>
          <div style={{ padding: "0 4rem" }}>
            <List {...COPY.LIST} />
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />

    <Grid.Container fluid background="#ececec">
      <Grid.Container py="4rem">
        <Grid.Row>
          <Grid.Col lg={{ size: 6, offset: 3 }}>
            <Typography variant="h2" textAlign="center" isUpperCase mb="3rem">
              Twoja miesięczna rata w wynajmie zawiera:
            </Typography>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {COPY.SERVICES.map(service => (
            <Grid.Col lg={3}>
              <div
                style={{
                  width: "74px",
                  height: "74px",
                  backgroundColor: "#ffa203",
                  borderRadius: "50%",
                  padding: "0.5rem",
                  color: "white",
                }}
              >
                <Icon name={service.icon} />
              </div>
              <Typography variant="h5" color="brandGreen" mt="1rem">
                {service.title}
              </Typography>
              <Typography color="darkGreen" mb="3rem">
                {service.description}
              </Typography>
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>

    <Grid.Container fluid>
      <Grid.Container pt="4rem" pb="1rem">
        <Grid.Row>
          <Grid.Col lg={{ size: 4, offset: 2 }}>
            <List {...COPY.LIST2} />
          </Grid.Col>
          <Grid.Col lg={{ size: 3, offset: 1 }}>
            <div
              style={{
                width: "100%",
                height: "0",
                paddingTop: "100%",
                background: `no-repeat center/cover url(${imageUrl2})`,
              }}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>
    <LogosArval
      mb="2rem"
      onClick={() => window.open("https://www.arval.pl/", "_blank").focus()}
    />

    <Grid.Container fluid background="#00965e">
      <Grid.Container py="4rem">
        <Grid.Row>
          <Grid.Col lg={{ size: 6, offset: 3 }}>
            <Typography
              variant="h2"
              color="white"
              textAlign="center"
              isUpperCase
              mb="1rem"
            >
              Najem dopasowany do Twoich potrzeb
            </Typography>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={{ size: 12 }}>
            <Typography textAlign="center" color="white" mb="1rem">
              Zapoznaj się z powyższą ofertą, parametrami najmu i wybierz
              samochód najbardziej dopasowany do Twojej potrzeby.
              <br />
              Jeśli masz dodatkowe pytania, pozostaw dane kontaktowe.
              Oddzwonimy.
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                isPrimary
                onClick={() => {
                  window.location.href = `/kredytomat/kontakt?product_url=${
                    window.location.pathname
                  }&product_name=x`;
                }}
              >
                Zostaw dane kontaktowe
              </Button>
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>

    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} mb="2rem" />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default ArvalNajemDlugoterminowy;

import React, { useState } from "react";
import PropTypes from "prop-types";

const CftCoopSection = ({ children, isDefaultOpen }) => {
  const [isOpen, setOpen] = useState(isDefaultOpen);
  return (
    <div className="cft-coop-section">{children({ isOpen, setOpen })}</div>
  );
};

export default CftCoopSection;

CftCoopSection.propTypes = {
  children: PropTypes.element.isRequired,
  isDefaultOpen: PropTypes.bool,
};

CftCoopSection.defaultProps = {
  isDefaultOpen: false,
};

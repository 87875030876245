import { useTranslation } from "react-i18next";
import slugify from "slugify";

import allCategoriesJSON from "./categories.json";
import categoriesNames from "./categoriesNames.json";
import { AGRO_ON_HEELS } from "./categoriesZones";

function useCategories() {
  const { t } = useTranslation(["urlsNames", "articlesMeta"]);

  const getUrl = id => {
    if (AGRO_ON_HEELS.indexOf(id) !== -1) {
      return `${t("urlsNames:articles.all")}/dla-kobiet`;
    }

    return t("urlsNames:articles.all");
  };

  const getCustomSlug = (id, slug) => {
    switch (id) {
      case 68:
        return "";
      case 72:
        return "/food-and-agro-conference";
      case 53:
        return "/prasa";
      default:
        return `/${slug}`;
    }
  };

  const parseCategories = (categories = []) => {
    return categories
      .filter(
        ({ active, id, membership }) => active && id > 17 && membership === 1
      )
      .map(category => {
        const subcategories = category.subcategories.map(subcategory => {
          const name = t(`articlesMeta:categories.${subcategory.id}`);
          const slug = slugify(
            categoriesNames[subcategory.id],
            "-"
          ).toLowerCase();
          return {
            id: subcategory.id,
            parent: category.id,
            path: `${getUrl(category.id)}/${slug}`,
            subcategories: [],
            name,
            slug,
          };
        });

        const name = t(`articlesMeta:categories.${category.id}`);
        const slug = slugify(categoriesNames[category.id], "-").toLowerCase();
        return {
          id: category.id,
          subcategories_ids: subcategories.reduce(
            (acc, curr) => [...acc, curr.id],
            []
          ),
          path: `${getUrl(category.id)}${getCustomSlug(category.id, slug)}`,
          name,
          slug,
          subcategories,
        };
      });
  };

  const categories = parseCategories(allCategoriesJSON);

  const getFilteredCategories = (id = 0) => {
    const cat = categories
      .filter(
        category =>
          [72, 90].indexOf(category.id) !== -1 ||
          category.subcategories.length > 0
      )
      .find(
        category =>
          category.id === id || category.subcategories_ids.indexOf(id) !== -1
      );

    if (!cat) {
      return [];
    }
    return [cat, ...cat.subcategories];
  };

  const getParentCategory = (id = 0) =>
    categories
      .filter(
        category =>
          [72, 90].indexOf(category.id) !== -1 ||
          category.subcategories.length > 0
      )
      .find(
        category =>
          category.id === id || category.subcategories_ids.indexOf(id) !== -1
      );

  const getCategory = (id = 0) =>
    categories.find(category => category.id === id);

  return {
    categories,
    getFilteredCategories,
    getParentCategory,
    getCategory,
  };
}

export default useCategories;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftTank,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import FuelAndEnergyFarmlandsForm, {
  FuelAndEnergyFarmlandsFormContext,
} from "../../forms/farmlands/FuelAndEnergyFarmlandsForm/FuelAndEnergyFarmlands.form";

function FuelAndEnergyPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveFarmlands, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={42}
          cropName={meta.title.farmlands}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="farmlands" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveFarmlands({
                    fuelAndEnergy: values,
                  });
                  history.push(routes.FARMLANDS.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:fuelAndEnergy.title", "Bezpośrednie zużycie energii")}
          onBack={() => history.push(routes.FARMLANDS.FERTILIZERS)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>
              {t("cft:fuelAndEnergy.title", "Bezpośrednie zużycie energii")}
            </h3>
            <p>
              {t(
                "cft:fuelAndEnergy.subtitle",
                "Wprowadź dane dotyczące energii elektrycznej i paliw używanych do produkcji roślinnej i ich przetwarzania w gospodarstwie."
              )}
            </p>
          </>
        }
      >
        <FuelAndEnergyFarmlandsForm />
        <CftReportProgress type="farmlands" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <FuelAndEnergyFarmlandsFormContext>
      <FuelAndEnergyPage />
    </FuelAndEnergyFarmlandsFormContext>
  );
};

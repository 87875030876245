/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      if (handler) {
        handler(event);
      }
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

const SearchBar = () => {
  const history = useHistory();
  const { t } = useTranslation(["globals"]);
  const ref = useRef(null);

  const onSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    const {
      target: { search },
    } = e;

    history.push(`/s?s=${search.value}`);
  };

  return (
    <form
      onSubmit={onSubmit}
      ref={ref}
      className={`search-bar search-bar--active`}
    >
      <label>
        <input 
          name="search" 
          type="text" 
          autoComplete="off" 
          autoFocus 
          placeholder={t('globals:searchbar.placeholder')}
        />
      </label>
    </form>
  );
};

export default SearchBar;

/* eslint-disable jsx-a11y/label-has-for */
import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import "./AmountInput.scss";

// inputstate - define input state: active, error
// haserror - define if input has correcr values
// errorMsg - pass error message
// icon - define icon that is displayed in input

export const AmountInput = props => {
  return (
    <div
      className={
        props.inputstate
          ? `input input--amount input--${props.inputstate}`
          : "input input--amount"
      }
    >
      <label htmlFor={props.id}>
        <div className="input-label-text">{props.label}</div>
        <div className="input-field">
          <NumberFormat
            id={props.id}
            type="text"
            thousandSeparator=" "
            allowNegative={false}
            decimalSeparator=","
            decimalScale={props.decimalScale}
            allowEmptyFormatting={false}
            isNumericString
            {...props}
          />
          <div className="input-icon-container">{props.icon}</div>
        </div>
        {props.haserror ? (
          <p className="input-error">{props.errormsg}</p>
        ) : null}
      </label>
    </div>
  );
};

AmountInput.propTypes = {
  inputstate: PropTypes.string,
  label: PropTypes.string.isRequired,
  haserror: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errormsg: PropTypes.string,
  id: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  decimalScale: PropTypes.number,
};

AmountInput.defaultProps = {
  inputstate: "",
  haserror: String(false),
  errormsg: "",
  icon: "",
  decimalScale: 2,
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { isEmpty } from "ramda";
import { Row, Col, Spinner } from "reactstrap";
import { isMobile } from "react-device-detect";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFormControl,
  CftTank,
  CftActionsButtons,
  CftReport,
  CftCard,
  CftTable,
  CftGraph,
  CftGroup,
  CftReportNavigation,
  GasEmissionNav,
  CftCostStructure,
  CostStructureNav,
  CftMobileReportWrapper,
  SpecificInformationNav,
  CftExportReport,
  CftInfoBox,
  CftReportDetailsSection,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import { farmlandsAPI } from "../../utils/cftApi";
import useFarmlandsDetailsReport from "../../utils/cftFarmlandsDetailsReport";

import { nFormatter } from "../../utils/cftUtils";

function ReportFarmlandsPage() {
  const [data, setData] = useState([]);
  const [report, setReport] = useState(null);
  const { t, i18n } = useTranslation(["cft"]);
  const history = useHistory();
  const { meta, farmlandsReport } = useCft();
  const [isPending, setPending] = useState(false);
  const { details } = useFarmlandsDetailsReport(farmlandsReport);
  const isValid =
    !isEmpty(farmlandsReport.crop) && !isEmpty(farmlandsReport.soil);

  const getFactor = scale => {
    const x = scale.max === 0 || scale.min === 0 ? 280 : 150;
    const value =
      Math.abs(scale.min) > Math.abs(scale.max)
        ? Math.abs(scale.min)
        : Math.abs(scale.max);

    return x / value;
  };

  useEffect(() => {
    if (isValid) {
      setPending(true);
      farmlandsAPI
        .report(farmlandsReport)
        .then(({ data }) => {
          setReport(data);
          const scale = data.total_emissions.reduce(
            (prev, curr) => {
              const min =
                prev.min < curr.total_co2e ? prev.min : curr.total_co2e;
              const max =
                prev.max > curr.total_co2e ? prev.max : curr.total_co2e;

              return {
                min,
                max,
              };
            },
            {
              min: 0,
              max: 0,
            }
          );

          const factor = getFactor(scale);

          const parsedData = data.total_emissions.map(
            ({ name, total_co2e }) => ({
              factor,
              value: total_co2e * factor,
              rawValue: total_co2e,
              label: name,
              legend: nFormatter(total_co2e, i18n.language),
              scale,
            })
          );

          setData(parsedData);
        })
        .finally(() => {
          setPending(false);
        });
    }
  }, []);

  return (
    <CftLayout
      header={
        <>
          <CftHeader />
          {isMobile && (
            <div
              style={{
                position: "fixed",
                top: "1%",
                zIndex: "1000",
                right: "1%",
              }}
            >
              <CftExportReport type="farmlands" isSmall noMargin />
            </div>
          )}
          <CftReportNavigation type="farmlands" />
        </>
      }
    >
      <CftSection
        isReport
        header={
          <CftTank
            className="report-hero"
            flexDirection="column"
            alignItems="flex-start"
          >
            <CftActionsButtons.GoToCalc
              onClick={() => history.push(routes.FARMLANDS.MAIN)}
            />
            <h3>
              {t("cft:report.title", "Raport emisji")} CO<sub>2</sub>
            </h3>
            <p className="full-width">{meta.title.farmlands}</p>
          </CftTank>
        }
      >
        {!isValid && (
          <CftFormControl variant="small">
            <h3 className="cft-headline">
              {t("cft:report.missingData", "Brak danych")}

              <small>
                {t(
                  "cft:report.requirements",
                  "Do wygenerowania raportu wymagane są następujące dane: Szczegóły uprawy oraz Charakterystyka gleby"
                )}
              </small>
            </h3>
          </CftFormControl>
        )}
        {isValid && isPending && (
          <CftTank justifyContent="center" mb="2rem">
            <Spinner color="success" />
          </CftTank>
        )}
        {report && (
          <div id="report">
            <CftGroup header={<GasEmissionNav />} id="gasEmission">
              <CftCard>
                <Row>
                  <Col xs={12} md={4} lg={3}>
                    <CftReport.Box paddingRight={0}>
                      <CftReport.Summary
                        data={[
                          {
                            value: nFormatter(
                              report.summary.emissions_total.value,
                              i18n.language
                            ),
                            unit: report.summary.emissions_total.unit,
                            title: t(
                              "cft:report.summary.totalEmmisions",
                              "Całkowita emisja"
                            ),
                          },
                          {
                            value: nFormatter(
                              report.summary.emissions_per_area.value,
                              i18n.language
                            ),
                            unit: report.summary.emissions_per_area.unit,
                            title: t(
                              "cft:report.summary.emissionsPerArea",
                              "Emisje na obszar"
                            ),
                          },
                          {
                            value: nFormatter(
                              report.summary.emissions_per_product.value,
                              i18n.language
                            ),
                            unit: report.summary.emissions_per_product.unit,
                            title: t(
                              "cft:report.summary.emissionsPerProduct",
                              "Emisje na produkt"
                            ),
                          },
                        ]}
                      />
                    </CftReport.Box>
                  </Col>
                  <Col xs={12} md={8} lg={9}>
                    <CftReport.Box>
                      <CftMobileReportWrapper>
                        <CftGraph data={data} />
                      </CftMobileReportWrapper>
                    </CftReport.Box>
                  </Col>
                </Row>
                <CftReport.Box paddingTop={0}>
                  <CftReport.Divider />
                  <CftReport.Title>
                    {t("cft:report.title", "Szczegółowe dane")}
                  </CftReport.Title>
                </CftReport.Box>
                <CftMobileReportWrapper>
                  <CftTable data={report.total_emissions} />
                </CftMobileReportWrapper>
                <CftReport.Box>
                  <CftInfoBox>
                    {t("cft:report.info1")}
                    {"\n"}
                    {t("cft:report.info2")}
                  </CftInfoBox>
                </CftReport.Box>
              </CftCard>
            </CftGroup>
            {/* <CftGroup header={<PerformancenNav />} id="performancen">
              <CftCard>
                <CftReport.Box />
              </CftCard>
            </CftGroup> */}
            <CftGroup
              header={<SpecificInformationNav />}
              id="specificInformation"
            >
              <CftCard>
                <CftReportDetailsSection data={details} />
              </CftCard>
            </CftGroup>
            <CftGroup header={<CostStructureNav />} id="costStructure">
              <CftCostStructure name={meta.title.farmlands} />
            </CftGroup>
            <p className="cft-disclaimer">
              {t(
                "cft:report.disclaimer",
                "Niniejszy dokument jest jedynie materiałem informacyjnym do użytku odbiorcy. Nie może on być uznany za rekomendację do dokonania jakiejkolwiek inwestycji. Wszystkie opinie i prognozy wyrażone w niniejszym dokumencie są wyrazem oceny autorów w dniu publikacji i mogą ulec zmianie bez zapowiedzi. Żadna część jak i całość utworów zawartych w publikacji nie może być powielana i rozpowszechniania lub dalej rozpowszechniana w jakiejkolwiek formie i w jakikolwiek sposób (w tym także elektroniczny, mechaniczny lub inny albo na wszelkich polach eksploatacji) włącznie z szeroko pojętą digitalizacją, fotokopiowaniem lub kopiowaniem, w tym także zamieszczaniem w Internecie - bez uprzedniej pisemnej zgody BNP Paribas Bank Polska S.A. z siedzibą w Warszawie. Jakiekolwiek użycie lub wykorzystanie z naruszeniem prawa utworów zawartych w publikacji, w całości lub w części, bez uprzedniej pisemnej zgody BNP Paribas Bank Polska S.A. jest zabronione pod groźbą kary i może być ścigane prawnie."
              )}
            </p>
          </div>
        )}
      </CftSection>
    </CftLayout>
  );
}

export default ReportFarmlandsPage;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./CftLabel.scss";

function CftLabel({
  children,
  label,
  className,
  innerStyle,
  childrenPosition,
  isFullWidth,
  ...props
}) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
    <label
      className={classNames(
        "cft-label",
        {
          "cft-label__with-children": children !== null,
          "cft-label__is-full-width": isFullWidth,
        },
        className
      )}
      {...props}
    >
      {childrenPosition === "left" && children}
      <span className="cft-label__label" style={innerStyle}>
        {label}
      </span>
      {childrenPosition === "right" && children}
    </label>
  );
}

CftLabel.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node.isRequired,
  childrenPosition: PropTypes.oneOf(["left", "right"]),
  isFullWidth: PropTypes.bool,
};

CftLabel.defaultProps = {
  children: null,
  childrenPosition: "right",
  isFullWidth: false,
};

export default CftLabel;

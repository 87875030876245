import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from "history";
import ErrorPage from "./views/ErrorPage/ErrorPage";
import { routes } from "../services/helpers/routes";
import { gtmRun, pixelRun } from "../services/helpers/tracers";
import useCategories from "./categories/useCategories";
import ArticlesPage from "./views/ArticlesPage/ArticlesPage";
import cftRouting from "../cft/utils/cftRouting";
import prfRouting from "../prf/prfRouting";
import regagriRouting from "../regagri/utils/regagriRouting";
import { MARKET_AGREGATOR_CATEGORY } from "../services/helpers/articles";
import MarketArticlesPage from "./views/ArticlesPage/MarketArticlesPage";
import { AGRO_ON_HEELS } from "./categories/categoriesZones";
import avgPricesRouting from "./averagePrices/avgPricesRouting";
// import TechnicalBreakPage from "./views/TechnicalBreakPage/TechnicalBreakPage";

const history = createBrowserHistory();

function AppRouting() {
  const { categories } = useCategories();

  useEffect(() => {
    let prevLocation = { ...window.location };
    history.listen(() => {
      const pathChanged = prevLocation.pathname !== window.location.pathname;
      if (pathChanged) {
        window.scrollTo(0, 0);
      }
      prevLocation = { ...window.location };
      setTimeout(() => {
        gtmRun(window.location.pathname);
        pixelRun();
      }, 5000);
    });
  }, []);

  const getPageComponent = (category, ownProps) => {
    if (MARKET_AGREGATOR_CATEGORY.categoriesList.includes(category.id)) {
      return (
        <MarketArticlesPage
          category={category}
          parentCategory={MARKET_AGREGATOR_CATEGORY}
        />
      );
    }

    if (AGRO_ON_HEELS.includes(category.id)) {
      const withoutParent = AGRO_ON_HEELS.slice(1, AGRO_ON_HEELS.length);
      const parentCategory = {
        id: 68,
        subcategories: withoutParent.map(id =>
          categories.find(category => category.id === id)
        ),
        subcategories_ids: withoutParent,
      };

      return (
        <ArticlesPage
          {...ownProps}
          category={category}
          parentCategory={parentCategory}
        />
      );
    }

    const parentCategory = categories.find(
      ({ subcategories_ids }) => subcategories_ids.indexOf(category.id) !== -1
    );

    return (
      <ArticlesPage
        {...ownProps}
        category={category}
        parentCategory={parentCategory}
      />
    );
  };

  const routeComponents = routes.map(({ path, component }, key) => (
    <Route exact path={path} component={component} key={key} />
  ));

  const routeCategoriesComponents = categories.map(category => (
    <Route
      key={category.id}
      exact
      path={category.path}
      component={props => getPageComponent(category, props)}
    />
  ));
  const routeSubcategoriesComponents = [];
  categories.forEach(category => {
    category.subcategories.forEach(subcategory => {
      routeSubcategoriesComponents.push(
        <Route
          key={subcategory.id}
          exact
          path={subcategory.path}
          component={props => getPageComponent(subcategory, props)}
        />
      );
    });
  });

  const cftRouteComponents = cftRouting.map(({ path, component }, key) => (
    <Route exact path={path} component={component} key={key} />
  ));

  const avgPricesComponents = avgPricesRouting.map(
    ({ path, component }, key) => (
      <Route exact path={path} component={component} key={key} />
    )
  );

  const prfRouteComponents = prfRouting.map(({ path, component }, key) => (
    <Route exact path={path} component={component} key={key} />
  ));

  const regagriRoutingComponents = regagriRouting.map(
    ({ path, component }, key) => (
      <Route exact path={path} component={component} key={key} />
    )
  );

  return (
    <Router history={history}>
      <Switch>
        {routeCategoriesComponents}
        {routeSubcategoriesComponents}
        {routeComponents}
        {cftRouteComponents}
        {avgPricesComponents}
        {prfRouteComponents}
        {regagriRoutingComponents}
        <Redirect from="/food-and-agro" to="/artykuly/analizy-food-agro" />
        {/* <Route exact path="/pracetechniczne" component={TechnicalBreakPage} /> */}
        <Route component={ErrorPage} />
      </Switch>
    </Router>
  );
}

export default AppRouting;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import { useField } from "formik";
import cl from "classnames";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { parse, getDay } from "date-fns";
import CftError from "../CftError/CftError";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";

import "react-datepicker/dist/react-datepicker.css";
import "./CftDatePicker.scss";

const days = ["Pn", "Wt", "Śr", "Czw", "Pt", "Sb", "Nd"];
const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];

const localePL = {
  localize: {
    day: n => days[n],
    month: n => months[n],
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
  },
};

function CftDatePicker({ name, placeholder, scope }) {
  const [field, meta, helpers] = useField(name);
  const { i18n } = useTranslation();
  const { min, interval } = scope;
  const ref = useRef();

  const CallendarContainer = ({ children }) => {
    return <div className="calendar">{children}</div>;
  };

  return (
    <div
      className={cl("cft-datepicker-field", {
        "cft-datepicker-field--isInvalid": meta.touched && meta.error,
      })}
    >
      <div className="datepicker-container">
        <DatePicker
          ref={ref}
          name={name}
          placeholderText={placeholder}
          minDate={min}
          onKeyDown={e => {
            e.preventDefault();
          }}
          excludeDates={[min]}
          filterDate={d => {
            if (interval) {
              const day = getDay(min);
              return getDay(d) === day;
            }
            return true;
          }}
          onCalendarOpen={() => {
            if (ref.current && isMobile) {
              if (ref.current.isCalendarOpen) {
                document.querySelector("body").classList.add("block-scroll");
              }
            }
          }}
          onCalendarClose={() => {
            document.querySelector("body").classList.remove("block-scroll");
          }}
          selected={field.value ? parse(field.value) : ""}
          onChange={date => helpers.setValue(date)}
          dateFormat="dd.MM.yyyy"
          locale={i18n.language === "pl" ? localePL : "en"}
          calendarContainer={CallendarContainer}
          popperModifiers={[
            {
              name: "preventOverflow",
              options: {
                rootBoundary: "viewport",
                tether: false,
                altAxis: true,
              },
            },
          ]}
        />
        <CalendarIcon
          onClick={() => {
            if (ref.current && !ref.current.isCalendarOpen()) {
              ref.current.setFocus();
            }
          }}
        />
      </div>
      <CftError isActive={meta.touched && meta.error} message={meta.error} />
    </div>
  );
}

CftDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

CftDatePicker.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  scope: {
    min: undefined,
    interval: false,
  },
};

export default CftDatePicker;

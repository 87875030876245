import React from "react";
import PropTypes from "prop-types";

function CftConditionalRendering({ isConditionFulfilled, children }) {
  if (!isConditionFulfilled) {
    return null;
  }

  return <>{children}</>;
}

CftConditionalRendering.propTypes = {
  isConditionFulfilled: PropTypes.bool,
};

CftConditionalRendering.defaultProps = {
  isConditionFulfilled: false,
};

export default CftConditionalRendering;

import propertiesReducer from "./properties.reducer";
import propertiesActions from "./properties.actions";
import propertiesSelectors from "./properties.selectors";
import propertiesApi from "./properties.api";

export default {
  reducer: propertiesReducer,
  selectors: propertiesSelectors,
  actions: propertiesActions,
  api: propertiesApi,
};

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    fertilisers: values.fertilizers.map(
      ({ rate, productions, fertiliser, inhibitor, method }) => ({
        fertiliser_type_id: Number(fertiliser),
        production_id: Number(productions),
        application_rate: {
          value: dotFormatter(rate.value),
          unit_id: rate.unit,
        },
        application_method_id: Number(method),
        inhibition: inhibitor,
      })
    ),
    pesticides: values.plantProtectionProducts.map(
      ({ applicationRate, percentageRate, category, events }) => {
        return {
          category_id: Number(category),
          applications: events ? dotFormatter(events) : 0,
          application_rate: {
            value: applicationRate ? dotFormatter(applicationRate) : 0,
            unit_id: "24",
          },
          percentage_rate: percentageRate ? dotFormatter(percentageRate) : 0,
        };
      }
    ),
  };
}

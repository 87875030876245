import React from "react";

function ConditionalRender({ isConditionFulfilled, children }) {
  if (isConditionFulfilled) {
    return <>{children}</>;
  }
  return null;
}

ConditionalRender.defaultProps = {
  isConditionFulfilled: true,
};

export default ConditionalRender;

import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PageTemplate from "../helpers/PageTemplate";
import imageUrl from "../assets/images/kredyt_36.png";
import { useProduct } from "../api/prf.api";
import PrfProduct from "../helpers/PrfProduct";

const PageViewer = () => {
  const { slug } = useParams();
  const { isError, isLoading, product } = useProduct(slug);
  const {
    i18n: { language },
  } = useTranslation();

  if (isLoading) {
    return null;
  }

  if (isError && !product) {
    return <Redirect to="/404" />;
  }

  return (
    <PageTemplate
      meta={{
        name: `${product.title[language]} - Kredytomat`,
        description: `${product.meta[language]} - Kredytomat`,
        mediaCoverUrl: imageUrl,
      }}
    >
      <PrfProduct page={product.body} language={language} />
    </PageTemplate>
  );
};

export default PageViewer;

/* eslint-disable no-undef */
import * as actions from "./actions";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  isError: false,
  invitations: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    isError: false,
  },
};

export default function userEvents(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_USER_EVENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        ...data,
        isError: false,
      };
    }

    case actions.GET_USER_EVENTS_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }

    case actions.GET_USER_RESTRICTED_EVENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        invitations: {
          ...data,
          isError: false,
        },
      };
    }

    case actions.RESET_USER_EVENTS: {
      return {
        ...InitialState,
      };
    }

    default:
      return state;
  }
}

export const getUserEvents = (token, id, query = "") => ({
  type: actions.GET_USER_EVENTS,
  payload: {
    request: {
      url: `/api/v1/users/${id}/events/${query}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

export const getUserRestrictedEvents = (token, past) => ({
  type: actions.GET_USER_RESTRICTED_EVENTS,
  payload: {
    request: {
      url: `/api/v1/events/?event__invitation_required=true&past=${past}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  },
});

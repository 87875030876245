import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import CharacteristicsDPForm, {
  CharacteristicsDPFormContext,
} from "../../forms/dairyProduction/CharacteristicsDPForm/CharacteristicsDP.form";
import { mapCattleGrazingHerd } from "../../forms/dairyProduction/CattleGrazingDPForm/cattleGrazingDP.utils";

function CharacteristicsPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { saveDairyProduction, dairyProduction } = useCft();
  const { submitForm, values, isSubmitting } = useFormikContext();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={14}
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveDairyProduction({
                    characteristics: values,
                    cattleGrazing: {
                      ...dairyProduction.cattleGrazing,
                      herd: mapCattleGrazingHerd(
                        values.cattleHerd,
                        dairyProduction.cattleGrazing.herd
                      ),
                    },
                  });
                  history.push(routes.DAIRY_PRODUCTION.MAIN);
                }}
              />
              <CftExportReport type="dairyProduction" />
            </div>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:characteristics.title", "Charakterystyka stada")}
          onBack={() => history.push(routes.DAIRY_PRODUCTION.DETAILS)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:characteristics.title", "Charakterystyka stada")}</h3>
            <p>
              {t(
                "cft:characteristics.subtitle",
                "Proszę podać średnią liczbę zwierząt w gospodarstwie w roku referencyjnym, liczbę sprzedanych zwierząt oraz liczbę zakupionych."
              )}
            </p>
          </>
        }
      >
        <CharacteristicsDPForm />
        <CftReportProgress type="dairyProduction" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <CharacteristicsDPFormContext>
      <CharacteristicsPage />
    </CharacteristicsDPFormContext>
  );
};

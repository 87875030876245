import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftButton,
  CftTank,
} from "../../components";
import { ReactComponent as ClockIcon } from "../../../assets/icons/cft/kalkukacja.svg";
import routes from "../../utils/cftRoutes";

import "../styles/intro.scss";

export default function IntroFarmlandsPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();

  return (
    <CftLayout
      header={<CftHeader cropName={t("cft:types.farmlands", "Pola uprawne")} />}
    >
      <CftSection
        header={
          <h3>
            {t("cft:intro.title", "Już za moment rozpoczniesz KALKULACJĘ...")}
          </h3>
        }
      >
        <div className="intro-page">
          <CftTank mb={4}>
            <ClockIcon />
          </CftTank>
          <p>
            {t(
              "cft:intro.p1",
              "Wykonanie kalkulacji zajmie kilkanaście minut. W każdej chwili możesz wyjść z kalkulatora,"
            )}
            <b>
              {" "}
              {t(
                "cft:intro.b",
                "a postęp kalkulacji możesz zachować na swoim komputerze."
              )}
            </b>
          </p>
          <p>
            {t(
              "cft:intro.p2",
              "Na koniec kalkulacji wygenerujuemy raport, który będziesz mógł zapisać na swoim komputerze a później wrócić do niego i zmodyfikować w razie potrzeby."
            )}
          </p>
          <CftButton
            variant="active"
            onClick={() => {
              switch (history.location.pathname) {
                case routes.FARMLANDS.INTRO: {
                  history.push(routes.FARMLANDS.DETAILS);
                  break;
                }
                case routes.FARMLANDS.WATER_FOOTPRINT.INTRO: {
                  history.push(routes.FARMLANDS.WATER_FOOTPRINT.DETAILS);
                  break;
                }
                case routes.FARMLANDS.BOTH_CALC.INTRO: {
                  history.push(routes.FARMLANDS.BOTH_CALC.DETAILS);
                  break;
                }
                default:
                  break;
              }
            }}
          >
            {t("cft:start", "rozpocznij")}
          </CftButton>
        </div>
      </CftSection>
    </CftLayout>
  );
}

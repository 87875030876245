export const GET_USER_EVENTS = "GET_USER_EVENTS";
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS";
export const GET_USER_EVENTS_FAIL = "GET_USER_EVENTS_FAIL";

export const GET_USER_RESTRICTED_EVENTS = "GET_USER_RESTRICTED_EVENTS";
export const GET_USER_RESTRICTED_EVENTS_SUCCESS =
  "GET_USER_RESTRICTED_EVENTS_SUCCESS";
export const GET_USER_RESTRICTED_EVENTS_FAIL =
  "GET_USER_RESTRICTED_EVENTS_FAIL";

export const RESET_USER_EVENTS = "RESET_USER_EVENTS";

import React from "react";
import {
  Grid,
  Hero,
  Typography,
  TileInvest,
  Disclaimer,
  ContactForm,
} from "agronomist-ui-components";
import { generatePath } from "react-router-dom";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import { MAIN_SEEDS } from "../consts/seeds";
import { WIZARD_URL } from "../consts/consts";
import { onContactSubmit } from "../helpers/utils";
import imageUrl from "../assets/images/main3.png";
import mobileImageUrl from "../assets/images/main2_rwd.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs(
      "Nowe perspektywy rozwoju twojego gospodarstwa"
    ),
    title: "Nowe perspektywy rozwoju twojego gospodarstwa",
    description:
      "Poszukujesz finansowania, prostego i szybkiego dostępu do rachunku poprzez bankowość elektroniczną, chciałbyś ubezpieczyć swój majątek lub porozmawiać o swoich potrzebach z Ekspertem Agro?",
    imageUrl,
    mobileImageUrl,
  },
  TILES_INVEST: [
    {
      icon: "Lands",
      title: "Chcę kupić grunty",
      url: "/kredytomat/kredyt-inwestycyjny-na-zakup-ziemi",
    },
    {
      icon: "Tractor",
      title: "Chcę kupić maszyny i urządzenia do gospodarstwa",
      url: generatePath(WIZARD_URL, {
        seed: MAIN_SEEDS.expandMyFarm,
        grain: 1,
      }),
    },
    {
      icon: "BarnPlus",
      title: "Chcę rozpocząć budowę, rozbudowę albo modernizacje gospodarstwa",
      url: generatePath(WIZARD_URL, {
        seed: MAIN_SEEDS.expandMyFarm,
        grain: 2,
      }),
    },
    {
      icon: "Farm",
      title: "Chcę kupić gospodarstwo",
      url: "/kredytomat/kredyt-inwestycyjny-na-zakup-gospodarstwa-rolnego",
    },
    {
      icon: "PhotovoltaicsSun",
      title: "Chcę kupić instalację fotowoltaiczną",
      url: generatePath(WIZARD_URL, {
        seed: MAIN_SEEDS.expandMyFarm,
        grain: 3,
      }),
    },
    {
      icon: "Cows",
      title: "Chcę kupić stado podstawowe",
      url: "/kredytomat/kredyt-na-zakup-stada-podstawowego",
    },
    {
      icon: "PlantationSnowflake",
      title: "Chcę założyć plantacje wieloletnie",
      url: generatePath(WIZARD_URL, {
        seed: MAIN_SEEDS.expandMyFarm,
        grain: 5,
      }),
    },
    {
      icon: "Irrigation",
      title: "Chcę zrealizować inwestycję w nawadnianie",
      url: generatePath(WIZARD_URL, {
        seed: MAIN_SEEDS.expandMyFarm,
        grain: 6,
      }),
    },
    {
      icon: "Car",
      title: "Chcę wynająć samochód długoterminowo",
      url: "/kredytomat/wynajem-arval",
    },
  ],
  DISCLAIMER: {
    notes: [
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych oraz ochrony ubezpieczeniowej następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem oraz w Ogólnych Warunkach Ubezpieczenia. Bank BNP Paribas działa jako agent ubezpieczeniowy Towarzystwa Ubezpieczeń na Życie Cardif Polska S.A. z siedzibą w Warszawie przy Pl. Piłsudskiego 2. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='https://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
};

const KredytInwestycyjny = () => (
  <PageTemplate
    meta={{
      name: "Kredyt na rozwój gospodarstwa rolnego",
      description:
        "Korzystny kredyt na rozwój gospodarstwa jest na wyciąnięcie ręki. Powiedz nam w co chcesz zainwestować i znajdź idealną opcję - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} isImageFullSize isLight pt="3rem" pb="7rem" />
    <Grid.Container fluid>
      <Grid.Container py="4rem">
        <Typography variant="h2" isUpperCase textAlign="center" mb="1rem">
          Sam sprawdź, jak możemy Ci pomóc
        </Typography>
        <Grid.Row>
          <Grid.Col md={{ size: 6, offset: 3 }}>
            <Typography textAlign="center" mb="2.5rem" />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          {COPY.TILES_INVEST.map(tile => (
            <Grid.Col md={6} xl={4}>
              <TileInvest
                key={tile.title}
                icon={tile.icon}
                title={tile.title}
                to={tile.url}
                m="0 auto 2rem"
              />
            </Grid.Col>
          ))}
        </Grid.Row>
      </Grid.Container>
    </Grid.Container>
    <Disclaimer {...COPY.DISCLAIMER} pb="3rem" />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytInwestycyjny;

import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import {
  CftBox,
  CftLabel,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftTextField,
  CftTooltip,
} from "../../../components";
import useCftOptions from "../../../utils/useCftOptions";
// Icons

import { ReactComponent as PivotIcon } from "../../../../assets/icons/cft/pivot.svg";
import { ReactComponent as RaingunIcon } from "../../../../assets/icons/cft/raingun.svg";
import { ReactComponent as FloodingIcon } from "../../../../assets/icons/cft/flooding.svg";
import { ReactComponent as DripIcon } from "../../../../assets/icons/cft/drip.svg";

// Energy types Icons
import { ReactComponent as EnergyIcon } from "../../../../assets/icons/cft/energia.svg";

// Water Source icons
import { ReactComponent as StawIcon } from "../../../../assets/icons/cft/staw.svg";
import { ReactComponent as ZbiornikIcon } from "../../../../assets/icons/cft/zbiornik.svg";
import { ReactComponent as RzekaIcon } from "../../../../assets/icons/cft/rzeka.svg";
import { ReactComponent as ZbiornikSztucznyIcon } from "../../../../assets/icons/cft/zbiornik-sztuczny.svg";
import { ReactComponent as OdwiertIcon } from "../../../../assets/icons/cft/odwiert.svg";
import { ReactComponent as KanalizacjaIcon } from "../../../../assets/icons/cft/kanalizacja.svg";

// Energy Source Icons
import { ReactComponent as DieselIcon } from "../../../../assets/icons/cft/diesel.svg";
import { ReactComponent as GrawitacjaIcon } from "../../../../assets/icons/cft/grawitacja.svg";

function EventTypeFields({ id, name }) {
  const { t } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();
  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:irrigation.form.1", "Metoda zdarzenia")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:irrigation.tooltips.1",
                "Określ metodę nawadniania dla każdego ze zdarzeń osobno"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <PivotIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.3", "Zraszacz")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <RaingunIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.4", "Pistolet spryskujący")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <FloodingIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.5", "Zalewanie")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "4"}
                  onClick={() => {
                    helpers.setValue("4");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DripIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.6", "Nawadnianie kropelkowe")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="medium">
        <Row>
          <Col xs={12} lg={8}>
            <CftLabel label={t("cft:irrigation.form.7", "Ilość wody")} />
            <CftTextField
              name={`${name}[${id}].waterAmount`}
              label={t("cft:fuelAndEnergy.form.0", "litrów")}
            />
          </Col>
          <Col xs={12} lg={4}>
            <CftLabel label={t("cft:irrigation.form.8", "Podlany obszar")} />
            <CftTextField name={`${name}[${id}].wateredArea`} adornment="%" />
          </Col>
        </Row>
      </CftFormControl>
      <CftFormControl variant="medium">
        <CftLabel
          label={t("cft:irrigation.form.9", "Źródło wody")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:irrigation.tooltips.2",
                "Określ źródło wody dla wykonania tego zabiegu"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].waterSource`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <StawIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.11", "Naturalne jezioro/staw")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <ZbiornikIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.12", "Zbiornik")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <RzekaIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.13", " Rzeka/stumień/rów")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "4"}
                  onClick={() => {
                    helpers.setValue("4");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <ZbiornikSztucznyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t(
                      "cft:irrigation.form.14",
                      "Staw/zbiornik retencyjny/\nzbiornik magazynowy w\ngospodarstwie"
                    )}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "5"}
                  onClick={() => {
                    helpers.setValue("5");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <OdwiertIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.15", "Odwiert/studnia")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "6"}
                  onClick={() => {
                    helpers.setValue("6");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <KanalizacjaIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.16", "Główne źródło")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:irrigation.form.17", "Głębkość źródła wody")} />
        <CftTextField name={`${name}[${id}].depthSource`} adornment="m" />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:irrigation.form.18", "Dystans od źródła")} />
        <CftUnitField
          name={`${name}[${id}].distanceSource`}
          units={getUnits(["m", "km"])}
        />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:irrigation.form.19", "Żródło energii")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:irrigation.tooltips.3",
                "Określ źródło zasilania dla pozyskania wody z jego źródła dla danego zabiegu\n"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].energySource`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <EnergyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.21", "Prąd")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <DieselIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.22", "Diesel")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => {
                    helpers.setValue("3");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <GrawitacjaIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:irrigation.form.23", "Grawitacja")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
    </>
  );
}

export default EventTypeFields;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { ButtonFix } from "../index";
import "./Alert.scss";

export const AlertWarning = ({ heading, text, position = "top", callback }) => {
  const [isopen, setIsopen] = useState(true);
  const { t } = useTranslation(["globals"]);
  const wrapClass = `c-alert-big c-alert-big--${position}`;
  if (!isopen) return null;
  return (
    <div className={wrapClass}>
      <Container className="c-alert-big__container c-alert-big__container--yellow">
        <Row>
          <Col>
            <div className="c-alert-big__heading">
              <span className="c-alert-big__heading-title">{t(heading)}</span>
              <ButtonFix
                buttoncolor="green"
                onClick={() => {
                  setIsopen(false);
                  if (callback) {
                    callback();
                  }
                }}
              >
                {t("globals:buttons.close")}
              </ButtonFix>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="description">{t(text)}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

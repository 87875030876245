import React, { useRef } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftTank,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftButton,
  CftBox,
  CftBoxWrapper,
  CftSelectField,
  CftUnitField,
  CftQuestionGroup,
  CftConditionalRendering,
  CftTooltip,
} from "../../../components";
import { scrollToLastElement } from "../../../utils/cftUtils";
import { energySourcesTypes } from "../../farmlands/FuelAndEnergyFarmlandsForm/fuelAndEnergyFarmlands.consts";

// Energy types Icons
import { ReactComponent as AgregatorIcon } from "../../../../assets/icons/cft/agregator.svg";
import { ReactComponent as EnergyIcon } from "../../../../assets/icons/cft/energia.svg";
import { ReactComponent as CHPIcon } from "../../../../assets/icons/cft/chp.svg";

export const initSource = () => ({
  id: Date.now(),
  agregator: "",
  consumption: {
    value: "",
    unit: "",
  },
  category: "",
  label: "",
  type: "",
});

function Source({ id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { values, setFieldValue } = useFormikContext();

  const sourceType = values.sources[id].type;

  const getSourceTypeUnits = () => {
    if (!sourceType || !values.sources[id].agregator) {
      return [];
    }
    const source = energySourcesTypes[sourceType].find(
      ({ value }) => value === values.sources[id].agregator
    );

    return source
      ? source.units.map(opt => ({
          value: opt.value,
          label: opt.pl,
        }))
      : [];
  };

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:fuelAndEnergy.form.40", "Żródło energii")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:energyAndProcessing.tooltips.1",
                "Określi rodzaj energii wykorzystywanej podczas hodowli w danym roku"
              )}
            />
          }
        />
        <CftFormField name={`sources[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "agregator"}
                  onClick={() => {
                    helpers.setValue("agregator");
                    setFieldValue(`sources[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <AgregatorIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.15", "Agregator")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "electricity"}
                  onClick={() => {
                    helpers.setValue("electricity");
                    setFieldValue(`sources[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <EnergyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.16", "Prąd")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "chp"}
                  onClick={() => {
                    helpers.setValue("chp");
                    setFieldValue(`sources[${id}].agregator`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CHPIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">CHP</p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={!!sourceType}>
        <CftFormControl variant="small">
          <CftLabel
            label={t("cft:energyAndProcessing:form.4", "Agregator prądu")}
            name={`sources[${id}].agregator`}
          />
          <CftSelectField
            name={`sources[${id}].agregator`}
            options={
              energySourcesTypes[sourceType]
                ? energySourcesTypes[sourceType].map(opt => ({
                    value: opt.value,
                    label: opt[language],
                  }))
                : []
            }
            onChange={() => {
              setFieldValue(`sources[${id}].consumption`, {
                unit: "",
                value: "",
              });
            }}
          />
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering
        isConditionFulfilled={!!values.sources[id].agregator}
      >
        <CftFormControl variant="small">
          <CftLabel
            label={t(
              "cft:energyAndProcessing:form.5",
              "Pobór energii dla całego pola"
            )}
          />
          <CftUnitField
            name={`sources[${id}].consumption`}
            units={getSourceTypeUnits()}
          />
        </CftFormControl>
      </CftConditionalRendering>

      <CftFormControl>
        <CftLabel label={t("cft:energyAndProcessing:form.6", "Kategoria")} />
        <CftFormField name={`sources[${id}].category`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.22", "Pole")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.23", "Obiekt")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:fuelAndEnergy.form.24", "Dodaj etykiete")} />
        <CftTextField name={`sources[${id}].label`} />
      </CftFormControl>
    </>
  );
}

function SourceType() {
  const { t } = useTranslation(["cft"]);
  const groupRefs = useRef([]);

  const getError = (value, key) => {
    if (!value) {
      return false;
    }
    return !!value[key];
  };

  return (
    <>
      <CftFormField name="sources">
        {({ value, helpers, meta }) => (
          <>
            {value.map(({ id }, key) => (
              <CftQuestionGroup
                key={id}
                // eslint-disable-next-line no-return-assign
                ref={el => (groupRefs.current[key] = el)}
                label={`${t(
                  "cft:fuelAndEnergy.form.25",
                  "Źródło energii"
                )} ${key + 1}`}
                withoutMarginBottom={key + 1 === value.length}
                onRemove={() => {
                  helpers.setValue(value.filter(f => f.id !== id));
                  scrollToLastElement(groupRefs, 130);
                }}
                hasError={meta.touched && getError(meta.error, key)}
              >
                <Source id={key} />
              </CftQuestionGroup>
            ))}
            <CftFormControl variant="small">
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  onClick={() => {
                    helpers.setValue([...value, initSource()]);
                    scrollToLastElement(groupRefs, 90);
                  }}
                >
                  {value.length
                    ? t(
                        "cft:fuelAndEnergy.form.26",
                        "Dodaj kolejne źródło energi"
                      )
                    : t("cft:fuelAndEnergy.form.27", "Dodaj źródło energi")}
                </CftButton>
              </CftTank>
            </CftFormControl>
          </>
        )}
      </CftFormField>
    </>
  );
}

export default SourceType;

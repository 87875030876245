import React from "react";
import PropTypes from "prop-types";

export const CheckedIcon = props => {
  return (
    <div className="checkedIcon">
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
      >
        <title>ico/check</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ico/check"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="ico/next"
            transform="translate(10.000000, 11.000000)"
            fill={props.color}
            fillRule="nonzero"
          >
            <polygon
              id="Path"
              points="4.34893587 6.14482284 11.2556082 -0.723187467 13.3709662 1.4040785 4.34761379 10.3769202 -0.398124521 5.65184676 1.71856216 3.52590285"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

CheckedIcon.propTypes = {
  color: PropTypes.string,
};

CheckedIcon.defaultProps = {
  color: "#2E3033",
};

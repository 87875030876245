import React, { useEffect, useMemo, useState } from "react";
import { CardArticle, CardMultimedia } from "agronomist-ui-components";
import { useTranslation } from "react-i18next";
import { useLinks } from "../../../services/helpers/links";
import {
  MULTIMEDIA_TYPES_VALUES,
  serializeMultimediaDataToCard,
} from "../../../services/helpers/multimedia";
import { serializeArticleDataToCard } from "../../../services/helpers/articles";
import { getMediaUrlMeta } from "../../../services/helpers/video-cover";

export function ArticleCardWrapper({ isMultimedia, article, cardType }) {
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const { article: articleLink, multimedia: multimediaLink } = useLinks();
  const { t } = useTranslation(["globals"]);
  const cardProps = useMemo(() => {
    const props = isMultimedia
      ? serializeMultimediaDataToCard(article, multimediaLink, cardType)
      : serializeArticleDataToCard(article, t, articleLink);

    if (thumbnailUrl) {
      props.coverUrl = thumbnailUrl;
    }

    return props;
  }, [thumbnailUrl, article]);

  useEffect(() => {
    if (
      isMultimedia &&
      article.multimedia_type === MULTIMEDIA_TYPES_VALUES.Video
    ) {
      if (article.cover_photo !== null) {
        setThumbnailUrl(article.cover_photo.file);
      } else {
        getMediaUrlMeta(article.url, setThumbnailUrl);
      }
    } else if (
      thumbnailUrl === null &&
      !isMultimedia &&
      article.thumb &&
      (article.thumb.file_webp || article.thumb.file)
    ) {
      setThumbnailUrl(article.thumb.file_webp || article.thumb.file);
    } else if (!isMultimedia && article.video_url) {
      getMediaUrlMeta(article.video_url, setThumbnailUrl);
    }
  }, []);

  if (isMultimedia) {
    return <CardMultimedia id={article.id} {...cardProps} />;
  }

  return <CardArticle id={article.id} {...cardProps} type={cardType} />;
}

import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CftFormErrorFocus } from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import SourceType from "./SourceType";
import { farmlandsAPI } from "../../../utils/cftApi";
import { mapValues } from "./energyAndProcessingDP.utils";

function EnergyAndProcessingDPForm() {
  return (
    <Form className="cft-form narrow-labels">
      <SourceType />
      <CftFormErrorFocus />
    </Form>
  );
}

export default EnergyAndProcessingDPForm;

export function EnergyAndProcessingDPFormContext({ children }) {
  const history = useHistory();
  const { t } = useTranslation(["cft"]);

  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();

  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.energyAndProcessing}
      validationSchema={Yup.object().shape({
        sources: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required(
              t(
                "cft:validation:requiredOption",
                t("cft:validation.requiredOption", "Wybierz opcję")
              )
            ),
            agregator: Yup.string().required(
              t(
                "cft:validation:requiredOption",
                t("cft:validation.requiredOption", "Wybierz opcję")
              )
            ),
            consumption: Yup.object().shape({
              value: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(
                  t(
                    "cft:validation:required",
                    t("cft:validation.required", "Wpisz wartość")
                  )
                )
                .typeError(
                  t(
                    "cft:validation:numberTypeError",
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
              unit: Yup.string().required(
                t(
                  "unitTypeError",
                  t("cft:validation.unitTypeError", "Wybierz jednostkę")
                )
              ),
            }),
            category: Yup.string().required(
              t(
                "cft:validation:requiredOption",
                t("cft:validation.requiredOption", "Wybierz opcję")
              )
            ),
            label: Yup.string()
              .trim()
              .max(20, "Maksymalna ilość znaków to 20")
              .required(
                t(
                  "cft:validation:required",
                  t("cft:validation.required", "Wpisz wartość")
                )
              ),
          })
        ),
      })}
      onSubmit={values => {
        farmlandsAPI
          .directEnergyValidation({
            direct_energy: mapValues(values).directEnergy,
          })
          .then(({ data }) => {
            history.push(routes.DAIRY_PRODUCTION.TRANSPORT);
            saveDairyProduction({ energyAndProcessing: values });
            saveDairyProductionReport(data);
          });
      }}
    >
      {children}
    </Formik>
  );
}

import { sprintf } from "sprintf-js";

import API from "./properties.api";

export const TYPES = {
  GET_PROPERTIES: "GET_PROPERTIES",
  SET_PROPERTIES: "SET_PROPERTIES",
  GET_PROMOTED_PROPERTIES: "GET_PROMOTED_PROPERTIES",
  SET_PROMOTED_PROPERTIES: "SET_PROMOTED_PROPERTIES",
  GET_SUGGESTED_PROPERTIES: "GET_SUGGESTED_PROPERTIES",
  GET_TRANSACTION_TYPES: "GET_TRANSACTION_TYPES",
  GET_PROPERTY_TYPES: "GET_PROPERTY_TYPES",
  GET_PROPERTY: "GET_PROPERTY",
  GET_PROPERTY_MEDIAS: "GET_PROPERTY_MEDIAS",
  GET_PROVINCE: "GET_PROVINCE",
};

export const SEARCH_LIMIT = 7;

export default {
  getProperties: params => ({
    type: TYPES.GET_PROPERTIES,
    payload: {
      request: {
        url: API.PROPERTIES,
        method: "GET",
        params: {
          ordering: "-published_at",
          limit: SEARCH_LIMIT,
          ...params,
        },
      },
    },
  }),
  setProperties: data => ({
    type: TYPES.SET_PROPERTIES,
    payload: {
      data,
    },
  }),
  getPromotedProperties: params => ({
    type: TYPES.GET_PROMOTED_PROPERTIES,
    payload: {
      request: {
        url: API.PROMOTED_PROPERTIES,
        method: "GET",
        params: {
          limit: 3,
          ...params,
        },
      },
    },
  }),
  setPromotedProperties: data => ({
    type: TYPES.SET_PROMOTED_PROPERTIES,
    payload: {
      data,
    },
  }),
  getSuggestedProperties: params => ({
    type: TYPES.GET_SUGGESTED_PROPERTIES,
    payload: {
      request: {
        url: API.PROPERTIES,
        method: "GET",
        params: {
          ordering: "-published_at",
          limit: 5,
          ...params,
        },
      },
    },
  }),
  getTransactionTypes: () => ({
    type: TYPES.GET_TRANSACTION_TYPES,
    payload: {
      request: {
        url: API.TRANSACTION_TYPES,
        method: "GET",
        params: {
          limit: 100,
        },
      },
    },
  }),
  getPropertyTypes: () => ({
    type: TYPES.GET_PROPERTY_TYPES,
    payload: {
      request: {
        url: API.PROPERTY_TYPES,
        method: "GET",
        params: {
          limit: 100,
        },
      },
    },
  }),
  getProperty: ({ slug }) => ({
    type: TYPES.GET_PROPERTY,
    payload: {
      request: {
        url: sprintf(API.PROPERTY, slug),
        method: "GET",
      },
    },
  }),
  getPropertyMedias: ({ slug }) => ({
    type: TYPES.GET_PROPERTY_MEDIAS,
    payload: {
      request: {
        url: sprintf(API.MEDIAS, slug),
        method: "GET",
      },
    },
  }),
  getProvince: () => ({
    type: TYPES.GET_PROVINCE,
    payload: {
      request: {
        url: API.PROVINCES,
        method: "GET",
        params: {
          limit: 100,
        },
      },
    },
  }),
};

import { dotFormatter } from "../../../utils/cftUtils";

export const initCattleGrazingHerd = {
  days: "",
  hours: "",
  category: "",
  quality: "",
};

export function mapCattleGrazingHerd(cattleHerd = [], cattleGrazingHerd = []) {
  return cattleHerd.map((herd, key) => {
    if (typeof herd === "boolean" || herd === null) {
      return false;
    }

    const grazingHerd = cattleGrazingHerd[key];
    if (typeof grazingHerd !== "boolean" && grazingHerd !== null) {
      return grazingHerd;
    }

    return initCattleGrazingHerd;
  });
}

export function mapValues(values) {
  const grazing = values.herd
    .map((val, key) => {
      if (val) {
        return {
          herd_section: key + 1,
          days: val.days,
          hours: val.hours,
          category: val.category,
          quality: val.quality,
        };
      }

      return val;
    })
    .filter(val => val !== false);

  if (values.fertilisation) {
    return {
      grazing,
      fertilisation_approach: values.fertilisation.fertilisationApproach,
      grazing_area: {
        value: dotFormatter(values.fertilisation.grazingArea.value),
        unit_id: values.fertilisation.grazingArea.unit,
      },
      fertilisers: values.applications.map(({ type, production, rate }) => ({
        fertiliser_type_id: type,
        production_id: production,
        application_rate: {
          value: dotFormatter(rate.value),
          unit_id: rate.unit,
        },
      })),
    };
  }

  return {
    grazing,
    fertilisers: [],
  };
}

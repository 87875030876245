import React from "react";
import {
  Hero,
  Benefits,
  BenefitsTable,
  Logos,
  Disclaimer,
  ContactForm,
  InvestmentCalculatorPlaceholder,
} from "agronomist-ui-components";

import PageTemplate from "../helpers/PageTemplate";
import { getBreadcrumbs } from "../helpers/fakeBreadcrumbs";
import TempShareButtonsComponent from "../helpers/TempShareButtonsComponent";
import { scrollToElement, onContactSubmit } from "../helpers/utils";
import CalculatorPage from "../../components/views/CalculatorPage/CalculatorPage";

import imageUrl from "../assets/images/kredyt_23.png";

const COPY = {
  HERO: {
    breadcrumbs: getBreadcrumbs("Kredyt na finansowanie wydatków gospodarstwa"),
    title: "Kredyt na finansowanie wydatków gospodarstwa",
    description:
      "Potrzebujesz finansowania potrzeb związanych z prowadzona działalnością m.in. z zakupem rzeczowych środków do produkcji rolnej lub rozliczeń pieniężnych z kontrahentami?",
    imageUrl,
    button: {
      title: "Sprawdź ofertę",
      onClick: () => scrollToElement("#ofert"),
    },
  },
  BENEFITS: {
    title: "Jakie są korzyści z finansowania?",
    subtitle:
      "Kredyt nieodnawialny o określonym harmonogramie spłat pomoże uzupełnić środki finansowe gospodarstwa oraz poprawi jego działanie.",
    benefits: [
      {
        title: "Możliwość dopłaty do oprocentowania do 2%",
        icon: "HandCoins",
      },
      {
        title:
          "Szeroki wachlarz produktów - rozwiązania dopasowane do różnych potrzeb",
        icon: "SquaresOverlapping",
      },
      {
        title:
          "Możliwość zabezpieczenia kredytu wyłącznie bezpłatną gwarancją z Funduszu Gwarancji Rolnych (FGR)",
        icon: "Umbrella",
      },
    ],
  },
  BENEFITS2: {
    title: "Jak to działa?",
    subtitle:
      "Kredytomat to prosta i przyjazna wyszukiwarka oferty bankowej ułatwiająca dobór produktów najbardziej dopasowanych do Twoich potrzeb związanych z prowadzeniem gospodarstwa rolnego.",
    benefits: [
      {
        title: "Znajdź produkt spełniający Twoje oczekiwania",
        description:
          "Ustaw interesujące Cię kryteria wyszukiwania, aby otrzymać ofertę jak najbardziej dopasowaną do Twoich potrzeb.",
        icon: "SearchBulb",
      },
      {
        title: "Skontaktuj się z nami",
        description:
          "Zapisz propozycję oferty i pozostaw swoje dane kontaktowe lub odwiedź nas w Oddziale.",
        icon: "MessagesBulb",
      },
      {
        title: "Gotowe! Możesz realizować swoje potrzeby",
        description:
          "Ciesz się z dodatkowych środków na finansowanie potrzeb Twojego gospodarstwa!",
        icon: "HandBulb",
      },
    ],
    withArrow: true,
  },
  DISCLAIMER: {
    notes: [
      "<sup>1</sup>Kredyt dostępny tylko dla podmiotów spełniających warunki określone w dokumencie: „Warunki uzyskania w Banku Gospodarstwa Krajowego gwarancji spłaty kredytu i dopłaty do oprocentowania kredytu objętego gwarancją w ramach portfelowej linii gwarancyjnej FGR”, przekazywanym w momencie wnioskowania o gwarancję. Dopłata do oprocentowania realizowana jest przez BGK, w wysokości odsetek kredytowych, lecz nie więcej niż 2% w stosunku rocznym (przez pierwsze 12 miesięcy spłaty kredytu przez kredytobiorcę).”",
      "Niniejszy materiał ma charakter wyłącznie reklamowy i informacyjny oraz nie stanowi oferty w rozumieniu Kodeksu Cywilnego. Ostateczna decyzja o udzieleniu kredytu i warunkach kredytowania uzależniona jest od wewnętrznych przepisów Banku oraz wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta. Świadczenie usług i produktów bankowych następuje na warunkach szczegółowo określonych w umowie zawartej z Klientem. Informacja o produktach i usługach bankowych dostępna jest w regulaminach, taryfie prowizji i opłat, dostępnych w oddziałach Banku oraz na stronie <a href='https://www.bnpparibas.pl'>www.bnpparibas.pl</a>.",
    ],
  },
  BENEFITS_TABLE: {
    packages: [
      "Przewagi produktu",
      "Maksymalny okres finansowania",
      "Maksymalna kwota kredytu",
      "Zabezpieczenie",
      "Częstotliwość spłaty kapitału",
    ],
    features: [
      {
        title: "Kredyt obrotowy z gwarancją FGR i dopłatą<sup>1</sup>",
        subtitle: "nieodnawialny",
        isRecommended: true,
        list: [
          "Dopłaty do oprocentowania przez pierwsze 12 miesięcy",
          "4 lata",
          "Równowartość w PLN kwoty 200 000 EUR",
          "Hipoteka, zastaw rejestrowy gwarancja FGR",
          "Raty miesięczne lub kwartalne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Kredyt obrotowy z gwarancją FGR i dopłatą`;
          },
        },
      },
      {
        title: "Agro Lider",
        subtitle: "dla mniejszych gospodarstw",
        list: [
          "Długi okres kredytowania",
          "15 lat",
          "1 mln PLN",
          "Hipoteka",
          "Raty miesięczne lub kwartalne",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Agro Lider`;
          },
        },
      },
      {
        title: "Rzeczówka zabezpieczona",
        subtitle: "nieodnawialna",
        list: [
          "Możliwość refinansowania zobowiązań",
          "3 lata",
          "Brak",
          "Hipoteka, zastaw rejestrowy",
          "Raty miesięczne lub kwartalne lub spłata jednorazowa",
        ],
        footer: {
          text: "ZAPYTAJ O OFERTĘ",
          onClick: () => {
            window.location.href = `/kredytomat/kontakt?product_url=${
              window.location.pathname
            }&product_name=Rzeczówka zabezpieczona`;
          },
        },
      },
    ],
  },
};

const KredytyObrotoweSplacaneWRatachZZabezpieczeniem = () => (
  <PageTemplate
    meta={{
      name: "Kredytomat",
      description:
        "Potrzebujesz finansowania, dotknęła Cię klęska żywiołowa, a może szukasz ubezpieczenia dla swojego gospodarstwa rolnego? Pomożemy Ci - Agronomist",
      mediaCoverUrl: imageUrl,
    }}
  >
    <Hero {...COPY.HERO} />
    <Benefits {...COPY.BENEFITS} pt="4rem" pb="4rem" />
    <BenefitsTable id="ofert" {...COPY.BENEFITS_TABLE} />
    <TempShareButtonsComponent />
    <Logos />
    <InvestmentCalculatorPlaceholder>
      <CalculatorPage isSeparate />
    </InvestmentCalculatorPlaceholder>
    <Benefits {...COPY.BENEFITS2} pt="4rem" pb="4rem" />
    <Disclaimer {...COPY.DISCLAIMER} />
    <ContactForm onSubmit={onContactSubmit} />
  </PageTemplate>
);

export default KredytyObrotoweSplacaneWRatachZZabezpieczeniem;

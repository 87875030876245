import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { CftProgressCircle, CftProgress, CftButton } from "../index";
import routes from "../../utils/cftRoutes";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow_svgo.svg";
import { useCft } from "../../utils/CftProvider";

import "./CftReportProgress.scss";

function SingleReport({ name, step, steps, complete }) {
  const isUntouched = step === 0 && steps === 0;

  return (
    <div className="cft-report-progress__single-report">
      <p>
        {name}
        {isUntouched ? (
          <span>0%</span>
        ) : (
          <span>{Math.round((step * 100) / steps)}%</span>
        )}
      </p>
      <CftProgress value={complete} />
    </div>
  );
}

function CftReportProgress({ type }) {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { progress, reportIsOpen, setReportOpen } = useCft();

  const dairyProductionReports = [
    {
      name: t("cft:types.detailsDairyProduction", "Szczegóły hodowli"),
      currentStep: progress.dairyProduction.details.completedFields,
      steps: progress.dairyProduction.details.allFields,
      complete: progress.dairyProduction.details.complete,
    },
    {
      name: t("cft:types.characteristics", "Charakterystyka stada"),
      currentStep: progress.dairyProduction.characteristics.completedFields,
      steps: progress.dairyProduction.characteristics.allFields,
      complete: progress.dairyProduction.characteristics.complete,
    },
    {
      name: t("cft:types.cattleGrazing", "Wypas bydła"),
      currentStep: progress.dairyProduction.cattleGrazing.completedFields,
      steps: progress.dairyProduction.cattleGrazing.allFields,
      complete: progress.dairyProduction.cattleGrazing.complete,
    },
    {
      name: t("cft:types.fuelAndEnergy", "Paliwo i energia"),
      currentStep: progress.dairyProduction.energyAndProcessing.completedFields,
      steps: progress.dairyProduction.energyAndProcessing.allFields,
      complete: progress.dairyProduction.energyAndProcessing.complete,
    },
    {
      name: t("cft:types.forage", "Pasze"),
      currentStep: progress.dairyProduction.forage.completedFields,
      steps: progress.dairyProduction.forage.allFields,
      complete: progress.dairyProduction.forage.complete,
    },

    {
      name: t("cft:types.manure", "Obornik"),
      currentStep: progress.dairyProduction.manure.completedFields,
      steps: progress.dairyProduction.manure.allFields,
      complete: progress.dairyProduction.manure.complete,
    },
    {
      name: t("cft:types.transport", "Transport"),
      currentStep: progress.dairyProduction.transport.completedFields,
      steps: progress.dairyProduction.transport.allFields,
      complete: progress.dairyProduction.transport.complete,
    },
  ];

  const farmlandsReports = [
    {
      name: t("cft:types.detailsFarmlands", "Detale uprawy"),
      currentStep: progress.farmlands.details.completedFields,
      steps: progress.farmlands.details.allFields,
      complete: progress.farmlands.details.complete,
    },
    {
      name: t("cft:types.soil", "Gleba"),
      currentStep: progress.farmlands.soil.completedFields,
      steps: progress.farmlands.soil.allFields,
      complete: progress.farmlands.soil.complete,
    },
    {
      name: t("cft:types.fertilizers", "Nawozy"),
      currentStep: progress.farmlands.fertilizers.completedFields,
      steps: progress.farmlands.fertilizers.allFields,
      complete: progress.farmlands.fertilizers.complete,
    },
    {
      name: t("cft:types.fuelAndEnergy", "Paliwo i energia"),
      currentStep: progress.farmlands.fuelAndEnergy.completedFields,
      steps: progress.farmlands.fuelAndEnergy.allFields,
      complete: progress.farmlands.fuelAndEnergy.complete,
    },
    {
      name: t("cft:types.irrigation", "Irygacja"),
      currentStep: progress.farmlands.irrigation.completedFields,
      steps: progress.farmlands.irrigation.allFields,
      complete: progress.farmlands.irrigation.complete,
    },

    {
      name: t("cft:types.carbonChanges", "Zmiany węglowe"),
      currentStep: progress.farmlands.carbonChanges.completedFields,
      steps: progress.farmlands.carbonChanges.allFields,
      complete: progress.farmlands.carbonChanges.complete,
    },
    {
      name: t("cft:types.transport", "Transport"),
      currentStep: progress.farmlands.transport.completedFields,
      steps: progress.farmlands.transport.allFields,
      complete: progress.farmlands.transport.complete,
    },
  ];

  const waterFootprintReports = [
    {
      name: t("cft:types.detailsWaterFootprint", "Detale uprawy"),
      currentStep: progress.waterFootprint.details.completedFields,
      steps: progress.waterFootprint.details.allFields,
      complete: progress.waterFootprint.details.complete,
    },
    {
      name: t("cft:types.soil", "Gleba"),
      currentStep: progress.waterFootprint.soil.completedFields,
      steps: progress.waterFootprint.soil.allFields,
      complete: progress.waterFootprint.soil.complete,
    },
    {
      name: t("cft:types.irrigation", "Irygacja"),
      currentStep: progress.waterFootprint.irrigation.completedFields,
      steps: progress.waterFootprint.irrigation.allFields,
      complete: progress.waterFootprint.irrigation.complete,
    },
  ];

  const bothCalcReports = [
    {
      name: t("cft:types.detailsFarmlands", "Detale uprawy"),
      currentStep: progress.bothCalc.details.completedFields,
      steps: progress.bothCalc.details.allFields,
      complete: progress.bothCalc.details.complete,
    },
    {
      name: t("cft:types.soil", "Gleba"),
      currentStep: progress.bothCalc.soil.completedFields,
      steps: progress.bothCalc.soil.allFields,
      complete: progress.bothCalc.soil.complete,
    },
    {
      name: t("cft:types.fertilizers", "Nawozy"),
      currentStep: progress.bothCalc.fertilizers.completedFields,
      steps: progress.bothCalc.fertilizers.allFields,
      complete: progress.bothCalc.fertilizers.complete,
    },
    {
      name: t("cft:types.fuelAndEnergy", "Paliwo i energia"),
      currentStep: progress.bothCalc.fuelAndEnergy.completedFields,
      steps: progress.bothCalc.fuelAndEnergy.allFields,
      complete: progress.bothCalc.fuelAndEnergy.complete,
    },
    {
      name: t("cft:types.irrigation", "Irygacja"),
      currentStep: progress.bothCalc.irrigation.completedFields,
      steps: progress.bothCalc.irrigation.allFields,
      complete: progress.bothCalc.irrigation.complete,
    },
    {
      name: t("cft:types.carbonChanges", "Zmiany węglowe"),
      currentStep: progress.bothCalc.carbonChanges.completedFields,
      steps: progress.bothCalc.carbonChanges.allFields,
      complete: progress.bothCalc.carbonChanges.complete,
    },
    {
      name: t("cft:types.transport", "Transport"),
      currentStep: progress.bothCalc.transport.completedFields,
      steps: progress.bothCalc.transport.allFields,
      complete: progress.bothCalc.transport.complete,
    },
  ];

  let reports = farmlandsReports;

  if (type === "dairyProduction") {
    reports = dairyProductionReports;
  } else if (type === "waterFootprint") {
    reports = waterFootprintReports;
  } else if (type === "bothCalc") {
    reports = bothCalcReports;
  }

  useEffect(() => {
    if (reportIsOpen) {
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("html").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "";
      document.querySelector("html").style.overflow = "";
    }
  }, [reportIsOpen]);

  function generateReport() {
    document.querySelector("body").style.overflowY = "";
    document.querySelector("html").style.overflow = "";
    setReportOpen(false);
    if (type === "farmlands") {
      return history.push(routes.FARMLANDS.REPORT);
    }
    if (type === "waterFootprint") {
      return history.push(routes.FARMLANDS.WATER_FOOTPRINT.REPORT);
    }
    if (type === "bothCalc") {
      return history.push(routes.FARMLANDS.BOTH_CALC.REPORT);
    }

    return history.push(routes.DAIRY_PRODUCTION.REPORT);
  }

  function reduceProgress(prev, curr) {
    const isCompletedStep = curr.complete === 100;
    return {
      allSteps: prev.allSteps + 1,
      completedSteps: isCompletedStep
        ? prev.completedSteps + 1
        : prev.completedSteps,
    };
  }

  function getGlobalComplete(
    farmlandsReports,
    dairyProductionReports,
    waterFootprintReports
  ) {
    if (type === "farmlands") {
      const farmlandsProgress = farmlandsReports.reduce(reduceProgress, {
        allSteps: 0,
        completedSteps: 0,
      });

      return (
        (farmlandsProgress.completedSteps * 100) / farmlandsProgress.allSteps
      );
    }
    if (type === "waterFootprint") {
      const waterFootprintProgress = waterFootprintReports.reduce(
        reduceProgress,
        {
          allSteps: 0,
          completedSteps: 0,
        }
      );

      return (
        (waterFootprintProgress.completedSteps * 100) /
        waterFootprintProgress.allSteps
      );
    }
    if (type === "bothCalc") {
      const bothCalcProgress = bothCalcReports.reduce(reduceProgress, {
        allSteps: 0,
        completedSteps: 0,
      });

      return (
        (bothCalcProgress.completedSteps * 100) / bothCalcProgress.allSteps
      );
    }
    const dairyProductionProgress = dairyProductionReports.reduce(
      reduceProgress,
      {
        allSteps: 0,
        completedSteps: 0,
      }
    );

    return (
      (dairyProductionProgress.completedSteps * 100) /
      dairyProductionProgress.allSteps
    );
  }

  const globalComplete = Math.floor(
    getGlobalComplete(
      farmlandsReports,
      dairyProductionReports,
      waterFootprintReports,
      bothCalcReports
    )
  );

  return (
    <div className="cft-report-progress__wrapper">
      <div
        className={classnames("cft-report-progress", {
          "cft-report-progress--isOpen": reportIsOpen,
        })}
      >
        <div className="cft-report-progress__content">
          <div
            className="cft-report-progress__box"
            onClick={() => !isMobile && setReportOpen(value => !value)}
          >
            <div className="cft-report-progress__box_left_border" />
            <div onClick={() => isMobile && setReportOpen(value => !value)}>
              <CftProgressCircle value={globalComplete} />
            </div>
            <div className="cft-report-progress__box_right_border" />
            <div
              className="cft-report-progress__text"
              onClick={() => isMobile && setReportOpen(value => !value)}
            >
              {t("cft:reportProgress", "Twój raport gotowy jest w")}{" "}
              {globalComplete}%
            </div>
            <ArrowIcon
              className="cft-report-progress__arrow"
              onClick={() => isMobile && setReportOpen(value => !value)}
            />
          </div>
          <div className="cft-report-progress__reports">
            <div className="cft-report-progress__reports__wrapper">
              {reports.map((report, key) => (
                <SingleReport
                  key={key}
                  name={report.name}
                  step={report.currentStep}
                  steps={report.steps}
                  complete={report.complete}
                />
              ))}
            </div>
            <CftButton variant="active" onClick={generateReport}>
              {t("cft:reportProgressBtn", "Wygeneruj raport cząstkowy")}
            </CftButton>
          </div>
        </div>
      </div>
      <div
        className={classnames("cft-report-progress__overlay", {
          "cft-report-progress__overlay--isOpen": reportIsOpen,
        })}
        onClick={() => setReportOpen(value => !value)}
      />
    </div>
  );
}

CftReportProgress.propTypes = {
  type: PropTypes.oneOf([
    "farmlands",
    "dairyProduction, waterFootprint, bothCalc",
  ]),
};

CftReportProgress.defaultProps = {
  type: "farmlands",
};

export default CftReportProgress;

import React, { useState, useEffect } from "react";
import {useTranslation, withTranslation} from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { ProductFamily } from "../../averagePrices/types";
import LoginForm from "../LoginForm/LoginForm";
import CerealsWidget from "./CerealsWidget/CerealsWidget";
import DefaultUserWidget from "./CerealsWidget/DefaultUserWidget";
import OleaginousWidget from "./OleaginousWidget/OleaginousWidget";
import PorkWidget from "./PorkWidget/PorkWidget";
import PoultryWidget from "./PoultryWidget/PoultryWidget";
import DairyWidget from "./DairyWidget/DairyWidget";

const MarketPricesWidget = ({ t }) => {
  const history = useHistory();
  const [showLogin, setShowLogin] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const user = useSelector(state => state.user);
  const [userDefaults] = user.user.defaults_markets;

  const isUserLogged = user.token !== "" || !!Cookies.get("user_token");

  useEffect(() => {
    if (shouldRedirect && isUserLogged) {
      if (!userDefaults) {
        return history.push("/srednie-ceny/zboza");
      }
      switch (userDefaults.product_family) {
        case ProductFamily.Oleaginous:
          return history.push("/srednie-ceny/oleiste/rzepak");
        case ProductFamily.Poultry:
          return history.push("/srednie-ceny/drob");
        case ProductFamily.Pork:
          return history.push("/srednie-ceny/wieprzowina");
        case ProductFamily.Dairy:
          return history.push("/srednie-ceny/nabial");
        default:
        case ProductFamily.Cereals:
          return history.push("/srednie-ceny/zboza");
      }
    }

    return undefined;
  }, [shouldRedirect, userDefaults]);

  const widget = (() => {
    if (userDefaults) {
      switch (userDefaults.product_family) {
        case ProductFamily.Oleaginous:
          return (
            <OleaginousWidget
              userDefaults={userDefaults}
              onLoginClick={() => setShowLogin(true)}
            />
          );
        case ProductFamily.Poultry:
          return (
            <PoultryWidget
              userDefaults={userDefaults}
              onLoginClick={() => setShowLogin(true)}
            />
          );
        case ProductFamily.Pork:
          return (
            <PorkWidget
              userDefaults={userDefaults}
              onLoginClick={() => setShowLogin(true)}
            />
          );
        case ProductFamily.Cereals:
          return (
            <CerealsWidget
              userDefaults={userDefaults}
              onLoginClick={() => setShowLogin(true)}
            />
          );
        case ProductFamily.Dairy:
          return (
            <DairyWidget
              userDefaults={userDefaults}
              onLoginClick={() => setShowLogin(true)}
            />
          );
        default:
          break;
      }
    }

    return <DefaultUserWidget onLoginClick={() => setShowLogin(true)} />;
  })();

  return (
    <>
      {widget}
      <p className="market-price-desc">{t("averagePrices:widget.description")}</p>
      <Modal
        isOpen={showLogin}
        onRequestClose={() => setShowLogin(false)}
        style={{
          overlay: {
            zIndex: "1000",
            backgroundColor: "rgba(0,0,0,0.6)",
          },
          content: {
            width: "100%",
            maxWidth: "670px",
            margin: "0 auto",
            bottom: "unset",
            borderRadius: 0,
            padding: 0,
            overflowX: "hidden",
            backgroundColor: "#ffffff",
          },
        }}
      >
        <div className="modal__login">
          <div className="modal__login__text">
            {t("widgets:popup.altLogin")}
          </div>
          <LoginForm onLoginCallback={() => setShouldRedirect(true)} />
        </div>
      </Modal>
    </>
  );
};

export default withTranslation(["widgets"])(MarketPricesWidget);

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import cl from "classnames";
import { useIMask } from "react-imask";

import CftError from "../CftError/CftError";

import "./CftUnitField.scss";

function getUnitLabel(unit) {
  if (typeof unit === "string") {
    return unit;
  }

  return unit.label;
}

function getUnitValue(unit) {
  if (typeof unit === "string") {
    return unit;
  }

  return unit.value;
}

function CftUnitField({
  name,
  placeholder,
  units,
  isAlternative,
  type,
  mask,
  definitions,
}) {
  const [field, meta, helpers] = useField(name);
  const unitsRef = useRef();

  const hasMask = mask.length > 0;

  const { ref, setValue } = useIMask(
    { mask, definitions },
    {
      onAccept: (value, mask, event) => {
        if (hasMask && !!event) {
          helpers.setValue({
            ...field.value,
            value: event.target.value,
            unit: field.value.unit,
          });
        }
      },
    }
  );

  useEffect(() => {
    if (hasMask) {
      const defaultValue = field.value;
      setValue(`${defaultValue}`);
    }
  }, []);

  function renderLabel(label) {
    if (label === "m2") {
      return (
        <>
          m<sup>2</sup>
        </>
      );
    }

    if (label === "m3") {
      return (
        <>
          m<sup>3</sup>
        </>
      );
    }

    return label;
  }

  return (
    <div
      className={cl("cft-unit-field", {
        "cft-unit-field--isInvalid": meta.touched && meta.error,
        "cft-unit-field--isAlternative": isAlternative,
      })}
    >
      <div className="cft-unit-field__input">
        {hasMask ? (
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            defaultValue={field.value.value}
            onBlur={field.onBlur}
            style={{
              paddingRight: unitsRef.current
                ? `${unitsRef.current.clientWidth + 16}px`
                : "1rem",
            }}
            name={field.name}
          />
        ) : (
          <input
            type={type}
            placeholder={placeholder}
            value={field.value.value}
            onChange={({ target }) =>
              helpers.setValue({
                ...field.value,
                value: target.value,
                unit: field.value.unit,
              })
            }
            onBlur={e => {
              const val = e.target.value || "";
              const number = Number(val.replace(/,/, "."));

              if (!Number.isNaN(number) && val !== "") {
                helpers.setValue({
                  ...field.value,
                  value: `${Number(number)}`.trimStart().trimEnd(),
                  unit: field.value.unit,
                });
              } else {
                helpers.setValue({
                  ...field.value,
                  value: val.trimStart().trimEnd(),
                  unit: field.value.unit,
                });
              }

              field.onBlur(e);
            }}
            style={{
              paddingRight: unitsRef.current
                ? `${unitsRef.current.clientWidth + 16}px`
                : "1rem",
            }}
            name={field.name}
          />
        )}

        <div ref={unitsRef} className="cft-unit-field__units">
          {units.map(unit => {
            const label = getUnitLabel(unit);
            const value = getUnitValue(unit);
            return (
              <span
                key={label}
                className={cl({
                  active: field.value.unit === value,
                })}
                onClick={() => {
                  helpers.setValue({
                    ...field.value,
                    value: field.value.value,
                    unit: value,
                  });
                }}
              >
                {renderLabel(label)}
              </span>
            );
          })}
        </div>
      </div>
      <CftError isActive={meta.touched && meta.error} message={meta.error} />
    </div>
  );
}

CftUnitField.propTypes = {
  name: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(),
  isAlternative: PropTypes.bool,
  type: PropTypes.string,
  mask: PropTypes.string,
};

CftUnitField.defaultProps = {
  units: [],
  isAlternative: false,
  type: "text",
  mask: "",
};

export default CftUnitField;

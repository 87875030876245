// Soil Texture Icons
import { ReactComponent as FineGrained } from "../../../../../assets/icons/cft/piaskowa.svg";
import { ReactComponent as MediumGrain } from "../../../../../assets/icons/cft/srednio.svg";
import { ReactComponent as CoarseGrained } from "../../../../../assets/icons/cft/grubo.svg";

// Soil Moisture Icons
import { ReactComponent as WetIcon } from "../../../../../assets/icons/cft/wilgotnosc_mokra.svg";
import { ReactComponent as MediumMoistureIcon } from "../../../../../assets/icons/cft/wilgotnosc_srednia.svg";
import { ReactComponent as DryIcon } from "../../../../../assets/icons/cft/wilgotnosc_sucha.svg";

export function mapValues(values) {
  return {
    soil_type_id: Number(values.soilTypeAdvanced),
    soil_moisture_at_sowing_id: Number(values.soilMoisture),
  };
}

export const SOIL_TEXTURE_TYPE = [
  {
    id: "1",
    Icon: FineGrained,
    label: {
      i18n: "cft:soilWaterFootprint.form.2",
      default: "Drobnoziarniste",
    },
  },
  {
    id: "2",
    Icon: MediumGrain,
    label: {
      i18n: "cft:soilWaterFootprint.form.3",
      default: "Średnioziarniste",
    },
  },
  {
    id: "3",
    Icon: CoarseGrained,
    label: {
      i18n: "cft:soilWaterFootprint.form.4",
      default: "Gruboziarniste",
    },
  },
];

export const SOIL_TEXTURE_TYPE_ADVANCED = [
  {
    id: "201",
    label: {
      i18n: "cft:soilWaterFootprint.form.5",
      default: "Piasek",
    },
  },
  {
    id: "202",
    label: {
      i18n: "cft:soilWaterFootprint.form.6",
      default: "Piasek gliniasty",
    },
  },
  {
    id: "203",
    label: {
      i18n: "cft:soilWaterFootprint.form.7",
      default: "Glina piaszczysta",
    },
  },
  {
    id: "2",
    label: {
      i18n: "cft:soilWaterFootprint.form.8",
      default: "Glina",
    },
  },
  {
    id: "103",
    label: {
      i18n: "cft:soilWaterFootprint.form.9",
      default: "Glina pylasta",
    },
  },
  {
    id: "206",
    label: {
      i18n: "cft:soilWaterFootprint.form.10",
      default: "Pył",
    },
  },
];

export const SOIL_MOISTURE = [
  {
    id: "1",
    Icon: WetIcon,
    label: {
      i18n: "cft:soilWaterFootprint.form.12",
      default: "Mokra",
    },
  },
  {
    id: "2",
    Icon: MediumMoistureIcon,
    label: {
      i18n: "cft:soilWaterFootprint.form.13",
      default: "Średnia",
    },
  },
  {
    id: "3",
    Icon: DryIcon,
    label: {
      i18n: "cft:soilWaterFootprint.form.14",
      default: "Sucha",
    },
  },
];

import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftTooltip,
} from "../../components";
import FeedComponentsFields from "./FeedComponentsFields";

export default function FemaleTurkeysFields({ productionCyclesId }) {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const { values } = useFormikContext();

  if (values.production_cycles[productionCyclesId].female_turkeys === null) {
    return null;
  }
  return (
    <>
      <CftFormControl alignLeft>
        <CftTank
          style={{
            padding: "1rem",
            backgroundColor: "#E5F5F0",
            color: "#00965E",
            fontWeight: 400,
            fontSize: "0.875rem",
            textAlign: "center",
            textTransform: "uppercase",
            justifyContent: "center",
          }}
          pt={1}
          pb={1}
          mb={4}
        >
          {t("cftTurkey:typeProduction.2", "Indyczki")}
        </CftTank>
        <CftFormControl alignLeft>
          <CftLabel
            isFullWidth
            label={t(
              "cftTurkey:FemaleTurkeysFields.fields.quantitylabel",
              "Całkowita ilość idyków w cyklu (szt)"
            )}
          >
            <CftTooltip
              content={t(
                "cftTurkey:tooltip.FemaleTurkeysFields.quantitylabel",
                "Podaj ile indyków zostało umieszczonych w indyczniku w tym cyklu produkcyjnym"
              )}
            />
          </CftLabel>
          <CftTextField
            isRounded
            name={`production_cycles[${productionCyclesId}].female_turkeys.quantity`}
            placeholder={t(
              "cftTurkey:FemaleTurkeysFields.fields.quantityPlaceholder",
              "Wpisz liczbę"
            )}
            isAlternative
            mask="0000000"
          />
        </CftFormControl>
        <CftFormControl alignLeft>
          <CftLabel
            isFullWidth
            label={t(
              "cftTurkey:FemaleTurkeysFields.fields.target_agelabel",
              "Docelowy wiek indorów"
            )}
          />
          <CftTextField
            isRounded
            name={`production_cycles[${productionCyclesId}].female_turkeys.target_age`}
            placeholder={t(
              "cftTurkey:FemaleTurkeysFields.fields.target_agePlaceholder",
              "Wpisz liczbę"
            )}
            isAlternative
            mask="000"
          />
        </CftFormControl>
        <CftFormControl alignLeft>
          <CftLabel
            isFullWidth
            label={t(
              "cftTurkey:FemaleTurkeysFields.fields.falls_percentlabel",
              "Ilość upadków na cykl produkcyjny (%)"
            )}
          >
            <CftTooltip
              content={t(
                "cftTurkey:tooltip.FemaleTurkeysFields.falls_percent",
                "Procent wstawionych indyków, które uległy upadkowi. Zmień wartość dla swojej hodowli"
              )}
            />
          </CftLabel>
          <CftTextField
            isRounded
            name={`production_cycles[${productionCyclesId}].female_turkeys.falls_percent`}
            placeholder={t(
              "cftTurkey:FemaleTurkeysFields.fields.falls_percentPlaceholder",
              "Wpisz liczbę"
            )}
            isAlternative
            mask="00"
          />
        </CftFormControl>
        <FeedComponentsFields
          productionCyclesId={productionCyclesId}
          fieldKey="female_turkeys"
        />
      </CftFormControl>
    </>
  );
}

FemaleTurkeysFields.propTypes = {
  productionCyclesId: PropTypes.string.isRequired,
};

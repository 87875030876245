/* eslint-disable no-undef */
import Cookies from "js-cookie";

import { GET_CONTENT_BAR } from "./actions";

const SUCCESS = "_SUCCESS";

const InitialState = {
  id: -1,
  active: false,
  content: "",
};

export default function ui(state = InitialState, action) {
  switch (action.type) {
    case GET_CONTENT_BAR + SUCCESS:
      return { ...action.payload.data.results[0] };
    default:
      return state;
  }
}

export const getContentBar = ({
  lang_code = Cookies.get("user_lang")
    ? Cookies.get("user_lang").toUpperCase()
    : "PL",
} = {}) => ({
  type: GET_CONTENT_BAR,
  payload: {
    request: {
      url: "/api/v1/bar-content/",
      method: "GET",
      params: {
        lang_code,
      },
    },
  },
});

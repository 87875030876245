import CattlePage from "./Cattle.page";
import CattleHerdFeedPage from "./CattleHerdFeed.page";
import CattleHerdFeedsPage from "./CattleHerdFeeds.page";
import CattleEmissionsPage from "./CattleEmissions.page";
import CattleReportPage from "./CattleReport.page";

export default {
  Main: CattlePage,
  Coop: CattleHerdFeedPage,
  Coops: CattleHerdFeedsPage,
  Emission: CattleEmissionsPage,
  Report: CattleReportPage,
};

import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import "./CftHeaderSteps.scss";

function CftHeaderSteps({ tabs, store }) {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);

  return (
    <div className={classnames("cft-header-steps")}>
      {tabs.map(({ name, path, keyStore }, key) => {
        const isActive =
          location.pathname.includes(path) || store[keyStore].isValidate;

        return (
          <div
            key={name}
            onClick={() => isActive && history.push(path)}
            className={classnames({ isActive })}
          >
            <span>
              {key + 1}. {t(name)}
            </span>
          </div>
        );
      })}
    </div>
  );
}

CftHeaderSteps.defaultProps = {
  tabs: [],
  store: {},
};

export default CftHeaderSteps;

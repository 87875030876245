import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import cl from "classnames";
import CftCheckbox from "../CftCheckbox/CftCheckbox";
import CftBox from "../CftBox/CftBox";
import CftError from "../CftError/CftError";

import "./CftRadioField.scss";

function CftRadioField({ name, options, variant, onChange }) {
  const [field, meta] = useField(name);

  switch (variant) {
    case "dots":
      return (
        <div
          className={cl("cft-radio-field", {
            "cft-radio-field--isInvalid": meta.touched && meta.error,
          })}
        >
          {options.map(({ label, value }) => (
            <CftCheckbox
              key={label}
              checked={String(field.value) === String(value)}
              value={String(value)}
              name={field.name}
              onChange={e => {
                field.onChange(e);
                onChange(e);
              }}
              onBlur={field.onBlur}
              id={field.name}
              label={label}
            />
          ))}
          <CftError
            isActive={meta.touched && meta.error}
            message={meta.error}
            textAlign="center"
          />
        </div>
      );

    case "box":
      return (
        <div
          className={cl("cft-radio-field", {
            "cft-radio-field--isInvalid": meta.touched && meta.error,
          })}
        >
          {options.map(({ label, value }) => (
            <CftBox
              style={{ textAlign: "center", justifyContent: "center" }}
              key={label}
              variant="fullWidth"
              isActive={String(field.value) === String(value)}
              onClick={e => {
                const event = e;
                event.target = {
                  ...event.target,
                  value,
                  name,
                };
                field.onChange(event);
              }}
            >
              {label}
            </CftBox>
          ))}
          <CftError
            isActive={meta.touched && meta.error}
            message={meta.error}
            textAlign="center"
          />
        </div>
      );

    default:
      return (
        <div
          className={cl("cft-radio-field", {
            "cft-radio-field--isInvalid": meta.touched && meta.error,
          })}
        >
          <div className="cft-radio-field__group">
            {options.map(({ label, value }) => (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
              <label key={value} className="cft-radio-field__input">
                <span className="cft-radio-field__label">{label}</span>
                <input
                  type="radio"
                  checked={field.value === String(value)}
                  value={value}
                  name={field.name}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  id={field.name}
                />
                <span className="cft-radio-field__checkmark" />
              </label>
            ))}
          </div>
          <CftError
            isActive={meta.touched && meta.error}
            message={meta.error}
            textAlign="center"
          />
        </div>
      );
  }
}

CftRadioField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ).isRequired,
  variant: PropTypes.oneOf(["default", "dots", "box"]),
  onChange: PropTypes.func,
};

CftRadioField.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  adornment: null,
  variant: "default",
  onChange: () => null,
};

export default CftRadioField;

import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useCft } from "../../utils/CftProvider";
import CftProgressCircle from "../CftProgressCircle/CftProgressCircle";
import routes from "../../utils/cftRoutes";

import "./CftWidget.scss";

export default function CftWidget() {
  const { t } = useTranslation(["cft"]);
  const { progress } = useCft();

  const total =
    ((progress.reports.farmlands.completedSteps +
      progress.reports.waterFootprint.completedSteps +
      progress.reports.bothCalc.completedSteps +
      progress.reports.dairyProduction.completedSteps) *
      100) /
    (progress.reports.farmlands.allSteps +
      progress.reports.waterFootprint.allSteps +
      progress.reports.bothCalc.allSteps +
      progress.reports.dairyProduction.allSteps);

  return (
    <div className="cft-widget">
      <div className="cft-widget__progress">
        <CftProgressCircle value={Math.floor(total)} />
      </div>
      <div className="cft-widget__content">
        <h3>{t("cft:shorttitle", "Agroemisja")}</h3>
        <Link className="bttn bttn--yellow" to={routes.MAIN}>
          {t("cft:go", "przejdź")}
        </Link>
      </div>
    </div>
  );
}

import React from "react";
import PropTypes from "prop-types";

import "./CftError.scss";

function CftError({ message, isActive, textAlign, style }) {
  if (!isActive) {
    return null;
  }

  if (typeof message === "string") {
    return (
      <div
        className="cft-error"
        style={{
          textAlign,
          ...style,
        }}
      >
        {message}
      </div>
    );
  }

  return (
    <>
      {Object.keys(message).map(key => (
        <div
          key={key}
          className="cft-error"
          style={{
            textAlign,
            ...style,
          }}
        >
          {message[key]}
        </div>
      ))}
    </>
  );
}

CftError.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isActive: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  textAlign: PropTypes.string,
};

CftError.defaultProps = {
  message: null,
  isActive: false,
  textAlign: "left",
};

export default CftError;

import React from "react";
import { useTranslation } from "react-i18next";

import {
  CftLabel,
  CftTextField,
  CftFormControl,
  CftSelectField,
  CftTooltip,
} from "../../../../components";
import useCftOptions from "../../../../utils/useCftOptions";

import { managementPracticeCovers } from "./carbonChangesBothCalc.consts";

function CatchFields({ soilMoistureId, name }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { years } = useCftOptions();

  if (!soilMoistureId) {
    return null;
  }

  return (
    <>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:carbonChanges.form.6", "Zmiana")} />
        <CftSelectField
          name={`${name}.coverChange`}
          options={managementPracticeCovers
            .filter(
              ({ soil_moisture_id }) =>
                soil_moisture_id === Number(soilMoistureId)
            )
            .map(opt => ({ label: opt[language], value: opt.id }))}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.7",
            "Kiedy nastąpiło przekształcenie"
          )}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:carbonChanges.tooltips.1",
                "Wskaż rok kiedy nastąpiło przekształcenie"
              )}
            />
          }
        />
        <CftSelectField name={`${name}.coverYearsAgo`} options={years} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:carbonChanges.form.8", "Procent pola")} />
        <CftTextField name={`${name}.coverAllocation`} adornment="%" />
      </CftFormControl>
    </>
  );
}

export default CatchFields;

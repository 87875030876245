import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

import {
  CftTank,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftNumberField,
  CftBox,
  CftBoxWrapper,
} from "../../../components";
import { titlesCattleHerd } from "../CharacteristicsDPForm/CattleHerdFields";

// Icons
import { ReactComponent as ConfidentIcon } from "../../../../assets/icons/cft/confident.svg";
import { ReactComponent as RangelandIcon } from "../../../../assets/icons/cft/rangeland.svg";

function HerdFields({ id, value }) {
  const { t } = useTranslation(["cft"]);

  if (!value) {
    return null;
  }

  return (
    <>
      <CftFormControl variant="small">
        <CftTank mb={4} justifyContent="center">
          <h3 className="cft-headline">{t(titlesCattleHerd[id])}</h3>
        </CftTank>
        <Row>
          <Col xs={12} md={6}>
            <CftLabel label={t("cft:cattleGrazing.form.21", "Dni")} />
            <CftNumberField name={`herd[${id}].days`} />
          </Col>
          <Col xs={12} md={6}>
            <CftLabel label={t("cft:cattleGrazing.form.22", "Godz./dzień")} />
            <CftNumberField name={`herd[${id}].hours`} />
          </Col>
        </Row>
      </CftFormControl>
      <CftFormControl>
        <CftLabel label={t("cft:cattleGrazing.form.23", "Typ pastwiska")} />
        <CftFormField name={`herd[${id}].category`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => helpers.setValue("2")}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <ConfidentIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:cattleGrazing.form.20", "Zamknięte pastwisko")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "3"}
                  onClick={() => helpers.setValue("3")}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <RangelandIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t(
                      "cft:cattleGrazing.form.19",
                      "Teren wypasu /\nniepielęgnowane pastwisko"
                    )}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        <CftLabel label={t("cft:cattleGrazing.form.23", "Typ pastwiska")} />
        <CftFormField name={`herd[${id}].quality`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => helpers.setValue("1")}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:cattleGrazing.form.26", "Niska")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => helpers.setValue("2")}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:cattleGrazing.form.24", "Wysoka")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
    </>
  );
}

export default HerdFields;

import { format, isSameDay, isSameYear } from "date-fns";
import pl from "date-fns/locale/pl";

// 2020-03-29T16:51:03Z => 3 mar
export const formatDate = (dateStr, locale = "pl") =>
  format(new Date(dateStr), "D MMM", {
    locale: locale === "pl" ? pl : undefined,
  });

export const getMonthName = (date, locale = "pl") =>
  format(date, "MMMM - YYYY", { locale: locale === "pl" ? pl : undefined });

export const getWeekName = (date, locale = "pl") =>
  format(date, "DD.MM.YY", { locale: locale === "pl" ? pl : undefined });

export const getWeeksPeriodText = (startDate, endDate, locale) =>
  `${getWeekName(new Date(startDate), locale)} - ${getWeekName(
    new Date(endDate),
    locale
  )}`;

export const isTheSameDate = (date1, date2) =>
  isSameYear(date1, date2) && isSameDay(date1, date2);

import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftTank,
  CftLabel,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftButton,
  CftConditionalRendering,
  CftSelectField,
  CftTooltip,
} from "../../../../components";

import { types, productions, methods } from "./fertilizersBothCalc.consts";
import useCftOptions from "../../../../utils/useCftOptions";

function FertilizerBothCalcTypeFields({ id, name, values }) {
  const { setFieldValue } = useFormikContext();
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();

  const fertilisersOptions =
    values.type === ""
      ? []
      : types.find(({ name }) => name === values.type).fertilisers;

  const availableProductions =
    values.fertiliser === ""
      ? []
      : fertilisersOptions.find(({ id }) => id === Number(values.fertiliser))
          .production_ids;
  const availablAapplicationMethod =
    values.fertiliser === ""
      ? []
      : fertilisersOptions.find(({ id }) => id === Number(values.fertiliser))
          .application_method_ids;

  const productionsOptions = productions.filter(
    ({ production_id }) => availableProductions.indexOf(production_id) !== -1
  );
  const methodsOptions = methods.filter(
    ({ id }) => availablAapplicationMethod.indexOf(id) !== -1
  );

  const resetFertiliser = () => {
    setFieldValue(`${name}[${id}].fertiliser`, "");
    setFieldValue(`${name}[${id}].productions`, "");
    setFieldValue(`${name}[${id}].method`, "");
  };

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:fertilizers.form.chooseType", "Wybierz typ nawozu")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fertilizers.form.0",
                "Określ wszystkie zabiegi nawożenia jakie wykonywałeś na danej uprawie."
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "Mineralny"}
                  onClick={() => {
                    helpers.setValue("Mineralny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.11", "Mineralny")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "Organiczny"}
                  onClick={() => {
                    helpers.setValue("Organiczny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.12", "Organiczny")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "Wapienny"}
                  onClick={() => {
                    helpers.setValue("Wapienny");
                    resetFertiliser();
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fertilizers.form.13", "Wapienny")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fertilizers.form.14", "Wybierz nawóz")}
          name={`${name}[${id}].fertiliser`}
        />
        <CftSelectField
          name={`${name}[${id}].fertiliser`}
          options={fertilisersOptions.map(option => ({
            label: option[language],
            value: option.id,
          }))}
          onChange={() => {
            setFieldValue(`${name}[${id}].productions`, "");
            setFieldValue(`${name}[${id}].method`, "");
          }}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fertilizers.form.15", "Wybierz region produkcji")}
        />
        <CftSelectField
          name={`${name}[${id}].productions`}
          options={productionsOptions.map(option => ({
            label: option[language],
            value: option.production_id,
          }))}
        />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fertilizers.form.16", "Dawka stosowania")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fertilizers.tooltips.2",
                "Określ dokładną wartość zastosowanego nawozu"
              )}
            />
          }
        />
        <CftUnitField
          name={`${name}[${id}].rate`}
          units={getUnits(["t/ha", "kg/a"])}
        />
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={!!methodsOptions.length}>
        <CftFormControl>
          <CftLabel
            label={t("cft:fertilizers.form.17", "Metoda aplikowania")}
            // eslint-disable-next-line react/no-children-prop
            children={
              <CftTooltip
                content={t(
                  "cft:fertilizers.form.18",
                  "Wybierz rodzaj aplikowania tego konkretnego nawozu"
                )}
              />
            }
          />
          <CftFormField name={`${name}[${id}].method`}>
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {methodsOptions.map(method => (
                    <CftBox
                      key={method.id}
                      isActive={value === `${method.id}`}
                      onClick={() => {
                        helpers.setValue(`${method.id}`);
                      }}
                      isInvalid={meta.touched && meta.error}
                    >
                      <p className="cft-box__no-icon">{method[language]}</p>
                    </CftBox>
                  ))}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fertilizers.form.19", "Inhibitory emisji")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fertilizers.tooltips.4",
                "Czy zastosowany nawóz posiadał inhibitor emisji?"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].inhibitor`}>
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => helpers.setValue(true)}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:yes", "Tak")}
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => helpers.setValue(false)}
                  isInvalid={meta.touched && meta.error}
                >
                  {t("cft:no", "nie")}
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
    </>
  );
}

export default FertilizerBothCalcTypeFields;

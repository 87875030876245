import React from "react";
import clx from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";

import "./CftSection.scss";

function CftSection({ children, header, isReport, withNoSpace, style }) {
  return (
    <section
      className={clx("cft-section", {
        "cft-section__report": isReport,
        "cft-section__withNoSpace": withNoSpace,
      })}
      style={style}
    >
      {header && (
        <header>
          <Container>
            <Row>
              <Col xs={12}>{header}</Col>
            </Row>
          </Container>
        </header>
      )}
      <main>
        <Container>
          <Row>
            <Col xs={12}>{children}</Col>
          </Row>
        </Container>
      </main>
    </section>
  );
}

CftSection.propTypes = {
  header: PropTypes.node,
  isReport: PropTypes.bool,
  withNoSpace: PropTypes.bool,
};

CftSection.defaultProps = {
  header: null,
  isReport: false,
  withNoSpace: false,
};

export default CftSection;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Breadcrumbs.scss";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

export const Breadcrumbs = props => {
  let homeUrl = "/";
  const homeName = "Agronomist";
  const token = Cookies.get("user_token");
  const consumerType = Cookies.get("user_client_type");

  if (token && consumerType === "3") {
    homeUrl = "/farmer";
  }
  if (token && consumerType === "2") {
    homeUrl = "/manufacturer";
  }

  const path = props.path.length
    ? props.path.map(item => (
        // eslint-disable-next-line react/jsx-indent
        <>
          <span className="separator" />
          <Link to={item.url}>{item.name}</Link>
        </>
      ))
    : null;
  const metaPath = props.path.length
    ? props.path.map((item, index) => {
        return {
          "@type": "ListItem",
          position: index + 2,
          name: item.name,
          item: window.location.origin + item.url,
        };
      })
    : [];
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: homeName,
        item: window.location.origin,
      },
      ...metaPath,
      {
        "@type": "ListItem",
        position: props.path.length + 2,
        name: props.currentName,
        item: window.location.href,
      },
    ],
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="breadcrumbs">
        <Link to={homeUrl}>Agronomist</Link>
        {path}
        <span className="separator" />
        <span>{props.currentName}</span>
      </div>
    </>
  );
};

Breadcrumbs.propTypes = {
  currentName: PropTypes.string.isRequired,
  path: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

Breadcrumbs.defaultProps = {
  path: [],
};

import React from "react";
import { ShareButtons } from "../../components/common";
import "./TempShareButtonsComponent.scss";

export default function TempShareButtonsComponent() {
  return (
    <>
      <div className="temp-share-btns-component-vertical">
        <ShareButtons hashtag="#agronomist" />
      </div>

      <div className="temp-share-btns-component-horizontal">
        <ShareButtons hashtag="#agronomist" inline={true}  />
      </div>
    </>
  );
}

/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { differenceInMonths } from "date-fns";
import {
  getPreviousEntry,
  timespanTypeToInstallmentFrequency,
  timespanTypeToNumberOfInstallments,
  getDifferenceInMonths,
} from "./dataHelpers";
import {
  getCapitalWithInterestInstallmentAmountWithSubvention,
  getInterestInstallmentAmountWithSubvention,
  getCapitalInstallmentAmountWithSubvention,
  getCreditAmountWithSubvention,
  getCurrentInstallmentMonth,
  getCurrentInstallmentInterestPeriod,
  getFirstCapitalInstallmentAfterSubvention,
  getFirstAnyInstallmentAfterSubvention,
} from "./installmentHelpers";
import { ipmt, pmt, ppmt } from "./bankingHelpers";
import { buildEntry } from "./formatters";

export const calculateCreditOfferFixedInstallmentsWithSubventionQuarterly = (
  input = null
) => {
  const {
    hasSubvention,
    subventionAmount,
    subventionReceivingMonth, // number of month starting from 1, e.g. 1 - january, 12 - december
    subventionReceivingYear, // full year, e.g. 2020
    repaymentTimespan, // in years, e.g. 15
    creditRate, // in percentage, e.g. 5
    creditAmount,
  } = input;

  const capitalInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.capitalInstallmentFrequency,
  });
  const interestInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.interestInstallmentFrequency,
  });
  const numberOfInterestInstallments =
    interestInstallmentFrequency * repaymentTimespan;
  const numberOfCapitalInstallments =
    interestInstallmentFrequency * repaymentTimespan;

  const output = [];

  let creditBaseAfterSubvention = 0;
  const firstEntryAfterSubventionIndex = numberOfInterestInstallments;
  let month = 0;
  let year = 0;
  let interestPeriod = 0;

  const installmentsRange = numberOfInterestInstallments;

  for (let periodNo = 1; periodNo <= installmentsRange; periodNo++) {
    const previousEntry = getPreviousEntry({ output, periodNo });

    year = Math.ceil(periodNo / interestInstallmentFrequency);

    month =
      periodNo === 1
        ? year * (12 / interestInstallmentFrequency)
        : previousEntry.month + 12 / interestInstallmentFrequency >
          repaymentTimespan * 12
        ? ""
        : previousEntry.month + 12 / interestInstallmentFrequency;
    interestPeriod = periodNo;

    const capitalInstallmentNumber = Math.ceil(
      periodNo / (interestInstallmentFrequency / capitalInstallmentFrequency)
    );
    let subventionRepaymentMonth = differenceInMonths(
      new Date(subventionReceivingYear, subventionReceivingMonth),
      new Date()
    );
    if (subventionRepaymentMonth === 3) {
      subventionRepaymentMonth++;
    }
    const subventionRepaymentInterestPeriod =
      subventionRepaymentMonth % 3 === 0
        ? subventionRepaymentMonth / 3 + 1
        : Math.ceil(subventionRepaymentMonth / 3);

    let currentCreditAmount = 0;
    let capitalWithInterestInstallmentAmount = 0;
    let interestInstallmentAmount = 0;
    let capitalInstallmentAmount = 0;

    const installmentsCountAfterSubvention =
      numberOfCapitalInstallments - subventionRepaymentInterestPeriod;

    let sumOfcapitalInstallmentAmountBeforeSubvention = 0;
    output.forEach(item => {
      if (item.interestPeriod < subventionRepaymentInterestPeriod) {
        sumOfcapitalInstallmentAmountBeforeSubvention +=
          item.capitalInstallmentAmount;
      }
    });

    const isAfterSubvention = month > subventionRepaymentMonth;

    const isSubventionPaidRecently =
      interestPeriod === subventionRepaymentInterestPeriod;

    const pmtRate = creditRate / 100 / 4;
    const pmtNumberOfPeriods = isAfterSubvention
      ? installmentsCountAfterSubvention + 1
      : numberOfCapitalInstallments;
    const pmtPresentValue = isAfterSubvention
      ? creditAmount -
        sumOfcapitalInstallmentAmountBeforeSubvention -
        subventionAmount
      : creditAmount;

    capitalWithInterestInstallmentAmount = -pmt(
      pmtRate,
      pmtNumberOfPeriods,
      pmtPresentValue
    );

    const ppmtRate = creditRate / 100 / 4;
    const ppmtPeriod = isAfterSubvention
      ? interestPeriod - subventionRepaymentInterestPeriod + 1
      : interestPeriod;
    const ppmtNumberOfPeriods = isAfterSubvention
      ? installmentsCountAfterSubvention + 1
      : numberOfCapitalInstallments;
    const ppmtPresentValue = isAfterSubvention
      ? creditAmount -
        sumOfcapitalInstallmentAmountBeforeSubvention -
        subventionAmount
      : creditAmount;

    capitalInstallmentAmount = -ppmt(
      ppmtRate,
      ppmtPeriod,
      ppmtNumberOfPeriods,
      ppmtPresentValue
    );

    interestInstallmentAmount =
      capitalWithInterestInstallmentAmount - capitalInstallmentAmount;

    if (periodNo === 1) {
      currentCreditAmount = creditAmount - capitalInstallmentAmount;
    } else if (previousEntry) {
      const currentCreditAmountPreviousEntry =
        previousEntry.currentCreditAmount;

      currentCreditAmount = isSubventionPaidRecently
        ? currentCreditAmountPreviousEntry -
          capitalInstallmentAmount -
          subventionAmount
        : currentCreditAmountPreviousEntry - capitalInstallmentAmount;
      creditBaseAfterSubvention = isSubventionPaidRecently
        ? currentCreditAmount
        : creditBaseAfterSubvention;
    }

    const debt = currentCreditAmount + capitalInstallmentAmount;

    const entry = buildEntry({
      year,
      month,
      interestPeriod,
      capitalInstallmentNumber,
      capitalInstallmentAmount,
      currentCreditAmount,
      interestInstallmentAmount,
      debt,
    });

    if (parseFloat(entry.capitalWithInterestInstallmentAmount) <= 0) {
      break;
    }

    output.push(entry);
  }

  const firstInstallmentAfterSubvention =
    hasSubvention &&
    output.find(
      entry => entry.interestPeriod === firstEntryAfterSubventionIndex
    );

  return {
    entries: output,
    creditAmount,
    creditAmountAfterSubvention: creditAmount - subventionAmount,
    installmentAmountAfterSubvention:
      (firstInstallmentAfterSubvention &&
        firstInstallmentAfterSubvention.capitalWithInterestInstallmentAmount) ||
      0,
  };
};

export const calculateCreditOfferFixedInstallmentsWithSubvention = (
  input = null
) => {
  const {
    hasSubvention,
    subventionAmount,
    subventionReceivingMonth: subventionReceivingMonthIndex,
    subventionReceivingYear,
    repaymentTimespan,
    creditRate,
    creditAmount,
  } = input;

  const interestInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.interestInstallmentFrequency,
  });
  const subventionReceivingMonth = differenceInMonths(
    new Date(subventionReceivingYear, subventionReceivingMonthIndex),
    new Date()
  );
  const numberOfInterestInstallments =
    interestInstallmentFrequency * repaymentTimespan;
  const numberOfInterestInstallmentsAfterSubvention =
    numberOfInterestInstallments - subventionReceivingMonth;
  const installmentsRange = numberOfInterestInstallments;
  const creditRateDiv = creditRate / 100;

  const output = [];

  let creditBaseAfterSubvention = 0;
  let paidWithoutSubvention = 0;
  let currentCreditAmount = 0;
  let firstEntryAfterSubventionIndex = 0;

  for (let periodNo = 1; periodNo <= installmentsRange; periodNo++) {
    const isBeforeSubvention = periodNo <= subventionReceivingMonth;
    const isSubventionRepaymentMonth = periodNo === subventionReceivingMonth;
    const isAfterSubvention = periodNo > subventionReceivingMonth;

    const previousEntry = getPreviousEntry({ output, periodNo });
    const year = Math.ceil(periodNo / interestInstallmentFrequency);

    let capitalInstallmentAmount = 0;
    let interestInstallmentAmount = 0;
    let capitalWithInterestInstallmentAmount = 0;

    const currentPeriod = isBeforeSubvention
      ? periodNo
      : periodNo - subventionReceivingMonth;
    const currentNumberOfPeriods = isAfterSubvention
      ? numberOfInterestInstallmentsAfterSubvention
      : numberOfInterestInstallments;

    if (!creditBaseAfterSubvention && isAfterSubvention) {
      paidWithoutSubvention = output.reduce(
        (sum, { capitalInstallmentAmount }) =>
          sum + parseFloat(capitalInstallmentAmount),
        0
      );
      creditBaseAfterSubvention =
        creditAmount - paidWithoutSubvention - subventionAmount;
      firstEntryAfterSubventionIndex = periodNo + 1;
    }

    const currentPresentValue = isAfterSubvention
      ? creditBaseAfterSubvention
      : creditAmount;

    capitalInstallmentAmount = -ppmt(
      creditRateDiv / 12,
      currentPeriod,
      currentNumberOfPeriods,
      currentPresentValue,
      0
    );

    if (periodNo === 1) {
      currentCreditAmount = creditAmount - capitalInstallmentAmount;
    } else if (previousEntry) {
      const previousCreditAmount = previousEntry.currentCreditAmount;

      if (isSubventionRepaymentMonth) {
        currentCreditAmount =
          previousCreditAmount - capitalInstallmentAmount - subventionAmount;
      } else {
        currentCreditAmount = previousCreditAmount - capitalInstallmentAmount;
      }
    }

    capitalWithInterestInstallmentAmount = -pmt(
      creditRateDiv / 12,
      currentNumberOfPeriods,
      currentPresentValue
    );

    interestInstallmentAmount =
      capitalWithInterestInstallmentAmount - capitalInstallmentAmount;

    const debt = previousEntry
      ? parseFloat(
          previousEntry.currentCreditAmount -
            previousEntry.currentSubventionAmount
        )
      : creditAmount;

    const entry = buildEntry({
      year,
      month: periodNo,
      interestPeriod: periodNo,
      capitalInstallmentNumber: periodNo,
      capitalInstallmentAmount,
      currentCreditAmount,
      interestInstallmentAmount,
      debt,
    });

    if (parseFloat(entry.capitalWithInterestInstallmentAmount) <= 0) {
      break;
    }

    output.push(entry);
  }

  const firstInstallmentAfterSubvention =
    hasSubvention &&
    output.find(
      entry => entry.interestPeriod === firstEntryAfterSubventionIndex
    );

  return {
    entries: output,
    creditAmount,
    creditAmountAfterSubvention: creditAmount - subventionAmount,
    installmentAmountAfterSubvention:
      (firstInstallmentAfterSubvention &&
        firstInstallmentAfterSubvention.capitalWithInterestInstallmentAmount) ||
      0,
  };
};

export const calculateCreditOfferFixedInstallments = (input = null) => {
  const {
    hasSubvention,
    subventionAmount,
    subventionReceivingMonth,
    subventionReceivingYear,
    repaymentTimespan,
    creditRate,
    creditAmount,
  } = input;

  const capitalInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.capitalInstallmentFrequency,
  });
  const interestInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.interestInstallmentFrequency,
  });
  const creditPeriod = repaymentTimespan * 12;
  const numberOfInterestInstallments =
    interestInstallmentFrequency * repaymentTimespan;

  const output = [];

  let creditBaseAfterSubvention = 0;
  let firstEntryAfterSubventionIndex = 0;
  let month = 0;
  let interestPeriod = 0;

  const installmentsRange = numberOfInterestInstallments;

  for (let periodNo = 1; periodNo <= installmentsRange; periodNo += 1) {
    const previousEntry = getPreviousEntry({ output, periodNo });

    const year = Math.ceil(periodNo / interestInstallmentFrequency);

    if (hasSubvention && parseFloat(subventionAmount) > 0) {
      // WITH SUBVENTION
      month =
        periodNo === 1
          ? year * (12 / interestInstallmentFrequency)
          : previousEntry.month + 12 / interestInstallmentFrequency >
            repaymentTimespan * 12
          ? ""
          : previousEntry.month + 12 / interestInstallmentFrequency;
      interestPeriod = periodNo;
    } else {
      // NO SUBVENTION
      month =
        periodNo === 1
          ? year * (12 / interestInstallmentFrequency)
          : previousEntry.month + 12 / interestInstallmentFrequency >
            repaymentTimespan * 12
          ? ""
          : previousEntry.month + 12 / interestInstallmentFrequency;
      interestPeriod =
        periodNo === 1
          ? periodNo
          : previousEntry.interestPeriod + 1 >
            Math.ceil((creditPeriod / 12) * interestInstallmentFrequency)
          ? ""
          : previousEntry.interestPeriod + 1;
    }

    const capitalInstallmentNumber = Math.ceil(
      periodNo / (interestInstallmentFrequency / capitalInstallmentFrequency)
    );
    const subventionRepaymentMonth = getDifferenceInMonths({
      from: { year: new Date().getFullYear(), month: new Date().getMonth() },
      to: {
        year: subventionReceivingYear,
        month: subventionReceivingMonth,
      },
    });

    let currentCreditAmount = 0;
    let capitalWithInterestInstallmentAmount = 0;
    let rate = 0;
    let nperiod = 0;
    let pv = 0;
    let interestInstallmentAmount = 0;
    let capitalInstallmentAmount = 0;

    // WITH SUBVENTION
    if (hasSubvention && parseFloat(subventionAmount) > 0) {
      const installmentsCountAfterSubvention = Math.ceil(
        (creditPeriod - subventionRepaymentMonth) /
          (12 / capitalInstallmentFrequency)
      );

      const isSubventionRepaymentMonth =
        subventionRepaymentMonth - month === 0 && !creditBaseAfterSubvention;

      if (isSubventionRepaymentMonth) {
        const nextEntryCapitalWithInterestInstallmentAmount = getCapitalWithInterestInstallmentAmountWithSubvention(
          {
            creditRate,
            capitalInstallmentFrequency,
            month,
            subventionRepaymentMonth,
            installmentsCountAfterSubvention,
            creditBaseAfterSubvention,
            numberOfInterestInstallments,
            creditAmount,
          }
        );

        const nextEntryInterestInstallmentAmount =
          nextEntryCapitalWithInterestInstallmentAmount !== 0
            ? month > subventionRepaymentMonth
              ? (previousEntry.currentCreditAmount * creditRate) /
                100 /
                interestInstallmentFrequency
              : (previousEntry.currentCreditAmount * creditRate) / 100 / 12
            : 0;

        const nextEntryCapitalInstallmentAmount =
          nextEntryCapitalWithInterestInstallmentAmount !== 0
            ? nextEntryCapitalWithInterestInstallmentAmount -
              nextEntryInterestInstallmentAmount
            : 0;

        let nextEntryCurrentCreditAmount = 0;

        if (previousEntry && previousEntry.currentCreditAmount) {
          nextEntryCurrentCreditAmount =
            month > subventionRepaymentMonth
              ? previousEntry.currentCreditAmount -
                nextEntryCapitalInstallmentAmount
              : month === subventionRepaymentMonth
              ? previousEntry.currentCreditAmount -
                nextEntryCapitalInstallmentAmount -
                subventionAmount
              : previousEntry.currentCreditAmount -
                nextEntryCapitalInstallmentAmount;
        }

        creditBaseAfterSubvention = nextEntryCurrentCreditAmount;
        firstEntryAfterSubventionIndex = periodNo + 1;
      }

      capitalWithInterestInstallmentAmount = getCapitalWithInterestInstallmentAmountWithSubvention(
        {
          creditRate,
          capitalInstallmentFrequency,
          month,
          subventionRepaymentMonth,
          installmentsCountAfterSubvention,
          creditBaseAfterSubvention,
          numberOfInterestInstallments,
          creditAmount,
        }
      );

      interestInstallmentAmount = getInterestInstallmentAmountWithSubvention(
        {
          capitalWithInterestInstallmentAmount,
          month,
          subventionRepaymentMonth,
          creditRate,
          interestInstallmentFrequency,
          creditAmount,
          previousEntry,
        },
        {
          isFirstEntry: periodNo === 1,
        }
      );

      capitalInstallmentAmount = getCapitalInstallmentAmountWithSubvention({
        capitalWithInterestInstallmentAmount,
        interestInstallmentAmount,
      });

      currentCreditAmount = getCreditAmountWithSubvention(
        {
          month,
          subventionRepaymentMonth,
          creditAmount,
          capitalInstallmentAmount,
          subventionAmount,
          previousEntry,
        },
        { isFirstEntry: periodNo === 1 }
      );
    } else {
      // NO SUBVENTION
      rate = creditRate / capitalInstallmentFrequency / 100;
      nperiod = (creditPeriod / 12) * capitalInstallmentFrequency;
      pv = -1 * creditAmount;
      interestInstallmentAmount = ipmt(rate, interestPeriod, nperiod, pv);

      capitalWithInterestInstallmentAmount =
        parseFloat(interestInstallmentAmount) !== 0
          ? pmt(rate, nperiod, pv)
          : 0;

      capitalInstallmentAmount =
        capitalWithInterestInstallmentAmount - interestInstallmentAmount;

      currentCreditAmount =
        periodNo === 1
          ? creditAmount - capitalInstallmentAmount
          : previousEntry.currentCreditAmount - capitalInstallmentAmount;
    }

    const debt = previousEntry
      ? parseFloat(
          previousEntry.currentCreditAmount -
            previousEntry.currentSubventionAmount
        )
      : creditAmount;

    const entry = buildEntry({
      year,
      month,
      interestPeriod,
      capitalInstallmentNumber,
      capitalInstallmentAmount,
      currentCreditAmount,
      interestInstallmentAmount,
      debt,
    });

    if (parseFloat(entry.capitalWithInterestInstallmentAmount) <= 0) {
      break;
    }

    output.push(entry);
  }

  const firstInstallmentAfterSubvention =
    hasSubvention &&
    output.find(
      entry => entry.interestPeriod === firstEntryAfterSubventionIndex
    );

  return {
    entries: output,
    creditAmount,
    creditAmountAfterSubvention: creditAmount - subventionAmount,
    installmentAmountAfterSubvention:
      (firstInstallmentAfterSubvention &&
        firstInstallmentAfterSubvention.capitalWithInterestInstallmentAmount) ||
      0,
  };
};

export const calculateCreditOffer = (input = null) => {
  const {
    hasSubvention,
    subventionAmount,
    subventionReceivingMonth, // number of month starting from 1, e.g. 1 - january, 12 - december
    subventionReceivingYear, // full year, e.g. 2020
    repaymentTimespan, // in years, e.g. 15
    creditRate, // in percentage, e.g. 5
    creditAmount,
  } = input;

  const capitalInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.capitalInstallmentFrequency,
  });
  const interestInstallmentFrequency = timespanTypeToInstallmentFrequency({
    type: input.interestInstallmentFrequency,
  });
  const creditPeriod = repaymentTimespan * 12;
  const numberOfCapitalInstallments = Math.ceil(
    (creditPeriod / 12) * capitalInstallmentFrequency
  );
  const numberOfInterestInstallments =
    interestInstallmentFrequency * repaymentTimespan;
  const installmentAmount =
    creditAmount / (creditPeriod / 12) / capitalInstallmentFrequency;

  const output = [];

  let creditBaseAfterSubvention = 0;
  let firstEntryAfterSubventionIndex = 0;

  for (let periodNo = 1; periodNo <= numberOfInterestInstallments; periodNo++) {
    const previousEntry = getPreviousEntry({ output, periodNo });

    const year = Math.ceil(periodNo / interestInstallmentFrequency);
    const month = getCurrentInstallmentMonth(
      { year, interestInstallmentFrequency, previousEntry, repaymentTimespan },
      { isFirstEntry: periodNo === 1 }
    );
    const interestPeriod = getCurrentInstallmentInterestPeriod(
      { periodNo, previousEntry, interestInstallmentFrequency, creditPeriod },
      { isFirstEntry: periodNo === 1 }
    );
    const capitalInstallmentNumber = Math.ceil(
      periodNo / (interestInstallmentFrequency / capitalInstallmentFrequency)
    );
    const capitalInstallmentNumberNextEntry = Math.ceil(
      (periodNo + 1) /
        (interestInstallmentFrequency / capitalInstallmentFrequency)
    );
    const subventionRepaymentMonth = getDifferenceInMonths({
      from: { year: new Date().getFullYear(), month: new Date().getMonth() },
      to: {
        year: subventionReceivingYear,
        month: subventionReceivingMonth,
      },
    });

    let capitalInstallmentAmount = 0;
    let currentCreditAmount = 0;

    if (hasSubvention && parseFloat(subventionAmount) > 0) {
      // WITH SUBVENTION
      if (subventionRepaymentMonth - month <= 0 && !creditBaseAfterSubvention) {
        creditBaseAfterSubvention =
          previousEntry.currentCreditAmount -
          previousEntry.capitalInstallmentAmount -
          subventionAmount;
        firstEntryAfterSubventionIndex = periodNo + 1;
      }

      const creditPeriodAfterSubvention =
        creditPeriod - subventionRepaymentMonth;

      const installmentAmountAfterSubvention =
        subventionRepaymentMonth - month >= 0
          ? creditAmount
          : creditBaseAfterSubvention /
            (creditPeriodAfterSubvention / 12) /
            capitalInstallmentFrequency;

      if (periodNo === 1) {
        // ONLY FIRST ENTRY
        capitalInstallmentAmount =
          capitalInstallmentNumber !== capitalInstallmentNumberNextEntry &&
          capitalInstallmentNumber < numberOfCapitalInstallments
            ? month >= subventionRepaymentMonth
              ? installmentAmountAfterSubvention
              : installmentAmount
            : 0;

        currentCreditAmount =
          month === subventionRepaymentMonth
            ? creditAmount - capitalInstallmentAmount - subventionAmount
            : creditAmount - capitalInstallmentAmount;
      } else {
        // REST OF ENTRIES
        const sumOfCapitalInstallments = output.reduce(
          (prev, curr) => prev + parseFloat(curr.capitalInstallmentAmount),
          0
        );

        capitalInstallmentAmount =
          capitalInstallmentNumber !== capitalInstallmentNumberNextEntry &&
          capitalInstallmentNumber <= numberOfCapitalInstallments
            ? sumOfCapitalInstallments +
                (month > subventionRepaymentMonth
                  ? installmentAmountAfterSubvention
                  : installmentAmount) >
              creditAmount
              ? creditAmount - sumOfCapitalInstallments
              : month > subventionRepaymentMonth
              ? installmentAmountAfterSubvention
              : installmentAmount
            : 0;

        currentCreditAmount =
          month >= subventionRepaymentMonth &&
          previousEntry.month < subventionRepaymentMonth
            ? previousEntry.currentCreditAmount -
              previousEntry.capitalInstallmentAmount -
              subventionAmount
            : previousEntry.currentCreditAmount - capitalInstallmentAmount;
      }
    } else {
      // NO SUBVENTION
      capitalInstallmentAmount =
        capitalInstallmentNumber !== capitalInstallmentNumberNextEntry &&
        capitalInstallmentNumber <= numberOfCapitalInstallments
          ? capitalInstallmentNumber === numberOfCapitalInstallments
            ? creditAmount -
              installmentAmount * (numberOfCapitalInstallments - 1)
            : installmentAmount
          : 0;

      currentCreditAmount =
        periodNo === 1
          ? creditAmount - capitalInstallmentAmount
          : previousEntry.currentCreditAmount - capitalInstallmentAmount;
    }

    const interestInstallmentAmount =
      ((currentCreditAmount + capitalInstallmentAmount) * (creditRate / 100)) /
      interestInstallmentFrequency;

    const debt = previousEntry
      ? previousEntry.currentCreditAmount -
        previousEntry.currentSubventionAmount
      : creditAmount;

    const entry = buildEntry({
      year,
      month,
      interestPeriod,
      capitalInstallmentNumber,
      capitalInstallmentAmount,
      currentCreditAmount,
      interestInstallmentAmount,
      debt,
    });

    if (parseFloat(entry.capitalWithInterestInstallmentAmount) <= 0) {
      break;
    }

    output.push(entry);
  }

  const firstInstallmentAfterSubvention =
    hasSubvention &&
    output.find(
      entry => entry.interestPeriod === firstEntryAfterSubventionIndex
    );

  return {
    entries: output,
    creditAmount,
    creditAmountAfterSubvention: creditAmount - subventionAmount,
    installmentAmountAfterSubvention:
      (firstInstallmentAfterSubvention &&
        firstInstallmentAfterSubvention.capitalWithInterestInstallmentAmount) ||
      0,
  };
};

export const calculateCreditOfferDescendingInstallmentsWithSubvention = (
  input = null
) => {
  const {
    subventionAmount,
    subventionReceivingMonth: subventionReceivingMonthIndex, // number of month starting from 1, e.g. 1 - january, 12 - december
    subventionReceivingYear, // full year, e.g. 2020
    repaymentTimespan, // in years, e.g. 15
    creditRate, // in percentage, e.g. 5
    creditAmount,
  } = input;

  const capitalInstallmentsCountInYear = timespanTypeToNumberOfInstallments({
    type: input.capitalInstallmentFrequency,
  });
  const interestInstallmentsCountInYear = timespanTypeToNumberOfInstallments({
    type: input.interestInstallmentFrequency,
  });
  const creditPeriod = repaymentTimespan * 12;
  const subventionRepaymentMonth = differenceInMonths(
    new Date(subventionReceivingYear, subventionReceivingMonthIndex),
    new Date()
  );
  const output = [];

  let sumOfInstallmentsWithoutSubvention = 0;

  for (let periodNo = 1; periodNo <= creditPeriod; periodNo++) {
    const previousEntry = getPreviousEntry({ output, periodNo });

    const month = periodNo;
    const year = Math.ceil(periodNo / 12);

    const capitalPeriod = Math.ceil(periodNo / capitalInstallmentsCountInYear);
    const interestPeriod = Math.ceil(
      periodNo / interestInstallmentsCountInYear
    );

    let isSubventionRepaymentMonth = false;
    let capitalInstallmentAmount = 0;
    let interestInstallmentAmount = 0;
    let currentSubventionAmount = 0;
    let installmentAmountWithoutSubvention = 0;
    let currentCreditAmount = 0;

    if (periodNo === 1) {
      currentCreditAmount = creditAmount;
    } else {
      currentCreditAmount =
        previousEntry.currentCreditAmount -
        previousEntry.currentSubventionAmount -
        previousEntry.capitalInstallmentAmount;
    }

    isSubventionRepaymentMonth = month === subventionRepaymentMonth;
    currentSubventionAmount = isSubventionRepaymentMonth ? subventionAmount : 0;

    const currentInterestPeriod = Math.ceil(
      periodNo / interestInstallmentsCountInYear
    );
    const nextInterestPeriod = Math.ceil(
      (periodNo + 1) / interestInstallmentsCountInYear
    );
    interestInstallmentAmount =
      currentInterestPeriod !== nextInterestPeriod
        ? (currentCreditAmount * creditRate) /
          100 /
          (12 / interestInstallmentsCountInYear)
        : 0;

    const currentCapitalPeriod = Math.ceil(
      periodNo / capitalInstallmentsCountInYear
    );
    const nextCapitalPeriod = Math.ceil(
      (periodNo + 1) / capitalInstallmentsCountInYear
    );
    installmentAmountWithoutSubvention =
      currentCapitalPeriod !== nextCapitalPeriod
        ? creditAmount /
          repaymentTimespan /
          (12 / capitalInstallmentsCountInYear)
        : 0;

    const installmentNumberAfterSubventionReceive =
      subventionRepaymentMonth % capitalInstallmentsCountInYear === 0
        ? subventionRepaymentMonth / capitalInstallmentsCountInYear + 1
        : Math.ceil(subventionRepaymentMonth / capitalInstallmentsCountInYear);

    if (installmentAmountWithoutSubvention === 0) {
      capitalInstallmentAmount = 0;
    } else if (subventionRepaymentMonth < periodNo) {
      capitalInstallmentAmount =
        (creditAmount - subventionAmount - sumOfInstallmentsWithoutSubvention) /
        (repaymentTimespan * (12 / capitalInstallmentsCountInYear) -
          installmentNumberAfterSubventionReceive +
          1);
    } else {
      sumOfInstallmentsWithoutSubvention += installmentAmountWithoutSubvention;
      capitalInstallmentAmount =
        creditAmount /
        repaymentTimespan /
        (12 / capitalInstallmentsCountInYear);
    }

    const debt = previousEntry
      ? parseFloat(
          previousEntry.currentCreditAmount -
            previousEntry.currentSubventionAmount -
            previousEntry.capitalInstallmentAmount
        )
      : creditAmount;

    if (Math.sign(currentCreditAmount) === -1) {
      break;
    }

    const entry = buildEntry({
      year,
      month,
      interestPeriod,
      capitalInstallmentNumber: capitalPeriod,
      capitalInstallmentAmount,
      currentCreditAmount,
      interestInstallmentAmount,
      debt,
      currentSubventionAmount,
    });

    output.push(entry);
  }

  const firstInstallmentAfterSubvention = getFirstAnyInstallmentAfterSubvention(
    {
      output,
      subventionRepaymentMonth,
    }
  );
  const firstCapitalInstallmentAfterSubvention = getFirstCapitalInstallmentAfterSubvention(
    {
      output,
      subventionRepaymentMonth,
    }
  );

  const trimmedOutput = output.filter(
    entry =>
      !(
        parseFloat(entry.capitalInstallmentAmount) === 0 &&
        parseFloat(entry.interestInstallmentAmount) === 0 &&
        parseFloat(entry.capitalWithInterestInstallmentAmount) === 0
      )
  );

  let creditAmountAfterSubvention;
  let installmentAmountAfterSubvention;
  if (firstInstallmentAfterSubvention) {
    creditAmountAfterSubvention =
      firstInstallmentAfterSubvention.currentCreditAmount;
  }
  if (firstCapitalInstallmentAfterSubvention) {
    installmentAmountAfterSubvention =
      firstCapitalInstallmentAfterSubvention.capitalInstallmentAmount;
  }

  return {
    entries: trimmedOutput,
    creditAmount,
    creditAmountAfterSubvention,
    installmentAmountAfterSubvention,
  };
};

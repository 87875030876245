import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../components/icons/ArrowRight";
import routes from "../../utils/cftRoutes";

export default function BackToCoops() {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const history = useHistory();

  return (
    <button
      style={{
        border: "none",
      }}
      type="button"
      onClick={() => {
        history.push(routes.TURKEY.COOPS);
      }}
    >
      <ArrowRight
        style={{
          transform: "rotate(180deg)",
          marginRight: "16px",
        }}
      />
      {t("cftTurkey:chickenBroilerCoopForm.back", "Indyczniki referencyjne")}
    </button>
  );
}

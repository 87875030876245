import {
  LandingPage,
  Wizard,
  Contact,
  PageViewer,
  KredytInwestycyjny,
  AllegroContactForm,
} from "./pages";

import { WIZARD_URL } from "./consts/consts";

const prfRouting = [
  {
    path: "/kredytomat",
    component: LandingPage,
  },
  {
    path: "/kredytomat/nowe-perspektywy-rozwoju-twojego-gospodarstwa",
    component: KredytInwestycyjny,
  },
  {
    path: WIZARD_URL,
    component: Wizard,
  },
  {
    path: "/kredytomat/kontakt",
    component: Contact,
  },
  {
    path: "/kredytomat/formularz-allegro",
    component: AllegroContactForm,
  },
  {
    path: "/kredytomat/:slug",
    component: PageViewer,
  },
];

export default prfRouting;

/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import PropTypes from "prop-types";
import "./ImageRadioButton.scss";

export const ImageRadioButton = props => {
  return (
    <div className="imageRadioButton">
      <label htmlFor={props.id}>
        <input
          id={props.id}
          type="radio"
          onChange={props.onChange}
          hidden
          {...props}
        />
        <div
          role="radio"
          aria-checked={props.value}
          onClick={event => {
            event.preventDefault();
            props.onChange(props.value);
          }}
          className={`radio-button-icon ${
            props.checked
              ? "imageRadioButton--active"
              : "imageRadioButton--inactive"
          }`}
        >
          <div
            className={`radio-button-icon ${
              props.checked
                ? "radio-button-icon--active"
                : "radio-button-icon--inactive"
            }`}
          >
            {props.icon}
          </div>
        </div>
      </label>
      <span className={`label${props.checked ? " label--active" : ""}`}>
        {props.label}
      </span>
    </div>
  );
};

ImageRadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

ImageRadioButton.defaultProps = {
  checked: false,
};

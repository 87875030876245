export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL";

export const GET_FILTERED_ARTICLES = "GET_FILTERED_ARTICLES";
export const GET_FILTERED_ARTICLES_SUCCESS = "GET_FILTERED_ARTICLES_SUCCESS";
export const GET_FILTERED_ARTICLES_FAIL = "GET_FILTERED_ARTICLES_FAIL";

export const GET_SUGGESTED_ARTICLES = "GET_SUGGESTED_ARTICLES";
export const GET_SUGGESTED_ARTICLES_SUCCESS = "GET_SUGGESTED_ARTICLES_SUCCESS";
export const GET_SUGGESTED_ARTICLES_FAIL = "GET_SUGGESTED_ARTICLES_FAIL";

export const GET_ARTICLES_POPULAR_SUCCESS = "GET_ARTICLES_POPULAR_SUCCESS";

export const GET_ARTICLES_NEWEST_SUCCESS = "GET_ARTICLES_NEWEST_SUCCESS";

import React from "react";

import "./CftGroup.scss";

function CftGroup({ children, header, ...props }) {
  return (
    <div className="cft-group" {...props}>
      <span className="cft-group__header">{header}</span>
      {children}
    </div>
  );
}

export default CftGroup;

import TurkeyPage from "./Turkey.page";
import TurkeyCoopPage from "./TurkeyCoop.page";
import TurkeyCoopsPage from "./TurkeyCoops.page";
import TurkeyEmissionsPage from "./TurkeyEmissions.page";
import TurkeyReportPage from "./TurkeyReport.page";

export default {
  Main: TurkeyPage,
  Coop: TurkeyCoopPage,
  Coops: TurkeyCoopsPage,
  Emission: TurkeyEmissionsPage,
  Report: TurkeyReportPage,
};

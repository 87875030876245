import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../../index";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function Step1({ handleClose, setStep, updateStyle }) {
  const { t } = useTranslation(["cftChickenBroiler"]);

  useEffect(() => {
    const dev = document.querySelector(".cft-button--isAlternative");

    window.scrollTo({
      top: dev.getBoundingClientRect().top + window.pageYOffset,
      behavior: "smooth",
    });
    setTimeout(() => {
      const dev = document.querySelector(".cft-button--isAlternative");
      const box = dev.getBoundingClientRect();
      const style = {
        width: "100vw",
        height: box.height + 32,
        top: box.top - 16,
        left: 0,
        opacity: 1,
      };
      updateStyle(style);
    }, 800);
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "15vw",
        }}
      >
        <h3 className="cft-onboarding__item__title">
          {t("cftChickenBroiler:onboarding.step1.title", "przejdź dalej")}
        </h3>
        <p className="cft-onboarding__item__subtitle">
          {t(
            "cftChickenBroiler:onboarding.step1.subtitle",
            "Jeśli uzupełniłeś poprawnie wszystkie pola w danej sekcji i chcesz  przejść dalej, użyj głównego przycisku z komendą opisującą kolejny krok kalkulacji. Aby ułatwić Ci proces, przycisk będzie aktywny dopiero po wypełnieniu wszystkich obowiązkowych pół formularza."
          )}
        </p>
        <CftTank justifyContent="center" alignItems="center">
          <CftButton
            variant="transparent"
            onClick={handleClose}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.skip", "Pomiń")}
          </CftButton>
          <CftButton
            variant="active"
            onClick={() => setStep(step => step + 1)}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.next", "Dalej")}
          </CftButton>
        </CftTank>
      </div>
    </>
  );
}

export default Step1;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";
import Cookies from "js-cookie";

import { useCft } from "../../utils/CftProvider";
import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftTank,
  CftOnboarding,
} from "../../components";
import routes from "../../utils/cftRoutes";
import DetailsFarmlandsForm, {
  DetailsFarmlandsFormContext,
} from "../../forms/farmlands/DetailsFarmlandsForm/DetailsFarmlands.form";

export const ONBOARDING_COOKIE = "sadda3fwsdfas";

function DetailsFarmlandsPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveFarmlands, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={0}
          cropName={meta.title.farmlands}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="farmlands" />
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveFarmlands({
                    details: {
                      ...values,
                      name: values.name.slice(0, 30),
                    },
                  });
                  history.push(routes.FARMLANDS.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:detailsFarmlands.title", "Szczegóły uprawy")}
          onNext={submitForm}
          onBack={!Cookies.get(ONBOARDING_COOKIE) ? () => "" : ""}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:detailsFarmlands.title", "Szczegóły uprawy")}</h3>
            <p>
              {t(
                "cft:detailsFarmlands.subtitle",
                "Wprowadź informacje dotyczące kalkulowanej uprawy i pola."
              )}
            </p>
          </>
        }
      >
        <DetailsFarmlandsForm />
        <CftReportProgress type="farmlands" />
      </CftSection>
      <CftOnboarding />
    </CftLayout>
  );
}

export default () => {
  return (
    <DetailsFarmlandsFormContext>
      <DetailsFarmlandsPage />
    </DetailsFarmlandsFormContext>
  );
};

import React from "react";
import PropTypes from "prop-types";
import "./Header.scss";

// headertype - props value define header type

export const Header = props => {
  return (
    <header className={`header--${props.headertype}`} {...props}>
      <p>{props.children}</p>
    </header>
  );
};

Header.propTypes = {
  headertype: PropTypes.string.isRequired,
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

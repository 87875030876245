import * as actions from "./actions";

const InitialState = {
  count: 0,
  next: "",
  previous: "",
  results: [],
  agro: [],
  isError: false,
};

export default function articlesCategories(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_ARTICLES_CATEGORIES_SUCCESS: {
      const {
        data: { results, ...data },
      } = action.payload;
      return {
        ...state,
        ...data,
        results: results.filter(item => item.membership === 1),
        agro: results.filter(item => item.membership === 2),
        isError: false,
      };
    }
    case actions.GET_ARTICLES_CATEGORIES_FAIL: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return state;
  }
}

export const getArticlesCategory = token => {
  let headers;
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return {
    type: "GET_ARTICLES_CATEGORIES",
    payload: {
      request: {
        url: "/api/v1/article-types/?limit=20",
        method: "GET",
        headers,
      },
    },
  };
};

export const types = [
  {
    name: "Mineralny",
    fertilisers: [
      {
        pl: "Azotan amonu — 33,5% N (granulowany)",
        gb: "Ammonium nitrate - 33.5% N (granulated)",
        id: 46,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Azotan amonu — 33,5% M (z granulacji wieżowej)",
        gb: "Ammonium nitrate - 33.5% N (prilled)",
        id: 47,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Siarczan amonu - 21% N",
        gb: "Ammonium sulphate - 21% N",
        id: 3,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Saletrosiarczan amonu — 26% N",
        gb: "Ammonium sulphate nitrate - 26%N",
        id: 4,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Amoniak bezwodny — 82% N",
        gb: "Anhydrous ammonia - 82% N",
        id: 5,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Azotan amonowo-wapniowy — 27% N",
        gb: "Calcium ammonium nitrate - 27% N",
        id: 6,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Azotan wapnia - 15,5% N",
        gb: "Calcium nitrate - 15.5% N",
        id: 47,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Nawóz NPK — 15% N / 15% K2O / 15% P2O5 (metoda mieszania kwasów)",
        gb: "Compound NPK - 15% N / 15% K2O / 15% P2O5 (mixed-acid process)",
        id: 48,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Nawóz NPK — 15% N / 15% K2O / 15% P2O5 (metoda nitrofosfatowa)",
        gb:
          "Compound NPK - 15% N / 15% K2O / 15% P2O5 (nitrophosphate process)",
        id: 49,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Wodorofosforan diamonu — 18% N / 46% P2O5",
        gb: "Diammonium phosphate - 18% N / 46% P2O5",
        id: 10,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Diwodorofosforan amonu — 11% N / 52% P2O5",
        gb: "Monoammonium phosphate - 11% N / 52% P2O5",
        id: 15,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Chlorek potasu — 60% K2O",
        gb: "Muriate of potash / Potassium chloride - 60% K2O",
        id: 16,
        production_ids: [18],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Fosforan / Fosforan skały - 32% P2O5",
        gb: "Phosphate/Rock Phosphate - 32% P2O5",
        id: 17,
        production_ids: [18],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Polihalit - 48% SO3 / 14% K2O / 6% MgO / 17% CaO",
        gb: "Polyhalite - 48% SO3 / 14% K2O / 6% MgO / 17% CaO",
        id: 59,
        production_ids: [22],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Siarczan potasu - 50% K2O / 45% SO3",
        gb: "Potassium sulphate - 50% K2O / 45% SO3",
        id: 18,
        production_ids: [18],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Superfosfat — 21% P2O5",
        gb: "Super phosphate - 21% P2O5",
        id: 19,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Superfosfat potrójny — 48% P2O5",
        gb: "Triple super phosphate - 48% P2O5",
        id: 20,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 6],
      },
      {
        pl: "Mocznik - 46% N",
        gb: "Urea - 46% N",
        id: 45,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 5],
      },
      {
        pl: "Roztwór saletrzano-mocznikowy — 32% N",
        gb: "Urea ammonium nitrate solution - 32% N",
        id: 22,
        production_ids: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
        application_method_ids: [1, 2, 5],
      },
    ],
  },
  {
    name: "Organiczny",
    fertilisers: [
      {
        pl: "Produkty pofermentacyjne (bydło) — 0,26% N",
        gb: "Cattle digestate - 0.26% N",
        id: 51,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Produkty pofermentacyjne bydlęce — 0,6% N",
        gb: "Cattle digestate - 0.6% N",
        id: 50,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Kompost (produkcja w pełni napowietrzona) - 1% N",
        gb: "Compost (fully aerated production) - 1% N",
        id: 24,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Kompost (produkcja nie w pełni tlgbowa) — 1% N",
        gb: "Compost (non-fully aerated production) - 1% N",
        id: 25,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Kompost (zeroemisyjny) — 1% N",
        gb: "Compost (zero emissions) - 1% N",
        id: 23,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Produkty pofermentacyjne (konie) — 0,7% N",
        gb: "Horse digestate - 0.7% N",
        id: 52,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Produkty pofermentacyjne (świnie) — 0,36% N",
        gb: "Pig digestate - 0.36% N",
        id: 54,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Produkty pofermentacyjne (świnie) — 0,7% N",
        gb: "Pig digestate - 0.7% N",
        id: 54,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Poferment z warstwy drobiu - 1,9% N",
        gb: "Poultry layer digestate - 1.9% N",
        id: 55,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Produkty pofermentacyjne (drób) — 1,9% N",
        gb: "Sheep digestate - 0.7% N",
        id: 56,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Ściółka dla drobiu/indyków — 3% N",
        gb: "Broiler/Turkey litter - 3% N",
        id: 31,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Obornik bydlęcy — 0,6% N",
        gb: "Cattle manure - 0.6% N",
        id: 26,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Gnojowica bydlęca - 0,26% N",
        gb: "Cattle slurry - 0.26% N",
        id: 32,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Obornik koński - 0,7% N.",
        gb: "Horse manure - 0.7% N",
        id: 29,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Obornik świński - 0,7% N.",
        gb: "Pig manure - 0.7% N",
        id: 27,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Gnojowica wieprzowa - 0,36% N",
        gb: "Pig slurry - 0.36% N",
        id: 33,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Pomiot ptasi — 1,9% N",
        gb: "Poultry layer manure - 1.9% N",
        id: 30,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Oddzielona gnojowica wieprzowa (część płynna) 0,36% N",
        gb: "Separated pig slurry (liquid part) 0.36% N",
        id: 34,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Oddzielona gnojowica wieprzowa (część stała) - 0,5% N",
        gb: "Separated pig slurry (solid part) - 0.5% N",
        id: 35,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
      {
        pl: "Obornik owczy - 0,7% N.",
        gb: "Sheep manure - 0.7% N",
        id: 28,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
    ],
  },
  {
    name: "Wapienny",
    fertilisers: [
      {
        pl: "Wapień - 55% CaCO3 / 29% CaO",
        gb: "Limestone - 55% CaCO3 / 29%CaO",
        id: 13,
        production_ids: [18],
        application_method_ids: [2, 5],
      },
    ],
  },
];

export const productions = [
  {
    production_id: 8,
    pl: "Europa 2014",
    gb: "Europe 2014",
  },
  {
    production_id: 9,
    pl: "Ameryka Północna 2014",
    gb: "N America 2014",
  },
  {
    production_id: 10,
    pl: "Ameryka Południowa 2014",
    gb: "S America 2014",
  },
  {
    production_id: 11,
    pl: "Afryka 2014",
    gb: "Africa 2014",
  },
  {
    production_id: 12,
    pl: "Bliski Wschód 2014",
    gb: "Middle East 2014",
  },
  {
    production_id: 13,
    pl: "Rosja (kraje CIS) 2014",
    gb: "Russia (CIS countries) 2014",
  },
  {
    production_id: 14,
    pl: "Azja Południowo-Wschodnia 2014",
    gb: "SE Asia 2014",
  },
  {
    production_id: 15,
    pl: "S Asia 2014",
    gb: "S Asia 2014",
  },
  {
    production_id: 16,
    pl: "Oceania 2014",
    gb: "Oceania 2014",
  },
  {
    production_id: 17,
    pl: "Chiny 2014",
    gb: "China 2014",
  },
  {
    production_id: 18,
    pl: "Świat 2014",
    gb: "World 2014",
  },
  {
    production_id: 22,
    pl: "Świat 2019",
    gb: "World 2019",
  },
];

export const methods = [
  {
    id: 1,
    pl: "Stosowanie w roztworze",
    gb: "Apply in solution",
    description: "Apply in solution",
  },
  {
    id: 2,
    pl: "Stosowanie rozrzutowe",
    gb: "Broadcast",
    description: "Broadcast",
  },
  {
    id: 5,
    pl: "Przyoranie",
    gb: "Incorporate",
    description: "Incorporate",
  },
  {
    id: 6,
    pl: "Fertygacja",
    gb: "Fertigation - subsurface drip",
    description: "Fertigation - subsurface drip",
  },
];

export const pesticide = [
  {
    id: 1,
    gb: "seed_treatmgbt",
    description: "Seed treatmgbt",
  },
  {
    id: 2,
    gb: "soil_treatmgbt",
    description: "Soil treatmgbt",
  },
  {
    id: 3,
    gb: "post_emerggbce",
    description: "Post-emerggbce",
  },
];

import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "recompose";
import useSWR from "swr";
import {
  formatDate,
  getMainCategory,
} from "../../../../../services/helpers/articles";

import { Article } from "../../../../common/Article/Article";
import { getListOfArticleCategories } from "../../../../../services/redux/articles/widget/reducer";

import API from "../../../../../services/apiHelpers";

import "./Sections.scss";
import { ArticleCardWrapper } from "../../../../common/Article/ArticleCardWrapper";

export function usePromotedArticles() {
  const { i18n } = useTranslation();
  const url = "/api/v2/articles/";
  const { data } = useSWR(url, () =>
    API().get(url, {
      params: {
        limit: 4,
        // category: 74,
        lang_code: Cookies.get("user_lang")
          ? Cookies.get("user_lang").toUpperCase()
          : i18n.language.toUpperCase(),
      },
    })
  );

  return {
    articles: data ? data.data.results : [],
  };
}

const RealEstateArticlesLP = ({
  articlesTags,
  articlesCategories,
  fetchArticlesCatories,
}) => {
  const { articles } = usePromotedArticles();

  useEffect(() => {
    if (articlesCategories.length === 0) {
      fetchArticlesCatories();
    }
  }, []);

  // if (!articles.length) {
  //   return null;
  // }

  return (
    <div className="suggested-properties suggested-properties--articles">
      <div className="suggested-properties__header" xs={12}>
        <h3>
          Porady na temat zakupu nieruchomości{" "}
          <Link to="/artykuly/nieruchomosci">Wszystkie wpisy</Link>
        </h3>
      </div>
      <div xs={12}>
        <div className="Sections__articles">
          {
            articles.map(el => (
              <ArticleCardWrapper
                article={el}
                key={el.id}
                id={el.id}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(
    state => ({
      articlesTags: state.articles.articlesTags.results,
      articlesCategories: state.articles.widget.categories.results,
    }),
    dispatch => ({
      fetchArticlesCatories: () => dispatch(getListOfArticleCategories()),
    })
  )
)(RealEstateArticlesLP);

import ChickenBroilerPage from "./ChickenBroiler.page";
import ChickenBroilerCoopPage from "./ChickenBroilerCoop.page";
import ChickenBroilerCoopsPage from "./ChickenBroilerCoops.page";
import ChickenBroilerEmissionsPage from "./ChickenBroilerEmissions.page";
import ChickenBroilerReportPage from "./ChickenBroilerReport.page";

export default {
  Main: ChickenBroilerPage,
  Coop: ChickenBroilerCoopPage,
  Coops: ChickenBroilerCoopsPage,
  Emission: ChickenBroilerEmissionsPage,
  Report: ChickenBroilerReportPage,
};

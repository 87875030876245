import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useCftTurkeyValues, {
  initialStore as initialStoreTurkey,
} from "../../utils/useCftTurkeyValues";
import useCftChickenBroilerValues, {
  initialStore as initialStoreChicken,
} from "../../utils/useCftChickenBroilerValues";
import useCftPigsValues, {
  initialStore as initialStorePigs,
} from "../../utils/useCftPigsValues";
import useCftCattleValues, {
  initialStore as initialStoreCattle,
} from "../../utils/useCftCattleValues";

import routes from "../../utils/cftRoutes";

import "./CftClearForm.scss";

function CftClearForm({ type }) {
  const history = useHistory();
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { updateStore: updateChickenStore } = useCftChickenBroilerValues();
  const { updateStore: updaTurkeyStore } = useCftTurkeyValues();
  const { updateStore: updatePigsStore } = useCftPigsValues();
  const { updateStore: updateCattleStore } = useCftCattleValues();

  const handleClick = () => {
    if (type === "chicken") {
      updateChickenStore(initialStoreChicken);
    }
    if (type === "turkey") {
      updaTurkeyStore(initialStoreTurkey);
    }
    if (type === "pigs") {
      updatePigsStore(initialStorePigs);
    }
    if (type === "cattle") {
      updateCattleStore(initialStoreCattle);
    }

    setTimeout(() => {
      history.push(routes.MAIN);
    }, 200);
  };

  return (
    <div className="cft-clear-form">
      <button type="button" onClick={handleClick}>
        {t("cftChickenBroiler:CftClearForm", "Wyczyść formularz")}
      </button>
    </div>
  );
}

export default CftClearForm;

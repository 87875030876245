import React from "react";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import clx from "classnames";

import { isMobile } from "react-device-detect";
import { CftButton } from "../index";
import { Loader } from "../../../components/common/index";

import "./CftFooter.scss";

function CftFooter({ text, onNext, onBack, nextText, backText, isPending }) {
  const { t } = useTranslation(["cft"]);
  const buttonsWidth = !isMobile ? "170px" : "80px";
  return (
    <footer
      className={clx("cft-footer", {
        "cft-footer__isPending": isPending,
      })}
    >
      <Container>
        <Row>
          <Col xs={12} className="cft-footer__content">
            {isPending ? (
              <Loader />
            ) : (
              <>
                <div className="cft-footer__back">
                  {onBack && (
                    <CftButton
                      style={{ height: "45px", width: buttonsWidth }}
                      variant="transparent"
                      onClick={onBack}
                    >
                      {backText || t("cft:footer.back", "cofnij")}
                    </CftButton>
                  )}
                </div>
                <div className="cft-footer__text">
                  <span>{text}</span>
                </div>
                <div className="cft-footer__next">
                  {onNext && (
                    <CftButton
                      style={{ height: "45px", width: buttonsWidth }}
                      variant="highlighted"
                      onClick={onNext}
                    >
                      {nextText || t("cft:footer.next", "dalej")}
                    </CftButton>
                  )}
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

CftFooter.propTypes = {
  text: PropTypes.string.isRequired,
  nextText: PropTypes.string,
  onNext: PropTypes.func,
  backText: PropTypes.string,
  onBack: PropTypes.func,
  isPending: PropTypes.bool,
};

CftFooter.defaultProps = {
  onNext: null,
  onBack: null,
  backText: null,
  nextText: null,
  isPending: false,
};

export default CftFooter;

import React from "react";
import PropTypes from "prop-types";
import "./TextArea.scss";

// inputstate - define input state: active, error
// haserror - define if input has correcr values
// errorMsg - pass error message
// icon - define icon that is displayed in input

export const TextArea = props => {
  return (
    <div
      className={
        props.inputstate ? `textarea textarea--${props.inputstate}` : "textarea"
      }
    >
      <label htmlFor={props.id}>
        {props.label}
        <textarea id={props.id} {...props} />
        <div className="textarea-icon-container">{props.icon}</div>
        {props.haserror ? (
          <p className="textarea-error">{props.errormsg}</p>
        ) : null}
      </label>
    </div>
  );
};

TextArea.propTypes = {
  inputstate: PropTypes.string,
  label: PropTypes.string.isRequired,
  haserror: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errormsg: PropTypes.string,
  id: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

TextArea.defaultProps = {
  inputstate: "",
  haserror: String(false),
  errormsg: "",
  icon: "",
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { isEmpty } from "ramda";
import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftCheckbox,
  CftError,
  CftTooltip,
} from "../../components";

const TypeProduction = ({ productionCyclesId }) => {
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler"]);
  const { setFieldValue, values, errors } = useFormikContext();
  const [productionType, setProductionType] = useState(() => {
    const state = [];

    if (values.production_cycles[productionCyclesId].female_turkeys !== null) {
      state.push(2);
    }
    if (values.production_cycles[productionCyclesId].male_turkeys !== null) {
      state.push(1);
    }

    return state;
  });

  return (
    <>
      <CftLabel
        isFullWidth
        label={t(
          "cftTurkey:productionCyclesFields.productionTitle",
          "Rodzaj produkcji"
        )}
        className="mb-3"
      >
        <CftTooltip
          content={t(
            "cftTurkey:tooltip.productionCyclesFields.production",
            "Wybierz odpowiednie grupy użytkowe w tym cyklu produkcyjnym."
          )}
        />
      </CftLabel>
      <CftTank mb={2} flexDirection="column">
        <CftCheckbox
          label={t("cftTurkey:typeProduction.1")}
          checked={productionType.includes(1)}
          onClick={() => {
            setProductionType(state => {
              if (state.includes(1)) {
                setFieldValue(
                  `production_cycles[${productionCyclesId}].male_turkeys`,
                  null
                );
                return state.filter(value => value !== 1);
              }

              setFieldValue(
                `production_cycles[${productionCyclesId}].male_turkeys`,
                {
                  target_age: "",
                  falls_percent: 3,
                  quantity: "",
                  feed_components: [],
                }
              );

              return [...state, 1];
            });
          }}
        />
        <CftCheckbox
          label={t("cftTurkey:typeProduction.2")}
          checked={productionType.includes(2)}
          onClick={() => {
            setProductionType(state => {
              if (state.includes(2)) {
                setFieldValue(
                  `production_cycles[${productionCyclesId}].female_turkeys`,
                  null
                );
                return state.filter(value => value !== 2);
              }

              setFieldValue(
                `production_cycles[${productionCyclesId}].female_turkeys`,
                {
                  target_age: "",
                  falls_percent: 3,
                  quantity: "",
                  feed_components: [],
                }
              );
              return [...state, 2];
            });
          }}
        />
      </CftTank>
      <CftError
        style={{ marginTop: "-1rem", marginBottom: "1rem" }}
        isActive={productionType.length === 0 && !isEmpty(errors)}
        message={t(
          "cftTurkey:productionCyclesFields.fields.productionTypeError",
          "Należy wybrać rodzaj produkcji."
        )}
      />
    </>
  );
};

export default TypeProduction;

import Cookies from "js-cookie";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import {
  Breadcrumbs,
  ButtonFix,
  Footer,
  Metadata,
  NavigationBar,
  Typography,
} from "../../common/index";
import "./ErrorPage.scss";

class ErrorPage extends Component {
  // eslint-disable-next-line class-methods-use-this
  removeSlashes(url) {
    // eslint-disable-next-line no-param-reassign
    url = url.replace(/([/])\1/g, "");

    // eslint-disable-next-line no-param-reassign
    if (url[0] !== "/") url = `/${url}`;
    return url;
  }

  render() {
    const { currentWidth, location } = this.props;
    const token = Cookies.get("user_token");
    const { t } = this.props;
    return (
      <Container fluid>
        {location.pathname && location.pathname.search(/([/])\1/g) !== -1 ? (
          <Redirect to={this.removeSlashes(location.pathname)} />
        ) : null}
        <Metadata
          name={t("metaInfo:errorPage.title")}
          description={t("metaInfo:errorPage.description")}
        />
        <NavigationBar search />
        <div className="error-page-wrapper">
          <Container>
            <Row>
              <Col className="text-center">
                <Breadcrumbs currentName="404" />
                <Typography typographyType="h1">
                  {t("errorPage:heading")}
                </Typography>
                <p>{t("errorPage:message")}</p>
                <div>
                  <ButtonFix
                    link={token ? "/tools" : "/"}
                    buttoncolor="yellow"
                    buttonsize="big"
                  >
                    {t("errorPage:button")}
                  </ButtonFix>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer windowWidth={currentWidth} />
      </Container>
    );
  }
}

export default withTranslation(["errorPage", "metaInfo"])(ErrorPage);

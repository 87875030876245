import { useTranslation } from "react-i18next";
import { mapMilkProduction } from "../forms/dairyProduction/DetailsDairyProductionForm/detailsDairyProduction.utils";
import { mapHerdSections } from "../forms/dairyProduction/CharacteristicsDPForm/characteristicsDP.utils";

const mapDetails = data => {
  const { t } = useTranslation(["cft"]);

  const report = Object.keys(data).reduce((prev, curr) => {
    if (curr === "milk_production") {
      return [
        ...prev,
        {
          name: {
            i18n: "",
            default: "Szczegóły hodowli",
          },
          rows: mapMilkProduction(data[curr]),
        },
      ];
    }

    if (curr === "herd_sections") {
      return [
        ...prev,
        {
          name: {
            i18n: "",
            default: "Charakterystyka stada",
          },
          rows: mapHerdSections(data[curr], t),
        },
      ];
    }

    return prev;
  }, []);

  return report;
};

const useDairyDetailsReport = report => {
  const { t } = useTranslation(["cft"]);
  return {
    details: mapDetails(report, t),
  };
};

export default useDairyDetailsReport;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Langs } from "../../../services/helpers/locale";
import { updateUserData } from "../../../services/redux/user/reducer";
import { getArticleTranslation } from "../../../services/redux/article/reducer";
import { getEventTranslation } from "../../../services/redux/event/reducer";
import { ReactComponent as ChevronDown } from "../../../assets/icons/chevron-down.svg";

class LanguageNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpenCurrentLang: false };
    this.langMenu = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const {
      article,
      event,
      token,
      getArticleTranslation,
      getEventTranslation,
    } = this.props;
    if ((!prevProps.article && article) || prevProps.article !== article) {
      getArticleTranslation(article, token);
    }
    if ((!prevProps.event && event) || prevProps.event !== event) {
      getEventTranslation(event, token).then();
    }
  }

  listenClick = (e, _code) => {
    const {
      article,
      event,
      translations,
      eventTranslations,
      isMobile,
    } = this.props;
    const langs = Langs();
    const langEl = this.langMenu.current.classList;
    if (langEl.contains("is-showed")) {
      langEl.remove("is-showed");
    } else {
      langEl.add("is-showed");
    }
    const { target } = e;
    const code = _code || target.dataset.lang;
    if (article) {
      const link = translations.find(
        item => item.lang_code.toLowerCase() === code
      );
      langs.switchLang(code, true);
      this.props.history.push(
        link ? `/articles/${link.slug || link.id}` : "/articles"
      );
    } else if (event) {
      const link = eventTranslations.find(item => {
        return item.lang_code.toLowerCase() === code;
      });
      langs.switchLang(code, true);

      this.props.history.push(link ? `/events/${link.id}` : "/events");
    } else if (code) {
      langs.switchLang(code);
      this.props.history.go();
    }
  };

  // getLangsLink() {
  //   const { article, event, token, getArticleTranslation } = this.props;
  //   let langs = Langs();
  //   let langsToRedirect = langs.unactiveLangs();
  //   if (article) {
  //     console.log(langsToRedirect);
  //     langsToRedirect = langsToRedirect.map((el) => {
  //       return { ...el, link: `/articles/${}`}
  //     });
  //   }
  //   return langsToRedirect;
  // }

  render() {
    const langs = Langs();
    const activeLang = langs.activeLang();
    const unactiveLangs = langs.unactiveLangs();
    const {
      user: { id },
    } = this.props;

    if (id !== -1) return null;
    return (
      <>
        <div
          className={`navigation-buttons__button navigation-buttons__button--languages ${
            this.props.isMobile ? "text-lowercase" : "text-uppercase"
          }`}
          onMouseLeave={() => {
            if (window.innerWidth > 992) {
              this.langMenu.current.classList.remove("is-showed");
            }
          }}
        >
          <div
            onClick={e => {
              this.setState({
                isOpenCurrentLang: !this.state.isOpenCurrentLang,
              });
            }}
            onMouseEnter={() => {
              if (window.innerWidth > 992) {
                this.langMenu.current.classList.add("is-showed");
              }
            }}
            className={`navigation-buttons__button navigation-buttons__button--current-lang ${
              this.state.isOpenCurrentLang ? "is-open" : ""
            }`}
          >
            {this.props.isMobile ? (
              <div className="current-lang-btn-mobile">
                <img
                  src={activeLang.icon}
                  style={{ height: "14px", width: "auto" }}
                  alt={activeLang.lang_code}
                />
                <span>{activeLang.name}</span>
              </div>
            ) : (
              activeLang.lang_code === 'gb' ? 'en' : activeLang.lang_code
            )}{" "}
            <ChevronDown />
          </div>
          <ul ref={this.langMenu} className="navigation-buttons__langs">
            {unactiveLangs.map(item => (
              <li
                key={`lang-${item.lang_code}`}
                className=""
                data-lang={item.lang_code}
                data-link={item.link}
                onClick={e => this.listenClick(e, item.lang_code)}
              >
                <img
                  src={item.icon}
                  style={{ height: "14px", width: "auto" }}
                  alt={item.lang_code}
                />{" "}
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  token: state.user.token,
  article: state.article.grouping_id,
  event: state.event.event,
  translations: state.article.translations,
  eventTranslations: state.event.translations,
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (fields, userId, token) =>
      dispatch(updateUserData(fields, userId, token)),
    getArticleTranslation: (grouping_id, token) => {
      dispatch(getArticleTranslation(grouping_id, token));
    },
    getEventTranslation: (grouping_id, token) =>
      dispatch(getEventTranslation(grouping_id, token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LanguageNavigation));

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  CftTank,
  CftButton,
  CftFormField,
  CftBox,
  CftWarningBox,
  CftClearForm,
  CftTooltip,
  CftFormErrorFocus,
} from "../../components";

import routes from "../../utils/cftRoutes";
import CreateCoopField from "./CreateCoopField";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import ArrowRight from "../../components/icons/ArrowRight";
import TrashIcon from "../../components/icons/TrashIcon";
import PlusIcon from "../../components/icons/PlusIcon";

export default function ChickenBroilerCoopsForm() {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const history = useHistory();
  const { store, updateStore } = useCftChickenBroilerValues();

  const isNextStepAvailable =
    store.henhouses_reference.coops.length > 0 &&
    store.henhouses_reference.coops.filter(coop => coop.isValidate).length ===
      store.henhouses_reference.coops.length;

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2
          className="cft-section-title mb-3 cft-max-wrapper"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t(
            "cftChickenBroiler:chickenBroilerCoopsForm.title",
            "Kurniki referencyjne"
          )}

          <CftTooltip
            style={{ position: "absolute", right: "0" }}
            content={t(
              "cftChickenBroiler:tooltip.chickenBroilerCoopsForm",
              "Kurnik referencyjny to jeden wzorcowy kurnik w którym podasz informacje o rożnych cyklach produkcyjnych i na podstawie którego dokonasz obliczeń emisji dla swojej hodowli. Możesz zdefiniować od 1 do 6 kurników referencyjnych"
            )}
          />
        </h2>
        <Formik
          initialValues={store.henhouses_reference}
          onSubmit={() => {
            updateStore({
              henhouses_reference: {
                ...store.henhouses_reference,
                isValidate: true,
              },
            });
            history.push(routes.CHICKEN_BROILER.EMISSIONS);
          }}
        >
          <Form style={{ width: "100%" }}>
            <CreateCoopField />
            <CftFormField name="coops">
              {({ value, helpers }) =>
                !!value.length && (
                  <>
                    <h4 className="cft-section-subtitle mb-6">
                      {t(
                        "cftChickenBroiler:chickenBroilerCoopsForm.subtitle",
                        "Podaj informacje o cyklach produkcyjnych we wszystkich kurnikach"
                      )}
                    </h4>
                    {value.map(({ name, isValidate }, key) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="cft-paper mb-2"
                      >
                        <CftBox
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          variant="fullWidth"
                          isActive={isValidate}
                          onClick={() => {
                            history.push(
                              `${routes.CHICKEN_BROILER.COOPS}/${key}`
                            );
                          }}
                        >
                          <span>
                            {name ||
                              `${t("cftChickenBroiler:coop")} ${key + 1}`}
                          </span>
                          <ArrowRight />
                        </CftBox>
                        <TrashIcon
                          onClick={() => {
                            updateStore({
                              henhouses_reference: {
                                ...store.henhouses_reference,
                                coops: value.filter((_, _key) => _key !== key),
                              },
                            });
                            helpers.setValue(
                              value.filter((_, _key) => _key !== key)
                            );
                          }}
                          style={{
                            marginTop: "16px",
                            marginLeft: "16px",
                            marginRight: "-32px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ))}
                    {value.length < 6 ? (
                      <div className="cft-paper">
                        <CftTank
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <CftButton
                            variant="active"
                            isRounded
                            isAlternative
                            isNegative
                            onClick={() => {
                              const coops = [
                                ...value,
                                {
                                  name: "",
                                  production_cycles: [
                                    {
                                      production_cycle_length: "",
                                      technological_break_length: "",
                                      repetitions: "",
                                      chicken_number: "",
                                      falls_percent: "2",
                                      pickup: "0",
                                      pickups: [],
                                      feed_components: [],
                                    },
                                  ],
                                  isValidate: false,
                                },
                              ];
                              updateStore({
                                henhouses_reference: {
                                  ...store.henhouses_reference,
                                  coops,
                                },
                              });
                              helpers.setValue(coops);
                            }}
                          >
                            <PlusIcon style={{ marginRight: "12px" }} />
                            {t(
                              "cftChickenBroiler:chickenBroilerCoopsForm.addHenhouses_reference",
                              "dodaj KURNIK"
                            )}
                          </CftButton>
                        </CftTank>
                      </div>
                    ) : (
                      <CftWarningBox
                        style={{ maxWidth: "570px" }}
                        text="Zdeklarowałeś maksymalną ilość kurników referencyjnych"
                      />
                    )}
                  </>
                )
              }
            </CftFormField>
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
                disabled={!isNextStepAvailable}
              >
                {t(
                  "cftChickenBroiler:chickenBroilerCoopsForm.submit",
                  "podaj informacje o emisjach"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="chicken" />
    </div>
  );
}

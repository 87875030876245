import React from "react";
import { ReactComponent as PaszeIcon } from "../../../../assets/icons/cft/pasza_wloknista.svg";
import { ReactComponent as ZbozeIcon } from "../../../../assets/icons/cft/polauprawne.svg";
import { ReactComponent as UboczneIcon } from "../../../../assets/icons/cft/produkty uboczne.svg";
import { ReactComponent as MieszankaIcon } from "../../../../assets/icons/cft/mieszanka_paszowa.svg";
import { ReactComponent as MakuchIcon } from "../../../../assets/icons/cft/makuch.svg";
import { ReactComponent as NasionaIcon } from "../../../../assets/icons/cft/straczkowe.svg";
import { ReactComponent as RoslinyIcon } from "../../../../assets/icons/cft/okopowe.svg";
import { ReactComponent as InneIcon } from "../../../../assets/icons/cft/inne.svg";

export const feedType = {
  fibrous: [
    {
      pl: "Świeża trawa uprawiana poza gospodarstwem",
      gb: "Grass fresh managed - off farm",
      value: 2,
    },
    {
      pl: "Trawa świeża (ekstensywna)",
      gb: "Grass fresh (extensive)",
      value: 3,
    },
    {
      pl: "Siano z trawy - poza gospodarstwem",
      gb: "Grass hay - off farm",
      value: 5,
    },
    {
      pl: "Kiszonka z trawy - poza gospodarstwem",
      gb: "Grass silage - off farm",
      value: 7,
    },
    {
      pl: "Kiszonka z kukurydzy",
      gb: "Maize silage",
      value: 8,
    },
    {
      pl: "Kiszonka z lucerny / lucerny",
      gb: "Lucerne / Alfalfa silage",
      value: 9,
    },
    {
      pl: "Słoma",
      gb: "rail",
      value: 11,
    },
    {
      pl: "Siano z trawy",
      gb: "Grass hay",
      value: 76,
    },
    {
      pl: "Siano z lucerny / lucerny",
      gb: "Lucerne/Alfalfa hay",
      value: 81,
    },
    {
      pl: "Siano z koniczyny",
      gb: "Clover hay",
      value: 82,
    },
  ],
  others: [
    {
      pl: "Inne pasze",
      gb: "Other fodder",
      value: 10,
    },
    {
      pl: "Mieszanka mineralna",
      gb: "Mineral mix",
      value: 80,
    },
  ],
  wheat: [
    {
      pl: "Inne resztki pożniwne",
      gb: "Other crop residues",
      value: 12,
    },
    {
      pl: "Siano owsiane",
      gb: "Oat hay",
      value: 83,
    },
    {
      pl: "Kiszonka z sorgo",
      gb: "Sorghum silage",
      value: 86,
    },
    {
      pl: "Pszenica",
      gb: "Wheat",
      value: 13,
    },
    {
      pl: "Jęczmień",
      gb: "Barley",
      value: 14,
    },
    {
      pl: "Kukurydza / ziarno kukurydzy",
      gb: "Maize / corn grain",
      value: 15,
    },
    {
      pl: "Owies",
      gb: "Oats",
      value: 16,
    },
    {
      pl: "Ryż",
      gb: "Rice",
      value: 17,
    },
    {
      pl: "Żyto",
      gb: "Rye",
      value: 18,
    },
    {
      pl: "Sorgo",
      gb: "Sorghum",
      value: 19,
    },
    {
      pl: "Pozostałe zboża",
      gb: "Other cereals",
      value: 20,
    },
    {
      pl: "Otręby",
      gb: "Brans",
      value: 21,
    },
    {
      pl:
        "Pasza kukurydziana / gluten kukurydziany (produkt uboczny przetwarzania)",
      gb: "Maize / Corn gluten feed (a by-product of processing)",
      value: 22,
    },
  ],
  byProducts: [
    {
      pl: "Suszone ziarna gorzelnicze (DDGS) / Draff",
      gb: "Dried distillers grains (DDGS) / Draff",
      value: 23,
    },
    {
      pl: "Ziarno piwowarskie",
      gb: "Brewer’s grain",
      value: 24,
    },
    {
      pl: "Skrobia ziemniaczana",
      gb: "Potato starch",
      value: 25,
    },
    {
      pl: "Melasa z buraków cukrowych / trzciny cukrowej",
      gb: "Sugarbeet / sugarcane molasses",
      value: 26,
    },
    {
      pl: "Łuska sojowa",
      gb: "Soy hull",
      value: 74,
    },
    {
      pl: "Omiń tłuszcz",
      gb: "Bypass fat",
      value: 75,
    },
    {
      pl: "Melasa",
      gb: "Molasses",
      value: 78,
    },
    {
      pl: "Serwatka w proszku",
      gb: "Whey powder",
      value: 79,
    },
    {
      pl: "Resztki warzyw",
      gb: "Vegetables residues",
      value: 45,
    },
    {
      pl: "Mleko w proszku (sucha masa)",
      gb: "Milk powder (dry matter)",
      value: 46,
    },
    {
      pl: "Organiczne odpady z gospodarstw domowych",
      gb: "Organic household waste",
      value: 47,
    },
    {
      pl: "Miąższ buraków cukrowych",
      gb: "Sugar beet pulp",
      value: 48,
    },
    {
      pl: "Pulpa cytrusowa",
      gb: "Citrus pulp",
      value: 49,
    },
  ],
  compound: [
    {
      pl: "Mieszanka paszowa mleczna: domyślnie (15% soi)",
      gb: "Compound dairy feed: default (15% soy)",
      value: 27,
    },
    {
      pl: "Mieszanka paszowa mleczna: wysokobiałkowa soja (46%)",
      gb: "Compound dairy feed: high (46%) soy, high protein",
      value: 28,
    },
    {
      pl: "Mieszanka paszowa mleczna: soja o niskiej (8%) zawartości białka",
      gb: "Compound dairy feed: low (8%) soy, high protein",
      value: 29,
    },
    {
      pl: "Mieszanka paszowa mleczna: duża farma w Meksyku (dojenie) (21% soi)",
      gb: "Compound dairy feed: Mexico big farm (milking) (21% soy)",
      value: 50,
    },
    {
      pl: "Mieszanka paszowa mleczna: duża farma w Meksyku (sucha) (0% soi)",
      gb: "Compound dairy feed: Mexico big farm (dry) (0% soy)",
      value: 51,
    },
    {
      pl: "Mieszanka paszowa mleczna: duża farma w Meksyku (dojenie) (40% soi)",
      gb: "Compound dairy feed: Mexico big farm (milking) (40% soy)",
      value: 52,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: duża farma w Meksyku (sucha) (20% DDG, 0% soi)",
      gb: "Compound dairy feed: Mexico big farm (dry) (20% DDG, 0% soy)",
      value: 53,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: małe gospodarstwo w Meksyku (dojenie) (8% soi)",
      gb: "Compound dairy feed: Mexico small farm (milking) (8% soy)",
      value: 54,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: małe gospodarstwo w Meksyku (sucha) (4,5% soi)",
      gb: "Compound dairy feed: Mexico small farm (dry) (4.5% soy)",
      value: 55,
    },
    {
      pl: "Mieszanka paszowa mleczna: Brazylia (30% soi)",
      gb: "Compound dairy feed: Brazil (30% soy)",
      value: 56,
    },
    {
      pl: "Mieszanki paszowe mleczne: Polska (20% soi)",
      gb: "Compound dairy feed: Poland (20% soy)",
      value: 57,
    },
    {
      pl: "Mieszanki paszowe mleczne: Polska (10% soi)",
      gb: "Compound dairy feed: Poland (10% soy)",
      value: 58,
    },
    {
      pl: "Mieszanka paszowa mleczna: Argentyna (20% soi)",
      gb: "Compound dairy feed: Argentina (20% soy)",
      value: 59,
    },
    {
      pl: "Mieszanka paszowa mleczna: Republika Południowej Afryki (6% soi)",
      gb: "Compound dairy feed: South Africa (6% soy)",
      value: 60,
    },
    {
      pl: "Mieszanka paszowa mleczna: Hiszpania (20% soi)",
      gb: "Compound dairy feed: Spain (20% soy)",
      value: 61,
    },
    {
      pl: "Mieszanka paszowa mleczna: Hiszpania (42% soi)",
      gb: "Compound dairy feed: Spain (42% soy)",
      value: 62,
    },
    {
      pl: "Mieszanka paszowa mleczna: Hiszpania (0% soi)",
      gb: "Compound dairy feed: Spain (0% soy)",
      value: 63,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: Niemcy (4% melasy cukrowej, 0% DDG, 0% soi)",
      gb: "Compound dairy feed: Germany (4% sugar molasses, 0% DDG, 0% soy)",
      value: 64,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: Niemcy (3% melasy cukrowej, 0% DDG, 0% soi)",
      gb: "Compound dairy feed: Germany (3% sugar molasses, 0% DDG, 0% soy)",
      value: 65,
    },
    {
      pl:
        "Mieszanka paszowa mleczna: Niemcy (3% melasy cukrowej, 10% DDG, 0% soi)",
      gb: "Compound dairy feed: Germany (3% sugar molasses, 10% DDG, 0% soy)",
      value: 66,
    },
    {
      pl: "Mieszanka paszowa mleczna: Belgia (10% soi)",
      gb: "Compound dairy feed: Belgium (10% soy)",
      value: 67,
    },
    {
      pl: "Mieszanka paszowa mleczna: Belgia (65% soi)",
      gb: "Compound dairy feed: Belgium (65% soy)",
      value: 68,
    },
    {
      pl: "Mieszanka paszowa mleczna: Belgia (16% soi)",
      gb: "Compound dairy feed: Belgium (16% soy)",
      value: 69,
    },
    {
      pl: "Mieszanka paszowa mleczna: Rosja Wschodnia (0% soi)",
      gb: "Compound dairy feed: Russia East (0% soy)",
      value: 70,
    },
    {
      pl: "Mieszanka paszowa mleczna: Rosja Południowa (0% soi)",
      gb: "Compound dairy feed: Russia South (0% soy)",
      value: 71,
    },
    {
      pl: "Mieszanka paszowa mleczna: Rosja Zachodnia (5% soi)",
      gb: "Compound dairy feed: Russia West (5% soy)",
      value: 72,
    },
    {
      pl: "Mieszanka paszowa mleczna: Algieria (28% soi)",
      gb: "Compound dairy feed: Algeria (28% soy)",
      value: 73,
    },
  ],
  soybean: [
    {
      pl: "Makuch / mączka sojowa",
      gb: "Soybean cake/meal",
      value: 30,
    },
    {
      pl: "Makuch / mączka rzepakowa",
      gb: "Rapeseed cake/meal",
      value: 31,
    },
    {
      pl: "Ciasto / mączka słonecznikowa",
      gb: "Sunflower cake/meal",
      value: 32,
    },
    {
      pl: "Ciasto / mączka z nasion bawełny",
      gb: "Cottonseed cake/meal",
      value: 33,
    },
    {
      pl: "Ciasto / mączka z ziaren palmowych",
      gb: "Palm kernel cake/meal",
      value: 34,
    },
    {
      pl: "Inne makuchy / mączki olejowe",
      gb: "Other oil cake/meal",
      value: 35,
    },
  ],
  seeds: [
    {
      pl: "Soja",
      gb: "Soybean",
      value: 36,
    },
    {
      pl: "Groch",
      gb: "Peas",
      value: 37,
    },
    {
      pl: "fasolki",
      gb: "Beans",
      value: 38,
    },
    {
      pl: "Inne rośliny strączkowe",
      gb: "Other pulses",
      value: 39,
    },
  ],
  root: [
    {
      pl: "Maniok / tapioka",
      gb: "Cassava / tapioca",
      value: 40,
    },
    {
      pl: "Słodki ziemniak",
      gb: "Sweet potato",
      value: 41,
    },
    {
      pl: "Buraki pastewne",
      gb: "Fodder beet",
      value: 42,
    },
    {
      pl: "Inne rośliny okopowe",
      gb: "Other root crops",
      value: 43,
    },
    {
      pl: "Inne pasze",
      gb: "Other feed",
      value: 44,
    },
  ],
};

export const newFeedType = [
  {
    pl: "Pasze włókniste",
    gb: "Fibrous feed",
    value: "fibrous",
    items: [
      {
        pl: "Świeża trawa uprawiana - w gospodarstwie",
        gb: "Grass fresh managed - on farm",
        value: 1,
      },
      {
        pl: "Świeża trawa uprawiana poza gospodarstwem",
        gb: "Grass fresh managed - off farm",
        value: 2,
      },
      {
        pl: "Trawa świeża (ekstensywna)",
        gb: "Grass fresh (extensive)",
        value: 3,
      },
      {
        pl: "Siano z trawy - na farmie",
        gb: "Grass hay - on farm",
        value: 4,
      },
      {
        pl: "Siano z trawy - poza gospodarstwem",
        gb: "Grass hay - off farm",
        value: 5,
      },
      {
        pl: "Kiszonka z trawy - w gospodarstwie",
        gb: "Grass silage - on farm",
        value: 6,
      },
      {
        pl: "Kiszonka z trawy - poza gospodarstwem",
        gb: "Grass silage - off farm",
        value: 7,
      },
      {
        pl: "Kiszonka z kukurydzy",
        gb: "Maize silage",
        value: 8,
      },
      {
        pl: "Kiszonka z lucerny / lucerny",
        gb: "Lucerne / Alfalfa silage",
        value: 9,
      },
      {
        pl: "Słoma",
        gb: "rail",
        value: 11,
      },
      {
        pl: "Siano z trawy",
        gb: "Grass hay",
        value: 76,
      },
      {
        pl: "Siano z lucerny / lucerny",
        gb: "Lucerne/Alfalfa hay",
        value: 81,
      },
      {
        pl: "Siano z koniczyny",
        gb: "Clover hay",
        value: 82,
      },
    ],
    icon: <PaszeIcon />,
  },
  {
    pl: "Inne",
    gb: "Others",
    value: "others",
    items: [
      {
        pl: "Inne pasze",
        gb: "Other fodder",
        value: 10,
      },
      {
        pl: "Mieszanka mineralna",
        gb: "Mineral mix",
        value: 80,
      },
    ],
    icon: <InneIcon />,
  },
  {
    pl: "Zboża",
    gb: "Wheat",
    value: "wheat",
    items: [
      {
        pl: "Inne resztki pożniwne",
        gb: "Other crop residues",
        value: 12,
      },
      {
        pl: "Siano owsiane",
        gb: "Oat hay",
        value: 83,
      },
      {
        pl: "Kiszonka z sorgo",
        gb: "Sorghum silage",
        value: 86,
      },
      {
        pl: "Pszenica",
        gb: "Wheat",
        value: 13,
      },
      {
        pl: "Jęczmień",
        gb: "Barley",
        value: 14,
      },
      {
        pl: "Kukurydza / ziarno kukurydzy",
        gb: "Maize / corn grain",
        value: 15,
      },
      {
        pl: "Owies",
        gb: "Oats",
        value: 16,
      },
      {
        pl: "Ryż",
        gb: "Rice",
        value: 17,
      },
      {
        pl: "Żyto",
        gb: "Rye",
        value: 18,
      },
      {
        pl: "Sorgo",
        gb: "Sorghum",
        value: 19,
      },
      {
        pl: "Pozostałe zboża",
        gb: "Other cereals",
        value: 20,
      },
      {
        pl: "Otręby",
        gb: "Brans",
        value: 21,
      },
      {
        pl:
          "Pasza kukurydziana / gluten kukurydziany (produkt uboczny przetwarzania)",
        gb: "Maize / Corn gluten feed (a by-product of processing)",
        value: 22,
      },
    ],
    icon: <ZbozeIcon />,
  },
  {
    pl: "Przetwórstwo produktów ubocznych",
    gb: "Processing of by-products",
    value: "byProducts",
    items: [
      {
        pl: "Suszone ziarna gorzelnicze (DDGS) / Draff",
        gb: "Dried distillers grains (DDGS) / Draff",
        value: 23,
      },
      {
        pl: "Ziarno piwowarskie",
        gb: "Brewer’s grain",
        value: 24,
      },
      {
        pl: "Skrobia ziemniaczana",
        gb: "Potato starch",
        value: 25,
      },
      {
        pl: "Melasa z buraków cukrowych / trzciny cukrowej",
        gb: "Sugarbeet / sugarcane molasses",
        value: 26,
      },
      {
        pl: "Łuska sojowa",
        gb: "Soy hull",
        value: 74,
      },
      {
        pl: "Omiń tłuszcz",
        gb: "Bypass fat",
        value: 75,
      },
      {
        pl: "Melasa",
        gb: "Molasses",
        value: 78,
      },
      {
        pl: "Serwatka w proszku",
        gb: "Whey powder",
        value: 79,
      },
      {
        pl: "Resztki warzyw",
        gb: "Vegetables residues",
        value: 45,
      },
      {
        pl: "Mleko w proszku (sucha masa)",
        gb: "Milk powder (dry matter)",
        value: 46,
      },
      {
        pl: "Organiczne odpady z gospodarstw domowych",
        gb: "Organic household waste",
        value: 47,
      },
      {
        pl: "Miąższ buraków cukrowych",
        gb: "Sugar beet pulp",
        value: 48,
      },
      {
        pl: "Pulpa cytrusowa",
        gb: "Citrus pulp",
        value: 49,
      },
    ],
    icon: <UboczneIcon />,
  },
  {
    pl: "Mieszanka paszowa",
    gb: "Compound feed",
    value: "compound",
    items: [
      {
        pl: "Mieszanka paszowa mleczna: domyślnie (15% soi)",
        gb: "Compound dairy feed: default (15% soy)",
        value: 27,
      },
      {
        pl: "Mieszanka paszowa mleczna: wysokobiałkowa soja (46%)",
        gb: "Compound dairy feed: high (46%) soy, high protein",
        value: 28,
      },
      {
        pl: "Mieszanka paszowa mleczna: soja o niskiej (8%) zawartości białka",
        gb: "Compound dairy feed: low (8%) soy, high protein",
        value: 29,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: duża farma w Meksyku (dojenie) (21% soi)",
        gb: "Compound dairy feed: Mexico big farm (milking) (21% soy)",
        value: 50,
      },
      {
        pl: "Mieszanka paszowa mleczna: duża farma w Meksyku (sucha) (0% soi)",
        gb: "Compound dairy feed: Mexico big farm (dry) (0% soy)",
        value: 51,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: duża farma w Meksyku (dojenie) (40% soi)",
        gb: "Compound dairy feed: Mexico big farm (milking) (40% soy)",
        value: 52,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: duża farma w Meksyku (sucha) (20% DDG, 0% soi)",
        gb: "Compound dairy feed: Mexico big farm (dry) (20% DDG, 0% soy)",
        value: 53,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: małe gospodarstwo w Meksyku (dojenie) (8% soi)",
        gb: "Compound dairy feed: Mexico small farm (milking) (8% soy)",
        value: 54,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: małe gospodarstwo w Meksyku (sucha) (4,5% soi)",
        gb: "Compound dairy feed: Mexico small farm (dry) (4.5% soy)",
        value: 55,
      },
      {
        pl: "Mieszanka paszowa mleczna: Brazylia (30% soi)",
        gb: "Compound dairy feed: Brazil (30% soy)",
        value: 56,
      },
      {
        pl: "Mieszanki paszowe mleczne: Polska (20% soi)",
        gb: "Compound dairy feed: Poland (20% soy)",
        value: 57,
      },
      {
        pl: "Mieszanki paszowe mleczne: Polska (10% soi)",
        gb: "Compound dairy feed: Poland (10% soy)",
        value: 58,
      },
      {
        pl: "Mieszanka paszowa mleczna: Argentyna (20% soi)",
        gb: "Compound dairy feed: Argentina (20% soy)",
        value: 59,
      },
      {
        pl: "Mieszanka paszowa mleczna: Republika Południowej Afryki (6% soi)",
        gb: "Compound dairy feed: South Africa (6% soy)",
        value: 60,
      },
      {
        pl: "Mieszanka paszowa mleczna: Hiszpania (20% soi)",
        gb: "Compound dairy feed: Spain (20% soy)",
        value: 61,
      },
      {
        pl: "Mieszanka paszowa mleczna: Hiszpania (42% soi)",
        gb: "Compound dairy feed: Spain (42% soy)",
        value: 62,
      },
      {
        pl: "Mieszanka paszowa mleczna: Hiszpania (0% soi)",
        gb: "Compound dairy feed: Spain (0% soy)",
        value: 63,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: Niemcy (4% melasy cukrowej, 0% DDG, 0% soi)",
        gb: "Compound dairy feed: Germany (4% sugar molasses, 0% DDG, 0% soy)",
        value: 64,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: Niemcy (3% melasy cukrowej, 0% DDG, 0% soi)",
        gb: "Compound dairy feed: Germany (3% sugar molasses, 0% DDG, 0% soy)",
        value: 65,
      },
      {
        pl:
          "Mieszanka paszowa mleczna: Niemcy (3% melasy cukrowej, 10% DDG, 0% soi)",
        gb: "Compound dairy feed: Germany (3% sugar molasses, 10% DDG, 0% soy)",
        value: 66,
      },
      {
        pl: "Mieszanka paszowa mleczna: Belgia (10% soi)",
        gb: "Compound dairy feed: Belgium (10% soy)",
        value: 67,
      },
      {
        pl: "Mieszanka paszowa mleczna: Belgia (65% soi)",
        gb: "Compound dairy feed: Belgium (65% soy)",
        value: 68,
      },
      {
        pl: "Mieszanka paszowa mleczna: Belgia (16% soi)",
        gb: "Compound dairy feed: Belgium (16% soy)",
        value: 69,
      },
      {
        pl: "Mieszanka paszowa mleczna: Rosja Wschodnia (0% soi)",
        gb: "Compound dairy feed: Russia East (0% soy)",
        value: 70,
      },
      {
        pl: "Mieszanka paszowa mleczna: Rosja Południowa (0% soi)",
        gb: "Compound dairy feed: Russia South (0% soy)",
        value: 71,
      },
      {
        pl: "Mieszanka paszowa mleczna: Rosja Zachodnia (5% soi)",
        gb: "Compound dairy feed: Russia West (5% soy)",
        value: 72,
      },
      {
        pl: "Mieszanka paszowa mleczna: Algieria (28% soi)",
        gb: "Compound dairy feed: Algeria (28% soy)",
        value: 73,
      },
    ],
    icon: <MieszankaIcon />,
  },
  {
    pl: "Makuch/mączka",
    gb: "Soybean/meal",
    value: "soybean",
    items: [
      {
        pl: "Makuch / mączka sojowa",
        gb: "Soybean cake/meal",
        value: 30,
      },
      {
        pl: "Makuch / mączka rzepakowa",
        gb: "Rapeseed cake/meal",
        value: 31,
      },
      {
        pl: "Ciasto / mączka słonecznikowa",
        gb: "Sunflower cake/meal",
        value: 32,
      },
      {
        pl: "Ciasto / mączka z nasion bawełny",
        gb: "Cottonseed cake/meal",
        value: 33,
      },
      {
        pl: "Ciasto / mączka z ziaren palmowych",
        gb: "Palm kernel cake/meal",
        value: 34,
      },
      {
        pl: "Inne makuchy / mączki olejowe",
        gb: "Other oil cake/meal",
        value: 35,
      },
    ],
    icon: <MakuchIcon />,
  },
  {
    pl: "Nasiona jadalne roślin strączkowych",
    gb: "Edible seeds of legumes",
    value: "seeds",
    items: [
      {
        pl: "Soja",
        gb: "Soybean",
        value: 36,
      },
      {
        pl: "Groch",
        gb: "Peas",
        value: 37,
      },
      {
        pl: "fasolki",
        gb: "Beans",
        value: 38,
      },
      {
        pl: "Inne rośliny strączkowe",
        gb: "Other pulses",
        value: 39,
      },
    ],
    icon: <NasionaIcon />,
  },
  {
    pl: "Rośliny okopowe",
    gb: "Root crops",
    value: "root",
    items: [
      {
        pl: "Maniok / tapioka",
        gb: "Cassava / tapioca",
        value: 40,
      },
      {
        pl: "Słodki ziemniak",
        gb: "Sweet potato",
        value: 41,
      },
      {
        pl: "Buraki pastewne",
        gb: "Fodder beet",
        value: 42,
      },
      {
        pl: "Inne rośliny okopowe",
        gb: "Other root crops",
        value: 43,
      },
      {
        pl: "Inne pasze",
        gb: "Other feed",
        value: 44,
      },
    ],
    icon: <RoslinyIcon />,
  },
];

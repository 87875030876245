/* eslint-disable global-require */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import useCategories from "../../categories/useCategories";
import { QuizzesContext } from "../../blocks/Quizzes/Quizzes.context";
import Widget from "./Widget";

import "./Widgets.scss";

export const GreenChangesWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/artykuly/zielone-zmiany"
      cover={require("../../../assets/img/widgets/zielone.jpg")}
      cta="widgets:readMore"
    >
      <div className="widget__green-changes">
        {t("widgets:sidebar.greenChangesWidget.p")}
      </div>
    </Widget>
  );
};

export const CftWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/narzedzia/kalkulator-emisji-co2"
      cover={require("../../../assets/img/widgets/agroemisjabaner.jpg")}
      cta="widgets:nitrogenCalculator.btn"
    >
      <div className="widget__cft">
        <h3>{t("widgets:sidebar.cftWidget.p")}</h3>
        {t("widgets:sidebar.cftWidget.cta")}
      </div>
    </Widget>
  );
};

export const ContestWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      href="https://www.bnpparibas.pl/blog/oblicz-jak-wplywasz-na-srodowisko-kalkulator-emisji-co2"
      cover={require("../../../assets/img/widgets/BNP_Kalkulator.jpg")}
      cta="landingPage:aside.getDetails"
    >
      <div className="widget__contest">
        <h3>{t("widgets:voluntarySaleCalculation.h1")}</h3>
        {t("widgets:voluntarySaleCalculation.p")}
      </div>
    </Widget>
  );
};

export const ArticlesHeelsWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/artykuly/dla-kobiet"
      cover={require("../../../assets/img/widgets/obcasy.jpg")}
      cta="landingPage:follow.btn"
    >
      <div className="widget__articles-heels">
        <h3>{t("widgets:sidebar.articlesHeelsWidget.h")}</h3>
        <ul>
          <li>{t("widgets:sidebar.articlesHeelsWidget.p1")}</li>
          <li>{t("widgets:sidebar.articlesHeelsWidget.p2")}</li>
          <li>{t("widgets:sidebar.articlesHeelsWidget.p3")}</li>
        </ul>
      </div>
    </Widget>
  );
};

export const NitrogenCalculatorWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/kalkulator-zuzycia-azotu"
      cover={require("../../../assets/img/widgets/cows.jpg")}
      cta="widgets:nitrogenCalculator.btn"
    >
      <div className="widget__nitrogen-calculator">
        <h3>{t("widgets:nitrogenCalculator.title")}</h3>
      </div>
    </Widget>
  );
};

export const RealEstateWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/nieruchomosci"
      cover={require("../../../assets/img/widgets/este.jpg")}
      cta="widgets:realEstate.btn"
    >
      <div className="widget__estate">
        <h3>{t("widgets:realEstate.title")}</h3>
        <span>{t("widgets:realEstate.subtitle")}</span>
      </div>
    </Widget>
  );
};

export const EkoGame = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/eko-man"
      isExternalLink
      cover={require("../../../assets/img/widgets/ekoman.jpg")}
      cta="widgets:ekoGame.btn"
    >
      <div className="widget__eko_game">
        <h3>{t("widgets:ekoGame.title")}</h3>
        <span>{t("widgets:ekoGame.subtitle")}</span>
      </div>
    </Widget>
  );
};

export const PhotographicCompetition = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/konkurs"
      cover={require("../../../assets/img/widgets/konkurs1_big.jpg")}
      cta="widgets:photographicCompetition.btn"
      isHorizontal
      className="widget__competition"
    >
      <div>
        <h3>{t("widgets:photographicCompetition.title")}</h3>
        <span>{t("widgets:photographicCompetition.subtitle")}</span>
      </div>
    </Widget>
  );
};

export const VoluntarySaleCalculation = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/kalkulator-korzysci-dsn"
      cover={require("../../../assets/img/widgets/kalkulator_landing.jpg")}
      cta="widgets:voluntarySaleCalculation.btn"
      className="widget__voluntarySaleCalculation"
    >
      <div>
        <h3>{t("widgets:voluntarySaleCalculation.title")}</h3>
      </div>
    </Widget>
  );
};

export const QuizWidget = () => {
  const { t } = useTranslation(["widgets"]);
  const { quizzes } = useContext(QuizzesContext);

  if (quizzes.count <= 0) {
    return null;
  }

  const [quiz] = quizzes.results;

  return (
    <Widget
      to={`/po-godzinach/quizy/${quiz.slug}`}
      cover={require("../../../assets/img/widgets/quiz.jpg")}
      cta="widgets:quiz.start"
    >
      <div className="widget__quiz">
        <span>{t("widgets:quiz.newest")}</span>
        <h3>{quiz.title}</h3>
      </div>
    </Widget>
  );
};

export const AcademyWidget = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["widgets"]);

  if (language === "gb") {
    return null;
  }

  return (
    <Widget
      to="https://www.rolnictwozrownowazone.pl/kurs"
      isExternalLink
      rel="nofollow"
      cover={require("../../../assets/img/widgets/akademia.jpg")}
      cta="widgets:academy.btn"
    >
      <div className="widget__academy">
        <h3>{t("widgets:academy.title")}</h3>
        <p>{t("widgets:academy.desc")}</p>
      </div>
    </Widget>
  );
};

export const KredytomatWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/kredytomat"
      cover={require("../../../assets/img/widgets/Kredytomatbanaer.jpg")}
      cta="widgets:kredytomat.btn"
    >
      <div className="widget__kredytomat">
        <h3>{t("widgets:kredytomat.title")}</h3>
        <p>{t("widgets:kredytomat.subtitle")}</p>
      </div>
    </Widget>
  );
};

export const PolskiPomidorWidget = () => {
  const { t } = useTranslation(["widgets"]);
  return (
    <Widget
      to="/nowy-polski-pomidor"
      cover={require("../../../assets/img/widgets/nowy-polski-pomidor.png")}
      cta="widgets:nowyPolskiPomidor.btn"
    >
      <div className="widget__pomidor">
        <h3>{t("widgets:nowyPolskiPomidor.title")}</h3>
        <p>{t("widgets:nowyPolskiPomidor.subtitle")}</p>
      </div>
    </Widget>
  );
};

export const LawyerRoleWidget = () => {
  const { t } = useTranslation(["widgets"]);
  const { getCategory } = useCategories();
  const category = getCategory(79);
  return (
    <Widget
      to={category.path}
      cover={require("../../../assets/img/widgets/law.jpg")}
      cta="widgets:lawyerRole.btn"
    >
      <div className="widget__meteos">
        <h3>{t("widgets:lawyerRole.title")}</h3>
        {t("widgets:lawyerRole.subtitle")}
      </div>
    </Widget>
  );
};

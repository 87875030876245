import { ANSWER_TYPE } from "../consts";

const EXPAND_MY_FARM_GRAINS = {
  0: {
    question: "Chcę rozwinąć swoje gospodarstwo?",
    answers: [
      {
        text: "Chcę kupić grunty",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-inwestycyjny-na-zakup-ziemi",
        icon: "Lands",
      },
      {
        text: "Chcę kupić maszyny i urządzenia do gospodarstwa",
        type: ANSWER_TYPE.grain,
        grain: 1,
        icon: "Tractor",
      },
      {
        text: "Chcę rozpocząć budowę, rozbudowę albo modernizację gospodarstwa",
        type: ANSWER_TYPE.grain,
        grain: 2,
        icon: "BarnPlus",
      },
      {
        text: "Chcę kupić gospodarstwo",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-inwestycyjny-na-zakup-gospodarstwa-rolnego",
        icon: "Farm",
      },
      {
        text: "Chcę kupić instalacje fotowoltaiczną",
        type: ANSWER_TYPE.grain,
        grain: 3,
        icon: "PhotovoltaicsSun",
      },
      {
        text: "Chcę kupić stado podstawowe",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-zakup-stada-podstawowego",
        icon: "Cows",
      },
      {
        text: "Chcę założyć plantacje wieloletnie",
        type: ANSWER_TYPE.grain,
        grain: 5,
        icon: "PlantationSnowflake",
      },
      {
        text: "Chcę zrealizować inwestycję w nawadnianie",
        type: ANSWER_TYPE.grain,
        grain: 6,
        icon: "Irrigation",
      },
      {
        text: "Chcę wynająć samochód długoterminowo",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/wynajem-arval",
        icon: "Car",
      },
    ],
  },
  1: {
    question: "Czy inwestycja będzie dofinansowana z PROW?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain:
          "/kredytomat/finansowanie-zakupu-maszyn-i-urzadzen-do-gospodarstwa",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/finansowanie-zakupu-maszyn-i-urzadzen-rolniczych",
      },
    ],
  },
  2: {
    question: "Czy inwestycja będzie dofinansowana z PROW?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain:
          "/kredytomat/kredyt-na-budowe-modernizacje-i-wyposazenie-budynkow-bez-dotacji",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain:
          "/kredytomat/kredyt-na-budowe-modernizacje-i-wyposazenie-budynkow-z-dotacja",
      },
    ],
  },
  3: {
    question: "Czy inwestycja będzie dofinansowana z PROW?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.grain,
        grain: 4,
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/finansowanie-zakupu-instalacji-fotowoltaicznej",
      },
    ],
  },
  4: {
    question: "Czy kwota inwestycji będzie większa niż 60 000 zł brutto?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-mniejsze-instalacje-fotowoltaiczne",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain:
          "/kredytomat/kredyt-na-zakup-i-montaz-instalacji-fotowoltaicznych",
      },
    ],
  },
  5: {
    question: "Czy inwestycja będzie dofinansowana z PROW?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-plantacje-wieloletnie-bez-dotacji",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-plantacje-wieloletnie-z-dotacja",
      },
    ],
  },
  6: {
    question: "Czy inwestycja będzie dofinansowana z PROW?",
    answers: [
      {
        text: "Nie",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-nawadnianie-bez-dotacji",
      },
      {
        text: "Tak",
        type: ANSWER_TYPE.end,
        grain: "/kredytomat/kredyt-na-nawadnianie-z-dotacja",
      },
    ],
  },
};

export default EXPAND_MY_FARM_GRAINS;

import React, { useState, forwardRef, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import cl from "classnames";

import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow_svgo.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/cft/smietnik.svg";
import "./CftQuestionGroup.scss";

function CftQuestionGroup(
  {
    children,
    label,
    onRemove,
    withoutMarginBottom,
    withoutMarginTop,
    hasError,
    ...props
  },
  ref
) {
  const [maxHeight, setMaxHeight] = useState(undefined);
  const [toRemove, setToRemove] = useState(false);
  const [isCollapsed, setCollapsed] = useState(false);
  const { t } = useTranslation(["cft"]);

  const contentRef = useRef();

  useEffect(() => {
    if (maxHeight) {
      setTimeout(() => {
        setCollapsed(true);
      }, 100);
    }
  }, [maxHeight]);

  return (
    <div
      className={cl("cft-question-group", {
        "cft-question-group--withoutMarginBottom": withoutMarginBottom,
        "cft-question-group--withoutMarginTop": withoutMarginTop,
        "cft-question-group--toRemove": toRemove,
        "cft-question-group--hasError": isCollapsed && hasError,
      })}
      ref={ref}
      {...props}
    >
      <div className="cft-question-group__label">
        <button
          className={cl("cft-question-group__hide", {
            "cft-question-group__hide--isCollapsed": isCollapsed,
          })}
          type="button"
          onClick={() => {
            if (isCollapsed) {
              setCollapsed(false);
              setTimeout(() => {
                setMaxHeight(undefined);
              }, 700);
            } else {
              setMaxHeight(() => {
                return `${contentRef.current.clientHeight}px`;
              });
            }
          }}
        >
          <ArrowIcon />
        </button>
        <span>{label}</span>
        {onRemove && (
          <button
            className="cft-question-group__remove"
            type="button"
            onClick={() => {
              setToRemove(true);
              setTimeout(() => {
                setToRemove(false);
                onRemove();
              }, 400);
            }}
          >
            <div className="cft-question-group__remove__group">
              <TrashIcon />
              <p className="cft-question-group__remove__text">
                {t("cft:remove", "usuń")}
              </p>
            </div>
          </button>
        )}
      </div>
      <div
        ref={contentRef}
        className={cl("cft-question-group__content", {
          "cft-question-group__content--isCollapsed": isCollapsed,
        })}
        style={{ maxHeight: isCollapsed ? "0" : maxHeight }}
      >
        {children}
      </div>
    </div>
  );
}

CftQuestionGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  withoutMarginBottom: PropTypes.bool,
  withoutMarginTop: PropTypes.bool,
  variant: PropTypes.string,
};

CftQuestionGroup.defaultProps = {
  onRemove: null,
  variant: "",
  withoutMarginBottom: false,
  withoutMarginTop: false,
};

export default forwardRef(CftQuestionGroup);

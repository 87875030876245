import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useHistory } from "react-router-dom";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftOnboarding,
} from "../../components";
import DetailsDairyProductionForm, {
  DetailsDairyProductionContext,
} from "../../forms/dairyProduction/DetailsDairyProductionForm/DetailsDairyProduction.form";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";

function DetailsDairyProductionPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveDairyProduction } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={0}
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveDairyProduction({
                    details: {
                      ...values,
                      name: values.name.slice(0, 30),
                    },
                  });
                  history.push(routes.DAIRY_PRODUCTION.MAIN);
                }}
              />
              <CftExportReport type="dairyProduction" />
            </div>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:detailsDairyProduction.title", "Szczegóły produkcji")}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:types.dairyProduction", "Produkcja mleczarska")}</h3>
            <p>
              {t(
                "cft:detailsFarmlands.subtitle",
                "Aby rozpocząć, wprowadź podstawowe informacje o produkcji mleka w stadzie."
              )}
            </p>
          </>
        }
      >
        <DetailsDairyProductionForm />
        <CftReportProgress type="dairyProduction" />
      </CftSection>
      <CftOnboarding />
    </CftLayout>
  );
}

export default () => {
  return (
    <DetailsDairyProductionContext>
      <DetailsDairyProductionPage />
    </DetailsDairyProductionContext>
  );
};

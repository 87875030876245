import { addMonths } from "date-fns";
import {
  TIMESTAMP_TYPES,
  INVESTMENT_TYPES,
  INSTALLMENT_TYPES,
  MONTHS,
} from "../constants";

export const investmentTypeToString = ({ type }) => {
  switch (type) {
    case INVESTMENT_TYPES.INFRASTRUCTURE:
      return "secondStep.summary.0";
    case INVESTMENT_TYPES.GROUND:
      return "secondStep.summary.1";
    case INVESTMENT_TYPES.EQUIPMENT:
      return "secondStep.summary.2";
    default:
      return "-";
  }
};

export const timespanTypeToString = ({ type }) => {
  switch (type) {
    case TIMESTAMP_TYPES.ONE_MONTH:
      // return "Co miesiąc";
      return "inputs.10.values.0";
    case TIMESTAMP_TYPES.QUARTER:
      // return "Co kwartał";;
      return "inputs.10.values.1";
    case TIMESTAMP_TYPES.HALF_YEAR:
      // return "Co pół roku";
      return "inputs.10.values.2";
    case TIMESTAMP_TYPES.ONE_YEAR:
      // return "Co rok";
      return "inputs.10.values.3";
    default:
      return "-";
  }
};

export const timespanTypeToInstallmentFrequency = ({ type }) => {
  switch (type) {
    case TIMESTAMP_TYPES.ONE_MONTH:
      return 12;
    case TIMESTAMP_TYPES.QUARTER:
      return 4;
    case TIMESTAMP_TYPES.HALF_YEAR:
      return 2;
    case TIMESTAMP_TYPES.ONE_YEAR:
      return 1;
    default:
      return "-";
  }
};

export const timespanTypeToNumberOfInstallments = ({ type }) => {
  switch (type) {
    case TIMESTAMP_TYPES.ONE_MONTH:
      return 1;
    case TIMESTAMP_TYPES.QUARTER:
      return 3;
    case TIMESTAMP_TYPES.HALF_YEAR:
      return 6;
    case TIMESTAMP_TYPES.ONE_YEAR:
      return 12;
    default:
      return "-";
  }
};

export const installmentTypeToString = ({ type }) => {
  switch (type) {
    case INSTALLMENT_TYPES.FIXED:
      // return "Równe";
      return "inputs.12.values.1";
    case INSTALLMENT_TYPES.DESCENDING:
      // return "Malejące";
      return "inputs.12.values.0";
    default:
      return "-";
  }
};

export const getOwnContributionPercentage = ({ type }) => {
  switch (type) {
    case INVESTMENT_TYPES.GROUND:
    case INVESTMENT_TYPES.INFRASTRUCTURE:
    case INVESTMENT_TYPES.EQUIPMENT:
    default:
      return 0.1;
  }
};

export const getRepaymentTimespanRange = ({ type }) => {
  switch (type) {
    case INVESTMENT_TYPES.GROUND:
      return [1, 30];
    case INVESTMENT_TYPES.INFRASTRUCTURE:
    case INVESTMENT_TYPES.EQUIPMENT:
      return [1, 15];
    default:
      return "-";
  }
};

export const getRepaymentMaxValue = ({ type }) => {
  const range = getRepaymentTimespanRange({ type });

  return range[range.length - 1];
};

export const getDifferenceInMonths = ({ from, to }) =>
  Math.ceil(
    Math.abs(
      new Date(from.year, from.month - 1) - new Date(to.year, to.month - 1)
    ) * 3.8026e-10
  ) - 1;

export const getAcceptedMonthValues = ({
  subventionReceivingYear,
  t,
  now = new Date(),
}) => {
  const allMonths = Object.values(MONTHS).map(option => ({
    label: t(option.label),
    value: option.value,
  }));

  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  let months = allMonths;

  if (currentYear === subventionReceivingYear) {
    const minimumMonth = addMonths(now, 3).getMonth();
    months = months.filter(month => month.value - 1 > minimumMonth);
  }

  if (currentYear + 1 === subventionReceivingYear && currentMonth === 11) {
    months = months.filter(month => ![0, 1, 2].includes(month.value - 1));
  }
  if (currentYear + 1 === subventionReceivingYear && currentMonth === 10) {
    months = months.filter(month => ![0, 1].includes(month.value - 1));
  }
  if (currentYear + 1 === subventionReceivingYear && currentMonth === 9) {
    months = months.filter(month => ![0].includes(month.value - 1));
  }

  return months;
};

export const getYearsSuffixTranslationKey = ({ i18n, repaymentTimespan }) => {
  const polishYearValuesMultipleLong = [2, 3, 4, 22, 23, 24];

  if (i18n.language === "pl") {
    return +repaymentTimespan === 1
      ? "year-single"
      : polishYearValuesMultipleLong.includes(+repaymentTimespan)
      ? "years-multiple-long"
      : "years-multiple-short";
  }
  if (i18n.language === "gb") {
    return +repaymentTimespan === 1 ? "year-single" : "years-multiple-long";
  }
  return undefined;
};

export const range = (start, end, step = 1) => {
  const len = Math.floor((end - start) / step) + 1;

  return Array(len)
    .fill()
    .map((_, idx) => start + idx * step);
};

export const getAcceptedYearValues = ({ investmentType }) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const isEndOfTheYear = [10, 11, 12].includes(currentMonth);

  const yearRange = range(
    isEndOfTheYear ? currentYear + 1 : currentYear,
    currentYear + getRepaymentTimespanRange({ type: investmentType })[1] - 1
  ).slice(0, isEndOfTheYear ? 5 : 4);

  return yearRange;
};

export const isDateBefore = (date, offset = 1) => {
  const currentPlusOne = new Date(new Date().toDateString());
  currentPlusOne.setMonth(currentPlusOne.getMonth() + offset);
  return new Date(date.toDateString()) <= currentPlusOne;
};

export const getPreviousEntry = ({ output, periodNo }) =>
  output[(periodNo - 1 + output.length - 1) % output.length];

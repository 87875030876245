import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftRadioField,
  CftTooltip,
} from "../../components";

export default function PickupsFields({ productionCyclesId }) {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { setFieldValue, values } = useFormikContext();

  return (
    <>
      <CftFormControl alignLeft>
        <CftLabel
          isFullWidth
          label={t(
            "cftChickenBroiler:pickupsFields.fields.pickupLabel",
            "Ile podbiórek wystąpiło w cyklu produkcyjnym?"
          )}
        />
        <CftRadioField
          variant="dots"
          name={`production_cycles[${productionCyclesId}].pickup`}
          options={[
            {
              value: "0",
              label: t("cftChickenBroiler:pickupsFields.pickup.0"),
            },
            {
              value: "1",
              label: t("cftChickenBroiler:pickupsFields.pickup.1"),
            },
            {
              value: "2",
              label: t("cftChickenBroiler:pickupsFields.pickup.2"),
            },
          ]}
          onChange={({ target }) => {
            if (target.value === "0") {
              return setFieldValue(
                `production_cycles[${productionCyclesId}].pickups`,
                []
              );
            }

            if (target.value === "2") {
              return setFieldValue(
                `production_cycles[${productionCyclesId}].pickups`,
                [
                  {
                    week: "",
                    quantity: "",
                  },
                  {
                    week: "",
                    quantity: "",
                  },
                ]
              );
            }
            return setFieldValue(
              `production_cycles[${productionCyclesId}].pickups`,
              [
                {
                  week: "",
                  quantity: "",
                },
              ]
            );
          }}
        />
      </CftFormControl>

      {values.production_cycles[productionCyclesId].pickups.map((_, key) => (
        <CftTank display="block">
          <CftFormControl alignLeft>
            <h5>
              {t(
                "cftChickenBroiler:pickupsFields.fields.pickupsTitle",
                "Podbiórka"
              )}{" "}
              {key + 1}
            </h5>
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:pickupsFields.fields.pickupsLabel",
                "Podaj informacje w którym dniu chowu nastąpiła pierwsza podbiórka i ile kurcząt podebrano."
              )}
              className="mb-3"
            />
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:pickupsFields.fields.pickupsSublabel",
                "Dzień podbiórki"
              )}
            />
            <CftTextField
              isRounded
              name={`production_cycles[${productionCyclesId}].pickups[${key}].day`}
              placeholder={t(
                "cftChickenBroiler:pickupsFields.fields.dayPlaceholder",
                "Wpisz liczbę"
              )}
              isAlternative
              mask="00"
            />
          </CftFormControl>
          <CftFormControl alignLeft>
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:pickupsFields.fields.quantityLabel",
                "Ilość sztuk podebranych kurcząt"
              )}
            >
              <CftTooltip
                content={t(
                  "cftChickenBroiler:tooltip.pickups.quantity",
                  "Podaj jaka ilość kurcząt została przeznaczona do tej podbiórki."
                )}
              />
            </CftLabel>
            <CftTextField
              isRounded
              name={`production_cycles[${productionCyclesId}].pickups[${key}].quantity`}
              placeholder={t(
                "cftChickenBroiler:pickupsFields.fields.weekPlaceholder",
                "Wpisz liczbę"
              )}
              isAlternative
              mask="0000000"
            />
          </CftFormControl>
        </CftTank>
      ))}
    </>
  );
}

PickupsFields.propTypes = {
  productionCyclesId: PropTypes.string.isRequired,
};

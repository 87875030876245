/* eslint-disable no-undef */
import * as actions from "./actions";

const INITIAL_STATE = { error: null, data: null };

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.SEND_CONTACT:
      return INITIAL_STATE;
    case actions.SEND_CONTACT_SUCCESS: {
      const { status } = action.payload;

      return { data: status, error: null };
    }
    case actions.SEND_CONTACT_FAIL: {
      const { error } = action;

      return { data: null, error };
    }
    default:
      return state;
  }
}

export const sendContact = formData => ({
  type: actions.SEND_CONTACT,
  payload: {
    request: {
      url: "/api/v1/contact/",
      method: "POST",
      data: {
        topic: formData.subject,
        response_email: formData.email,
        message: formData.description,
        recaptcha: formData.recaptcha,
        name: formData.name,
      },
    },
  },
});

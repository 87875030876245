import React from "react";
import PropTypes from "prop-types";

export const CancelIcon = props => {
  return (
    <div
      className="cancelIcon"
      role="button"
      tabIndex={0}
      onClick={props.onClickIcon}
    >
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="https://www.w3.org/2000/svg"
        xmlnsXlink="https://www.w3.org/1999/xlink"
      >
        <title>ico/x</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ico/x"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M18.25,16 L22,19.75 L19.75,22 L16,18.25 L12.25,22 L10,19.75 L13.75,16 L10,12.25 L12.25,10 L16,13.75 L19.75,10 L22,12.25 L18.25,16 Z"
            id="Combined-Shape"
            fill={props.color}
          />
        </g>
      </svg>
    </div>
  );
};

CancelIcon.propTypes = {
  color: PropTypes.string,
  onClickIcon: PropTypes.func,
};

CancelIcon.defaultProps = {
  color: "#E92B5C",
  onClickIcon: () => {},
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../../index";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function Step5({ handleClose, updateStyle }) {
  const { t } = useTranslation(["cftChickenBroiler"]);

  useEffect(() => {
    setTimeout(() => {
      const style = {
        width: 1,
        height: 1,
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        opacity: 1,
      };

      updateStyle(style);
    }, 800);
  }, []);

  return (
    <div>
      <h3 className="cft-onboarding__item__title">
        {t(
          "cftChickenBroiler:onboarding.step5.title",
          "Wczytywanie zapisanej kalkulacji"
        )}
      </h3>
      <p className="cft-onboarding__item__subtitle">
        {t(
          "cftChickenBroiler:onboarding.step5.subtitle",
          "Jeżeli wcześniej zapisałeś progres swojej kalkulacji możesz wgrać pobrany plik i kontynuować obliczenia."
        )}
      </p>
      <CftTank justifyContent="center" alignItems="center">
        <CftButton
          variant="active"
          onClick={handleClose}
          style={{ marginTop: "1rem" }}
        >
          {t("cft:onboarding.finish", "rozpocznij kalkulację")}
        </CftButton>
      </CftTank>
    </div>
  );
}

export default Step5;

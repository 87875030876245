import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftLabel,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftTextField,
  CftSelectField,
  CftTooltip,
} from "../../../../components";

// Energy types Icons
import { ReactComponent as AgregatorIcon } from "../../../../../assets/icons/cft/agregator.svg";
import { ReactComponent as EnergyIcon } from "../../../../../assets/icons/cft/energia.svg";
import { ReactComponent as CHPIcon } from "../../../../../assets/icons/cft/chp.svg";

import { energySourcesTypes } from "./fuelAndEnergyBothCalc.consts";

function ProcessingTypeFields({ id, name }) {
  const { setFieldValue, values } = useFormikContext();
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);

  const sourceType = values[name][id].energyType;

  return (
    <>
      <CftFormControl>
        <CftLabel
          label={t("cft:fuelAndEnergy.form.40", "Żródło energii")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fuelAndEnergy:tooltips.9",
                "Określ jaki rodzaj energii był wykorzystany do wykonania tego zabiegu"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].energyType`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "agregator"}
                  onClick={() => {
                    helpers.setValue("agregator");
                    setFieldValue(`${name}[${id}].energySource`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <AgregatorIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.15", "Agregator")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "electricity"}
                  onClick={() => {
                    helpers.setValue("electricity");
                    setFieldValue(`${name}[${id}].energySource`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <EnergyIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:fuelAndEnergy.form.16", "Prąd")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "chp"}
                  onClick={() => {
                    helpers.setValue("chp");
                    setFieldValue(`${name}[${id}].energySource`, "");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <CHPIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">CHP</p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering isConditionFulfilled={!!sourceType}>
        <CftFormControl variant="small">
          <CftLabel label={t("cft:fuelAndEnergy.form.18", "Agregator prądu")} />
          <CftSelectField
            name={`${name}[${id}].energySource`}
            options={
              energySourcesTypes[sourceType]
                ? energySourcesTypes[sourceType].map(option => ({
                    label: option[language],
                    value: option.value,
                  }))
                : []
            }
          />
        </CftFormControl>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel
          label={t("cft:fuelAndEnergy.form.50", "Przeznaczenie")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fuelAndEnergy:tooltips.0",
                "Określ jaki procent uprawy przeznaczasz na przetwarzanie"
              )}
            />
          }
        />
        <CftTextField name={`${name}[${id}].allocation`} adornment="%" />
      </CftFormControl>
      <CftFormControl>
        <CftLabel
          label={t("cft:fuelAndEnergy.form.54", "Typ")}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:fuelAndEnergy:tooltips.11",
                "Określ jaki rodzaj zabiegu był wykonany na tej uprawie"
              )}
            />
          }
        />
        <CftFormField name={`${name}[${id}].type`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "1"}
                  onClick={() => {
                    helpers.setValue("1");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.51", "Zmywanie")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "2"}
                  onClick={() => {
                    helpers.setValue("2");
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  <p className="cft-box__no-icon">
                    {t("cft:fuelAndEnergy.form.52", "Cieniowanie")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
    </>
  );
}

export default ProcessingTypeFields;

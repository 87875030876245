import NEED_ONGOING_FINANCING_GRAINS from "./seeds/needOngoingFinancing";
import EXPAND_MY_FARM_GRAINS from "./seeds/expandMyFarm";
import LOOKING_FOR_INSURANCE_GRAINS from "./seeds/lookingForInsurance";

export const MAIN_SEEDS = {
  needOngoingFinancing: "potrzebuje-biezacego-finansowania",
  expandMyFarm: "chce-rozwinac-swoje-gospodarstwo",

  naturalDisaster: "dotkneła-mnie-kleska-zywiolowa",
  openBankAccount: "chce-otworzyc-rachunek-bankowy",
  lookingForInsurance: "poszukuje-ubezpieczenia",
};

export const TITLE_MAP = {
  [MAIN_SEEDS.needOngoingFinancing]: "Potrzebuję bieżącego finansowania",
  [MAIN_SEEDS.expandMyFarm]: "Chcę rozwinąć swoje gospodarstwo",
  [MAIN_SEEDS.lookingForInsurance]: "Poszukuję ubezpieczenia",
};

export const SEEDS_MAP = {
  [MAIN_SEEDS.needOngoingFinancing]: NEED_ONGOING_FINANCING_GRAINS,
  [MAIN_SEEDS.expandMyFarm]: EXPAND_MY_FARM_GRAINS,
  [MAIN_SEEDS.lookingForInsurance]: LOOKING_FOR_INSURANCE_GRAINS,
};

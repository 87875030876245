import React from "react";
import PropTypes from "prop-types";
import "./RadioButton.scss";

export const RadioButton = props => {
  return (
    <div className="radioButton">
      <label htmlFor={props.id}>
        <input
          id={props.id}
          type="radio"
          onChange={props.onChange}
          {...props}
          className={`${props.hasError && "has-error"} ${props.className ||
            ""}`}
        />
        {props.checked ? <div className="radioButton--active" /> : null}
      </label>
      <span>{props.label}</span>
    </div>
  );
};

RadioButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  checked: false,
};

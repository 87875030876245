import Cookies from "js-cookie";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "recompose";
import appLogo from "../../../assets/logo/agronomist_a.svg";
import ContentBar from "../ContentBar/ContentBar";
import { ProfilMenu } from "../index";
import LanguageNavigation from "./LanguageNavigation";
import "./NavigationBar.scss";
import SearchBar from "./SearchBar";
import { selectUser } from "../../../services/redux/user/selectors";
import Navigation from "./components/Navigation";
import withCft from "../../../cft/utils/withCft";
import { ReactComponent as UserIcon } from "../../../assets/icons/menu-user.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/menu-search.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as MobileMenuIcon } from "../../../assets/icons/mobile-menu.svg";

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      showProfilMenu: false,
      searchBarOpen: false,
    };

    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleSearchBarOpen = this.handleSearchBarOpen.bind(this);
    this.handleProfilMenu = this.handleProfilMenu.bind(this);
    this.handleRedirectFromLogo = this.handleRedirectFromLogo.bind(this);
    this.mobileMenu = React.createRef();
  }

  componentWillUnmount() {
    const body = document.querySelector("body");
    body.classList.remove("block-scroll");
  }

  handleMenuOpen() {
    const { menuOpen } = this.state;
    const body = document.querySelector("body");
    if (this.mobileMenu.current.classList.contains("in")) {
      if (this.state.menuOpen) {
        body.classList.remove("block-scroll");
        this.mobileMenu.current.classList.remove("in");
        setTimeout(() => {
          this.setState({
            menuOpen: !menuOpen,
          });
        }, 201);
      }
    } else {
      this.setState(
        {
          menuOpen: !menuOpen,
        },
        () => {
          if (this.state.menuOpen) {
            setTimeout(() => {
              body.classList.add("block-scroll");
              this.mobileMenu.current.classList.add("in");
            }, 10);
          }
        }
      );
    }
  }

  handleSearchBarOpen() {
    const { searchBarOpen } = this.state;
    this.setState({
      searchBarOpen: !searchBarOpen,
    });
  }

  handleProfilMenu(value) {
    this.setState({
      showProfilMenu: value,
    });
  }

  getTabs() {
    const { t, i18n } = this.props;
    // const agroLink = {
    //   tabName: t("navigation:agroRaport2019"),
    //   redirectTo: "/agroraport",
    // };
    const usersLinks = [
      {
        tabName: t("navigation:navArticles"),
        redirectTo: i18n.language === "pl" ? "/artykuly" : "/articles",
      },
      {
        tabName: t("navigation:realEstate"),
        redirectTo: i18n.language === "pl" ? "/nieruchomosci" : "/real-estate",
      },
      {
        tabName: t("navigation:navEvents"),
        redirectTo: i18n.language === "pl" ? "/wydarzenia" : "/events",
      },
    ];
    const fieldLink = {
      tabName: t("navigation:navField"),
      redirectTo: i18n.language === "pl" ? "/galeria" : "/gallery",
    };
    const basicLinks = [
      fieldLink,
      {
        tabName: t("navigation:navAbout"),
        redirectTo: i18n.language === "pl" ? "/o-nas" : "/about",
      },

      // {
      //   tabName: t('navigation:navContact'),
      //   redirectTo: i18n.language === 'pl' ? '/kontakt' : '/contact'
      // },
    ];
    const toolsLink = {
      tabName: t("navigation:navTools"),
      redirectTo: "/narzedzia",
    };

    if (Cookies.get("user_token")) {
      if (Cookies.get("user_client_type") === "3") {
        const links = [...usersLinks, ...basicLinks];
        return [...links];
      }
      if (Cookies.get("user_client_type") === "2") {
        const links = [...usersLinks, ...basicLinks];
        return [...links];
      }
      return [...basicLinks];
    }
    return [...usersLinks, toolsLink, ...basicLinks];
  }

  getButtonText() {
    const { t } = this.props;
    if (Cookies.get("user_token")) {
      return t("navigation:navMyProfile");
    }
    return t("globals:buttons.login");
  }

  getButtonPath() {
    const { i18n } = this.props;

    if (Cookies.get("user_token")) {
      return "/my/profil/dashboard";
    }
    return i18n.language === "pl" ? "/uzytkownik/login" : "/user/login";
  }

  handleRedirectFromLogo(token, consumerType) {
    this.redirectTo = "/";
    if (token && consumerType === "3") {
      this.redirectTo = "/farmer";
    }
    if (token && consumerType === "2") {
      this.redirectTo = "/manufacturer";
    }
    return this.redirectTo;
  }

  render() {
    const { src, search, history, isMaintenanceMode } = this.props;
    const { menuOpen, showProfilMenu, searchBarOpen } = this.state;
    const token = Cookies.get("user_token");
    const consumerType = Cookies.get("user_client_type");

    const isAgroRaportRoute = history.location.pathname.includes("agroraport");

    if (isMaintenanceMode) {
      return (
        <div className="navigation-container-wrapper">
          <nav className="navigation-container">
            <Link
              className="navigation-logo"
              to={this.handleRedirectFromLogo(token, consumerType)}
            >
              <img
                className="appLogo"
                src={src || appLogo}
                alt=""
                width="157px"
                height="28px"
              />
            </Link>
          </nav>
        </div>
      );
    }

    return (
      <>
        <div
          ref={this.navRef}
          className="navigation-container-wrapper fixed"
          data-searchbar-open={searchBarOpen}
          data-mobile-nav-open={menuOpen}
        >
          <ContentBar />
          <div className="container-fluid">
            <nav
              className={`
                navigation-container
                ${menuOpen ? " navigation-container--open" : ""}
                ${searchBarOpen ? " navigation-container--searching" : ""}
              `}
            >
              <div className="navigation-container__row">
                <div
                  className="openMenu navigation-hamb"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleMenuOpen()}
                >
                  {menuOpen ? <CloseIcon /> : <MobileMenuIcon />}
                </div>

                <Link
                  className="navigation-logo"
                  to={this.handleRedirectFromLogo(token, consumerType)}
                >
                  <img
                    className="appLogo"
                    src={src || appLogo}
                    alt=""
                    width="157px"
                    height="28px"
                  />
                </Link>
                {!searchBarOpen && <Navigation />}

                <ul
                  ref={this.mobileMenu}
                  className={`navigation-list-mobile ${
                    menuOpen ? "navigation-list-mobile--visible" : ""
                  }`}
                >
                  <Navigation isMobile onClose={this.handleMenuOpen} />
                </ul>
              </div>

              {searchBarOpen && <SearchBar />}

              <div className="navigation-buttons">
                {searchBarOpen ? (
                  <div
                    className="navigation-buttons__button navigation-buttons__button--no-border"
                    onClick={() => this.handleSearchBarOpen()}
                  >
                    <CloseIcon />
                  </div>
                ) : (
                  <div
                    className="navigation-buttons__button"
                    onClick={() => this.handleSearchBarOpen()}
                  >
                    <SearchIcon />
                  </div>
                )}

                <Link
                  to={this.getButtonPath()}
                  onMouseEnter={() => this.handleProfilMenu(true)}
                  onMouseLeave={() => this.handleProfilMenu(false)}
                  className="navigation-buttons__button"
                >
                  <UserIcon />
                  {this.getButtonPath() === "/my/profil/dashboard" &&
                  showProfilMenu &&
                  token ? (
                    <ProfilMenu
                      logout={() => {
                        this.props.logoutUser();
                        this.props.clearCftData();
                      }}
                    />
                  ) : null}
                </Link>

                <LanguageNavigation />

                {/* <Link to={this.getButtonPath()} className="navigation-profile">
                  <img src={profileIcon} alt="" width="auto" height="auto" />
                  <p className="navigation-profile__txt">
                    {this.getButtonText()}
                  </p>
                </Link> */}
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

NavigationBar.propTypes = {
  search: PropTypes.bool,
  src: PropTypes.string,
  isMaintenanceMode: PropTypes.bool,
};

NavigationBar.defaultProps = {
  search: true,
  src: "",
  isMaintenanceMode: false,
};

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch({ type: "LOG_OUT" }),
  };
};

export default compose(
  withTranslation(["navigation", "globals"]),
  withCft,
  connect(
    state => ({
      user: selectUser(state),
    }),
    mapDispatchToProps
  )
)(withRouter(NavigationBar));

import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useHover } from "use-events";

import "./CftButton.scss";

function CftButton({
  children,
  variant,
  onClick,
  isActive,
  isFullwidth,
  isInvalid,
  type,
  noMargin,
  isRounded,
  isAlternative,
  isNegative,
  ...props
}) {
  const [isHoveredState, bind] = useHover();
  const isRenderProp = typeof children === "function";

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...props}
      {...bind}
      type={type}
      className={classnames("cft-button", {
        "cft-button--isInvalid": isInvalid,
        "cft-button--isFullwidth": isFullwidth,
        "cft-button--isHovered": isHoveredState,
        "cft-button--isActive": isActive || variant === "active",
        "cft-button--isTransparent": variant === "transparent",
        "cft-button--isHighlighted": variant === "highlighted",
        "cft-button--isText": variant === "text",
        "cft-button--isExport": variant === "export",
        "cft-button--isImport": variant === "import",
        "cft-button--noMargin": noMargin,
        "cft-button--isRounded": isRounded,
        "cft-button--isAlternative": isAlternative,
        "cft-button--isNegative": isNegative,
      })}
      onClick={e => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      {isRenderProp ? children({ isHoveredState }) : children}
    </button>
  );
}

CftButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf([
    "default",
    "transparent",
    "highlighted",
    "active",
    "text",
    "export",
    "import",
    "alternative",
  ]),
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isFullwidth: PropTypes.bool,
  type: PropTypes.string,
  isInvalid: PropTypes.bool,
  isRounded: PropTypes.bool,
  isAlternative: PropTypes.bool,
  isNegative: PropTypes.bool,
};

CftButton.defaultProps = {
  onClick: null,
  isActive: false,
  isFullwidth: false,
  variant: "default",
  type: "button",
  isInvalid: false,
  isRounded: false,
  isAlternative: false,
  isNegative: false,
};

export default CftButton;

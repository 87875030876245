import { Langs } from "./locale";

export const checkForRedirect = (
  history,
  currentLang,
  routePl,
  routeGb,
  slug
) => {
  if (
    history.length < 3 &&
    (history.location.pathname === routeGb ||
      history.location.pathname === `${routeGb}/` ||
      history.location.pathname.indexOf(routeGb) !== -1)
  ) {
    const langs = Langs();
    langs.switchLang("gb", true);
    history.push(slug ? `${routeGb}/${slug}` : routeGb);
  } else {
    if (slug) {
      return currentLang === "pl" ? `${routePl}/${slug}` : `${routeGb}/${slug}`;
    }
    return currentLang === "pl" ? routePl : routeGb;
  }
  return "";
};

export const checkDeepRedirect = (
  history,
  contentPath,
  { slug, lang_code }
) => {
  if (!contentPath.includes("/wydarzenia/") && lang_code === "PL") {
    return history.push(`/wydarzenia/${slug}`);
  }
  return false;
};

/* eslint-disable no-undef */
import Cookies from "js-cookie";
import * as actions from "./actions";
import { objectToGetParams } from "../../../helpers/utilities";

const InitialState = {
  count: -1,
  next: "",
  previous: "",
  results: [],
  suggested: [],
  isError: false,
};

export default function report(state = InitialState, action) {
  switch (action.type) {
    case actions.GET_REPORT_ARTICLES_SUCCESS: {
      const {
        payload: { data },
      } = action;
      if (!data.results.length) {
        data.results = null;
      }
      return {
        ...data,
        isError: false,
      };
    }
    default:
      return state;
  }
}

export const getReportArticles = (page = 1, filters = {}, limit = 20) => {
  const token = Cookies.get("user_token");
  const params = objectToGetParams(filters);
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : null;
  const offset = limit * (page - 1) || 0;
  return {
    type: actions.GET_REPORT_ARTICLES,
    payload: {
      request: {
        url: `/api/v2/agroraport-articles/${params}&limit=${limit}&offset=${offset}`,
        method: "GET",
        headers,
      },
    },
  };
};

import React from "react";
import "./ListWithIterator.scss";

export const ListWithIterator = ({ list }) =>
  list.length ? (
    <ol className="iterator-list">
      {list.map((item, index) => (
        <li key={index}>
          <div className="iterator-list__body">
            <p className="iterator-list__title">{item.title}</p>
            <p className="iterator-list__desc">{item.desc}</p>
          </div>
        </li>
      ))}
    </ol>
  ) : null;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./Button.scss";

// buttondisabled - props value if set, set to "inactive"
// buttonsize - props value "small" or "big"
// buttoncolor - props value "yellow" or "green"

const classes = props => {
  let setclasses = "bttn";
  if (props.buttondisabled) {
    setclasses += ` bttn--${props.buttondisabled}`;
  }
  if (props.buttoncolor) {
    setclasses += ` bttn--${props.buttoncolor}`;
  }
  if (props.buttonsize) {
    setclasses += ` bttn--${props.buttonsize}`;
  }
  if (props.buttoncenter) {
    setclasses += ` bttn--${props.buttoncenter}`;
  }
  if (props.absolute) {
    setclasses += " bttn--abs bttn--abs-bttm";
  }
  return setclasses;
};

export const ButtonFix = ({ link, target, nofollow, ...props }) => {
  return target === "_blank" ? (
    <a
      href={link}
      {...props}
      // eslint-disable-next-line react/jsx-no-target-blank
      target="_blank"
      rel={`noopener noreferrer${nofollow ? " nofollow" : ""}`}
      className={classes(props)}
    >
      {props.children}
    </a>
  ) : link ? (
    <Link to={link} {...props} className={classes(props)}>
      {props.children}
    </Link>
  ) : (
    // eslint-disable-next-line react/button-has-type
    <button
      {...props}
      type={props.type ? props.type : "button"}
      className={classes(props)}
    >
      {props.children}
    </button>
  );
};

ButtonFix.propTypes = {
  buttondisabled: PropTypes.string,
  buttonsize: PropTypes.string.isRequired,
  buttoncolor: PropTypes.string.isRequired,
  children: PropTypes.node,
  type: PropTypes.string,
};

ButtonFix.defaultProps = {
  buttondisabled: "",
  children: null,
  type: "button",
};

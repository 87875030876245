import React, { useState, createContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import API from "../../../services/apiHelpers";

const initialContext = {
  quizzes: {
    count: -1,
    next: null,
    previous: null,
    results: [],
  },
};

export const QuizzesContext = createContext(initialContext);

export const QuizzesProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [quizzes, setQuizzes] = useState({ ...initialContext.quizzes });

  const getQuizzes = params =>
    API()
      .get("/api/v1/quizzes/", {
        params: {
          lang_code: Cookies.get("user_lang")
            ? Cookies.get("user_lang").toUpperCase()
            : i18n.language.toUpperCase(),
          ordering: "-published_at",
          limit: 9,
          ...params,
        },
      })
      .then(({ data }) => {
        return setQuizzes(data);
      });

  useEffect(() => {
    if (quizzes.count === -1) {
      getQuizzes();
    }
  }, []);

  return (
    <QuizzesContext.Provider value={{ quizzes, getQuizzes }}>
      {children}
    </QuizzesContext.Provider>
  );
};

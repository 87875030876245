import React from "react";
import PropTypes from "prop-types";
import "./Select.scss";
import Select from "react-select";

const customStyles = {
  container: provided => ({
    ...provided,
    border: "2px solid #00965E",
  }),
  control: provided => ({
    ...provided,
    border: 0,
    borderRadius: 0,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  input: provided => ({
    ...provided,
    paddingTop: 6,
    paddingBottom: 6,
    lineHeight: "20px",
  }),
  menu: provided => ({ ...provided, zIndex: 20 }),
};

const stylesError = {
  container: provided => ({
    ...provided,
    border: "2px solid #E92B5C",
  }),
};

const SelectInput = props => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className="select-label" htmlFor={props.id}>
        {props.label}
      </label>
      <Select
        inputId={props.id}
        styles={{ ...customStyles, ...(props.haserror && stylesError) }}
        {...props}
      />
      {props.haserror && (
        <p className="select__input-error">{props.errormsg}</p>
      )}
    </>
  );
};

SelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};

SelectInput.defaultProps = {
  id: null,
  label: "",
};

export default SelectInput;

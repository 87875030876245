import API from "../../services/apiHelpers";

export const scrollToElement = targetSelector => {
  const element = document.querySelector(targetSelector);
  if (element) {
    const y = element.getBoundingClientRect().top + window.pageYOffset - 150;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};

export const onContactSubmit = (values, formikHelpers) =>
  API()
    .post("/api/v1/product-contacts/", {
      name: values.name,
      telephone: values.phone,
      county: values.district,
      email: values.email,
      product_url: values.product_url,
      product_name: values.product_name,
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus("done");
    });

export const onAllegroContactFormSubmit = (values, formikHelpers) =>
  API()
    .post("/api/v1/promo-product-contacts/", {
      name: values.name,
      surname: values.surname,
      telephone: values.phone,
      county: values.county,
      email: values.email,
      category: values.category,
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
      formikHelpers.setStatus("done");
    });

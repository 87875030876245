import { lazy } from "react";
import routes from "./avgPricesRoutes";

// import CerealPrices from "../views/CerealPrices/CerealPrices";
// import OleaginousPrices from "../views/OleaginousPrices/OleaginousPrices";
// import PoultryPrices from "../views/PoultryPrices/PoultryPrices";
// import PorkPrices from "../views/PorkPrices/PorkPrices";
// import DairyPrices from "../views/DairyPrices/DairyPrices";

const CerealPrices = lazy(() => import("../views/CerealPrices/CerealPrices"));
const OleaginousPrices = lazy(() =>
  import("../views/OleaginousPrices/OleaginousPrices")
);
const PorkPrices = lazy(() => import("../views/PorkPrices/PorkPrices"));
const PoultryPrices = lazy(() =>
  import("../views/PoultryPrices/PoultryPrices")
);
const DairyPrices = lazy(() => import("../views/DairyPrices/DairyPrices"));

export default [
  // GRAIN RELATED PATHS
  {
    path: `${routes.GRAINS.GRAINS_MAIN}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.BARLEY}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.CORN}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.OAT}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.WHEAT}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.TRITICALE}`,
    component: CerealPrices,
  },
  {
    path: `${routes.GRAINS.RYE}`,
    component: CerealPrices,
  },
  // === OIL RELATED PATHS ===
  {
    path: `${routes.OILS.OILS_MAIN}`,
    component: OleaginousPrices,
  },
  {
    path: `${routes.OILS.SEEDS}`,
    component: OleaginousPrices,
  },
  {
    path: `${routes.OILS.MEAL}`,
    component: OleaginousPrices,
  },
  {
    path: `${routes.OILS.CAKE}`,
    component: OleaginousPrices,
  },
  {
    path: `${routes.OILS.CAKE}`,
    component: OleaginousPrices,
  },
  // POULTRY RELATED PATHS
  {
    path: `${routes.POULTRY.POULTRY_MAIN}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.GOOSE}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.TURKEY_M}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.TURKEY_F}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.DUCK}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.CHICKEN}`,
    component: PoultryPrices,
  },
  {
    path: `${routes.POULTRY.CHICKEN_HERD}`,
    component: PoultryPrices,
  },
  // PORK PATH
  {
    path: `${routes.PORK_MAIN}`,
    component: PorkPrices,
  },
  // DAIRY RELATED PATHS
  {
    path: `${routes.DAIRY.DAIRY_MAIN}`,
    component: DairyPrices,
  },
  {
    path: `${routes.DAIRY.BUTTER}`,
    component: DairyPrices,
  },
  {
    path: `${routes.DAIRY.MILK_POWDER}`,
    component: DairyPrices,
  },
  {
    path: `${routes.DAIRY.LIQUIDS}`,
    component: DairyPrices,
  },
  {
    path: `${routes.CHEESE.CHEESE_MAIN}`,
    component: DairyPrices,
  },
  {
    path: `${routes.CHEESE.GOUDA}`,
    component: DairyPrices,
  },
  {
    path: `${routes.CHEESE.EDAM}`,
    component: DairyPrices,
  },
  {
    path: `${routes.CHEESE.COTTAGE}`,
    component: DairyPrices,
  },
];

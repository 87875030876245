import React from "react";
import classnames from "classnames";
import Cookies from "js-cookie";
import { Redirect } from "react-router-dom";

import { useCft } from "../../utils/CftProvider";
import "./CftLayout.scss";

function CftLayout({ children, footer, header, withoutRedirect, ...props }) {
  const token = Cookies.get("user_token");
  const { validIp } = useCft();

  if (validIp === null) {
    return null;
  }

  if (validIp === false) {
    return <Redirect to="/404" />;
  }

  if (!token && !withoutRedirect) {
    return <Redirect to="/narzedzia/kalkulator-emisji-co2" />;
  }

  return (
    <>
      {header}
      <div
        className={classnames("cft-layout", {
          "cft-layout--withHeader": !!header,
          "cft-layout--withFooter": !!footer,
          "cft-layout--withTrim": !!footer && !!header,
        })}
        {...props}
      >
        {children}
      </div>
      {footer}
    </>
  );
}

export default CftLayout;

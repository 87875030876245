import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import {
  CftBox,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftTooltip,
} from "../../../components";

// Soil categories
import { ReactComponent as ArableIcon } from "../../../../assets/icons/cft/orny.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/icons/cft/trawa.svg";
import { ReactComponent as ForestIcon } from "../../../../assets/icons/cft/las.svg";
import { ReactComponent as CropsIcon } from "../../../../assets/icons/cft/crops.svg";
import { ReactComponent as FieldIcon } from "../../../../assets/icons/cft/field.svg";

import { landUseTypes } from "./carbonChanges.consts";
import TillageFields from "./TillageFields";
import CarbonInputsFields from "./CarbonInputsFields";

const FIELDS_ICONS = {
  1: <ArableIcon className="cft-box__svg-fix" />,
  3: <CropsIcon className="cft-box__svg-fix" />,
  4: <FieldIcon className="cft-box__svg-fix" />,
  5: <GrassIcon className="cft-box__svg-fix" />,
  6: <ForestIcon className="cft-box__svg-fix" />,
};
function ConvertedFields({ name, id }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);

  const { values } = useFormikContext();

  const parentLandFromId =
    id !== 0
      ? values.managementPractice.carbon_changes[id - 1].land_use.land_from
      : null;

  return (
    <>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.18",
            "Kiedy nastąpiło przekształcenie"
          )}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:carbonChanges.tooltips.3",
                "Wskaż rok kiedy nastąpiło przekształcenie"
              )}
            />
          }
        />
        <CftTextField name={`${name}.year_of_change`} mask="0000" />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:carbonChanges.form.19", "Procent pola")} />
        <CftTextField name={`${name}.allocation`} adornment="%" mask="000" />
      </CftFormControl>
      <CftFormControl>
        <CftLabel label={t("cft:carbonChanges.form.11", "Zmienione z...")} />
        <CftFormField name={`${name}.land_use.land_from`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {landUseTypes.map(landUse => (
                  <CftBox
                    key={`landUse_${landUse.id}`}
                    isActive={value === landUse.id}
                    onClick={() => {
                      helpers.setValue(landUse.id);
                    }}
                    isInvalid={meta.touched && meta.error}
                    variant="medium"
                  >
                    {FIELDS_ICONS[landUse.id]}
                    <p className="cft-box__paragraph">{landUse[language]}</p>
                  </CftBox>
                ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftFormControl>
        <CftLabel label={t("cft:carbonChanges.form.15", "Zmieniony na...")} />
        <CftFormField name={`${name}.land_use.land_to`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                {landUseTypes
                  .filter(landUse => {
                    if (parentLandFromId) {
                      return parentLandFromId === landUse.id;
                    }

                    return true;
                  })
                  .map(landUse => (
                    <CftBox
                      key={`landUse_${landUse.id}`}
                      isActive={value === landUse.id}
                      onClick={() => {
                        helpers.setValue(landUse.id);
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <ArableIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">{landUse[language]}</p>
                    </CftBox>
                  ))}
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <TillageFields name={name} id={id} />
      <CarbonInputsFields name={name} id={id} />
    </>
  );
}

export default ConvertedFields;

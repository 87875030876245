import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftActionsButtons,
  CftReportProgress,
  CftExportReport,
  CftDairyProductionRestricted,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import ForageDPForm, {
  ForageDPFormContext,
} from "../../forms/dairyProduction/ForageDPForm/ForageDP.form";

function ForagePage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { saveDairyProduction } = useCft();
  const { submitForm, values, isSubmitting } = useFormikContext();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={42}
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveDairyProduction({
                    forage: values,
                  });
                  history.push(routes.DAIRY_PRODUCTION.MAIN);
                }}
              />
              <CftExportReport type="dairyProduction" />
            </div>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:forage.title", "Pasza")}
          onBack={() => history.push(routes.DAIRY_PRODUCTION.CATTLE_GRAZING)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:forage.title", "Pasze")}</h3>
            <p>
              {t(
                "cft:forage.subtitle",
                "Jeśli znasz wartości spożycia suchej masy (DMI), wybierz pierwszą metodę. W przeciwnym razie wybierz drugą metodę, a narzędzie oszacuje DMI."
              )}
            </p>
          </>
        }
      >
        <CftDairyProductionRestricted>
          <ForageDPForm />
        </CftDairyProductionRestricted>
        <CftReportProgress type="dairyProduction" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <ForageDPFormContext>
      <ForagePage />
    </ForageDPFormContext>
  );
};

import React from "react";
import classnames from "classnames";
import { Col, Container, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { CftProgress } from "../index";
import { ReactComponent as Logo } from "../../../assets/logo/agronomist_a.svg";

import "./CftHeader.scss";

function CftHeader({ progressValue, cropName, actions, title, logoUrl }) {
  const { t } = useTranslation(["cft"]);
  return (
    <header
      className={classnames("cft-header", {
        "cft-header--withProgressBar": progressValue !== null,
      })}
    >
      <Container>
        <Row>
          <Col xs={12} className="cft-header__content">
            <div className="cft-header__logo">
              <Link to={logoUrl || "/farmer"}>
                <Logo />
              </Link>
            </div>
            <div>
              {cropName && (
                <>
                  <h3 className="cft-header__title">
                    {t("cft:shorttitle", "Agroemisja")}
                  </h3>
                  <p className="cft-header__crop">{cropName}</p>
                </>
              )}
              {title && title}
            </div>
            <div>{actions}</div>
          </Col>
        </Row>
      </Container>
      {progressValue !== null && (
        <CftProgress isHeader value={progressValue} color="#00965e" />
      )}
    </header>
  );
}

CftHeader.propTypes = {
  progressValue: PropTypes.number,
  cropName: PropTypes.string,
};

CftHeader.defaultProps = {
  progressValue: null,
  cropName: null,
};

export default CftHeader;

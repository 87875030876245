import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
import "./LoginForm.scss";
import { Metadata, ButtonFix as Button, Input } from "../index";
// import facebookLoginFormImg from "../../../../assets/img/fb_logowanie.jpg";
// import googleLoginFormImg from "../../../../assets/img/g_logowanie.jpg";
import { logUserIn, clearErrors } from "../../../services/redux/user/reducer";
import { Langs } from "../../../services/helpers/locale";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      notConfimed: false,
      letRedirect: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.clearErrors();
    if (!this.state.letRedirect) {
      Cookies.remove("redirect");
      this.props.setRedirect();
    }
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
    });

    this.props.clearErrors();
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value,
    });

    this.props.clearErrors();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      notConfimed: false,
    });
    const { email, password } = this.state;
    const { onLoginCallback } = this.props;
    this.props.onSubmit(email, password).then(({ payload }) => {
      if (payload && payload.data.token) {
        Langs().switchLang(payload.data.user.default_language, true);
        onLoginCallback(payload.data);
      } else if (payload && payload.data.is_email_confirmed === "False") {
        this.setState({
          notConfimed: true,
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.props.userType && this.props.loggedIn) {
      if (this.props.userType === 3 && this.props.location.pathname === "/") {
        this.props.history.push("/farmer");
      }
      if (this.props.userType2 === 2 && this.props.location.pathname === "/") {
        this.props.history.push("/manufacturer");
      }
    }
  }

  render() {
    const { email, password, notConfimed } = this.state;
    const { errors, t, i18n } = this.props;

    return (
      <div className="m-login-form user-logIn-page__main-wrapper">
        <Container>
          <Metadata
            name={t("metaInfo:loginPage.title")}
            description={t("metaInfo:loginPage.description")}
          />
          <Row>
            <Col className="user-logIn-page" xs={12}>
              <div className="logIn-description">
                <form onSubmit={this.handleSubmit}>
                  <Input
                    id="email"
                    label={t("user:inputs.email")}
                    type="email"
                    name="email"
                    inputstate={errors.email || errors.detail ? "error" : ""}
                    haserror={!!errors.email || !!errors.detail}
                    errormsg={errors.email && t("validation:email")}
                    value={email}
                    onChange={this.handleEmailChange}
                  />
                  <Input
                    id="password"
                    label={t("user:inputs.password")}
                    type="password"
                    name="password"
                    inputstate={errors.password || errors.detail ? "error" : ""}
                    haserror={!!errors.password || !!errors.detail}
                    errormsg={
                      (errors.password || errors.detail) &&
                      t("validation:password2")
                    }
                    value={password}
                    onChange={this.handlePasswordChange}
                  />
                  {notConfimed ? (
                    // eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for
                    <label htmlFor="">
                      <p className="input-error input-error--big">
                        {t("user:activation.unactive")}
                      </p>
                    </label>
                  ) : null}
                  <RouterLink
                    className="forgot-password-link"
                    to={
                      i18n.language === "pl"
                        ? "/uzytkownik/przypomnienie-hasla"
                        : "/user/passwordReminder"
                    }
                  >
                    {t("user:login.remind")}
                  </RouterLink>
                  <Button type="submit">{t("globals:buttons.login")}</Button>
                </form>
              </div>
              <p className="user-register__modal">
                {t("widgets:popup.noAccount")}{" "}
                <a href="/uzytkownik/rejestracja">
                  {t("widgets:popup.signUp")}
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearErrors: () => dispatch(clearErrors()),
    onSubmit: (email, password) => dispatch(logUserIn(email, password)),
    setRedirect: () => dispatch({ type: "SET_REDIRECT", data: false }),
  };
};

const mapStateToProps = state => ({
  logInError: state.user.isError,
  errors: state.user.errors || [],
  loggedIn: state.user.loggedIn,
  userType: state.user.user.consumer_type,
  redirectUser: state.user.redirect,
});

export default compose(
  withTranslation(["metaInfo", "user", "validation", "globals", "widgets"]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(withRouter(LoginForm));

LoginForm.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.string),
    password: PropTypes.arrayOf(PropTypes.string),
  }),
  onLoginCallback: PropTypes.func,
};

LoginForm.defaultProps = {
  errors: [],
  onLoginCallback: () => {},
};

import React from "react";

import VideoCover from "./VideoCover";

import "./VideosPreview.scss";

function VideosPreview({ video }) {
  return (
    <div className="VideosPreview">
      {video && <VideoCover isBig video={video} />}
    </div>
  );
}

export default VideosPreview;

import PropTypes from "prop-types";
import React, { Component } from "react";

import { ButtonAdditional } from "../ButtonAdditional/ButtonAdditional";
import "./Pagination.scss";

class Pagination extends Component {
  render() {
    const { pageNumber, onPageChange, maximumPages, currentPage } = this.props;
    const pages = maximumPages;
    const pagination = new Set();
    for (let i = 1; i <= pages; i += 1) {
      if (pages < 10) {
        pagination.add(
          <div
            key={i}
            className={i === currentPage ? "page-item--selected" : "page-item"}
            onClick={event => {
              onPageChange(parseInt(event.target.innerText, 10));
            }}
            role="button"
            tabIndex={0}
          >
            {i}
          </div>
        );
      } else if (
        i === 1 ||
        (currentPage - 2 < i && currentPage + 2 > i) ||
        i === pages - 1
      ) {
        pagination.add(
          <div
            key={i}
            className={i === currentPage ? "page-item--selected" : "page-item"}
            onClick={event => {
              onPageChange(parseInt(event.target.innerText, 10));
            }}
            role="button"
            tabIndex={0}
          >
            {i}
          </div>
        );
      } else if (i === currentPage + 2 || i === currentPage - 2) {
        pagination.add(
          <div
            key={i}
            className={i === currentPage ? "page-item--selected" : "page-item"}
            role="button"
            tabIndex={0}
          >
            ...
          </div>
        );
      }
    }

    if (pages <= 1) {
      return null;
    }

    return (
      <div className="pagination-container">
        <ButtonAdditional
          className={`${
            currentPage === 1 ? "pagination-arrow-prev--disabled" : ""
          } pagination-arrow-prev`}
          buttoncolor="yellow"
          iconColor="#FFA203"
          onClickIcon={() => {
            onPageChange(currentPage - 1);
          }}
        />
        <div className="pages-container">
          {pageNumber.length === 1 ? (
            <div
              key={1}
              className={
                currentPage === 1 ? "page-item--selected" : "page-item"
              }
              onClick={event => {
                onPageChange(parseInt(event.target.innerText, 10));
              }}
              role="button"
              tabIndex={0}
            >
              1
            </div>
          ) : (
            pagination
          )}
        </div>
        <ButtonAdditional
          className={`${
            maximumPages === currentPage
              ? "pagination-arrow-next--disabled"
              : ""
          } pagination-arrow-next`}
          buttoncolor="yellow"
          iconColor="#FFA203"
          onClickIcon={() => {
            if (maximumPages !== currentPage) {
              onPageChange(currentPage + 1);
            }
          }}
        />
      </div>
    );
  }
}

Pagination.propTypes = {
  pageNumber: PropTypes.arrayOf(PropTypes.number),
  onPageChange: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  pageNumber: 1,
};

export default Pagination;

import React, { useEffect, useRef, useState } from "react";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import regagriApi, {
  errorDataMapper,
  reportDataMapper,
  submitMapper,
} from "../utils/regagriApi";
import { RegagriReport } from "./index";
import RegagriLocation from "./RegagriLocation";
import RegagriSoilTypes from "./RegagriSoilTypes";
import RegagriFertilizers from "./RegagriFertilizers";
import RegagriPractices from "./RegagriPractices";

const Form = () => {
  const { t } = useTranslation(["regagri"]);
  const [domReady, setDomReady] = React.useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const ref = useRef();

  useEffect(() => {
    setDomReady(true);
  }, []);

  const [soilTypesData, setSoilTypesData] = useState([]);
  const [practicesData, setPracticesData] = useState();
  const [initialValues, setInitialValues] = useState({
    name: "",
    farmSize: "",
    geo: [],
    soil: [],
    organicMatter: "",
    quantity: "",
    applicationArea: "",
    price: "32",
    practices: [],
    modalInput: "",
  });
  const [isReportVisible, setIsReportVisible] = useState(false);
  const [reportData, setReportData] = useState({
    details: {
      name: "",
      size: "",
      soilType: [],
    },
    explorer: {
      reductionPotential: {
        value: 0,
        reduction: 0,
        removals: 0,
      },
      income: {
        reduction: 0,
      },
      climate: {
        length: 0,
        temp: 0,
      },
    },
    potential: [],
    chart: [],
  });

  useEffect(() => {
    regagriApi
      .cultivationDetails()
      .then(({ data }) => {
        if (data) {
          setSoilTypesData(data.soil_types);
          setPracticesData(data.practices);
          setInitialValues(prevValues => ({
            ...prevValues,
            organicMatter: 0,
          }));
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, []);

  const handleScrollToError = () => {
    if (ref.current) {
      setTimeout(() => {
        if (!ref.current.isValid) {
          const firstErrorName = Object.keys(ref.current.errors)[0];
          const selector = document.getElementById(firstErrorName);
          window.scrollTo({
            top: selector.offsetTop
              ? selector.offsetTop
              : selector.offsetParent.offsetTop,
            behavior: "smooth",
          });
        }
      }, 0);
    }
  };

  const handleSubmit = values => {
    regagriApi
      .report(submitMapper(values))
      .then(({ data }) => {
        setReportData(prevState => reportDataMapper(prevState, values, data));
        setIsReportVisible(true);
      })
      .catch(error => {
        if (error.response.status === 400 && ref.current) {
          const errors = errorDataMapper(error.response.data);
          if (Object.keys(ref.current.errors).length === 0) {
            Object.keys(errors).forEach(key => {
              ref.current.setFieldError(key, errors[key]);
            });
          }
          handleScrollToError();
        } else {
          // eslint-disable-next-line no-console
          console.err(error);
        }
      });
  };

  useEffect(() => {
    if (isReportVisible) {
      window.scrollTo({
        top: document.querySelector(".regagri-report").offsetTop - 60,
        behavior: "smooth",
      });
    }
  }, [isReportVisible]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={ref}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .trim()
          .max(
            20,
            t(
              "regagri:cultivationDetails.form.location.inputName.validations.0"
            )
          )
          .required(
            t(
              "regagri:cultivationDetails.form.location.inputName.validations.1"
            )
          ),
        farmSize: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(
            t("regagri:cultivationDetails.form.location.farmSize.validations.0")
          )
          .typeError(
            t("regagri:cultivationDetails.form.location.farmSize.validations.0")
          )
          .min(
            1,
            t("regagri:cultivationDetails.form.location.farmSize.validations.0")
          ),
        soil: Yup.array().of(
          Yup.object().shape({
            id: Yup.number(),
            area: Yup.number()
              // eslint-disable-next-line func-names
              .test("correctSum", function() {
                if (
                  this.from.length > 1 &&
                  this.from[1].value.soil.length > 1
                ) {
                  if (
                    Number(this.from[1].value.soil[0].area) +
                      Number(this.from[1].value.soil[1].area) >
                    100
                  ) {
                    return this.createError({
                      path: this.path,
                      message: t(
                        "regagri:cultivationDetails.form.soilTypes.area.validations.1"
                      ),
                    });
                  }
                }
                return true;
              })
              .transform((_, v) => Number(v.replace(/,/, ".")))
              .typeError(
                t(
                  "regagri:cultivationDetails.form.soilTypes.area.validations.0"
                )
              )
              .min(
                0,
                t(
                  "regagri:cultivationDetails.form.soilTypes.area.validations.0"
                )
              )
              .max(
                100,
                t(
                  "regagri:cultivationDetails.form.soilTypes.area.validations.0"
                )
              )
              .required(
                t(
                  "regagri:cultivationDetails.form.soilTypes.area.validations.0"
                )
              ),
          })
        ),
        organicMatter: Yup.number()
          .transform((_, v) => {
            return Number(v.toString().replace(/,/, "."));
          })
          .typeError(
            t(
              "regagri:cultivationDetails.form.soilTypes.organicMatter.validations.0"
            )
          )
          .min(
            0,
            t(
              "regagri:cultivationDetails.form.soilTypes.organicMatter.validations.0"
            )
          )
          .max(
            100,
            t(
              "regagri:cultivationDetails.form.soilTypes.organicMatter.validations.0"
            )
          )
          .required(
            t(
              "regagri:cultivationDetails.form.soilTypes.organicMatter.validations.0"
            )
          ),
        quantity: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .typeError(
            t(
              "regagri:cultivationDetails.form.fertilizers.quantity.validations.0"
            )
          )
          .required(
            t(
              "regagri:cultivationDetails.form.fertilizers.quantity.validations.0"
            )
          ),
        applicationArea: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .typeError(
            t(
              "regagri:cultivationDetails.form.fertilizers.applicationArea.validations.0"
            )
          )
          .required(
            t(
              "regagri:cultivationDetails.form.fertilizers.applicationArea.validations.0"
            )
          ),
        price: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .required(
            t("regagri:cultivationDetails.form.fertilizers.price.validations.1")
          )
          .typeError(
            t("regagri:cultivationDetails.form.fertilizers.price.validations.0")
          ),
        modalInput: Yup.number()
          .transform((_, v) => Number(v.replace(/,/, ".")))
          .typeError(
            t("regagri:cultivationDetails.form.practices.modal.validations.0")
          )
          .min(
            0,
            t("regagri:cultivationDetails.form.practices.modal.validations.0")
          )
          .max(
            100,
            t("regagri:cultivationDetails.form.practices.modal.validations.0")
          ),
      })}
      onSubmit={handleSubmit}
    >
      <FormikForm
        onChange={() => {
          if (isReportVisible) {
            setIsReportVisible(false);
          }
        }}
      >
        <>
          <RegagriLocation
            typesData={soilTypesData}
            selectedTypes={selectedTypes}
            setSelectedTypes={setSelectedTypes}
            setReportData={setReportData}
          />
          <RegagriSoilTypes
            typesData={soilTypesData}
            setSelectedTypes={setSelectedTypes}
            selectedTypes={selectedTypes}
          />
          <RegagriFertilizers />
          <RegagriPractices practicesData={practicesData} />
          <div className="submit-btn">
            <button
              className="button--small button--green"
              type="submit"
              onClick={handleScrollToError}
            >
              {t("regagri:cultivationDetails.form.submit")}
            </button>
          </div>
          {isReportVisible && (
            <div>
              {domReady &&
                createPortal(
                  <RegagriReport data={reportData} />,
                  document.getElementById("regagri-report-portal")
                )}
            </div>
          )}
        </>
      </FormikForm>
    </Formik>
  );
};

export default Form;

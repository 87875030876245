import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import {
  CftTank,
  CftLabel,
  CftSelectField,
  CftFormControl,
  CftUnitField,
} from "../../components";
import useCftAeOptions from "../../utils/useCftAeOptions";

export default function EnergyCarriersFields() {
  const { t } = useTranslation(["cftChickenBroiler"]);
  const { carrierOptions, getCarrierName, energyUnits } = useCftAeOptions();
  const { setFieldValue, values } = useFormikContext();

  const options = useMemo(
    () =>
      carrierOptions.filter(
        ({ value }) =>
          !values.energy_carriers
            .map(({ carrier_type }) => carrier_type)
            .includes(value)
      ),
    [values]
  );

  return (
    <>
      <h2 className="cft-section-title mb-3">
        {t(
          "cftChickenBroiler:energyCarriersFields.title",
          "Informacje o nośnikach energii"
        )}
      </h2>
      <h4 className="cft-section-subtitle mb-7 cft-wrapper-cb">
        {t(
          "cftChickenBroiler:energyCarriersFields.subtitle",
          "Podaj informacje o stosowanych nośnikach energii. Możesz zdefiniować do 6 różnych nośników."
        )}
      </h4>
      <div className="cft-paper mb-6">
        {!!values["energy_carriers"].length && (
          <CftFormControl alignLeft>
            {values["energy_carriers"].map(({ carrier_type }, key) => (
              <CftTank key={carrier_type} display="block" mt={key && 2}>
                <CftTank
                  mb={2}
                  justifyContent="space-between"
                  alignItems="center"
                  flexDirection="row"
                >
                  <span className="cft-badge">
                    {key + 1}. {getCarrierName(carrier_type, t)}
                  </span>
                  <button
                    className="cft-close-badge"
                    type="button"
                    onClick={() =>
                      setFieldValue(
                        "energy_carriers",
                        values["energy_carriers"].filter(
                          carrier => carrier.carrier_type !== carrier_type
                        )
                      )
                    }
                  />
                </CftTank>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftChickenBroiler:energyCarriersFields.fields.energy_carriersLabel",
                    "Ilość zużytego nośnika"
                  )}
                />
                <CftUnitField
                  name={`energy_carriers[${key}]`}
                  units={[energyUnits[Number(carrier_type) - 1]]}
                  isAlternative
                  mask="000000000"
                />
              </CftTank>
            ))}
          </CftFormControl>
        )}

        {!!options.length && (
          <CftFormControl alignLeft withoutMarginBottom>
            <CftLabel
              isFullWidth
              label={t(
                "cftChickenBroiler:energyCarriersFields.fields.energy_carriersSublabel",
                "Dodaj nośnik energii"
              )}
            />
            <CftSelectField
              name="tmp"
              options={options}
              onChange={value => {
                setFieldValue("energy_carriers", [
                  ...values["energy_carriers"],
                  {
                    carrier_type: value,
                    value: 0,
                    unit: 2,
                  },
                ]);
              }}
              placeholder={t(
                "cftChickenBroiler:energyCarriersFields.fields.energy_carriersPlaceholder",
                "Wybierz z listy"
              )}
              isRounded
              isAlternative
            />
          </CftFormControl>
        )}
      </div>
    </>
  );
}

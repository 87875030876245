import axios from "axios";
import { o } from "ramda";

export function YouTubeGetID(_url) {
  let ID = "";
  const url = _url
    .replace(/(>|<)/gi, "")
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    // eslint-disable-next-line prefer-destructuring
    ID = ID[0];
  } else {
    ID = url;
  }
  return ID;
}

export async function getMediaUrlMeta(
  mediaURL,
  setThumbnailUrl,
  biggerSize = false
) {
  if (mediaURL.match(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)) {
    const v = YouTubeGetID(mediaURL);

    if (setThumbnailUrl) {
      await setThumbnailUrl(`https://img.youtube.com/vi/${v}/mqdefault.jpg`);
    }

    return {
      provider: "youtube",
      url: `https://img.youtube.com/vi/${v}/mqdefault.jpg`,
    };
  }

  if (mediaURL.includes("vimeo")) {
    const id = mediaURL.replace("https://vimeo.com/", "");
    const thumbnail_url = await axios
      .get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`)
      .then(({ data }) => {
        return data.thumbnail_url;
      })
      .catch(() => {
        return `https://i.vimeocdn.com/video/${id}_${
          biggerSize ? 1280 : 640
        }.jpg`;
      });
    if (setThumbnailUrl) {
      setThumbnailUrl(thumbnail_url);
    }

    return {
      provider: "vimeo",
      url: thumbnail_url,
    };
  }
  if (setThumbnailUrl) {
    setThumbnailUrl(null);
  }
  return {
    provider: "unknown",
    url: null,
  };
}

import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CftFormErrorFocus } from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import ManureManagemenType from "./ManureManagemenType";
import BeddingType from "./BeddingType";
import { dairyProductionAPI } from "../../../utils/cftApi";
import { mapValues } from "./manure.utils";

function ManureDPForm() {
  return (
    <Form className="cft-form narrow-labels">
      <ManureManagemenType />
      <CftFormErrorFocus />
      <BeddingType />
    </Form>
  );
}

export default ManureDPForm;

export function ManureDPFormmContext({ children }) {
  const history = useHistory();
  const {
    dairyProduction,
    saveDairyProduction,
    saveDairyProductionReport,
  } = useCft();
  const { t } = useTranslation(["cft", "cftPigs"]);

  return (
    <Formik
      enableReinitialize
      initialValues={dairyProduction.manure}
      validationSchema={Yup.object().shape({
        herd: Yup.array().of(
          Yup.object()
            .shape({
              herdSection: Yup.number().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              type: Yup.string().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              allocation: Yup.number()
                .transform((_, v) => Number(v.replace(/,/, ".")))
                .required(t("cft:validation.required", "Wpisz wartość"))
                .integer("Tylko wartości całkowite")
                .max(
                  100,
                  t(
                    "cftPigs:validationSchema.max",
                    "Wartość maksymalna to {{max}}.",
                    { max: 100 }
                  )
                )
                .typeError(
                  t(
                    "cft:validation.numberTypeError",
                    "Wpisz poprawną wartośc liczbową"
                  )
                )
                .min(
                  0,
                  t("cft:validation.numberMinError", "Wartość minimalna to 0")
                ),
            })
            .nullable()
        ),
        bedding: Yup.array().of(
          Yup.object()
            .shape({
              type: Yup.number().required(
                t("cft:validation.requiredOption", "Wybierz opcję")
              ),
              amount: Yup.object().shape({
                value: Yup.number()
                  .transform((_, v) => Number(v.replace(/,/, ".")))
                  .required(t("cft:validation.required", "Wpisz wartość"))
                  .typeError(
                    t(
                      "cft:validation.numberTypeError",
                      "Wpisz poprawną wartośc liczbową"
                    )
                  )
                  .min(
                    0,
                    t("cft:validation.numberMinError", "Wartość minimalna to 0")
                  ),
                unit: Yup.string().required(
                  t("cft:validation.unitTypeError", "Wybierz jednostkę")
                ),
              }),
            })
            .nullable()
        ),
      })}
      onSubmit={values => {
        const parsedValues = mapValues(values);

        Promise.all([
          dairyProductionAPI.manureValidation({ manure: parsedValues.manure }),
          dairyProductionAPI.beddingValidation({
            bedding: parsedValues.bedding,
          }),
        ]).then(([manure, bedding]) => {
          saveDairyProductionReport({
            ...manure.data,
            ...bedding.data,
          });
          saveDairyProduction({ manure: values });
          history.push(routes.DAIRY_PRODUCTION.ENERGY_AND_PROCESSING);
        });
      }}
    >
      {children}
    </Formik>
  );
}

import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

import withLayout from "../../services/hoc/withLayout";
import {
  CftBox,
  CftTooltip,
  CftButton,
  CftSelect,
  CftProgress,
  CftProgressCircle,
  CftQuestionGroup,
} from "../components";

function CFTComponentsPage() {
  const [groups, setGroups] = useState([0]);
  const [value, setValue] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setValue(value => {
        if (value >= 100) {
          clearInterval(interval);
          return value;
        }
        return value + 1;
      });
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <h5>CftInputs:</h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>CftInputs:</h5>
          <div>
            <div />
            <div>
              <CftTooltip content="Test" placement="right-start" />
            </div>

            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "23rem",
                }}
              />
            </div>
            <div>
              <CftSelect
                id="select-field-1"
                label="Long select field"
                options={[
                  { value: "a", label: "a" },
                  { value: "b", label: "b" },
                  { value: "c", label: "c" },
                ]}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "23rem",
              }}
            >
              <CftSelect
                id="select-field-2"
                label="Short select field"
                options={[
                  { value: "a", label: "a" },
                  { value: "b", label: "b" },
                  { value: "c", label: "c" },
                ]}
                isLong={false}
              />
              <CftSelect
                id="select-field-3"
                label="Short select field"
                options={[
                  { value: "a", label: "a" },
                  { value: "b", label: "b" },
                  { value: "c", label: "c" },
                ]}
                isLong={false}
              />
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <h5>CftBox:</h5>
          <CftBox>
            {({ isActiveState, isHoveredState }) => (
              <>
                {isActiveState ? "Jestem aktywny" : "Nie jestem aktywny"}
                {isHoveredState && " i trzymasz na mnie kursor"}
              </>
            )}
          </CftBox>
          <CftBox>Tylko tekst</CftBox>
        </Col>
        <Col xs={12}>
          <h5>Button:</h5>
          <CftButton>Text</CftButton>
          <CftButton variant="highlighted">highlighted</CftButton>
          <div
            style={{
              margin: "1rem 0",
              backgroundColor: "#00965e",
              padding: "1rem 0",
            }}
          >
            <CftButton variant="transparent">Transparent</CftButton>
          </div>
        </Col>
        <Col xs={12}>
          <h5>CftProgress</h5>
          <CftProgress value={25} />
          <br />
          <CftProgress value={55} color="#00965E" isHeader />
          <br />
          <CftProgress value={100} color="#00965E" withValueIndicator />
          <br />
          <CftProgress value={72} color="#0069FF" withValueIndicator />
          <br />
          <CftProgress value={51} color="#E92B5C" withValueIndicator />
          <br />
          <CftProgress value={29} color="#FFA203" withValueIndicator />
          <br />
          <CftProgress value={5} color="#666F85" withValueIndicator />
          <br />
          <CftProgress value={value} />
          <br />
          <CftProgress value={value} color="#00965E" isHeader />
          <br />
        </Col>
        <Col xs={12}>
          <h5>CftProgressCircle</h5>
          <CftProgressCircle value={15} color="#FFA203" />
          <br />
          <CftProgressCircle value={25} color="#0069FF" />
          <br />
          <CftProgressCircle value={51} color="#E92B5C" />
          <br />
          <CftProgressCircle value={value} />
          <br />
        </Col>
        <Col xs={12}>
          <h5>CftQuestionGroup</h5>
          <CftQuestionGroup label="Grupa statyczna">
            <div
              style={{
                maxWidth: "720px",
                margin: "0 auto",
                textAlign: "center",
              }}
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                luctus bibendum luctus. Ut eu diam quam. Donec eu justo porta
                nunc viverra egestas. Fusce porta pharetra lacinia.
              </p>
            </div>
          </CftQuestionGroup>
          <CftButton
            onClick={() => setGroups(values => [...values, `${values.length}`])}
          >
            Dodaj grupę dynamiczna
          </CftButton>
          {groups.map(value => (
            <CftQuestionGroup
              key={value}
              label={`Grupa dynamiczna ${value}`}
              onRemove={() =>
                setGroups(values => values.filter(v => v !== value))
              }
            >
              <div
                style={{
                  maxWidth: "720px",
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <h6>{`Grupa dynamiczna ${value}`}</h6>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  luctus bibendum luctus. Ut eu diam quam. Donec eu justo porta
                  nunc viverra egestas. Fusce porta pharetra lacinia.
                </p>
              </div>
            </CftQuestionGroup>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default withLayout(CFTComponentsPage);

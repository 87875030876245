import { TYPES } from "./properties.actions";

const getSucceedType = type => `${type}_SUCCESS`;
const getFailedType = type => `${type}_FAIL`;

export const InitialState = {
  search: {
    count: -1,
    next: null,
    previous: null,
    results: [],
    isFetching: false,
  },
  transactionTypes: {
    count: -1,
    next: null,
    previous: null,
    results: [],
    isFetching: false,
  },
  propertyTypes: {
    count: -1,
    next: null,
    previous: null,
    results: [],
    isFetching: false,
  },
  property: {
    id: -1,
    name: null,
    slug: null,
    province: -1,
    county: -1,
    borough: -1,
    town: -1,
    property_type: -1,
    transaction_type: -1,
    keeper: -1,
    promoted: false,
    revision_required: false,
    published_at: null,
    publish_status: -1,
    local_area: -1,
    land_area: -1,
    price: -1,
    offer_number: null,
    nr_kw: null,
    description: null,
    details: null,
    province_name: null,
    county_name: null,
    borough_name: null,
    town_name: null,
    thumbnail: null,
    thumbnail_link: null,
    keeper_data: {
      id: -1,
      first_name: null,
      last_name: null,
      email: null,
      telephone: null,
    },
    isFetching: false,
  },
  medias: {
    list: [],
    isFetching: false,
  },
  promoted: {
    list: [],
    isFetching: false,
  },
  suggested: {
    list: [],
    isFetching: false,
  },
  provinces: {
    count: -1,
    next: null,
    previous: null,
    results: [],
    isFetching: false,
  },
};

export default function properties(state = InitialState, action) {
  switch (action.type) {
    case TYPES.GET_PROPERTIES:
      return { ...state, search: { ...state.search, isFetching: true } };
    case getSucceedType(TYPES.GET_PROPERTIES):
      return {
        ...state,
        search: { ...action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROPERTIES):
      return {
        ...state,
        transactionTypes: { ...state.search, isFetching: false },
      };
    case TYPES.SET_PROPERTIES:
      return {
        ...state,
        search: { ...action.payload.data, isFetching: false },
      };
    case TYPES.GET_TRANSACTION_TYPES:
      return {
        ...state,
        transactionTypes: {
          ...state.transactionTypes,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_TRANSACTION_TYPES):
      return {
        ...state,
        transactionTypes: { ...action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_TRANSACTION_TYPES):
      return {
        ...state,
        transactionTypes: {
          ...state.transactionTypes,
          isFetching: false,
        },
      };
    case TYPES.GET_PROPERTY_TYPES:
      return {
        ...state,
        propertyTypes: {
          ...state.propertyTypes,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_PROPERTY_TYPES):
      return {
        ...state,
        propertyTypes: { ...action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROPERTY_TYPES):
      return {
        ...state,
        propertyTypes: { ...state.propertyTypes, isFetching: false },
      };
    case TYPES.GET_PROPERTY:
      return {
        ...state,
        property: {
          ...state.property,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_PROPERTY):
      return {
        ...state,
        property: { ...action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROPERTY):
      return {
        ...state,
        property: { ...state.property, isFetching: false },
      };
    case TYPES.GET_PROPERTY_MEDIAS:
      return {
        ...state,
        medias: {
          ...state.medias,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_PROPERTY_MEDIAS):
      return {
        ...state,
        medias: { list: action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROPERTY_MEDIAS):
      return {
        ...state,
        medias: { ...state.medias, isFetching: false },
      };
    case TYPES.GET_PROVINCE:
      return {
        ...state,
        provinces: {
          ...state.provinces,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_PROVINCE):
      return {
        ...state,
        provinces: { ...action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROPGET_PROVINCEERTY):
      return {
        ...state,
        provinces: { ...state.provinces, isFetching: false },
      };
    case TYPES.GET_PROMOTED_PROPERTIES:
      return {
        ...state,
        promoted: {
          ...state.promoted,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_PROMOTED_PROPERTIES):
      return {
        ...state,
        promoted: { list: action.payload.data, isFetching: false },
      };
    case getFailedType(TYPES.GET_PROMOTED_PROPERTIES):
      return {
        ...state,
        promoted: { ...state.promoted, isFetching: false },
      };
    case TYPES.SET_PROMOTED_PROPERTIES:
      return {
        ...state,
        promoted: { list: action.payload.data, isFetching: false },
      };
    case TYPES.GET_SUGGESTED_PROPERTIES:
      return {
        ...state,
        suggested: {
          ...state.suggested,
          isFetching: true,
        },
      };
    case getSucceedType(TYPES.GET_SUGGESTED_PROPERTIES):
      return {
        ...state,
        suggested: { list: action.payload.data.results, isFetching: false },
      };
    case getFailedType(TYPES.GET_SUGGESTED_PROPERTIES):
      return {
        ...state,
        suggested: { ...state.suggested, isFetching: false },
      };
    default:
      return state;
  }
}

import React from "react";
import "./RangeInput.scss";

const RangeInput = ({ value, range, id, ...rest }) => {
  return (
    <div className="range-input">
      <input
        className="range-input--input"
        type="range"
        min={range[0]}
        max={range[1]}
        value={value}
        id={id}
        {...rest}
      />
    </div>
  );
};

export default RangeInput;

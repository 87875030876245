import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { timespanTypeToString } from "../../../../services/helpers/calculator/dataHelpers";
import { isIncorrectCombination } from "../../../../services/helpers/calculator/validators";
import {
  TIMESTAMP_TYPES,
  INSTALLMENT_TYPES,
} from "../../../../services/helpers/constants";

import { Button, RadioButton, Tooltip } from "../../../common";

import "./CreditStep.scss";

const capitalInstallmentTypes = [
  {
    id: "capital-installment-frequency",
    type: "capitalInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.ONE_MONTH }),
    value: TIMESTAMP_TYPES.ONE_MONTH,
    i18i: 0,
  },
  {
    id: "capital-installment-frequency",
    type: "capitalInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.QUARTER }),
    value: TIMESTAMP_TYPES.QUARTER,
    i18i: 1,
  },
  {
    id: "capital-installment-frequency",
    type: "capitalInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.HALF_YEAR }),
    value: TIMESTAMP_TYPES.HALF_YEAR,
    i18i: 2,
  },
  {
    id: "capital-installment-frequency",
    type: "capitalInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.ONE_YEAR }),
    value: TIMESTAMP_TYPES.ONE_YEAR,
    i18i: 3,
  },
];

const interestInstallmentTypes = [
  {
    id: "interest-installment-frequency",
    type: "interestInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.ONE_MONTH }),
    value: TIMESTAMP_TYPES.ONE_MONTH,
    i18i: 0,
  },
  {
    id: "interest-installment-frequency",
    type: "interestInstallmentFrequency",
    label: timespanTypeToString({ type: TIMESTAMP_TYPES.QUARTER }),
    value: TIMESTAMP_TYPES.QUARTER,
    i18i: 1,
  },
];

const installmentTypes = [
  {
    id: "installment-type",
    type: "installmentType",
    label: "Malejące",
    value: INSTALLMENT_TYPES.DESCENDING,
    i18i: 0,
  },
  {
    id: "installment-type",
    type: "installmentType",
    label: "Równe",
    value: INSTALLMENT_TYPES.FIXED,
    i18i: 1,
  },
];

const rateTransitionValues = [1];

class CreditStep extends Component {
  renderRadioboxes({ id, type, value, i18i }, current, inputIndex) {
    const { handleInputChange, t } = this.props;
    // const labels = t('inputs')
    return (
      <RadioButton
        id={id}
        name={type}
        label={t(`inputs.${inputIndex}.values.${i18i}`)}
        value={value}
        checked={current === value}
        onChange={event => handleInputChange(event.target.value, type)}
      />
    );
  }

  render() {
    const {
      creditRate,
      installmentType,
      capitalInstallmentFrequency,
      interestInstallmentFrequency,
      handleStepCompletionChange,
      creditStepCompleted,
      handleCalculatingCreditOffer,
      isInEditMode,
      hasError,
      t,
    } = this.props;

    const hasIncorrectCombination = isIncorrectCombination({
      params: {
        capitalInstallmentFrequency,
        interestInstallmentFrequency,
        installmentType,
      },
    });
    const hasIncorrectCreditRate = rateTransitionValues.includes(
      Number(creditRate)
    );
    return (
      <>
        <div className="credit-container">
          <p className="computed-value-description">
            {t("inputs.10.label")}
            <Tooltip content={t("inputs.10.tooltip")} />
          </p>
          <div className="radio-fields-container">
            {capitalInstallmentTypes.map((type, key) => (
              <Fragment key={key}>
                {this.renderRadioboxes(type, capitalInstallmentFrequency, 10)}
              </Fragment>
            ))}
          </div>
          <p className="computed-value-description">
            {t("inputs.11.label")}
            <Tooltip content={t("inputs.11.tooltip")} />
          </p>
          <div className="radio-fields-container">
            {interestInstallmentTypes.map((type, key) => (
              <Fragment key={key}>
                {this.renderRadioboxes(type, interestInstallmentFrequency, 11)}
              </Fragment>
            ))}
          </div>
          <p className="computed-value-description">
            {t("inputs.12.label")}
            <Tooltip content={t("inputs.12.tooltip")} />
          </p>
          <div className="radio-fields-container">
            {installmentTypes.map((type, key) => (
              <Fragment key={key}>
                {this.renderRadioboxes(type, installmentType, 12)}
              </Fragment>
            ))}
          </div>
          {hasIncorrectCombination && (
            <p className="error-message">
              <span className="error-message--pink">
                {t("secondStep.warningLabel")}:&nbsp;
              </span>
              {t(hasIncorrectCombination)}
            </p>
          )}
          {hasIncorrectCreditRate && (
            <p className="error-message">{t("secondStep.warnings.0")}</p>
          )}
          <div className="back-btn">
            <Button
              buttoncolor="yellow"
              buttonsize="small"
              onClick={() =>
                handleStepCompletionChange(
                  "investmentStepCompleted",
                  false,
                  true
                )
              }
            >
              {t("globals:buttons.changeData")}
            </Button>
          </div>
        </div>
        {!creditStepCompleted && (
          <>
            <div className="next-step-button-container">
              <Button
                buttondisabled={
                  hasError || hasIncorrectCombination || hasIncorrectCreditRate
                    ? "disabled"
                    : ""
                }
                buttonsize="big"
                buttoncolor="yellow"
                onClick={() => {
                  handleStepCompletionChange("creditStepCompleted", true);
                  handleCalculatingCreditOffer();
                }}
              >
                {isInEditMode
                  ? t("globals:buttons.update")
                  : t("secondStep.submit")}
              </Button>
            </div>
          </>
        )}
      </>
    );
  }
}

CreditStep.propTypes = {
  handleStepCompletionChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleCalculatingCreditOffer: PropTypes.func.isRequired,
  creditStepCompleted: PropTypes.bool,
  creditRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  installmentType: PropTypes.string,
  capitalInstallmentFrequency: PropTypes.string,
  interestInstallmentFrequency: PropTypes.string,
  hasError: PropTypes.bool,
  isInEditMode: PropTypes.bool,
};

CreditStep.defaultProps = {
  creditStepCompleted: false,
  isInEditMode: false,
  hasError: false,
  creditRate: 0,
  installmentType: "",
  capitalInstallmentFrequency: "",
  interestInstallmentFrequency: "",
};

export default withTranslation(["calculatorPage", "globals"])(CreditStep);

import React, { Component } from "react";
import Cookies from "js-cookie";
import { connect } from "react-redux";
import { compose } from "recompose";
import { osName, isMobile, isMobileSafari } from "react-device-detect";
import { Metadata, AlertWarning, AlertPWA } from "../../components/common";

const isInStandaloneMode = () =>
  "standalone" in window.navigator && window.navigator.standalone;

const withLayout = WrappedComponent => {
  return class Layout extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentWidth: "",
        location: window.location.pathname,
        token: Cookies.get("user_token"),
      };

      this.updateWidthView = this.updateWidthView.bind(this);
    }

    componentDidMount() {
      this.setState({
        currentWidth: window.outerWidth,
      });

      window.addEventListener("resize", this.updateWidthView);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWidthView);
    }

    updateWidthView() {
      this.setState({
        currentWidth: window.outerWidth,
      });
    }

    renderMetaData() {
      const { location } = this.state;
      if (!location.includes("/articles/") || !location.includes("/events/")) {
        return (
          <Metadata description="Agronomist to kompleksowy i nowoczesny portal stworzony z udziałem rolników i przetwórców. Wyznaczaj trendy i postaw na rozwój!" />
        );
      }
      return null;
    }

    renderAlerts() {
      const { alertUserData } = this.props;
      const token = Cookies.get("user_token");
      return token &&
        alertUserData.user.id &&
        !alertUserData.user.is_email_confirmed ? (
        // eslint-disable-next-line react/jsx-indent
        <AlertWarning
          heading="user:activation.heading"
          text="user:activation.txt"
        />
      ) : null;
    }

    // eslint-disable-next-line class-methods-use-this
    renderPWAInfo() {
      const showed = Cookies.get("pwa_info");
      if (
        !isInStandaloneMode() &&
        (isMobileSafari || (osName === "os" && isMobile)) &&
        !showed
      ) {
        return (
          <AlertPWA
            callback={() => {
              return Cookies.set("pwa_info", true, {
                expires: 7,
                secure: true,
              });
            }}
          />
        );
      }
      return <></>;
    }

    render() {
      const { currentWidth } = this.state;
      const { alertUserData, ...rest } = this.props;
      return (
        <>
          {this.renderMetaData()}
          {this.renderAlerts()}
          {this.renderPWAInfo()}
          <WrappedComponent currentWidth={currentWidth} {...rest} />
        </>
      );
    }
  };
};

const mapStateToProps = state => {
  return {
    alertUserData: state.user,
  };
};

export default compose(
  connect(mapStateToProps),
  // withTranslation(),
  withLayout
);

// export default withLayout;

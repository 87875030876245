import Cookies from "js-cookie";
import API from "../../services/apiHelpers";
import { DEFAULT_POSITION } from "../forms/RegagriLocation";

const getAuthToken = () => {
  const token = Cookies.get("user_token");

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const regagriApi = {
  cultivationDetails: data =>
    API().get("/api/v1/carbon-inputs/", { ...getAuthToken(), data }),
  report: data =>
    API().post("/api/v1/carbon-calculation/", data, { ...getAuthToken() }),
  carbonClimate: data =>
    API().post("/api/v1/carbon-climate/", data, { ...getAuthToken() }),
  carbonSoilRange: data =>
    API().post("api/v1/carbon-soil-range/", data, { ...getAuthToken() }),
};

export default regagriApi;

export const submitMapper = ({ modalInput, ...values }) => {
  const practicesData = [];
  let geo = [...values.geo];
  if (geo.length === 0) {
    geo = DEFAULT_POSITION;
  }

  values.practices.forEach(({ practices }) => {
    practices.forEach(child => {
      practicesData.push({
        id: child.id,
        area: child.value,
      });
    });
  });

  return {
    hectares: values.farmSize,
    lat: geo[0],
    lon: geo[1],
    certification_price: values.price,
    ...(practicesData.length > 0 ? { practices: practicesData } : {}),
    soil_types: values.soil,
    soil_organic_matter: values.organicMatter,
    synthetic_n_fertiliser_dose: values.quantity,
    synthetic_n_fertiliser_area: values.applicationArea,
  };
};

export const reportDataMapper = (prev, values, data) => {
  return {
    details: {
      name: values.name,
      size: values.farmSize,
      soilType: values.soil.map(el => ({
        ...el,
        name: values.soil.find(f => f.id === el.id).name,
      })),
    },
    explorer: {
      reductionPotential: {
        value: data.total_reduction,
        reduction: data.total_emissions_reduction,
        removals: data.total_removals,
      },
      income: {
        reduction: data.certification_income,
      },
      climate: {
        length: prev.explorer.climate.length,
        temp: prev.explorer.climate.temp,
      },
    },
    potential: values.practices,
    chart: data.time_series,
  };
};

export const errorDataMapper = data => {
  const practices =
    data.practices &&
    data.practices.map(el => {
      if (typeof el === "object" && "area" in el) {
        return el.area;
      }
      return el;
    });
  return {
    lat: data.lat,
    long: data.long,
    farmSize: data.hectares,
    price: data.certification_price,
    soil: data.soil_types,
    quantity: data.synthetic_n_fertiliser_dose,
    applicationArea: data.synthetic_n_fertiliser_area,
    organicMatter: data.soil_organic_matter,
    practices,
  };
};

export const carbonInputsLangMapper = (t, data) => {
  let translatedPractices = [];
  let translatedSoilTypes = [];
  if (t) {
    translatedPractices = data.practices.map(el => {
      return {
        ...el,
        name: t(`cultivationDetails.form.practices.names.${el.id}.name`),
        practices: el.practices.map(practice => {
          return {
            ...practice,
            name: t(
              `cultivationDetails.form.practices.names.${el.id}.practices.${
                practice.id
              }.name`
            ),
          };
        }),
      };
    });
    translatedSoilTypes = data.soil_types.map(el => {
      return {
        ...el,
        name: t(`cultivationDetails.form.soilTypes.names.${el.id}`),
      };
    });
  }

  return {
    soil_types: t ? translatedSoilTypes : data.soil_types,
    practices: t ? translatedPractices : data.practices,
  };
};

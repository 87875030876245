import React, { useRef, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { formatDate } from "../../../averagePrices/date";
import { ButtonFix } from "../../Button/Buttonfix";
import "./MarketWidget.scss";
import Table, { createTableRow } from "./Table";

const MarketWidget = props => {
  const refWidget = useRef(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === "#market" && !!refWidget) {
      refWidget.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [refWidget]);

  return <div ref={refWidget} className="MarketWidget" {...props} />;
};

const PeriodSection = ({ startWeek, endWeek }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(["averagePrices"]);
  return (
    <div className="time-period">
      {formatDate(startWeek, language)} - {formatDate(endWeek, language)}
    </div>
  );
};

const Header = ({ children }) => {
  return <div className="header">{children}</div>;
};

const HeaderTitle = props => <p className="title" {...props} />;

const Badge = props => <div className="whole-country" {...props} />;

const Footer = ({ linkTo, isUserLogged, onLoginClick }) => {
  const { t } = useTranslation(["averagePrices"]);

  return (
    <div className="footer">
      {isUserLogged ? (
        <Link to={linkTo} style={{ width: "100%" }}>
          <ButtonFix
            buttonsize="big"
            buttoncolor="yellow"
            style={{ width: "100%", fontWeight: 300 }}
          >
            {t("averagePrices:widget.customize")}
          </ButtonFix>
        </Link>
      ) : (
        <ButtonFix
          buttonsize="big"
          buttoncolor="yellow"
          onClick={onLoginClick}
          style={{ width: "100%", fontWeight: 300 }}
        >
          {t("averagePrices:widget.customize")}
        </ButtonFix>
      )}
    </div>
  );
};

const Customization = ({ linkTo, isUserLogged }) => {
  const { t, i18n } = useTranslation(["averagePrices"]);
  const loginPath =
    i18n.language === "pl" ? "/uzytkownik/login" : "/user/login";

  return (
    <Link
      to={isUserLogged ? linkTo : loginPath}
      className="show-more"
      style={{ width: "100%" }}
    >
      {t("averagePrices:widget.customize")}
    </Link>
  );
};

MarketWidget.Header = Header;
MarketWidget.HeaderTitle = HeaderTitle;
MarketWidget.Badge = Badge;
MarketWidget.PeriodSection = PeriodSection;
MarketWidget.Table = Table;
MarketWidget.Footer = Footer;
MarketWidget.Customization = Customization;

export default MarketWidget;
export { createTableRow };

/* eslint-disable func-names */
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import {
  CftTank,
  CftLabel,
  CftFormControl,
  CftTextField,
  CftButton,
  CftRadioField,
  CftErrorBoxForm,
  CftClearForm,
  CftFormErrorFocus,
} from "../../components";
import routes from "../../utils/cftRoutes";
import API from "../../../services/apiHelpers";

import EnergyCarriersFields from "./EnergyCarriersFields";
import TransportFields from "./TransportFields";
import useCftChickenBroilerValues from "../../utils/useCftChickenBroilerValues";
import useCftAeOptions from "../../utils/useCftAeOptions";

export default function ChickenBroilerEmissionsForm() {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["cftChickenBroiler"]);
  const history = useHistory();
  const { store, updateStore } = useCftChickenBroilerValues();
  const { getVehicleName } = useCftAeOptions();

  return (
    <div className="progress-dashboard cft-wrapper narrow-labels">
      <CftTank
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2 className="cft-section-title mb-3">
          {t(
            "cftChickenBroiler:chickenBroilerEmissionsForm.title",
            "Informacje o ściółce"
          )}
        </h2>
        <h4 className="cft-section-subtitle mb-7 cft-wrapper-cb">
          {t(
            "cftChickenBroiler:chickenBroilerEmissionsForm.title",
            "Podaj informacje o rodzaju i ilości stosowanej ściółki"
          )}
        </h4>
        <Formik
          initialValues={store.emission}
          validationSchema={Yup.object().shape({
            bedding: Yup.object().shape({
              bedding_period: Yup.string().required(
                t(
                  "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
              bedding_type: Yup.string().required(
                t(
                  "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                  "Pole wymagane"
                )
              ),
              quantity: Yup.number()
                .min(
                  1,
                  t(
                    "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                    "Wartość minimalna to 1."
                  )
                )
                .max(
                  2147483647,
                  t(
                    "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                    "Wartość maksymalna to 2147483647."
                  )
                )
                .required(
                  t(
                    "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                ),
            }),
            energy_carriers: Yup.array().of(
              Yup.object().shape({
                carrier_type: Yup.string().required(
                  t(
                    "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                ),
                value: Yup.number()
                  .min(
                    1,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin",
                      "Wartość minimalna to 1."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .required(
                    t(
                      "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                      "Pole wymagane"
                    )
                  ),
                unit: Yup.string().required(
                  t(
                    "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                ),
              })
            ),
            transport: Yup.array().of(
              Yup.object().shape({
                vehicle_type: Yup.string().required(
                  t(
                    "cftChickenBroiler:chickenBroilerMainForm.fields.errorRequired",
                    "Pole wymagane"
                  )
                ),
                distance: Yup.number()
                  .min(
                    0,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin0",
                      "Wartość minimalna to 0."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cftChickenBroiler:transportFields.error.distance"),
                    function() {
                      const [field] = this.from;

                      const isDiesel = getVehicleName(
                        field.value.vehicle_type,
                        t
                      ).includes("(diesel)");

                      if (isDiesel) {
                        return (
                          !(
                            Number(field.value.distance) === 0 &&
                            Number(field.value.diesel) === 0
                          ) ||
                          (field.value.distance === "" &&
                            field.value.diesel === "") ||
                          (Number(field.value.distance) === 0 &&
                            field.value.diesel === "") ||
                          (field.value.distance === "" &&
                            Number(field.value.diesel) === 0)
                        );
                      }

                      return (
                        !(
                          Number(field.value.distance) === 0 &&
                          Number(field.value.gasoline) === 0
                        ) ||
                        (field.value.distance === "" &&
                          field.value.gasoline === "") ||
                        (Number(field.value.distance) === 0 &&
                          field.value.gasoline === "") ||
                        (field.value.distance === "" &&
                          Number(field.value.gasoline) === 0)
                      );
                    }
                  ),
                diesel: Yup.number()
                  .min(
                    0,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin0",
                      "Wartość minimalna to 0."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cftChickenBroiler:transportFields.error.distance"),
                    function() {
                      const [field] = this.from;

                      const isDiesel = getVehicleName(
                        field.value.vehicle_type,
                        t
                      ).includes("(diesel)");

                      if (isDiesel) {
                        return (
                          !(
                            Number(field.value.distance) === 0 &&
                            Number(field.value.diesel) === 0
                          ) ||
                          (field.value.distance === "" &&
                            field.value.diesel === "") ||
                          (Number(field.value.distance) === 0 &&
                            field.value.diesel === "") ||
                          (field.value.distance === "" &&
                            Number(field.value.diesel) === 0)
                        );
                      }

                      return true;
                    }
                  ),
                gasoline: Yup.number()
                  .min(
                    0,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMin0",
                      "Wartość minimalna to 0."
                    )
                  )
                  .max(
                    2147483647,
                    t(
                      "cftChickenBroiler:chickenBroilerCoopForm.fields.production_cycle_lengthErrorMax2147483647",
                      "Wartość maksymalna to 2147483647."
                    )
                  )
                  .test(
                    "is-right-calculationPeriodLength",
                    t("cftChickenBroiler:transportFields.error.distance"),
                    function() {
                      const [field] = this.from;

                      const isDiesel = getVehicleName(
                        field.value.vehicle_type,
                        t
                      ).includes("(diesel)");

                      if (isDiesel) {
                        return true;
                      }

                      return (
                        !(
                          Number(field.value.distance) === 0 &&
                          Number(field.value.gasoline) === 0
                        ) ||
                        (field.value.distance === "" &&
                          field.value.gasoline === "") ||
                        (Number(field.value.distance) === 0 &&
                          field.value.gasoline === "") ||
                        (field.value.distance === "" &&
                          Number(field.value.gasoline) === 0)
                      );
                    }
                  ),
              })
            ),
          })}
          onSubmit={async (
            { bedding, energy_carriers, transport },
            helpers
          ) => {
            try {
              await API().post(
                "/api/v1/ae/chicken/emission-validation/",
                {
                  bedding,
                  transport,
                  energy_carriers: energy_carriers.map(
                    ({ carrier_type, value, unit }) => ({
                      carrier_type,
                      quantity: value,
                      unit_id: unit,
                    })
                  ),
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              updateStore({
                emission: {
                  bedding,
                  energy_carriers,
                  transport,
                  isValidate: true,
                },
              });
              history.push(routes.CHICKEN_BROILER.REPORT);
            } catch (error) {
              helpers.setErrors(error.response.data);
            }
          }}
        >
          <Form width="100%">
            <div className="cft-paper mb-6">
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.bedding_periodLabel",
                    "Podaj zużycie ściółki na"
                  )}
                />
                <CftRadioField
                  variant="dots"
                  name="bedding.bedding_period"
                  options={[
                    {
                      value: "2",
                      label: t(
                        "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.bedding_period2",
                        "Okres obliczeniowy (365 dni)"
                      ),
                    },
                    {
                      value: "1",
                      label: t(
                        "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.bedding_period1",
                        "Cykl produkcyjny"
                      ),
                    },
                  ]}
                />
              </CftFormControl>
              <CftFormControl alignLeft>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.bedding_typeLabel",
                    "Rodzaj stosowanej ściółki"
                  )}
                />
                <CftRadioField
                  variant="box"
                  name="bedding.bedding_type"
                  options={[
                    {
                      value: "1",
                      label: t(
                        "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.1",
                        "Sciółka pszenna"
                      ),
                    },
                    {
                      value: "2",
                      label: t(
                        "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.2",
                        "Trociny drzewne"
                      ),
                    },
                  ]}
                />
              </CftFormControl>
              <CftFormControl alignLeft withoutMarginBottom>
                <CftLabel
                  isFullWidth
                  label={t(
                    "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.quantityLabel",
                    "Masa zużytej ściółki (t)"
                  )}
                />
                <CftTextField
                  isRounded
                  name="bedding.quantity"
                  placeholder={t(
                    "cftChickenBroiler:chickenBroilerEmissionsForm.fields.bedding.quantityPlaceholder",
                    "Wpisz ilość"
                  )}
                  isAlternative
                  mask="000000000"
                />
              </CftFormControl>
            </div>
            <EnergyCarriersFields />
            <TransportFields />
            <CftTank
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftErrorBoxForm
                text={t(
                  "cftChickenBroiler:form.error.main",
                  "Wypełnij brakujące pola żeby przejść dalej"
                )}
              />
              <CftButton
                variant="active"
                style={{ marginTop: "2rem" }}
                isRounded
                isAlternative
                type="submit"
              >
                {t(
                  "cftChickenBroiler:chickenBroilerEmissionsForm.fields.submit",
                  "generuj raport"
                )}
              </CftButton>
            </CftTank>
            <CftFormErrorFocus />
          </Form>
        </Formik>
      </CftTank>
      <CftClearForm type="chicken" />
    </div>
  );
}

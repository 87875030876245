import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";

const options = {
  loadPath: "/static/locales/{{lng}}/{{ns}}.json",
  addPath: "/static/locales/add/{{lng}}/{{ns}}",
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: [],
    lng: Cookies.get("user_lang")
      ? Cookies.get("user_lang").toLowerCase()
      : "pl",
    backend: options,
    fallbackLng: "pl",
    // debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

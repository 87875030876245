import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./CftProgress.scss";

function CftProgress({
  color,
  value,
  isHeader,
  withValueIndicator,
  indicatorValue,
}) {
  return (
    <div
      className={classnames("cft-progress", {
        "cft-progress--isHeader": isHeader,
        "cft-progress--withValueIndicator": withValueIndicator,
      })}
    >
      <div className="cft-progress__bar">
        <span
          className="cft-progress__bar__fill"
          style={{
            backgroundColor: color,
            width: withValueIndicator ? `calc(${value}% - 40px)` : `${value}%`,
          }}
        />
        {withValueIndicator && (
          <span
            className="cft-progress__value"
            style={{
              color,
              left: `calc(${value}% - 35px)`,
            }}
          >
            {Math.round(indicatorValue || value)}%
          </span>
        )}
      </div>
    </div>
  );
}

CftProgress.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string,
  isHeader: PropTypes.bool,
  withValueIndicator: PropTypes.bool,
  indicatorValue: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  onClick: PropTypes.func,
};

CftProgress.defaultProps = {
  onClick: null,
  isHeader: false,
  color: "#ffa203",
  withValueIndicator: false,
  indicatorValue: null,
};

export default CftProgress;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftActionsButtons,
  CftReportProgress,
  CftExportReport,
  CftDairyProductionRestricted,
} from "../../components";
import routes from "../../utils/cftRoutes";
import { useCft } from "../../utils/CftProvider";
import CattleGrazingDPForm, {
  CattleGrazingDPFormContext,
} from "../../forms/dairyProduction/CattleGrazingDPForm/CattleGrazingDP.form";

function CattleGrazingPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { saveDairyProduction } = useCft();
  const { submitForm, values, isSubmitting } = useFormikContext();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={28}
          cropName={t("cft:types.dairyProduction", "Produkcja mleczarska")}
          actions={
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveDairyProduction({
                    cattleGrazing: values,
                  });
                  history.push(routes.DAIRY_PRODUCTION.MAIN);
                }}
              />
              <CftExportReport type="dairyProduction" />
            </div>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:cattleGrazing.title", "Wypas bydła")}
          onBack={() => history.push(routes.DAIRY_PRODUCTION.CHARACTERISTICS)}
          onNext={submitForm}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:cattleGrazing.title", "Wypas bydła")}</h3>
            <p>
              {t(
                "cft:cattleGrazing.subtitle",
                "Proszę podać informacje o całkowitej liczbie dni i średniej liczby godzin pastwiskowania dziennie w okresie wypasu oraz wybrać rodzaj wypasu i jakość trawy."
              )}
            </p>
          </>
        }
      >
        <CftDairyProductionRestricted>
          <CattleGrazingDPForm />
        </CftDairyProductionRestricted>
        <CftReportProgress type="dairyProduction" />
      </CftSection>
    </CftLayout>
  );
}

export default function() {
  return (
    <CattleGrazingDPFormContext>
      <CattleGrazingPage />
    </CattleGrazingDPFormContext>
  );
}

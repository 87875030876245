import React from "react";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  getMainCategory,
} from "../../../services/helpers/articles";
import Image from "../Image/Image";
import { AgroCategoriesEl, ButtonFix, CategoryLabel } from "../index";
import "./BigArticleCard.scss";

const truncate = (str = "", maxLength = 175) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }

  return str;
};

export const BigCardArticle = ({ article, category }) => {
  const { t, i18n } = useTranslation(["articlesMeta", "globals"]);
  const articlesPath = i18n.language === "pl" ? "/artykuly/" : "/articles/";
  const agroArticlesPath =
    i18n.language === "pl" ? "/agroraport/artykuly/" : "/agroraport/article/";
  const slug = article.article_type > 10 ? agroArticlesPath : articlesPath;

  const categoryID = category || getMainCategory(article.categories);
  return (
    <div className="c-article-card--big">
      <div className="c-article-card__content">
        <div className="c-article-card__header">
          <div className="u-font-v-middle c-article__date">
            {formatDate(article.published_at)}
          </div>
          <div className="u-header--inline c-article__category">
            {article.article_type > 10 ? (
              <AgroCategoriesEl id={categoryID} color="#F06592" type="small" />
            ) : (
              <CategoryLabel id={categoryID}>
                <span>#{t(`articlesMeta:categories.${categoryID}`)}</span>
              </CategoryLabel>
            )}
          </div>
        </div>

        <h2 className="c-heading--tertiary">{truncate(article.name, 64)}</h2>
        <div className="c-article-card-html">
          {truncate(article.summary, 175)}
        </div>
        <ButtonFix
          link={`${slug}${article.slug}`}
          buttonsize="higher"
          buttoncolor="green"
          absolute
        >
          {t("globals:buttons.readMore")}
        </ButtonFix>
      </div>
      <a
        href={`${slug}${article.slug}${article.video_url ? "?video" : ""}`}
        className={
          "c-article-card__img" +
          `${article.video_url ? " c-video c-video__overlay" : ""}`
        }
      >
        <Image
          src={
            article.carousel_photo
              ? article.carousel_photo.file
              : article.thumb !== null
              ? article.thumb.file
              : ""
          }
          alt={article.name}
        />
        {article.thumb === null && article.carousel_photo === null ? (
          <div className="img-temp img-temp--absolute" />
        ) : null}
      </a>
    </div>
  );
};

import React, { useState } from "react";
import {
  ContactFormExtended,
  ContactFormSuccess,
} from "agronomist-ui-components";
import { useLocation } from "react-router";
import queryString from "query-string";
import { ReactComponent as AllegroFormIcon } from "../assets/icons/allegro-form.svg";

import { onAllegroContactFormSubmit } from "../helpers/utils";
import PageTemplate from "../helpers/PageTemplate";

const AllegroContactForm = () => {
  const [isSend, setSend] = useState(false);
  const { search } = useLocation();

  const { product_url, product_name } = queryString.parse(search);

  return (
    <PageTemplate>
      {isSend ? (
        <ContactFormSuccess
          link="/articles/jeden-kredyt-wiele-mozliwosci"
          isWithRating={false}
        />
      ) : (
        <ContactFormExtended
          linkCtaBtn="/kredytomat/"
          teserImg={
            <div style={{ marginBottom: "44px" }}>
              <AllegroFormIcon style={{ maxWidth: "100%" }} />
            </div>
          }
          onSubmit={(values, formikHelpers) => {
            return onAllegroContactFormSubmit(
              { ...values, product_url, product_name },
              formikHelpers
            ).finally(() => {
              setSend(true);
            });
          }}
        />
      )}
    </PageTemplate>
  );
};

export default AllegroContactForm;

import React, { useEffect, useState } from "react";
import { isEmpty } from "ramda";
import { useTranslation } from "react-i18next";
import { Col, Row, Spinner } from "reactstrap";
import { useCft } from "../../../utils/CftProvider";
import { farmlandsAPI } from "../../../utils/cftApi";
import { nFormatter } from "../../../utils/cftUtils";
import {
  CftCard,
  CftFormControl,
  CftGraph,
  CftGroup,
  CftMobileReportWrapper,
  CftReport,
  CftTank,
} from "../../../components";
import { WaterFootprintNav } from "../../../components/CftReportNavigation/CftReportNavigation";

const WaterFootprintGraph = ({ type }) => {
  const [alternativeGraphData, setAlternativeGraphData] = useState([]);
  const { t, i18n } = useTranslation(["cft"]);
  const { waterFootprintReport } = useCft();
  const { bothCalcReport } = useCft();

  const [isPending, setPending] = useState(false);
  const [report, setReport] = useState(null);
  const [graphData, setGraphData] = useState([]);

  let reportData = bothCalcReport;
  if (type === "waterFootprint") {
    reportData = waterFootprintReport;
  }

  const isValid =
    !isEmpty(reportData.details) &&
    !isEmpty(reportData.soil) &&
    !isEmpty(reportData.irigation);

  const getFactor = scale => {
    const x = scale.max === 0 || scale.min === 0 ? 280 : 150;
    const value =
      Math.abs(scale.min) > Math.abs(scale.max)
        ? Math.abs(scale.min)
        : Math.abs(scale.max);

    return x / value;
  };

  const getAlternativeGraphLabel = index => {
    switch (index) {
      case 0:
        return {
          text: t("cft:reportWaterFootprint.summary.waterRequirement"),
          color: "#00965E",
        };
      case 1:
        return {
          text: t("cft:reportWaterFootprint.summary.waterAdded"),
          color: "#FFA203",
        };
      case 2:
        return {
          text: t("cft:reportWaterFootprint.summary.waterRunoff"),
          color: "#0069FF",
        };
      case 3:
        return {
          text: t("cft:reportWaterFootprint.summary.irrigationBalance"),
          color: "#E92B5C",
        };
      case 4:
        return {
          text: t("cft:reportWaterFootprint.summary.interceptionLosses"),
          color: "#666F85",
        };
      default:
        return {
          text: "",
          color: "",
        };
    }
  };

  useEffect(() => {
    if (isValid) {
      setPending(true);
      farmlandsAPI
        .reportWaterFootprint(reportData)
        .then(({ data }) => {
          setReport(data);
          const {
            summary: { water_balance },
          } = data;
          const balanceData = [
            {
              rawValue: water_balance.water_requirement.total_value,
            },
            {
              rawValue:
                water_balance.water_added.rainfall.total_value +
                water_balance.water_added.irrigation.total_value,
            },
            {
              rawValue:
                water_balance.irrigation_runoff +
                water_balance.irrigation_percolation +
                water_balance.rainfall_runoff +
                water_balance.rainfall_runoff,
            },
            {
              rawValue: water_balance.irrigation_balance,
            },
            {
              rawValue: water_balance.interception_losses,
            },
          ];
          const scaleAlternative = balanceData
            .map(el => el.rawValue)
            .reduce(
              (prev, curr) => {
                const min = prev.min < curr ? prev.min : curr;
                const max = prev.max > curr ? prev.max : curr;

                return {
                  min,
                  max,
                };
              },
              {
                min: 0,
                max: 0,
              }
            );

          const alternativeGraphData = balanceData.map((el, i) => ({
            factor: getFactor(scaleAlternative),
            value: el.rawValue * getFactor(scaleAlternative),
            rawValue: el.rawValue,
            label: getAlternativeGraphLabel(i).text,
            color: getAlternativeGraphLabel(i).color,
            legend: nFormatter(el.rawValue, i18n.language),
            scale: scaleAlternative,
          }));

          setAlternativeGraphData(alternativeGraphData);

          const scale = water_balance.water_added.irrigation.weekly.reduce(
            (prev, curr) => {
              const min = prev.min < curr ? prev.min : curr;
              const max = prev.max > curr ? prev.max : curr;

              return {
                min,
                max,
              };
            },
            {
              min: 0,
              max: 0,
            }
          );
          const graphData = [];
          water_balance.water_added.irrigation.weekly.forEach((week, i) => {
            graphData.push({
              factor: getFactor(scale),
              value: week * getFactor(scale),
              rawValue: week,
              label: i + 1,
              legend: nFormatter(week, i18n.language),
              scale,
            });
          });
          setGraphData(graphData);
        })
        .finally(() => {
          setPending(false);
        });
    }
  }, []);

  return (
    <>
      {!isValid && (
        <CftFormControl variant="small">
          <h3 className="cft-headline">
            {t("cft:reportWaterFootprint.missingData", "Brak danych")}

            <small>
              {t(
                "cft:reportWaterFootprint.requirements",
                "Do wygenerowania raportu wymagane są następujące dane: Szczegóły uprawy oraz Charakterystyka gleby"
              )}
            </small>
          </h3>
        </CftFormControl>
      )}
      {isValid && isPending && (
        <CftTank justifyContent="center" mb="2rem">
          <Spinner color="success" />
        </CftTank>
      )}
      {report && (
        <div id="report">
          <CftGroup header={<WaterFootprintNav />} id="waterFootprint">
            <CftCard>
              <Row style={{ marginBottom: "100px" }}>
                <Col xs={12} md={4} lg={3}>
                  <CftReport.Box paddingRight={0}>
                    <CftReport.Summary
                      data={[
                        {
                          value: nFormatter(
                            report.summary.water_productivity.value,
                            i18n.language
                          ),
                          unit: report.summary.water_productivity.unit,
                          title: t(
                            "cft:reportWaterFootprint.summary.waterProductivity"
                          ),
                        },
                        {
                          value: nFormatter(
                            report.summary.water_footprint.value,
                            i18n.language
                          ),
                          unit: report.summary.water_footprint.unit,
                          title: t(
                            "cft:reportWaterFootprint.summary.waterFootprint"
                          ),
                        },
                        {
                          value: nFormatter(
                            report.summary.irrigation_efficiency.value,
                            i18n.language
                          ),
                          unit: report.summary.irrigation_efficiency.unit,
                          title: t(
                            "cft:reportWaterFootprint.summary.irrigationEfficiency",
                            "Emisje na produkt"
                          ),
                        },
                      ]}
                    />
                  </CftReport.Box>
                </Col>
                <Col xs={12} md={8} lg={9}>
                  <div
                    style={{
                      width: "94%",
                      marginLeft: "auto",
                      paddingRight: "25px",
                      paddingTop: "40px",
                      display: "flex",
                    }}
                  >
                    <h4
                      className="cft-report__summary__header"
                      style={{ width: "6%" }}
                    >
                      <span
                        style={{
                          transform: "rotate(-90deg)",
                          display: "inline-block",
                          position: "absolute",
                          top: "60%",
                          left: "40px",
                        }}
                      >
                        {report.summary.water_balance.unit}
                      </span>
                    </h4>
                    <div style={{ width: "100%" }}>
                      <CftReport.Title>
                        {t("cft:reportWaterFootprint.summary.waterBalance")}
                      </CftReport.Title>
                      <CftMobileReportWrapper>
                        <CftGraph data={alternativeGraphData} alternativeView />
                      </CftMobileReportWrapper>
                    </div>
                  </div>
                </Col>
              </Row>
              <CftReport.Box paddingTop={0} paddingBottom={0}>
                <CftReport.Divider />
                <CftReport.Title>
                  {t("cft:reportWaterFootprint.summary.irrigation")}
                </CftReport.Title>
              </CftReport.Box>
              <CftReport.Box>
                <Row>
                  <h4
                    className="cft-report__summary__header"
                    style={{ width: "6%", margin: "auto" }}
                  >
                    <span
                      style={{
                        transform: "rotate(-90deg)",
                        display: "inline-block",
                      }}
                    >
                      {report.summary.water_balance.unit}
                    </span>
                  </h4>
                  <div style={{ width: "94%" }}>
                    <CftMobileReportWrapper>
                      <CftGraph data={graphData} indexLabel />
                    </CftMobileReportWrapper>
                  </div>
                </Row>
              </CftReport.Box>
              <h4
                className="cft-report__summary__header"
                style={{
                  textAlign: "center",
                  color: "#666f85",
                  marginTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                {t("cft:reportWaterFootprint.summary.weeks")}
              </h4>
            </CftCard>
          </CftGroup>
          <p className="cft-disclaimer">
            {t(
              "cft:report.disclaimer",
              "Niniejszy dokument jest jedynie materiałem informacyjnym do użytku odbiorcy. Nie może on być uznany za rekomendację do dokonania jakiejkolwiek inwestycji. Wszystkie opinie i prognozy wyrażone w niniejszym dokumencie są wyrazem oceny autorów w dniu publikacji i mogą ulec zmianie bez zapowiedzi. Żadna część jak i całość utworów zawartych w publikacji nie może być powielana i rozpowszechniania lub dalej rozpowszechniana w jakiejkolwiek formie i w jakikolwiek sposób (w tym także elektroniczny, mechaniczny lub inny albo na wszelkich polach eksploatacji) włącznie z szeroko pojętą digitalizacją, fotokopiowaniem lub kopiowaniem, w tym także zamieszczaniem w Internecie - bez uprzedniej pisemnej zgody BNP Paribas Bank Polska S.A. z siedzibą w Warszawie. Jakiekolwiek użycie lub wykorzystanie z naruszeniem prawa utworów zawartych w publikacji, w całości lub w części, bez uprzedniej pisemnej zgody BNP Paribas Bank Polska S.A. jest zabronione pod groźbą kary i może być ścigane prawnie."
            )}
          </p>
        </div>
      )}
    </>
  );
};

export default WaterFootprintGraph;

import { dotFormatter } from "../../../utils/cftUtils";

export function mapValues(values) {
  return {
    manure: values.herd.map(({ herdSection, type, allocation }) => ({
      herd_section: herdSection + 1,
      type,
      allocation,
    })),
    bedding: values.bedding.map(({ type, amount }) => ({
      type,
      quantity: {
        value: dotFormatter(amount.value),
        unit_id: amount.unit,
      },
    })),
  };
}

import React, { useState, useEffect } from "react";
import queryString from "query-string";
import classNames from "classnames";
import Modal from "react-modal";
import axios from "axios";

import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play.svg";
import VideoEmbed from "./VideoEmbed";

import "./VideoCover.scss";

function VideoCover({ video, isBig }) {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  async function getMediaUrlMeta(mediaURL) {
    if (mediaURL.includes("youtube")) {
      const [, search] = mediaURL.split("?");
      const { v } = queryString.parse(search);
      setThumbnailUrl(`https://img.youtube.com/vi/${v}/mqdefault.jpg`);

      return {
        provider: "youtube",
        url: `https://img.youtube.com/vi/${v}/mqdefault.jpg`,
      };
    }

    if (mediaURL.includes("vimeo")) {
      const id = mediaURL.replace("https://vimeo.com/", "");
      const thumbnail_url = await axios
        .get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${id}`)
        .then(({ data }) => {
          return data.thumbnail_url;
        })
        .catch(() => {
          return `https://i.vimeocdn.com/video/${id}_640.jpg`;
        });
      setThumbnailUrl(thumbnail_url);

      return {
        provider: "vimeo",
        url: thumbnail_url,
      };
    }
    setThumbnailUrl(null);

    return {
      provider: "unknown",
      url: null,
    };
  }

  useEffect(() => {
    if (thumbnailUrl === null) {
      getMediaUrlMeta(video.video_url);
    }
  }, []);

  return (
    <>
      <div
        className={classNames("VideoCover", { "VideoCover--isBig": isBig })}
        onClick={() => {
          setIsPreviewVisible(true);
        }}
      >
        <div
          className="VideoCover__cover"
          style={{ backgroundImage: `url("${thumbnailUrl}")` }}
        >
          <PlayIcon />
        </div>
        <div className="VideoCover__title">
          <h3>{video.title}</h3>
        </div>
      </div>
      <Modal
        className="VideoCover__modal-container"
        isOpen={isPreviewVisible}
        onRequestClose={() => setIsPreviewVisible(false)}
        style={{
          overlay: {
            zIndex: "1000",
            backgroundColor: "rgba(0,0,0,0.6)",
          },
          content: {
            width: "100%",
            maxWidth: "860px",
            margin: "0 auto",
            bottom: "unset",
            borderRadius: 0,
            border: "none",
            padding: 0,
            overflowX: "hidden",
            backgroundColor: "#373A3C",
          },
        }}
      >
        <div className="VideoCover__modal">
          <button
            type="button"
            className="VideoCover__modal__close"
            onClick={() => setIsPreviewVisible(false)}
          >
            <CloseIcon />
          </button>
          <div className="VideoCover__preview">
            <VideoEmbed mediaURL={video.video_url} />
          </div>
          <h3>{video.title}</h3>
          <p>{video.description}</p>
        </div>
      </Modal>
    </>
  );
}

export default VideoCover;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";

import {
  CftLayout,
  CftHeader,
  CftTank,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import { useCft } from "../../../utils/CftProvider";
import TransportBothCalcForm, {
  TransportBothCalcFormContext,
} from "../../../forms/farmlands/bothCalc/TransportBothCalcForm/TransportBothCalc.form";

function TransportBothCalcPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { saveBothCalc, meta } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={88}
          cropName={meta.title.bothCalc}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="bothCalc" />

              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveBothCalc({
                    transport: values,
                  });
                  history.push(routes.FARMLANDS.BOTH_CALC.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:transport.title", "Transport")}
          onBack={() => history.push(routes.FARMLANDS.BOTH_CALC.CARBON_CHANGES)}
          onNext={submitForm}
          nextText={t("cft:report.btn", "Wygeneruj raport")}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:transport.title", "Transport")}</h3>
            <p>
              {t(
                "cft:transport.subtitle",
                "Należy uwzględnić transport przychodzący środków produkcji - takich jak pasze lub nawozy od sprzedawcy - do gospodarstwa. Dobrą praktyką jest uwzględnienie również transportu wychodzącego gotowych produktów do miejsca przetwarzania."
              )}
            </p>
          </>
        }
      >
        <TransportBothCalcForm />
        <CftReportProgress type="bothCalc" />
      </CftSection>
    </CftLayout>
  );
}

export default () => {
  return (
    <TransportBothCalcFormContext>
      <TransportBothCalcPage />
    </TransportBothCalcFormContext>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../../index";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function Step0({ handleClose, setStep, updateStyle }) {
  const { t } = useTranslation(["cftChickenBroiler"]);

  useEffect(() => {
    const style = {
      width: 1,
      height: 1,
      top: window.innerHeight / 2,
      left: window.innerWidth / 2,
      opacity: 1,
    };

    updateStyle(style);
  }, []);

  return (
    <>
      <div>
        <h3 className="cft-onboarding__item__title">
          {t(
            "cftChickenBroiler:onboarding.step0.title",
            "Witaj w  kalkulatorze emisji Gazów cieplarnianych"
          )}
        </h3>
        <p className="cft-onboarding__item__subtitle">
          {t(
            "cftChickenBroiler:onboarding:step0.subtitle",
            "Przeprowadzimy Cię przez najażniejsze funkcje kalkulatora, aby było łatwiej nam zacząć wspólną kalkulację. Jeśli będzie to Twoja pierwsza kalkulacja zalecamy przejście przez ten krotki samouczek, natomiast jeśli jesteś zaawansowanym użytkownikiem możesz pominąc samouczek w każdym momencie."
          )}
        </p>
        <CftTank justifyContent="center" alignItems="center">
          <CftButton
            variant="transparent"
            onClick={handleClose}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.skip", "Pomiń")}
          </CftButton>
          <CftButton
            variant="active"
            onClick={() => setStep(step => step + 1)}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.next", "Dalej")}
          </CftButton>
        </CftTank>
      </div>
    </>
  );
}

export default Step0;

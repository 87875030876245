import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import homeScreenicon from "../../../assets/icons/2Add_to_home_screen_icon.png";
import shareIcon from "../../../assets/icons/shareIcon.png";
import Image from "../Image/Image";
import { ButtonFix } from "../index";
import "./Alert.scss";

export const AlertPWA = ({ callback }) => {
  const [isopen, setIsopen] = useState(true);
  const { t } = useTranslation(["globals"]);
  if (!isopen) return null;
  return (
    <div className="c-alert-big c-alert-big--bottom">
      <Container className="c-alert-big__container c-alert-big__container--green">
        <Row>
          <Col>
            <div className="c-alert-big__heading c-alert-big__heading--top">
              <div className="c-alert-pwa">
                <div className="c-alert-pwa__icon">
                  <Image src={homeScreenicon} alt="" />
                </div>
                <div
                  className="c-alert-pwa__desc"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t("globals:utis.pwa").replace(
                      "<IMG>",
                      `<img src="${shareIcon}" alt="" />`
                    ),
                  }}
                />
              </div>
              <ButtonFix
                buttoncolor="yellow"
                onClick={() => {
                  setIsopen(false);
                  if (callback) {
                    callback();
                  }
                }}
              >
                {t("globals:buttons.close")}
              </ButtonFix>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

import { map } from "ramda";

export const decreaseStars = metaPhoto => ({
  ...metaPhoto,
  voted: false,
  votes_count: metaPhoto.votes_count - 1,
});

export const increaseStars = metaPhoto => ({
  ...metaPhoto,
  voted: true,
  votes_count: metaPhoto.votes_count + 1,
});

const getSlugFromAction = action =>
  action.meta ? action.meta.previousAction.slug : action.slug;

export const increaseStarsOnPhoto = (action, photos) =>
  map(photo => {
    if (getSlugFromAction(action) === photo.slug) {
      return increaseStars(photo);
    }
    return photo;
  }, photos);

export const decreaseStarsOnPhoto = (action, photos) =>
  map(photo => {
    if (getSlugFromAction(action) === photo.slug) {
      return decreaseStars(photo);
    }
    return photo;
  }, photos);

export const prepareTagsOptions = action =>
  map(
    tag => ({
      ...tag,
      value: tag.name,
      label: tag.name,
    }),
    action.payload.data.results
  );

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import clx from "classnames";
import PropTypes from "prop-types";

import "./CftCheckbox.scss";

export default function CftCheckbox({ name, label, ...props }) {
  return (
    <label className={clx("cft-checkbox")} id={name}>
      {label}
      <input type="radio" id={name} name={name} {...props} />
      <span className="cft-checkbox__checkmark" />
    </label>
  );
}

CftCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

CftCheckbox.defaultProps = {
  label: "",
};

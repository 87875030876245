import React, { useRef, useEffect } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftQuestionGroup,
  CftFormControl,
  CftButton,
  CftTank,
  CftFormErrorFocus,
} from "../../../components";
import { scrollToLastElement } from "../../../utils/cftUtils";
import ConvertedFields from "./ConvertedFields";
import { initCarbonChange } from "./carbonChanges.consts";
import ForestBiomass from "./ForestBiomass";

function LandManagement({ soilMoistureId }) {
  const { t } = useTranslation(["cft"]);
  const { values, setFieldValue, errors } = useFormikContext();
  const landManagementGroupRefs = useRef([]);

  if (!soilMoistureId) {
    return null;
  }

  useEffect(() => {
    if (values.managementPractice.carbon_changes.length === 0) {
      setFieldValue("converted", false);
      setFieldValue("managementPractice", null);
    }
  }, [values]);

  const checkGroupError = key => {
    if (errors.managementPractice) {
      if (errors.managementPractice.carbon_changes) {
        return (
          typeof errors.managementPractice.carbon_changes[key] !== "undefined"
        );
      }
    }

    return false;
  };

  return (
    <>
      {values.managementPractice.carbon_changes.map((carbonChange, key) => (
        <CftQuestionGroup
          // eslint-disable-next-line no-return-assign
          ref={el => (landManagementGroupRefs.current[key] = el)}
          key={`${key}_${carbonChange.id}`}
          label={`${t("cft:carbonChanges.group", "Zmiana")} ${key + 1}`}
          withoutMarginBottom={
            key + 1 === values.managementPractice.carbon_changes.length
          }
          onRemove={() => {
            setFieldValue(
              "managementPractice.carbon_changes",
              values.managementPractice.carbon_changes.filter(
                (f, k) => k !== key
              )
            );
            scrollToLastElement(landManagementGroupRefs, 130);
          }}
          hasError={checkGroupError(key)}
        >
          <ConvertedFields
            name={`managementPractice.carbon_changes.${key}`}
            id={key}
          />
        </CftQuestionGroup>
      ))}
      <CftFormControl variant="small">
        <CftTank display="flex" justifyContent="space-around">
          <CftButton
            onClick={() => {
              setFieldValue("managementPractice.carbon_changes", [
                ...values.managementPractice.carbon_changes,
                {
                  ...initCarbonChange,
                  id: Date.now(),
                },
              ]);
              scrollToLastElement(landManagementGroupRefs, 90);
            }}
          >
            {t("cft:carbonChanges.add", "Dodaj wcześniejszą zmianę")}
          </CftButton>
        </CftTank>
      </CftFormControl>
      <ForestBiomass />
      <CftFormErrorFocus />
    </>
  );
}

export default LandManagement;

const AVGP_MAIN = "/srednie-ceny";
const AVGP_GRAIN = "/zboza";
const AVGP_OILS = "/oleiste/rzepak";
const AVGP_POULTRY = "/drob";
const AVGP_PORK = "/wieprzowina";
const AVGP_DAIRY = "/nabial";
const AVGP_CHEESE = "/sery";

export default {
  GRAINS: {
    GRAINS_MAIN: `${AVGP_MAIN}${AVGP_GRAIN}`,
    BARLEY: `${AVGP_MAIN}${AVGP_GRAIN}/jeczmien`,
    CORN: `${AVGP_MAIN}${AVGP_GRAIN}/kukurydza`,
    OAT: `${AVGP_MAIN}${AVGP_GRAIN}/owies`,
    WHEAT: `${AVGP_MAIN}${AVGP_GRAIN}/pszenica`,
    RYE: `${AVGP_MAIN}${AVGP_GRAIN}/zyto`,
    TRITICALE: `${AVGP_MAIN}${AVGP_GRAIN}/pszenzyto`,
  },
  OILS: {
    OILS_MAIN: `${AVGP_MAIN}${AVGP_OILS}`,
    SEEDS: `${AVGP_MAIN}${AVGP_OILS}/nasiona-rzepaku`,
    MEAL: `${AVGP_MAIN}${AVGP_OILS}/sruta-rzepakowa`,
    CAKE: `${AVGP_MAIN}${AVGP_OILS}/makuch-rzepakowy`,
  },
  PORK_MAIN: `${AVGP_MAIN}${AVGP_PORK}`,
  POULTRY: {
    POULTRY_MAIN: `${AVGP_MAIN}${AVGP_POULTRY}`,
    GOOSE: `${AVGP_MAIN}${AVGP_POULTRY}/gesi`,
    TURKEY_M: `${AVGP_MAIN}${AVGP_POULTRY}/indory`,
    TURKEY_F: `${AVGP_MAIN}${AVGP_POULTRY}/indyczki`,
    DUCK: `${AVGP_MAIN}${AVGP_POULTRY}/kaczki-brojler`,
    CHICKEN: `${AVGP_MAIN}${AVGP_POULTRY}/kurczeta-brojler`,
    CHICKEN_HERD: `${AVGP_MAIN}${AVGP_POULTRY}/kury-miesne-stada-reprodukcyjne`,
  },
  DAIRY: {
    DAIRY_MAIN: `${AVGP_MAIN}${AVGP_DAIRY}`,
    BUTTER: `${AVGP_MAIN}${AVGP_DAIRY}/maslo`,
    MILK_POWDER: `${AVGP_MAIN}${AVGP_DAIRY}/proszki-mleczne`,
    LIQUIDS: `${AVGP_MAIN}${AVGP_DAIRY}/produkty-plynne`,
    CHEESE: `${AVGP_MAIN}${AVGP_DAIRY}${AVGP_CHEESE}`,
  },
  CHEESE: {
    CHEESE_MAIN: `${AVGP_MAIN}${AVGP_DAIRY}${AVGP_CHEESE}`,
    GOUDA: `${AVGP_MAIN}${AVGP_DAIRY}${AVGP_CHEESE}/gouda`,
    EDAM: `${AVGP_MAIN}${AVGP_DAIRY}${AVGP_CHEESE}/edamski`,
    COTTAGE: `${AVGP_MAIN}${AVGP_DAIRY}${AVGP_CHEESE}/twarog`,
  },
};

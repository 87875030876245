import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import API from "../../../services/apiHelpers";
import useCftPigsValues, { initialStore } from "../../utils/useCftPigsValues";
import { CftTank } from "..";
import CftPigsReportChart from "./CftPigsReportChart";
import routes from "../../utils/cftRoutes";

function CftTurkeyReport() {
  const token = Cookies.get("user_token");
  const { t } = useTranslation(["cftTurkey", "cftChickenBroiler", "cftPigs"]);
  const { store, updateStore, getProductionGroupTypeName } = useCftPigsValues();
  const [report, setReport] = useState(store.report);
  const [isFetching, setFetching] = useState(true);
  const [error, setError] = useState();
  const numberFormat = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const history = useHistory();

  const getReport = async () => {
    const henhouse = {
      postal_code: store.main.postal_code,
      name: store.main.name,
      calculation_period_length: store.main.calculation_period_length,
      production_groups: store.herd_reference.production_groups.map(
        productionGroup => ({
          ...productionGroup,
          feed_port: [13, 14].includes(Number(productionGroup.feed_type))
            ? productionGroup.feed_port
            : undefined,
        })
      ),
      bedding: store.emission.bedding,
      energy_carriers: store.emission.energy_carriers.map(
        ({ carrier_type, value, unit }) => ({
          carrier_type,
          quantity: value,
          unit_id: unit,
        })
      ),
      transport: store.emission.transport,
    };
    setFetching(true);
    try {
      const { data } = await API().post(
        "/api/v1/ae/pigs/calculation/",
        henhouse,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReport(data);
      updateStore({
        report: {
          ...data,
          isValidate: true,
        },
      });
    } catch (error) {
      setError(error);
    }

    setFetching(false);
  };

  useEffect(async () => {
    getReport();
  }, []);

  if (isFetching) {
    return (
      <>
        {t(
          "cftTurkey:cftChickenBroilerReport.loading",
          " Generowanie raportu...."
        )}
      </>
    );
  }

  if (error) {
    return (
      <div id="report">
        <div className="cft-wrapper cft-henhouses-report">
          <CftTank
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <h2 className="cft-section-title mb-3">
              {t(
                "cftChickenBroiler:cftChickenBroilerReport.error",
                "Nie można wygenerować raportu, sprawdź czy wszystkie dane zostały wprowadzone. Jeżeli błąd się powtórzy spróbój poźniej lub skontaktuj się z pomocą techniczną."
              )}
            </h2>
          </CftTank>
        </div>
      </div>
    );
  }

  return (
    <div id="report">
      <div className="cft-wrapper cft-henhouses-report">
        <CftTank
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          <h2 className="cft-section-title mb-3">
            {t("cftTurkey:cftChickenBroilerReport.title", "Raport")}
          </h2>
        </CftTank>
        <div className="cft-paper mb-4">
          <p className="cft-henhouses-report__title">
            {t(
              "cftTurkey:cftChickenBroilerReport.report__title",
              "Informacje o produkcji"
            )}
          </p>
          <hr />
          <p className="cft-henhouses-report__subtitle">
            {t("cftTurkey:cftChickenBroilerReport.name", "Nazwa hodowli:")}{" "}
            <span>{report.name}</span>
          </p>
          <hr />
          <p className="cft-henhouses-report__subtitle">
            {t("cftTurkey:cftChickenBroilerReport.postal_code", "Lokalizacja:")}{" "}
            <span>{report.postal_code}</span>
          </p>
          <hr />
          <p className="cft-henhouses-report__subtitle">
            {t(
              "cftTurkey:cftChickenBroilerReport.calculation_period_length",
              "Długość okresu obliczeniowego:"
            )}{" "}
            <span>{report.calculation_period_length}</span>
          </p>
          <hr />
          <p className="cft-henhouses-report__subtitle">
            {t(
              "cftTurkey:cftChickenBroilerReport.total_carbon_footprint",
              "Całkowity ślad węglowy w analizowanym okresie"
            )}
          </p>
          <p className="cft-report__summary__value mb-4">
            {numberFormat.format(report.total_emission.carbon_footprint)}
            <small>
              {t(
                "cftPigs:cftChickenBroilerReport.total_carbon_footprintLabel",
                "kg eCO2 / {{days}} dni",
                { days: report.calculation_period_length }
              )}
            </small>
          </p>
          <CftPigsReportChart report={report} />
          <div className="cft-table-wrapper">
            <table className="cft-table cft-table--alignRight">
              <thead>
                <tr>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header1",
                      "Środki produkcji"
                    )}
                  </th>
                  <th>
                    {t(
                      "cftPigs:cftChickenBroilerReport.header2",
                      "kg eCO2/kilogram produktu"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.tabel.total_carrier_use",
                      "Nośniki energii"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_emission.carrier_use)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_bedding_use",
                      "Produkcja Ściółki"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_emission.bedding_use)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_feed_production",
                      "Paszy"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_emission.feed_production)}
                  </td>
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_one",
                    "Odchody - podbiórka 1"
                  )}
                </td>
                <td>
                  {numberFormat.format(report.total_excrement_pickup_one)}
                </td>
              </tr> */}
                {/* {!!report.total_excrement_pickup_two && (
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_two",
                      "odchody - podbiórka 2"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_excrement_pickup_two)}
                  </td>
                </tr>
              )} */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement",
                      "Związane z odchodami"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_emission.excrement)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_transport_use",
                      "Transport"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_emission.transport_use)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="cft-paper mb-4">
          <p className="cft-henhouses-report__subtitle mb-4">
            {t(
              "cftTurkey:cftChickenBroilerReportPerAnimal.subtitle2",
              "Całkowity ślad węglowy/ jedno zwierzę"
            )}
          </p>
          <p className="cft-report__summary__value mb-4">
            {numberFormat.format(
              report.total_emission_per_animal.carbon_footprint
            )}
            <small>
              {t(
                "cftPigs:cftChickenBroilerReport.total_carbon_footprintLabel",
                "kg eCO2 / {{days}} dni",
                { days: report.calculation_period_length }
              )}
            </small>
          </p>
          <div className="cft-table-wrapper">
            <table className="cft-table cft-table--alignRight">
              <thead>
                <tr>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header1",
                      "Środki produkcji"
                    )}
                  </th>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header2",
                      "kg eCO2/kilogram produktu"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.tabel.total_carrier_use",
                      "Nośniki energii"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_animal.carrier_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_bedding_use",
                      "Produkcja Ściółki"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_animal.bedding_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_feed_production",
                      "Paszy"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_animal.feed_production
                    )}
                  </td>
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_one",
                    "Odchody - podbiórka 1"
                  )}
                </td>
                <td>
                  {numberFormat.format(report.total_excrement_pickup_one)}
                </td>
              </tr> */}
                {/* {!!report.total_excrement_pickup_two && (
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_two",
                      "odchody - podbiórka 2"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_excrement_pickup_two)}
                  </td>
                </tr>
              )} */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement",
                      "Związane z odchodami"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_animal.excrement
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_transport_use",
                      "Transport"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_animal.transport_use
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="cft-paper mb-4">
          <p className="cft-henhouses-report__subtitle mb-4">
            {t(
              "cftTurkey:cftChickenBroilerReportPerKg.subtitle2",
              "Całkowity ślad węglowy/ kilogram produktu"
            )}
          </p>
          <p className="cft-report__summary__value mb-4">
            {numberFormat.format(
              report.total_emission_per_kilogram_of_product.carbon_footprint
            )}
            <small>
              {t(
                "cftPigs:cftChickenBroilerReport.total_carbon_footprintLabel",
                "kg eCO2 / {{days}} dni",
                { days: report.calculation_period_length }
              )}
            </small>
          </p>
          <div className="cft-table-wrapper">
            <table className="cft-table cft-table--alignRight">
              <thead>
                <tr>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header1",
                      "Środki produkcji"
                    )}
                  </th>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header2",
                      "kg eCO2/kilogram produktu"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.tabel.total_carrier_use",
                      "Nośniki energii"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_kilogram_of_product.carrier_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_bedding_use",
                      "Produkcja Ściółki"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_kilogram_of_product.bedding_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_feed_production",
                      "Paszy"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_kilogram_of_product
                        .feed_production
                    )}
                  </td>
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_one",
                    "Odchody - podbiórka 1"
                  )}
                </td>
                <td>
                  {numberFormat.format(report.total_excrement_pickup_one)}
                </td>
              </tr> */}
                {/* {!!report.total_excrement_pickup_two && (
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_two",
                      "odchody - podbiórka 2"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_excrement_pickup_two)}
                  </td>
                </tr>
              )} */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement",
                      "Związane z odchodami"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_kilogram_of_product.excrement
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_transport_use",
                      "Transport"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_kilogram_of_product
                        .transport_use
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="cft-paper mb-4">
          <p className="cft-henhouses-report__subtitle mb-4">
            {t(
              "cftPigs:cftPigsReport.subtitle2",
              "Całkowity ślad węglowy/ Grupa produkcyjna"
            )}
          </p>
          <div className="cft-table-wrapper">
            <table className="cft-table cft-table--alignRight">
              <thead>
                <tr>
                  <th>
                    {t(
                      "cftPigs:cftPigsReport.productionGroups.header1",
                      "Grupa produkcyjna"
                    )}
                  </th>
                  {report.production_groups.map(({ name }) => (
                    <th key={name}>{getProductionGroupTypeName(name)}</th>
                  ))}
                </tr>
                <tr>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.henhouses.header2",
                      "Środki produkcji"
                    )}
                  </th>
                  {report.production_groups.map(({ name }) => (
                    <th key={name}>kg eCO2</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t(
                      "cftPigs:cftChickenBroilerReport.table.carbon_footprint",
                      "Całkowity ślad węglowy w kurniku referencyjnym"
                    )}
                  </td>
                  {report.production_groups.map(({ carbon_footprint }, key) => (
                    <td key={`carbon_footprint_${key}`}>
                      {numberFormat.format(carbon_footprint)}
                    </td>
                  ))}
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.carrier_use",
                    " Nośniki energii"
                  )}
                </td>
                {report.henhouses.map(({ carrier_use }, key) => (
                  <td key={`carrier_use_${key}`}>
                    {numberFormat.format(carrier_use)}
                  </td>
                ))}
              </tr> */}
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.bedding_use",
                    "Produkcja Ściółki"
                  )}
                </td>
                {report.henhouses.map(({ bedding_use }, key) => (
                  <td key={`total_bedding_use_${key}`}>
                    {numberFormat.format(bedding_use)}
                  </td>
                ))}
              </tr> */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.feed_production",
                      "Paszy"
                    )}
                  </td>
                  {report.production_groups.map(({ feed_production }, key) => (
                    <td key={`feed_production_${key}`}>
                      {numberFormat.format(feed_production)}
                    </td>
                  ))}
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.excrement_pickup_one",
                    "Odchody - podbiórka 1"
                  )}
                </td>
                {report.henhouses.map(({ excrement_pickup_one }, key) => (
                  <td key={`excrement_pickup_one_${key}`}>
                    {numberFormat.format(excrement_pickup_one)}
                  </td>
                ))}
              </tr> */}
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.excrement_pickup_two",
                    "odchody - podbiórka 2"
                  )}
                </td>
                {report.henhouses.map(({ excrement_pickup_two }, key) => (
                  <td key={`excrement_pickup_two_${key}`}>
                    {numberFormat.format(excrement_pickup_two)}
                  </td>
                ))}
              </tr> */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.excrement",
                      "Związane z odchodami"
                    )}
                  </td>
                  {report.production_groups.map(({ excrement }, key) => (
                    <td key={`excrement_${key}`}>
                      {numberFormat.format(excrement)}
                    </td>
                  ))}
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.transport_use",
                    "Transport"
                  )}
                </td>
                {report.henhouses.map(({ transport_use }, key) => (
                  <td key={`transport_use_${key}`}>
                    {numberFormat.format(transport_use)}
                  </td>
                ))}
              </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="cft-paper mb-4">
          <p className="cft-henhouses-report__subtitle mb-4">
            {t(
              "cftTurkey:cftChickenBroilerReportPerProductionCycle.subtitle2",
              "Ślad węglowy/ cykl produkcyjny"
            )}
          </p>
          <p className="cft-report__summary__value mb-4">
            {numberFormat.format(
              report.total_emission_per_production_cycle.carbon_footprint
            )}
            <small>
              {t(
                "cftPigs:cftChickenBroilerReport.total_carbon_footprintLabel",
                "kg eCO2 / {{days}} dni",
                { days: report.calculation_period_length }
              )}
            </small>
          </p>
          <div className="cft-table-wrapper">
            <table className="cft-table cft-table--alignRight">
              <thead>
                <tr>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header1",
                      "Środki produkcji"
                    )}
                  </th>
                  <th>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.header2",
                      "kg eCO2/kilogram produktu"
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.tabel.total_carrier_use",
                      "Nośniki energii"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_production_cycle.carrier_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_bedding_use",
                      "Produkcja Ściółki"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_production_cycle.bedding_use
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_feed_production",
                      "Paszy"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_production_cycle.feed_production
                    )}
                  </td>
                </tr>
                {/* <tr>
                <td>
                  {t(
                    "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_one",
                    "Odchody - podbiórka 1"
                  )}
                </td>
                <td>
                  {numberFormat.format(report.total_excrement_pickup_one)}
                </td>
              </tr> */}
                {/* {!!report.total_excrement_pickup_two && (
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement_pickup_two",
                      "odchody - podbiórka 2"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(report.total_excrement_pickup_two)}
                  </td>
                </tr>
              )} */}
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_excrement",
                      "Związane z odchodami"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_production_cycle.excrement
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t(
                      "cftTurkey:cftChickenBroilerReport.table.total_transport_use",
                      "Transport"
                    )}
                  </td>
                  <td>
                    {numberFormat.format(
                      report.total_emission_per_production_cycle.transport_use
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <CftTank display="flex" flexDirection="column" alignItems="center">
          <button
            className="hide-on-pdf"
            onClick={() => {
              updateStore(initialStore);
              setTimeout(() => {
                history.push(routes.TURKEY.MAIN);
              }, 100);
            }}
            style={{
              margin: "0 1rem",
              background: "transparent",
              border: "none",
            }}
            type="button"
          >
            {t("cftChickenBroiler:clear", "Wyczyść kalkulację")}
          </button>
        </CftTank>
        <CftTank display="flex" flexDirection="column" alignItems="center">
          <p
            style={{
              fontSize: "0.75rem",
              textAlign: "center",
              marginTop: "1rem",
            }}
          >
            {t("cftChickenBroiler:disclaimera")}
          </p>
        </CftTank>
      </div>
    </div>
  );
}

export default CftTurkeyReport;

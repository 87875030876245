import { useTranslation } from "react-i18next";

export const UNITS = [
  {
    id: 1,
    name: "l",
    full_name: "l",
  },
  {
    id: 2,
    name: "kg",
    full_name: "kilogram",
  },
  {
    id: 3,
    name: "m3",
    full_name: "cubic metre",
  },
];

export const BEDDING_PERIODS = [
  {
    id: 1,
    name: "cftChickenBroiler:beddingPeriods.1",
    name_en: "Production cycle",
  },
  {
    id: 2,
    name: "cftChickenBroiler:beddingPeriods.2",
    name_en: "Calculation period",
  },
];

export const CARRIER_TYPES = [
  {
    id: 1,
    name: "cftChickenBroiler:carrierTypes.1",
    name_en: "Energia elektryczna",
  },
  {
    id: 2,
    name: "cftChickenBroiler:carrierTypes.2",
    name_en: "Węgiel kamienny",
  },
  {
    id: 3,
    name: "cftChickenBroiler:carrierTypes.3",
    name_en: "Gaz ziemny",
  },
  {
    id: 4,
    name: "cftChickenBroiler:carrierTypes.4",
    name_en: "Gaz płynny",
  },
  {
    id: 5,
    name: "cftChickenBroiler:carrierTypes.5",
    name_en: "Olej opałowy",
  },
  {
    id: 6,
    name: "cftChickenBroiler:carrierTypes.6",
    name_en: "Ciepło Sieciowe",
  },
];

const energyUnits = [
  {
    value: 2,
    label: "kWh",
  },
  {
    value: 2,
    label: "kg",
  },
  { value: 2, label: "m3" },
  { value: 2, label: "kg" },
  {
    value: 2,
    label: "kg",
  },
  {
    value: 2,
    label: "GJ",
  },
];

export const VEHICLE_TYPES = [
  {
    id: 1,
    name: "cftChickenBroiler:vehicleTypes.1",
    name_en: "Pojazd o ładowności do 3,5 t (diesel)",
  },
  {
    id: 2,
    name: "cftChickenBroiler:vehicleTypes.2",
    name_en: "Pojazd o ładowności do 3,5 t (benzyna)",
  },
  {
    id: 3,
    name: "cftChickenBroiler:vehicleTypes.3",
    name_en: "Pojazd o ładowności od 7,5 do 17 t (diesel)",
  },
  {
    id: 4,
    name: "cftChickenBroiler:vehicleTypes.4",
    name_en: "Pojazd o ładowności od 7,5 do 17 t (benzyna)",
  },
];

export const FEED_TYPES = [
  {
    name: "Starter",
    elements: [
      {
        id: 1,
        name: "cftChickenBroiler:feedTypes.corn",
        name_en: "Kukurydza",
      },
      {
        id: 2,
        name: "cftChickenBroiler:feedTypes.soybean_meal",
        name_en: "Śruta sojowa",
      },
      {
        id: 3,
        name: "cftChickenBroiler:feedTypes.wheat",
        name_en: "Pszenica",
      },
      {
        id: 4,
        name: "cftChickenBroiler:feedTypes.corn_gluten",
        name_en: "Gluten kukurydziany",
      },
      {
        id: 5,
        name: "cftChickenBroiler:feedTypes.canola",
        name_en: "Rzepak",
      },
      {
        id: 6,
        name: "cftChickenBroiler:feedTypes.barley",
        name_en: "Jęczmień",
      },
      {
        id: 7,
        name: "cftChickenBroiler:feedTypes.oats",
        name_en: "Owies",
      },
    ],
  },
  {
    name: "Grower",
    elements: [
      {
        id: 8,
        name: "cftChickenBroiler:feedTypes.corn",
        name_en: "Kukurydza",
      },
      {
        id: 9,
        name: "cftChickenBroiler:feedTypes.soybean_meal",
        name_en: "Śruta sojowa",
      },
      {
        id: 10,
        name: "cftChickenBroiler:feedTypes.wheat",
        name_en: "Pszenica",
      },
      {
        id: 11,
        name: "cftChickenBroiler:feedTypes.corn_gluten",
        name_en: "Gluten kukurydziany",
      },
      {
        id: 12,
        name: "cftChickenBroiler:feedTypes.canola",
        name_en: "Rzepak",
      },
      {
        id: 13,
        name: "cftChickenBroiler:feedTypes.barley",
        name_en: "Jęczmień",
      },
      {
        id: 14,
        name: "cftChickenBroiler:feedTypes.oats",
        name_en: "Owies",
      },
    ],
  },
  {
    name: "Finisher",
    elements: [
      {
        id: 15,
        name: "cftChickenBroiler:feedTypes.corn",
        name_en: "Kukurydza",
      },
      {
        id: 16,
        name: "cftChickenBroiler:feedTypes.soybean_meal",
        name_en: "Śruta sojowa",
      },
      {
        id: 17,
        name: "cftChickenBroiler:feedTypes.wheat",
        name_en: "Pszenica",
      },
      {
        id: 18,
        name: "cftChickenBroiler:feedTypes.corn_gluten",
        name_en: "Gluten kukurydziany",
      },
      {
        id: 19,
        name: "cftChickenBroiler:feedTypes.canola",
        name_en: "Rzepak",
      },
      {
        id: 20,
        name: "cftChickenBroiler:feedTypes.barley",
        name_en: "Jęczmień",
      },
      {
        id: 21,
        name: "cftChickenBroiler:feedTypes.oats",
        name_en: "Owies",
      },
    ],
  },
];

const FEED_TYPES_FLAT = FEED_TYPES.reduce((prev, crr) => {
  return [
    ...prev,
    ...crr.elements.map(element => ({
      ...element,
      parent: crr.name,
    })),
  ];
}, []);

const feedTypesOptions = t =>
  FEED_TYPES_FLAT.map(({ id, name, parent }) => ({
    label: t(name),
    value: id.toString(),
    parent,
  }));

const mainFeedTypesOptions = t =>
  FEED_TYPES.map(({ name }) => ({
    label: t(name),
    value: name,
  }));

export const getUnitName = id => {
  const unit = UNITS.find(unit => unit.id === id);

  return unit ? unit.name : "";
};

const unitsOptions = UNITS.map(({ id, name }) => ({
  label: name,
  value: id.toString(),
}));

const getUnits = (includeUnits = []) =>
  UNITS.filter(({ name }) => includeUnits.indexOf(name) !== -1);

const carrierOptions = t =>
  CARRIER_TYPES.map(({ id, name }) => ({
    label: t(name),
    value: id.toString(),
  }));

const getFeedTypeName = (id, t) => {
  const carrier = FEED_TYPES_FLAT.find(feed => feed.id === Number(id));

  return carrier ? `${carrier.parent} ${t(carrier.name)}` : "";
};

const getCarrierName = (id, t) => {
  const carrier = CARRIER_TYPES.find(carrier => carrier.id === Number(id));

  return carrier ? t(carrier.name) : "";
};

const getVehicleName = (id, t) => {
  const vehicle = VEHICLE_TYPES.find(vehicle => vehicle.id === Number(id));

  return vehicle ? t(vehicle.name) : "";
};

const beddingPeriodsOptions = t =>
  BEDDING_PERIODS.map(({ id, name }) => ({
    label: t(name),
    value: id.toString(),
  }));

const vehicleTypesOptions = t =>
  VEHICLE_TYPES.map(({ id, name }) => ({
    label: t(name),
    value: id.toString(),
  }));

export default function useCftAeOptions() {
  const { t } = useTranslation(["cft", "calculatorPage", "cftChickenBroiler"]);
  const year = new Date().getFullYear();

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(),
    label: t(`calculatorPage:months.${i}`),
  }));

  const years = Array.from({ length: 250 }, (_, i) => ({
    value: (year - i).toString(),
    label: (year - i).toString(),
  }));

  return {
    months,
    years,
    year,
    carrierOptions: carrierOptions(t),
    unitsOptions,
    beddingPeriodsOptions: beddingPeriodsOptions(t),
    vehicleTypesOptions: vehicleTypesOptions(t),
    feedTypesOptions: feedTypesOptions(t),
    energyUnits,
    mainFeedTypesOptions: mainFeedTypesOptions(t),
    getCarrierName,
    getVehicleName,
    getUnits,
    getUnitName,
    getFeedTypeName,
  };
}

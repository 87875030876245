import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftLabel,
  CftTextField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftConditionalRendering,
  CftSelectField,
  CftNumberField,
  CftTooltip,
} from "../../../../components";
import useCftOptions from "../../../../utils/useCftOptions";

// Soil categories
import { ReactComponent as ArableIcon } from "../../../../../assets/icons/cft/orny.svg";
import { ReactComponent as GrassIcon } from "../../../../../assets/icons/cft/trawa.svg";
import { ReactComponent as ForestIcon } from "../../../../../assets/icons/cft/las.svg";

import {
  managementPractice,
  forestTypes,
} from "./carbonChangesBothCalc.consts";

function ConvertedFields({ soilMoistureId, name }) {
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { years } = useCftOptions();

  if (!soilMoistureId) {
    return null;
  }

  function getManagementIcon(name) {
    switch (name) {
      case "Trawa":
        return <GrassIcon className="cft-box__svg-fix" />;
      case "Las":
        return <ForestIcon className="cft-box__svg-fix" />;
      default:
        return <ArableIcon className="cft-box__svg-fix" />;
    }
  }

  const isFrest =
    values.managementPractice.landUseBase === "forest" ||
    [17, 18, 9, 10].indexOf(values.managementPractice.landUse) !== -1;

  function initForest(clear = false) {
    if (clear && values[name].forestType !== undefined) {
      setFieldValue(`${name}.forestType`, undefined);
      setFieldValue(`${name}.forestYear`, undefined);
    }
    if (!clear && !values[name].forestType) {
      setFieldValue(`${name}.forestType`, "");
      setFieldValue(`${name}.forestYear`, 1);
      setFieldTouched(`${name}.forestType`);
      setFieldTouched(`${name}.forestYear`);
    }
  }

  return (
    <>
      <CftFormControl>
        <CftLabel label={t("cft:carbonChanges.form.11", "Zmienione z...")} />
        <CftFormField name={`${name}.landUseBase`}>
          {({ value, helpers, meta }) => (
            <>
              <CftBoxWrapper>
                <CftBox
                  isActive={value === "arable"}
                  onClick={() => {
                    setFieldValue(`${name}.landUse`, "");
                    initForest(true);
                    helpers.setValue("arable");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <ArableIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:carbonChanges.form.12", "Orny")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "grass"}
                  onClick={() => {
                    setFieldValue(`${name}.landUse`, "");
                    initForest(true);
                    helpers.setValue("grass");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <GrassIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:carbonChanges.form.9", "Trawa")}
                  </p>
                </CftBox>
                <CftBox
                  isActive={value === "forest"}
                  onClick={() => {
                    setFieldValue(`${name}.landUse`, "");
                    initForest();
                    helpers.setValue("forest");
                  }}
                  isInvalid={meta.touched && meta.error}
                  variant="medium"
                >
                  <ForestIcon className="cft-box__svg-fix" />
                  <p className="cft-box__paragraph">
                    {t("cft:carbonChanges.form.10", "Las")}
                  </p>
                </CftBox>
              </CftBoxWrapper>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={!!values.managementPractice.landUseBase}
      >
        <CftFormControl>
          <CftLabel label={t("cft:carbonChanges.form.15", "Zmieniony na...")} />
          <CftFormField name={`${name}.landUse`}>
            {({ value, helpers, meta }) => (
              <>
                <CftBoxWrapper>
                  {managementPractice[values.managementPractice.landUseBase]
                    .filter(
                      ({ soil_moisture_id }) =>
                        soil_moisture_id === Number(soilMoistureId)
                    )
                    .map(option => (
                      <CftBox
                        key={option.id}
                        isActive={value === option.id}
                        onClick={() => {
                          if ([10, 18].indexOf(option.id) === -1) {
                            initForest(true);
                          } else {
                            initForest();
                          }
                          helpers.setValue(option.id);
                        }}
                        isInvalid={meta.touched && meta.error}
                        variant="medium"
                      >
                        {getManagementIcon(option.pl)}
                        <p className="cft-box__paragraph">{option[language]}</p>
                      </CftBox>
                    ))}
                </CftBoxWrapper>
                <CftError
                  isActive={meta.touched && meta.error}
                  message={meta.error}
                  textAlign="center"
                />
              </>
            )}
          </CftFormField>
        </CftFormControl>
      </CftConditionalRendering>
      <CftConditionalRendering isConditionFulfilled={isFrest}>
        <>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:carbonChanges.form.16", "Typ lasu")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:carbonChanges.tooltips.2",
                    "Określ rodzaj lasu przed jego przekształceniem"
                  )}
                />
              }
            />
            <CftSelectField
              name={`${name}.forestType`}
              options={forestTypes.map(opt => ({
                label: opt[language],
                value: opt.id,
              }))}
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:carbonChanges.form.17", "Wiek lasu")} />
            <CftNumberField name={`${name}.forestYear`} min={1} />
          </CftFormControl>
        </>
      </CftConditionalRendering>
      <CftFormControl variant="small">
        <CftLabel
          label={t(
            "cft:carbonChanges.form.18",
            "Kiedy nastąpiło przekształcenie"
          )}
          // eslint-disable-next-line react/no-children-prop
          children={
            <CftTooltip
              content={t(
                "cft:carbonChanges.tooltips.3",
                "Wskaż rok kiedy nastąpiło przekształcenie"
              )}
            />
          }
        />
        <CftSelectField name={`${name}.yearsAgo`} options={years} />
      </CftFormControl>
      <CftFormControl variant="small">
        <CftLabel label={t("cft:carbonChanges.form.19", "Procent pola")} />
        <CftTextField name={`${name}.landUseAllocation`} adornment="%" />
      </CftFormControl>
    </>
  );
}

export default ConvertedFields;

import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import cl from "classnames";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import CftError from "../CftError/CftError";

import "./CftSelectField.scss";

const getCustomStyles = isAlternative => ({
  control: provided => ({
    ...provided,
    minHeight: "40px",
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: isAlternative ? "400" : "300",
  }),
  noOptionsMessage: provided => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: "300",
  }),
  input: provided => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: "300",
    outline: "none",
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: "300",
    outline: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    zIndex: 1000,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    textAlign: "left",
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    fontWeight: "300",
    color: state.isFocused ? "#2e3033" : state.isSelected ? "#fff" : "#2e3033",
    backgroundColor: state.isFocused
      ? "rgba(0, 150, 94, 0.2)"
      : state.isSelected
      ? "#00965e"
      : "#fff",
  }),
});

function CftSelectField({
  name,
  options,
  isSearchable,
  placeholder,
  onChange,
  isRounded,
  isAlternative,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const { t } = useTranslation(["cft"]);

  const value = props.isMulti
    ? field.value
    : options.find(({ value }) => String(value) === String(field.value)) ||
      null;

  return (
    <div
      className={cl("cft-select-field", {
        "cft-select-field--isInvalid": meta.touched && meta.error,
        "cft-select-field--rounded": isRounded,
        "cft-select-field--alternative": isAlternative,
      })}
    >
      <Select
        className="cft-select-field__input"
        options={options}
        name={field.name}
        id={field.name}
        onChange={option => {
          if (props.isMulti) {
            helpers.setValue(option);
          } else {
            helpers.setValue(option.value);
          }

          if (onChange) {
            if (props.isMulti) {
              onChange(option, helpers);
            } else {
              onChange(option.value, helpers);
            }
          }
        }}
        instanceId={`${name}_instance`}
        placeholder={placeholder || t("cft:selectPlaceholder", "Wybierz...")}
        styles={getCustomStyles(isAlternative)}
        noOptionsMessage={() => <>{t("cft:noOptionsMessage", "Brak opcji")}</>}
        isSearchable={isSearchable}
        value={value}
        {...props}
      />
      <CftError isActive={meta.touched && meta.error} message={meta.error} />
    </div>
  );
}

CftSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  isRounded: PropTypes.bool,
  isAlternative: PropTypes.bool,
};

CftSelectField.defaultProps = {
  options: [],
  isSearchable: true,
  placeholder: "",
  isRounded: false,
  isAlternative: false,
};

export default CftSelectField;

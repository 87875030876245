import React from "react";
import "./Checkbox.scss";
import PropTypes from "prop-types";
import { CheckedIcon } from "../index";

export const Checkbox = props => {
  return (
    <>
      <div
        className={`checkbox ${props.className} ${props.haserror &&
          "checkbox--error"}`}
      >
        <label htmlFor={props.id}>
          <input
            id={props.id}
            type="checkbox"
            onChange={props.onChange}
            {...props}
          />
          <span className="checkbox-replacement">
            {props.checked ? <CheckedIcon /> : null}
          </span>
        </label>
        {props.label.$$typeof ? (
          <span>
            {props.label}{" "}
            {props.haserror && (
              <span className="input-error">{props.errormsg}</span>
            )}
          </span>
        ) : (
          // eslint-disable-next-line react/no-danger
          <span dangerouslySetInnerHTML={{ __html: props.label }} />
        )}
      </div>
    </>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: "",
};

import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { CftTank, CftButton } from "../../index";

export const ONBOARDING_COOKIE = "sadda3fwsdfasAdsA";

function Step2({ handleClose, setStep, updateStyle }) {
  const { t } = useTranslation(["cftChickenBroiler"]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      const dev =
        document.querySelector(".cft-henhouses-header") ||
        document.querySelector(".cft-header-steps");
      const box = dev.getBoundingClientRect();

      const style = {
        width: "100vw",
        height: box.height + 32,
        top: box.top - 16,
        left: 0,
        opacity: 1,
      };
      updateStyle(style);
    }, 800);
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: isMobile ? "0" : "10vw",
        }}
      >
        <h3 className="cft-onboarding__item__title">
          {t(
            "cftChickenBroiler:onboarding.step2.title",
            "Pasek postępu kalkulacji"
          )}
        </h3>
        <p className="cft-onboarding__item__subtitle">
          {t(
            "cftChickenBroiler:onboarding.step2.subtitle",
            "Pasek postępu podpowie Ci ile kroków formularza udało Ci się wypełnić i ile jeszcze pozostało do wypełnienia. Jeżeli chcesz wrócić do poprzedniego kroku, możesz to zrobić klikając w sekcję pasku postępu do której chcesz powrócić."
          )}
        </p>
        <CftTank justifyContent="center" alignItems="center">
          <CftButton
            variant="transparent"
            onClick={handleClose}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.skip", "Pomiń")}
          </CftButton>
          <CftButton
            variant="active"
            onClick={() => setStep(step => step + 1)}
            style={{ marginTop: "1rem" }}
          >
            {t("cft:onboarding.next", "Dalej")}
          </CftButton>
        </CftTank>
      </div>
    </>
  );
}

export default Step2;

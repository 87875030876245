import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import {
  CftBox,
  CftTank,
  CftLabel,
  CftUnitField,
  CftFormField,
  CftError,
  CftFormControl,
  CftBoxWrapper,
  CftButton,
  CftConditionalRendering,
  CftTextField,
  CftSelectField,
  CftNumberField,
  CftTooltip,
} from "../../../components";
import { useCft } from "../../../utils/CftProvider";
import useCftOptions from "../../../utils/useCftOptions";

// Energy types Icons
import { ReactComponent as AgregatorIcon } from "../../../../assets/icons/cft/agregator.svg";
import { ReactComponent as EnergyIcon } from "../../../../assets/icons/cft/energia.svg";
import { ReactComponent as CHPIcon } from "../../../../assets/icons/cft/chp.svg";

import {
  energySourcesTypes,
  magazinEnergySourcesTypes,
} from "./fuelAndEnergyFarmlands.consts";

const initPotatoStorage = {
  storedAllocation: "",
  storagePowerSource: "",
  storeLoadingPowerSource: "",
  unloadingPowerSource: "",
  time: {
    value: "",
    unit: "",
  },
  temperature: "",
  cipcDose: "1",
  cipcApplication: "1",
  energyType: "",
  energyTypeUnloading: "",
};

function PotatoStorageFields() {
  const { farmlands } = useCft();
  const { setFieldValue, values } = useFormikContext();
  const {
    t,
    i18n: { language },
  } = useTranslation(["cft"]);
  const { getUnits } = useCftOptions();

  const isPotatoesGrows = farmlands.details.crop === "14";

  if (!isPotatoesGrows) {
    return null;
  }

  if (values.isPotatoStorage === undefined) {
    setFieldValue("isPotatoStorage", null);
    return null;
  }

  const sourceType = values.potatoStorage.energyType;
  const sourceTypeUnloading = values.potatoStorage.energyTypeUnloading;

  return (
    <>
      <CftFormControl variant="small">
        <h3 className="cft-headline">
          {t("cft:fuelAndEnergy.form.37", "Przechowywanie")}
          <small>
            {t("cft:fuelAndEnergy.form.38", "Czy przechowujesz ziemniaki?")}
          </small>
        </h3>
        <CftFormField name="isPotatoStorage">
          {({ value, helpers, meta }) => (
            <>
              <CftTank display="flex" justifyContent="space-around">
                <CftButton
                  isActive={value}
                  onClick={() => {
                    helpers.setValue(true);
                    setFieldValue("potatoStorage", initPotatoStorage);
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  TAK
                </CftButton>
                <CftButton
                  isActive={value === false}
                  onClick={() => {
                    helpers.setValue(false);
                    setFieldValue("potatoStorage", {});
                  }}
                  isInvalid={meta.touched && meta.error}
                >
                  Nie
                </CftButton>
              </CftTank>
              <CftError
                isActive={meta.touched && meta.error}
                message={meta.error}
                textAlign="center"
              />
            </>
          )}
        </CftFormField>
      </CftFormControl>
      <CftConditionalRendering
        isConditionFulfilled={values.isPotatoStorage === true}
      >
        <>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fuelAndEnergy.form.39", "Procent przechowywan")}
            />
            <CftTextField name="potatoStorage.storedAllocation" adornment="%" />
          </CftFormControl>
          <CftFormControl>
            <CftLabel
              label={t("cft:fuelAndEnergy.form.40", "Procent przechowywan")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:fuelAndEnergy.tooltips.6",
                    "Określ jaki rodzaj energii jest wykorzystywany do przechowywania ziemniaków"
                  )}
                />
              }
            />
            <CftFormField name="potatoStorage.energyType">
              {({ value, helpers, meta }) => (
                <>
                  <CftBoxWrapper>
                    <CftBox
                      isActive={value === "agregator"}
                      onClick={() => {
                        helpers.setValue("agregator");
                        setFieldValue("potatoStorage.storagePowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <AgregatorIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {t("cft:fuelAndEnergy.form.15", "Agregator")}
                      </p>
                    </CftBox>
                    <CftBox
                      isActive={value === "electricity"}
                      onClick={() => {
                        helpers.setValue("electricity");
                        setFieldValue("potatoStorage.storagePowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <EnergyIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {t("cft:fuelAndEnergy.form.16", "Prąd")}
                      </p>
                    </CftBox>
                    <CftBox
                      isActive={value === "chp"}
                      onClick={() => {
                        helpers.setValue("chp");
                        setFieldValue("potatoStorage.storagePowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <CHPIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">CHP</p>
                    </CftBox>
                  </CftBoxWrapper>
                  <CftError
                    isActive={meta.touched && meta.error}
                    message={meta.error}
                    textAlign="center"
                  />
                </>
              )}
            </CftFormField>
          </CftFormControl>
          <CftConditionalRendering isConditionFulfilled={!!sourceType}>
            <CftFormControl variant="small">
              <CftLabel
                label={t("cft:fuelAndEnergy.form.41", "Załadunek magazynu")}
              />
              <CftSelectField
                name="potatoStorage.storagePowerSource"
                options={
                  energySourcesTypes[sourceType]
                    ? energySourcesTypes[sourceType].map(option => ({
                        label: option[language],
                        value: option.value,
                      }))
                    : []
                }
              />
            </CftFormControl>
          </CftConditionalRendering>
          <CftFormControl>
            <CftLabel
              label={t(
                "cft:fuelAndEnergy.form.42",
                "Żródło energii (rozładunek)"
              )}
            />
            <CftFormField name="potatoStorage.energyTypeUnloading">
              {({ value, helpers, meta }) => (
                <>
                  <CftBoxWrapper>
                    <CftBox
                      isActive={value === "agregator"}
                      onClick={() => {
                        helpers.setValue("agregator");
                        setFieldValue("potatoStorage.unloadingPowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <AgregatorIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {" "}
                        {t("cft:fuelAndEnergy.form.15", "Agregator")}
                      </p>
                    </CftBox>
                    <CftBox
                      isActive={value === "electricity"}
                      onClick={() => {
                        helpers.setValue("electricity");
                        setFieldValue("potatoStorage.unloadingPowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <EnergyIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">
                        {" "}
                        {t("cft:fuelAndEnergy.form.16", "Prąd")}
                      </p>
                    </CftBox>
                    <CftBox
                      isActive={value === "chp"}
                      onClick={() => {
                        helpers.setValue("chp");
                        setFieldValue("potatoStorage.unloadingPowerSource", "");
                      }}
                      isInvalid={meta.touched && meta.error}
                      variant="medium"
                    >
                      <CHPIcon className="cft-box__svg-fix" />
                      <p className="cft-box__paragraph">CHP</p>
                    </CftBox>
                  </CftBoxWrapper>
                  <CftError
                    isActive={meta.touched && meta.error}
                    message={meta.error}
                    textAlign="center"
                  />
                </>
              )}
            </CftFormField>
          </CftFormControl>
          <CftConditionalRendering isConditionFulfilled={!!sourceTypeUnloading}>
            <CftFormControl variant="small">
              <CftLabel
                label={t("cft:fuelAndEnergy.form.41", "Załadunek magazynu")}
              />
              <CftSelectField
                name="potatoStorage.unloadingPowerSource"
                options={
                  energySourcesTypes[sourceTypeUnloading]
                    ? energySourcesTypes[sourceTypeUnloading].map(option => ({
                        label: option[language],
                        value: option.value,
                      }))
                    : []
                }
              />
            </CftFormControl>
          </CftConditionalRendering>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fuelAndEnergy.form.44", "Źródło prądu (magazyn)")}
            />
            <CftSelectField
              name="potatoStorage.storeLoadingPowerSource"
              options={
                magazinEnergySourcesTypes
                  ? magazinEnergySourcesTypes.map(option => ({
                      label: option[language],
                      value: option.value,
                    }))
                  : []
              }
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fuelAndEnergy.form.45", "Czas przechowywania")}
            />
            <CftUnitField
              name="potatoStorage.time"
              units={getUnits(["lat", "msc.", "tyg.", "dni"])}
            />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel label={t("cft:fuelAndEnergy.form.46", "Temperatura")} />
            <CftTextField name="potatoStorage.temperature" adornment="°C" />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fuelAndEnergy.form.47", "Stosowanie CIPC")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:fuelAndEnergy.tooltips.7",
                    "Określ ile razy stosowałeś chloroprofam"
                  )}
                />
              }
            />
            <CftNumberField name="potatoStorage.cipcDose" min={1} />
          </CftFormControl>
          <CftFormControl variant="small">
            <CftLabel
              label={t("cft:fuelAndEnergy.form.48", "Dawka CIPC")}
              // eslint-disable-next-line react/no-children-prop
              children={
                <CftTooltip
                  content={t(
                    "cft:fuelAndEnergy.tooltips.8",
                    "Określ średnią dawkę zastosowane chloroprofamu"
                  )}
                />
              }
            />
            <CftNumberField name="potatoStorage.cipcApplication" min={1} />
          </CftFormControl>
        </>
      </CftConditionalRendering>
    </>
  );
}

export default PotatoStorageFields;

export const dairyProductionReport = {
  milk_production: {},
  herd_sections: [],
  grazing: [],
  grazing_area: {},
  fertilisation_approach: null,
  fertilisers: [],
  feed_aproche: null,
  feed_components: [],
  manure: [],
  bedding: [],
  direct_energy: [],
  transport: [],
};

export const dairyProductionInit = {
  details: {
    name: "",
    breed: "",
    referencePeriod: "",
    totalMilkProduction: {
      value: "",
      unit: "",
    },
    fatContent: "",
    proteinContent: "",
    proteinContentType: "",
    year: "",
    month: "",
  },
  characteristics: {
    cattleHerd: [null, null, null, null, null, null],
  },
  cattleGrazing: {
    herd: [null, null, null, null, null, null],
    fertilisation: null,
    applications: [],
  },
  forage: {
    approach: "",
    herd: [],
    feed: [],
  },
  manure: {
    herd: [
      {
        id: 0,
        herdSection: "",
        type: "",
        allocation: "",
      },
    ],
    bedding: [
      {
        id: 0,
        type: "",
        amount: {
          unit: "",
          value: "",
        },
      },
    ],
  },
  energyAndProcessing: {
    sources: [],
  },
  transport: {
    used: null,
    transports: [],
  },
};

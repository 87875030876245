import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CftLayout,
  CftHeader,
  CftSection,
  CftActionsButtons,
  CftTank,
  CftLeavingModal,
} from "../../components";
import CalcTypeForm, {
  CalcTypeFormContext,
} from "../../forms/farmlands/CalcTypeForm/CalcType.form";

export const ONBOARDING_COOKIE = "sadda3fwsdfas";

const CalcTypePage = () => {
  const { t } = useTranslation(["cft"]);

  const [showLeavingModal, setShowLeavingModal] = useState(false);
  const stopOverflow = () => {
    document.querySelector("body").style.overflowY = "hidden";
    document.querySelector("html").style.overflow = "hidden";
  };

  return (
    <CftLayout
      header={
        <CftHeader
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftActionsButtons.CancelButton
                onClick={() => {
                  setShowLeavingModal(!showLeavingModal);
                  stopOverflow();
                }}
              />
            </CftTank>
          }
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:calcType.title")}</h3>
            <p>{t("cft:calcType.subtitle")}</p>
          </>
        }
      />
      <CalcTypeForm />
      <CftLeavingModal
        isVisible={showLeavingModal}
        type="both"
        hide={() => setShowLeavingModal(!showLeavingModal)}
      />
    </CftLayout>
  );
};

export default () => {
  return (
    <CalcTypeFormContext>
      <CalcTypePage />
    </CalcTypeFormContext>
  );
};

import Cookies from "js-cookie";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useSWRInfinite from "swr/infinite";
import useSWR from "swr";
import API from "../apiHelpers";

export function usePressArticles() {
  const { i18n } = useTranslation();
  const url = "/api/v2/articles/";

  const { data, isValidating } = useSWR([url], () => {
    const token = Cookies.get("user_token");

    const params = {
      lang_code: Cookies.get("user_lang")
        ? Cookies.get("user_lang").toUpperCase()
        : i18n.language.toUpperCase(),
      membership: 1,
      limit: 50,
      offset: 0,
      categories_list: "53, 54, 55, 56",
    };

    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};

    return API().get(url, {
      params,
      headers,
    });
  });

  return data && data.data && data.data.results
    ? {
        pressArticles: data.data.results,
        isLoading: isValidating,
      }
    : {
        pressArticles: [],
        isLoading: isValidating,
      };
}

export function useArticlesInfinite({ category, categoriesList, limit = 10 }) {
  const { i18n } = useTranslation();

  const url = "/api/v2/articles/";

  const {
    data,
    mutate,
    size,
    setSize,
    isValidating,
    isLoading,
  } = useSWRInfinite(
    page => {
      const offset = limit * page;
      return JSON.stringify({
        url,
        categoriesList,
        category,
        offset: page > 1 ? offset - 2 : offset,
        limit: page > 0 ? limit - 2 : limit,
        lang: i18n.language,
      });
    },
    req => {
      const { url, limit, offset, category, categoriesList, lang } = JSON.parse(
        req
      );
      const token = Cookies.get("user_token");

      const params = {
        membership: 1,
        category: !categoriesList ? category.id : null,
        lang_code: lang.toUpperCase() || Cookies.get("user_lang").toUpperCase(),
        limit,
        offset,
        ordering: "-published_at",
        categories_list: categoriesList,
      };
      const headers = token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {};

      return API().get(url, {
        params,
        headers,
      });
    },
    { revalidateFirstPage: false }
  );

  const articlesData = data ? data.flatMap(({ data }) => data) : [];

  const nextPageAvailable = useMemo(() => {
    return !articlesData.length
      ? false
      : !!articlesData[articlesData.length - 1].next;
  }, [articlesData.length]);

  return {
    // ...articles,
    articlesData,
    isLoading,
    isValidating,
    mutate,
    page: size,
    nextPage: () => setSize(size + 1),
    nextPageAvailable,
  };
}

import PropTypes from "prop-types";
import React, { PureComponent } from "react";

function windowOpen(
  url,
  { name, height = 400, width = 550 },
  onShareWindowClose
) {
  /* eslint-disable no-mixed-operators */
  const left =
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2;
  const top =
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2;
  /* eslint-enable no-mixed-operators */

  const config = {
    height,
    width,
    left,
    top,
    location: "no",
    toolbar: "no",
    status: "no",
    directories: "no",
    menubar: "no",
    scrollbars: "yes",
    resizable: "no",
    centerscreen: "yes",
    chrome: "yes",
  };

  const shareDialog = window.open(
    url,
    name,
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(", ")
  );

  if (onShareWindowClose) {
    const interval = window.setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          window.clearInterval(interval);
          onShareWindowClose(shareDialog);
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
      }
    }, 1000);
  }

  return shareDialog;
}

class ShareButton extends PureComponent {
  static propTypes = {
    networkLink: PropTypes.func.isRequired,
    openWindow: PropTypes.bool,
    url: PropTypes.string.isRequired,
    role: PropTypes.string,
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    openWindow: true,
    role: "button",
    tabIndex: "0",
  };

  onClick = e => {
    const { disabled, onClick, openWindow } = this.props;

    if (disabled) {
      return;
    }

    e.preventDefault();

    const link = this.link();

    const clickHandler = openWindow
      ? () => this.openWindow(link)
      : () => onClick(link);

    clickHandler();
    // if (beforeOnClick) {
    //   const returnVal = beforeOnClick();

    //   if (isPromise(returnVal)) {
    //     returnVal.then(clickHandler);
    //   } else {
    //     clickHandler();
    //   }
    // } else {
    // }
  };

  openWindow = link => {
    const { onShareWindowClose, windowWidth, windowHeight } = this.props;

    const windowOptions = {
      height: windowHeight,
      width: windowWidth,
    };

    windowOpen(link, windowOptions, onShareWindowClose);
  };

  link() {
    const { url, opts, networkLink } = this.props;
    return networkLink(url, opts);
  }

  render() {
    const { children, name, role, tabIndex } = this.props;

    return (
      <div
        name={name}
        role={role}
        tabIndex={tabIndex}
        onClick={this.onClick}
        // onKeyPress={this.onKeyPress}
        // style={{
        //   ...style,
        //   ...(disabled ? disabledStyle : {})
        // }}
      >
        {children}
      </div>
    );
  }
}

function createShareButton(
  network,
  link,
  optsMap = () => ({}),
  propTypes,
  defaultProps = {}
) {
  const CreatedButton = props => (
    <ShareButton
      {...props}
      network={network}
      networkLink={link}
      opts={optsMap(props)}
    />
  );

  CreatedButton.propTypes = propTypes;
  CreatedButton.defaultProps = defaultProps;

  return CreatedButton;
}

export default createShareButton;

import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useFormikContext } from "formik";
import Cookies from "js-cookie";

import { useCft } from "../../../utils/CftProvider";
import {
  CftLayout,
  CftHeader,
  CftSection,
  CftFooter,
  CftReportProgress,
  CftActionsButtons,
  CftExportReport,
  CftTank,
  CftOnboarding,
} from "../../../components";
import routes from "../../../utils/cftRoutes";
import DetailsWaterFootprintForm, {
  DetailsWaterFootprintFormContext,
} from "../../../forms/farmlands/waterFootprint/DetailsWaterFootprintForm/DetailsWaterFootprint.form";

export const ONBOARDING_COOKIE = "sadda3fwsdfas";

function DetailsWaterFootprintPage() {
  const { t } = useTranslation(["cft"]);
  const history = useHistory();
  const { submitForm, values, isSubmitting } = useFormikContext();
  const { meta, saveWaterFootprint } = useCft();

  return (
    <CftLayout
      header={
        <CftHeader
          progressValue={0}
          cropName={meta.title.waterFootprint}
          actions={
            <CftTank
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <CftExportReport type="waterFootprint" />
              <CftActionsButtons.CancelButton
                onClick={() => {
                  saveWaterFootprint({
                    details: {
                      ...values,
                      name: values.name.slice(0, 30),
                    },
                  });
                  history.push(routes.FARMLANDS.WATER_FOOTPRINT.MAIN);
                }}
              />
            </CftTank>
          }
        />
      }
      footer={
        <CftFooter
          isPending={isSubmitting}
          text={t("cft:detailsFarmlands.title", "Szczegóły uprawy")}
          onNext={submitForm}
          onBack={!Cookies.get(ONBOARDING_COOKIE) ? () => "" : ""}
        />
      }
    >
      <CftSection
        header={
          <>
            <h3>{t("cft:detailsFarmlands.title", "Szczegóły uprawy")}</h3>
            <p>
              {t(
                "cft:detailsFarmlands.subtitle",
                "Wprowadź informacje dotyczące kalkulowanej uprawy i pola."
              )}
            </p>
          </>
        }
      >
        <DetailsWaterFootprintForm />
        <CftReportProgress type="waterFootprint" />
      </CftSection>
      <CftOnboarding />
    </CftLayout>
  );
}

export default () => {
  return (
    <DetailsWaterFootprintFormContext>
      <DetailsWaterFootprintPage />
    </DetailsWaterFootprintFormContext>
  );
};

import { useTranslation } from "react-i18next";

export const useLinks = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const loginPath = language === "pl" ? "/uzytkownik/login" : "/user/login";

  return {
    login: () => loginPath,
    event: slug => `/${language === "pl" ? "wydarzenia" : "events"}/${slug}`,
    multimedias: () => "/multimedia",
    multimedia: slug => `/multimedia/${slug}`,
    article: (slug, categoryId) => {
      const articlesPath = language === "pl" ? "/artykuly/" : "/articles/";
      const agroArticlesPath =
        language === "pl" ? "/agroraport/artykuly/" : "/agroraport/article/";

      const path = categoryId < 18 ? agroArticlesPath : articlesPath;

      return `${path}${slug}`;
    },
    knowledge: () => {
      return language === "pl" ? "/artykuly" : "/articles";
    },
    press: () => {
      return language === "pl" ? "/artykuly/prasa" : "/articles/prasa";
    },
    makro: () => {
      return language === "pl"
        ? "/artykuly/analizy-makro"
        : "/articles/analizy-makro";
    },
  };
};

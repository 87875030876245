import React from "react";
// import PieChart from "react-minimal-pie-chart";
// import { PieChart } from "react-d3-components";
import PieChart from "./PieChart";
import "./PollResults.scss";

const colors = ["#00655E", "#2B815A", "#68A955", "#9BCA50"];

export const PollResults = ({ results }) => {
  const data = results.map((item, index) => ({
    ...item,
    color: colors[index],
  }));
  return data.length ? (
    <div className="c-poll-chart">
      <div className="c-poll-chart__pie">
        <PieChart
          data={data}
          width={200}
          height={200}
          margin={60}
          innerRadius={22}
          colors={colors}
        />
      </div>
      <div className="c-poll-results">
        {data.map(item => (
          <div className="c-poll-results__row">
            <div
              className="c-poll-results__val"
              style={{ backgroundColor: item.color }}
            >
              {`${item.fraction}%`}
            </div>
            <div className="c-poll-results__txt">{item.label}</div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
